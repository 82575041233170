import React, {
  memo, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
} from 'semantic-ui-react'
import {
  get,
} from 'lodash'

const ACTIVITY_INTERVAL_TIME_MS = 1000 * 60 * 1 // one minute
const MAX_SIMULATED_ACTIVITY_TIME_MS = 1000 * 10 * 60 // one hour

const CourseModal = memo(function CourseModal ({
  id,
  accessToken,
  refreshToken,
  open,
  onClose,
}) {
  const modalRef = useRef(null)
  const intervalRef = useRef(null)
  const openTimeRef = useRef(null)

  /*
    This effect creates an interval where a mouse move event is simulated every so often while the modal is open
    (for up to an hour), in order to keep the user logged in while not interacting directly with the admin portal.
    Since the learner portal is opened in an iFrame that is in a different origin, it is not possible to listen
    for mouse and keyboard events from within that iframe.
  */
  useEffect(() => {
    if (open) {
      if (!openTimeRef.current) {
        openTimeRef.current = Date.now()
      }
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          const evt = new MouseEvent('mousemove', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          const ref = get(modalRef, 'current.ref.current')
          if (ref) {
            ref.dispatchEvent(evt)
          }
          if ((Date.now() - openTimeRef.current) > MAX_SIMULATED_ACTIVITY_TIME_MS) {
            clearInterval(intervalRef.current)
          }
        }, ACTIVITY_INTERVAL_TIME_MS)
      }
    } else {
      // reset everything when modal is closed
      clearInterval(intervalRef.current)
      openTimeRef.current = null
      intervalRef.current = null
    }

    return function cleanUp () {
      // clear interval when component unmounts
      clearInterval(intervalRef.current)
    }
  }, [ open ])

  return (
    <Modal
      closeIcon
      open={open && !!id}
      onClose={onClose}
      style={{ height: '90%', width: '95%' }}
      ref={modalRef}
    >
      <Modal.Content style={{ height: '100%' }}>
        {(!!id) && (
          <iframe
            src={`${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}course.base.education?auth=${window.btoa(JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))}&launch_course_id=${id}`}
            height='100%'
            width='100%'
            frameBorder='0'
            title='BASE Learner Portal'
            allow="microphone"
            allowFullScreen={true}
          >
          </iframe>
        )}
      </Modal.Content>
    </Modal>
  )
},
// prevents the modal from reloading if the auth token is being refreshed
(prevProps, nextProps) => (prevProps.open === nextProps.open && prevProps.id === nextProps.id),
)

CourseModal.propTypes = {
  id: PropTypes.string,
  accessToken: PropTypes.string.isRequired,
  refreshToken: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

CourseModal.defaultProps = {
  onClose: console.log.bind(console, 'onClose'),
}

export default CourseModal
