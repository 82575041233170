import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { get, map } from 'lodash'

import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import { getClientWithOverview } from '../../../../../actions/clients'
import {
  saveProfile,
  acknowledgeSaveProfile,
} from '../../../../../actions/user-profile'
import UserProfileForm from '../../../../forms/user-profile/user-profile'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'
import { ROLE_TYPES } from '../../../../../helpers/get-roles'
import { getSsoName } from '../../../../../helpers/user'

export class ProfileView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    postProfileState: PropTypes.object.isRequired,
    putProfileState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    saveProfile: PropTypes.func.isRequired,
    acknowledgeSaveProfile: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
  }

  static path = '/profile'

  dismissMessage = () => {
    this.props.acknowledgeSaveProfile()
  }

  onSaveProfile = (profileData) => {
    const {
      authState: { accessToken },
      clientId,
      saveProfile,
      getClientsState: { value: clientsById },
    } = this.props
    const client = get(clientsById, clientId, {})
    saveProfile({
      clientId,
      data: {
        id: get(client, 'profile.id'),
        ...profileData,
        assessmentsDeclinedReason: get(client, 'profile.assessmentsDeclinedReason'),
        ethnicity: (profileData.ethnicity) ? profileData.ethnicity.map((eth) => ({ key: eth })) : undefined,
      },
      accessToken,
    })
  }

  componentWillUnmount () {
    setTimeout(this.dismissMessage.bind(this), 100)
  }

  render () {
    const {
      getClientsState: { value: clientsById },
      putProfileState: {
        isLoading: putProfileIsLoading,
        error: putProfileError,
        succeeded: putProfileSucceeded,
      },
      postProfileState: {
        isLoading: postProfileIsLoading,
        error: postProfileError,
        succeeded: postProfileSucceeded,
      },
      showFriendlyApiErrorMessages,
      clientId,
      organization,
      authState: { roleType, userId },
    } = this.props

    const client = get(clientsById, clientId, {})
    const error = putProfileError || postProfileError
    const wasSuccesful = ((putProfileSucceeded || postProfileSucceeded) && !error)
    const ssoName = getSsoName(client.userName)
    return (
      <React.Fragment>
        <FetchResultMessage
          itemType='user profile information'
          success={wasSuccesful}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        <UserProfileForm
          isSSO={!!ssoName}
          ssoName={ssoName}
          ethnicity={map(get(client, 'profile.ethnicity', []), 'key')}
          firstName={get(client, 'profile.firstName', '')}
          lastName={get(client, 'profile.lastName', '')}
          school={get(client, 'profile.school', '')}
          age={get(client, 'profile.age')}
          gender={get(client, 'profile.gender')}
          assessmentsDeclinedReason={get(client, 'profile.assessmentsDeclinedReason')}
          jobTitle={get(client, 'profile.jobTitle')}
          worksWithStudents={get(client, 'profile.worksWithStudents')}
          onSave={this.onSaveProfile}
          isLoading={putProfileIsLoading || postProfileIsLoading}
          color='green'
          isDisabled={roleType === ROLE_TYPES.VIEWER || (roleType === ROLE_TYPES.ADVISOR && clientId !== userId)}
          isStaffMember={![ ROLE_TYPES.STUDENT, ROLE_TYPES.ADULT ].includes(client.roleType)}
          showAssessmentsDeclinedReason={organization.assessmentsEnabled}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    userProfile: {
      [FETCH_ACTIONS.POST]: postProfileState,
      [FETCH_ACTIONS.PUT]: putProfileState,
    },
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    postProfileState,
    putProfileState,
    getClientsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  saveProfile,
  acknowledgeSaveProfile,
  getClientWithOverview,
}
const ProfileViewContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileView)

export default translate([ 'components' ])(ProfileViewContainer)
