import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get, find, reject, flatten,
} from 'lodash'

import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import {
  updateClient,
  invalidateClients,
  acknowledgeUpdateClient,
} from '../../../../../actions/clients'
import {
  createRelationships,
  acknowledgeCreateRelationship,
  deleteRelationships,
  acknowledgeDeleteRelationship,
} from '../../../../../actions/relationships'
import {
  changePassword,
  acknowledgeChangePassword,
} from '../../../../../actions/password'
import ClientAccountForm from '../../../../forms/client-account/client-account'
import ChangePasswordForm from '../../../../forms/change-password/change-password'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'
import getRoles, {
  ROLE_TYPES, ROLE_VALUES, EXTERNAL_ROLE_TYPES, INTERNAL_ROLE_TYPES,
} from '../../../../../helpers/get-roles'
import NotificationSettings from '../../../../common/notification-settings/notification-settings'
import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'
import GET_USER_NOTIFICATION_SETTINGS from '../../../../../helpers/graphql-queries/get-user-notification-settings'
import CREATE_NOTIFICATION_SETTING from '../../../../../helpers/graphql-queries/create-notification-setting'
import DELETE_NOTIFICATION_SETTING from '../../../../../helpers/graphql-queries/delete-notification-setting'
import GET_NOTIFICATIONS_ON_BY_ORG_ID from '../../../../../helpers/graphql-queries/get-notifications-on-by-org-id'
import {
  getOrgTypesOfParents,
  // getOrgType,
} from '../../../../../helpers/organization'
import { getSsoName } from '../../../../../helpers/user'

import RoomOrgChangeModal from '../../../../common/room-org-change-modal/room-org-change-modal'
import RoomOrgTransferModal from '../../../../common/room-org-change-modal/room-org-transfer-modal'

const flattenAllOrgs = (org) => {
  const newOrg = {
    id: org.id,
    name: org.name,
    isActive: org.isActive,
  }
  if (!org.children || !org.children.length) {
    return [ newOrg ]
  }
  return [ newOrg ].concat(flatten(org.children.map(flattenAllOrgs)))
}

export class AccountView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    putClientsState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    updateClient: PropTypes.func.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    acknowledgeUpdateClient: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    homeOrganization: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    changePassword: PropTypes.func.isRequired,
    acknowledgeChangePassword: PropTypes.func.isRequired,
    putPasswordState: PropTypes.object.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      showRoomChange: false,
      showRoomTransfer: false,
      callback: () => {},
      userTransferSelection: null,
    }
  }

  static path = '/account'

  dismissMessage = () => {
    const {
      getClientsState: { value: clientsById },
      acknowledgeUpdateClient,
      acknowledgeChangePassword,
      invalidateClients,
      clientId,
      organization,
    } = this.props
    acknowledgeUpdateClient()
    acknowledgeChangePassword()

    // NOTE: If we switched the user's org, we need to reload all users
    const client = (!clientsById) ? {} : clientsById[clientId]
    if (!client || client.orgId !== organization.id) {
      invalidateClients()
    }
  }

  onSaveClient = ({ ...clientInfo }) => {
    const {
      authState: { accessToken },
      clientId,
      updateClient,
      getClientsState: { value: clientsById },
    } = this.props
    const client = (!clientsById) ? {} : clientsById[clientId]

    const data = {
      userName: client.userName,
      id: clientId,
      type: 'user',
      ...clientInfo,
    }

    if (client.orgId === clientInfo.orgId || !clientInfo.orgId) {
      updateClient({ data, accessToken })
    } else {
      const callback = () => {
        updateClient({ data, accessToken })
      }
      this.setState({
        ...this.state, showRoomChange: true, callback: callback,
      })
    }
  }

  onChangePassword = ({ newPassword }) => {
    const {
      changePassword,
      clientId,
      authState: { accessToken },
    } = this.props
    changePassword({
      newSecret: newPassword,
      clientId,
      accessToken,
    })
  }

  componentWillUnmount () {
    setTimeout(this.dismissMessage.bind(this), 100)
  }

  render () {
    const {
      authState: { roleType, userId },
      getClientsState: { value: clientsById },
      putClientsState: {
        succeeded: putClientSucceeded,
        isLoading: putClientIsLoading,
        error: putClientError,
      },
      putPasswordState: {
        isLoading: changePasswordLoading,
        error: changePasswordError,
        succeeded: changePasswordSucceeded,
      },
      showFriendlyApiErrorMessages,
      clientId,
      organization,
      homeOrganization,
    } = this.props

    const client = (!clientsById) ? {} : clientsById[clientId]
    if (!client) {
      return null
    }
    const didAttemptPutClient = (putClientSucceeded || !!putClientError)
    const atLeastOneChangeAttempted = (didAttemptPutClient)
    const error = putClientError
    const wasSuccesful = (atLeastOneChangeAttempted && !error)

    const isCustomer = !!organization.contractStartDate
    const parentOrgTypes = (homeOrganization.id === organization.id) ? [] : getOrgTypesOfParents(homeOrganization, organization)
    const showExternalRoleTypesOnly = Object.values(EXTERNAL_ROLE_TYPES).includes(roleType) || isCustomer || parentOrgTypes.includes('customer')
    const filteredRoles = getRoles({ externalOnly: showExternalRoleTypesOnly }).filter((role) => {
      const authenticatedUserRoleValue = ROLE_VALUES[roleType]
      const targetRoleValue = ROLE_VALUES[role.type]
      return (roleType === EXTERNAL_ROLE_TYPES.SUPERVISOR || roleType === EXTERNAL_ROLE_TYPES.ADVISOR)
        ? (role.type === roleType || authenticatedUserRoleValue > targetRoleValue)
        : (authenticatedUserRoleValue >= targetRoleValue)
    })

    const userCanHaveNotifications = [ ROLE_TYPES.ADMIN, ROLE_TYPES.SUPERVISOR, ROLE_TYPES.ADVISOR ].includes(client.roleType)
    const canManageOthersNotifications = [ ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.INTERNAL_ADMIN, ROLE_TYPES.ACCOUNT_MANAGER, ROLE_TYPES.ADMIN ].includes(roleType)
    const canViewNotifications = ((canManageOthersNotifications && userCanHaveNotifications) || (userId === clientId))
    const canManageNotifications = canManageOthersNotifications || (organization.selfManagedNotificationRoleTypes && organization.selfManagedNotificationRoleTypes.includes(roleType))
    const canOverrideSso = [ ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.INTERNAL_ADMIN, ROLE_TYPES.ACCOUNT_MANAGER, ROLE_TYPES.ADMIN ].includes(roleType)
    const ssoName = getSsoName(client.userName)
    const isSSO = !!ssoName
    const firstName = get(client, 'profile.firstName', '')
    const lastName = get(client, 'profile.lastName', '')
    const userName = (isSSO) ? `${firstName} ${lastName}` : client.userName
    return (
      <React.Fragment>
        <RoomOrgTransferModal
          open={this.state.showRoomTransfer}
          orgId={client.orgId}
          userId={client.id}
          userTransferSelection={this.state.userTransferSelection}
          onOptionSelect={(userId) => this.setState({ ...this.state, userTransferSelection: userId })}
          onBack={() => this.setState({
            ...this.state, showRoomTransfer: false, showRoomChange: true,
          })}
          onClose={() => this.setState({ ...this.state, showRoomTransfer: false })}
          callback={this.state.callback}
        />
        <RoomOrgChangeModal
          open={this.state.showRoomChange}
          orgId={client.orgId}
          userIds={[ client.id ]}
          onClose={() => this.setState({ ...this.state, showRoomChange: false })}
          onTransferSelect={() => this.setState({
            ...this.state, showRoomTransfer: true, showRoomChange: false,
          })}
          callback={this.state.callback}
        />
        <FetchResultMessage
          itemType='user account information'
          success={wasSuccesful}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        <FetchResultMessage
          itemType='password'
          success={changePasswordSucceeded}
          error={changePasswordError}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        {canViewNotifications && (
          <Query
            variables={{ userId: clientId, orgId: organization.id }}
            query={GET_USER_NOTIFICATION_SETTINGS}
          >
            {({
              loading: settingsLoading,
              data: settingsData,
            }) => {
              const notificationSettings = get(settingsData, 'notificationSettings.notificationSettings')
              return (
                <ClientAccountForm
                  onSave={(newClientData) => {
                    const unaccessibleSettings = reject(notificationSettings, {
                      orgId: newClientData.orgId,
                      userId: clientId,
                    })
                    if (client.orgId !== newClientData.orgId && unaccessibleSettings.length) {
                      alert('In order to move this user to a new organization, you must first disable all of their notification settings unrelated to the new organization.')
                      return
                    }
                    if ([ EXTERNAL_ROLE_TYPES.STUDENT, EXTERNAL_ROLE_TYPES.ADULT ].includes(newClientData.roleType) && !!notificationSettings.length) {
                      alert(`In order to change this user's role to ${newClientData.roleType}, you must first disable all of their notification settings.`)
                      return
                    }
                    if (Object.values(INTERNAL_ROLE_TYPES).includes(newClientData.roleType)) {
                      const areYouSure = window.confirm(`You are about to change this user's role to one that is meant for BASE employees/contractors only. Are you sure you want to do this?`)
                      if (!areYouSure) {
                        return
                      }
                    }
                    this.onSaveClient(newClientData)
                  }}
                  isSSO={isSSO}
                  ssoName={ssoName}
                  canOverrideSso={canOverrideSso}
                  isLoading={putClientIsLoading || settingsLoading}
                  color='green'
                  roles={filteredRoles}
                  selectedRoleType={client.roleType}
                  isSelf={clientId === userId}
                  orgs={flattenAllOrgs(homeOrganization)}
                  isDisabled={roleType === ROLE_TYPES.VIEWER || (roleType === ROLE_TYPES.ADVISOR && clientId !== userId)}
                  {...client}
                  ssoOverride={(canOverrideSso && client.ssoOverride)}
                  userName={userName}
                  graduationYear={client.graduationYear}
                />
              )
            }}
          </Query>
        )}
        {!canViewNotifications && (
          <ClientAccountForm
            isSSO={isSSO}
            ssoName={ssoName}
            canOverrideSso={canOverrideSso}
            onSave={this.onSaveClient}
            isLoading={putClientIsLoading}
            color='green'
            roles={filteredRoles}
            selectedRoleType={client.roleType}
            isSelf={clientId === userId}
            orgs={flattenAllOrgs(homeOrganization)}
            isDisabled={(roleType === ROLE_TYPES.ADVISOR)}
            {...client}
            ssoOverride={(canOverrideSso && client.ssoOverride)}
            userName={userName}
            graduationYear={client.graduationYear}
          />
        )}
        {(!isSSO) && (
          <ChangePasswordForm
            onSave={this.onChangePassword}
            isLoading={changePasswordLoading}
            isDisabled={false}
            color='green'
          />
        )}
        {(canViewNotifications) && (
          <Query
            variables={{ userId: clientId }}
            query={GET_USER_NOTIFICATION_SETTINGS}
          >
            {({
              loading,
              data,
            }) => {
              const notificationSettings = get(data, 'notificationSettings.notificationSettings')
              return (
                <Mutation
                  mutation={DELETE_NOTIFICATION_SETTING}
                  refetchQueries={[ { query: GET_NOTIFICATIONS_ON_BY_ORG_ID, variables: { orgId: organization.id } } ]}
                  update={(cache, { data: { deleteUserNotificationSetting } }) => {
                    const variables = { userId: clientId }
                    const data = cache.readQuery({ query: GET_USER_NOTIFICATION_SETTINGS, variables })
                    const notificationSettings = reject(data.notificationSettings.notificationSettings, { id: deleteUserNotificationSetting })
                    cache.writeQuery({
                      query: GET_USER_NOTIFICATION_SETTINGS,
                      variables,
                      data: {
                        notificationSettings: {
                          ...data.notificationSettings,
                          notificationSettings,
                        },
                      },
                    })
                  }}
                >
                  {(deleteUserNotificationSetting, { loading: isDeleting }) => {
                    return (
                      <Mutation
                        mutation={CREATE_NOTIFICATION_SETTING}
                        refetchQueries={[ { query: GET_NOTIFICATIONS_ON_BY_ORG_ID, variables: { orgId: organization.id } } ]}
                        update={(cache, { data: { createUserNotificationSetting } }) => {
                          const variables = { userId: clientId }
                          const data = cache.readQuery({ query: GET_USER_NOTIFICATION_SETTINGS, variables })
                          const notificationSettings = data.notificationSettings.notificationSettings.concat(createUserNotificationSetting)
                          cache.writeQuery({
                            query: GET_USER_NOTIFICATION_SETTINGS,
                            variables,
                            data: {
                              notificationSettings: {
                                ...data.notificationSettings,
                                notificationSettings,
                              },
                            },
                          })
                        }}
                      >
                        {(createUserNotificationSetting, { loading: isCreating }) => {
                          return (
                            <NotificationSettings
                              isDisabled={!canManageNotifications}
                              isLoading={loading}
                              isSaving={isCreating || isDeleting}
                              onEnableSetting={(orgId, type) => {
                                createUserNotificationSetting({
                                  variables: {
                                    notificationSetting: {
                                      orgId,
                                      userId: clientId,
                                      type,
                                      deliveryMethods: [ 'email' ],
                                    },
                                  },
                                })
                              }}
                              onDisableSetting={(orgId, type) => {
                                const matchingSetting = find(notificationSettings, {
                                  orgId,
                                  type,
                                  userId: clientId,
                                })
                                if (!matchingSetting) {
                                  alert('Unable to disable notification setting. Please refresh the page!')
                                  return
                                }
                                deleteUserNotificationSetting({
                                  variables: {
                                    id: matchingSetting.id,
                                  },
                                })
                              }}
                              notificationSettings={notificationSettings}
                              orgWithChildren={organization}
                              notificationMethods={{ email: client.email }}
                            />
                          )
                        }}
                      </Mutation>
                    )
                  }}
                </Mutation>
              )
            }}
          </Query>
        )}
        <br/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
      [FETCH_ACTIONS.PUT]: putClientsState,
    },
    password: {
      [FETCH_ACTIONS.PUT]: putPasswordState,
    },
    relationships: {
      [FETCH_ACTIONS.POST]: postRelationshipsState,
      [FETCH_ACTIONS.DELETE]: deleteRelationshipsState,
    },
    authentication: { accessToken, roles: authenticatedUserRoles },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getClientsState,
    putClientsState,
    putPasswordState,
    postRelationshipsState,
    deleteRelationshipsState,
    accessToken,
    authenticatedUserRoles,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  updateClient,
  invalidateClients,
  createRelationships,
  acknowledgeCreateRelationship,
  deleteRelationships,
  acknowledgeDeleteRelationship,
  acknowledgeUpdateClient,
  changePassword,
  acknowledgeChangePassword,
}
const AccountViewContainer = connect(mapStateToProps, mapDispatchToProps)(AccountView)

export default translate([ 'components' ])(AccountViewContainer)
