import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { filter } from 'lodash'

import EnrollmentItem from '../enrollment-item'
import { getDisplayName } from '../../../helpers/user'

import './course-enrollments-table.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.course-enrollments-table'

class CourseEnrollmentsTable extends React.Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    enrollments: PropTypes.arrayOf(PropTypes.object).isRequired,
    course: PropTypes.object,
    isSaving: PropTypes.bool,
    color: PropTypes.string,
    header: PropTypes.string,
    t: PropTypes.func.isRequired,
    onEnroll: PropTypes.func,
    onUnenroll: PropTypes.func,
    onReenroll: PropTypes.func,
  }

  static defaultProps = {
    color: 'blue',
    header: 'User',
    isSaving: false,
    enrollments: [],
    users: [],
  }

  renderEnrollmentRow = (user) => {
    const {
      course,
      isSaving,
      onEnroll,
      onUnenroll,
      onReenroll,
      enrollments,
    } = this.props
    const clientEnrollments = enrollments.filter((enrollment) => enrollment.clientId === user.id)
    const courseEnrollments = filter(clientEnrollments, { courseId: course.id })
    const displayName = getDisplayName(user.userName)
    return (
      <EnrollmentItem
        key={user.id}
        isSaving={isSaving}
        displayName={displayName}
        itemId={user.id}
        contentItemCount={(course.contentItems) ? course.contentItems.length : 0}
        courseEnrollments={courseEnrollments}
        onEnroll={onEnroll}
        onUnenroll={onUnenroll}
        onReenroll={onReenroll}
        discontinued={!course.isActive}
      />
    )
  }

  render () {
    const {
      users,
      color,
      header,
      t,
      course,
    } = this.props

    return (
      <Table
        className='course-enrollments-table'
        color={color}
        striped={true}
        celled={true}
        selectable={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell data-public style={{ minWidth: '140px' }}>{header}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.progress`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.last_activity`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.attempt_number`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.actions`)}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {course && users.map(this.renderEnrollmentRow)}
        </Table.Body>
      </Table>
    )
  }
}

export default translate([ 'components' ])(CourseEnrollmentsTable)
