import copyError from 'utils-copy-error'

const loginFailure = (error) => (state) => {
  return Object.assign({}, state, {
    accessToken: null,
    refreshToken: null,
    userId: null,
    orgId: null,
    roleType: null,
    expires: 0,
    isLoading: false,
    isRefreshing: false,
    error: (error) ? copyError(error) : null,
  })
}

export default loginFailure
