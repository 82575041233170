import React from 'react'
import PropTypes from 'prop-types'

import './main-content-container.css'

const MainContentContainer = ({
  children,
  className,
}) => (
  <div className={(className) ? `${className} main-content-container no-margin-print` : 'main-content-container no-margin-print'}>
    {children}
  </div>
)

MainContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default MainContentContainer
