import gql from 'graphql-tag'

export const NAME = 'deleteUserAssessment'
const DELETE_USER_ASSESEMENT = gql`
mutation ${NAME}($id: ID!) {
  ${NAME}(id: $id)
}
`

export default DELETE_USER_ASSESEMENT
