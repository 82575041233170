import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import {
  get,
  groupBy,
  keyBy,
  sortBy,
  filter,
  flatMap,
  map,
  find,
  isNumber,
  compact,
  pick,
  omitBy,
  isNil,
} from 'lodash'
import {
  Header,
  Button,
  Segment,
  Image,
  Icon,
  Breadcrumb,
} from 'semantic-ui-react'

import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_STAFF_BY_ORG_ID from '../../../../../helpers/graphql-queries/get-staff-by-org-id'
import GET_STUDENT_DETAILS from '../../../../../helpers/graphql-queries/get-student-details'
import GET_ROOM_BY_ID from '../../../../../helpers/graphql-queries/get-room-by-id'
import RESET_REGISTRATION from '../../../../../helpers/graphql-queries/reset-registration'
import UNLOCK_REGISTRATION from '../../../../../helpers/graphql-queries/unlock-registration'
// import ProgressBar from '../../../../common/progress-bar'
// import printElement from '../../../../../helpers/print-element'
import CourseAnswers from '../../../../common/course-answers/course-answers'
import {
  FETCH_ACTIONS,
} from '../../../../../helpers/fetch-constants'
import {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
} from '../../../../../actions/analyzed-answers'
import { createIgnisRating, acknowledgeCreateIgnisRating } from '../../../../../actions/ignis-ratings'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'
import { getDisplayName } from '../../../../../helpers/user'
import ResetEnrollmentModal from '../../../../common/reset-enrollment-modal/reset-enrollment-modal'
import exportToCsv from '../../../../../helpers/export-to-csv'

import './rooms.css'

const roomIdPathParam = ':roomId'
const courseIdPathParam = ':courseId'
const path = `/rooms/${roomIdPathParam}/courses/${courseIdPathParam}`
const alternatePath = `/all-rooms/${roomIdPathParam}/courses/${courseIdPathParam}`

export const helpers = {
  // all urls under a given room
  getRoomDetailsCoursePath: (roomId, courseId, isAlternate) => (isAlternate) ? `${alternatePath.replace(roomIdPathParam, roomId).replace(courseIdPathParam, courseId)}` : `${path.replace(roomIdPathParam, roomId).replace(courseIdPathParam, courseId)}`,
}

export class RoomDetailsCourseView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    homeOrganization: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    // onReviewProgressClick: PropTypes.func.isRequired,
    // onReviewFirewordsClick: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    createAnalyzedAnswer: PropTypes.func.isRequired,
    acknowledgeCreateAnalyzedAnswer: PropTypes.func.isRequired,
    createIgnisRating: PropTypes.func.isRequired,
    acknowledgeCreateIgnisRating: PropTypes.func.isRequired,
    postAnalyzedAnswersState: PropTypes.object.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool.isRequired,
  }

  static path = path

  static alternatePath = alternatePath

  constructor (props) {
    super(props)
    this.state = {
      showAddStudentsModal: false,
      showAddCoursesModal: false,
      showEditRoomModal: false,
      selectedUser: false,
      activePageNumber: 1,
      activeTabIndex: 0,
      filter: '',
      selectedCourse: null,
      launchCourseId: null,
      printCourseId: null,
      searchResults: [],
      isSearching: false,
      resetEnrollmentId: null,
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.postAnalyzedAnswersState.isLoading && !this.props.postAnalyzedAnswersState.isLoading) {
      this.setState({
        ...this.state,
        isSavingAnalyzedAnswer: false,
        shouldRefetch: true,
      })
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  render () {
    const {
      getCoursesState,
      organization,
      createAnalyzedAnswer,
      createIgnisRating,
      postAnalyzedAnswersState,
      match: { params: { roomId, courseId } },
      location: { pathname },
      showFriendlyApiErrorMessages,
      authState: {
        accessToken,
      },
    } = this.props

    if (getCoursesState.isLoading) {
      return (<FullScreenLoadingOverlay isActive={true}/>)
    }
    const coursesById = get(getCoursesState, 'value')
    if (!coursesById || !coursesById[courseId]) {
      return (
        <FetchResultMessage
          success={false}
          error={new Error('Modules not found')}
          showFriendlyError={showFriendlyApiErrorMessages}
        />
      )
    }
    return (
      <Query
        variables={{
          orgId: organization.id,
        }}
        query={GET_STAFF_BY_ORG_ID}
      >
        {({
          loading,
          data,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const staff = get(data, 'staffByOrgId') || []
          const staffById = keyBy(staff, 'id')

          return (
            <Query
              variables={{
                id: roomId,
              }}
              query={GET_ROOM_BY_ID}
            >
              {({
                loading,
                data,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const room = get(data, 'room')
                if (!room) {
                  return (
                    <FetchResultMessage
                      success={false}
                      error={new Error('Room not found')}
                      showFriendlyError={showFriendlyApiErrorMessages}
                    />
                  )
                }

                return (
                  <Query
                    variables={{
                      clientIds: room.studentIds,
                      courseIds: [ courseId ],
                      privateRoomId: (room.isPrivate) ? room.id : undefined,
                    }}
                    query={GET_STUDENT_DETAILS}
                    fetchPolicy='network-only'
                    onCompleted={() => this.setState({
                      ...this.state,
                      isSavingAnalyzedAnswer: false,
                      shouldRefetch: false,
                    })}
                  >
                    {({
                      loading: studentDataLoading,
                      data: studentData,
                      refetch: refetchStudentData,
                    }) => {
                      if (studentDataLoading) {
                        return (<FullScreenLoadingOverlay isActive={true}/>)
                      }
                      if (this.state.shouldRefetch) {
                        setTimeout(refetchStudentData, 10)
                      }
                      const users = get(studentData, 'studentDetails') || []
                      const course = coursesById[courseId]
                      const courseTitle = course.titles || course.title.en
                      const isAllRooms = pathname.includes('/all-rooms/')
                      const pathRegex = new RegExp(`/${(isAllRooms) ? 'all-' : ''}rooms/.+`)
                      const sections = [
                        {
                          key: (!isAllRooms) ? 'Home' : 'All Rooms', content: (!isAllRooms) ? 'Home' : 'All Rooms', link: true, onClick: () => this.navigateTo(pathname.replace(pathRegex, (!isAllRooms) ? '/rooms' : '/all-rooms')),
                        },
                        {
                          key: roomId, content: room.name, link: true, onClick: () => this.navigateTo(this.props.location.pathname.replace(pathRegex, (!isAllRooms) ? '/rooms' : '/all-rooms') + `/${roomId}`),
                        },
                        {
                          key: courseId, content: courseTitle, active: true,
                        },
                      ]

                      const onDownloadResponses = () => {
                        const sortedContentItemsWithQuestions = sortBy(filter(course.contentItems, 'questionId'), 'ordinal')
                        const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                        const orderedQuestions = compact(courseQuestionIds.map((id) => find(course.questions, { id })))
                        const exportData = users.flatMap((user) => {
                          // coerce attemptNumber to numbers if possible
                          const enrollments = user.enrollments.map((enrollment) => ({
                            ...enrollment,
                            attemptNumber: enrollment.attemptNumber * 1 || enrollment.attemptNumber,
                          })).filter((enrollment) => (room.isPrivate) ? enrollment.attemptNumber === `room_${room.id}` : isNumber(enrollment.attemptNumber))
                          const answers = user.answers.map((answer) => ({
                            ...answer,
                            attemptNumber: answer.attemptNumber * 1 || answer.attemptNumber,
                          }))
                          const fpcs = user.firePhraseCaptures.map((firePhraseCapture) => ({
                            ...firePhraseCapture,
                            attemptNumber: firePhraseCapture.attemptNumber * 1 || firePhraseCapture.attemptNumber,
                          }))
                          const firePhrasesByQuestionId = groupBy(fpcs, 'questionId')
                          const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
                          const hydratedAnswers = answers.map((ans) => {
                            const firePhraseCaptures = (!firePhrasesByQuestionId[ans.questionId]) ? [] : filter(firePhrasesByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                            return {
                              ...ans,
                              firePhraseCaptures,
                            }
                          })
                          const answersByQuestionId = groupBy(hydratedAnswers, 'questionId')
                          const displayName = getDisplayName(user)
                          if (!enrollmentsByCourseId[courseId] || !enrollmentsByCourseId[courseId].length) {
                            return {
                              id: user.clientId,
                              name: displayName,
                            }
                          }
                          return enrollmentsByCourseId[courseId].map((enrollment) => {
                            return {
                              id: user.clientId,
                              name: displayName,
                              attemptNumber: enrollment.attemptNumber,
                              ...orderedQuestions.reduce((accum, question, idx) => {
                                const answers = get(answersByQuestionId, question.id) || []
                                const answer = find(answers, { attemptNumber: enrollment.attemptNumber }) || { answerText: '' }
                                return {
                                  ...accum,
                                  [`${idx + 1}. ${question.title.replace(/\n/g, ' ')}`]: answer.answerText.replace(/\n/g, ' '),
                                  [`${idx + 1}. Firewords`]: (answer.firePhraseCaptures && answer.firePhraseCaptures.length) ? map(flatMap(answer.firePhraseCaptures, 'analyzedCaptures'), 'sentence').join('; ') : '',
                                }
                              }, {}),
                            }
                          })
                        })
                        const date = new Date().toISOString().split('T')[0]
                        const questionTitles = orderedQuestions.flatMap((question, idx) => [
                          `${idx + 1}. ${question.title.replace(/\n/g, ' ')}`,
                          `${idx + 1}. Firewords`,
                        ])
                        const simplifiedRoomName = `room-${room.name.toLowerCase().replace(/\s/g, '-').replace(/[^-\w]/g, '').replace(/-{2,}/g, '-')}`
                        const fields = [ 'id', 'name', 'attemptNumber' ].concat(questionTitles)
                        exportToCsv(`${simplifiedRoomName}-answers-${date}`, exportData, fields)
                      }

                      return (
                        <Mutation
                          mutation={UNLOCK_REGISTRATION}
                        >
                          {(unlockRegistration, { loading: isUnlocking }) => {
                            return (
                              <Mutation
                                mutation={RESET_REGISTRATION}
                                onCompleted={() => {
                                  this.setState({
                                    ...this.state,
                                    resetEnrollmentId: null,
                                  })
                                }}
                              >
                                {(resetRegistration, { loading: isSaving }) => {
                                  return (
                                    <>
                                      <ResetEnrollmentModal
                                        open={!!this.state.resetEnrollmentId}
                                        forPrivateRoom={room.isPrivate}
                                        onClose={() => this.setState({ ...this.state, resetEnrollmentId: null })}
                                        onOptionSelect={(option) => {
                                          resetRegistration({
                                            variables: {
                                              id: this.state.resetEnrollmentId,
                                              createNew: (option === 'new'),
                                            },
                                          }).then(refetchStudentData)
                                        }}
                                      />
                                      <Segment basic className='room-details'>
                                        <Breadcrumb icon='right angle' sections={sections} />
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                          <Header as='h2' style={{ flexGrow: 1, margin: '15px 0' }}>
                                            <Image src={room.imageUrl} />
                                            <Header.Content>
                                              {room.name}
                                              <Header.Subheader><Icon style={{ marginRight: 4 }} name='write' /> {`Responses for: ${courseTitle}`}</Header.Subheader>
                                            </Header.Content>
                                          </Header>
                                          <div style={{ flexShrink: 1, marginTop: 15 }}>
                                            <Button
                                              data-public
                                              className='base-teal-bg text white'
                                              size='small'
                                              onClick={onDownloadResponses}
                                            >
                                              <Icon style={{ marginRight: 4 }} name='download' /> Download Responses
                                            </Button>
                                          </div>
                                        </div>
                                        <br/>

                                        {/* <div className='print-only' ref={this.printElem}>
                              <Header>Responses for: {displayName}</Header>

                              <Segment basic key={`print-user-room-answers-${user.id}`}>
                                {roomCourses.map((c) => {
                                  const sortedContentItemsWithQuestions = sortBy(filter(c.contentItems, 'questionId'), 'ordinal')
                                  const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                                  const answerCount = courseQuestionIds.filter((id) => answersByQuestionId[id] && answersByQuestionId[id].length).length
                                  const questionsById = keyBy(c.questions, 'id')
                                  return (
                                    <Segment key={`print-user-room-course-answers-${user.id}-${c.id}`}>
                                      <Header size='small'>{c.titles} <span className='text italic blue'>{(!answerCount) ? '(No Responses)' : `( ${answerCount} / ${c.questions.length} )`}</span></Header>

                                      {courseQuestionIds.map((qId, i) => {
                                        const q = questionsById[qId]
                                        if (!q) {
                                          return null
                                        }
                                        const ans = answersByQuestionId[q.id]
                                        const aa = analyzedAnswersByQuestionId[q.id]
                                        const fps = firePhrasesByQuestionId[q.id] || []
                                        if (!ans) {
                                          return null
                                        }
                                        return (
                                          <QuestionCard
                                            key={`print-user-room-answers-${user.id}-${c.id}-${q.id}`}
                                            questionNumber={i + 1}
                                            questionText={q.title}
                                            answerText={(ans[0]) ? ans[0].answerText : ''}
                                            notes={q.notes}
                                            analyzedCaptures={flatten(fps.map((fp) => fp.analyzedCaptures))}
                                            analyzedAnswer={(aa) ? { ...aa, reviewer: { userName: get(staffById, `${aa.reviewerClientId}.userName`, 'A Staff Member') } } : null}
                                          />
                                        )
                                      })}
                                    </Segment>
                                  )
                                })}
                              </Segment>
                            </div> */}
                                        {users.map((user) => {
                                          const sortedContentItemsWithQuestions = sortBy(filter(course.contentItems, 'questionId'), 'ordinal')
                                          const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                                          const orderedQuestions = courseQuestionIds.map((id) => find(course.questions, { id }))
                                          // coerce attemptNumber to numbers if possible
                                          const enrollments = user.enrollments.map((enrollment) => ({
                                            ...enrollment,
                                            attemptNumber: enrollment.attemptNumber * 1 || enrollment.attemptNumber,
                                          })).filter((enrollment) => (room.isPrivate) ? enrollment.attemptNumber === `room_${room.id}` : isNumber(enrollment.attemptNumber))
                                          const answers = user.answers.map((answer) => ({
                                            ...answer,
                                            attemptNumber: answer.attemptNumber * 1 || answer.attemptNumber,
                                          }))
                                          const aas = user.analyzedAnswers.map((analyzedAnswer) => ({
                                            ...analyzedAnswer,
                                            attemptNumber: analyzedAnswer.attemptNumber * 1 || analyzedAnswer.attemptNumber,
                                          }))
                                          const fpcs = user.firePhraseCaptures.map((firePhraseCapture) => ({
                                            ...firePhraseCapture,
                                            attemptNumber: firePhraseCapture.attemptNumber * 1 || firePhraseCapture.attemptNumber,
                                          }))
                                          const analyzedAnswersByQuestionId = groupBy(aas, 'questionId')
                                          const firePhrasesByQuestionId = groupBy(fpcs, 'questionId')
                                          const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
                                          const hydratedAnswers = answers.map((ans) => {
                                            const firePhraseCaptures = (!firePhrasesByQuestionId[ans.questionId]) ? [] : filter(firePhrasesByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                                            const analyzedAnswer = (!analyzedAnswersByQuestionId[ans.questionId]) ? null : find(analyzedAnswersByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                                            return {
                                              ...ans,
                                              firePhraseCaptures,
                                              analyzedAnswer,
                                              hasUnreviewedFirePhrases: (!!firePhraseCaptures.length && !analyzedAnswer),
                                            }
                                          })
                                          const answersByQuestionId = groupBy(hydratedAnswers, 'questionId')
                                          const displayName = getDisplayName(user)

                                          const handleAcknowledgeFireword = (isSafe, reason, questionId, answerId, attemptNumber) => {
                                            createAnalyzedAnswer({
                                              data: {
                                                isSafe,
                                                isSafeReason: reason,
                                                attemptNumber: attemptNumber,
                                                _meta: {
                                                  relationships: [ {
                                                    type: 'questions',
                                                    id: questionId,
                                                  } ],
                                                },
                                              },
                                              answerId,
                                              accessToken,
                                              clientId: user.clientId,
                                              skipCustomerReducer: true,
                                            })
                                            this.setState({
                                              ...this.state, isSavingAnalyzedAnswer: true, shouldRefetch: false,
                                            })
                                          }
                                          const handleRateIgnis = (rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData) => {
                                            analyzedCaptures = analyzedCaptures.map((ac) => ({
                                              ...pick(ac, [ 'ignisSeverity', 'receptivitiSeverity', 'isSafe', 'sentence' ]), matchedPhrases: [], sentenceTokens: [],
                                            }))
                                            analyzedCaptures = analyzedCaptures.map((ac) => (omitBy(ac, isNil)))
                                            createIgnisRating({
                                              data: {
                                                rating,
                                                questionText,
                                                questionId,
                                                sentence,
                                                answerId,
                                                analyzedCaptures,
                                              },
                                              accessToken,
                                              clientId: user.clientId,
                                            })
                                            refetchStudentData()
                                          }
                                          return (
                                            <CourseAnswers
                                              key={`answers-${user.clientId}-${course.id}`}
                                              id={`answers-${user.clientId}-${course.id}`}
                                              courseTitle={displayName}
                                              questions={orderedQuestions}
                                              firePhrasesByQuestionId={firePhrasesByQuestionId}
                                              analyzedAnswersByQuestionId={analyzedAnswersByQuestionId}
                                              answersByQuestionId={answersByQuestionId}
                                              courseEnrollments={enrollmentsByCourseId[course.id]}
                                              clientsById={staffById}
                                              userName={user.userName}
                                              displayName={courseTitle}
                                              isSaving={postAnalyzedAnswersState.isLoading}
                                              onReviewedClick={handleAcknowledgeFireword}
                                              onIgnisRate={(rating, questionText, questionId, sentence, answerId, analyzedCaptures) => handleRateIgnis(rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData)}
                                              previousIgnisRatings={user.ignisRatings}
                                              firewordsEnabled={organization.firewordsEnabled}
                                              courseContentItems={course.contentItems}
                                              onResetClick={(enrollmentId) => this.setState({ ...this.state, resetEnrollmentId: enrollmentId })}
                                              onUnlockClick={(enrollmentId) => unlockRegistration({
                                                variables: {
                                                  id: enrollmentId,
                                                },
                                              }).then(refetchStudentData)}
                                              isUnlocking={isUnlocking}
                                            />
                                          )
                                        })}
                                      </Segment>
                                    </>
                                  )
                                }}
                              </Mutation>
                            )
                          }}
                        </Mutation>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    analyzedAnswers: {
      [FETCH_ACTIONS.POST]: postAnalyzedAnswersState,
    },
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state
  return {
    postAnalyzedAnswersState,
    showFriendlyApiErrorMessages,
    getCoursesState,
  }
}
const mapDispatchToProps = {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
  createIgnisRating,
  acknowledgeCreateIgnisRating,
}
const ClientRoomDCourseViewContainer = connect(mapStateToProps, mapDispatchToProps)(RoomDetailsCourseView)

export default translate([ 'components' ])(ClientRoomDCourseViewContainer)
