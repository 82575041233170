import React, {
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import {
  Message,
  List,
  Icon,
  Header,
  Popup,
  Input,
  Segment,
  Button,
} from 'semantic-ui-react'
import { debounce } from 'lodash'
import '../../common.css'
import './remote-search-input.css'
const DETAULT_RENDER_ITEM = (item) => (
  <>
    <List.Icon style={{ minWidth: 20, padding: 0 }} name={item.icon || 'building'} verticalAlign='middle' />
    <List.Content>
      {item.name}
    </List.Content>
  </>
)
const RemoteSearchInput = ({
  isLoading,
  isHidden,
  onChange,
  onSelect,
  searchResults,
  popupWidth,
  noResultsMessage,
  showRefineSearchMessage,
  preserveSearchOnSelect,
  usePopup,
  renderItemContent = DETAULT_RENDER_ITEM,
  size,
  searchOnInitialRender,
  maxHeightStyle,
  enablePaging,
  pageSize,
  excludedItemIds,
  ...otherProps
}) => {
  const [ searchText, setSearchText ] = useState('')
  const [ isTyping, setIsTyping ] = useState(false)
  const [ pageNum, setPageNum ] = useState(0)
  const [ isInitialRender, setIsInitialRender ] = useState(true)
  const wrapped = (data, skip, limit) => {
    setIsTyping(false)
    onChange(data, skip, limit)
  }
  const debouncedOnChange = useRef(debounce(wrapped, 300))
  const wrappedOnChange = (data, skip, limit) => {
    setIsTyping(true)
    debouncedOnChange.current(data, skip, limit)
  }

  if (searchOnInitialRender && isInitialRender && !isLoading) {
    setTimeout(() => wrappedOnChange(searchText, pageNum * pageSize, pageSize + 1), 10)
  }
  if (isInitialRender) {
    setTimeout(() => setIsInitialRender(false), 1)
  }

  const options = searchResults && searchResults.length && searchResults.filter((item, i) => (i <= pageSize - 1) && (!excludedItemIds || !excludedItemIds.length || !excludedItemIds.includes(item.id))).map((item) => (
    <List.Item
      className={item.className}
      tabIndex='0'
      key={item.id}
      onClick={() => {
        if (!preserveSearchOnSelect) {
          setSearchText('')
        }
        onSelect(item)
      }}
      onKeyUp={(event) => {
        if (event.keyCode === 13) {
          if (!preserveSearchOnSelect) {
            setSearchText('')
          }
          onSelect(item)
        }
      }}
    >
      {renderItemContent(item)}
    </List.Item>
  ))
  const popupContent = (!options || !options.length) ? (
    <Message icon warning size='small'>
      <Icon name='warning' style={{ fontSize: '2em' }} />
      {noResultsMessage}
    </Message>
  ) : (
    <>
      <List
        className='remote-search-list'
        divided
        selection
        verticalAlign='middle'
        style={{ marginBottom: 0, maxHeight: maxHeightStyle }}
      >
        {options}
      </List>
      {(showRefineSearchMessage) && (
        <div
          className='text bold grey small'
          style={{
            padding: 10,
            backgroundColor: '#eee',
            borderTop: '1px #ccc solid',
            fontSize: '.8em',
          }}
        >
          <Header size='tiny'>
            <Icon name='info circle' />
            <Header.Content>There are more results. Please refine your search.</Header.Content>
          </Header>
        </div>
      )}
      {(enablePaging) && (
        <Button.Group
          attached='bottom'
          fluid
        >
          {(pageNum > 0) && (
            <Button
              icon
              labelPosition='left'
              type='button'
              onClick={() => {
                setPageNum(pageNum - 1)
                onChange(searchText, (pageNum - 1) * pageSize, pageSize + 1)
              }}
            >
              <Icon name='left arrow' />
              Previous
            </Button>
          )}
          {(searchResults.length > pageSize) && (
            <Button
              icon
              labelPosition='right'
              type='button'
              onClick={() => {
                setPageNum(pageNum + 1)
                onChange(searchText, (pageNum + 1) * pageSize, pageSize + 1)
              }}
            >
              Next
              <Icon name='right arrow' />
            </Button>
          )}
        </Button.Group>
      )}
    </>
  )
  const inputIcon = (isLoading || isTyping) ? (
    <Icon
      name='spinner'
      style={{
        marginRight: 32,
      }}
    />
  ) : (searchText) ? (
    <Icon
      name='close'
      color='grey'
      inverted
      circular
      link
      onClick={() => {
        setSearchText('')
        onChange('', 0, pageSize + 1)
      }}
      style={{
        marginRight: 34, top: '25%', fontSize: '65%',
      }}
    />
  ) : null
  if (usePopup) {
    const popupStyle = {
      padding: (searchResults && searchResults.length) ? 0 : 10,
      minWidth: popupWidth,
      maxWidth: popupWidth,
      marginTop: 3,
    }
    return (
      <Popup
        basic
        style={popupStyle}
        eventsEnabled={false}
        open={!isHidden && !isLoading && !isTyping && !!searchText && !!popupContent}
        content={popupContent}
        position='bottom left'
        positionFixed
        trigger={(
          <Input
            data-public
            {...otherProps}
            size='small'
            icon={inputIcon}
            loading={isLoading || isTyping}
            value={searchText}
            action={{
              basic: true,
              size: 'tiny',
              color: 'grey',
              icon: 'search',
              disabled: true,
            }}
            onChange={(e, data) => {
              setSearchText(data.value)
              setPageNum(0)
              wrappedOnChange(data.value, 0, pageSize + 1)
            }}
          />
        )}
      />
    )
  }
  return (
    <>
      <Input
        data-public
        {...otherProps}
        size={size}
        icon={inputIcon}
        loading={isLoading || isTyping}
        value={searchText}
        action={{
          basic: true,
          size: 'tiny',
          color: 'grey',
          icon: 'search',
          disabled: true,
        }}
        onChange={(e, data) => {
          setSearchText(data.value)
          setPageNum(0)
          wrappedOnChange(data.value, 0, pageSize + 1)
        }}
      />
      {(!!options && !isLoading && !isTyping) && (
        <Segment className='remote-search-segment'>
          {popupContent}
        </Segment>
      )}
    </>
  )
}

RemoteSearchInput.propTypes = {
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  excludedItemIds: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  popupWidth: PropTypes.number,
  noResultsMessage: PropTypes.string,
  renderItemContent: PropTypes.func,
  showRefineSearchMessage: PropTypes.bool,
  preserveSearchOnSelect: PropTypes.bool,
  usePopup: PropTypes.bool,
  searchOnInitialRender: PropTypes.bool,
  isHidden: PropTypes.bool,
  size: PropTypes.string,
  maxHeightStyle: PropTypes.string,
  enablePaging: PropTypes.bool,
  pageSize: PropTypes.number,
}

RemoteSearchInput.defaultProps = {
  isLoading: false,
  searchOnInitialRender: false,
  onChange: console.log.bind(console, 'onChange'),
  onSelect: console.log.bind(console, 'onSelect'),
  noResultsMessage: 'There are no results. Please try a different search.',
  preserveSearchOnSelect: false,
  usePopup: true,
  isHidden: false,
  size: 'small',
  maxHeightStyle: 'calc(100vh - 500px)',
  enablePaging: false,
  pageSize: 50,
  excludedItemIds: [],
}

export default RemoteSearchInput
