const loginSuccess = (data) => (state) => {
  const [ userId, orgId, roleType ] = data.access_token.split(':')
  return Object.assign({}, state, {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    userId,
    orgId,
    roleType,
    email: data.email,
    userName: data.userName,
    profile: data.profile,
    expires: Date.now() + (data.expires_in * 1000),
    isLoading: false,
    isRefreshing: false,
    error: null,
    lastSuccessfulAuthentication: Date.now(),
  })
}

export default loginSuccess
