
import gql from 'graphql-tag'

const GET_ORG_WITH_PARENTS = gql`

query getOrganizationWithParents($id: ID!) {
  organizationWithParents(
    id: $id
  ) {
    id
    name
    isActive
    type
    contractStartDate
    contractEndDate
    parentIds
    notes
    sessionsEnabled
    roomsEnabled
    privateRoomsEnabled
    studentAccessSchedule {
      timezone
    }
    children {
      id
      name
      isActive
      type
      contractStartDate
      contractEndDate
      parentIds
      children {
        id
        name
        isActive
        type
        contractStartDate
        contractEndDate
        parentIds
        children {
          id
          name
          isActive
          type
          contractStartDate
          contractEndDate
          parentIds
          children {
            id
            name
            isActive
            type
            contractStartDate
            parentIds
            children {
              id
              name
              isActive
              type
              contractStartDate
              contractEndDate
              parentIds
              children {
                id
                name
                isActive
                type
                contractStartDate
                contractEndDate
                parentIds
                children {
                  id
                  name
                  isActive
                  type
                  contractStartDate
                  parentIds
                  children {
                    id
                    name
                    isActive
                    type
                    contractStartDate
                    contractEndDate
                    parentIds
                    children {
                      id
                      name
                      isActive
                      type
                      contractStartDate
                      contractEndDate
                      parentIds
                      children {
                        id
                        name
                        isActive
                        type
                        contractStartDate
                        contractEndDate
                        parentIds
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default GET_ORG_WITH_PARENTS
