import { isObject, isString } from 'lodash'

// TODO: support traversing arrays, like lodash set/get
const mergeAtPath = (target, value, path) => {
  if (!isObject(target) || !path || !path.length) {
    throw new Error('Invalid parameters')
  }
  const pathParts = (isString(path)) ? path.split('.') : path
  const firstPart = pathParts[0]
  if (pathParts.length === 1) {
    return { ...target, [firstPart]: value }
  }
  if (!isObject(target[firstPart])) {
    throw new Error(`Invalid path. Unable to traverse the specified path on the target or the obejct to merge. pathParts: ${pathParts}`)
  }
  const child = mergeAtPath(target[firstPart], value, pathParts.slice(1))
  return { ...target, [firstPart]: { ...target[firstPart], ...child } }
}

export default mergeAtPath
