import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  Segment,
  Input,
  Label,
  Button,
  Message,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'

import {
  pattern,
  minLength,
  maxLength,
  composeValidators,
} from '../../../helpers/form-validators'
import { lowerCaseFormatter, trimInputOnBlur } from '../../../helpers/form'
import FetchResultMessage from '../../common/fetch-result-message/fetch-result-message'

import './reset-password.css'

const validateUserName = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
  pattern({ message: 'forms.login.user_name_error_pattern', pattern: /^[^\s]+$/ }),
)

export class ResetPasswordForm extends Component {
  static propTypes = {
    onResetPassword: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showNotConnectedError: PropTypes.bool,
    showNotFoundError: PropTypes.bool,
    showSuccessMessage: PropTypes.bool,
    showGeneralError: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onResetPassword: console.log.bind(console, 'onResetPassword'),
    color: 'teal',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleResetPassword = ({ userName }) => {
    userName = userName.toLowerCase()
    this.props.onResetPassword({ userName })
  }

  render () {
    const {
      isLoading,
      showNotConnectedError,
      showSuccessMessage,
      showNotFoundError,
      showGeneralError,
      t,
    } = this.props
    const hasError = showNotConnectedError || showNotFoundError || showGeneralError
    return (
      <Form
        onSubmit={this.handleResetPassword}
        subscription={{ pristine: true, invalid: true }}
        initialValues={{ userName: '' }}
        render={({
          handleSubmit,
          pristine,
          invalid,
        }) => (
          <form className='reset-password-form' onSubmit={handleSubmit}>
            <Segment attached='top'>
              <Image src='https://media.base.education/img/base-7m-logo-color.png' size='large' centered />
            </Segment>
            <Segment attached inverted color='black'>
              <Field
                data-public
                name='userName'
                validate={validateUserName}
                format={lowerCaseFormatter}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <div>
                    <Input
                      data-public
                      className='reset-password-input'
                      placeholder={t('forms.user_name_label', { postProcess: 'titleCase' })}
                      error={!!message && touched}
                      type='text'
                      label={{ icon: 'user', className: 'base-green-bg text white' }}
                      labelPosition='left'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                      onBlur={trimInputOnBlur(input)}>
                    </Input>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </div>
                )}
              />
              {(showSuccessMessage || hasError) && <br/>}
              <Message data-public icon success hidden={!showSuccessMessage}>
                <Icon name='info circle' />
                <Message.Content>
                  <Message.Header>
                    {t('forms.reset-password.success')}
                  </Message.Header>
                  <p>{t('forms.reset-password.check_email')}</p>
                </Message.Content>
              </Message>
              {showNotConnectedError && <FetchResultMessage
                data-public
                error={{ statusCode: 0 }}
                success={false}
                showFriendlyError={true}
              />}
              {showNotFoundError && <Message
                data-public
                error
                header={t('forms.reset-password.error_header')}
                content={t('forms.reset-password.error_description_not_found')}
              />}
              {showGeneralError && <Message
                data-public
                error
                header={t('forms.reset-password.error_header')}
                content={t('forms.reset-password.error_description_general')}
              />}
            </Segment>
            <Segment className='bottom-segment' attached='bottom'>
              <Button
                data-public
                type='submit'
                className='reset-password-button base-green-bg text white'
                loading={isLoading}
                disabled={isLoading || pristine || invalid}>
                {t('forms.reset-password.reset_password_button')}
              </Button>
            </Segment>
          </form>
        )}
      />
    )
  }
}

export default translate([ 'components' ])(ResetPasswordForm)
