import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Message, Icon } from 'semantic-ui-react'
import { translate } from 'react-i18next'

import { requestPasswordReset, acknowledgePasswordResult } from '../../../actions/password'

import ResetPasswordForm from '../../forms/reset-password/reset-password'
import LoginView from '../login/login'

import './reset-password.css'

export class ResetPasswordView extends React.Component {
  static propTypes = {
    password: PropTypes.object.isRequired,
    requestPasswordReset: PropTypes.func.isRequired,
    acknowledgePasswordResult: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static path = '/reset-password'

  componentWillUnmount () {
    this.props.acknowledgePasswordResult()
  }

  render () {
    const {
      password,
      requestPasswordReset,
      t,
    } = this.props

    const errorStatus = get(password, 'post.error.statusCode')
    return (
      <div className='reset-password-page'>
        <ResetPasswordForm
          onResetPassword={requestPasswordReset}
          isLoading={password.post.isLoading}
          showNotConnectedError={errorStatus === 0}
          showNotFoundError={errorStatus === 404}
          showGeneralError={errorStatus >= 500}
          showSuccessMessage={password.post.succeeded}/>
        <Message data-public icon>
          <Icon name='info circle' />
          <Message.Content>
            <Message.Header>
              {t('views.reset-password.know_password')}
            </Message.Header>
            <p><a href={`#${LoginView.path}`}>{t('views.reset-password.click_here')}</a></p>
          </Message.Content>
        </Message>
      </div>
    )
  }
}

ResetPasswordView.path = '/reset-password'

const mapStateToProps = (state) => {
  const {
    password,
  } = state
  return {
    password,
  }
}
const mapDispatchToProps = { requestPasswordReset, acknowledgePasswordResult }
const ResetPasswordViewContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView)

export default translate([ 'components' ])(ResetPasswordViewContainer)
