import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { sortBy } from 'lodash'
import moment from 'moment'

import ProgressBar from '../progress-bar'
import './enrollment-item.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.enrollment-item'

const EnrollmentItem = ({
  displayName,
  itemId,
  courseEnrollments,
  contentItemCount,
  onEnroll,
  onUnenroll,
  onReenroll,
  isSaving,
  maxAttempts,
  disabled,
  discontinued,
  t,
}) => {
  let isEnrolled = false
  let isCompleted = false
  let isStarted = false
  let attemptNumber = 0
  let lastActivity = null
  let progressPercentage = 0
  let currentEnrollment = null
  if (courseEnrollments && courseEnrollments.length) {
    currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
    isEnrolled = true
    isCompleted = !!currentEnrollment.completedOn
    attemptNumber = currentEnrollment.attemptNumber
    let mostRecentDate = (currentEnrollment.contentItemProgresses.length) ? new Date(0) : null
    let highestOrdinalCompleted = -1
    currentEnrollment.contentItemProgresses.forEach(function (progress) {
      if (!!progress.startedOn && new Date(progress.startedOn) > mostRecentDate) {
        mostRecentDate = new Date(progress.startedOn)
      }
      if (!!progress.completedOn && new Date(progress.completedOn) > mostRecentDate) {
        mostRecentDate = new Date(progress.completedOn)
      }
      if (!!progress.completedOn && progress.ordinal > highestOrdinalCompleted) {
        highestOrdinalCompleted = progress.ordinal
      }
    })
    progressPercentage = (isCompleted) ? 100 : Math.round((highestOrdinalCompleted + 1) * 100 / contentItemCount)
    isStarted = highestOrdinalCompleted >= 0
    lastActivity = mostRecentDate
  }
  const enrollBtn = (<Button data-public className='enrollmentAction' color='green' loading={isSaving} disabled={isSaving || disabled} size='mini' floated='right' icon='plus' onClick={() => onEnroll({ itemId, attemptNumber: 1 })} content={t(`${TRANSLATION_PREFIX}.enroll`)}/>)
  const reenrollBtn = (<Button data-public className='enrollmentAction' color='blue' loading={isSaving} disabled={isSaving || disabled} size='mini' floated='right' icon='undo' onClick={() => onReenroll({ itemId, attemptNumber: attemptNumber + 1 })} content={t(`${TRANSLATION_PREFIX}.reenroll`)}/>)
  const unenrollBtn = (<Button data-public className='enrollmentAction' color='red' loading={isSaving} disabled={isSaving || disabled} size='mini' floated='right' icon='remove' onClick={() => onUnenroll({ itemId, enrollmentId: currentEnrollment.id })} content={t(`${TRANSLATION_PREFIX}.unenroll`)}/>)
  const lastActivityElement = (lastActivity)
    ? (<div>{moment(lastActivity).fromNow()}<br/>({moment(lastActivity).format('M/D/YYYY @ h:mm A')})</div>)
    : t(`${TRANSLATION_PREFIX}.no_activity`)
  return (
    <Table.Row className='enrollment-item'>
      <Table.Cell collapsing={true}>{displayName}</Table.Cell>
      <Table.Cell>{(courseEnrollments && courseEnrollments.length) ? <ProgressBar completed={progressPercentage}/> : null}</Table.Cell>
      <Table.Cell className={(lastActivity) ? 'activity collapsing' : 'no activity collapsing'}>{lastActivityElement}</Table.Cell>
      <Table.Cell collapsing={true}>{attemptNumber}</Table.Cell>
      <Table.Cell collapsing={true}>
        {(!discontinued && !isEnrolled && !isStarted) && enrollBtn}
        {(!discontinued && isEnrolled && isStarted && attemptNumber < maxAttempts) && reenrollBtn}
        {(isEnrolled && !isCompleted) && unenrollBtn}
        {(!discontinued && isEnrolled && isStarted && attemptNumber >= maxAttempts) && <div className='no activity text italic grey'>{t(`${TRANSLATION_PREFIX}.max_attempts`)}</div>}
      </Table.Cell>
    </Table.Row>
  )
}

EnrollmentItem.propTypes = {
  displayName: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  courseEnrollments: PropTypes.arrayOf(PropTypes.object),
  onEnroll: PropTypes.func,
  onUnenroll: PropTypes.func,
  onReenroll: PropTypes.func,
  isSaving: PropTypes.bool,
  contentItemCount: PropTypes.number.isRequired,
  maxAttempts: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  discontinued: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

EnrollmentItem.defaultProps = {
  isSaving: false,
  contentItemCount: 1,
  maxAttempts: 5,
  onEnroll: console.log.bind(console, 'onEnroll'),
  onUnenroll: console.log.bind(console, 'onUnenroll'),
  onReenroll: console.log.bind(console, 'onReenroll'),
  disabled: false,
  discontinued: false,
}

export default translate([ 'components' ])(EnrollmentItem)
