import React from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Icon,
} from 'semantic-ui-react'

import Mutation from '../../../common/mutation/mutation'
import INCREMENT_CLIENT_AGES_BY_ORG_ID from '../../../../helpers/graphql-queries/increment-client-ages-by-org-id'

const IncrementClientAgesByOrgIdMenuItem = ({
  orgId,
  onLoadingChange,
}) => (
  <Mutation
    mutation={INCREMENT_CLIENT_AGES_BY_ORG_ID}
    onCompleted={(data) => {
      const numModified = data.incrementClientAgesByOrgId
      onLoadingChange(false)
      setTimeout(() => window.alert(`Success! ${numModified} ${(numModified === 1) ? 'student was' : 'students were'} updated.`), 100)
    }}
  >
    {(incrementClientAgesByOrgId, { loading }) => {
      return (
        <Dropdown.Item data-public onClick={() => {
          if (!window.confirm('Are you sure you want to increase the age of all students in this orgainzation by 1? (This will only affect students who have an age set in their profile)')) {
            return
          }
          onLoadingChange(true)
          incrementClientAgesByOrgId({
            variables: {
              orgId,
            },
          })
        }}><Icon name='add circle' />Increment All Student Ages</Dropdown.Item>
      )
    }}
  </Mutation>
)

IncrementClientAgesByOrgIdMenuItem.propTypes = {
  orgId: PropTypes.string,
  onLoadingChange: PropTypes.func,
}

export default IncrementClientAgesByOrgIdMenuItem
