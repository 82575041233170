import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Header,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { pick } from 'lodash'

import Page1 from './create-room-page-1'
import { getDisplayName } from '../../../helpers/user'

import '../../common.css'

export class RoomDetailsModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    enablePrivateRooms: PropTypes.bool,
    staff: PropTypes.arrayOf(PropTypes.object),
    onClose: PropTypes.func.isRequired,
    room: PropTypes.object,
    userRole: PropTypes.string,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    isSaving: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      isValid: true,
      roomData: pick(props.room, [
        'name',
        'imageUrl',
        'firewordNotificationsEnabled',
        'firewordNotificationsForRoomCoursesOnly',
        'isOpenForRequests',
        'isPrivate',
        'ownerUserId',
      ]),
    }
  }

  render () {
    const {
      isSaving,
      onSave,
      open,
      onClose,
      enablePrivateRooms,
      staff,
      userRole,
      t,
    } = this.props
    const dropdownOptions = staff.length > 0 && staff.map((staff) => { return { text: getDisplayName(staff), value: staff.id } })

    const {
      roomData,
      isValid,
    } = this.state
    return (
      <Modal
        className='create-room-modal'
        closeOnDimmerClick={false}
        open={open}
        onClose={onClose}
      >
        <Modal.Content>
          <Header>Edit Room Details</Header>
          <Page1
            onValidChange={(isValid) => {
              this.setState({
                ...this.state,
                isValid,
              })
            }}
            onChange={(roomData) => {
              this.setState({
                ...this.state,
                roomData,
              })
            }}
            name={roomData.name}
            imageUrl={roomData.imageUrl}
            firewordNotificationsEnabled={roomData.firewordNotificationsEnabled}
            firewordNotificationsForRoomCoursesOnly={roomData.firewordNotificationsForRoomCoursesOnly}
            isOpenForRequests={roomData.isOpenForRequests}
            isPrivate={roomData.isPrivate}
            disablePrivacyChange={true}
            enablePrivateRooms={enablePrivateRooms}
            userDropdownOptions={dropdownOptions}
            ownerUserId={roomData.ownerUserId}
            userRole={userRole}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            onClick={onClose}
          >
            {t('forms.cancel_button')}
          </Button>
          <Button
            content={t('forms.save_button')}
            labelPosition='right'
            loading={isSaving}
            icon='checkmark'
            color='green'
            disabled={!isValid}
            onClick={() => onSave(roomData)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default translate([ 'components' ])(RoomDetailsModal)
