import gql from 'graphql-tag'

const GET_ROOMS_BY_STUDENT_ID = gql`
query roomsByStudentId($userId: String!) {
  roomsByStudentId(
    userId: $userId
  ) 
  {
    id
    name
    imageUrl
    orgId
    ownerUserId
    studentIds
    requestedStudentIds
    courseIds
    firewordNotificationsEnabled
    firewordNotificationsForRoomCoursesOnly
    isOpenForRequests
    isPrivate
    lastActiveDate
    unreviewedFirewordCount
    highestUnreviewedFirewordSeverity
    created
    modified
  }
}
`
export default GET_ROOMS_BY_STUDENT_ID
