import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  Message, Input, Segment, Header, Dropdown,
} from 'semantic-ui-react'
import {
  map,
  flatten,
  intersection,
  find,
  get,
  pick,
  keyBy,
  omitBy,
  isNil,
  flatMap,
  isNumber,
} from 'lodash'

import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import { createAnalyzedAnswer, acknowledgeCreateAnalyzedAnswer } from '../../../../../actions/analyzed-answers'
import { createIgnisRating, acknowledgeCreateIgnisRating } from '../../../../../actions/ignis-ratings'
import { invalidateClientAnswers } from '../../../../../actions/answers'
import {
  getClientWithOverview,
} from '../../../../../actions/clients'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'
import QuestionCard from '../../../../common/question-card'
import Query from '../../../../common/query/query'
import GET_USER_COURSE_INFO from '../../../../../helpers/graphql-queries/get-user-course-info'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay'

const TRANSLATION_PREFIX = 'views.organization.client-details.unreviewed-firewords'

export class UnreviewedFirewordsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    postAnalyzedAnswersState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getClientState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    createAnalyzedAnswer: PropTypes.func.isRequired,
    acknowledgeCreateAnalyzedAnswer: PropTypes.func.isRequired,
    createIgnisRating: PropTypes.func.isRequired,
    acknowledgeCreateIgnisRating: PropTypes.func.isRequired,
    clientId: PropTypes.string.isRequired,
    roomView: PropTypes.bool,
    roomCourses: PropTypes.array,
    roomFirePhraseCaptures: PropTypes.array,
    unreviewedAnswers: PropTypes.arrayOf(PropTypes.object),
    allFirePhraseCaptures: PropTypes.arrayOf(PropTypes.object),
    invalidateClientAnswers: PropTypes.func.isRequired,
    getClientWithOverview: PropTypes.func.isRequired,
    onReview: PropTypes.func,
  }

  static defaultProps = {
    onReview: () => null,
  }

  static path = '/unreviewed-firewords'

  constructor (props) {
    super(props)
    this.state = {
      notes: '',
      filterColor: 0,
    }
  }

  dismissMessage = () => {
    this.props.acknowledgeCreateAnalyzedAnswer()
    this.props.invalidateClientAnswers()
  }

  setNotes = (notes) => {
    this.setState({ notes })
  }

  handleAcknowledgeAllFirewords = (notes, relevantUnreviewedAnswers) => {
    relevantUnreviewedAnswers.forEach((ua) => {
      this.handleAcknowledgeFireword(true, notes, ua.questionId, ua.id, ua.attemptNumber)
    })
    setTimeout(() => {
      this.setState({ notes: '' })
      this.props.onReview()
    }, 500)
  }

  handleAcknowledgeFireword = (isSafe, reason, questionId, answerId, attemptNumber) => {
    const {
      authState: { accessToken },
      clientId,
      createAnalyzedAnswer,
    } = this.props
    createAnalyzedAnswer({
      data: {
        isSafe,
        isSafeReason: reason,
        attemptNumber: attemptNumber,
        _meta: {
          relationships: [ {
            type: 'questions',
            id: questionId,
          } ],
        },
      },
      accessToken,
      clientId,
      answerId,
    })
    setTimeout(() => {
      this.props.onReview()
    }, 500)
  }

  handleRateIgnis = (rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData) => {
    const {
      authState: { accessToken },
      clientId,
      createIgnisRating,
    } = this.props
    analyzedCaptures = analyzedCaptures.map((ac) => ({
      ...pick(ac, [ 'ignisSeverity', 'receptivitiSeverity', 'isSafe', 'sentence' ]), matchedPhrases: [], sentenceTokens: [],
    }))
    analyzedCaptures = analyzedCaptures.map((ac) => (omitBy(ac, isNil)))

    createIgnisRating({
      data: {
        rating,
        questionText,
        questionId,
        sentence,
        answerId,
        analyzedCaptures,
      },
      accessToken,
      clientId,
    })
    refetchStudentData()
  }

  componentWillUnmount () {
    setTimeout(this.dismissMessage.bind(this), 100)
  }

  shouldComponentUpdate (nextProps, nextState) {
    const answerIds = map(this.props.unreviewedAnswers, 'id')
    const nextAnswerIds = map(nextProps.unreviewedAnswers, 'id')
    return (
      this.props.clientId !== nextProps.clientId ||
      this.props.authState.accessToken !== nextProps.authState.accessToken ||
      this.props.getCoursesState.lastUpdated !== nextProps.getCoursesState.lastUpdated ||
      this.props.getCoursesState.error !== nextProps.getCoursesState.error ||
      this.props.postAnalyzedAnswersState.isLoading !== nextProps.postAnalyzedAnswersState.isLoading ||
      this.props.postAnalyzedAnswersState.lastUpdated !== nextProps.postAnalyzedAnswersState.lastUpdated ||
      this.props.postAnalyzedAnswersState.error !== nextProps.postAnalyzedAnswersState.error ||
      this.props.getClientState.lastUpdated !== nextProps.getClientState.lastUpdated ||
      answerIds.length !== nextAnswerIds.length ||
      intersection(answerIds, nextAnswerIds).length !== answerIds.length ||
      this.state.filterColor !== nextState.filterColor
    )
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      postAnalyzedAnswersState,
      getClientWithOverview,
      clientId,
    } = this.props
    if (prevProps.postAnalyzedAnswersState.isLoading && !postAnalyzedAnswersState.isLoading && !postAnalyzedAnswersState.error) {
      getClientWithOverview({ accessToken, id: clientId })
    }
  }

  render () {
    const {
      showFriendlyApiErrorMessages,
      unreviewedAnswers,
      postAnalyzedAnswersState,
      getCoursesState,
      getClientState,
      roomView,
      roomCourses,
      roomFirePhraseCaptures,
      t,
    } = this.props
    const submittedQuestionIds = unreviewedAnswers.map((a) => a.questionId)
    const courses = Object.values(getCoursesState.value || {})
    const client = get(getClientState, 'value')
    const allFirePhraseCaptures = roomFirePhraseCaptures || (client && client.unreviewedFirePhraseCaptures.filter((fpc) => isNumber(fpc.attemptNumber))) || unreviewedAnswers.flatMap((ua) => ua.firePhraseCaptures) || []
    const roomHasCourses = (roomView && roomCourses && !!roomCourses.length)
    const questionIds = map(allFirePhraseCaptures, 'questionId')
    const courseTitlesByQuestionId = {}
    const courseIdsByQuestionId = {}
    const questionTitleByQuestionId = {}
    const questionNotesByQuestionId = {}
    courses.forEach((course) => {
      const questions = course.questions.filter((q) => questionIds.includes(q.id))
      questions.forEach((question) => {
        const matchingContentItem = find(course.contentItems, { questionId: question.id })
        if (matchingContentItem && matchingContentItem.notes) {
          questionNotesByQuestionId[question.id] = matchingContentItem.notes
        }
        courseTitlesByQuestionId[question.id] = course.titles
        courseIdsByQuestionId[question.id] = course.id
        questionTitleByQuestionId[question.id] = question.title
      })
    })

    const courseIds = courses.map((c) => c.id)
    return (
      <Query
        variables={{
          clientId: this.props.clientId,
          courseIds,
        }}
        query={GET_USER_COURSE_INFO}
        fetchPolicy='network-only'
        onCompleted={() => {
          this.setState({
            ...this.state,
          })
        }}
      >
        {({
          loading: studentDataLoading,
          data: studentData,
          refetch: refetchStudentData,
        }) => {
          if (studentDataLoading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const user = get(studentData, 'userCourseDetails[0]') || {}
          const ratingsByAnswerId = keyBy(user.ignisRatings, 'answerId')
          const colorMap = [ 'All', 'Yellow', 'Orange', 'Red' ]
          const allRelevantSeverities = unreviewedAnswers.map((ua) => {
            const relevantSeverities = flatMap(ua.firePhraseCaptures || [], (capture) => {
              return capture.analyzedCaptures.map((analyzedCapture) => (analyzedCapture.ignisSeverity && analyzedCapture.ignisSeverity) || (analyzedCapture.receptivitiSeverity > 0 && analyzedCapture.receptivitiSeverity) || 3)
            })
            return Math.max(...relevantSeverities)
          },
          )
          const dropdownOptions = [
            { text: colorMap[0], value: 0 },
            {
              text: colorMap[3], value: 3, disabled: !allRelevantSeverities.includes(3),
            },
            {
              text: colorMap[2], value: 2, disabled: !allRelevantSeverities.includes(2),
            },
            {
              text: colorMap[1], value: 1, disabled: !allRelevantSeverities.includes(1),
            },

          ]
          const colorText = this.state.filterColor > 0 ? colorMap[this.state.filterColor] + ' ' : ''
          const relevantUnreviewedAnswers = unreviewedAnswers.filter((_, index) => {
            return (this.state.filterColor === 0 || this.state.filterColor === allRelevantSeverities[index])
          },
          )
          return (
            <React.Fragment>
              <Message warning>
                <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.message_header`)}</Message.Header>
                <p data-public>{t(`${TRANSLATION_PREFIX}.message_description_part_1`)}</p>
                <p data-public>{t(`${TRANSLATION_PREFIX}.message_description_part_2`)}</p>
                <p data-public>{t(`${TRANSLATION_PREFIX}.message_description_part_3`)}</p>
              </Message>
              {unreviewedAnswers.length < 1 && allFirePhraseCaptures.length < 1 && <Message positive>
                <Message.Header>{t(`${TRANSLATION_PREFIX}.no_review_header`)}</Message.Header>
                <p>{t(`${TRANSLATION_PREFIX}.no_review_description`)}</p>
              </Message>}
              <FetchResultMessage
                itemType='fireword acknowledgement'
                success={false}
                error={postAnalyzedAnswersState.error}
                showFriendlyError={showFriendlyApiErrorMessages}
                onDismiss={this.dismissMessage}
              />
              <div>
                {relevantUnreviewedAnswers.length > 0 && <Header data-public>Filter Firewords:
                  <Dropdown
                    data-public
                    options={dropdownOptions}
                    value={this.state.filterColor}
                    style={{ marginLeft: 10 }}
                    onChange={(_, e) => this.setState({ filterColor: e.value })}
                  />
                </Header>}
              </div>
              {relevantUnreviewedAnswers.length > 0 && (
                <Segment color='yellow'>
                  <Header data-public>Review ALL {colorText} Firewords</Header>
                  <p data-public className='text italic'>This is an optional way to review and add notes to all the unreviewed Firewords at once. Please make sure you have read all of them before using this action.</p>
                  <Input
                    fluid
                    action={{
                      loading: postAnalyzedAnswersState.isLoading,
                      labelPosition: 'right',
                      icon: 'check',
                      content: 'Mark ALL ' + colorText + 'as Reviewed',
                      onClick: () => {
                        const confirm = window.confirm(`Are you sure you would like to mark all (${relevantUnreviewedAnswers.length}) of these Firewords as "Reviewed"?`)
                        if (confirm) {
                          this.handleAcknowledgeAllFirewords(this.state.notes || 'N/A', relevantUnreviewedAnswers)
                        }
                      },
                    }}
                    onChange={(e, data) => this.setNotes(data.value)}
                    placeholder={'Write Optional Notes for ALL ' + colorText + ' Firewords Here'}
                  />
                </Segment>
              )}
              {relevantUnreviewedAnswers.map((ua) => {
                if (roomHasCourses && !roomCourses.includes(courseIdsByQuestionId[ua.questionId])) {
                  return null
                }
                return (
                  <QuestionCard
                    key={`${ua.questionId}-${ua.id}`}
                    isLoading={postAnalyzedAnswersState.isLoading}
                    isSaving={postAnalyzedAnswersState.isLoading}
                    questionText={questionTitleByQuestionId[ua.questionId]}
                    answerText={ua.answerText}
                    courseTitle={courseTitlesByQuestionId[ua.questionId]}
                    attemptNumber={ua.attemptNumber}
                    notes={questionNotesByQuestionId[ua.questionId]}
                    answerTimestamp={ua.created.length === 13 ? new Date(parseInt(ua.created)) : new Date(ua.created)}
                    analyzedCaptures={flatten(map(ua.firePhraseCaptures, 'analyzedCaptures'))}
                    onReviewedClick={(reason) => this.handleAcknowledgeFireword(true, reason, ua.questionId, ua.id, ua.attemptNumber)}
                    previousRating={ratingsByAnswerId[ua.id]}
                    onIgnisRate={(rating) => this.handleRateIgnis(rating, questionTitleByQuestionId[ua.questionId], ua.questionId, ua.answerText, ua.id, flatten(map(ua.firePhraseCaptures, 'analyzedCaptures')), refetchStudentData)}
                  />
                )
              },
              )}
              {allFirePhraseCaptures.map((ua) => {
                if (submittedQuestionIds.includes(ua.questionId)) {
                  return null
                }
                if (roomHasCourses && !roomCourses.includes(courseIdsByQuestionId[ua.questionId])) {
                  return null
                }
                const answerText = ua.answerText || ua.analyzedCaptures[0].sentence
                return (
                  <QuestionCard
                    key={`${ua.questionId}-${ua.id}`}
                    isLoading={postAnalyzedAnswersState.isLoading}
                    isSaving={postAnalyzedAnswersState.isLoading}
                    questionText={questionTitleByQuestionId[ua.questionId]}
                    unsubmitted={true}
                    courseTitle={courseTitlesByQuestionId[ua.questionId]}
                    attemptNumber={ua.attemptNumber}
                    notes={questionNotesByQuestionId[ua.questionId]}
                    answerTimestamp={ua.created.length === 13 ? new Date(parseInt(ua.created)) : new Date(ua.created)}
                    analyzedCaptures={map(ua.analyzedCaptures)}
                    onReviewedClick={(reason) => this.handleAcknowledgeFireword(true, reason, ua.questionId, ua.id, ua.attemptNumber)}
                    previousRating={ratingsByAnswerId[ua.id]}
                    onIgnisRate={(rating) => this.handleRateIgnis(rating, questionTitleByQuestionId[ua.questionId], ua.questionId, answerText, ua.id, flatten(map(ua.firePhraseCaptures, 'analyzedCaptures')), refetchStudentData)}
                  />
                )
              },
              )}
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    analyzedAnswers: {
      [FETCH_ACTIONS.POST]: postAnalyzedAnswersState,
    },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    clients: { [FETCH_ACTIONS.GET]: getClientState },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    postAnalyzedAnswersState,
    showFriendlyApiErrorMessages,
    getCoursesState,
    getClientState,
  }
}
const mapDispatchToProps = {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
  createIgnisRating,
  acknowledgeCreateIgnisRating,
  invalidateClientAnswers,
  getClientWithOverview,
}
const UnreviewedFirewordsViewContainer = connect(mapStateToProps, mapDispatchToProps)(UnreviewedFirewordsView)

export default translate([ 'components' ])(UnreviewedFirewordsViewContainer)
