import gql from 'graphql-tag'

export const NAME = 'deleteUserNotificationSettingsByOrgId'
const DELETE_NOTIFICATION_SETTINGS_BY_ORG = gql`
mutation ${NAME}($orgId: ID!) {
  ${NAME}(orgId: $orgId)
}
`

export default DELETE_NOTIFICATION_SETTINGS_BY_ORG
