import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'
import { get } from 'lodash'

import Mutation from '../../common/mutation/mutation'

import UPDATE_ORG_ACTIVE_STATUS from '../../../helpers/graphql-queries/update-org-active-status'

const UpdateOrganizationActiveStatus = ({
  organizationId,
  isActive,
  disabled,
}) => (
  <Mutation
    mutation={UPDATE_ORG_ACTIVE_STATUS}
  >
    {(updateOrganizationActiveStatus, { loading, data }) => {
      const activeStatus = get(data, 'updateOrganizationActiveStatus.isActive', isActive)
      return (
        <Button
          basic
          size='tiny'
          loading={loading}
          disabled={disabled || loading}
          color={(!activeStatus) ? 'red' : 'green'}
          title={(!activeStatus) ? 'Organization is INACTIVE and users in this organization will NOT be able to log in' : 'Organization is ACTIVE and users in this organization will be able to log in'}
          onClick={() => updateOrganizationActiveStatus({ variables: { organizationId, activeStatus: !activeStatus } })}
        >
          <Icon name={(!activeStatus) ? 'ban' : 'check'} />
          {(!activeStatus) ? 'INACTIVE' : 'ACTIVE'}
        </Button>
      )
    }}
  </Mutation>
)

UpdateOrganizationActiveStatus.propTypes = {
  organizationId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
}

UpdateOrganizationActiveStatus.defaultProps = {
  isActive: true,
  disabled: false,
}

export default UpdateOrganizationActiveStatus
