import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Icon, Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import './message-modal.css'

const TRANSLATION_PREFIX = 'common.message-modal'

const MessageModal = ({
  message,
  open,
  onCloseClick,
  t,
}) => {
  if (!message) {
    return null
  }
  return (
    <Modal
      className='messageModal'
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size='large'
      // size='fullscreen'  change back to this when semantic-ui-css v2.4.2 is published
    >
      <Modal.Header data-public><Icon size='big' className='base-blue' name='info circle'></Icon> {message.header}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description data-public>
          {message.content}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button data-public as='a' size='tiny' className='base-blue-bg text white' href='mailto:basesupport@7mindsets.com'>{t(`${TRANSLATION_PREFIX}.need_help`)}</Button>
        <Button data-public size='tiny' className='base-green-bg text white' icon='thumbs up' content={t(`${TRANSLATION_PREFIX}.got_it`)} onClick={onCloseClick}/>
      </Modal.Actions>
    </Modal>
  )
}

MessageModal.propTypes = {
  message: PropTypes.object,
  open: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

MessageModal.defaultProps = {
  open: false,
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(MessageModal)
