import { get, isArray } from 'lodash'

const QUOTE_COMMA_REGEX = /[,"]/
const GLOBAL_QUOTE_REGEX = /"/g
const ESCAPED_QUOTE = '""'
const NEW_LINE = '\r\n'

export default function exportToCsv (fileName, items, fields = []) {
  if (!fileName || !isArray(items) || !items.length) {
    return
  }
  fields = (!fields || !fields.length) ? Object.keys(items[0]) : fields
  const csvContent = 'data:text/csv;charset=utf-8,'
  const headers = fields.map((field) => {
    if (QUOTE_COMMA_REGEX.test(field)) {
      field = '"' + field.replace(GLOBAL_QUOTE_REGEX, ESCAPED_QUOTE) + '"'
    }
    return field
  }).join(',') + NEW_LINE
  const lines = items.map((item) => {
    const values = fields.map((field) => {
      let value = (get(item, field) ?? '').toString()
      if (QUOTE_COMMA_REGEX.test(value)) {
        value = '"' + value.replace(GLOBAL_QUOTE_REGEX, ESCAPED_QUOTE) + '"'
      }
      return value
    })
    return values.join(',')
  }).join(NEW_LINE)

  const encodedUri = csvContent + encodeURIComponent(headers + lines)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  // convert whitespace to dashes and remove all other special characters from file name
  link.setAttribute('download', fileName.replace(/\s/g, '-').replace(/[^-_'a-z0-9]/gi, '').replace(/-{2,}/g, '-'))
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link)
}
