import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Icon,
  List,
  Button,
  Grid,
  Modal,
  Popup,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { sortBy } from 'lodash'

import PutOrganizationForm from '../put-organization/put-organization'
import DebouncedInput from '../../common/debounced-input'

import './manage-suborganizations.css'

const TRANSLATION_PREFIX = 'forms.manage-suborganizations'
const SHOW_MORE_INCREMENT = 50

const markOrg = (searchText, organization) => {
  const showChild = (!organization.children) ? false : !!organization.children.filter(markOrg.bind(null, searchText)).length
  organization.show = (!searchText || organization.name.toLowerCase().includes(searchText) || showChild)
  return organization.show
}

export class ManageSuborganizations extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    suborganizations: PropTypes.arrayOf(PropTypes.object),
    parentOrganization: PropTypes.object.isRequired,
    courses: PropTypes.arrayOf(PropTypes.object),
    onCreate: PropTypes.func.isRequired,
    onToggleActiveStatus: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    disableModifications: PropTypes.bool,
    canCreateCustomer: PropTypes.bool,
    canCreateSite: PropTypes.bool,
    canCreateIntegrationOrgs: PropTypes.bool,
    canCreateGroups: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onCreate: console.log.bind(console, 'onCreate'),
    onDelete: console.log.bind(console, 'onDelete'),
    onToggleActiveStatus: console.log.bind(console, 'onToggleActiveStatus'),
    courses: [],
    isLoading: false,
    suborganizations: [],
    disableModifications: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      showModal: false,
      currentParentId: null,
      currentParentName: null,
      orgType: null,
      searchText: '',
      showCount: SHOW_MORE_INCREMENT,
    }
    this.renderCount = { count: 0, hasMore: false }
  }

  handleShowCreateOrgModal = (currentParentId, currentParentName, orgType) => {
    this.setState({
      ...this.state,
      showModal: true,
      currentParentId,
      currentParentName,
      orgType,
    })
  }

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
      currentParentId: null,
      currentParentName: null,
      orgType: null,
    })
  }

  renderSuborganizations = (suborganizations, parentIsLocked) => {
    const {
      onDelete,
      onToggleActiveStatus,
      disableModifications,
      t,
    } = this.props
    if (!suborganizations || !suborganizations.length) {
      return null
    }
    return (
      <List>
        {sortBy(suborganizations, 'name').map((child) => {
          if (child.show) {
            this.renderCount.count += 1
          } else {
            return null
          }
          if (this.renderCount.count > this.state.showCount) {
            this.renderCount.hasMore = true
            return null
          } else {
            this.renderCount.hasMore = false
          }
          const isCustomer = !!child.contractStartDate
          const isSite = !!child.type && !isCustomer
          const icon = (isCustomer) ? 'address card' : (isSite) ? 'building' : 'users'
          return (
            <List.Item key={child.id}>
              <Grid>
                <Grid.Column width={10}>
                  <Header as='h4'>
                    <Icon name={icon} />
                    <Header.Content>{child.name} <span style={{ fontSize: '.8em', color: '#aaaaaa' }} className='text italic'>(ID: {child.id})</span></Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign='right'>
                  <Button
                    data-public
                    size='mini'
                    basic
                    color={(child.isActive) ? 'green' : 'red'}
                    onClick={() => onToggleActiveStatus(child.id, !child.isActive)}
                  >
                    <Icon name={(child.isActive) ? 'check' : 'ban'} />
                    {(child.isActive) ? 'Active' : 'Inactive'}
                  </Button>
                  {!disableModifications && (
                    <Button
                      size='mini'
                      color='red'
                      icon='remove'
                      disabled={parentIsLocked || child.isLocked || (child.children && !!child.children.length)}
                      onClick={() => {
                        const areYouSure = window.prompt(t(`${TRANSLATION_PREFIX}.delete_confirm`, { name: child.name }))
                        if (areYouSure !== 'delete') {
                          window.alert('Cancelling')
                          return
                        }
                        onDelete(child.id)
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
              {this.renderSuborganizations(child.children, child.isLocked)}
            </List.Item>
          )
        })}
      </List>
    )
  }

  renderCreateOrgModal = (showModal, currentParentId, currentParentName) => {
    const {
      onCreate,
      isLoading,
      courses,
      canCreateCustomer,
      parentOrganization,
      canCreateIntegrationOrgs,
    } = this.props
    const {
      orgType,
    } = this.state
    const icon = (orgType === 'customer') ? 'address card' : (orgType === 'site') ? 'building' : 'users'
    return (
      <Modal
        open={showModal}
        onClose={this.handleCloseModal}
      >
        <Header icon={icon} content={`Create a ${orgType} under: ${currentParentName}`} />
        <Modal.Content>
          <PutOrganizationForm
            onSave={(data) => {
              const organization = {
                parentId: currentParentId,
                ...data,
                sessionsEnabled: false,
                roomsEnabled: true,
                // since these are not available to set on create, just inherit the parent's settings
                baselineDefaultMinDays: parentOrganization.baselineDefaultMinDays,
                baselineStartingPoints: parentOrganization.baselineStartingPoints,
                assessmentsEnabled: parentOrganization.assessmentsEnabled,
                baselineAnalysisEnabled: parentOrganization.baselineAnalysisEnabled,
                baselineRecommendationsEnabled: parentOrganization.baselineRecommendationsEnabled,
                firewordsEnabled: parentOrganization.firewordsEnabled,
                privateRoomsEnabled: parentOrganization.privateRoomsEnabled,
                requireRoomFirewords: parentOrganization.requireRoomFirewords,
              }
              onCreate(organization, data.orgSource)
              this.setState({
                showModal: false,
                currentParentId: null,
                currentParentName: null,
                orgType: null,
              })
            }}
            isLoading={isLoading}
            courses={courses}
            courseIds={parentOrganization.courseIds}
            orgType={orgType}
            hideHeader={true}
            canUpdateCourses={true}
            studentAccessSchedule={parentOrganization.studentAccessSchedule || undefined}
            region={(canCreateCustomer) ? undefined : parentOrganization.region}
            country={(canCreateCustomer) ? undefined : parentOrganization.country}
            canCreateIntegrationOrgs={canCreateIntegrationOrgs}
          />
        </Modal.Content>
      </Modal>
    )
  }

  render () {
    this.renderCount = { count: 0, hasMore: false }
    const {
      isLoading,
      parentOrganization,
      disableModifications,
      canCreateCustomer,
      canCreateSite,
      canCreateGroups,
      t,
    } = this.props
    const {
      showModal,
      currentParentId,
      currentParentName,
      searchText,
      showCount,
    } = this.state
    const hasSuborganizations = (parentOrganization && parentOrganization.children && !!parentOrganization.children.length)
    markOrg(searchText, parentOrganization)
    // const icon = (isCustomer) ? 'address card' : (isSite) ? 'building' : 'users'
    return (
      <Segment
        className='manage-suborganizations-form'
        placeholder={!hasSuborganizations}
        loading={isLoading}
      >
        {this.renderCreateOrgModal(showModal, currentParentId, currentParentName)}
        {!hasSuborganizations && (
          <React.Fragment>
            <Header data-public icon>
              <Icon name='building' />
              {(!canCreateCustomer && !canCreateSite) ? t(`${TRANSLATION_PREFIX}.no_groups`) : (!canCreateCustomer) ? t(`${TRANSLATION_PREFIX}.no_sites_or_groups`) : t(`${TRANSLATION_PREFIX}.no_customers_or_groups`)}
              <Popup
                hoverable
                trigger={(
                  <Icon
                    style={{
                      display: 'inline-block',
                      marginLeft: 10,
                      marginBottom: 2,
                      fontSize: '1.2em',
                      cursor: 'help',
                    }}
                    name='question circle'
                    color='blue'
                    size='small'
                  />
                )}
                wide='very'
                position='top center'
              >
                <p data-public>{(!canCreateCustomer && !canCreateSite) ? t(`${TRANSLATION_PREFIX}.help.no_groups`) : (!canCreateCustomer) ? t(`${TRANSLATION_PREFIX}.help.no_sites_or_groups`) : t(`${TRANSLATION_PREFIX}.help.no_customers_or_groups`)}</p>
                <p data-public>{t(`${TRANSLATION_PREFIX}.help.support`)}</p>
              </Popup>
            </Header>
            {!disableModifications && (
              <Grid columns={(!canCreateCustomer && !canCreateSite) ? 1 : (!canCreateCustomer || !canCreateSite) ? 2 : 3} stackable textAlign='center'>
                <Grid.Row verticalAlign='middle'>
                  {(canCreateCustomer) && (
                    <Grid.Column>
                      <Button
                        data-public
                        fluid
                        color='blue'
                        disabled={parentOrganization.isLocked}
                        onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'customer')}
                      >
                        <Icon name='address card' /> Create a Customer
                      </Button>
                    </Grid.Column>
                  )}
                  {(canCreateSite) && (
                    <Grid.Column>
                      <Button
                        data-public
                        fluid
                        color='blue'
                        disabled={parentOrganization.isLocked}
                        onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'site')}
                      >
                        <Icon name='building' /> Create a Site
                      </Button>
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      color='blue'
                      disabled={parentOrganization.isLocked}
                      onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'group')}
                    >
                      <Icon name='users' /> Create a Group
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

            // <React.Fragment>
            //   <Button
            //     data-public
            //     color='blue'
            //     disabled={parentOrganization.isLocked}
            //     onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name)}
            //   >
            //     {t(`${TRANSLATION_PREFIX}.add`)}
            //   </Button>
            //   <Button
            //     data-public
            //     color='blue'
            //     disabled={parentOrganization.isLocked}
            //     onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name)}
            //   >
            //     {t(`${TRANSLATION_PREFIX}.add`)}
            //   </Button>
            //   <Button
            //     data-public
            //     color='blue'
            //     disabled={parentOrganization.isLocked}
            //     onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name)}
            //   >
            //     {t(`${TRANSLATION_PREFIX}.add`)}
            //   </Button>
            // </React.Fragment>
            )}
          </React.Fragment>
        )}
        {hasSuborganizations && (
          <React.Fragment>
            <Header data-public>{t(`${TRANSLATION_PREFIX}.header`)}
              {/* {!disableModifications && (
                <Popup
                  hoverable
                  trigger={(
                    <Icon
                      style={{
                        float: 'right',
                        margin: 0,
                        fontSize: '1.2em',
                        cursor: 'help',
                      }}
                      name='question circle'
                      color='blue'
                      size='small'
                    />
                  )}
                  wide='very'
                  position='left center'
                >
                  <Header as='h5'>{t(`${TRANSLATION_PREFIX}.help.adding_header`)}</Header>
                  <p>{t(`${TRANSLATION_PREFIX}.help.adding_description`)}</p>
                  <Header as='h5'>{t(`${TRANSLATION_PREFIX}.help.deleting_header`)}</Header>
                  <p>{t(`${TRANSLATION_PREFIX}.help.deleting_description`)}</p>
                </Popup>
              )} */}
            </Header>
            <DebouncedInput
              data-public
              size='small'
              fluid
              icon={!!searchText && (
                <Icon
                  name='close'
                  color='grey'
                  inverted
                  circular
                  link
                  onClick={() => {
                    this.setState({ ...this.state, searchText: '' })
                  }}
                  style={{
                    marginRight: 34, marginTop: 5, fontSize: '.7em',
                  }}
                />
              )}
              action={{
                basic: true,
                size: 'tiny',
                color: 'grey',
                icon: 'search',
                disabled: true,
              }}
              placeholder='Search Organizations...'
              value={searchText}
              wait={300}
              onChange={(value) => {
                this.setState({ ...this.state, searchText: value.toLowerCase() })
              }}
            />
            {this.renderSuborganizations(parentOrganization.children, parentOrganization.isLocked)}
            {(this.renderCount.hasMore) && (
              <div style={{ textAlign: 'center', marginBottom: 15 }}>
                <Button basic size='mini' onClick={() => {
                  this.setState({ ...this.state, showCount: showCount + SHOW_MORE_INCREMENT })
                }}>Show More...</Button>
              </div>
            )}
            {!disableModifications && (
              <Grid columns={(!canCreateCustomer && !canCreateSite) ? 1 : (!canCreateCustomer || !canCreateSite) ? 2 : 3}>
                {(canCreateCustomer) && (
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      color='blue'
                      disabled={parentOrganization.isLocked}
                      onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'customer')}
                    >
                      <Icon name='address card' /> {t(`${TRANSLATION_PREFIX}.add_another_customer`)}
                    </Button>
                  </Grid.Column>
                )}
                {(canCreateSite) && (
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      color='blue'
                      disabled={parentOrganization.isLocked}
                      onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'site')}
                    >
                      <Icon name='building' /> {t(`${TRANSLATION_PREFIX}.add_another_site`)}
                    </Button>
                  </Grid.Column>
                )}
                {(canCreateGroups) && (
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      color='blue'
                      disabled={parentOrganization.isLocked}
                      onClick={this.handleShowCreateOrgModal.bind(this, parentOrganization.id, parentOrganization.name, 'group')}
                    >
                      <Icon name='users' /> {t(`${TRANSLATION_PREFIX}.add_another_group`)}
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            )}
          </React.Fragment>
        )}
      </Segment>
    )
  }
}

export default translate([ 'components' ])(ManageSuborganizations)
