import React from 'react'
import PropTypes from 'prop-types'
import {
  cloneDeep, remove, isArray, isObject, isString,
} from 'lodash'

import Mutation from '../../common/mutation/mutation'
import ManageSuborganizationsForm from '../../forms/manage-suborganizations/manage-suborganizations'
import CREATE_ORG from '../../../helpers/graphql-queries/create-org'
import CREATE_CLEVER_ORG from '../../../helpers/graphql-queries/create-clever-org'
import CREATE_CLASSLINK_ORG from '../../../helpers/graphql-queries/create-classlink-org'
import DELETE_ORG from '../../../helpers/graphql-queries/delete-org'
import GET_ORG from '../../../helpers/graphql-queries/get-org'
import UPDATE_ORG_ACTIVE_STATUS from '../../../helpers/graphql-queries/update-org-active-status'

const findOrg = (org, id) => {
  if (org.id === id) {
    return org
  }
  if (!org.children) {
    return null
  }
  let matchingOrg = null
  org.children.forEach((child) => {
    if (matchingOrg) {
      return
    }
    matchingOrg = findOrg(child, id)
  })
  return matchingOrg
}

// TODO: Don't use this if we don't have to at some point!!!
// This is so hacky, but for some reason when reading from the cache,
// some objects are missing their __typename field. This
// method makes a REALLY dumb assumption about what the value
// of that field should be for each nested object based on the key names
const hydrateWithTypename = (obj, type) => {
  if (!isString(type)) {
    throw new Error(`Invalid type: ${type}`)
  }
  if (isArray(obj)) {
    obj.forEach((val) => hydrateWithTypename(val, type.replace(/s$/, '')))
  } else if (isObject(obj)) {
    if (!obj.__typename) {
      obj.__typename = type
    }
    Object.keys(obj).forEach((key) => {
      hydrateWithTypename(obj[key], key[0].toUpperCase() + key.substr(1))
    })
  }
}

const ManageSuborganizations = ({
  org,
  courses,
  disableModifications,
  canCreateCustomer,
  canCreateSite,
  canCreateIntegrationOrgs,
  canCreateGroups,
  refectchChildOrgList,
}) => {
  const addToOrgCache = (queryName) => (cache, { data }) => {
    const variables = { organizationId: org.id, childrenDepth: 10 }
    const { organization: cachedOrganization } = cache.readQuery({ query: GET_ORG, variables })
    const organization = cloneDeep(cachedOrganization)
    const matchingParentOrg = findOrg(organization, data[queryName].parentId)
    if (matchingParentOrg) {
      matchingParentOrg.children = matchingParentOrg.children || []
      matchingParentOrg.children.push(data[queryName])
      hydrateWithTypename(organization, 'Organization')
      cache.writeQuery({
        query: GET_ORG,
        variables,
        data: { organization },
      })
      if (refectchChildOrgList) {
        refectchChildOrgList()
      }
    }
  }
  const removeOrgFromCache = (cache, { data: { deleteOrganization: deletedOrgId } }) => {
    const variables = { organizationId: org.id, childrenDepth: 10 }
    const { organization: cachedOrganization } = cache.readQuery({ query: GET_ORG, variables })
    const organization = cloneDeep(cachedOrganization)
    const matchingOrg = findOrg(organization, deletedOrgId)
    const matchingParentOrg = findOrg(organization, matchingOrg.parentId)
    if (matchingParentOrg) {
      remove(matchingParentOrg.children, { id: deletedOrgId })
      hydrateWithTypename(organization, 'Organization')
      cache.writeQuery({
        query: GET_ORG,
        variables,
        data: { organization },
      })
    }
    if (refectchChildOrgList) {
      refectchChildOrgList()
    }
  }
  return (
    <Mutation
      mutation={CREATE_ORG}
      update={addToOrgCache('createOrganization')}
    >
      {(createOrganization, { loading: createIsLoading }) => {
        return (
          <Mutation
            mutation={CREATE_CLEVER_ORG}
            update={addToOrgCache('createCleverOrganization')}
          >
            {(createCleverOrganization, { loading: createCleverIsLoading }) => {
              return (
                <Mutation
                  mutation={CREATE_CLASSLINK_ORG}
                  update={addToOrgCache('createClasslinkOrganization')}
                >
                  {(createClasslinkOrganization, { loading: createClasslinkIsLoading }) => {
                    return (
                      <Mutation
                        mutation={DELETE_ORG}
                        update={removeOrgFromCache}
                      >
                        {(deleteOrganization, { loading: deleteIsLoading }) => {
                          return (
                            <Mutation
                              mutation={UPDATE_ORG_ACTIVE_STATUS}
                            >
                              {(updateOrganizationActiveStatus, { loading: activeStatusLoading }) => {
                                return (
                                  <ManageSuborganizationsForm
                                    parentOrganization={org}
                                    isLoading={createIsLoading || createCleverIsLoading || createClasslinkIsLoading || deleteIsLoading || activeStatusLoading}
                                    courses={courses}
                                    disableModifications={disableModifications}
                                    onCreate={(organization, orgSource) => {
                                      if (orgSource === 'base') {
                                        createOrganization({
                                          variables: {
                                            organization: {
                                              ...organization,
                                              orgSource: undefined,
                                            },
                                          },
                                        })
                                      } else if (orgSource === 'clever') {
                                        createCleverOrganization({
                                          variables: {
                                            organization: {
                                              ...organization,
                                              districtId: organization.name,
                                              name: undefined,
                                              orgSource: undefined,
                                            },
                                          },
                                        })
                                      } else if (orgSource === 'classlink') {
                                        createClasslinkOrganization({
                                          variables: {
                                            organization: {
                                              ...organization,
                                              tenantId: organization.name,
                                              name: undefined,
                                              orgSource: undefined,
                                            },
                                          },
                                        })
                                      }
                                    }}
                                    onDelete={(organizationId) => deleteOrganization({ variables: { organizationId } })}
                                    onToggleActiveStatus={(organizationId, activeStatus) => updateOrganizationActiveStatus({ variables: { organizationId, activeStatus } })}
                                    canCreateCustomer={canCreateCustomer}
                                    canCreateSite={canCreateSite}
                                    canCreateIntegrationOrgs={canCreateIntegrationOrgs}
                                    canCreateGroups={canCreateGroups}
                                  />
                                )
                              }}
                            </Mutation>
                          )
                        }}
                      </Mutation>
                    )
                  }}
                </Mutation>
              )
            }}
          </Mutation>
        )
      }}
    </Mutation>
  )
}

ManageSuborganizations.propTypes = {
  org: PropTypes.object.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableModifications: PropTypes.bool,
  canCreateCustomer: PropTypes.bool,
  canCreateSite: PropTypes.bool,
  canCreateIntegrationOrgs: PropTypes.bool,
  canCreateGroups: PropTypes.bool,
  refectchChildOrgList: PropTypes.func,
}

ManageSuborganizations.defaultProps = {
}

export default ManageSuborganizations
