import React, { useState } from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { get, defer } from 'lodash'
import to from 'await-to-js'
import { Message, Icon } from 'semantic-ui-react'
import LogRocket from 'logrocket'

import GraphqlErrorMessage from '../graphql-error-message/graphql-error-message'

// This wrapper helps facilitate showing errors on fetchMore (without re-rendering the original query), and retrying fetchMore after an error
const InfiniteScrollWrapper = ({
  children,
  hasMore,
  loadMore,
  ...rest
}) => {
  const [ error, setError ] = useState(null)
  const loadMoreWrapper = async () => {
    const [ err ] = await to(loadMore())
    if (err) {
      console.error(err)
      LogRocket.captureException(new Error('GraphQL API Error'))
      setError(err)
    } else if (error && !err) {
      setError(null)
    }
  }
  return (
    <InfiniteScroll
      {...rest}
      hasMore={(error) ? false : hasMore}
      loadMore={loadMoreWrapper}
    >
      {children}
      {(!!error) && (
        <Message
          icon
          negative
          onDismiss={() => {
            setError(null)
            defer(loadMoreWrapper)
          }}>
          <Icon name='warning' />
          <Message.Content>
            <Message.Header data-public>
            There was a problem loading the requested data.
            </Message.Header>
            <p data-public>There were more items that we tried to load but were not successful. Close this message and we will try to load them again. If the issue persists, please let us know at basesupport@7mindsets.com</p>
          </Message.Content>
        </Message>
      )}
    </InfiniteScroll>
  )
}

InfiniteScrollWrapper.propTypes = {
  children: PropTypes.any,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
}

const Query = ({
  infiniteScroll,
  children,
  ignoreError,
  ...rest
}) => {
  return (
    <ApolloQuery
      errorPolicy='all'
      {...rest}
    >
      {(result) => {
        const queryName = get(rest, 'query.definitions[0].selectionSet.selections[0].name.value', 'unknown')
        const type = get(rest, 'query.definitions[0].selectionSet.selections[0].selectionSet.selections', []).find((sel) => ![ 'endCursor', 'hasNextPage' ].includes(sel.name.value))
        const typeName = (type) ? get(type, 'name.value') : 'none'
        if (!typeName) {
          throw new Error('There is no data selected in the query. Please make sure the query is selecting the field that contains the array of data associated with this paged query.')
        }
        const endCursor = get(result, `data.${queryName}.endCursor`, null)
        const hasMore = get(result, `data.${queryName}.hasNextPage`, false)
        // const values = get(result, `data.${queryName}.${typeName}`, [])
        return (
          <>
            <GraphqlErrorMessage error={result.error} ignoreError={ignoreError}/>
            {(infiniteScroll && typeName !== 'none') && (
              <InfiniteScrollWrapper
                pageStart={0}
                threshold={100}
                loader={<div className='loader' key={0}>Loading ...</div>}
                {...infiniteScroll}
                loadMore={() => {
                  return result.fetchMore({
                    query: rest.query,
                    variables: {
                      ...rest.variables,
                      after: endCursor,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      return {
                        [queryName]: {
                          ...fetchMoreResult[queryName],
                          [typeName]: [ ...previousResult[queryName][typeName], ...fetchMoreResult[queryName][typeName] ],
                        },
                      }
                    },
                  })
                }}
                hasMore={hasMore}
              >
                {children(result)}
              </InfiniteScrollWrapper>
            )}
            {(!infiniteScroll) && (
              children(result)
            )}
          </>
        )
      }}
    </ApolloQuery>
  )
}

Query.propTypes = {
  children: PropTypes.any,
  ignoreError: PropTypes.bool,
  infiniteScroll: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
}

export default Query
