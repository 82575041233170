import gql from 'graphql-tag'

export const NAME = 'createRoom'
const CREATE_ROOM = gql`
mutation ${NAME}($room: CreateRoomInput!) {
  ${NAME}(room: $room) {
    id
    name
    imageUrl
    orgId
    ownerUserId
    studentIds
    requestedStudentIds
    courseIds
    firewordNotificationsEnabled
    firewordNotificationsForRoomCoursesOnly
    isOpenForRequests
    isPrivate
    created
    modified
  }
}
`

export default CREATE_ROOM
