import React, {
  useEffect, useState, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { ResponsiveBar } from '@nivo/bar'
import {
  Message,
  Header,
  Icon,
  Popup,
} from 'semantic-ui-react'
import {
  flatten,
  extend,
  floor,
  sum,

} from 'lodash'

const PROGRESS_COLORS = [
  '#de5626',
  '#f8bf00',
  '#90ad43',
  '#0892a5',
  '#71bbdb',
]

const BaselineMeanScoresByDomain = ({
  height,
  chartData,
}) => {
  const CustomTooltip = (data) => {
    const isMaxDomain = data.id === '5'
    const studentCount = data.data[`${data.id}StudentCount`]
    const headerText = isMaxDomain ? `${studentCount} respondent${studentCount > 1 ? `s` : ``} (${floor(data.value, 2)}% of your organization) ${studentCount > 1 ? `have` : `has`} a perfect score in ${data.data.domain}` : `${studentCount} respondent${studentCount > 1 ? `s` : ``} (${floor(data.value, 2)}% of your organization) ${studentCount > 1 ? `have` : `has`} a score within the ${data.id} range in ${data.data.domain}`
    return (
      <Message
        size='mini'
        icon='info circle'
        header={headerText}
      />
    )
  }
  const barChartData = chartData.toReversed().map((attempt) => {
    return flatten(attempt).map((domain) => {
      const relevantBuckets = domain.buckets
      return flatten(relevantBuckets.map((b, idx) => ({
        [`${idx + 1}`]: (b / sum(relevantBuckets)) * 100,
        [`${idx + 1}Color`]: PROGRESS_COLORS[idx],
        [`${idx + 1}StudentCount`]: b,
        attempt: domain.key,
        domain: domain.id,
      })))
    },
    )
  })
  const colorBy = ({ id, data }) => data[`${id}Color`]

  const formattedBarChartData = barChartData.map((attempt) => {
    return attempt.map((domain) => extend.apply({}, domain))
  })

  const chartRef = useRef(null)
  const legendWidth = 72

  const [ refWidth, setRefWidth ] = useState(0)

  useEffect(() => {
    const handleResize = (printOption) => {
      const width = printOption === 880 ? printOption : chartRef.current.clientWidth
      setRefWidth(width)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    const handleBeforePrint = () => {
      handleResize(880)
    }

    const handleAfterPrint = () => {
      handleResize()
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  return (

    <div ref={chartRef} style={{
      display: 'flex',
      width: '100%',
      height,
      fontWeight: 'bold',
      alignContent: 'space-around',
      flexDirection: 'row',
    }}>
      {formattedBarChartData.map((data, idx) => {
        const barWidth = (refWidth - legendWidth) / 4
        return (
          <div
            style={{
              display: 'flex',
              width: idx === 3 ? `${barWidth + legendWidth}px` : `${barWidth}px`,
              height: '100%',
              fontWeight: 'bold',
              alignContent: 'center',
              flexDirection: 'column',
            }}
            key={idx}
          >
            <Header as='h5'
              style={{
                textAlign: 'center',
                width: '100%',
                paddingRight: idx === 3 ? '70px' : '0px',
                paddingLeft: '20px',
                marginBottom: '15px',
              }}>
              {data[0].domain}
            </Header>
            <ResponsiveBar

              margin={{
                top: 20, right: idx === 3 ? 70 : 0, bottom: 50, left: 20,
              }}
              style={{
                width: '100%',

              }}
              labelSkipHeight={20}
              data={data}
              indexBy='attempt'
              defs={[
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#dddddd',
                  rotation: 0,
                  lineWidth: 0.1,
                  spacing: 0.12,
                  modifiers: [
                    [ 'darker', 0.6 ],
                    [ 'opacity', 0.01 ],
                  ],
                },
              ]}
              keys={[
                '1',
                '2',
                '3',
                '4',
                '5',
              ]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Attempt',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={null}
              axisRight={null}

              label={(d) =>
                (`${data[d.index][`${d.id}StudentCount`]} (${Math.round(data[d.index][d.id])}%)`)
              }
              labelTextColor={(d) => 'black'}
              colors={colorBy}
              animate={false}

              legends={idx === 3 ? [
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                },
              ] : []}

              tooltip={CustomTooltip}

            />
          </div>

        )
      },
      )}
      <Popup
        content={
          <span>Scores range from 1 to 5 and reflect how students responded to the BASEline assessment. For more information on how the scores relate to each domain, please click{' '}
            <a href='https://downloads.base.education/Baseline-Score-Descriptors.pdf' target='_blank' rel='noopener noreferrer'>here</a>.
          </span>
        }
        trigger={<Icon className='no-print' name='info circle' />}
        hoverable
        on='hover'
        position='bottom center'
      />
    </div>
  )
}
BaselineMeanScoresByDomain.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  keys: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.any,
  width: PropTypes.any,
  indexBy: PropTypes.string,
  bottomLegendLabel: PropTypes.string,
  colorBy: PropTypes.any,
  lineWidth: PropTypes.number,
  enableArea: PropTypes.bool,
  t: PropTypes.func.isRequired,
}
BaselineMeanScoresByDomain.defaultProps = {
  chartData: [],
  height: 300,
  width: '100%',
  lineWidth: 0,
  enableArea: true,
}
export default translate([ 'components' ])(BaselineMeanScoresByDomain)
