import { FETCH_ACTIONS, HTTP_METHODS } from '../helpers/fetch-constants'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'

const STORE_PATH_PREFIX = 'relationships'

export function createRelationship ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, '/relationships', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createRelationship', true)
}

export function createRelationships ({ relationships, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, `/batchRelationships`, relationships, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createRelationships', true)
}

export function acknowledgeCreateRelationship () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateRelationship' ]
}

export function deleteRelationship ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.DELETE, id, `/relationships/${id}`, null, { headers }, FETCH_ACTIONS.DELETE, STORE_PATH_PREFIX, 'deleteRelationship', true)
}

export function deleteRelationships ({ ids, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.DELETE, null, `/batchRelationships`, ids, { headers }, FETCH_ACTIONS.DELETE, STORE_PATH_PREFIX, 'deleteRelationships', true)
}

export function acknowledgeDeleteRelationship () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.DELETE}`
  return [ acknowledge(), storePath, 'acknowledgeDeleteRelationship' ]
}
