import React, { Component } from 'react'
import Paginator from 'paginator'
import {
  Button, Segment, Dropdown,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'

export default class Pager extends Component {
  static propTypes = {
    items: PropTypes.array,
    render: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    pageRange: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
    activePage: PropTypes.number,
    basic: PropTypes.bool,
    scrollToTopOnPageChange: PropTypes.bool,
    showJumpToPage: PropTypes.bool,
    resetToken: PropTypes.string,
  }

  static defaultProps = {
    onPageChange: console.log.bind(console, 'onPageChange'),
    itemsPerPage: 20,
    pageRange: 5,
    activePage: 1,
    basic: false,
    scrollToTopOnPageChange: true,
    showJumpToPage: false,
  }

  constructor (props) {
    super(props)
    this.state = { activePage: props.activePage }
  }

  handlePageChange = (e, button) => {
    this.setState({ activePage: button.page })
    this.props.onPageChange(button.page)
    if (this.props.scrollToTopOnPageChange) {
      window.scrollTo(0, 0)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.resetToken !== prevProps.resetToken) {
      this.setState({ activePage: 1 })
    }
  }

  render () {
    // TODO: validate activePage is even in range
    // TODO: translate
    const { activePage } = this.state
    const {
      items,
      render,
      itemsPerPage,
      pageRange,
      basic,
      showJumpToPage,
    } = this.props
    const {
      first_page: firstPage,
      last_page: lastPage,
      has_previous_page: hasPreviousPage,
      has_next_page: hasNextPage,
      total_pages: totalPages,
    } = new Paginator(itemsPerPage, pageRange).build(items.length, activePage)
    const pageButtons = []
    for (let i = firstPage; i <= lastPage; i++) {
      pageButtons.push(
        <Button
          key={'page_' + i}
          data-public
          page={i}
          active={i === activePage}
          onClick={this.handlePageChange}>{i}</Button>,
      )
    }

    const start = (activePage - 1) * itemsPerPage
    const itemsSlice = items.slice(start, start + itemsPerPage)

    const options = []
    for (let i = 1; i <= totalPages; i++) {
      options.push({ value: i, text: i })
    }
    // TODO: handle scroll to top on page change
    return (
      <React.Fragment>
        {render(itemsSlice, start)}
        <Segment textAlign='center' basic={basic} disabled={pageButtons.length <= 1}>
          <Button.Group>
            <Button
              data-public
              disabled={!hasPreviousPage}
              page={1}
              onClick={this.handlePageChange}>First</Button>
            <Button
              data-public
              disabled={!hasPreviousPage}
              page={activePage - 1}
              onClick={this.handlePageChange}
              icon='chevron left' />
            {pageButtons}
            <Button
              data-public
              disabled={!hasNextPage}
              page={activePage + 1}
              onClick={this.handlePageChange}
              icon='chevron right' />
            <Button
              data-public
              disabled={!hasNextPage}
              page={totalPages}
              onClick={this.handlePageChange}>Last</Button>
          </Button.Group>
          {showJumpToPage && (
            <Dropdown
              data-public
              className='jump-to-page-input'
              compact
              style={{ marginLeft: 10 }}
              placeholder={'Jump to page'}
              search
              selection
              disabled={totalPages <= 1}
              options={options}
              value={this.state.activePage}
              onChange={(e, props) => {
                this.setState({ activePage: props.value })
                this.props.onPageChange(props.value)
                window.scrollTo(0, 0)
              }}
            />
          )}
        </Segment>
      </React.Fragment>
    )
  }
}
