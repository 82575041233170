import React from 'react'
import { Route, Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import isAuthenticated from '../../../helpers/is-authenticated'
import { setToken } from '../../../actions/authentication'

export const PrivateRoute = ({
  authentication,
  render,
  component: Component,
  loginPath,
  setToken,
  ...rest
}) => {
  return (
    <Route {...rest} render={({ location, ...props }) => {
      // NOTE: for some reason the location prop doesn't always have the query string params
      if (isAuthenticated(authentication)) {
        if (render) {
          return render({
            authState: authentication,
            location,
            ...props,
          })
        }
        return (
          <Component
            authState={authentication}
            location={location}
            {...props}
          />
        )
      }
      if (loginPath) {
        window.location = `${loginPath}${(authentication.error) ? '?inactive=1' : ''}`
      }
      return <Redirect to={{
        pathname: '/login',
        state: { from: null }, // changed this to explicitly not save the location state because it was causing too many issues
      }}/>
    }
    }/>
  )
}

PrivateRoute.propTypes = {
  authentication: PropTypes.object.isRequired,
  component: PropTypes.any,
  loginPath: PropTypes.string,
  render: PropTypes.func,
  setToken: PropTypes.func.isRequired,
}

PrivateRoute.defaultProps = {
  loginPath: process.env.REACT_APP_LOGIN_PATH,
}

const mapStateToProps = (state) => {
  const { authentication } = state
  return { authentication }
}
const mapDispatchToProps = {
  setToken,
}
const PrivateRouteContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute))

export default PrivateRouteContainer
