import React, { Component } from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Image,
  Menu,
  Container,
  Icon,
  Popup,
  Header,
  Button,
  Grid,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Switch, Redirect } from 'react-router'
import { get } from 'lodash'
import Joyride, {
  STATUS, EVENTS, ACTIONS,
} from 'react-joyride'

// import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
// import isStale from '../../../helpers/is-stale'
import OrganizationView, { helpers as orgViewHelpers } from '../organization/organization'
import ManageOrganizationView from '../organization/manage-organization'
import FirePhrasesView from '../fire-phrases/fire-phrases'
import SaveFirePhraseView from '../fire-phrases/save-fire-phrase'
import PrivateRoute from '../../common/private-route/private-route'
// import LanguageSelect from '../../common/language-select/language-select'
import { logout } from '../../../actions/authentication'
import { invalidateClients } from '../../../actions/clients'
import OrganizationsMenu from '../../common/organizations-menu/organizations-menu'
import Query from '../../common/query/query'
import GET_ORG from '../../../helpers/graphql-queries/get-org'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import { invalidateClientActivity } from '../../../actions/client-activity'

import './home.css'

export class HomeView extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    invalidateClientActivity: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  static path = '/'

  constructor (props) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  componentDidMount () {
    const {
      location,
      authState: {
        orgId,
      },
    } = this.props

    if (location.pathname === HomeView.path) {
      this.navigateToSelectedOrg(orgId)
    }
  }

  componentDidUpdate (prevProps) {
    // const { location } = prevProps
    const {
      authState: {
        orgId,
      },
      location: currentLocation,
    } = this.props
    if (currentLocation.pathname === HomeView.path) {
      this.navigateToSelectedOrg(orgId)
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  navigateToSelectedOrg = (orgId) => {
    const {
      invalidateClients,
      invalidateClientActivity,
      location: { pathname },
      // authState: {
      //   userId, orgId: userOrgId,
      // },
    } = this.props
    const orgIdMatch = pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : null
    if (currentOrgId !== orgId) {
      invalidateClientActivity()
    }
    invalidateClients()
    // if (userOrgId === orgId) {
    //   this.navigateTo(orgViewHelpers.getOrgClientDetailsPath(false, orgId, userId, false))
    // } else {
    this.navigateTo(orgViewHelpers.getOrgClientsPath(orgId))
    // }
  }

  navigateToCreateOrg = () => this.navigateTo(ManageOrganizationView.path)

  navigateToOrgClients = (orgId) => {
    const path = orgViewHelpers.getOrgClientsPath(orgId)
    // if (this.props.location.pathname !== path) {
    //   this.props.this.props.invalidateClients()
    // }
    this.navigateTo(path)
  }

  navigateToOrgCourses = (orgId) => {
    const path = orgViewHelpers.getOrgCoursesPath(orgId)
    // if (this.props.location.pathname !== path) {
    //   this.props.invalidateClients()
    // }
    this.navigateTo(path)
  }

  navigateToOrgAccessSchedule = (orgId) => {
    const path = orgViewHelpers.getOrgAccessSchedulePath(orgId)
    // if (this.props.location.pathname !== path) {
    //   this.props.invalidateClients()
    // }
    this.navigateTo(path)
  }

  navigateToOrgRoles = (orgId) => this.navigateTo(orgViewHelpers.getOrgRolesPath(orgId))

  navigateToOrgSettings = (orgId) => this.navigateTo(orgViewHelpers.getOrgSettingsPath(orgId))

  navigateToManageFirePhrases = () => this.navigateTo(FirePhrasesView.path)

  renderOrganizationView = (homeOrg, currentOrg, routeProps) => {
    return (
      <OrganizationView
        homeOrg={homeOrg}
        currentOrg={currentOrg}
        isNewVersion={false}
        {...routeProps}
      />
    )
  }

  render () {
    const {
      t,
      logout,
      location: { pathname },
      authState: {
        orgId,
        userId,
        // roleType,
      },
    } = this.props
    const orgIdMatch = pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : orgId
    const onCourses = (pathname.endsWith('/courses'))
    const onOrgSettings = (pathname.endsWith('/manage'))
    const onDashboard = (pathname.endsWith('/users'))
    const classes = (pathname.includes('/users') || pathname.includes('/archivedUsers'))
      ? 'users'
      : (pathname.includes('/courses'))
        ? 'courses'
        : 'default'

    return (
      <Query
        variables={{ organizationId: orgId, childrenDepth: 10 }}
        query={GET_ORG}
      >
        {({
          loading: homeOrgLoading,
          data: homeOrgData,
        }) => {
          return (
            <Query
              variables={{ organizationId: currentOrgId, childrenDepth: 10 }}
              query={GET_ORG}
            >
              {({
                loading: currentOrgLoading,
                data: currentOrgData,
              }) => {
                const homeOrg = get(homeOrgData, 'organization')
                return (
                  <div className='home-page'>
                    <div className={classes}>
                      <Joyride
                        continuous={true}
                        run={this.state.runTour}
                        showSkipButton={true}
                        disableOverlayClose={true}
                        locale={{ skip: 'Exit' }}
                        styles={{
                          options: {
                            primaryColor: '#2185d0',
                          },
                        }}
                        callback={({
                          action, index, status, type,
                        }) => {
                          if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
                          // Need to set our running state to false, so we can restart if we click start again.
                            this.setState({ runTour: false })
                            return
                          }
                          if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 8 && !onDashboard) {
                            this.navigateToSelectedOrg(currentOrgId)
                          } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 7 && !onCourses) {
                            this.navigateToOrgCourses(currentOrgId)
                          } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 10 && !onCourses) {
                            this.navigateToOrgCourses(currentOrgId)
                          } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 9 && !onOrgSettings) {
                            this.navigateToOrgSettings(currentOrgId)
                          } else if (type === EVENTS.TOUR_STATUS && action === ACTIONS.STOP && !onDashboard) {
                            this.navigateToSelectedOrg(currentOrgId)
                          }
                        // console.table({
                        //   action, index, status, type,
                        // })
                        }}
                        steps={[
                          {
                            target: 'body',
                            content: (
                              <div>
                                <h3>Welcome to the BASE Education Admin Portal</h3>
                                <p>We would like to show you around in order to help you get started.</p>
                              </div>
                            ),
                            placement: 'center',
                            hideCloseButton: true,
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#dashboardBtn',
                            content: 'Clicking the BASE logo takes you to the page we are currently on, the main dashboard. Click "Next" to go over the specific parts of this page.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            placement: 'right-start',
                            target: '#charts-and-messages',
                            content: 'On this section of the page, you can click these items to see more information about your users\' activity or see messages about recent changes we\'ve made or important announcments.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#users-list',
                            content: 'This is where you can see information about the users in your organization. By default, they are sorted by most recent activity so that the most relevant users are on top of the list. You can also click on an individual user to see their enrollment and module progress details.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#user-search',
                            content: 'If you are looking for a specific user, you can type in here to search by user name, first name, last name, and email address.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#user-filter-options',
                            content: 'Use these controls to narrow down your search for a specific set of users or to change the order in which they appear.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            placement: 'bottom-end',
                            target: '#user-actions',
                            content: 'This button will open the user actions menu. These user actions will let you toggle between active and archived users, create new users, and download the user data in csv/spreadsheet format.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#org-dropdown',
                            content: 'This menu will let you navigate to different suborganizations within your main organization. If you aren\'t able to find the student(s) you are looking for, you may be able to find them in one of these suborganizations.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#coursesBtn',
                            content: 'This section is where you can view a list of modules that we offer and their descriptions.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            placement: 'right',
                            target: '.ui.raised.card.course-card:first-child .header',
                            content: 'You can click on a module to manage user enrollments for that specific module.',
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: '#orgSettingsBtn',
                            content: (
                              <div>
                                <p>This section is where you view and/or manage some of the organization settings, like the students&quot; hours of accessibility and the suborganizations within your current organization.</p>
                                <p>Managing the student hours of accessibility can only be done by admins and it lets you set the days and times of the week that your students are able to log into the system. If you need to disable access on specific days for your students, you can add restricted days.</p>
                              </div>
                            ),
                          }, {
                            disableBeacon: true,
                            hideCloseButton: true,
                            target: 'body',
                            content: (
                              <div>
                                <h3>Thank You for touring the BASE Education Admin Portal</h3>
                                <p>If you need additional help, give us a call at 678-878-3144 or send an email to <a href="mailto:basesupport@7mindsets.com">basesupport@7mindsets.com.</a></p>
                              </div>
                            ),
                            placement: 'center',
                          },
                        ]}
                      />
                      <Menu className='top-nav'>
                        <Menu.Item
                          id='dashboardBtn'
                          // active={!onCourses && !onOrgSettings}
                          header
                          className='logo'
                          onClick={this.navigateToSelectedOrg.bind(this, currentOrgId)}
                        >
                          <Image inline size='tiny' src={'https://media.base.education/img/base-logo.svg'} />
                        </Menu.Item>
                        <Menu.Item
                          data-public
                          id='usersBtn'
                          active={!onCourses && !onOrgSettings}
                          onClick={this.navigateToOrgClients.bind(this, currentOrgId)}>
                          <Icon size='large' name='users'/>
                          {t('resource_types.client', { postProcess: 'titleCase', count: 2 })}
                        </Menu.Item>
                        <Menu.Item
                          data-public
                          id='coursesBtn'
                          active={onCourses}
                          onClick={this.navigateToOrgCourses.bind(this, currentOrgId)}>
                          <Icon size='large' name='university'/>
                          {t('resource_types.course', { postProcess: 'titleCase', count: 2 })}
                        </Menu.Item>
                        <Menu.Item
                          data-public
                          id='orgSettingsBtn'
                          active={onOrgSettings}
                          disabled={false}
                          onClick={this.navigateToOrgSettings.bind(this, currentOrgId)}>
                          <Icon size='large' name='setting'/>
                          {t('views.home.menu_item_settings')}
                        </Menu.Item>
                        <Popup
                          flowing
                          hoverable
                          disabled={this.state.runTour}
                          position='bottom center'
                          trigger={
                            <Menu.Item
                              data-public
                              id='helpBtn'
                              disabled={false}
                            >
                              <Icon size='large' name='question'/>
                              {t('views.home.menu_item_help')}
                            </Menu.Item>
                          }>
                          <Grid style={{ width: 470 }} centered divided celled='internally' columns={2}>
                            {/* <Grid.Row>
                            <Grid.Column data-public textAlign='center'>
                              <Header as='h4'>Getting Started</Header>
                              <Button color='pink' onClick={() => {
                                if (!onDashboard) {
                                  this.navigateToSelectedOrg(currentOrgId)
                                }
                                this.setState({ runTour: true })
                              }}><Icon name='binoculars'/> Take a Tour</Button>
                            </Grid.Column>
                            <Grid.Column data-public textAlign='center'>
                              <Header as='h4'>Need Additional Help?</Header>
                              <Button color='blue' as='a' target='new' href='https://drive.google.com/a/base-ed.com/file/d/1vDXteGaiaKRmZVPTDI7bXYrI94nj7lHa/view?usp=sharing'><Icon name='eye'/> View Training Guide</Button>
                            </Grid.Column>
                          </Grid.Row> */}
                            <Grid.Row>
                              <Grid.Column data-public width={16} textAlign='center'>
                                <Header as='h4'>Have questions?</Header>
                                <p>Give us a call at 678-878-3144 or send an email to <a href="mailto:basesupport@7mindsets.com">basesupport@7mindsets.com</a></p>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Popup>
                        <Menu.Menu position='right'>
                          <Button data-public icon className='text blue new-home-link' onClick={() => {
                            window.localStorage.removeItem(`education.base.admin.${userId}.old-nav-state`)
                            window.location.reload()
                          }}>
                            <Icon name='lightning'/> Try out our new navigation
                          </Button>
                          <OrganizationsMenu
                            homeOrg={homeOrg}
                            currentOrg={get(currentOrgData, 'organization')}
                            onChangeOrg={this.navigateToSelectedOrg}
                            isLoading={homeOrgLoading || currentOrgLoading}
                          />
                          {/* <LanguageSelect/> */}
                          <Menu.Item
                            data-public
                            className='logout'
                            onClick={() => logout({ isUserInitiated: true })}>
                            <Icon size='large' name='sign out'/>
                            {t('views.home.menu_item_logout')}
                          </Menu.Item>
                        </Menu.Menu>
                      </Menu>
                      <Container className='body-segment'>
                        {(homeOrgLoading || currentOrgLoading) && <Container>
                          <FullScreenLoadingOverlay isActive={true}/>
                        </Container>}
                        {(!homeOrgLoading && !currentOrgLoading && homeOrgData && currentOrgData) && <Switch>
                          <PrivateRoute path={OrganizationView.path} render={this.renderOrganizationView.bind(this, homeOrgData.organization, currentOrgData.organization)}/>
                          <PrivateRoute exact strict path={FirePhrasesView.path} component={FirePhrasesView}/>
                          <PrivateRoute exact strict path={SaveFirePhraseView.path.existing} component={SaveFirePhraseView}/>
                          <PrivateRoute exact strict path={SaveFirePhraseView.path.new} component={SaveFirePhraseView}/>
                          <Redirect to={HomeView.path} />
                        </Switch>}
                      </Container>
                    </div>
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
  logout,
  invalidateClients,
  invalidateClientActivity,
}
const HomeViewContainer = connect(mapStateToProps, mapDispatchToProps)(HomeView)

export default translate([ 'components' ])(HomeViewContainer)
