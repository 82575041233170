import React from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Message,
  Dropdown,
  Button,
  Checkbox,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  sortBy, defer, isEqual,
} from 'lodash'

import WeekdayAccessTimeRange from '../weekday-access-time-range/weekday-access-time-range'

const TRANSLATION_PREFIX = 'common.weekly-access-time-ranges'

class WeeklyAccessTimeRanges extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    weeklyTimes: PropTypes.array,
    disabled: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    weeklyTimes: [],
    disabled: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      lockTimes: false,
    }
  }

  setWeeklyTimesToDefault = () => {
    const times = [ {
      day: 2,
      startMinute: 450,
      endMinute: 930,
    }, {
      day: 3,
      startMinute: 450,
      endMinute: 930,
    }, {
      day: 4,
      startMinute: 450,
      endMinute: 930,
    }, {
      day: 5,
      startMinute: 450,
      endMinute: 930,
    }, {
      day: 6,
      startMinute: 450,
      endMinute: 930,
    } ]
    this.props.onChange(times)
  }

  setWeeklyTimesTo247 = () => {
    const times = [ {
      day: 1,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 2,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 3,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 4,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 5,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 6,
      startMinute: 0,
      endMinute: 1440,
    }, {
      day: 7,
      startMinute: 0,
      endMinute: 1440,
    } ]
    this.props.onChange(times)
  }

  onRemoveWeeklyTime = (index) => {
    const { weeklyTimes, onChange } = this.props
    const newTimes = weeklyTimes.slice(0, index).concat(weeklyTimes.slice(index + 1))
    onChange(newTimes)
  }

  onChangeWeeklyTime = (index, [ startMinute, endMinute ]) => {
    const { weeklyTimes, onChange } = this.props
    let newTimes
    if (this.state.lockTimes) {
      newTimes = weeklyTimes.map((time) => ({
        ...time,
        startMinute,
        endMinute,
      }))
    } else {
      const currentTime = weeklyTimes[index]
      const newTime = {
        ...currentTime,
        startMinute,
        endMinute,
      }
      newTimes = weeklyTimes.slice(0, index).concat([ newTime ]).concat(weeklyTimes.slice(index + 1))
    }
    if (!isEqual(weeklyTimes, newTimes)) {
      onChange(newTimes)
    }
  }

  addNewTime = (day) => {
    const { weeklyTimes, onChange } = this.props
    const newTimes = sortBy(weeklyTimes.slice(0).concat({
      day,
      startMinute: 480,
      endMinute: 1020,
    }), 'day')
    onChange(newTimes)
  }

  renderWeeklyTimeSegment = (weeklyTime, index) => {
    const {
      t,
      disabled,
    } = this.props
    const days = [
      t(`${TRANSLATION_PREFIX}.weekdays.sunday`),
      t(`${TRANSLATION_PREFIX}.weekdays.monday`),
      t(`${TRANSLATION_PREFIX}.weekdays.tuesday`),
      t(`${TRANSLATION_PREFIX}.weekdays.wednesday`),
      t(`${TRANSLATION_PREFIX}.weekdays.thursday`),
      t(`${TRANSLATION_PREFIX}.weekdays.friday`),
      t(`${TRANSLATION_PREFIX}.weekdays.saturday`),
    ]
    return (
      <Segment attached key={index}>
        <WeekdayAccessTimeRange
          disabled={disabled}
          onRemove={this.onRemoveWeeklyTime.bind(this, index)}
          onChange={this.onChangeWeeklyTime.bind(this, index)}
          startTime={weeklyTime.startMinute}
          endTime={weeklyTime.endMinute}
          label={days[weeklyTime.day - 1]}/>
      </Segment>
    )
  }

  render () {
    const {
      t,
      weeklyTimes,
      disabled,
    } = this.props

    const days = [
      t(`${TRANSLATION_PREFIX}.weekdays.sunday`),
      t(`${TRANSLATION_PREFIX}.weekdays.monday`),
      t(`${TRANSLATION_PREFIX}.weekdays.tuesday`),
      t(`${TRANSLATION_PREFIX}.weekdays.wednesday`),
      t(`${TRANSLATION_PREFIX}.weekdays.thursday`),
      t(`${TRANSLATION_PREFIX}.weekdays.friday`),
      t(`${TRANSLATION_PREFIX}.weekdays.saturday`),
    ]
    const addText = t('add', { postProcess: 'titleCase' })
    const anotherText = t(`${TRANSLATION_PREFIX}.another`)
    return (
      <React.Fragment>
        <Message attached='top'>
          <div style={{ display: 'flex' }}>
            <Message.Header data-public style={{ flexGrow: 2 }}>{t(`${TRANSLATION_PREFIX}.weekly_times_header`)}</Message.Header>
            {(!disabled) && (
              <Checkbox
                data-public
                label={t(`${TRANSLATION_PREFIX}.sync_times_label`)}
                toggle
                onChange={(e, props) => {
                  this.setState({ lockTimes: props.checked })
                  if (weeklyTimes && weeklyTimes.length && props.checked) {
                    defer(this.onChangeWeeklyTime, 0, [ weeklyTimes[0].startMinute, weeklyTimes[0].endMinute ])
                  }
                }}
              />
            )}
          </div>
        </Message>
        <Message attached info>
          <Message.Content>
            <p data-public>{t(`${TRANSLATION_PREFIX}.description`)}</p>
            {!disabled && (
              <Message.List>
                <Message.Item data-public>{t(`${TRANSLATION_PREFIX}.description_list_item_1`)}</Message.Item>
                <Message.Item data-public>{t(`${TRANSLATION_PREFIX}.description_list_item_2`)}</Message.Item>
                <Message.Item data-public>{t(`${TRANSLATION_PREFIX}.description_list_item_3`)}</Message.Item>
                <Message.Item data-public>{t(`${TRANSLATION_PREFIX}.description_list_item_4`)}</Message.Item>
              </Message.List>
            )}
          </Message.Content>
        </Message>
        {weeklyTimes.map(this.renderWeeklyTimeSegment)}
        <Button.Group attached='bottom'>
          <Dropdown
            data-public
            floating
            labeled
            pointing='bottom left'
            button
            icon='plus'
            text={`${addText} ${anotherText}`}
            className='icon blue'
            disabled={disabled}
          >
            <Dropdown.Menu>
              <Dropdown.Header data-public content={t(`${TRANSLATION_PREFIX}.placeholder`)} />
              {days.map((day, index) => (<Dropdown.Item key={index} data-public value={index + 1} text={day} onClick={this.addNewTime.bind(this, index + 1)}/>))}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            data-public
            color='blue'
            icon='clock'
            type='button'
            content={t(`${TRANSLATION_PREFIX}.schedule_monday_friday`)}
            onClick={this.setWeeklyTimesToDefault}
            disabled={disabled}
          />
          <Button
            data-public
            color='blue'
            icon='clock'
            type='button'
            content={t(`${TRANSLATION_PREFIX}.schedule_24_7`)}
            onClick={this.setWeeklyTimesTo247}
            disabled={disabled}
          />
        </Button.Group>
      </React.Fragment>
    )
  }
}

export default translate([ 'components' ])(WeeklyAccessTimeRanges)
