import gql from 'graphql-tag'

export const NAME = 'deleteRoom'
const DELETE_ROOM = gql`
mutation ${NAME}($id: ID!) {
  ${NAME}(id: $id)
}
`

export default DELETE_ROOM
