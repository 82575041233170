
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Editable = ({
  childRef,
  text,
  name,
  placeholder,
  children,
  onEditComplete,
  onEditStart,
  ...props
}) => {
  const [ isEditing, setEditing ] = useState(false)

  // using use effect, when isEditing state is changing, check whether it is set to true, if true, then focus on the reference element
  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus()
    }
  }, [ isEditing, childRef ])

  const handleKeyDown = (event) => {
    const { key } = event
    const exitKeys = [ 'Escape', 'Tab' ]
    const enterKey = 'Enter'
    const allKeys = [ ...exitKeys, enterKey ]

    /*
      - For textarea, check only Escape and Tab key and set the state to false
      - For everything else, all three keys will set the state to false
    */
    if (
      (children.type === 'textarea' && exitKeys.indexOf(key) > -1) ||
      (children.type !== 'textarea' && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false)
      onEditComplete()
    }
  }

  return (
    <section {...props}>
      {(isEditing) ? (
        <div
          onBlur={() => {
            setEditing(false)
            onEditComplete()
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {children}
        </div>
      ) : (
        <div
          tabIndex={0}
          onFocus={() => {
            setEditing(true)
            onEditStart()
          }}
          onClick={() => {
            setEditing(true)
            onEditStart()
          }}
          style={{ borderBottom: '1px solid #ccc' }}
        >
          {(!!text && children.type === 'textarea') && (
            <>
              {text.split('\n').filter((line) => !!line).map((line, i) => (
                <div key={`editableline_${name}_${i}`}>
                  {line}
                </div>
              ))}
            </>
          )}
          {(!text || children.type !== 'textarea') && (
            <span style={{ color: (!text) ? '#CCC' : 'black', fontStyle: (!text) ? 'italic' : 'normal' }}>
              {text || placeholder}
            </span>
          )}
        </div>
      )}
    </section>
  )
}

Editable.propTypes = {
  childRef: PropTypes.any,
  text: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onEditComplete: PropTypes.func,
  onEditStart: PropTypes.func,
  children: PropTypes.any,
}

Editable.defaultProps = {
  placeholder: 'Click to edit',
  onEditComplete: console.log.bind(console, 'onEditComplete'),
  onEditStart: console.log.bind(console, 'onEditStart'),
}

export default Editable
