import gql from 'graphql-tag'

const GET_USER_PROFILE = gql`
query getUserProfile($userId: String!) {
  userProfile(userId: $userId) {
      assessmentsDeclinedReason
  }
}
`

export default GET_USER_PROFILE
