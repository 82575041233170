import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Segment,
  Header,
  Popup,
  Icon,
} from 'semantic-ui-react'

import {
  without,
  uniq,
} from 'lodash'

import './baseline-selector.css'

const BaselineSelector = ({
  selections,
  onChange,
  isSaving,
}) => {
  return (
    <Segment className='baseline-selector' disabled={isSaving}>
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 20,
      }}>
        <Header style={{ height: 20, marginRight: 10 }}>Baseline</Header>
        <Popup
          hoverable
          wide
          trigger={(<Icon name='info circle' className='base-teal pointer' style={{ fontSize: '1.2em' }} />)}
          content={(
            <>
              <Header data-public>What is Baseline?</Header>
              <p data-public>Baseline is used to reliably measure students’ strengths and deficits in domains reflective of CASEL Competencies and school engagement.</p>
            </>
          )}
          position='top center'
        />
      </div>
      <div style={{
        display: 'flex', marginTop: 10, alignItems: 'center',
      }}>
        <Button.Group>
          <Button type='button' className='off-btn' onClick={() => onChange([])} active={!selections.includes('assessments')}>Off</Button>
          <Button type='button' onClick={() => onChange(selections.concat([ 'assessments' ]))} active={selections.includes('assessments')}>On</Button>
        </Button.Group>
        <Header style={{ marginTop: 0, marginLeft: 15 }}>
          <Icon name='file alternate outline' style={{ fontSize: '1.8em' }} />
          <Header.Content>
            Assessments
            <Header.Subheader>When enabled, students will periodically be asked to complete an assessment in order to provide you with details on their behavior, engagement, academic self confidence, and social connectivity in school.</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div style={{
        display: 'flex', marginTop: 10, alignItems: 'center',
      }}>
        <Button.Group>
          <Button type='button' className='off-btn' onClick={() => onChange(without(selections, 'recommendations'))} active={!selections.includes('recommendations')}>Off</Button>
          <Button type='button' onClick={() => onChange(uniq(selections.concat([ 'assessments', 'recommendations' ])))} active={selections.includes('recommendations')}>On</Button>
        </Button.Group>
        <Header style={{ marginTop: 0, marginLeft: 15 }}>
          <Icon name='star' style={{ fontSize: '1.8em' }} />
          <Header.Content>
            Module Recommendations
            <Header.Subheader>When enabled, students will be shown recommended modules based on their responses in the assessment (requires Assessments to be enabled).</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div style={{
        display: 'flex', marginTop: 10, alignItems: 'center',
      }}>
        <Button.Group>
          <Button type='button' className='off-btn' onClick={() => onChange(without(selections, 'insights'))} active={!selections.includes('insights')}>Off</Button>
          <Button type='button' onClick={() => onChange(uniq(selections.concat([ 'assessments', 'insights' ])))} active={selections.includes('insights')}>On</Button>
        </Button.Group>
        <Header style={{ marginTop: 0, marginLeft: 15 }}>
          <Icon name='area chart' style={{ fontSize: '1.8em' }} />
          <Header.Content>
            Reporting
            <Header.Subheader>When enabled, staff will be able to view data about how their students are performing (requires Assessments to be enabled)</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div style={{
        display: 'flex', marginTop: 10, alignItems: 'center',
      }}>
        <Button.Group>
          <Button type='button' className='off-btn' onClick={() => onChange(without(selections, 'alternatives'))} active={!selections.includes('alternatives')}>Off</Button>
          <Button type='button' onClick={() => onChange(uniq(selections.concat([ 'assessments', 'alternatives' ])))} active={selections.includes('alternatives')}>On</Button>
        </Button.Group>
        <Header style={{ marginTop: 0, marginLeft: 15 }}>
          <Icon name='clipboard list' style={{ fontSize: '1.8em' }} />
          <Header.Content>
            Alternative Questions
            <Header.Subheader>When enabled, students will be presented with alternative versions of some of the demographic questions (like gender). This is only for organizations that directly request this (requires Assessments to be enabled).</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    </Segment>
  )
}

BaselineSelector.propTypes = {
  selections: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
}

BaselineSelector.defaultProps = {
  isSaving: false,
  selections: [],
  onChange: console.log.bind(console, 'onChange'),
}

export default BaselineSelector
