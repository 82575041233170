import React, { useState } from 'react'
import { translate } from 'react-i18next'

import {
  Button, Dropdown, Header, Icon, Modal,
} from 'semantic-ui-react'

import PropTypes from 'prop-types'
import Mutation from '../../../common/mutation/mutation'

import DELETE_CLIENTS from '../../../../helpers/graphql-queries/delete-clients'

const TRANSLATION_PREFIX = 'views.organization.clients'

const BulkDeleteClientsMenuItem = (props) => {
  const {
    onLoadingChange, org, userIds,
  } = props
  const [ open, setOpen ] = useState(false)
  const [ complete, setComplete ] = useState(false)

  const disabled = (userIds && !userIds.length)

  function save (deleteClients) {
    setOpen(false)
    onLoadingChange(true)
    deleteClients({ variables: { orgId: org.id, ids: userIds } })
  }

  return (
    <Mutation
      mutation={DELETE_CLIENTS}
      onCompleted={() => {
        onLoadingChange(false)
        setComplete(true)
      }}
    >
      {(deleteClients) => {
        return (
          <>
            <Dropdown.Item
              disabled={disabled}
              icon='trash alternate'
              text='Delete'
              onClick={() => setOpen(true)}
            />

            <ConfirmationModal
              {...props}
              open={open}
              setOpen={setOpen}
              save={() => save(deleteClients)}
            />

            <CompletionModal
              {...props}
              open={complete}
              setOpen={setComplete}
            />
          </>
        )
      }}
    </Mutation>
  )
}

BulkDeleteClientsMenuItem.propTypes = {
  userIds: PropTypes.array,
  org: PropTypes.object,
  onLoadingChange: PropTypes.func,
}

const ConfirmationModal = ({
  open, save, setOpen, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Header>{t(`${TRANSLATION_PREFIX}.bulk_delete_header`)}</Modal.Header>

    <Modal.Content image>
      <Modal.Description>
        <div>{t(`${TRANSLATION_PREFIX}.bulk_delete_description`)}</div>
        <div>{t(`${TRANSLATION_PREFIX}.bulk_delete_subdescription`)}</div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions>
      <Button
        color='#339cff'
        onClick={() => setOpen(false)}
      >
                Cancel
      </Button>

      <Button
        content={t(`${TRANSLATION_PREFIX}.submit`)}
        labelPosition='left'
        icon='checkmark'
        onClick={save}
        positive
      />
    </Modal.Actions>
  </Modal>
)

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  save: PropTypes.func,
  setOpen: PropTypes.func,
  t: PropTypes.func,
}

const CompletionModal = ({
  invalidateClients, open, setOpen, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Content>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <Icon style={{ fontSize: '5em', margin: '40px auto -25px auto' }} name='check circle' />
      </Header>

      <Modal.Description>
        <div className='success-label'>{t(`${TRANSLATION_PREFIX}.bulk_delete_success`)}</div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions style={{ justifyContent: 'flex-end' }}>
      <Button
        content={t(`${TRANSLATION_PREFIX}.done`)}
        onClick={() => {
          setOpen(false)
          invalidateClients()
        }}
        primary
      />
    </Modal.Actions>
  </Modal>
)

CompletionModal.propTypes = {
  open: PropTypes.bool,
  invalidateClients: PropTypes.func,
  setOpen: PropTypes.func,
  t: PropTypes.func,
}

export default translate([ 'components' ])(BulkDeleteClientsMenuItem)
