// This code was derived from https://github.com/jaystack/repatch
import { get, cloneDeep } from 'lodash'

import mergeAtPath from './helpers/merge-at-path'
import inspect from './helpers/inspect'

export default class Store {
  constructor (initialState, enableLogging = false) {
    this.state = initialState
    this.isDispatching = false
    this.listeners = []
    this.enableLogging = enableLogging
  }

  getState (path) {
    return (path) ? get(this.state, path) : this.state
  }

  dispatch ([ reducer, path, name ]) {
    if (typeof reducer !== 'function') {
      throw new Error('Reducer is not a function: dispatch takes only reducers as functions.')
    }
    if (this.isDispatching) {
      throw new Error('Reducers may not dispatch actions.')
    }
    this.isDispatching = true
    // only clone what we need to
    const selectedOldState = (path) ? cloneDeep(get(this.state, path)) : cloneDeep(this.state)
    try {
      const selectedNextState = reducer(selectedOldState)
      this.enableLogging && inspect(selectedOldState, selectedNextState, path, name)
      this.state = (path) ? mergeAtPath(this.state, selectedNextState, path) : selectedNextState
    } catch (ex) {
      console.error('Reducer produced an exception', ex)
    } finally {
      this.isDispatching = false
    }

    this.listeners.forEach((listener) => listener(path, selectedOldState))
    // TODO: do we really need to return the state tree?
    return this.state
  }

  subscribe (listener) {
    if (typeof listener !== 'function') {
      throw new Error('Listener is not a function: subscribe takes only listeners as functions.')
    }
    this.listeners = [ ...this.listeners, listener ]
    return () => (this.listeners = this.listeners.filter((lis) => lis !== listener))
  }

  addMiddleware (...middlewares) {
    if (middlewares.some((middleware) => typeof middleware !== 'function')) {
      throw new Error('Middleware is not a function: addMiddleware takes only middlewares as functions.')
    }
    middlewares.forEach((middleware) => {
      this.dispatch = middleware(this)(this.dispatch.bind(this))
    })
    return this
  }
}

export const thunkMiddleware = (store) => (next) => ([ reducer, path, name ]) => {
  if (typeof reducer !== 'function') {
    throw new Error('Thunk reducer must return a function')
  }
  const result = reducer(store.getState(path))
  if (typeof result === 'function') {
    return result(store.dispatch, store.getState)
  } else {
    return next([ () => (result), path, name ])
  }
}
