import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  keyBy,
} from 'lodash'
import { invalidateClients } from '../../../../actions/clients'
import {
  Segment,
  Header,
  Item,
  Form,
  Icon,
} from 'semantic-ui-react'

import Query from '../../../common/query/query'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ROOMS_BY_ORG_ID from '../../../../helpers/graphql-queries/get-rooms-by-org-id'
import GET_STAFF_BY_ORG_ID from '../../../../helpers/graphql-queries/get-staff-by-org-id'
// import isStale from '../../../../helpers/is-stale'
import RoomItem from '../../../common/room-item'
import Pager from '../../../common/pager/uncontrolled-pager'
import { getDisplayName } from '../../../../helpers/user'

// import './rooms.css'

const path = `/all-rooms`
const ROOMS_PER_PAGE = 20

export class AllRoomsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    homeOrganization: PropTypes.object,
    showFriendlyApiErrorMessages: PropTypes.bool,
    onRoomClick: PropTypes.func.isRequired,
  }

  static path = path

  constructor (props) {
    super(props)
    this.state = {
      searchFilter: '',
      roomPage: 1,
    }
  }

  render () {
    const {
      organization,
      // homeOrganization,
      onRoomClick,
      t,
    } = this.props
    const {
      searchFilter,
      roomPage,
    } = this.state

    return (
      <Query
        variables={{
          orgId: organization.id,
        }}
        query={GET_STAFF_BY_ORG_ID}
      >
        {({
          loading,
          data,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const staff = get(data, 'staffByOrgId') || []
          const staffById = keyBy(staff, 'id')

          return (
            <Query
              variables={{
                orgId: organization.id,
              }}
              query={GET_ROOMS_BY_ORG_ID}
              fetchPolicy='network-only'
            >
              {({
                loading,
                data,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const rooms = get(data, 'roomsByOrgId.rooms') || []
                const filterText = t('search', { postProcess: 'titleCase' })
                const roomsText = t('resource_types.room', { count: 2, postProcess: 'titleCase' })
                if (!rooms || !rooms.length) {
                  return (
                    <Segment placeholder>
                      <Header icon>
                        <Icon style={{ width: 65 }} className='fas fa-door-open'/>
                  There are no rooms in this organization.
                      </Header>
                    </Segment>
                  )
                }
                const filteredRooms = (!searchFilter) ? rooms : rooms.filter((room) => {
                  const matchesName = room.name.toLowerCase().includes(searchFilter)
                  const owner = staffById[room.ownerUserId]
                  const displayName = getDisplayName(owner)
                  const matchesOwner = displayName.toLowerCase().includes(searchFilter)
                  return matchesName || matchesOwner
                })

                return (
                  <Segment>
                    <Form>
                      <Form.Field>
                        <Form.Input
                          icon='search'
                          autoFocus
                          data-public
                          fluid
                          placeholder={`${filterText} ${roomsText}`}
                          onChange={(e, props) => this.setState({
                            ...this.state, searchFilter: props.value.toLowerCase(), roomPage: 1,
                          })}
                        />
                      </Form.Field>
                    </Form>
                    {(!filteredRooms || !filteredRooms.length) && (
                      <Segment placeholder>
                        <Header icon>
                          <Icon name='search'/>
                        There are no rooms that match that search critria.
                        </Header>
                      </Segment>
                    )}
                    <Pager
                      basic
                      itemsPerPage={ROOMS_PER_PAGE}
                      items={filteredRooms.slice(((roomPage - 1) * ROOMS_PER_PAGE), (((roomPage - 1) * ROOMS_PER_PAGE) + ROOMS_PER_PAGE))}
                      onPageChange={(pageNumber) => this.setState({
                        ...this.state,
                        roomPage: pageNumber,
                      })}
                      activePage={roomPage}
                      totalItemCount={filteredRooms.length}
                      render={(items) => {
                        return (
                          <Item.Group>
                            {items.map((room) => {
                              const user = staffById[room.ownerUserId]
                              const displayName = getDisplayName(user)
                              return (
                                <RoomItem
                                  key={room.id}
                                  id={`room-${room.id}`}
                                  room={room}
                                  staffName={displayName}
                                  lastActiveDate={room.lastActiveDate}
                                  hasUnreviewedFirewords={!!room.unreviewedFirewordCount}
                                  onClick={() => onRoomClick(room.id)}
                                />
                              )
                            })}
                          </Item.Group>
                        )
                      }}
                    />
                  </Segment>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  invalidateClients,
}
const AllRoomsViewContainer = connect(mapStateToProps, mapDispatchToProps)(AllRoomsView)

export default translate([ 'components' ])(AllRoomsViewContainer)
