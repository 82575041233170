/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import converter from 'number-to-words'
import { capitalCase } from 'capital-case'
import {
  Header,
  Accordion,
  Icon,
  Message,
  Tab,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  without,
  get,
  keyBy,
  sortBy,
  find,
  round,
} from 'lodash'

import QuestionCard from '../question-card'

import './assessment-answer-card.css'
import Mutation from '../../common/mutation/mutation'
import DELETE_USER_ASSESEMENT from '../../../helpers/graphql-queries/delete-user-assessment'
import REPORCESS_USER_ASSESEMENT_SCORES from '../../../helpers/graphql-queries/reprocess-user-assessment-scores'

const MEAN_DEFAULT_BAR_COLOR = '#224147'
const MEAN_COLORS_BY_ATTEMPT = {
  '1': MEAN_DEFAULT_BAR_COLOR,
  '2': '#3e6870',
  '3': '#5c929c',
  '4': '#7cbecb',
}

const PROGRESS_COLORS = [
  '#de5626',
  '#f8bf00',
  '#90ad43',
  '#0892a5',
  '#71bbdb',
]

// const TRANSLATION_PREFIX = 'common.assessment-answer-card'

const AssessmentAnswerCard = ({
  t,
  assessment,
  userAssessments,
  userAssessmentScores,
  insightsEnabled,
  canDeleteAssessment,
}) => {
  const [ activeAccordionIndices, setActiveAccordionIndices ] = useState([])
  const numberOfQuestions = assessment.sections.reduce((count, section) => {
    return section.questions.length + count
  }, 0)
  const panes = sortBy(userAssessments, 'schoolYearAttempt').map((ua) => {
    const answersByQuestionId = keyBy(ua.answers, 'questionId')
    const startedOn = (ua.created) ? new Date(ua.created) : null
    const completedOn = (ua.completionDate) ? new Date(ua.completionDate) : null
    const attemptWords = capitalCase(converter.toWordsOrdinal(ua.schoolYearAttempt))
    const attemptScores = find(userAssessmentScores, { id: attemptWords })
    return {
      menuItem: {
        key: attemptWords, icon: { name: 'file alternate outline', className: 'base-teal' }, content: attemptWords,
      },
      render: () => {
        let questionNumber = 0
        return (
          <Mutation
            mutation={DELETE_USER_ASSESEMENT}
          >
            {(deleteAssessment) => {
              return (
                <Mutation
                  mutation={REPORCESS_USER_ASSESEMENT_SCORES}
                >
                  {(reprocessAssessmentScores) => {
                    return (
                      <Tab.Pane attached={false} basic>
                        <div style={{
                          display: 'flex', marginBottom: 10, alignItems: 'center',
                        }}>
                          <div style={{
                            flexShrink: 2,
                            fontSize: '3.5em',
                            marginRight: 15,
                            fontFamily: 'Brush Script MT',
                            color: MEAN_COLORS_BY_ATTEMPT[ua.schoolYearAttempt] || MEAN_DEFAULT_BAR_COLOR,
                          }}>
                            {ua.schoolYearAttempt}
                          </div>
                          <Header as='h4' style={{
                            flexGrow: 2, marginBottom: 0, marginTop: 0,
                          }}>
                            <Header.Content data-public>
                              {attemptWords}
                              <Header.Subheader><span className='text bold'>{ua.answers.length}</span> of <span className='text bold'>{numberOfQuestions}</span> questions answered</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <div style={{ flexShrink: 2, textAlign: 'right' }}>
                            <div className='text grey'><span data-public className='text bold'>Started on:</span> {moment(startedOn).format('MMM D, YYYY')}</div>
                            {(!!completedOn) && (<div className='text grey'><Icon name='check' className='base-green' /><span data-public className='text bold'>Completed on:</span> {moment(completedOn).format('MMM D, YYYY')}</div>)}
                            {(!completedOn) && (<div className='text grey'><Icon name='remove' color='red' /><span data-public className='text bold'>Incomplete</span></div>)}
                          </div>
                        </div>
                        {(!completedOn) && (
                          <Message
                            icon='wait'
                            header='Not enough information'
                            content='This student has not completed the assessment yet. Please have them complete the assessment and then check back here to see their results.'
                          />
                        )}

                        <Accordion
                          fluid
                          styled
                          exclusive={false}
                        >
                          {assessment.sections.map((section, index) => {
                            const isActive = activeAccordionIndices.includes(index)
                            const sectionScore = (attemptScores) ? find(attemptScores.data, { x: section.name }) : null
                            const scoreDefinition = (!sectionScore || !section.scoringDefinitions) ? null : section.scoringDefinitions[Math.floor(sectionScore.d.groupNormalizedAvgScore) - 1]
                            return (
                              <React.Fragment key={`${assessment.id}-${section.key}`}>
                                <Accordion.Title
                                  data-public
                                  active={isActive}
                                  index={0}
                                  onClick={() => {
                                    if (isActive) {
                                      setActiveAccordionIndices(without(activeAccordionIndices, index))
                                    } else {
                                      setActiveAccordionIndices(activeAccordionIndices.concat([ index ]))
                                    }
                                  }}
                                >
                                  <Icon name='dropdown' />
                                  {section.name}
                                  {(!!sectionScore && insightsEnabled) && (<span style={{ float: 'right', color: PROGRESS_COLORS[Math.floor(sectionScore.d.groupNormalizedAvgScore) - 1] }}>{`${`Average: ${round(sectionScore.d.groupNormalizedAvgScore, 2)}`}`}</span>)}
                                </Accordion.Title>

                                <Accordion.Content active={isActive}>
                                  {(!!scoreDefinition) && (
                                    <Message
                                      info
                                      icon='info circle'
                                      content={scoreDefinition.description}
                                    />
                                  )}
                                  {section.questions.map((q) => {
                                    questionNumber++
                                    const answerValue = get(answersByQuestionId, `${q.id}.value`, '') || ''
                                    const hasChoices = (q.choices && q.choices.length)
                                    const matchingChoice = (hasChoices) ? q.choices.find((c) => c.value === answerValue) : null
                                    const matchingChoiceLabel = (matchingChoice) ? matchingChoice.label : null
                                    return (
                                      <QuestionCard
                                        key={`${assessment.id}-section${section.name}-${q.id}`}
                                        questionNumber={questionNumber}
                                        questionText={q.text}
                                        answerText={matchingChoiceLabel || answerValue}
                                      />
                                    )
                                  })}
                                </Accordion.Content>
                              </React.Fragment>
                            )
                          })}
                        </Accordion>
                        {canDeleteAssessment && (
                          <span style={{
                            float: 'right', marginTop: 15,
                          }}>
                            <Button
                              data-public
                              color='blue'
                              icon
                              onClick={() => {
                                if (!window.confirm(`Are you sure you want to recalculate the scores of this user assessment? Refresh the page after this action completes to see the scores.`)) {
                                  return
                                }
                                reprocessAssessmentScores({ variables: { id: ua.id } })
                              }}
                            >
                              <Icon name='sync alternate'/> Reprocess Scores
                            </Button>
                            <Button
                              data-public
                              color='red'
                              icon
                              onClick={() => {
                                if (!window.confirm(`Are you sure you want to delete the results of this user assessment? This cannot be undone`)) {
                                  return
                                }
                                deleteAssessment({ variables: { id: ua.id } })
                              }}
                            >
                              <Icon name='remove'/> Delete Assessment
                            </Button>
                          </span>
                        )}
                      </Tab.Pane>
                    )
                  }}
                </Mutation>
              )
            }}
          </Mutation>
        )
      },
    }
  })

  return (
    <Tab
      className='assessment-answers'
      menu={{
        secondary: true, vertical: true, fluid: true,
      }}
      grid={{ paneWidth: 13, tabWidth: 3 }}
      panes={panes}
    />
  )
}

AssessmentAnswerCard.propTypes = {
  assessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        choices: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          subLabel: PropTypes.string,
        })),
      })),
    })),
  }),
  userAssessments: PropTypes.arrayOf(PropTypes.object),
  userAssessmentScores: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func.isRequired,
  insightsEnabled: PropTypes.bool.isRequired,
  canDeleteAssessment: PropTypes.bool,
}

AssessmentAnswerCard.defaultProps = {
  insightsEnabled: true,
}

export default translate([ 'components' ])(AssessmentAnswerCard)
