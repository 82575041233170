import to from 'await-to-js'
import {
  identity,
} from 'lodash'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'

const STORE_PATH_PREFIX = 'clientActivity'

export function getClientActivity ({
  accessToken,
  organizationId,
  isArchived = false,
  roleTypes,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.GET
  const fetchAction = FETCH_ACTIONS.GET_ALL
  const actionName = 'getClientActivity'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const url = (roleTypes && roleTypes.length) ? `/clientActivity?orgId=${organizationId}&isArchived=${isArchived}&${roleTypes.map((rt) => `roleTypes=${rt}`).join('&')}` : `/clientActivity?orgId=${organizationId}&isArchived=${isArchived}`
      const [ err, result ] = await to(fetch(url, { method, headers }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      dispatch([ requestSuccess(result, identity), storePath, `${actionName}Success` ])
    },
  ]
}

export function invalidateClientActivity () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateClientActivity' ]
}
