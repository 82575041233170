import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form as SemanticForm,
  Label,
  Dropdown,
  Icon,
  Divider,
  Header,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  Form, Field, FormSpy,
} from 'react-final-form'
import { isNil, sortBy } from 'lodash'

import {
  minLength,
  maxLength,
  required,
  composeValidators,
} from '../../../helpers/form-validators'
import {
  dropdownOnChange,
  checkboxOnChange,
} from '../../../helpers/form'
import ConditionalField from '../../common/conditional-field'
import { EXTERNAL_ROLE_TYPES, INTERNAL_ROLE_TYPES } from '../../../helpers/get-roles'
import '../../common.css'

const TRANSLATION_PREFIX = 'forms.create-room'

const validateName = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
)

const setFieldValue = (args, state) => {
  const [ name, data ] = args
  const field = state.fields[name]
  if (field) {
    field.change(data)
  }
}

const IMAGES_BY_NAME = {
  'Teal Lights': 'https://media.base.education/img/avatars/avatar01.jpg',
  'Curved Space': 'https://media.base.education/img/avatars/avatar02.jpg',
  'Blue Lights': 'https://media.base.education/img/avatars/avatar03.jpg',
  'Pink Lights': 'https://media.base.education/img/avatars/avatar04.jpg',
  'Deep Space': 'https://media.base.education/img/avatars/avatar05.jpg',
  'Oil and Water': 'https://media.base.education/img/avatars/avatar06.jpg',
  'Cracked Leather': 'https://media.base.education/img/avatars/avatar07.jpg',
  'Pink Flowers': 'https://media.base.education/img/avatars/avatar08.jpg',
  'Yellow Lights': 'https://media.base.education/img/avatars/avatar09.jpg',
  'Sea Shells': 'https://media.base.education/img/avatars/avatar10.jpg',
  'White Flowers': 'https://media.base.education/img/avatars/avatar11.jpg',
  'Colorful Tiles': 'https://media.base.education/img/avatars/avatar12.jpg',
  'Tall Grass': 'https://media.base.education/img/avatars/avatar13.jpg',
  'Orange Flower': 'https://media.base.education/img/avatars/avatar14.jpg',
  'Water Droplets': 'https://media.base.education/img/avatars/avatar15.jpg',
  'Water Splash': 'https://media.base.education/img/avatars/avatar16.jpg',
  'Green Leaves': 'https://media.base.education/img/avatars/avatar17.jpg',
  Sunset: 'https://media.base.education/img/avatars/avatar18.jpg',
  'Hazy Sun': 'https://media.base.education/img/avatars/avatar19.jpg',
}

const IMAGES = sortBy(Object.keys(IMAGES_BY_NAME).map((text) => ({
  key: IMAGES_BY_NAME[text],
  text,
  value: IMAGES_BY_NAME[text],
  image: {
    src: IMAGES_BY_NAME[text], rounded: true,
  },
})), 'text')

export class CreateRoomPage1 extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onValidChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    firewordNotificationsEnabled: PropTypes.bool,
    firewordNotificationsForRoomCoursesOnly: PropTypes.bool,
    isOpenForRequests: PropTypes.bool,
    forceFirewords: PropTypes.bool,
    isPrivate: PropTypes.bool,
    disablePrivacyChange: PropTypes.bool,
    enablePrivateRooms: PropTypes.bool,
    userDropdownOptions: PropTypes.arrayOf(PropTypes.object),
    ownerUserId: PropTypes.string,
    userRole: PropTypes.string,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    onValidChange: console.log.bind(console, 'onValidChange'),
    t: (key, opts = {}) => opts.defaultValue || key,
    forceFirewords: false,
  }

  renderForm = ({
    handleSubmit,
    form,
  }) => {
    const {
      onValidChange,
      forceFirewords,
      disablePrivacyChange,
      enablePrivateRooms,
      userDropdownOptions,
      userRole,
      t,
    } = this.props
    return (
      <SemanticForm onSubmit={handleSubmit}>
        <FormSpy
          subscription={{ valid: true, pristine: true }}
          onChange={(props) => setTimeout(() => {
            onValidChange(props.valid)
            if (!props.pristine && props.valid) {
              form.submit()
            }
          }, 10)
          }
        />
        <SemanticForm.Group widths='equal'>
          <Field
            data-public
            name='name'
            style={{ margin: 0 }}
            validate={validateName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  id='room-name-input'
                  placeholder={'Room Name'}
                  label={'Room Name *'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
          <Field
            data-public
            name='imageUrl'
            validate={required({ message: 'forms.error_required' })}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <label data-public>{t(`${TRANSLATION_PREFIX}.room_image_label`)} *</label>
                <Dropdown
                  id='room-image-input'
                  placeholder={t(`${TRANSLATION_PREFIX}.room_image_label`)}
                  error={!!message && touched}
                  fluid
                  selection
                  {...input}
                  options={IMAGES}
                  onChange={dropdownOnChange(input)}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
        </SemanticForm.Group>
        {(userDropdownOptions && [ ...Object.values(INTERNAL_ROLE_TYPES), EXTERNAL_ROLE_TYPES.admin ].includes(userRole)) && <Field
          data-public
          name='ownerUserId'
          validate={required({ message: 'forms.error_required' })}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <SemanticForm.Field>
              <label data-public>Room Owner *</label>
              <Dropdown
                id='room-owner-input'
                error={!!message && touched}
                fluid
                selection
                search
                {...input}
                options={userDropdownOptions}
                onChange={dropdownOnChange(input)}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </SemanticForm.Field>
          )}
        />}

        <Divider horizontal>
          <Header data-public as='h4'>
            <Icon name='fire' />
            Firewords
          </Header>
        </Divider>
        <Field
          data-public
          name='firewordNotificationsEnabled'
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <>
              <SemanticForm.Checkbox
                id='room-firewords-input'
                className='checkbox'
                label={t(`${TRANSLATION_PREFIX}.fireword_notifications_label`)}
                {...input}
                checked={!!input.value}
                disabled={forceFirewords}
                value='firewordNotificationsEnabled'
                onChange={checkboxOnChange(input)}
              />
              {(forceFirewords) && (
                <Label style={{ marginLeft: 20 }} basic color='blue' pointing='left'>
                  <Icon name='info circle' />Your organization requires that fireword notifications be enabled for all rooms
                </Label>
              )}
            </>
          )}
        />
        <ConditionalField
          data-public
          when='firewordNotificationsEnabled'
          name='firewordNotificationsForRoomCoursesOnly'
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <SemanticForm.Checkbox
              id='room-firewords-courses-input'
              style={{ marginLeft: 26, marginTop: 10 }}
              className='checkbox'
              label={t(`${TRANSLATION_PREFIX}.room_fireword_notifications_label`)}
              {...input}
              checked={!!input.value}
              value='firewordNotificationsForRoomCoursesOnly'
              onChange={checkboxOnChange(input)}
            />
          )}
        />
        <Divider horizontal>
          <Header as='h4' data-public>
            <Icon name='lock' />
            Access
          </Header>
        </Divider>
        {(enablePrivateRooms) && (
          <>
            <Field
              data-public
              name='isPrivate'
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <SemanticForm.Checkbox
                  id='room-private-input'
                  className='checkbox'
                  label={t(`${TRANSLATION_PREFIX}.room_private_label`)}
                  {...input}
                  disabled={disablePrivacyChange}
                  checked={!!input.value}
                  value='isPrivate'
                  onChange={(e, data) => {
                    form.mutators.setFieldValue('isOpenForRequests', false)
                    input.onChange(data.checked)
                  }}
                />
              )}
            />
            <ConditionalField
              data-public
              when='isPrivate'
              name='isOpenForRequests'
              condition={(val) => !val}
              alwaysRender={true}
              render={({
                input, conditionMet, meta: { error: { message, ...options } = {}, touched },
              }) => (
                <SemanticForm.Checkbox
                  id='room-requests-input'
                  className='checkbox'
                  label={t(`${TRANSLATION_PREFIX}.room_requests_label`)}
                  {...input}
                  disabled={!conditionMet}
                  checked={!!input.value}
                  value='isOpenForRequests'
                  onChange={checkboxOnChange(input)}
                />
              )}
            />
          </>
        )}
        {(!enablePrivateRooms) && (
          <Field
            data-public
            name='isOpenForRequests'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Checkbox
                id='room-private-input'
                className='checkbox'
                label={t(`${TRANSLATION_PREFIX}.room_requests_label`)}
                {...input}
                checked={!!input.value}
                value='isOpenForRequests'
                onChange={checkboxOnChange(input)}
              />
            )}
          />
        )}
      </SemanticForm>
    )
  }

  render () {
    const {
      onChange,
      name,
      imageUrl,
      ownerUserId,
      firewordNotificationsEnabled,
      firewordNotificationsForRoomCoursesOnly,
      isOpenForRequests,
      isPrivate,
    } = this.props
    return (
      <Form
        onSubmit={(d) => onChange({
          ...d,
          firewordNotificationsEnabled: !!d.firewordNotificationsEnabled,
          firewordNotificationsForRoomCoursesOnly: !!d.firewordNotificationsForRoomCoursesOnly,
          isOpenForRequests: !!d.isOpenForRequests,
        })}
        subscription={{
          pristine: true,
          invalid: true,
        }}
        initialValues={{
          name,
          imageUrl,
          ownerUserId,
          firewordNotificationsEnabled: isNil(firewordNotificationsEnabled) ? true : firewordNotificationsEnabled,
          firewordNotificationsForRoomCoursesOnly,
          isOpenForRequests,
          isPrivate,
        }}
        render={this.renderForm}
        mutators={{ setFieldValue }}
      />
    )
  }
}

export default translate([ 'components' ])(CreateRoomPage1)
