import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import {
  get,
  groupBy,
  sortBy,
  filter,
  flatMap,
  map,
  find,
  difference,
  isNumber,
  compact,
  flatten,
} from 'lodash'
import {
  Button,
  Header,
  Segment,
  Image,
  Breadcrumb,
  Icon,
  Tab,
  Menu,
  Label,
} from 'semantic-ui-react'

import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_STAFF_BY_ORG_ID from '../../../../../helpers/graphql-queries/get-staff-by-org-id'
import GET_STUDENT_DETAILS from '../../../../../helpers/graphql-queries/get-student-details'
import GET_ROOM_BY_ID from '../../../../../helpers/graphql-queries/get-room-by-id'
import RESET_REGISTRATION from '../../../../../helpers/graphql-queries/reset-registration'
import UNLOCK_REGISTRATION from '../../../../../helpers/graphql-queries/unlock-registration'
// import ProgressBar from '../../../../common/progress-bar'
// import printElement from '../../../../../helpers/print-element'
// import CourseAnswers from '../../../../common/course-answers'
import CourseProgressView from '../course-progress/course-progress'
import UnreviewedFirewordsView from '../unreviewed-firewords/unreviewed-firewords'
import { getClientWithOverview } from '../../../../../actions/clients'
import { getClientAnswersWithFirewords, invalidateClientAnswers } from '../../../../../actions/answers'
import isStale from '../../../../../helpers/is-stale'
import {
  FETCH_ACTIONS,
} from '../../../../../helpers/fetch-constants'
import {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
} from '../../../../../actions/analyzed-answers'
import { createIgnisRating, acknowledgeCreateIgnisRating } from '../../../../../actions/ignis-ratings'
import { getClientEnrollments, invalidateClientEnrollments } from '../../../../../actions/enrollments'
import FetchResultMessage from '../../../../common/fetch-result-message'
import { getDisplayName } from '../../../../../helpers/user'
import ResetEnrollmentModal from '../../../../common/reset-enrollment-modal/reset-enrollment-modal'
import exportToCsv from '../../../../../helpers/export-to-csv'
import { WELCOME_COURSE_IDS } from '../../../../../helpers/module-constants'

import './rooms.css'
const TRANSLATION_PREFIX = 'views.organization.client-details'

const roomIdPathParam = ':roomId'
const studentIdPathParam = ':studentId'
const path = `/rooms/${roomIdPathParam}/students/${studentIdPathParam}`
const alternatePath = `/all-rooms/${roomIdPathParam}/students/${studentIdPathParam}`
const getRelevantAnswer = (ans) => (ans.hasUnreviewedFirePhrases && (isNumber(ans.attemptNumber) || ans.attemptNumber.startsWith('room_')))
// const getRelevantFirePhraseCapture = (fpc) => (isNumber(fpc.attemptNumber) || !fpc.attemptNumber.startsWith('room_'))

export const helpers = {
  // all urls under a given room
  getRoomDetailsStudentPath: (roomId, studentId, isAlternate) => (isAlternate) ? `${alternatePath.replace(roomIdPathParam, roomId).replace(studentIdPathParam, studentId)}` : `${path.replace(roomIdPathParam, roomId).replace(studentIdPathParam, studentId)}`,

}

export class RoomDetailsStudentView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    homeOrganization: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    getEnrollmentsState: PropTypes.object.isRequired,
    getClientWithOverview: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    createAnalyzedAnswer: PropTypes.func.isRequired,
    acknowledgeCreateAnalyzedAnswer: PropTypes.func.isRequired,
    createIgnisRating: PropTypes.func.isRequired,
    acknowledgeCreateIgnisRating: PropTypes.func.isRequired,
    getClientEnrollments: PropTypes.func.isRequired,
    invalidateClientEnrollments: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool.isRequired,
    getAnswersState: PropTypes.object.isRequired,
    getClientAnswersWithFirewords: PropTypes.func.isRequired,
    invalidateClientAnswers: PropTypes.func.isRequired,
    postAnalyzedAnswersState: PropTypes.object.isRequired,
  }

  static path = path

  static alternatePath = alternatePath

  constructor (props) {
    super(props)
    this.state = {
      showAddStudentsModal: false,
      showAddCoursesModal: false,
      showEditRoomModal: false,
      selectedUser: false,
      activePageNumber: 1,
      activeTabIndex: 0,
      filter: '',
      selectedCourse: null,
      launchCourseId: null,
      printCourseId: null,
      searchResults: [],
      isSearching: false,
      shouldRefetch: false,
      resetEnrollmentId: null,
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  handleTabChange = (event, data) => this.setState({ activeTabIndex: data.activeIndex })

  componentDidMount () {
    const {
      authState: { accessToken },
      getClientsState,
      getClientWithOverview,
      getEnrollmentsState,
      getAnswersState,
      getClientAnswersWithFirewords,
      getClientEnrollments,
      match: { params: { studentId } },
    } = this.props

    if (isStale(getClientsState)) {
      getClientWithOverview({
        accessToken,
        id: studentId,
      })
    }
    if (isStale(getEnrollmentsState)) {
      getClientEnrollments({ accessToken, clientId: studentId })
    }
    if (isStale(getAnswersState)) {
      getClientAnswersWithFirewords({ accessToken, clientId: studentId })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      getAnswersState,
      getEnrollmentsState,
      getClientAnswersWithFirewords,
      getClientEnrollments,
      invalidateClientAnswers,
      invalidateClientEnrollments,
      match: { params: { studentId } },
    } = this.props

    if (prevProps.match.params.studentId !== studentId) {
      invalidateClientAnswers()
      invalidateClientEnrollments()
    }
    if (isStale(getAnswersState)) {
      getClientAnswersWithFirewords({ accessToken, clientId: studentId })
    }
    if (isStale(getEnrollmentsState)) {
      getClientEnrollments({ accessToken, clientId: studentId })
    }
  }

  render () {
    const {
      getCoursesState,
      getEnrollmentsState,
      getClientsState,
      organization,
      t,
      invalidateClientEnrollments,
      match: {
        params: {
          roomId, studentId,
        },
      },
      showFriendlyApiErrorMessages,
      location: { pathname },
    } = this.props

    if (getCoursesState.isLoading) {
      return (<FullScreenLoadingOverlay isActive={true}/>)
    }
    const coursesById = get(getCoursesState, 'value')
    if (!coursesById) {
      return (
        <FetchResultMessage
          success={false}
          error={new Error('Modules not found')}
          showFriendlyError={showFriendlyApiErrorMessages}
        />
      )
    }
    return (
      <Query
        variables={{
          orgId: organization.id,
        }}
        query={GET_STAFF_BY_ORG_ID}
      >
        {({
          loading,
          data,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }

          return (
            <Query
              variables={{
                id: roomId,
              }}
              fetchPolicy='network-only'
              query={GET_ROOM_BY_ID}
            >
              {({
                loading,
                data,
                refetch: refetchRoomData,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const room = get(data, 'room')
                if (!room) {
                  return (
                    <FetchResultMessage
                      success={false}
                      error={new Error('Room not found')}
                      showFriendlyError={showFriendlyApiErrorMessages}
                    />
                  )
                }

                return (
                  <Query
                    variables={{
                      clientIds: [ studentId ],
                      courseIds: (room.courseIds && room.courseIds.length) ? room.courseIds : organization.courseIds,
                      privateRoomId: (room.isPrivate) ? room.id : undefined,
                    }}
                    query={GET_STUDENT_DETAILS}
                    fetchPolicy='network-only'
                    onCompleted={() => this.setState({
                      isSavingAnalyzedAnswer: false,
                      shouldRefetch: false,
                    })}
                  >
                    {({
                      loading: studentDataLoading,
                      data: studentData,
                      refetch: refetchStudentData,
                    }) => {
                      if (studentDataLoading) {
                        return (<FullScreenLoadingOverlay isActive={true}/>)
                      }
                      if (this.state.shouldRefetch) {
                        setTimeout(refetchStudentData, 10)
                      }
                      const user = get(studentData, 'studentDetails[0]') || {}
                      const roomEnrollmentIds = room.students.find((s) => s.id === studentId).enrollmentsMetadata.map((md) => md.id)
                      user.enrollments = user.enrollments.filter((en) => roomEnrollmentIds.includes(en.id))
                      const unreviewedFirePhraseCaptures = room.students.find((s) => s.id === studentId).unreviewedFirePhraseCaptures
                      const renderUnreviewedFirewordsTab = (unreviewedAnswers, allFirePhraseCaptures) => {
                        const {
                          match: { params: { studentId } },
                          authState,
                        } = this.props
                        return (
                          <Tab.Pane as='div'>
                            <UnreviewedFirewordsView
                              clientId={studentId}
                              unreviewedAnswers={unreviewedAnswers}
                              allFirePhraseCaptures={allFirePhraseCaptures}
                              authState={authState}
                              roomView={true}
                              roomFirePhraseCaptures={unreviewedFirePhraseCaptures}
                              onReview={() => {
                                refetchRoomData()
                                refetchStudentData()
                              }}
                            />
                          </Tab.Pane>
                        )
                      }

                      const renderCourseProgressTab = (answersByQuestionId, firePhrases) => {
                        const {
                          match: { params: { studentId } },
                          authState,
                          organization,
                        } = this.props
                        return (
                          <Tab.Pane as='div'>
                            <CourseProgressView
                              clientId={studentId}
                              answersByQuestionId={answersByQuestionId || {}}
                              firePhrases={firePhrases}
                              authState={authState}
                              organization={organization}
                              getEnrollmentsState={getEnrollmentsState}
                              getClientsState={getClientsState}
                              room={room}
                              roomCourses={room.courseIds}
                              invalidateClientEnrollment={invalidateClientEnrollments}
                              onReview={() => {
                                refetchRoomData()
                                refetchStudentData()
                              }}
                            />
                          </Tab.Pane>
                        )
                      }

                      const displayName = getDisplayName(user)
                      const roomHasCourses = (room.courseIds && !!room.courseIds.length)
                      const courseIds = (roomHasCourses || room.isPrivate) ? room.courseIds : difference(organization.courseIds, WELCOME_COURSE_IDS)
                      const roomCourses = sortBy(courseIds.map((courseId) => coursesById[courseId] || {}), (c) => get(c, 'titles') || get(c, 'title.en') || 'Unknown Course')

                      // coerce attemptNumber to numbers if possible
                      const enrollments = (!user.enrollments) ? [] : user.enrollments.map((enrollment) => ({
                        ...enrollment,
                        attemptNumber: enrollment.attemptNumber * 1 || enrollment.attemptNumber,
                      })).filter((enrollment) => (room.isPrivate) ? enrollment.attemptNumber === `room_${room.id}` : isNumber(enrollment.attemptNumber))
                      const answers = (!user.answers) ? [] : user.answers.map((answer) => ({
                        ...answer,
                        attemptNumber: answer.attemptNumber * 1 || answer.attemptNumber,
                      }))
                      const aas = (!user.analyzedAnswers) ? [] : user.analyzedAnswers.map((analyzedAnswer) => ({
                        ...analyzedAnswer,
                        attemptNumber: analyzedAnswer.attemptNumber * 1 || analyzedAnswer.attemptNumber,
                      }))
                      const fpcs = (!user.firePhraseCaptures) ? [] : user.firePhraseCaptures.map((firePhraseCapture) => ({
                        ...firePhraseCapture,
                        attemptNumber: firePhraseCapture.attemptNumber * 1 || firePhraseCapture.attemptNumber,
                      }))
                      const analyzedAnswersByQuestionId = groupBy(aas, 'questionId')
                      const firePhrasesByQuestionId = groupBy(fpcs, 'questionId')
                      const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
                      const hydratedAnswers = answers.map((ans) => {
                        const firePhraseCaptures = (!firePhrasesByQuestionId[ans.questionId]) ? [] : filter(firePhrasesByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                        const analyzedAnswer = (!analyzedAnswersByQuestionId[ans.questionId]) ? null : find(analyzedAnswersByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                        return {
                          ...ans,
                          firePhraseCaptures,
                          analyzedAnswer,
                          hasUnreviewedFirePhrases: (!!firePhraseCaptures.length && !analyzedAnswer),
                        }
                      })
                      const answersByQuestionId = groupBy(hydratedAnswers, 'questionId')
                      const unreviewedAnswers = filter(flatten(Object.values(answersByQuestionId || {})), getRelevantAnswer)
                      const allRelevantSeverities = unreviewedFirePhraseCaptures.map((fp) => {
                        return fp.analyzedCaptures.map((ac) => ac.ignisSeverity || ac.receptivitiSeverity || 3)
                      }) || [ 3 ]
                      const maxRelevantSeverities = allRelevantSeverities.map((scores) => Math.max(flatten(scores)) || 3)
                      const severityColors = [ 'green', 'yellow', 'orange', 'red' ]
                      const badgeColor = severityColors[Math.max(...maxRelevantSeverities)]
                      const panes = [
                        {
                          menuItem: t(`${TRANSLATION_PREFIX}.course_progress_label`),
                          render: renderCourseProgressTab.bind(this, answersByQuestionId, fpcs),
                          key: 'room_course_progress',
                        },
                        {
                          menuItem: (
                            <Menu.Item key='messages'>
                              {t(`${TRANSLATION_PREFIX}.unreviewed_firewords_label`)}
                              {(unreviewedFirePhraseCaptures.length > 0) && <Label data-public color={badgeColor} circular floating>{unreviewedFirePhraseCaptures.length}</Label>}
                            </Menu.Item>
                          ),
                          render: renderUnreviewedFirewordsTab.bind(this, unreviewedAnswers, fpcs),
                          key: 'room_unreviewed_firewords',
                        },
                      ]
                      const isAllRooms = pathname.includes('/all-rooms/')
                      const pathRegex = new RegExp(`/${(isAllRooms) ? 'all-' : ''}rooms/.+`)
                      const sections = [
                        {
                          key: (!isAllRooms) ? 'Home' : 'All Rooms', content: (!isAllRooms) ? 'Home' : 'All Rooms', link: true, onClick: () => this.navigateTo(pathname.replace(pathRegex, (!isAllRooms) ? '/rooms' : '/all-rooms')),
                        },
                        {
                          key: roomId, content: room.name, link: true, onClick: () => this.navigateTo(this.props.location.pathname.replace(pathRegex, (!isAllRooms) ? '/rooms' : '/all-rooms') + `/${roomId}`),
                        },
                        {
                          key: studentId, content: displayName, active: true,
                        },
                      ]

                      const onDownloadResponses = () => {
                        const exportData = roomCourses.flatMap((course) => {
                          if (!course || !enrollmentsByCourseId[course.id]) {
                            return null
                          }
                          const sortedContentItemsWithQuestions = sortBy(filter(course.contentItems, 'questionId'), 'ordinal')
                          const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                          const orderedQuestions = compact(courseQuestionIds.map((id) => find(course.questions, { id })))

                          return enrollmentsByCourseId[course.id].flatMap((enrollment) => {
                            return orderedQuestions.map((question) => {
                              const answers = get(answersByQuestionId, question.id) || []
                              const answer = find(answers, { attemptNumber: enrollment.attemptNumber }) || { answerText: '' }
                              return {
                                name: course.titles,
                                type: course.type,
                                attemptNumber: enrollment.attemptNumber,
                                question: question.title.replace(/\n/g, ' '),
                                response: answer.answerText.replace(/\n/g, ' '),
                                firewords: (answer.firePhraseCaptures && answer.firePhraseCaptures.length) ? map(flatMap(answer.firePhraseCaptures, 'analyzedCaptures'), 'sentence').join('; ') : '',
                              }
                            })
                          })
                        })
                        const date = new Date().toISOString().split('T')[0]
                        const fields = [ 'name', 'type', 'attemptNumber', 'question', 'response', 'firewords' ]
                        const simplifiedRoomName = `room-${room.name.toLowerCase().replace(/\s/g, '-').replace(/[^-\w]/g, '').replace(/-{2,}/g, '-')}`
                        exportToCsv(`${simplifiedRoomName}-${user.userName}-answers-${date}`, exportData, fields)
                      }
                      return (
                        <Mutation
                          mutation={UNLOCK_REGISTRATION}
                        >
                          {(unlockRegistration, { loading: isUnlocking }) => {
                            return (
                              <Mutation
                                mutation={RESET_REGISTRATION}
                                onCompleted={() => {
                                  this.setState({
                                    ...this.state,
                                    resetEnrollmentId: null,
                                  })
                                }}
                              >
                                {(resetRegistration, { loading: isSaving }) => {
                                  return (
                                    <>
                                      <ResetEnrollmentModal
                                        open={!!this.state.resetEnrollmentId}
                                        forPrivateRoom={room.isPrivate}
                                        onClose={() => this.setState({ ...this.state, resetEnrollmentId: null })}
                                        onOptionSelect={(option) => {
                                          resetRegistration({
                                            variables: {
                                              id: this.state.resetEnrollmentId,
                                              createNew: (option === 'new'),
                                            },
                                          }).then(refetchStudentData)
                                        }}
                                      />
                                      <Segment basic className='room-details'>
                                        <Breadcrumb icon='right angle' sections={sections} />
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                          <Header as='h2' style={{ flexGrow: 1, margin: '15px 0' }}>
                                            <Image src={room.imageUrl} />
                                            <Header.Content>
                                              {room.name}
                                              <Header.Subheader><Icon style={{ marginRight: 4 }} name='write' /> {`Responses for: ${displayName}`}</Header.Subheader>
                                            </Header.Content>
                                          </Header>
                                          <div style={{ flexShrink: 1, marginTop: 15 }}>
                                            <Button
                                              className='base-teal-bg text white'
                                              size='small'
                                              onClick={onDownloadResponses}
                                              data-public
                                            >
                                              <Icon style={{ marginRight: 4 }} name='download' /> Download Responses
                                            </Button>
                                          </div>
                                        </div>
                                        <br/>
                                        <div>
                                          {/* <div className='print-only' ref={this.printElem}>
                          <Header>Responses for: {displayName}</Header>

                          <Segment basic key={`print-user-room-answers-${user.clientId}`}>
                            {roomCourses.map((c) => {
                              const sortedContentItemsWithQuestions = sortBy(filter(c.contentItems, 'questionId'), 'ordinal')
                              const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                              const answerCount = courseQuestionIds.filter((id) => answersByQuestionId[id] && answersByQuestionId[id].length).length
                              const questionsById = keyBy(c.questions, 'id')
                              return (
                                <Segment key={`print-user-room-course-answers-${user.clientId}-${c.id}`}>
                                  <Header size='small'>{c.titles} <span className='text italic blue'>{(!answerCount) ? '(No Responses)' : `( ${answerCount} / ${c.questions.length} )`}</span></Header>

                                  {courseQuestionIds.map((qId, i) => {
                                    const q = questionsById[qId]
                                    if (!q) {
                                      return null
                                    }
                                    const ans = answersByQuestionId[q.id]
                                    const aa = analyzedAnswersByQuestionId[q.id]
                                    const fps = firePhrasesByQuestionId[q.id] || []
                                    if (!ans) {
                                      return null
                                    }
                                    return (
                                      <QuestionCard
                                        key={`print-user-room-answers-${user.clientId}-${c.id}-${q.id}`}
                                        questionNumber={i + 1}
                                        questionText={q.title}
                                        answerText={(ans[0]) ? ans[0].answerText : ''}
                                        notes={q.notes}
                                        analyzedCaptures={flatten(fps.map((fp) => fp.analyzedCaptures))}
                                        analyzedAnswer={(aa) ? { ...aa, reviewer: { userName: get(staffById, `${aa.reviewerClientId}.userName`, 'A Staff Member') } } : null}
                                      />
                                    )
                                  })}
                                </Segment>
                              )
                            })}
                          </Segment>
                        </div> */}
                                          {/* {roomCourses.map((course) => {
                                            if (!course) {
                                              return null
                                            }
                                            const sortedContentItemsWithQuestions = sortBy(filter(course.contentItems, 'questionId'), 'ordinal')
                                            const courseQuestionIds = map(sortedContentItemsWithQuestions, 'questionId')
                                            const orderedQuestions = courseQuestionIds.map((id) => find(course.questions, { id }))
                                            return (
                                              <CourseAnswers
                                                key={`answers-${course.id}-${user.clientId}`}
                                                id={`answers-${course.id}-${user.clientId}`}
                                                courseTitle={course.titles}
                                                questions={orderedQuestions}
                                                answersByQuestionId={answersByQuestionId}
                                                analyzedAnswersByQuestionId={analyzedAnswersByQuestionId}
                                                firePhrasesByQuestionId={firePhrasesByQuestionId}
                                                courseEnrollments={enrollmentsByCourseId[course.id]}
                                                clientsById={staffById}
                                                userName={user.userName}
                                                displayName={displayName}
                                                isSaving={postAnalyzedAnswersState.isLoading}
                                                onReviewedClick={handleAcknowledgeFireword}
                                                onIgnisRate={(rating, questionText, questionId, sentence, answerId, analyzedCaptures) => handleRateIgnis(rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData)}
                                                previousIgnisRatings={user.ignisRatings}
                                                firewordsEnabled={organization.firewordsEnabled}
                                                hideEmpty={!roomHasCourses}
                                                courseContentItems={course.contentItems}
                                                onResetClick={(enrollmentId) => this.setState({ ...this.state, resetEnrollmentId: enrollmentId })}
                                                onUnlockClick={(enrollmentId) => unlockRegistration({
                                                  variables: {
                                                    id: enrollmentId,
                                                  },
                                                }).then(refetchStudentData)}
                                                isUnlocking={isUnlocking}
                                              />
                                            )
                                          })} */}
                                          <Tab
                                            renderActiveOnly={true}
                                            onTabChange={this.handleTabChange}
                                            menu={{ pointing: true }}
                                            activeIndex={this.state.activeTabIndex}
                                            panes={panes}
                                          />
                                        </div>
                                      </Segment>
                                    </>
                                  )
                                }}
                              </Mutation>
                            )
                          }}
                        </Mutation>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: { [FETCH_ACTIONS.GET_ALL]: getClientsState },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    enrollments: { [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState },
    answers: { [FETCH_ACTIONS.GET_ALL]: getAnswersState },
    analyzedAnswers: {
      [FETCH_ACTIONS.POST]: postAnalyzedAnswersState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state
  return {
    postAnalyzedAnswersState,
    showFriendlyApiErrorMessages,
    getClientsState,
    getCoursesState,
    getEnrollmentsState,
    getAnswersState,
  }
}
const mapDispatchToProps = {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
  createIgnisRating,
  acknowledgeCreateIgnisRating,
  getClientWithOverview,
  getClientEnrollments,
  invalidateClientEnrollments,
  getClientAnswersWithFirewords,
  invalidateClientAnswers,
}
const ClientRoomDetailsStudentViewContainer = connect(mapStateToProps, mapDispatchToProps)(RoomDetailsStudentView)

export default translate([ 'components' ])(ClientRoomDetailsStudentViewContainer)
