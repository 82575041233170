import { FETCH_ACTIONS, HTTP_METHODS } from '../helpers/fetch-constants'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'

const STORE_PATH_PREFIX = 'password'

export function resetPassword ({ resetToken, newSecret }) {
  return callApi(HTTP_METHODS.POST, null, '/secretReset', { resetToken, newSecret }, {}, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'resetPassword', true)
}

export function requestPasswordReset ({ userName }) {
  return callApi(HTTP_METHODS.POST, null, '/secretResetRequests', { userName }, {}, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'requestPasswordReset', true)
}

export function changePassword ({
  clientId,
  newSecret,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.PUT, clientId, `/clients/${clientId}/secret`, { newSecret }, { headers }, FETCH_ACTIONS.PUT, STORE_PATH_PREFIX, 'changePassword', true)
}

export function acknowledgeChangePassword () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeChangePassword' ]
}

export function acknowledgePasswordResult () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgePasswordResult' ]
}
