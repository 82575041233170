import React from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Segment,
  Header,
  Popup,
  Icon,
  Button,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  takeRight,
  compact,
  keyBy,
} from 'lodash'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'

import dropdownSearch from '../../../helpers/dropdown-search'
import exportToCsv from '../../../helpers/export-to-csv'
import getRoles from '../../../helpers/get-roles'

import './user-activity.css'

const TRANSLATION_PREFIX = 'charts.user-activity'

// const getBlankData = (daysAgo) => [ ...Array(daysAgo) ].map((_, i) => ({ x: moment().subtract(daysAgo - i, 'days').format('YYYY-MM-DD'), y: 0 }))
const ROLES = getRoles()
const ROLES_BY_TYPE = keyBy(ROLES, 'type')

class UserActivityChart extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedTimePeriod: 7,
      selectedRoleTypes: [],
    }
  }

  static propTypes = {
    rolesWithActivityByRoleType: PropTypes.object,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    hideRoleSelection: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    forRoom: PropTypes.bool,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    rolesWithActivityByRoleType: {},
    isLoading: false,
    hideRoleSelection: false,
    width: '100%',
    height: '400px',
  }

  changeTimePeriod = (e, data) => {
    this.setState({ ...this.state, selectedTimePeriod: data.value })
  }

  changeRoleTypes = (e, data) => {
    this.setState({ ...this.state, selectedRoleTypes: data.value })
  }

  renderRoleOption = (roleType) => ({ text: ROLES_BY_TYPE[roleType].name, value: roleType })

  downloadData = () => {
    const {
      rolesWithActivityByRoleType,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
    } = this.state

    const dailyData = []
    const weeklyData = []
    const monthlyData = []
    const rolesByType = rolesWithActivityByRoleType || {}
    const roleTypes = (!selectedRoleTypes || !selectedRoleTypes.length) ? Object.keys(rolesByType) : selectedRoleTypes

    Object.keys(rolesByType).forEach((roleType, i) => {
      if (!roleTypes.includes(roleType)) {
        return
      }
      const role = rolesByType[roleType]
      role.activity.daily.forEach((data, i) => {
        dailyData[i] = (!dailyData[i]) ? data : { x: data.x, y: dailyData[i].y + data.y }
      })
      role.activity.weekly.forEach((data, i) => {
        weeklyData[i] = (!weeklyData[i]) ? data : { x: data.x, y: weeklyData[i].y + data.y }
      })
      role.activity.monthly.forEach((data, i) => {
        monthlyData[i] = (!monthlyData[i]) ? data : { x: data.x, y: monthlyData[i].y + data.y }
      })
    })

    const visibleDailyData = takeRight(dailyData, period)
    const visibleWeeklyData = takeRight(weeklyData, period)
    const visibleMonthlyData = takeRight(monthlyData, period)

    const data = visibleDailyData.map((dailyData, i) => {
      return {
        date: dailyData.x,
        dailyCount: dailyData.y,
        weeklyCount: visibleWeeklyData[i].y,
        monthlyCount: visibleMonthlyData[i].y,
      }
    })
    const date = new Date().toISOString().split('T')[0]

    exportToCsv(`BASE-user-activity-${date}-last-${period}-days.csv`, data, [ 'date', 'dailyCount', 'weeklyCount', 'monthlyCount' ])
  }

  componentDidUpdate (prevProps) {
    // const {
    //   rolesWithActivityByRoleType,
    // } = this.props
    // const {
    //   selectedRoleTypes,
    // } = this.state
    // if (selectedRoleTypes && selectedRoleTypes.length) {
    //   const rolesByType = rolesWithActivityByRoleType || {}
    //   const roleTypes = Object.keys(rolesByType)
    //   // if the org has changed, let's reset the selected roles
    //   if (!roleTypes.includes(selectedRoleTypes[0])) {
    //     this.setState({ ...this.state, selectedRoleTypes: [] })
    //   }
    // }
  }

  render () {
    const {
      rolesWithActivityByRoleType,
      hideRoleSelection,
      width,
      height,
      t,
      isLoading,
      forRoom,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
    } = this.state

    const dailyData = []
    const weeklyData = []
    const monthlyData = []
    const rolesByType = rolesWithActivityByRoleType || {}
    const roleTypes = (!selectedRoleTypes || !selectedRoleTypes.length) ? Object.keys(rolesByType) : selectedRoleTypes

    Object.keys(rolesByType).forEach((roleType, i) => {
      if (!roleTypes.includes(roleType)) {
        return
      }
      const role = rolesByType[roleType]
      role.activity.daily.forEach((data, i) => {
        dailyData[i] = (!dailyData[i]) ? data : { x: data.x, y: dailyData[i].y + data.y }
      })
      role.activity.weekly.forEach((data, i) => {
        weeklyData[i] = (!weeklyData[i]) ? data : { x: data.x, y: weeklyData[i].y + data.y }
      })
      role.activity.monthly.forEach((data, i) => {
        monthlyData[i] = (!monthlyData[i]) ? data : { x: data.x, y: monthlyData[i].y + data.y }
      })
    })

    const dropdownOptions = [
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_week`), value: 7 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_30_days`), value: 30 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_90_days`), value: 90 },
    ]
    const visibleDailyData = takeRight(dailyData, period)
    const visibleWeeklyData = takeRight(weeklyData, period)
    const visibleMonthlyData = takeRight(monthlyData, period)
    const xAxisTickLabelInterval = Math.round(period / 7)
    const hasData = visibleMonthlyData.some((d) => !!d.y)
    return (
      <div>
        <Segment.Group className='userActivity'>
          <Segment loading={isLoading}>
            <div style={{
              display: 'flex', alignItems: 'center', alignContent: 'flex-start',
            }}>
              <Header style={{ margin: 0 }} data-public className='activityHeader'>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
              <Popup
                trigger={(
                  <div style={{
                    paddingLeft: 8, flexGrow: 2, fontSize: '1.2em',
                  }}>
                    <Icon className='base-teal' name='help circle' />
                  </div>
                )}
                position='bottom left'
                hoverable
              >
                <p data-public>{t((forRoom) ? `${TRANSLATION_PREFIX}.help.first_sentence_room` : `${TRANSLATION_PREFIX}.help.first_sentence`)}</p>
                <p data-public>{t(`${TRANSLATION_PREFIX}.help.second_sentence`)}</p>
                {(!hideRoleSelection) && (
                  <p data-public>{t(`${TRANSLATION_PREFIX}.help.third_sentence`)}</p>
                )}
              </Popup>
              <div>
                {t(`${TRANSLATION_PREFIX}.time_period_prefix`) + ' '}
                <Dropdown
                  data-public
                  className='user-activity-input'
                  inline
                  options={dropdownOptions}
                  defaultValue={this.state.selectedTimePeriod}
                  onChange={this.changeTimePeriod}
                />
              </div>
              <Button
                data-public
                className='downloadButton'
                basic
                size='tiny'
                icon='download'
                title={t(`${TRANSLATION_PREFIX}.download_button_label`)}
                onClick={this.downloadData}
              />
            </div>
            {(!hasData && !isLoading) && (
              <Message info>
                <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.no_activity_header`)}</Message.Header>
                <p data-public>{t(`${TRANSLATION_PREFIX}.no_activity_description`)}</p>
              </Message>
            )}
            {(hasData) && (
              <div style={{ height, width }}>
                <ResponsiveLine
                  margin={{
                    top: 20,
                    right: 100,
                    bottom: 40,
                    left: 50,
                  }}
                  enablePoints={false}
                  data={[
                    {
                      id: t(`${TRANSLATION_PREFIX}.series_labels.daily`),
                      data: visibleDailyData,
                      color: '#4d9965',
                    }, {
                      id: t(`${TRANSLATION_PREFIX}.series_labels.weekly`),
                      data: visibleWeeklyData,
                      color: '#339cff',
                    }, {
                      id: t(`${TRANSLATION_PREFIX}.series_labels.monthly`),
                      data: visibleMonthlyData,
                      color: '#003050',
                    },
                  ]}
                  animate={false}
                  isInteractive={true}
                  enableSlices='x'
                  enableGridX={false}
                  curve="monotoneX"
                  yScale={{ type: 'linear', stacked: false }}
                  lineWidth={4}
                  colors={{ datum: 'color' }}
                  axisBottom={{
                    tickValues: compact(visibleDailyData.map((d, i) => (i % xAxisTickLabelInterval) ? null : d.x)),
                    format: (val) => moment(val).format('MMM D'),
                    tickRotation: -45,
                  }}
                  sliceTooltip={(data) => {
                    return (
                      <div style={{
                        backgroundColor: 'white',
                        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.3)',
                        padding: 15,
                        borderRadius: 3,
                      }}>
                        <strong>{moment(data.slice.points[0].data.x).format('MMM D, YYYY')}</strong>
                        <hr/>
                        {data.slice.points.map((d) => (
                          <div
                            key={d.serieId}
                            style={{
                              padding: '5px 0',
                              verticalAlign: 'middle',
                            }}
                          >
                            <span style={{
                              display: 'inline-block',
                              width: '15px',
                              height: '15px',
                              marginRight: '8px',
                              marginBottom: '-2px',
                              background: d.serieColor,
                            }} />
                            {d.serieId} <strong>({d.data.y})</strong>
                          </div>
                        ))}
                      </div>
                    )
                  }}
                  legends={[
                    {
                      anchor: 'bottom-right',
                      direction: 'column',
                      itemWidth: 120,
                      itemHeight: 20,
                      translateX: 140,
                      itemDirection: 'left-to-right',
                    },
                  ]}
                />
              </div>
            )}
          </Segment>
          {(!hideRoleSelection) && (
            <Segment>
              <Dropdown
                data-public
                className='user-activity-input'
                multiple
                search={dropdownSearch()}
                selection
                fluid
                upward
                placeholder={t(`${TRANSLATION_PREFIX}.role_filter_label`)}
                options={Object.keys(rolesByType).sort().map(this.renderRoleOption)}
                defaultValue={this.state.selectedRoleTypes}
                onChange={this.changeRoleTypes}
              />
            </Segment>
          )}
        </Segment.Group>
      </div>
    )
  }
}

export default translate([ 'components' ])(UserActivityChart)
