import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
} from 'semantic-ui-react'
import { get } from 'lodash'
import { translate } from 'react-i18next'
import LogRocket from 'logrocket'

const TRANSLATION_PREFIX = 'common.graphql-error-message'

class GraphqlErrorMessage extends Component {
  static propTypes = {
    error: PropTypes.any,
    ignoreError: PropTypes.bool,
    t: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      isAcknowledged: false,
    }
  }

  handleClose = () => {
    this.setState({ isAcknowledged: true })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ isAcknowledged: false })
    }
  }

  render () {
    const {
      error,
      ignoreError,
      t,
    } = this.props

    const {
      isAcknowledged,
    } = this.state
    if (error && !ignoreError) {
      console.error(error)
      LogRocket.captureException(new Error('GraphQL API Error'))
    }
    const statusCode = get(error, 'networkError.statusCode')
    const graphQLErrors = get(error, 'graphQLErrors')
    const message = (statusCode)
      ? t(`${TRANSLATION_PREFIX}.message.${statusCode}`)
      : (graphQLErrors && graphQLErrors.length)
        ? graphQLErrors.map((err) => err.message).join(';')
        : t(`${TRANSLATION_PREFIX}.message.unknown`)

    return (
      <Modal
        onClose={this.handleClose}
        open={error && !ignoreError && !isAcknowledged}
        closeIcon >
        <Header data-public><Icon name='warning circle' color='red'/>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
        <Modal.Content>
          <Modal.Description>
            <p data-public>{message}</p>
            <p data-public>{t(`${TRANSLATION_PREFIX}.support`)}</p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default translate([ 'components' ])(GraphqlErrorMessage)
