import gql from 'graphql-tag'

const GET_USER_COURSE_INFO = gql`
query getUserCourseInfo($clientId: String!, $courseIds: [String], $privateRoomId: String) {
  userCourseDetails(
    clientId: $clientId
    courseIds: $courseIds
    privateRoomId: $privateRoomId
  ) {
      profile {
        firstName
        lastName
      }
      enrollments {
        id
        courseId
        attemptNumber
        contentItemProgresses {
          ordinal
          startedOn
          completedOn
        }
        completedOn
        startedOn
        created
      }
      firePhraseCaptures {
        id
        attemptNumber
        questionId
        created
        analyzedCaptures {
          matchedPhrases {
            id
            matchedPhraseText
            matchedSentenceSegment
          }
          isSafe
          sentence
          receptivitiSeverity
          ignisSeverity
        }
      }
      answers {
        id
        answerText
        attemptNumber
        created
        questionId
      }
      analyzedAnswers {
        id
        attemptNumber
        reviewerClientId
        isSafeReason
        isSafeTimestamp
        isSafe
        questionId
      }
      ignisRatings {
        answerId
        rating
      }
    }
}
`

export default GET_USER_COURSE_INFO
