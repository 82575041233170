import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Container, Icon, Message,
} from 'semantic-ui-react'
import LogRocket from 'logrocket'

import './top-level-error-boundary.css'

export default class TopLevelErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
    redirectOnError: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true })

    // captured by LogRocket
    console.error({ error, info })
    LogRocket.captureException(error)

    // clear cache to resolve any data issues and send them back to the login page
    window.sessionStorage.clear()
    const promise = window.indexedDB.databases()
    promise.then((databases) => {
      databases.forEach((db) => {
        window.indexedDB.deleteDatabase(db.name)
      })
      if (this.props.redirectOnError) {
        setTimeout(() => {
          window.location.replace('/')
        }, 5000)
      }
    })
  }

  render () {
    if (this.state.hasError) {
      return (
        <Container text className='top-level-error-boundary'>
          <Message negative icon>
            <Icon name='attention' />
            <Message.Content>
              <Message.Header data-public>We are sorry! Something went wrong.</Message.Header>
              <p data-public>This error has been recorded and we will try to resolve this as quickly as possible. In a few seconds we will take you back to the login screen so you can try again.</p>
            </Message.Content>
          </Message>
        </Container>
      )
    }
    return this.props.children
  }
}
