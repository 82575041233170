import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'
import { translate } from 'react-i18next'

import './question-answer-segment.css'
import '../../common.css'

const QuestionAnswerSegment = ({
  color,
  number,
  text,
  type,
  t,
}) => {
  const labelText = t(`resource_types.${type}`, { postProcess: 'titleCase' })
  return (
    <div className='question-answer-segment'>
      <div data-public className='question-header no-print'>
        {(type === 'question' && number > 0) && <Label className='text white base-teal-bg' circular>{number}</Label>}
        <strong data-public>{labelText}</strong>
      </div>
      <div className='question-text'>
        {(type === 'question' && number > 0) && <span className='inline-print-only'>{number}. </span>}
        {text}
      </div>
    </div>
  )
}

QuestionAnswerSegment.propTypes = {
  number: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([ 'question', 'answer' ]),
  t: PropTypes.func.isRequired,
}

QuestionAnswerSegment.defaultProps = {
  number: 0,
  color: 'blue',
  type: 'question',
}

export default translate([ 'components' ])(QuestionAnswerSegment)
