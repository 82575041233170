import gql from 'graphql-tag'

const GET_NOTIFICATIONS_ON_BY_ORG_ID = gql`
query notificationsOnByOrgId($orgId: ID!) {
  notificationsOnByOrgId(
    orgId: $orgId
  ) {
    hasFirewordNotificationsEnabled,
    hasHelpNotificationsEnabled
    }
}
`
export default GET_NOTIFICATIONS_ON_BY_ORG_ID
