import path from 'path'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
// need to preload any locales that the app uses
// eslint-disable-next-line no-unused-vars
import esLocalization from 'moment/locale/es'
import {
  set,
  get,
  intersection,
  isObject,
  isArray,

} from 'lodash'
import { titleCase } from 'i18next-change-case-post-processor'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import LANGUAGES from './helpers/languages'

const resources = LANGUAGES.reduce((accum, langObj) => ({ ...accum, [langObj.value]: {} }), {})

const parseObjectDeep = (lang, object) => {
  if (!isObject(object)) {
    return object
  }
  if (isArray(object)) {
    throw new Error('Invalid array in language file')
  }
  if (Object.keys(object).includes(lang)) {
    return object[lang]
  }
  return Object.keys(object).reduce((accum, key) => {
    if (!isObject(object[key])) {
      return accum
    }
    return {
      ...accum,
      [key]: parseObjectDeep(lang, object[key]),
    }
  }, {})
}

// Jest doesn't like 'require.context' and we don't need the translations for tests
if (process.env.NODE_ENV !== 'test') {
  const req = require.context('./', true, /\.lang\.json$/)
  req.keys()
    .sort()
    .forEach((fileName) => {
      const directory = path.dirname(fileName)
      const baseFileNameParts = path.basename(fileName, '.json').split('.')
      let objPath

      if (directory === '.') {
        objPath = baseFileNameParts[0]
      } else {
        objPath = directory.replace(new RegExp(`^\\.${path.sep}`), '')
          .split(path.sep)
          .join('.')
      }
      const allLangRes = req(fileName)
      LANGUAGES.forEach((langObj) => {
        const existingValue = get(resources[langObj.value], objPath)
        let res = parseObjectDeep(langObj.value, allLangRes)
        if (existingValue) {
          const dupTranslationKeys = intersection(Object.keys(existingValue), Object.keys(res))
          if (dupTranslationKeys.length) {
            throw Error('Duplicate translation keys: ' + dupTranslationKeys.join(', '))
          }
          res = Object.assign({}, existingValue, res)
        }
        set(resources[langObj.value], objPath, res)
      })
    })
}

i18n
  .use(LanguageDetector)
  .use(titleCase)
  .use(intervalPlural)
  .init({
    resources,
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: [ 'components' ],
    defaultNS: 'components',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'lowercase') return value.toLowerCase()
        if (value instanceof Date) return moment(value).format(format)
        return value
      },
    },

    react: {
      wait: false,
      // these options are set to false for perf reasons
      // https://github.com/i18next/react-i18next/issues/456
      bindStore: false,
      bindI18n: false,
    },
  })

moment.locale(i18n.language)

// disable this for now since we are no longer firing the languageChanged event for perf reasons
// i18n.on('languageChanged', function (lng) {
//   moment.locale(lng)
// })

export default i18n
