import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  sortBy,
  compact,
  flatMap,
  keyBy,
} from 'lodash'
import {
  Button,
  Dropdown,
  Form,
} from 'semantic-ui-react'
import { Field } from 'react-final-form'
import ztable from 'ztable'

import ConditionalField from '../../../../common/conditional-field/conditional-field'
import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay'

import AssessmentMeanScoresSummaryCard from '../../../../common/assessment-mean-scores-summary-card/assessment-mean-scores-summary-card'
import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'
import GET_USER_ASSESSMENT_SCORES from '../../../../../helpers/graphql-queries/get-user-assessment-scores'
import GET_ORG_USER_ASSESSMENT_SCORES from '../../../../../helpers/graphql-queries/get-org-user-assessment-scores' // this is not in dev... why?
import GET_USER_ASSESSMENTS from '../../../../../helpers/graphql-queries/get-user-assessments'
import GET_ASSESSMENT from '../../../../../helpers/graphql-queries/get-assessment'
import GET_USER_PROFILE from '../../../../../helpers/graphql-queries/get-user-profile'
import UPDATE_USER_PROFILE from '../../../../../helpers/graphql-queries/update-user-profile'
import GET_RECOMMENED_COURSE_IDS_BY_USER_ID from '../../../../../helpers/graphql-queries/get-recommended-course-ids-by-user-id'
import { INTERNAL_ROLE_TYPES } from '../../../../../helpers/get-roles'
import config from '../../../../../config.js'
import {
  dropdownOnChange,
  checkboxOnChange,
} from '../../../../../helpers/form'
import BaseForm from '../../../../forms/base-form'
import { required } from '../../../../../helpers/form-validators'
import {
  getClientWithOverview,
} from '../../../../../actions/clients'
import exportToCsv from '../../../../../helpers/export-to-csv'
import isInSchoolYear from '../../../../../helpers/is-in-school-year'
import { findParentContractedCustomerOrSiteOrg } from '../../../../../helpers/organization.js'

const TRANSLATION_PREFIX = 'views.organization.client-details.baseline'
const ASSESSMENTS_DECLINED_REASONS = [ 'emotional', 'time', 'privacy', 'language' ]

const ReasonsDropdown = ({
  t,
  onChange,
  defaultValue,
  ...otherProps
}) => {
  const hasCustomDeclinedReason = (defaultValue && !ASSESSMENTS_DECLINED_REASONS.includes(defaultValue))
  const [ currentValue, setCurrentValue ] = useState(defaultValue)
  const [ assessmentsDeclinedReasons, setAssessmentsDeclinedReasons ] = useState((!hasCustomDeclinedReason) ? ASSESSMENTS_DECLINED_REASONS : [
    ...ASSESSMENTS_DECLINED_REASONS,
    defaultValue,
  ])

  const handleReasonAddition = (e, { value }) => {
    setAssessmentsDeclinedReasons([
      ...ASSESSMENTS_DECLINED_REASONS,
      `other:${value}`,
    ])
  }

  const getAssessmentsDeclinedReasonOptions = (value) => {
    if (value.startsWith('other:')) {
      return { value, text: `Other: ${value.substr(6)}` }
    }
    return { value, text: t(`${TRANSLATION_PREFIX}.assessments_declined_options.${value}`) }
  }
  return (
    <Dropdown
      {...otherProps}
      value={currentValue}
      fluid
      selection
      search
      options={assessmentsDeclinedReasons.map(getAssessmentsDeclinedReasonOptions)}
      onChange={(e, { value }) => {
        const val = value.replace(/^other:/, '')
        if (!ASSESSMENTS_DECLINED_REASONS.includes(val)) {
          setCurrentValue(`other:${val}`)
          onChange(e, { value: `other:${val}` })
        } else {
          setCurrentValue(val)
          onChange(e, { value: val })
        }
      }}
      allowAdditions
      onAddItem={handleReasonAddition}
      additionLabel={<i style={{ color: 'red' }}>{t(`${TRANSLATION_PREFIX}.other_reason`)}: </i>}
    />
  )
}
ReasonsDropdown.propTypes = {
  defaultValue: PropTypes.string,
  t: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

const validateReasons = required({ message: 'forms.error_required' })

const downloadData = (assessment, userAssessments, userAssessmentScores, includeInsights) => {
  const date = new Date().toISOString().split('T')[0]
  const questions = flatMap(assessment.sections, 'questions')
  const data = userAssessments.map((ua) => {
    const answersByQuestionId = keyBy(ua.answers, 'questionId')
    const matchingScores = userAssessmentScores.filter((uas) => uas.schoolYear === ua.schoolYear && uas.schoolYearAttempt === ua.schoolYearAttempt)
    const scoresObj = (!includeInsights) ? {} : matchingScores.reduce((prev, d) => {
      return {
        ...prev,
        [`${d.sectionKey}_score`]: Math.round(ztable((d.groupAvgScore - d.popAvgScore) / d.popScoreStdDev) * 100),
      }
    }, {})
    const answersObj = questions.reduce((prev, q) => {
      const answerValue = get(answersByQuestionId, `${q.id}.value`, '') || ''
      const hasChoices = (q.choices && q.choices.length)
      const matchingChoice = (hasChoices) ? q.choices.find((c) => c.value === answerValue) : null
      const matchingChoiceLabel = (matchingChoice) ? matchingChoice.label : null
      return {
        ...prev,
        [q.text]: matchingChoiceLabel || answerValue,
      }
    }, {})
    return {
      attemptNumber: ua.attemptNumber,
      startedOn: ua.created,
      completedOn: ua.completionDate,
      ...scoresObj,
      ...answersObj,
    }
  })
  exportToCsv(`Base-Insights-data-${date}.csv`, data, Object.keys(data[0]))
}

const BaselineView = ({
  getClientsState: { value: clientsById },
  getCoursesState: { value: coursesById },
  getEnrollmentsState: { value: enrollmentsById },
  getClientWithOverview,
  authState: { accessToken, roleType },
  clientId,
  t,
  organization,
  homeOrganization,
}) => {
  const now = new Date()
  const currentSchoolYear = (now.getMonth() <= 6) ? now.getFullYear() - 1 : now.getFullYear()
  const startOrg = findParentContractedCustomerOrSiteOrg(homeOrganization, organization)
  const [ schoolYear, setSchoolYear ] = useState(currentSchoolYear)
  const [ focusOrganization, setFocusOrganization ] = useState(startOrg)
  const [ isGlobal, setIsGlobal ] = useState(false)
  const client = (!clientsById) ? {} : clientsById[clientId]
  if (!client || !coursesById) {
    return null
  }
  const firstBaselineSchooYear = (client.created) ? (client.created.getMonth() <= 6) ? client.created.getFullYear() - 1 : client.created.getFullYear() : 2019
  const schoolYearOptions = Array.apply(null, Array(currentSchoolYear - firstBaselineSchooYear + 1)).map((_, i) => ({
    text: (!i) ? 'Current School Year' : `${currentSchoolYear - i}-${currentSchoolYear - i + 1} School Year`,
    value: currentSchoolYear - i,
  }))
  const schoolYearEnrollmentsByCourseId = Object.keys(enrollmentsById || {}).reduce((accum, enrollmentId) => {
    const enrollment = enrollmentsById[enrollmentId]
    const startedOn = (enrollment && enrollment.startedOn) ? new Date(enrollment.startedOn) : null
    if (!startedOn || !isInSchoolYear(startedOn, schoolYear)) {
      return accum
    }
    return {
      ...accum,
      [enrollment.courseId]: enrollment,
    }
  }, {})
  return (
    <Query
      variables={{
        id: config.baseline.currentAssessmentId,
        orgId: organization.id,
      }}
      query={GET_ASSESSMENT}
    >
      {({
        loading: assessmentLoading,
        data: assessmentData,
      }) => {
        if (assessmentLoading) {
          return (<FullScreenLoadingOverlay isActive={true}/>)
        }
        const assessment = get(assessmentData, 'assessment')
        return (
          <Query
            skip={!organization.baselineAnalysisEnabled}
            variables={{
              userId: clientId,
              assessmentId: config.baseline.currentAssessmentId,
              schoolYear,
            }}
            query={GET_USER_ASSESSMENT_SCORES}
          >
            {({
              loading,
              data,
            }) => {
              if (loading) {
                return (<FullScreenLoadingOverlay isActive={true}/>)
              }
              return (
                <Query
                  variables={{
                    orgId: focusOrganization.id,
                    assessmentId: config.baseline.currentAssessmentId,
                    schoolYear,
                  }}
                  query={GET_ORG_USER_ASSESSMENT_SCORES}
                >
                  {({
                    loading: orgLoading,
                    data: orgData,
                  }) => {
                    if (orgLoading) {
                      return (<FullScreenLoadingOverlay isActive={true}/>)
                    }
                    const userAssessmentScores = get(data, 'userAssessmentScoresByUserId', [])
                    const userAssessmentScoresByOrgId = get(orgData, 'userAssessmentScoresByOrgId', [])
                    return (
                      <Query
                        variables={{
                          userId: clientId,
                          assessmentId: config.baseline.currentAssessmentId,
                          first: 999,
                        }}
                        query={GET_USER_ASSESSMENTS}
                      >
                        {({
                          loading,
                          data,
                        }) => {
                          if (loading) {
                            return (<FullScreenLoadingOverlay isActive={true}/>)
                          }
                          const userAssessments = sortBy(get(data, 'userAssessments.userAssessments', []), 'attemptNumber').reverse()
                          return (
                            <Query
                              variables={{
                                userId: clientId,
                                assessmentId: config.baseline.currentAssessmentId,
                                schoolYear,
                              }}
                              query={GET_RECOMMENED_COURSE_IDS_BY_USER_ID}
                            >
                              {({
                                loading,
                                data: recommendedCourseIdsData,
                              }) => {
                                if (loading) {
                                  return (<FullScreenLoadingOverlay isActive={true}/>)
                                }

                                const recommendedCourseIds = get(recommendedCourseIdsData, 'recommendedCourseIdsByUserId', []).filter((id) => organization.courseIds.includes(id)) || []
                                const recommendedCourses = compact(recommendedCourseIds.map((id) => coursesById[id]))
                                return (
                                  <>
                                    {(client.roleType === 'student') && (
                                      <Query
                                        variables={{
                                          userId: clientId,
                                        }}
                                        query={GET_USER_PROFILE}
                                      >
                                        {({
                                          loading: profileLoading,
                                          data: profileData,
                                          refetch,
                                        }) => {
                                          const profile = get(profileData, 'userProfile') || {}
                                          return (
                                            <Mutation
                                              mutation={UPDATE_USER_PROFILE}
                                              awaitRefetchQueries={true}
                                              onCompleted={() => refetch().then(() => getClientWithOverview({
                                                accessToken,
                                                id: clientId,
                                              }))}
                                            >
                                              {(updateProfile, { loading: isSaving }) => {
                                                return (
                                                  <BaseForm
                                                    header={t(`${TRANSLATION_PREFIX}.form_header`)}
                                                    onSubmit={(newProfile) => {
                                                      updateProfile({
                                                        variables: {
                                                          profile: {
                                                            clientId,
                                                            assessmentsDeclinedReason: (!newProfile.assessmentsDeclined) ? null : newProfile.assessmentsDeclinedReason,
                                                          },
                                                        },
                                                      })
                                                    }}
                                                    isLoading={profileLoading}
                                                    initialValues={{
                                                      assessmentsDeclined: !!profile.assessmentsDeclinedReason,
                                                      assessmentsDeclinedReason: profile.assessmentsDeclinedReason,
                                                    }}
                                                    render={({
                                                      handleSubmit,
                                                      submitDisabled,
                                                    }) => {
                                                      return (
                                                        <Form onSubmit={handleSubmit}>
                                                          <Field
                                                            name='assessmentsDeclined'
                                                            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                                                              <Form.Checkbox
                                                                className='inline-block'
                                                                label={t(`${TRANSLATION_PREFIX}.user_decline_assessments`)}
                                                                toggle
                                                                disabled={isSaving}
                                                                {...input}
                                                                checked={input.value}
                                                                value='assessmentsDeclined'
                                                                onChange={checkboxOnChange(input)}/>
                                                            )}
                                                          />
                                                          <ConditionalField
                                                            when='assessmentsDeclined'
                                                            name='assessmentsDeclinedReason'
                                                            validate={validateReasons} // TODO: figure out how to get this to only validate when the assessmentsDeclined checkbox is checked
                                                            render={({
                                                              input,
                                                              meta: { error: { message, ...options } = {}, touched },
                                                              conditionMet,
                                                            }) => (
                                                              <Form.Field width='eight' disabled={!conditionMet}>
                                                                <label>{t(`${TRANSLATION_PREFIX}.decline_reason`)} *</label>
                                                                <ReasonsDropdown
                                                                  placeholder={t(`${TRANSLATION_PREFIX}.decline_reason`)}
                                                                  error={!!message && touched}
                                                                  disabled={isSaving}
                                                                  {...input}
                                                                  defaultValue={input.value}
                                                                  onChange={dropdownOnChange(input)}
                                                                  t={t}
                                                                />
                                                              </Form.Field>
                                                            )}/>

                                                          <Button
                                                            type='submit'
                                                            className='user-profile-button'
                                                            floated='right'
                                                            loading={isSaving}
                                                            color='green'
                                                            size='small'
                                                            disabled={submitDisabled}>
                                                            {t('forms.save_button')}
                                                          </Button>
                                                          <br style={{ clear: 'both' }}/>
                                                        </Form>
                                                      )
                                                    }}
                                                  />
                                                )
                                              }}
                                            </Mutation>
                                          )
                                        }}
                                      </Query>
                                    )}
                                    <AssessmentMeanScoresSummaryCard
                                      isInternalRole={Object.values(INTERNAL_ROLE_TYPES).includes(roleType)}
                                      recommendedCourses={recommendedCourses}
                                      schoolYearEnrollmentsByCourseId={schoolYearEnrollmentsByCourseId}
                                      isForIndividual={true}
                                      assessment={assessment}
                                      userAssessmentScores={userAssessmentScores}
                                      userAssessments={userAssessments}
                                      onSchoolYearChange={(year) => setSchoolYear(year)}
                                      schoolYear={schoolYear}
                                      showNumberOfResponsesChart={false}
                                      schoolYearOptions={schoolYearOptions}
                                      insightsEnabled={organization.baselineAnalysisEnabled}
                                      onDownloadClick={() => downloadData(assessment, userAssessments, userAssessmentScores, organization.baselineAnalysisEnabled)}
                                      orgId={organization.id}
                                      userAssessmentScoresByOrgId={userAssessmentScoresByOrgId}
                                      focusOrganization={focusOrganization}
                                      isGlobal={isGlobal}
                                      onFocusOrganizationChange={(org) => {
                                        if (org.id !== 'global') {
                                          setIsGlobal(false)
                                          setFocusOrganization(org)
                                        } else {
                                          setIsGlobal(true)
                                        }
                                      }}
                                      homeOrganization={homeOrganization}
                                      startOrg={startOrg}
                                    />
                                  </>
                                )
                              }}
                            </Query>
                          )
                        }}
                      </Query>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

BaselineView.propTypes = {
  authState: PropTypes.object.isRequired,
  getClientsState: PropTypes.object.isRequired,
  getCoursesState: PropTypes.object.isRequired,
  getEnrollmentsState: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getClientWithOverview: PropTypes.func.isRequired,
  showFriendlyApiErrorMessages: PropTypes.bool,
  organization: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  homeOrganization: PropTypes.object,
}

BaselineView.path = '/baseline-summary'

const mapStateToProps = (state) => {
  const {
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
    },
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    authentication: { accessToken },
    enrollments: { [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getClientsState,
    getCoursesState,
    getEnrollmentsState,
    accessToken,
    showFriendlyApiErrorMessages,
  }
}

const mapDispatchToProps = {
  getClientWithOverview,
}

const BaselineViewContainer = connect(mapStateToProps, mapDispatchToProps)(BaselineView)

export default translate([ 'components' ])(BaselineViewContainer)
