import querystring from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  Button,
  Label,
  Segment,
  Container,
  Icon,
  Form,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { sortBy } from 'lodash'

import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
import isStale from '../../../helpers/is-stale'
import SaveFirePhrase from './save-fire-phrase'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import FetchResultMessage from '../../common/fetch-result-message/fetch-result-message'
import Pager from '../../common/pager/pager'
import {
  getAllFirePhrases,
  deleteFirePhrase,
  invalidateFirePhrases,
} from '../../../actions/fire-phrases'

export class FirePhrasesView extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    getFirePhrasesState: PropTypes.object.isRequired,
    deleteFirePhrasesState: PropTypes.object.isRequired,
    deleteFirePhrase: PropTypes.func.isRequired,
    getAllFirePhrases: PropTypes.func.isRequired,
    invalidateFirePhrases: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool.isRequired,
  }

  static path = '/firePhrases'

  constructor (props) {
    super(props)
    this.state = {
      filter: '',
    }
  }

  onNewFirePhraseClick = () => this.props.history.push(SaveFirePhrase.path.new)

  onRemoveFirePhraseClick = (id) => () => {
    const { deleteFirePhrase, accessToken } = this.props
    let shouldDelete = window.confirm('Do not do this unless you know what you are doing! Users who have captures tied to this fire phrase will appear to be missing captures.')
    if (shouldDelete) {
      shouldDelete = window.confirm('Are you really really sure???')
      if (shouldDelete) {
        deleteFirePhrase({ accessToken, id })
      }
    }
  }

  onModifyFirePhraseClick = (id) => () => this.props.history.push(SaveFirePhrase.path.existing.replace(':id', id))

  renderFirePhrase = (firePhrase) => {
    const { t, deleteFirePhrasesState: { id, isLoading } } = this.props
    const isDeleting = (isLoading && id === firePhrase.id)
    return (
      <List.Item key={firePhrase.id}>
        <List.Content floated='right'>
          <Button disabled={isDeleting} color='teal' icon title={t('edit')} onClick={this.onModifyFirePhraseClick(firePhrase.id)}>
            <Icon name='write' />
          </Button>
          <Button disabled={isDeleting} loading={isDeleting} color='red' icon title={t('delete')} onClick={this.onRemoveFirePhraseClick(firePhrase.id)}>
            <Icon name='remove' />
          </Button>
        </List.Content>
        <List.Content>
          <List.Header>Words: {firePhrase.keyWords.en.join(', ')}</List.Header>
          <Label color='teal' horizontal>{firePhrase.category}</Label>
          <strong>Phrases:</strong> {firePhrase.phrases.en.join(', ')}
        </List.Content>
      </List.Item>
    )
  }

  renderError = (error) => <div>error! {error.message}</div>

  renderLoading = () => <FullScreenLoadingOverlay isActive/>

  filterPhrases = (phrase) => phrase.keyWords.en.some((kw) => kw.toLowerCase().includes(this.state.filter.toLowerCase())) || phrase.category.includes(this.state.filter.toLowerCase())

  navigateToPage = (pageNum) => this.props.history.push(`${FirePhrasesView.path}?page=${pageNum}`)

  setFilter = (e, props) => {
    const { location } = this.props
    const activePage = querystring.parse(location.search.replace('?', '')).page || 1
    if (activePage !== 1) {
      this.navigateToPage(1)
    }
    this.setState({ filter: props.value })
  }

  componentDidMount () {
    const {
      accessToken,
      getFirePhrasesState,
      getAllFirePhrases,
    } = this.props

    if (isStale(getFirePhrasesState)) {
      getAllFirePhrases({ accessToken })
    }
  }

  componentWillUnmount () {
    this.props.invalidateFirePhrases()
  }

  render () {
    const {
      t,
      location,
      getFirePhrasesState: {
        value,
        error,
        isLoading,
      },
      showFriendlyApiErrorMessages,
    } = this.props

    if (error) {
      return (
        <FetchResultMessage
          itemType='fire phrase'
          success={false}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      )
    }

    const firePhrases = (!value) ? [] : Object.values(value)
    const filteredFirePhrases = firePhrases.filter(this.filterPhrases)
    const sortedFirePhrases = sortBy(filteredFirePhrases, [ 'category' ])

    const activePage = querystring.parse(location.search.replace('?', '')).page || 1
    const firePhrasesText = t('resource_types.fire_phrase', { count: 2, postProcess: 'titleCase' })
    const firePhraseText = t('resource_types.fire_phrase', { postProcess: 'titleCase' })
    const filterText = t('filter', { postProcess: 'titleCase' })
    const newText = t('new', { postProcess: 'titleCase' })

    return (
      <Container>
        <FullScreenLoadingOverlay isActive={isLoading}/>
        <h2>{firePhrasesText}</h2>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input fluid placeholder={`${filterText} ${firePhrasesText}`} onChange={this.setFilter} />
            <Form.Button floated='right' color='teal' icon onClick={this.onNewFirePhraseClick}>
              <Icon name='plus' /> {`${newText} ${firePhraseText}`}
            </Form.Button>
          </Form.Group>
        </Form>
        <Segment>
          <Pager
            items={sortedFirePhrases}
            onPageChange={this.navigateToPage}
            activePage={activePage * 1}
            render={(items) => {
              return (
                <List selection verticalAlign='middle'>
                  {items.map(this.renderFirePhrase)}
                </List>
              )
            }}/>
        </Segment>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    firePhrases: {
      [FETCH_ACTIONS.GET_ALL]: getFirePhrasesState,
      [FETCH_ACTIONS.DELETE]: deleteFirePhrasesState,
    },
    authentication: { accessToken },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getFirePhrasesState,
    deleteFirePhrasesState,
    accessToken,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  deleteFirePhrase,
  getAllFirePhrases,
  invalidateFirePhrases,
}
const FirePhrasesViewContainer = connect(mapStateToProps, mapDispatchToProps)(FirePhrasesView)

export default translate([ 'components' ])(FirePhrasesViewContainer)
