import gql from 'graphql-tag'

export const NAME = 'deleteUserNotificationSetting'
const DELETE_NOTIFICATION_SETTING = gql`
mutation ${NAME}($id: ID!) {
  ${NAME}(id: $id)
}
`

export default DELETE_NOTIFICATION_SETTING
