import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  Header,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Segment,
  Container,
  Table,
  Popup,
  List,
  Button,
  Message,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  findLast,
  find,
  get,
  isEqual,
  isNumber,
  sortBy,
  isString,
  flatten,
  compact,
  orderBy,
  without,
  groupBy,
  partial,
  truncate,
  union,
  difference,
  reject,
} from 'lodash'
import moment from 'moment'

import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import {
  batchPasswordReset, getAllClientsWithOverviews, invalidateClients,
} from '../../../../actions/clients'
import isStale from '../../../../helpers/is-stale'
// import ClientCard from '../../../common/client-card/client-card'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import Pager from '../../../common/pager/uncontrolled-pager'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import getRoles, {
  EXTERNAL_ROLE_TYPES, INTERNAL_ROLE_TYPES, ROLE_VALUES,
} from '../../../../helpers/get-roles'
import SearchInput from '../../../common/search-input'
import config from '../../../../config.js'
import buildQueryString from '../../../../helpers/build-query-string'
import getWhatsNewMessages from './get-whats-new-messages'
import CompactMessages from '../../../common/compact-messages'
import UserActivityThumb from '../../../common/user-activity-thumb'
import CourseUsageThumb from '../../../common/course-usage-thumb'
import { getClientActivity } from '../../../../actions/client-activity'
import ProgressDots from '../../../common/progress-dots'
import ProgressBar from '../../../common/progress-bar'
import {
  getOrgTypesOfParents,
} from '../../../../helpers/organization'
import { getDisplayName } from '../../../../helpers/user'
import IncrementClientAgesByOrgIdMenuItem from './increment-ages-menu-item'
import IncrementClientGradeLevelsByOrgIdMenuItem from './increment-grade-levels-menu-item'
import ArchiveClientsByUserIdsMenuItem from './archive-clients-menu-item'
import ChangeClientsOrgMenuItem from './change-clients-org-menu-item'
import BulkUpdateClientsPasswordMenuItem from './bulk-update-clients-password-menu-item'
import BulkDeleteClientsMenuItem from './bulk-delete-clients-menu-item'

import './clients.css'

const TRANSLATION_PREFIX = 'views.organization.clients'
const ROLES = getRoles()
const PAGE_SIZE = 50
const LOCAL_STORAGE_PREFIX = 'com.base-ed.whats-new'

const mapFirePhrase = (color, fpc) => fpc.analyzedCaptures.map((ac) => ({
  color, sentence: ac.sentence, questionText: ac.questionText,
}))
const mapRedFirePhrases = partial(mapFirePhrase, 'red')
const mapOrangeFirePhrases = partial(mapFirePhrase, 'orange')
const mapYellowFirePhrases = partial(mapFirePhrase, 'yellow')
const COURSE_TYPES = {
  high: 'High School',
  middle: 'Middle School',
  elementary: 'Elementary School',
  secondary: 'High/Middle School',
  parent: 'Parent/Guardian',
}

export class ClientsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getActivityState: PropTypes.object.isRequired,
    getClientActivity: PropTypes.func.isRequired,
    organization: PropTypes.object,
    homeOrganization: PropTypes.object,
    onSearchParamsChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    batchPasswordReset: PropTypes.func.isRequired,
    getAllClientsWithOverviews: PropTypes.func.isRequired,
    onCreateClick: PropTypes.func,
    onClientClick: PropTypes.func,
    onReviewProgressClick: PropTypes.func,
    onReviewFirewordsClick: PropTypes.func,
    userId: PropTypes.string.isRequired,
    searchParams: PropTypes.object,
    match: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    isNewVersion: PropTypes.bool.isRequired,
  }

  static path = '/users'

  static archivedUsersPath = '/archivedUsers'

  constructor (props) {
    super(props)
    const unreadMessageIds = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '17', '18' ].filter((msgId) => {
      const isStored = window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${msgId}.${props.authState.userId}`)
      return !isStored
    })

    this.state = {
      activeAccordionIndex: -1,
      activityFilterEnabled: (props.searchParams.activeSince !== undefined),
      unreadMessageIds,
      filterByCompleted: (!props.searchParams.incompleteCourseIds),
      isUpdating: false,
      selectedUserIds: [],
      userHash: {},
      onload: true,
    }

    const now = new Date()

    this.timeRanges = {
      never: -1,
      last30Minutes: now - (1000 * 60 * 30),
      lastHour: now - (1000 * 60 * 60 * 1),
      last24Hours: now - (1000 * 60 * 60 * 24),
      lastWeek: now - (1000 * 60 * 60 * 24 * 7),
      last30Days: now - (1000 * 60 * 60 * 24 * 30),
      last90Days: now - (1000 * 60 * 60 * 24 * 30 * 3),
      last180Days: now - (1000 * 60 * 60 * 24 * 30 * 6),
      lastYear: now - (1000 * 60 * 60 * 24 * 365),
      anytime: 0,
    }
  }

  handleMessageRead = (messageId) => {
    this.setState({ ...this.state, unreadMessageIds: without(this.state.unreadMessageIds, messageId) })
    window.localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.${messageId}.${this.props.authState.userId}`, 'true')
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeAccordionIndex } = this.state
    const newIndex = activeAccordionIndex === index ? -1 : index

    this.setState({ ...this.state, activeAccordionIndex: newIndex })
  }

  handleLoadingChange = (isUpdating) => {
    this.setState({
      ...this.state, isUpdating, selectedUserIds: [],
    })
  }

  setTextFilter = (value) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    onSearchParamsChange({
      ...searchParams,
      skip: 0,
      limit: PAGE_SIZE,
      filter: value,
    })
    invalidateClients()
  }

  handleSelectClients = (userIds) => (e, props) => {
    if (props.checked === false) {
      this.setState({ ...this.state, selectedUserIds: difference(this.state.selectedUserIds, userIds) })
    } else {
      this.setState({ ...this.state, selectedUserIds: union(this.state.selectedUserIds, userIds) })
    }
  }

  setFilter = (type) => (e, props) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    onSearchParamsChange({
      ...searchParams,
      skip: 0,
      limit: PAGE_SIZE,
      [type]: props.value,
    })
    invalidateClients()
  }

  setMultipleFilters = (filters) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    onSearchParamsChange({
      ...searchParams,
      skip: 0,
      limit: PAGE_SIZE,
      ...filters,
    })
    invalidateClients()
  }

  setPageNumber = (pageNumber) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    onSearchParamsChange({
      ...searchParams,
      skip: ((pageNumber - 1) * PAGE_SIZE),
      limit: PAGE_SIZE,
    })
    invalidateClients()
  }

  archiveUsers = (clientIds) => {
    console.log(clientIds)
  }

  toggleActivityFilter = () => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    onSearchParamsChange({ ...searchParams, activeSince: (this.state.activityFilterEnabled) ? undefined : searchParams.activeSince * 1 || 0 })
    invalidateClients()
    this.setState({ ...this.state, activityFilterEnabled: !this.state.activityFilterEnabled })
  }

  // renderClientCard = (clientCardData) => {
  //   return (
  //     <ClientCard
  //       key={clientCardData.id}
  //       userName={clientCardData.userName}
  //       email={clientCardData.email}
  //       usageSeconds={clientCardData.totalUsageSeconds}
  //       firstName={(clientCardData.profile) ? clientCardData.profile.firstName : ''}
  //       lastName={(clientCardData.profile) ? clientCardData.profile.lastName : ''}
  //       role={clientCardData.roleName}
  //       coursesCompletedCount={clientCardData.completedCourseIds.length}
  //       lastActiveCourse={(clientCardData.lastActiveCourse) ? clientCardData.lastActiveCourse.title : null}
  //       lastActiveCourseDate={(clientCardData.lastActiveCourse) ? new Date(clientCardData.lastActiveCourse.date) : null}
  //       lastCompletedCourse={(clientCardData.lastCompletedCourse) ? clientCardData.lastCompletedCourse.title : null}
  //       onClick={() => this.props.onClientClick(clientCardData.id)}
  //     />
  //   )
  // }

  renderClientItem = (bulkEditableUsers, clientItemData) => {
    const {
      getCoursesState,
      onReviewProgressClick,
      onClientClick,
      onReviewFirewordsClick,
      organization,
      authState: {
        roleType,
      },
    } = this.props
    const displayName = getDisplayName(clientItemData)
    const lastActiveCourse = (clientItemData.lastActiveCourse)
      ? (<><div className='text bold'>{clientItemData.lastActiveCourse.title}</div><div className='text italic'>{'(' + moment(clientItemData.lastActiveCourse.date).fromNow() + ')'}</div></>)
      : ''
    const coursesById = get(getCoursesState, 'value', {})
    const groupedEnrollments = groupBy(clientItemData.enrollments, 'courseId')
    const latestEnrollments = compact(Object.keys(groupedEnrollments).map((courseId) => {
      const course = coursesById[courseId]
      // check if course still exists in org, if not, ignore it
      if (!course) {
        return null
      }
      const latest = sortBy(groupedEnrollments[courseId], 'attemptNumber').pop()
      if (!latest.contentItemProgresses.length) {
        return null
      }
      return latest
    }))
    const latestSortedProgresses = orderBy(latestEnrollments.map((enrollment) => {
      const latestOrdinal = enrollment.contentItemProgresses.reduce((ord, prog) => Math.max(ord, prog.ordinal), 0)
      const course = coursesById[enrollment.courseId]
      return {
        courseTitle: course.titles,
        progress: (enrollment.completedOn) ? 100 : Math.floor(100 * latestOrdinal / course.contentItems.length),
        lastActiveDate: enrollment.modified,
      }
    }), 'lastActiveDate', 'desc')
    const progress = (Object.keys(groupedEnrollments).length) ? Math.round(100 * clientItemData.completedCourseIds.length / Object.keys(groupedEnrollments).length) : 0
    const nonPrivateUnreviewedFirePhraseCaptures = (clientItemData.unreviewedFirePhraseCaptures || []).filter((fpc) => isNumber(fpc.attemptNumber) || !fpc.attemptNumber.startsWith('room_'))
    const analyzedCaptures = nonPrivateUnreviewedFirePhraseCaptures.map((capture) => {
      return flatten(capture.analyzedCaptures)
    })
    const allRelevantSeverities = analyzedCaptures.map((fp) => {
      return fp.map((phrase) => phrase.ignisSeverity || phrase.receptivitiSeverity || 3)
    })
    const maxRelevantSeverities = allRelevantSeverities.map((scores) => Math.max(scores) || 0)
    const redCaptures = nonPrivateUnreviewedFirePhraseCaptures.filter((_, idx) => maxRelevantSeverities[idx] === 3)
    const orangeCaptures = nonPrivateUnreviewedFirePhraseCaptures.filter((_, idx) => maxRelevantSeverities[idx] === 2)
    const yellowCaptures = nonPrivateUnreviewedFirePhraseCaptures.filter((_, idx) => maxRelevantSeverities[idx] === 1)
    // const greenCaptures = nonPrivateUnreviewedFirePhraseCaptures.filter((_, idx) => maxReceptivitiSeverities[idx] === 0)
    const highestPriorityColor = ((redCaptures.length > 0 && 'red') || (orangeCaptures.length > 0 && 'orange') || (yellowCaptures.length > 0 && 'yellow') || 'grey')
    const firePhrases = flatten([
      ...redCaptures.map(mapRedFirePhrases),
      ...orangeCaptures.map(mapOrangeFirePhrases),
      ...yellowCaptures.map(mapYellowFirePhrases),
    ])
    return (
      <Table.Row
        key={clientItemData.userName}
        id={`client-${clientItemData.id}`}
        onClick={() => onClientClick(clientItemData.id)}
      >
        {roleType === INTERNAL_ROLE_TYPES.SUPER_ADMIN && bulkEditableUsers.length > 0 && <Table.Cell onClick={(e) => e.stopPropagation() } style={{ textAlign: 'center' }}>
          {clientItemData.roleType !== INTERNAL_ROLE_TYPES.SUPER_ADMIN && <Popup
            basic
            hoverable
            onClick={(e) => {
            // NOTE: Don't let click events on the popup do anything
              e.preventDefault()
              e.stopPropagation()
            }}
            trigger={(
              <Checkbox
                data-public
                className='activity-checkbox'
                onChange={this.handleSelectClients([ clientItemData.id ])}
                checked={this.state.selectedUserIds.includes(clientItemData.id)}
              />
            )}
            position='right center'
          >
            <Popup.Content >Select</Popup.Content>
          </Popup>}
        </Table.Cell>}
        <Table.Cell>{displayName}</Table.Cell>
        <Table.Cell>{clientItemData.roleName}</Table.Cell>
        <Popup
          hoverable
          onClick={(e) => {
            // NOTE: Don't let click events on the popup do anything
            e.preventDefault()
            e.stopPropagation()
          }}
          trigger={(
            <Table.Cell collapsing={true}>
              <ProgressDots
                completed={progress}
                radius={8}
              />
            </Table.Cell>
          )}
          position='right center'
        >
          <Popup.Content style={{ width: 220 }}>
            {latestSortedProgresses.length === 0 && (
              <div className='course-progress-label text bold italic'>No module activity</div>
            )}
            {latestSortedProgresses.length > 0 && (
              <Header as='h3' className='course-progress-label'>Most Recent Activity</Header>
            )}
            {latestSortedProgresses.slice(0, 3).map((prog) => {
              return (
                <div key={prog.courseTitle}>
                  <ProgressBar completed={prog.progress} />
                  <div className='course-progress-label text bold clip'>{prog.courseTitle}</div>
                </div>
              )
            })}
            <Button
              fluid
              color='blue'
              size='small'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onReviewProgressClick(clientItemData.id)
              }}
            >
              Review Module Progress
            </Button>
          </Popup.Content>
        </Popup>
        {(organization.firewordsEnabled) && (
          <Popup
            hoverable
            disabled={!nonPrivateUnreviewedFirePhraseCaptures.length}
            onClick={(e) => {
              // NOTE: Don't let click events on the popup do anything
              e.preventDefault()
              e.stopPropagation()
            }}
            trigger={(
              <Table.Cell collapsing data-public textAlign='center'>
                <Icon name='fire' color={highestPriorityColor} size={highestPriorityColor !== 'grey' ? 'large' : null}/> {nonPrivateUnreviewedFirePhraseCaptures.length}
              </Table.Cell>
            )}
            position='right center'
          >
            <Popup.Content style={{ width: 220 }}>
              <List>
                {firePhrases.slice(0, 10).map((fp) => {
                  return (
                    <List.Item key={fp.sentence}>
                      <List.Icon name='fire' color={fp.color}/>
                      <List.Content>
                        <div className='text bold italic'>{!!fp.questionText && truncate(fp.questionText, { length: 49 })}</div>
                        {fp.sentence}
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
              {(firePhrases.length > 10) && (
                <div style={{ paddingBottom: 10, float: 'right' }} className='text bold italic grey'>more...</div>
              )}
              <Button
                fluid
                color='blue'
                size='small'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onReviewFirewordsClick(clientItemData.id)
                }}
              >
                Review Firewords
              </Button>
            </Popup.Content>
          </Popup>
        )}
        <Table.Cell textAlign='center'>{lastActiveCourse}</Table.Cell>
      </Table.Row>
    )
  }

  componentDidMount () {
    const {
      searchParams,
      authState: { accessToken },
      match: { params: { orgId: currentOrgId } },
      getClientsState,
      getActivityState,
      getAllClientsWithOverviews,
      getClientActivity,
    } = this.props
    if (isStale(getActivityState)) {
      getClientActivity({
        accessToken,
        organizationId: currentOrgId,
        roleTypes: [ EXTERNAL_ROLE_TYPES.STUDENT ],
      })
    }
    if (isStale(getClientsState)) {
      getAllClientsWithOverviews({
        accessToken,
        organizationId: currentOrgId,
        limit: PAGE_SIZE,
        ...searchParams,
      })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      searchParams,
      authState: { accessToken },
      match: { params: { orgId: currentOrgId } },
      getClientsState,
      getActivityState,
      getAllClientsWithOverviews,
      getClientActivity,
      getCoursesState,
      invalidateClients,
    } = this.props
    const {
      onload,
    } = this.state

    if (onload && !getClientsState.isLoading && !getCoursesState.isLoading) {
      // initial load and appropriate data has been finished loading
      this.setState({ onload: false })
    } else if (!isEqual(searchParams, prevProps.searchParams)) {
      invalidateClients()
    } else {
      if (isStale(getActivityState)) {
        getClientActivity({
          accessToken,
          organizationId: currentOrgId,
          roleTypes: [ EXTERNAL_ROLE_TYPES.STUDENT ],
        })
      }
      if (isStale(getClientsState)) {
        getAllClientsWithOverviews({
          accessToken,
          organizationId: currentOrgId,
          limit: PAGE_SIZE,
          ...searchParams,
        })
      }
    }
  }

  render () {
    const {
      t,
      searchParams,
      getClientsState,
      getCoursesState,
      getActivityState,
      showFriendlyApiErrorMessages,
      onCreateClick,
      authState: {
        accessToken, roleType, email,
      },
      organization,
      homeOrganization,
      isNewVersion,
    } = this.props

    const {
      activeAccordionIndex,
      activityFilterEnabled,
      filterByCompleted,
      isUpdating,
      onload,
    } = this.state

    if (!organization) {
      return null
    }
    if (getClientsState.error || getCoursesState.error) {
      return (
        <Container>
          <FetchResultMessage
            success={false}
            error={getClientsState.error || getCoursesState.error}
            showFriendlyError={showFriendlyApiErrorMessages}/>
        </Container>
      )
    }

    if (onload) {
      return (<FullScreenLoadingOverlay isActive={true}/>)
    }

    // TODO: make query param just text and convert to time when making api call.
    // TODO: make download button download all data???
    const activityFilterOptions = [
      { label: 'never', value: this.timeRanges.never },
      { label: 'in the last 30 minutes', value: this.timeRanges.last30Minutes },
      { label: 'in the last hour', value: this.timeRanges.lastHour },
      { label: 'in the last 24 hours', value: this.timeRanges.last24Hours },
      { label: 'in the last 7 days', value: this.timeRanges.lastWeek },
      { label: 'in the last 30 days', value: this.timeRanges.last30Days },
      { label: 'in the last 90 days', value: this.timeRanges.last90Days },
      { label: 'in the last 180 days', value: this.timeRanges.last180Days },
      { label: 'in the last year', value: this.timeRanges.lastYear },
      { label: 'any time', value: this.timeRanges.anytime },
    ]
    const sortOptions = [
      { label: 'last active date', value: 'lastActiveCourse.date' },
      { label: 'user name', value: 'userName' },
      { label: 'first name', value: 'profile.firstName' },
      { label: 'last name', value: 'profile.lastName' },
      { label: 'total activity', value: 'totalUsageSeconds' },
      { label: 'number of modules completed', value: 'completedCourseIds.length' },
    ]

    const clientsById = get(getClientsState, 'value', {}) || {}
    const clients = Object.values(clientsById)
    const clientCounts = get(getClientsState, 'value.totalCount.totalCount', 0) || 0
    const coursesById = get(getCoursesState, 'value', {}) || {}
    const clientCardData = compact(clients.map((client) => {
      if (client.id === 'totalCount') {
        return null
      }
      const completedCourseIds = []
      let totalUsageSeconds = 0
      let lastCompletedCourse
      let lastActiveCourse
      if (client.enrollmentsUsage) {
        Object.keys(client.enrollmentsUsage).forEach((courseId) => {
          if (!coursesById[courseId]) {
            return
          }
          const courseTitle = coursesById[courseId].titles
          const courseEnrollmentsUsage = client.enrollmentsUsage[courseId]
          const sortedEnrollmentsUsage = sortBy(courseEnrollmentsUsage, 'attemptNumber')
          totalUsageSeconds += sortedEnrollmentsUsage.reduce((total, usage) => total + usage.seconds, 0)
          const lastStartedEnrollment = findLast(sortedEnrollmentsUsage, (e) => !!e.seconds)
          if (lastStartedEnrollment && (!lastActiveCourse || lastStartedEnrollment.lastModified > lastActiveCourse.date)) {
            lastActiveCourse = { title: courseTitle, date: lastStartedEnrollment.lastModified }
          }
          const lastCompletedEnrollment = findLast(sortedEnrollmentsUsage, (e) => !!e.completedOn)
          if (lastCompletedEnrollment) {
            if (!lastCompletedCourse || lastCompletedEnrollment.completedOn > lastCompletedCourse.date) {
              lastCompletedCourse = { title: courseTitle, date: lastCompletedEnrollment.completedOn }
            }
            completedCourseIds.push(courseId)
          }
        })
      }
      return {
        ...client,
        roleName: find(ROLES, { type: client.roleType }).name,
        lastActiveCourse,
        lastCompletedCourse,
        totalUsageSeconds,
        completedCourseIds,
      }
    }))

    const bulkEditableUsers = reject(clientCardData, { roleType: INTERNAL_ROLE_TYPES.SUPER_ADMIN })
    const createText = t('create', { postProcess: 'titleCase' })
    const sortedCourses = sortBy(Object.values(coursesById), 'titles')
    const isArchived = (isString(searchParams.isArchived)) ? searchParams.isArchived === 'true' : searchParams.isArchived
    const roleTypes = (!searchParams.roleTypes) ? [] : flatten([ searchParams.roleTypes ])
    const completedCourseIds = (!searchParams.completedCourseIds) ? [] : flatten([ searchParams.completedCourseIds ])
    const incompleteCourseIds = (!searchParams.incompleteCourseIds) ? [] : flatten([ searchParams.incompleteCourseIds ])
    const viewUsersButtonText = (isArchived) ? t(`${TRANSLATION_PREFIX}.view_active_button`) : t(`${TRANSLATION_PREFIX}.view_archived_button`)
    const clientsText = t('resource_types.client', { count: 2, postProcess: 'titleCase' })
    const isCustomer = !!organization.contractStartDate
    const parentOrgTypes = (homeOrganization.id === organization.id) ? [] : getOrgTypesOfParents(homeOrganization, organization)
    const showExternalRoleTypesOnly = Object.values(EXTERNAL_ROLE_TYPES).includes(roleType) || isCustomer || parentOrgTypes.includes('customer')
    const filteredRoles = getRoles({ externalOnly: showExternalRoleTypesOnly }).filter((role) => {
      const authenticatedUserRoleValue = ROLE_VALUES[roleType]
      const targetRoleValue = ROLE_VALUES[role.type]
      return (roleType === EXTERNAL_ROLE_TYPES.ADVISOR)
        ? (role.type === roleType || authenticatedUserRoleValue > targetRoleValue)
        : (authenticatedUserRoleValue >= targetRoleValue)
    })
    const isInternalRole = Object.values(INTERNAL_ROLE_TYPES).includes(roleType)
    const showUpdateEmailMsg = (email.startsWith('undeliverable-'))
    const updateEmailMessage = (!showUpdateEmailMsg) ? null : (
      <Message
        data-public
        error
        icon='warning sign'
        header={t(`${TRANSLATION_PREFIX}.update_email_header`)}
        content={t(`${TRANSLATION_PREFIX}.update_email_description`, { email: email.substring(14) })}
      />
    )

    const downloadQueryString = buildQueryString({
      token: `Bearer ${accessToken}`,
      orgId: organization.id,
      csv: true,
      enrollmentsUsage: true,
      ...searchParams,
    })
    const downloadAllQueryString = buildQueryString({
      token: `Bearer ${accessToken}`,
      orgId: organization.id,
      csv: true,
      allOrgs: true,
      enrollmentsUsage: true,
      ...searchParams,
    })
    const downloadAllLoginQueryString = buildQueryString({
      token: `Bearer ${accessToken}`,
      orgId: organization.id,
      basic: !Object.values(INTERNAL_ROLE_TYPES).includes(roleType),
      isArchived,
    })
    const courseTitlesById = {}
    if (getCoursesState.value) {
      Object.keys(getCoursesState.value).forEach((courseId) => {
        courseTitlesById[courseId] = getCoursesState.value[courseId].titles
      })
    }
    return (
      <>
        <FullScreenLoadingOverlay isActive={isUpdating || getClientsState.isLoading}/>

        {updateEmailMessage}

        <div className='search-options'>
          <Grid>
            {(!isNewVersion) && (
              <Grid.Column id='charts-and-messages' className='no-right-padding' width={3}>
                <Segment attached='top' color='blue' style={{ background: '#f9fafb' }}>
                  <Header data-public as='h4'>Charts</Header>
                </Segment>
                <Segment attached>
                  <UserActivityThumb
                    isLoading={getActivityState.isLoading}
                    rolesWithActivityByRoleType={getActivityState.value}
                  />
                </Segment>
                <Segment attached='bottom'>
                  <CourseUsageThumb
                    isLoading={getActivityState.isLoading || getCoursesState.isLoading}
                    courseTitlesById={courseTitlesById}
                    rolesWithUsageByRoleType={getActivityState.value}
                  />
                </Segment>
                <CompactMessages
                  messages={orderBy(getWhatsNewMessages(t, 'views.organization'), 'date', [ 'desc' ]).slice(0, 3)}
                  messageIdsToStartOpen={this.state.unreadMessageIds}
                  onMessageRead={this.handleMessageRead}
                />
              </Grid.Column>
            )}
            <Grid.Column width={(isNewVersion) ? 16 : 13}>

              <Grid>
                <Grid.Column id='user-search' width={11}>
                  <SearchInput
                    fluid
                    autoFocus
                    initialValue={searchParams.filter}
                    onSubmit={this.setTextFilter}
                  />
                </Grid.Column>

                <Grid.Column width={5} textAlign='right'>
                  {/* user actions dropdown */}
                  <Dropdown
                    id='user-actions'
                    data-public
                    labeled
                    icon='bars'
                    floating
                    button
                    className='blue icon'
                    text='User Actions'
                    title='User Actions'
                    closeOnBlur
                  >
                    <Dropdown.Menu direction='left'>
                      {/* user data */}
                      <Dropdown.Item data-public onClick={this.setFilter('isArchived').bind(this, null, { value: !isArchived })}><Icon name='exchange' />{viewUsersButtonText}</Dropdown.Item>

                      <Dropdown.Item data-public onClick={onCreateClick}><Icon name='plus' />{`${createText} ${clientsText}`}</Dropdown.Item>

                      {/* increment actions */}
                      {(isInternalRole) && (
                        <>
                          <IncrementClientAgesByOrgIdMenuItem
                            orgId={organization.id}
                            onLoadingChange={(isUpdating) => this.setState({ ...this.state, isUpdating })}
                          />

                          <IncrementClientGradeLevelsByOrgIdMenuItem
                            orgId={organization.id}
                            onLoadingChange={(isUpdating) => this.setState({ ...this.state, isUpdating })}
                          />
                        </>
                      )}

                      {/* downloadable actions */}
                      <Dropdown.Header content='download actions' />
                      <Dropdown.Divider/>

                      {(!organization.children || organization.children.length === 0) &&
                          (
                            <Dropdown.Item data-public as='a' href={`${config.api.baseUrl}/clientOverviews?${downloadQueryString}`} target='_blank'><Icon name='download' />{`User Data`}</Dropdown.Item>
                          )
                      }

                      {(!!organization.children && organization.children.length > 0) &&
                          (
                            <>
                              <Dropdown.Item data-public as='a' href={`${config.api.baseUrl}/clientOverviews?${downloadQueryString}`} target='_blank'><Icon name='download' />{`User Data (This Organization Only)`}</Dropdown.Item>
                              <Dropdown.Item data-public as='a' href={`${config.api.baseUrl}/clientOverviews?${downloadAllQueryString}`} target='_blank'><Icon name='cloud download' />{`All User Data (Includes all Suborganizations)`}</Dropdown.Item>
                            </>
                          )
                      }

                      <Dropdown.Item data-public as='a' href={`${config.api.baseUrl}/clients/sessions?${downloadAllLoginQueryString}`} target='_blank'><Icon name='id card' />{`User Login Info (Includes all Suborganizations)`}</Dropdown.Item>

                      {(roleType === INTERNAL_ROLE_TYPES.SUPER_ADMIN && bulkEditableUsers.length > 0) &&
                        <>
                          {/* selection actions */}
                          <Dropdown.Header content='selected user(s) actions' />
                          <Dropdown.Divider/>

                          <ArchiveClientsByUserIdsMenuItem
                            {...this.props}
                            isArchived={isArchived}
                            onLoadingChange={this.handleLoadingChange}
                            userIds={this.state.selectedUserIds}
                          />

                          <BulkDeleteClientsMenuItem
                            {...this.props}
                            onLoadingChange={this.handleLoadingChange}
                            org={organization}
                            userIds={this.state.selectedUserIds}
                          />

                          <ChangeClientsOrgMenuItem
                            {...this.props}
                            currentOrg={organization}
                            homeOrg={homeOrganization}
                            onLoadingChange={this.handleLoadingChange}
                            userIds={this.state.selectedUserIds}
                          />

                          <BulkUpdateClientsPasswordMenuItem
                            {...this.props}
                            clientCardData={clientCardData}
                            onLoadingChange={this.handleLoadingChange}
                            userIds={this.state.selectedUserIds}
                          />
                        </>
                      }

                      {/*
                      <Dropdown.Item><Icon name='plus' />Delete Users</Dropdown.Item>
                      <Dropdown.Item onClick={this.archiveUsers.bind(this, (map(filteredClientData, 'id')))}>Archive Users</Dropdown.Item>
                      <Dropdown.Item>{`Change User's Role`}</Dropdown.Item>
                      <Dropdown.Item>{`Export Data`}</Dropdown.Item>
                      */}

                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              </Grid>
              <br/>
              <Accordion id='user-filter-options' fluid styled>
                <Accordion.Title data-public active={activeAccordionIndex === 0} index={0} onClick={this.handleAccordionClick}>
                  <Icon name='dropdown' />
                    Filtering/Sorting Options
                </Accordion.Title>
                <Accordion.Content active={activeAccordionIndex === 0}>
                  <Header data-public size='medium'>Filtering Options</Header>
                  <Form style={{ position: 'unset' }}>
                    <Form.Field width={8}>
                      <label className='text small bold' data-public>Show users that are in any of these roles</label>
                      <Dropdown
                        data-public
                        placeholder={t('resource_types.role', { count: 2, postProcess: 'titleCase' })}
                        clearable
                        fluid
                        search
                        selection
                        multiple
                        value={roleTypes}
                        options={filteredRoles.map((role) => ({ text: role.name, value: role.type }))}
                        onChange={this.setFilter('roleTypes')}
                      />
                    </Form.Field>
                    <Form.Field width={8}>
                      <span data-public className='text small bold'>Show users that</span>
                      <Dropdown
                        data-public
                        inline
                        className='text small bold'
                        style={{ paddingRight: 6 }}
                        options={[ { text: 'have', value: true }, { text: 'have not', value: false } ]}
                        onChange={(e, props) => {
                          this.setMultipleFilters({
                            completedCourseIds: (props.value) ? incompleteCourseIds : [],
                            incompleteCourseIds: (props.value) ? [] : completedCourseIds,
                          })
                          this.setState({ ...this.state, filterByCompleted: props.value })
                        }}
                        value={filterByCompleted}
                      />
                      <span data-public className='text small bold'>completed any of these modules</span>
                      <Dropdown
                        data-public
                        placeholder={t('resource_types.course', { count: 2, postProcess: 'titleCase' })}
                        clearable
                        fluid
                        search
                        selection
                        multiple
                        value={(filterByCompleted) ? completedCourseIds : incompleteCourseIds}
                        options={sortedCourses.map((course) => {
                          const interactivityType = (course.type === 'parent') ? '' : (course.isInstructional) ? 'Instructional' : 'Interactive'
                          const typeLabel = (interactivityType) ? `(${COURSE_TYPES[course.type]} - ${(interactivityType)})` : ''
                          return { text: `${course.titles} ${typeLabel}`, value: course.id }
                        })}
                        onChange={(e, props) => {
                          this.setFilter((filterByCompleted) ? 'completedCourseIds' : 'incompleteCourseIds')(e, props)
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        data-public
                        className='activity-checkbox'
                        toggle
                        label='Show users who were active'
                        onChange={this.toggleActivityFilter}
                        checked={activityFilterEnabled}
                      />
                      <Dropdown
                        data-public
                        inline
                        disabled={!activityFilterEnabled}
                        header='Select time span'
                        options={activityFilterOptions.map((option) => ({ text: option.label, value: option.value }))}
                        onChange={this.setFilter('activeSince')}
                        value={searchParams.activeSince * 1 || 0}
                      />
                    </Form.Field>
                    <Header data-public size='medium'>Sorting Options</Header>
                    <Form.Group inline>
                      <span data-public>Sort users by</span>
                      <Dropdown
                        data-public
                        inline
                        header='Select type'
                        options={sortOptions.map((option) => ({ text: option.label, value: option.value }))}
                        onChange={this.setFilter('sortBy')}
                        value={searchParams.sortBy || 'lastActiveCourse.date'}
                      />
                      <Form.Radio
                        data-public
                        radio
                        label='ascending'
                        name='checkboxRadioGroup'
                        value='asc'
                        checked={searchParams.sortOrder === 'asc'}
                        onChange={this.setFilter('sortOrder')}
                      />
                      <Form.Radio
                        data-public
                        radio
                        label='descending'
                        name='checkboxRadioGroup'
                        value='desc'
                        checked={!searchParams.sortOrder || searchParams.sortOrder === 'desc'}
                        onChange={this.setFilter('sortOrder')}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Content>
              </Accordion>
              {/* <br/>
              <Statistic.Group widths='three' size='tiny'>
                <Statistic color='blue'>
                  <Statistic.Value>{filteredClientData.length.toString()}</Statistic.Value>
                  <Statistic.Label>Users</Statistic.Label>
                </Statistic>

                <Statistic color='blue'>
                  <Statistic.Value>{displayTime}</Statistic.Value>
                  <Statistic.Label>{displayTimeLabel}</Statistic.Label>
                </Statistic>

                <Statistic color='blue'>
                  <Statistic.Value>
                    {courses.toString()}
                  </Statistic.Value>
                  <Statistic.Label>Modules Completed</Statistic.Label>
                </Statistic>
              </Statistic.Group> */}

              {((!clientCardData || !clientCardData.length) && !getClientsState.isLoading) && (
                <Segment
                  placeholder={!getClientsState.isLoading}
                  loading={getClientsState.isLoading}
                  style={{ position: 'unset' }}
                >
                  <Header data-public icon>
                    <Icon name='users' />
                      We were unable to find any users in this organization with the provided search criteria.
                  </Header>
                </Segment>
              )}
              {(!!clientCardData && !!clientCardData.length) && (
                <Pager
                  itemsPerPage={PAGE_SIZE}
                  items={clientCardData}
                  onPageChange={this.setPageNumber}
                  activePage={(searchParams.skip) ? (searchParams.skip / PAGE_SIZE) + 1 : 1}
                  itemsArePaged={true}
                  totalItemCount={clientCounts}
                  render={(items) => {
                    return (
                      <Table celled padded selectable>
                        <Table.Header>
                          <Table.Row textAlign='center'>
                            {roleType === INTERNAL_ROLE_TYPES.SUPER_ADMIN && bulkEditableUsers.length > 0 && <Table.HeaderCell className='text white base-teal-bg' data-public collapsing>
                              <Popup
                                basic
                                hoverable
                                onClick={(e) => {
                                  // NOTE: Don't let click events on the popup do anything
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                trigger={(
                                  <Checkbox
                                    data-public
                                    className='activity-checkbox'
                                    onChange={this.handleSelectClients(bulkEditableUsers.map((u) => u.id))}
                                    checked={bulkEditableUsers.length === this.state.selectedUserIds.length}
                                  />
                                )}
                                position='right center'
                              >
                                <Popup.Content >{bulkEditableUsers.length === this.state.selectedUserIds.length ? 'Deselect' : 'Select'} All</Popup.Content>
                              </Popup>
                            </Table.HeaderCell>}
                            <Table.HeaderCell className='text white base-teal-bg' data-public>User Name</Table.HeaderCell>
                            <Table.HeaderCell className='text white base-teal-bg' data-public>Role</Table.HeaderCell>
                            <Table.HeaderCell className='text white base-teal-bg' data-public>Progress</Table.HeaderCell>
                            {(organization.firewordsEnabled) && (<Table.HeaderCell className='text white base-teal-bg' data-public>Unreviewed Firewords</Table.HeaderCell>)}
                            <Table.HeaderCell className='text white base-teal-bg' data-public>Last Active Module</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {items.map((i) => this.renderClientItem(bulkEditableUsers, i))}
                        </Table.Body>
                      </Table>
                    )
                  }}
                />
              )}
            </Grid.Column>
          </Grid>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
      [FETCH_ACTIONS.POST]: getClientsState2,
    },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    clientActivity: {
      [FETCH_ACTIONS.GET_ALL]: getActivityState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
    authentication: {
      userId,
    },
  } = state

  return {
    getClientsState,
    getClientsState2,
    getCoursesState,
    getActivityState,
    showFriendlyApiErrorMessages,
    userId,
  }
}
const mapDispatchToProps = {
  batchPasswordReset,
  getAllClientsWithOverviews,
  getClientActivity,
  invalidateClients,
}
const ClientsViewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientsView)

export default translate([ 'components' ])(ClientsViewContainer)
