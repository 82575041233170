import gql from 'graphql-tag'

const GET_ORG_USER_ASSESSMENT_SCORES = gql`
query userAssessmentScoresByOrgId($orgId: String!, $assessmentId: String, $schoolYear: Int, $groupByOrgId: Boolean, $groupByDemographic: String, $childrenDepth: Int) {
  userAssessmentScoresByOrgId(
    orgId: $orgId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
    childrenDepth: $childrenDepth
    groupByOrgId: $groupByOrgId
    groupByDemographic: $groupByDemographic
  ) {
    sectionKey
    popAvgTimeSeconds
    popTimeSecondsStdDev
    popAvgScore
    popNormalizedAvgScore
    popScoreStdDev
    groupAvgTimeSeconds
    groupAvgScore
    groupNormalizedAvgScore
    buckets
    orgId
    schoolYearAttempt
    schoolYear
    grade
    age
    ethnicity
    gpa
    gender
    count
  }
}
`

export default GET_ORG_USER_ASSESSMENT_SCORES
