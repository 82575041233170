const TIMEZONE_DATA = [
  // Canonical
  { shortCountryCode: 'CI', timezone: 'Africa/Abidjan' },
  { shortCountryCode: 'GH', timezone: 'Africa/Accra' },
  { shortCountryCode: 'DZ', timezone: 'Africa/Algiers' },
  { shortCountryCode: 'GW', timezone: 'Africa/Bissau' },
  { shortCountryCode: 'EG', timezone: 'Africa/Cairo' },
  { shortCountryCode: 'MA', timezone: 'Africa/Casablanca' },
  { shortCountryCode: 'ES', timezone: 'Africa/Ceuta' },
  { shortCountryCode: 'EH', timezone: 'Africa/El_Aaiun' },
  { shortCountryCode: 'ZA', timezone: 'Africa/Johannesburg' },
  { shortCountryCode: 'SS', timezone: 'Africa/Juba' },
  { shortCountryCode: 'SD', timezone: 'Africa/Khartoum' },
  { shortCountryCode: 'NG', timezone: 'Africa/Lagos' },
  { shortCountryCode: 'MZ', timezone: 'Africa/Maputo' },
  { shortCountryCode: 'LR', timezone: 'Africa/Monrovia' },
  { shortCountryCode: 'KE', timezone: 'Africa/Nairobi' },
  { shortCountryCode: 'TD', timezone: 'Africa/Ndjamena' },
  { shortCountryCode: 'LY', timezone: 'Africa/Tripoli' },
  { shortCountryCode: 'TN', timezone: 'Africa/Tunis' },
  { shortCountryCode: 'NA', timezone: 'Africa/Windhoek' },
  { shortCountryCode: 'US', timezone: 'America/Adak' },
  { shortCountryCode: 'US', timezone: 'America/Anchorage' },
  { shortCountryCode: 'BR', timezone: 'America/Araguaina' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Buenos_Aires' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Catamarca' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Cordoba' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Jujuy' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/La_Rioja' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Mendoza' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Rio_Gallegos' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Salta' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/San_Juan' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/San_Luis' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Tucuman' },
  { shortCountryCode: 'AR', timezone: 'America/Argentina/Ushuaia' },
  { shortCountryCode: 'PY', timezone: 'America/Asuncion' },
  { shortCountryCode: 'CA', timezone: 'America/Atikokan' },
  { shortCountryCode: 'BR', timezone: 'America/Bahia' },
  { shortCountryCode: 'MX', timezone: 'America/Bahia_Banderas' },
  { shortCountryCode: 'BB', timezone: 'America/Barbados' },
  { shortCountryCode: 'BR', timezone: 'America/Belem' },
  { shortCountryCode: 'BZ', timezone: 'America/Belize' },
  { shortCountryCode: 'CA', timezone: 'America/Blanc-Sablon' },
  { shortCountryCode: 'BR', timezone: 'America/Boa_Vista' },
  { shortCountryCode: 'CO', timezone: 'America/Bogota' },
  { shortCountryCode: 'US', timezone: 'America/Boise' },
  { shortCountryCode: 'CA', timezone: 'America/Cambridge_Bay' },
  { shortCountryCode: 'BR', timezone: 'America/Campo_Grande' },
  { shortCountryCode: 'MX', timezone: 'America/Cancun' },
  { shortCountryCode: 'VE', timezone: 'America/Caracas' },
  { shortCountryCode: 'GF', timezone: 'America/Cayenne' },
  { shortCountryCode: 'US', timezone: 'America/Chicago' },
  { shortCountryCode: 'MX', timezone: 'America/Chihuahua' },
  { shortCountryCode: 'CR', timezone: 'America/Costa_Rica' },
  { shortCountryCode: 'CA', timezone: 'America/Creston' },
  { shortCountryCode: 'BR', timezone: 'America/Cuiaba' },
  { shortCountryCode: 'CW', timezone: 'America/Curacao' },
  { shortCountryCode: 'GL', timezone: 'America/Danmarkshavn' },
  { shortCountryCode: 'CA', timezone: 'America/Dawson' },
  { shortCountryCode: 'CA', timezone: 'America/Dawson_Creek' },
  { shortCountryCode: 'US', timezone: 'America/Denver' },
  { shortCountryCode: 'US', timezone: 'America/Detroit' },
  { shortCountryCode: 'CA', timezone: 'America/Edmonton' },
  { shortCountryCode: 'BR', timezone: 'America/Eirunepe' },
  { shortCountryCode: 'SV', timezone: 'America/El_Salvador' },
  { shortCountryCode: 'CA', timezone: 'America/Fort_Nelson' },
  { shortCountryCode: 'BR', timezone: 'America/Fortaleza' },
  { shortCountryCode: 'CA', timezone: 'America/Glace_Bay' },
  { shortCountryCode: 'GL', timezone: 'America/Godthab' },
  { shortCountryCode: 'CA', timezone: 'America/Goose_Bay' },
  { shortCountryCode: 'TC', timezone: 'America/Grand_Turk' },
  { shortCountryCode: 'GT', timezone: 'America/Guatemala' },
  { shortCountryCode: 'EC', timezone: 'America/Guayaquil' },
  { shortCountryCode: 'GY', timezone: 'America/Guyana' },
  { shortCountryCode: 'CA', timezone: 'America/Halifax' },
  { shortCountryCode: 'CU', timezone: 'America/Havana' },
  { shortCountryCode: 'MX', timezone: 'America/Hermosillo' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Indianapolis' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Knox' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Marengo' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Petersburg' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Tell_City' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Vevay' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Vincennes' },
  { shortCountryCode: 'US', timezone: 'America/Indiana/Winamac' },
  { shortCountryCode: 'CA', timezone: 'America/Inuvik' },
  { shortCountryCode: 'CA', timezone: 'America/Iqaluit' },
  { shortCountryCode: 'JM', timezone: 'America/Jamaica' },
  { shortCountryCode: 'US', timezone: 'America/Juneau' },
  { shortCountryCode: 'US', timezone: 'America/Kentucky/Louisville' },
  { shortCountryCode: 'US', timezone: 'America/Kentucky/Monticello' },
  { shortCountryCode: 'BO', timezone: 'America/La_Paz' },
  { shortCountryCode: 'PE', timezone: 'America/Lima' },
  { shortCountryCode: 'US', timezone: 'America/Los_Angeles' },
  { shortCountryCode: 'BR', timezone: 'America/Maceio' },
  { shortCountryCode: 'NI', timezone: 'America/Managua' },
  { shortCountryCode: 'BR', timezone: 'America/Manaus' },
  { shortCountryCode: 'MQ', timezone: 'America/Martinique' },
  { shortCountryCode: 'MX', timezone: 'America/Matamoros' },
  { shortCountryCode: 'MX', timezone: 'America/Mazatlan' },
  { shortCountryCode: 'US', timezone: 'America/Menominee' },
  { shortCountryCode: 'MX', timezone: 'America/Merida' },
  { shortCountryCode: 'US', timezone: 'America/Metlakatla' },
  { shortCountryCode: 'MX', timezone: 'America/Mexico_City' },
  { shortCountryCode: 'PM', timezone: 'America/Miquelon' },
  { shortCountryCode: 'CA', timezone: 'America/Moncton' },
  { shortCountryCode: 'MX', timezone: 'America/Monterrey' },
  { shortCountryCode: 'UY', timezone: 'America/Montevideo' },
  { shortCountryCode: 'BS', timezone: 'America/Nassau' },
  { shortCountryCode: 'US', timezone: 'America/New_York' },
  { shortCountryCode: 'CA', timezone: 'America/Nipigon' },
  { shortCountryCode: 'US', timezone: 'America/Nome' },
  { shortCountryCode: 'BR', timezone: 'America/Noronha' },
  { shortCountryCode: 'US', timezone: 'America/North_Dakota/Beulah' },
  { shortCountryCode: 'US', timezone: 'America/North_Dakota/Center' },
  { shortCountryCode: 'US', timezone: 'America/North_Dakota/New_Salem' },
  { shortCountryCode: 'MX', timezone: 'America/Ojinaga' },
  { shortCountryCode: 'PA', timezone: 'America/Panama' },
  { shortCountryCode: 'CA', timezone: 'America/Pangnirtung' },
  { shortCountryCode: 'SR', timezone: 'America/Paramaribo' },
  { shortCountryCode: 'US', timezone: 'America/Phoenix' },
  { shortCountryCode: 'TT', timezone: 'America/Port_of_Spain' },
  { shortCountryCode: 'HT', timezone: 'America/Port-au-Prince' },
  { shortCountryCode: 'BR', timezone: 'America/Porto_Velho' },
  { shortCountryCode: 'PR', timezone: 'America/Puerto_Rico' },
  { shortCountryCode: 'CL', timezone: 'America/Punta_Arenas' },
  { shortCountryCode: 'CA', timezone: 'America/Rainy_River' },
  { shortCountryCode: 'CA', timezone: 'America/Rankin_Inlet' },
  { shortCountryCode: 'BR', timezone: 'America/Recife' },
  { shortCountryCode: 'CA', timezone: 'America/Regina' },
  { shortCountryCode: 'CA', timezone: 'America/Resolute' },
  { shortCountryCode: 'BR', timezone: 'America/Rio_Branco' },
  { shortCountryCode: 'BR', timezone: 'America/Santarem' },
  { shortCountryCode: 'CL', timezone: 'America/Santiago' },
  { shortCountryCode: 'DO', timezone: 'America/Santo_Domingo' },
  { shortCountryCode: 'BR', timezone: 'America/Sao_Paulo' },
  { shortCountryCode: 'GL', timezone: 'America/Scoresbysund' },
  { shortCountryCode: 'US', timezone: 'America/Sitka' },
  { shortCountryCode: 'CA', timezone: 'America/St_Johns' },
  { shortCountryCode: 'CA', timezone: 'America/Swift_Current' },
  { shortCountryCode: 'HN', timezone: 'America/Tegucigalpa' },
  { shortCountryCode: 'GL', timezone: 'America/Thule' },
  { shortCountryCode: 'CA', timezone: 'America/Thunder_Bay' },
  { shortCountryCode: 'MX', timezone: 'America/Tijuana' },
  { shortCountryCode: 'CA', timezone: 'America/Toronto' },
  { shortCountryCode: 'CA', timezone: 'America/Vancouver' },
  { shortCountryCode: 'CA', timezone: 'America/Whitehorse' },
  { shortCountryCode: 'CA', timezone: 'America/Winnipeg' },
  { shortCountryCode: 'US', timezone: 'America/Yakutat' },
  { shortCountryCode: 'CA', timezone: 'America/Yellowknife' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Casey' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Davis' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/DumontDUrville' },
  { shortCountryCode: 'AU', timezone: 'Antarctica/Macquarie' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Mawson' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Palmer' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Rothera' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Syowa' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Troll' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/Vostok' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Almaty' },
  { shortCountryCode: 'JO', timezone: 'Asia/Amman' },
  { shortCountryCode: 'RU', timezone: 'Asia/Anadyr' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Aqtau' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Aqtobe' },
  { shortCountryCode: 'TM', timezone: 'Asia/Ashgabat' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Atyrau' },
  { shortCountryCode: 'IQ', timezone: 'Asia/Baghdad' },
  { shortCountryCode: 'AZ', timezone: 'Asia/Baku' },
  { shortCountryCode: 'TH', timezone: 'Asia/Bangkok' },
  { shortCountryCode: 'RU', timezone: 'Asia/Barnaul' },
  { shortCountryCode: 'LB', timezone: 'Asia/Beirut' },
  { shortCountryCode: 'KG', timezone: 'Asia/Bishkek' },
  { shortCountryCode: 'BN', timezone: 'Asia/Brunei' },
  { shortCountryCode: 'RU', timezone: 'Asia/Chita' },
  { shortCountryCode: 'MN', timezone: 'Asia/Choibalsan' },
  { shortCountryCode: 'LK', timezone: 'Asia/Colombo' },
  { shortCountryCode: 'SY', timezone: 'Asia/Damascus' },
  { shortCountryCode: 'BD', timezone: 'Asia/Dhaka' },
  { shortCountryCode: 'TL', timezone: 'Asia/Dili' },
  { shortCountryCode: 'AE', timezone: 'Asia/Dubai' },
  { shortCountryCode: 'TJ', timezone: 'Asia/Dushanbe' },
  { shortCountryCode: 'CY', timezone: 'Asia/Famagusta' },
  { shortCountryCode: 'PS', timezone: 'Asia/Gaza' },
  { shortCountryCode: 'PS', timezone: 'Asia/Hebron' },
  { shortCountryCode: 'VN', timezone: 'Asia/Ho_Chi_Minh' },
  { shortCountryCode: 'HK', timezone: 'Asia/Hong_Kong' },
  { shortCountryCode: 'MN', timezone: 'Asia/Hovd' },
  { shortCountryCode: 'RU', timezone: 'Asia/Irkutsk' },
  { shortCountryCode: 'ID', timezone: 'Asia/Jakarta' },
  { shortCountryCode: 'ID', timezone: 'Asia/Jayapura' },
  { shortCountryCode: 'IL', timezone: 'Asia/Jerusalem' },
  { shortCountryCode: 'AF', timezone: 'Asia/Kabul' },
  { shortCountryCode: 'RU', timezone: 'Asia/Kamchatka' },
  { shortCountryCode: 'PK', timezone: 'Asia/Karachi' },
  { shortCountryCode: 'NP', timezone: 'Asia/Kathmandu' },
  { shortCountryCode: 'RU', timezone: 'Asia/Khandyga' },
  { shortCountryCode: 'IN', timezone: 'Asia/Kolkata' },
  { shortCountryCode: 'RU', timezone: 'Asia/Krasnoyarsk' },
  { shortCountryCode: 'MY', timezone: 'Asia/Kuala_Lumpur' },
  { shortCountryCode: 'MY', timezone: 'Asia/Kuching' },
  { shortCountryCode: 'MO', timezone: 'Asia/Macau' },
  { shortCountryCode: 'RU', timezone: 'Asia/Magadan' },
  { shortCountryCode: 'ID', timezone: 'Asia/Makassar' },
  { shortCountryCode: 'PH', timezone: 'Asia/Manila' },
  { shortCountryCode: 'RU', timezone: 'Asia/Novokuznetsk' },
  { shortCountryCode: 'RU', timezone: 'Asia/Novosibirsk' },
  { shortCountryCode: 'RU', timezone: 'Asia/Omsk' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Oral' },
  { shortCountryCode: 'ID', timezone: 'Asia/Pontianak' },
  { shortCountryCode: 'KP', timezone: 'Asia/Pyongyang' },
  { shortCountryCode: 'QA', timezone: 'Asia/Qatar' },
  { shortCountryCode: 'KZ', timezone: 'Asia/Qyzylorda' },
  { shortCountryCode: 'SA', timezone: 'Asia/Riyadh' },
  { shortCountryCode: 'RU', timezone: 'Asia/Sakhalin' },
  { shortCountryCode: 'UZ', timezone: 'Asia/Samarkand' },
  { shortCountryCode: 'KR', timezone: 'Asia/Seoul' },
  { shortCountryCode: 'CN', timezone: 'Asia/Shanghai' },
  { shortCountryCode: 'SG', timezone: 'Asia/Singapore' },
  { shortCountryCode: 'RU', timezone: 'Asia/Srednekolymsk' },
  { shortCountryCode: 'TW', timezone: 'Asia/Taipei' },
  { shortCountryCode: 'UZ', timezone: 'Asia/Tashkent' },
  { shortCountryCode: 'GE', timezone: 'Asia/Tbilisi' },
  { shortCountryCode: 'IR', timezone: 'Asia/Tehran' },
  { shortCountryCode: 'BT', timezone: 'Asia/Thimphu' },
  { shortCountryCode: 'JP', timezone: 'Asia/Tokyo' },
  { shortCountryCode: 'RU', timezone: 'Asia/Tomsk' },
  { shortCountryCode: 'MN', timezone: 'Asia/Ulaanbaatar' },
  { shortCountryCode: 'CN', timezone: 'Asia/Urumqi' },
  { shortCountryCode: 'RU', timezone: 'Asia/Ust-Nera' },
  { shortCountryCode: 'RU', timezone: 'Asia/Vladivostok' },
  { shortCountryCode: 'RU', timezone: 'Asia/Yakutsk' },
  { shortCountryCode: 'MM', timezone: 'Asia/Yangon' },
  { shortCountryCode: 'RU', timezone: 'Asia/Yekaterinburg' },
  { shortCountryCode: 'AM', timezone: 'Asia/Yerevan' },
  { shortCountryCode: 'PT', timezone: 'Atlantic/Azores' },
  { shortCountryCode: 'BM', timezone: 'Atlantic/Bermuda' },
  { shortCountryCode: 'ES', timezone: 'Atlantic/Canary' },
  { shortCountryCode: 'CV', timezone: 'Atlantic/Cape_Verde' },
  { shortCountryCode: 'FO', timezone: 'Atlantic/Faroe' },
  { shortCountryCode: 'PT', timezone: 'Atlantic/Madeira' },
  { shortCountryCode: 'IS', timezone: 'Atlantic/Reykjavik' },
  { shortCountryCode: 'GS', timezone: 'Atlantic/South_Georgia' },
  { shortCountryCode: 'FK', timezone: 'Atlantic/Stanley' },
  { shortCountryCode: 'AU', timezone: 'Australia/Adelaide' },
  { shortCountryCode: 'AU', timezone: 'Australia/Brisbane' },
  { shortCountryCode: 'AU', timezone: 'Australia/Broken_Hill' },
  { shortCountryCode: 'AU', timezone: 'Australia/Currie' },
  { shortCountryCode: 'AU', timezone: 'Australia/Darwin' },
  { shortCountryCode: 'AU', timezone: 'Australia/Eucla' },
  { shortCountryCode: 'AU', timezone: 'Australia/Hobart' },
  { shortCountryCode: 'AU', timezone: 'Australia/Lindeman' },
  { shortCountryCode: 'AU', timezone: 'Australia/Lord_Howe' },
  { shortCountryCode: 'AU', timezone: 'Australia/Melbourne' },
  { shortCountryCode: 'AU', timezone: 'Australia/Perth' },
  { shortCountryCode: 'AU', timezone: 'Australia/Sydney' },
  { shortCountryCode: 'NL', timezone: 'Europe/Amsterdam' },
  { shortCountryCode: 'AD', timezone: 'Europe/Andorra' },
  { shortCountryCode: 'RU', timezone: 'Europe/Astrakhan' },
  { shortCountryCode: 'GR', timezone: 'Europe/Athens' },
  { shortCountryCode: 'RS', timezone: 'Europe/Belgrade' },
  { shortCountryCode: 'DE', timezone: 'Europe/Berlin' },
  { shortCountryCode: 'BE', timezone: 'Europe/Brussels' },
  { shortCountryCode: 'RO', timezone: 'Europe/Bucharest' },
  { shortCountryCode: 'HU', timezone: 'Europe/Budapest' },
  { shortCountryCode: 'MD', timezone: 'Europe/Chisinau' },
  { shortCountryCode: 'DK', timezone: 'Europe/Copenhagen' },
  { shortCountryCode: 'IE', timezone: 'Europe/Dublin' },
  { shortCountryCode: 'GI', timezone: 'Europe/Gibraltar' },
  { shortCountryCode: 'FI', timezone: 'Europe/Helsinki' },
  { shortCountryCode: 'TR', timezone: 'Europe/Istanbul' },
  { shortCountryCode: 'RU', timezone: 'Europe/Kaliningrad' },
  { shortCountryCode: 'UA', timezone: 'Europe/Kiev' },
  { shortCountryCode: 'RU', timezone: 'Europe/Kirov' },
  { shortCountryCode: 'PT', timezone: 'Europe/Lisbon' },
  { shortCountryCode: 'GB', timezone: 'Europe/London' },
  { shortCountryCode: 'LU', timezone: 'Europe/Luxembourg' },
  { shortCountryCode: 'ES', timezone: 'Europe/Madrid' },
  { shortCountryCode: 'MT', timezone: 'Europe/Malta' },
  { shortCountryCode: 'BY', timezone: 'Europe/Minsk' },
  { shortCountryCode: 'MC', timezone: 'Europe/Monaco' },
  { shortCountryCode: 'RU', timezone: 'Europe/Moscow' },
  { shortCountryCode: 'CY', timezone: 'Asia/Nicosia' },
  { shortCountryCode: 'NO', timezone: 'Europe/Oslo' },
  { shortCountryCode: 'FR', timezone: 'Europe/Paris' },
  { shortCountryCode: 'CZ', timezone: 'Europe/Prague' },
  { shortCountryCode: 'LV', timezone: 'Europe/Riga' },
  { shortCountryCode: 'IT', timezone: 'Europe/Rome' },
  { shortCountryCode: 'RU', timezone: 'Europe/Samara' },
  { shortCountryCode: 'RU', timezone: 'Europe/Saratov' },
  { shortCountryCode: 'UA', timezone: 'Europe/Simferopol' },
  { shortCountryCode: 'BG', timezone: 'Europe/Sofia' },
  { shortCountryCode: 'SE', timezone: 'Europe/Stockholm' },
  { shortCountryCode: 'EE', timezone: 'Europe/Tallinn' },
  { shortCountryCode: 'AL', timezone: 'Europe/Tirane' },
  { shortCountryCode: 'RU', timezone: 'Europe/Ulyanovsk' },
  { shortCountryCode: 'UA', timezone: 'Europe/Uzhgorod' },
  { shortCountryCode: 'AT', timezone: 'Europe/Vienna' },
  { shortCountryCode: 'LT', timezone: 'Europe/Vilnius' },
  { shortCountryCode: 'RU', timezone: 'Europe/Volgograd' },
  { shortCountryCode: 'PL', timezone: 'Europe/Warsaw' },
  { shortCountryCode: 'UA', timezone: 'Europe/Zaporozhye' },
  { shortCountryCode: 'CH', timezone: 'Europe/Zurich' },
  { shortCountryCode: 'IO', timezone: 'Indian/Chagos' },
  { shortCountryCode: 'CX', timezone: 'Indian/Christmas' },
  { shortCountryCode: 'CC', timezone: 'Indian/Cocos' },
  { shortCountryCode: 'TF', timezone: 'Indian/Kerguelen' },
  { shortCountryCode: 'SC', timezone: 'Indian/Mahe' },
  { shortCountryCode: 'MV', timezone: 'Indian/Maldives' },
  { shortCountryCode: 'MU', timezone: 'Indian/Mauritius' },
  { shortCountryCode: 'RE', timezone: 'Indian/Reunion' },
  { shortCountryCode: 'WS', timezone: 'Pacific/Apia' },
  { shortCountryCode: 'NZ', timezone: 'Pacific/Auckland' },
  { shortCountryCode: 'PG', timezone: 'Pacific/Bougainville' },
  { shortCountryCode: 'NZ', timezone: 'Pacific/Chatham' },
  { shortCountryCode: 'FM', timezone: 'Pacific/Chuuk' },
  { shortCountryCode: 'CL', timezone: 'Pacific/Easter' },
  { shortCountryCode: 'VU', timezone: 'Pacific/Efate' },
  { shortCountryCode: 'KI', timezone: 'Pacific/Enderbury' },
  { shortCountryCode: 'TK', timezone: 'Pacific/Fakaofo' },
  { shortCountryCode: 'FJ', timezone: 'Pacific/Fiji' },
  { shortCountryCode: 'TV', timezone: 'Pacific/Funafuti' },
  { shortCountryCode: 'EC', timezone: 'Pacific/Galapagos' },
  { shortCountryCode: 'PF', timezone: 'Pacific/Gambier' },
  { shortCountryCode: 'SB', timezone: 'Pacific/Guadalcanal' },
  { shortCountryCode: 'GU', timezone: 'Pacific/Guam' },
  { shortCountryCode: 'US', timezone: 'Pacific/Honolulu' },
  { shortCountryCode: 'KI', timezone: 'Pacific/Kiritimati' },
  { shortCountryCode: 'FM', timezone: 'Pacific/Kosrae' },
  { shortCountryCode: 'MH', timezone: 'Pacific/Kwajalein' },
  { shortCountryCode: 'MH', timezone: 'Pacific/Majuro' },
  { shortCountryCode: 'PF', timezone: 'Pacific/Marquesas' },
  { shortCountryCode: 'NR', timezone: 'Pacific/Nauru' },
  { shortCountryCode: 'NU', timezone: 'Pacific/Niue' },
  { shortCountryCode: 'NF', timezone: 'Pacific/Norfolk' },
  { shortCountryCode: 'NC', timezone: 'Pacific/Noumea' },
  { shortCountryCode: 'AS', timezone: 'Pacific/Pago_Pago' },
  { shortCountryCode: 'PW', timezone: 'Pacific/Palau' },
  { shortCountryCode: 'PN', timezone: 'Pacific/Pitcairn' },
  { shortCountryCode: 'FM', timezone: 'Pacific/Pohnpei' },
  { shortCountryCode: 'PG', timezone: 'Pacific/Port_Moresby' },
  { shortCountryCode: 'CK', timezone: 'Pacific/Rarotonga' },
  { shortCountryCode: 'PF', timezone: 'Pacific/Tahiti' },
  { shortCountryCode: 'KI', timezone: 'Pacific/Tarawa' },
  { shortCountryCode: 'TO', timezone: 'Pacific/Tongatapu' },
  { shortCountryCode: 'UM', timezone: 'Pacific/Wake' },
  { shortCountryCode: 'WF', timezone: 'Pacific/Wallis' },
  // Aliases
  { shortCountryCode: 'ET', timezone: 'Africa/Addis_Ababa' },
  { shortCountryCode: 'ER', timezone: 'Africa/Asmara' },
  { shortCountryCode: 'ML', timezone: 'Africa/Bamako' },
  { shortCountryCode: 'CF', timezone: 'Africa/Bangui' },
  { shortCountryCode: 'GM', timezone: 'Africa/Banjul' },
  { shortCountryCode: 'MW', timezone: 'Africa/Blantyre' },
  { shortCountryCode: 'CG', timezone: 'Africa/Brazzaville' },
  { shortCountryCode: 'BI', timezone: 'Africa/Bujumbura' },
  { shortCountryCode: 'GN', timezone: 'Africa/Conakry' },
  { shortCountryCode: 'SN', timezone: 'Africa/Dakar' },
  { shortCountryCode: 'TZ', timezone: 'Africa/Dar_es_Salaam' },
  { shortCountryCode: 'DJ', timezone: 'Africa/Djibouti' },
  { shortCountryCode: 'CM', timezone: 'Africa/Douala' },
  { shortCountryCode: 'SL', timezone: 'Africa/Freetown' },
  { shortCountryCode: 'BW', timezone: 'Africa/Gaborone' },
  { shortCountryCode: 'ZW', timezone: 'Africa/Harare' },
  { shortCountryCode: 'UG', timezone: 'Africa/Kampala' },
  { shortCountryCode: 'RW', timezone: 'Africa/Kigali' },
  { shortCountryCode: 'CD', timezone: 'Africa/Kinshasa' },
  { shortCountryCode: 'GA', timezone: 'Africa/Libreville' },
  { shortCountryCode: 'TG', timezone: 'Africa/Lome' },
  { shortCountryCode: 'AO', timezone: 'Africa/Luanda' },
  { shortCountryCode: 'CD', timezone: 'Africa/Lubumbashi' },
  { shortCountryCode: 'ZM', timezone: 'Africa/Lusaka' },
  { shortCountryCode: 'GQ', timezone: 'Africa/Malabo' },
  { shortCountryCode: 'LS', timezone: 'Africa/Maseru' },
  { shortCountryCode: 'SZ', timezone: 'Africa/Mbabane' },
  { shortCountryCode: 'SO', timezone: 'Africa/Mogadishu' },
  { shortCountryCode: 'NE', timezone: 'Africa/Niamey' },
  { shortCountryCode: 'MR', timezone: 'Africa/Nouakchott' },
  { shortCountryCode: 'BF', timezone: 'Africa/Ouagadougou' },
  { shortCountryCode: 'BJ', timezone: 'Africa/Porto-Novo' },
  { shortCountryCode: 'ST', timezone: 'Africa/Sao_Tome' },
  { shortCountryCode: 'AI', timezone: 'America/Anguilla' },
  { shortCountryCode: 'AG', timezone: 'America/Antigua' },
  { shortCountryCode: 'AW', timezone: 'America/Aruba' },
  { shortCountryCode: 'KY', timezone: 'America/Cayman' },
  { shortCountryCode: 'DM', timezone: 'America/Dominica' },
  { shortCountryCode: 'GD', timezone: 'America/Grenada' },
  { shortCountryCode: 'GP', timezone: 'America/Guadeloupe' },
  { shortCountryCode: 'BQ', timezone: 'America/Kralendijk' },
  { shortCountryCode: 'SX', timezone: 'America/Lower_Princes' },
  { shortCountryCode: 'MF', timezone: 'America/Marigot' },
  { shortCountryCode: 'MS', timezone: 'America/Montserrat' },
  { shortCountryCode: 'BL', timezone: 'America/St_Barthelemy' },
  { shortCountryCode: 'KN', timezone: 'America/St_Kitts' },
  { shortCountryCode: 'LC', timezone: 'America/St_Lucia' },
  { shortCountryCode: 'VI', timezone: 'America/St_Thomas' },
  { shortCountryCode: 'VC', timezone: 'America/St_Vincent' },
  { shortCountryCode: 'VG', timezone: 'America/Tortola' },
  { shortCountryCode: 'AQ', timezone: 'Antarctica/McMurdo' },
  { shortCountryCode: 'SJ', timezone: 'Arctic/Longyearbyen' },
  { shortCountryCode: 'YE', timezone: 'Asia/Aden' },
  { shortCountryCode: 'BH', timezone: 'Asia/Bahrain' },
  { shortCountryCode: 'KW', timezone: 'Asia/Kuwait' },
  { shortCountryCode: 'OM', timezone: 'Asia/Muscat' },
  { shortCountryCode: 'KH', timezone: 'Asia/Phnom_Penh' },
  { shortCountryCode: 'MM', timezone: 'Asia/Rangoon' },
  { shortCountryCode: 'LA', timezone: 'Asia/Vientiane' },
  { shortCountryCode: 'SH', timezone: 'Atlantic/St_Helena' },
  { shortCountryCode: 'SK', timezone: 'Europe/Bratislava' },
  { shortCountryCode: 'DE', timezone: 'Europe/Busingen' },
  { shortCountryCode: 'GG', timezone: 'Europe/Guernsey' },
  { shortCountryCode: 'IM', timezone: 'Europe/Isle_of_Man' },
  { shortCountryCode: 'JE', timezone: 'Europe/Jersey' },
  { shortCountryCode: 'SI', timezone: 'Europe/Ljubljana' },
  { shortCountryCode: 'AX', timezone: 'Europe/Mariehamn' },
  { shortCountryCode: 'ME', timezone: 'Europe/Podgorica' },
  { shortCountryCode: 'SM', timezone: 'Europe/San_Marino' },
  { shortCountryCode: 'BA', timezone: 'Europe/Sarajevo' },
  { shortCountryCode: 'MK', timezone: 'Europe/Skopje' },
  { shortCountryCode: 'LI', timezone: 'Europe/Vaduz' },
  { shortCountryCode: 'VA', timezone: 'Europe/Vatican' },
  { shortCountryCode: 'HR', timezone: 'Europe/Zagreb' },
  { shortCountryCode: 'MG', timezone: 'Indian/Antananarivo' },
  { shortCountryCode: 'KM', timezone: 'Indian/Comoro' },
  { shortCountryCode: 'YT', timezone: 'Indian/Mayotte' },
  { shortCountryCode: 'UM', timezone: 'Pacific/Midway' },
  { shortCountryCode: 'MP', timezone: 'Pacific/Saipan' },
]

export default TIMEZONE_DATA
