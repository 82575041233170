import to from 'await-to-js'
import qs from 'qs'
import httperr from 'httperr'

import fetch from '../helpers/fetch'
import loginRequest from '../reducers/authentication/login-request'
import refreshLoginRequest from '../reducers/authentication/refresh-login-request'
import loginSuccess from '../reducers/authentication/login-success'
import loginFailure from '../reducers/authentication/login-failure'
import cancelRefreshLoginRequest from '../reducers/authentication/cancel-refresh-login-request'
import { HTTP_METHODS } from '../helpers/fetch-constants'

const STORE_PATH = 'authentication'
const TOKEN_PATH = '/token'
const GRANT_TYPES = {
  PASSWORD: 'password',
  REFRESH: 'refresh_token',
}
const SESSION_EXPIRED_ERR = 'session expired'

export function login ({ userName, password }) {
  return [
    () => async (dispatch) => {
      dispatch([ loginRequest(), STORE_PATH, 'loginRequest' ])
      const data = qs.stringify({
        grant_type: GRANT_TYPES.PASSWORD,
        username: userName,
        password,
      })
      const [ err, result ] = await to(fetch(TOKEN_PATH, { method: HTTP_METHODS.POST, data }))
      if (err) {
        return dispatch([ loginFailure(err), STORE_PATH, 'loginRequestFailure' ])
      }

      const userId = result.access_token.split(':')[0]
      const roleType = result.access_token.split(':')[2]
      if (process.env.REACT_APP_NAME === 'BASE Administration' && (roleType === 'student' || roleType === 'adult')) {
        const loginError = httperr[418]('Students are not allowed to use the admin portal')
        return dispatch([ loginFailure(loginError), STORE_PATH, 'loginRequestFailure' ])
      }

      const promises = [
        fetch(`/clients/${userId}`, { headers: { authorization: `Bearer ${result.access_token}` }, method: HTTP_METHODS.GET }),
        fetch(`/clients/${userId}/profiles`, { headers: { authorization: `Bearer ${result.access_token}` }, method: HTTP_METHODS.GET }),
      ]
      const [ selfErr, selfResult ] = await to(Promise.all(promises))
      if (selfErr) {
        return dispatch([ loginFailure(selfErr), STORE_PATH, 'loginRequestFailure' ])
      }
      const [ user, [ profile ] ] = selfResult
      dispatch([ loginSuccess({
        ...result,
        email: user.email,
        userName: user.userName,
        profile,
      }), STORE_PATH, 'loginRequestSuccess' ])
    },
  ]
}

export function logout ({ isUserInitiated = false } = {}) {
  const error = (isUserInitiated) ? null : new Error(SESSION_EXPIRED_ERR)
  const name = (isUserInitiated) ? 'logout' : 'sessionExpired'
  return [ loginFailure(error), STORE_PATH, name ]
}

export function refresh ({ refreshToken }) {
  return [
    () => async (dispatch) => {
      const data = qs.stringify({ grant_type: GRANT_TYPES.REFRESH, refresh_token: refreshToken })
      dispatch([ refreshLoginRequest(), STORE_PATH, 'refreshLoginRequest' ])
      const [ err, result ] = await to(fetch(TOKEN_PATH, { method: 'post', data }))
      if (err) {
        // Don't log user out if they have lost their connection when refreshing
        if (err.statusCode === 0) {
          return dispatch([ cancelRefreshLoginRequest(), STORE_PATH, 'cancelRefreshLoginRequest' ])
        }
        return dispatch([ loginFailure(err), STORE_PATH, 'refreshLoginFailure' ])
      }
      const userId = result.access_token.split(':')[0]
      const promises = [
        fetch(`/clients/${userId}`, { headers: { authorization: `Bearer ${result.access_token}` }, method: HTTP_METHODS.GET }),
        fetch(`/clients/${userId}/profiles`, { headers: { authorization: `Bearer ${result.access_token}` }, method: HTTP_METHODS.GET }),
      ]
      const [ selfErr, selfResult ] = await to(Promise.all(promises))
      if (selfErr) {
        return dispatch([ loginFailure(selfErr), STORE_PATH, 'loginRequestFailure' ])
      }
      const [ user, [ profile ] ] = selfResult
      dispatch([ loginSuccess({
        ...result,
        email: user.email,
        userName: user.userName,
        profile,
      }), STORE_PATH, 'refreshLoginSuccess' ])
    },
  ]
}

export function setToken (token, refreshToken) {
  return [
    () => async (dispatch) => {
      dispatch([ loginRequest(), STORE_PATH, 'loginRequest' ])

      const userId = token.split(':')[0]
      const promises = [
        fetch(`/clients/${userId}`, { headers: { authorization: `Bearer ${token}` }, method: HTTP_METHODS.GET }),
        fetch(`/clients/${userId}/profiles`, { headers: { authorization: `Bearer ${token}` }, method: HTTP_METHODS.GET }),
      ]
      const [ selfErr, selfResult ] = await to(Promise.all(promises))
      if (selfErr) {
        return dispatch([ loginFailure(selfErr), STORE_PATH, 'loginRequestFailure' ])
      }
      const [ user, [ profile ] ] = selfResult
      dispatch([ loginSuccess({
        access_token: token,
        refresh_token: refreshToken,
        expires_in: 3500,
        email: user.email,
        userName: user.userName,
        profile,
      }), STORE_PATH, 'loginRequestSuccess' ])
    },
  ]
}

export function setAuthProfile (profile) {
  return [
    () => async (dispatch) => {
      dispatch([ (state) => ({ ...state, profile: { ...state.profile, ...profile } }), STORE_PATH, 'setAuthProfile' ])
    },
  ]
}

export function setTokenError (error) {
  return [
    () => async (dispatch) => {
      return dispatch([ loginFailure(error), STORE_PATH, 'loginRequestFailure' ])
    },
  ]
}
