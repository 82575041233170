import React from 'react'
import PropTypes from 'prop-types'

import './progress-bar.css'
import '../../common.css'

const ProgressBar = ({
  hideText,
  completed,
}) => {
  completed = Math.min(Math.max(0, completed), 100)
  const classes = (hideText)
    ? 'progress-bar-progress hide-text'
    : 'progress-bar-progress text bold'
  return (
    <div className='progress-bar'>
      <div className={classes} style={{ width: `${completed}%` }}>{(hideText) ? '' : `${completed}%`}</div>
    </div>
  )
}

ProgressBar.propTypes = {
  hideText: PropTypes.bool,
  completed: PropTypes.number,
}

ProgressBar.defaultProps = {
  hideText: false,
  completed: 0,
}

export default ProgressBar
