import { clone } from 'lodash'
import { FETCH_ACTIONS } from './fetch-constants'

const defaultGetAllState = {
  isLoading: false,
  loadingStartTime: 0,
  isStale: true,
  value: null,
  lastUpdated: 0,
  error: null,
}
const defaultGetState = {
  id: null,
  isLoading: false,
  loadingStartTime: 0,
  value: null,
  lastUpdated: 0,
  error: null,
}
const defaultModifyState = {
  id: null,
  isLoading: false,
  loadingStartTime: 0,
  succeeded: false,
  lastUpdated: 0,
  error: null,
}

const initialStatesByFetchAction = {
  [FETCH_ACTIONS.GET_ALL]: defaultGetAllState,
  [FETCH_ACTIONS.GET]: defaultGetState,
  [FETCH_ACTIONS.POST]: defaultModifyState,
  [FETCH_ACTIONS.PUT]: defaultModifyState,
  [FETCH_ACTIONS.DELETE]: defaultModifyState,
}

export default function getInitialFetchState (fetchActions = Object.values(FETCH_ACTIONS)) {
  const state = {}
  fetchActions.forEach((action) => {
    state[action] = clone(initialStatesByFetchAction[action])
  })
  return state
}
