import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import to from 'await-to-js'
import pDebounce from 'p-debounce'
import httperr from 'httperr'
import {
  compact,
  difference,
  filter,
  find,
  get,
  groupBy,
  intersection,
  keyBy,
  map,
  sortBy,
  orderBy,
  partial,
  reject,
  union,
  without,
  truncate,
} from 'lodash'
import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Header,
  Icon,
  Image,
  Label,
  List,
  Menu,
  Message,
  Popup,
  Segment,
  Tab,
  Table,
  Dropdown,
  Modal,
} from 'semantic-ui-react'
import moment from 'moment'
import { Player } from 'video-react'

import Pager from '../../../../common/pager/uncontrolled-pager'
import Query from '../../../../common/query/query'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ROOM_BY_ID from '../../../../../helpers/graphql-queries/get-room-by-id'
import GET_STAFF_BY_ORG_ID from '../../../../../helpers/graphql-queries/get-staff-by-org-id'
import DELETE_ROOM from '../../../../../helpers/graphql-queries/delete-room'
import UPDATE_ROOM from '../../../../../helpers/graphql-queries/update-room'
import PROCESS_STUDENT_REQUESTS from '../../../../../helpers/graphql-queries/process-student-requests'
// import GET_COURSES from '../../../../../helpers/graphql-queries/get-courses'
import Mutation from '../../../../common/mutation/mutation'
import ProgressDots from '../../../../common/progress-dots'
import ProgressBar from '../../../../common/progress-bar'
import getRoles, { EXTERNAL_ROLE_TYPES, INTERNAL_ROLE_TYPES } from '../../../../../helpers/get-roles'
import CourseCard from '../../../../common/course-card/course-card'
import SearchInput from '../../../../common/search-input'
import RoomStudentsModal from '../../../../forms/create-room/room-students-modal'
import RoomCoursesModal from '../../../../forms/create-room/room-courses-modal'
import RoomDetailsModal from '../../../../forms/create-room/room-details-modal'
import CourseModal from '../../../../common/course-modal'
import { getDisplayName, matchesSearchText } from '../../../../../helpers/user'
import fetch from '../../../../../helpers/fetch'
import isUnauthorizedError from '../../../../../helpers/is-unauthorized-error'
import { logout } from '../../../../../actions/authentication'
import { HTTP_METHODS, FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import {
  getAllChildrenById,
} from '../../../../../helpers/organization'
import UserActivityChart from '../../../../charts/user-activity'
import CourseUsageChart from '../../../../charts/course-usage'
import FetchResultMessage from '../../../../common/fetch-result-message'
import { getAllContentItems } from '../../../../../actions/content-items'
import { LGBTQ_COURSE_IDS } from '../../../../../helpers/module-constants'
import PUT_ACTIVITY from '../../../../../helpers/graphql-queries/put-activity'
import CourseSelector from '../../../../common/course-selector/course-selector'

import './rooms.css'

const PAGE_SIZE = 50
const ROLES = getRoles()
const SA_DEFAULT_COLOR = 'darkblue'
const SA_COURSE_COLORS = {
  'drug facts': 'brown',
  'drugs and the mind': 'darkorange',
  'drugs and the body': 'gold',
  'drugs and relationships': '#8807bf',
  'overcoming drugs': '#b3196f',
  'living drug-free': '#0091d6',
}
const dropdownLanguageOptions = [
  { text: 'None', value: 'en' },
  { text: 'English', value: 'en_sub' },
  { text: 'Español (Spanish)', value: 'es_sub' },
  { text: 'عربى (Arabic)', value: 'ar_sub' },
  { text: 'Français (French)', value: 'fr_sub' },
  { text: '한국어 (Korean)', value: 'ko_sub' },
  { text: '简化字 (Chinese - Simplified)', value: 'zh_sub' },
  { text: 'हिंदी (Hindi)', value: 'hi_sub' },
  { text: 'украї́нська мо́ва (Ukrainian)', value: 'uk_sub' },
  { text: 'Русский язык  (Russian)', value: 'ru_sub' },
  { text: 'Polski (Polish)', value: 'pl_sub' },
  { text: 'فارسی (Persian / Farsi)', value: 'fa_sub' },
  { text: 'ਪੰਜਾਬੀ (Punjabi)', value: 'pa_sub' },
]
const roomIdPathParam = ':roomId'
const path = `/rooms/${roomIdPathParam}`
const alternatePath = `/all-rooms/${roomIdPathParam}`

const EDIT_ROLES = [
  INTERNAL_ROLE_TYPES.SUPER_ADMIN,
  INTERNAL_ROLE_TYPES.INTERNAL_ADMIN,
  INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER,
  EXTERNAL_ROLE_TYPES.ADMIN,
]

export const helpers = {
  // all urls under a given room
  getRoomDetailsPath: (roomId, isAlternate) => (isAlternate) ? `${alternatePath.replace(roomIdPathParam, roomId)}` : `${path.replace(roomIdPathParam, roomId)}`,
}

const mapFirePhrase = (color, fpc) => ({
  color, sentence: fpc.sentence, questionText: fpc.questionText,
})
const mapRedFirePhrases = partial(mapFirePhrase, 'red')
const mapOrangeFirePhrases = partial(mapFirePhrase, 'orange')
const mapYellowFirePhrases = partial(mapFirePhrase, 'yellow')

export class RoomDetailsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    homeOrganization: PropTypes.object.isRequired,
    onReviewProgressClick: PropTypes.func.isRequired,
    onReviewFirewordsClick: PropTypes.func.isRequired,
    onStudentClick: PropTypes.func.isRequired,
    onCourseClick: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool.isRequired,
    getAllContentItems: PropTypes.func.isRequired,
    getContentItemsState: PropTypes.object.isRequired,
  }

  static path = path

  static alternatePath = alternatePath

  constructor (props) {
    super(props)
    this.state = {
      showAddStudentsModal: false,
      showAddCoursesModal: false,
      showEditRoomModal: false,
      activePageNumber: 1,
      activeTabIndex: 0,
      filter: '',
      launchCourseId: null,
      printCourseId: null,
      searchResults: [],
      isSearching: false,
      isVideo: false,
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      authState: { accessToken },
      getContentItemsState,
      getAllContentItems,
    } = this.props

    if (this.state.printCourseId && prevState.printCourseId !== this.state.printCourseId && !getContentItemsState.isLoading) {
      getAllContentItems({ accessToken, courseId: this.state.printCourseId })
    }
  }

  setPageNumber = (num) => this.setState({ ...this.state, activePageNumber: num })

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  setActiveTabIndex = (num) => this.setState({
    ...this.state, activeTabIndex: num, activePageNumber: 1, filter: '',
  })

  renderClientItem = (clientItemData) => {
    const {
      onStudentClick,
      organization,
      match: { params: { roomId } },
    } = this.props
    const displayName = getDisplayName(clientItemData, '|')
    const lastActiveCourse = clientItemData.activeCourses[0]
    const lastActiveCourseElem = (lastActiveCourse)
      ? (<React.Fragment><div className='text bold'>{moment(lastActiveCourse.lastActiveDate).fromNow()}</div><div className='text italic'>{`(${lastActiveCourse.title})`}</div></React.Fragment>)
      : ''
    // TODO: calculate overall progress correctly, based on latest attempt of each module
    // right now, if there is one completed attempt, it will count that module as completed, even if the latest attempt isn't
    const groupedEnrollments = groupBy(clientItemData.activeCourses, 'courseId')
    const unreviewedFirePhraseCaptures = clientItemData.unreviewedFirePhraseCaptures || []
    const analyzedCaptures = unreviewedFirePhraseCaptures.flatMap((capture) => capture.analyzedCaptures)
    const redCaptures = union(filter(analyzedCaptures, { ignisSeverity: 3 }), filter(analyzedCaptures, { receptivitiSeverity: 3 }))
    const orangeCaptures = union(filter(analyzedCaptures, { ignisSeverity: 2 }), filter(analyzedCaptures, { receptivitiSeverity: 2 }))
    const yellowCaptures = union(filter(analyzedCaptures, { ignisSeverity: 1 }), filter(analyzedCaptures, { receptivitiSeverity: 1 }))
    const highestPriorityColor = ((redCaptures.length > 0 && 'red') || (orangeCaptures.length > 0 && 'orange') || (yellowCaptures.length > 0 && 'yellow') || 'grey')
    const roomHasCourses = (!!clientItemData.roomCourseIds && !!clientItemData.roomCourseIds.length)
    const completedRoomCourseIds = (roomHasCourses) ? intersection(clientItemData.completedCourseIds, clientItemData.roomCourseIds) : clientItemData.completedCourseIds
    const totalCourseIds = (roomHasCourses) ? clientItemData.roomCourseIds : Object.keys(groupedEnrollments)
    const progress = (clientItemData.activeCourses.length) ? Math.floor(100 * completedRoomCourseIds.length / totalCourseIds.length) : 0
    const firePhrases = [
      ...redCaptures.map(mapRedFirePhrases),
      ...orangeCaptures.map(mapOrangeFirePhrases),
      ...yellowCaptures.map(mapYellowFirePhrases),
    ]
    const [ name, additionalName ] = displayName.split('|')
    return (
      <Table.Row
        className='client-item'
        key={clientItemData.userName}
        id={`client-${clientItemData.id}`}
        onClick={() => onStudentClick(roomId, clientItemData.id)}
        // onClick={() => this.setState({
        //   ...this.state, selectedUser: clientItemData,
        // })}
      >
        <Table.Cell><strong>{name}</strong> {additionalName}<br/><span className='text italic base-teal'>{clientItemData.orgName}</span></Table.Cell>
        <Table.Cell data-public>{lastActiveCourseElem}</Table.Cell>
        <Popup
          hoverable
          onClick={(e) => {
            // NOTE: Don't let click events on the popup do anything
            e.preventDefault()
            e.stopPropagation()
          }}
          trigger={(
            <Table.Cell collapsing={true}>
              <ProgressDots
                completed={progress}
                radius={8}
              />
            </Table.Cell>
          )}
          position='right center'
        >
          <Popup.Content style={{ width: 220 }}>
            {clientItemData.activeCourses.length === 0 && (
              <div data-public className='course-progress-label text bold italic'>No module activity</div>
            )}
            {clientItemData.activeCourses.length > 0 && (
              <Header data-public as='h3' className='course-progress-label'>Most Recent Activity</Header>
            )}
            {clientItemData.activeCourses.slice(0, 3).map((course, i) => {
              return (
                <div key={course.courseId + i}>
                  <ProgressBar completed={course.progress} />
                  <div data-public className='course-progress-label text bold clip'>{course.title}</div>
                </div>
              )
            })}
          </Popup.Content>
        </Popup>
        {(organization.firewordsEnabled) && (
          <Popup
            hoverable
            disabled={!analyzedCaptures.length}
            onClick={(e) => {
              // NOTE: Don't let click events on the popup do anything
              e.preventDefault()
              e.stopPropagation()
            }}
            trigger={(
              <Table.Cell collapsing data-public textAlign='center'>
                <Icon name='fire' color={highestPriorityColor} size={highestPriorityColor !== 'grey' ? 'large' : null}/> {analyzedCaptures.length}
              </Table.Cell>
            )}
            position='right center'
          >
            <Popup.Content style={{ width: 220 }}>
              <List>
                {firePhrases.slice(0, 10).map((fp) => {
                  return (
                    <List.Item key={fp.sentence}>
                      <List.Icon name='fire' color={fp.color}/>
                      <List.Content>
                        <div className='text bold italic'>{!!fp.questionText && truncate(fp.questionText, { length: 49 })}</div>
                        {fp.sentence}
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
              {(firePhrases.length > 10) && (
                <div data-public style={{ paddingBottom: 10, float: 'right' }} className='text bold italic grey'>more...</div>
              )}
            </Popup.Content>
          </Popup>
        )}

      </Table.Row>
    )
  }

  renderCourse = (room, course) => {
    const {
      organization,
      onCourseClick,
      match: { params: { roomId } },
      authState: {
        roleType, userId,
      },
    } = this.props
    const title = course.titles || course.title.en
    const lowerCaseTitle = title.toLowerCase()
    const isSubstanceAbuse = lowerCaseTitle.includes('substance use and misuse')
    const substanceAbuseCategory = (isSubstanceAbuse) ? lowerCaseTitle.replace(/substance use and misuse: (section \d\d ?- ?)?/, '').split(' - ')[0] : null
    const colorBar = (substanceAbuseCategory) ? SA_COURSE_COLORS[substanceAbuseCategory] || SA_DEFAULT_COLOR : null
    const isHidden = (room.hiddenCourseIds && room.hiddenCourseIds.includes(course.id))
    const categories = (course.referralReasons || course.tags)
    const relevantCategories = (intersection(organization.courseIds, LGBTQ_COURSE_IDS).length) ? categories : without(categories, 'lgbtq')
    const isDeprecated = CourseSelector.coursePacks.secondary.courseIds.includes(course.id)
    return (
      <Mutation
        key={course.id}
        mutation={UPDATE_ROOM}
        awaitRefetchQueries={true}
      >
        {(updateRoom, { loading: isSaving }) => {
          return (
            <CourseCard
              id={course.id}
              interactiveId={(course.isInstructional) ? undefined : course.id}
              instructionalId={(course.isInstructional) ? course.id : undefined}
              isDeprecated={isDeprecated}
              colorBar={colorBar}
              title={title}
              description={course.descriptions || course.description.en}
              showProgress={false}
              isLoading={isSaving || this.state.printCourseId === course.id}
              isActive={course.isActive}
              imageUrl={course.imageUrl || 'https://media.base.education/img/base-7m-logo-color-no-solution.png'}
              videoUrl={course.videoUrl && course.videoUrl.en}
              guides={course.guides && course.guides.map((guide) => ({
                name: guide.name.en,
                url: guide.url.en,
              }))}
              categories={relevantCategories}
              audience={course.type}
              isInstructional={course.isInstructional}
              onPrint={(cId) => {
                this.setState({ ...this.state, printCourseId: cId })
              }}
              onLaunch={(cId, isVideo) => {
                this.setState({
                  ...this.state, launchCourseId: cId, isVideo,
                })
              }}
              canManage={false}
              canLaunch={!isDeprecated}
              canPrint={!!course.contentItems && !!course.contentItems.length}
              onViewResponses={() => onCourseClick(roomId, course.id)}
              showHiddenOverlay={isHidden}
              onToggleVisibility={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType)) ? null : (id, hidden) => {
                updateRoom({
                  variables: {
                    room: {
                      id: roomId,
                      hiddenCourseIds: (hidden) ? union(room.hiddenCourseIds, [ id ]) : difference(room.hiddenCourseIds, [ id ]),
                    },
                  },
                  refetchQueries: [ {
                    query: GET_ROOM_BY_ID,
                    variables: {
                      id: roomId,
                    },
                  } ],
                })
              }}
            />
          )
        }}
      </Mutation>
    )
  }

  setTextFilter = (filter) => {
    this.setState({ ...this.state, filter })
  }

  render () {
    const {
      homeOrganization,
      getCoursesState,
      organization,
      authState: {
        accessToken, refreshToken, roleType, userId,
      },
      match: { params: { roomId } },
      location: { pathname },
      showFriendlyApiErrorMessages,
      getContentItemsState,
      logout,
    } = this.props
    const {
      showAddStudentsModal,
      showAddCoursesModal,
      showEditRoomModal,
      activePageNumber,
      activeTabIndex,
      launchCourseId,
      searchResults,
      isSearching,
      printCourseId,
      isVideo,
    } = this.state
    const cisById = get(getContentItemsState, 'value', {}) || {}
    const cis = sortBy(Object.values(cisById), 'ordinal')
    const printCourse = (printCourseId && getCoursesState.value) ? getCoursesState.value[printCourseId] : null
    if (!!printCourse && !getContentItemsState.isLoading && !getContentItemsState.error && !getCoursesState.isLoading && !getCoursesState.error && !!cis.length && cis[0]._meta.parent === `/courses/${printCourse.id}`) {
      const newWin = window.frames['printf']
      // let content = '<body><h3>' + printCourse.titles + ' (with Educator Notes)</h3><ul>'
      let content = '<body><h3>' + printCourse.titles + '</h3><ul>'
      content += cis.map((ci) => {
        const titles = (ci.titles && !!ci.titles.length) ? '<h4 style="margin-top:0px;margin-bottom:5px;">' + ci.titles.join('<br/>') + '</h4>' : ''
        const bodies = (ci.bodies && !!ci.bodies.length) ? '<p style="margin-top:0px; padding-top:0px;">' + ci.bodies.join('<br/>') + '</p>' : ''
        const notes = (ci.notes && ci.notes.en && roleType !== 'student' && roleType !== 'adult') ? '<p style="font-style: italic; color: #767676;">' + ((ci.notes.en.match(/[A-Z ]{2,}:/g)) ? ci.notes.en.match(/[A-Z ]{2,}:/g).map((n, i) => { const secondParts = ci.notes.en.split(/[A-Z ]{2,}:/).filter((r) => !!r); return n + ((secondParts[i]) ? secondParts[i] : '') }).join('<br/><br/>') : ci.notes.en) + '</p>' : ''
        const viewContainer = ci.view.container
        const isFileRef = (viewContainer.indexOf('.jpg') >= 0 || viewContainer.indexOf('.png') >= 0 || viewContainer.indexOf('.jpeg') >= 0 || viewContainer.indexOf('.svg') >= 0)
        const img = (isFileRef) ? viewContainer : without(viewContainer.split(' '), 'c', 'n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw')[0].replace(/^(\d)/, 'a$1')
        const fileExt = (img === 'grumpy-cat') ? 'png' : 'jpg'
        return '<div style="break-inside: avoid; display:flex; padding-bottom:10px;margin-bottom:10px; border-bottom:solid 1px #999;"><div style="align-self:start; margin-right:10px;"><h4 style="margin-top:0px;">' + (ci.ordinal + 1) + ')</h4></div><div style="margin-right:10px"><img style="min-width:200px;max-width:200px; max-height:140px;" src="https://course.base.education/img/' + ((isFileRef) ? img : img + '.' + fileExt) + '" /></div><div>' + titles + bodies + notes + '</div></div>'
      }).join(' ')
      content += '</body>'

      newWin.document.write(content)
      newWin.onload = () => {
        newWin.print()
        this.setState({ ...this.state, printCourseId: null })
      }
      newWin.document.close()
    }

    const oldCoursesById = get(getCoursesState, 'value', {}) || {}
    const coursesById = {
      ...oldCoursesById,
      // ...keyBy(newCourses, 'id'),
    }
    const courseTitlesById = {}
    const courseTypesById = {}
    Object.keys(coursesById).forEach((courseId) => {
      courseTitlesById[courseId] = get(coursesById, `[${courseId}].titles`) || get(coursesById, `[${courseId}].title.en`)
      courseTypesById[courseId] = get(coursesById, `[${courseId}].type`)
    })
    const onSearchChange = async (search, skip, limit, excludedIds) => {
      this.setState({ ...this.state, isSearching: true })
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/studentSearch`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          search,
          orgId: organization.id,
          excludedIds,
          skip,
          limit,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
      }
      this.setState({
        ...this.state,
        isSearching: false,
        searchResults: (err) ? [] : result.map((res) => ({ ...res, className: (res.belongsToARoom) ? 'has-room' : '' })),
      })
    }

    const validateUserName = async (userName) => {
      if (!userName) {
        return {
          message: 'forms.error_required',
        }
      }
      if (userName.length < 3) {
        return {
          message: 'forms.error_min_length',
          count: 3,
        }
      }
      if (userName.length > 1000) {
        return {
          message: 'forms.error_max_length',
          count: 1000,
        }
      }
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/userNameAvailability`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
        return {
          message: err.message,
        }
      }
      if (result.isAvalable) {
        return
      }
      return {
        message: (!!result.suggestions && !!result.suggestions.length) ? 'forms.error_not_available_with_suggestions' : 'forms.error_not_available',
        field: 'user name',
        suggestions: (!!result.suggestions && !!result.suggestions.length) ? result.suggestions.join(', ') : '',
      }
    }
    const onValidateUserName = pDebounce(validateUserName, 300)

    const onCreateStudent = async (student) => {
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/clients`
      const method = HTTP_METHODS.POST
      const [ err, id ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: homeOrganization.id,
          isArchived: false,
          ssoOverride: false,
          secret: student.password,
          graduationYear: student.graduationYear,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error creating student', err)
        throw err
      }

      const profileUrl = `/clients/${id}/profiles`
      const [ profileErr ] = await to(fetch(profileUrl, {
        method,
        headers,
        data: {
          firstName: student.firstName,
          lastName: student.lastName,
        },
      }))
      isUnauthorizedError(profileErr) && logout({ userInitiated: false })
      if (profileErr) {
        console.error('Error creating student profile', profileErr)
        throw profileErr
      }

      this.setState({
        ...this.state,
        searchResults: [ {
          id,
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: homeOrganization.id,
          isArchived: false,
          ssoOverride: false,
          profile: {
            firstName: student.firstName,
            lastName: student.lastName,
          },
          className: 'new-student',
        } ],
      })
    }
    const orgChildrenById = getAllChildrenById(homeOrganization.children)
    const orgNamesById = { [homeOrganization.id]: homeOrganization.name }
    Object.keys(orgChildrenById).forEach((id) => {
      orgNamesById[id] = orgChildrenById[id].name
    })

    return (
      <Query
        variables={{
          orgId: organization.id,
        }}
        query={GET_STAFF_BY_ORG_ID}
      >
        {({
          loading,
          data,
          refetch: refetchStaff,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const staff = get(data, 'staffByOrgId') || []
          const staffById = keyBy(staff, 'id')

          return (
            <Query
              variables={{
                id: roomId,
              }}
              query={GET_ROOM_BY_ID}
              fetchPolicy='network-only'
            >
              {({
                loading: roomLoading,
                data: roomData,
                refetch,
              }) => {
                if (roomLoading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const room = get(roomData, 'room')
                if (!room) {
                  const NotFoundErr = httperr.createHttpError(404, 'Room not Found')
                  return (
                    <FetchResultMessage
                      success={false}
                      error={new NotFoundErr()}
                      showFriendlyError={showFriendlyApiErrorMessages}
                    />
                  )
                }
                const deprecatedModulesCount = room.courseIds.filter((id) => CourseSelector.coursePacks.secondary.courseIds.includes(id)).length
                const isAllRooms = pathname.includes('/all-rooms/')
                const pathRegex = new RegExp(`/${(isAllRooms) ? 'all-' : ''}rooms/.+`)
                const sections = [
                  {
                    key: (!isAllRooms) ? 'Home' : 'All Rooms', content: (!isAllRooms) ? 'Home' : 'All Rooms', link: true, onClick: () => this.navigateTo(pathname.replace(pathRegex, (!isAllRooms) ? '/rooms' : '/all-rooms')),
                  },
                  {
                    key: room.id, content: room.name, active: true,
                  },
                ]

                const studentData = room.students.map((client) => {
                  let completedCourseIds = []
                  let activeCourses = []
                  let lastActiveDate
                  if (client.enrollmentsMetadata) {
                    const filteredEnrollmentsData = filter(client.enrollmentsMetadata, (e) => !!e.lastActiveDate && ((room.isPrivate && e.attemptNumber === `room_${room.id}`) || (!room.isPrivate && !!(e.attemptNumber * 1))))
                    const sortedEnrollmentsData = orderBy(filteredEnrollmentsData, [ 'lastActiveDate' ], [ 'desc' ])
                    activeCourses = sortedEnrollmentsData.map((enrollmentData) => ({
                      courseId: enrollmentData.courseId,
                      title: get(coursesById, `${enrollmentData.courseId}.titles`, '') || '',
                      lastActiveDate: new Date(enrollmentData.lastActiveDate),
                      progress: (enrollmentData.isComplete) ? 100 : Math.floor(100 * (enrollmentData.highestCompletedOrdinal + 1) / get(coursesById, `${enrollmentData.courseId}.contentItems.length`, 1)),
                    }))
                    completedCourseIds = map(filter(sortedEnrollmentsData, 'isComplete'), 'courseId')
                    lastActiveDate = (activeCourses.length) ? activeCourses[0].lastActiveDate : null
                  }
                  return {
                    ...client,
                    roleName: find(ROLES, { type: client.roleType || 'student' }).name,
                    activeCourses,
                    lastActiveDate,
                    completedCourseIds,
                    roomCourseIds: room.courseIds,
                    orgName: get(orgNamesById, client.orgId, '') || '',
                  }
                })
                const inactiveStudents = orderBy(reject(studentData, 'lastActiveDate'), [ 'userName' ], [ 'asc' ])
                const activeStudents = orderBy(filter(studentData, 'lastActiveDate'), [ 'lastActiveDate', 'userName' ], [ 'desc', 'asc' ])
                const filteredStudentData = activeStudents.concat(inactiveStudents).filter(matchesSearchText.bind(null, this.state.filter))
                const lastActive = activeStudents[0]
                const lastActiveDate = (lastActive && lastActive.lastActiveDate) ? lastActive.lastActiveDate : null
                const roomCourses = compact(room.courseIds.map((id) => coursesById[id]))
                const filteredCourses = roomCourses.filter((c) => {
                  const title = c.titles || c.title.en
                  return title.toLowerCase().includes(this.state.filter.toLowerCase())
                })
                const sortedCourses = sortBy(filteredCourses, (c) => c.titles || c.title.en)
                const user = staffById[room.ownerUserId]
                const displayName = getDisplayName(user)
                const launchedVideoCourse = (launchCourseId && isVideo) ? roomCourses.find((course) => course.id === launchCourseId) : null
                const tzOffset = new Date().toString().split('GMT')[1].split(' ')[0]

                const actvityByRole = {
                  student: {
                    activity: room.activity,
                  },
                }
                const usageByRoleType = {
                  student: {
                    usage: room.usage.reduce((accum, courseUsage) => {
                      return {
                        ...accum,
                        [courseUsage.courseId]: courseUsage.data,
                      }
                    }, {}),
                  },
                }

                return (
                  <Mutation
                    mutation={UPDATE_ROOM}
                    awaitRefetchQueries={true}
                    onCompleted={({ updateRoom }) => {
                      this.setState({
                        ...this.state,
                        showEditRoomModal: false,
                        showAddCoursesModal: false,
                        showAddStudentsModal: false,
                      })
                    }}
                  >
                    {(updateRoom, { loading: isSaving }) => {
                      return (
                        <Mutation
                          mutation={DELETE_ROOM}
                          awaitRefetchQueries={true}
                          onCompleted={({ deleteRoom }) => {
                            this.navigateTo(this.props.location.pathname.replace(/(\/|\/all-)rooms\/.+/, '$1rooms'))
                          }}
                        >
                          {(deleteRoom, { loading: isDeleting }) => {
                            return (
                              <>
                                <Modal
                                  closeIcon
                                  open={!!launchCourseId && !!launchedVideoCourse}
                                  onClose={() => {
                                    this.setState({
                                      ...this.state, launchCourseId: null, putActivityCourseId: null,
                                    })
                                  }}
                                >
                                  <Header style={{ flexGrow: 1, margin: 0 }} data-public icon={{ name: 'video', className: 'base-teal' }} content={(launchedVideoCourse) ? get(launchedVideoCourse, 'title.en', launchedVideoCourse.titles) : 'Loading...'} />
                                  <Modal.Content onContextMenu={(e) => e.preventDefault()} className='base-video'>
                                    <Mutation
                                      mutation={PUT_ACTIVITY}
                                      variables={{
                                        activity: {
                                          clientId: userId,
                                          roleType: roleType,
                                          orgId: organization.id,
                                          courseType: 'video',
                                          courseId: launchCourseId,
                                          launches: 1,
                                        },
                                        timeZoneOffset: tzOffset,
                                      }}

                                    >
                                      {(putActivity) => {
                                        return (
                                          <Player
                                            ref={(player) => { this.player = player }}
                                            playsInline
                                            poster={(launchedVideoCourse) ? launchedVideoCourse.imageUrl : ''}
                                            src={(launchedVideoCourse) ? launchedVideoCourse.videoUrl[this.state.subtitleLanguage || 'en'] : ''}
                                            preload='auto'
                                            onTimeUpdate={async () => {
                                              if (this.state.putActivityCourseId !== launchCourseId) {
                                                const timeRange = this.player.video.props.player.played
                                                if (timeRange.length && timeRange.end(timeRange.length - 1) > timeRange.start(timeRange.length - 1) + 10) { // must play 10 continuous seconds to log
                                                  putActivity()
                                                  this.setState({ ...this.state, putActivityCourseId: launchCourseId })
                                                }
                                              }
                                            }}
                                          />
                                        )
                                      }}
                                    </Mutation>
                                    <div>
                                      {'Subtitles:'}
                                      <Dropdown
                                        scrolling
                                        selection
                                        defaultValue={'en'}
                                        value={this.state.subtitleLanguage}
                                        style={{ margin: 10 }}
                                        onChange={(e, data) => {
                                          this.setState({ ...this.state, subtitleLanguage: data.value })
                                          this.forceUpdate()
                                        }}
                                        options={dropdownLanguageOptions.filter((langOpt) => !!get(launchedVideoCourse, `videoUrl[${langOpt.value}]`))}
                                      />
                                    </div>
                                  </Modal.Content>
                                </Modal>
                                <CourseModal
                                  accessToken={accessToken}
                                  refreshToken={refreshToken}
                                  open={!!launchCourseId && !launchedVideoCourse}
                                  onClose={() => this.setState({
                                    ...this.state, launchCourseId: null,
                                  })}
                                  id={launchCourseId}
                                />
                                <RoomDetailsModal
                                  open={!!showEditRoomModal}
                                  staff={staff}
                                  onClose={() => this.setState({
                                    ...this.state, showEditRoomModal: false,
                                  })}
                                  onSave={(details) => {
                                    updateRoom({
                                      variables: {
                                        room: {
                                          id: room.id,
                                          ...details,
                                        },
                                      },
                                    }).then(() => refetch())
                                  }}
                                  isSaving={isSaving}
                                  room={room}
                                  userRole={roleType}
                                  enablePrivateRooms={organization.privateRoomsEnabled}
                                />
                                <RoomCoursesModal
                                  open={!!showAddCoursesModal}
                                  onClose={() => this.setState({
                                    ...this.state, showAddCoursesModal: false,
                                  })}
                                  onSave={(courseIds) => {
                                    updateRoom({
                                      variables: {
                                        room: {
                                          id: room.id,
                                          courseIds,
                                        },
                                      },
                                    }).then(() => refetch())
                                  }}
                                  isSaving={isSaving}
                                  courses={roomCourses}
                                  availableCourses={Object.values(coursesById).filter((course) => organization.courseIds.includes(course.id))}
                                />
                                <RoomStudentsModal
                                  open={!!showAddStudentsModal}
                                  onClose={() => this.setState({
                                    ...this.state, showAddStudentsModal: false,
                                  })}
                                  onSave={(studentIds) => {
                                    updateRoom({
                                      variables: {
                                        room: {
                                          id: room.id,
                                          studentIds,
                                        },
                                      },
                                    }).then(() => refetch())
                                  }}
                                  orgNamesById={orgNamesById}
                                  students={room.students}
                                  isSaving={isSaving}
                                  isSearching={isSearching}
                                  searchResults={searchResults}
                                  onSearchChange={onSearchChange}
                                  onCreateStudent={onCreateStudent}
                                  validateUserName={onValidateUserName}
                                  canCreateStudents={roleType !== 'advisor'}
                                />
                                <Segment basic loading={isDeleting || isSaving} className='room-details'>
                                  <Breadcrumb icon='right angle' sections={sections} />
                                  <div style={{
                                    marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center',
                                  }}>
                                    <Header as='h2' style={{
                                      flexGrow: 2, margin: 0, position: 'relative',
                                    }}>
                                      <Image src={room.imageUrl} />
                                      <Header.Content>
                                        {room.name}
                                        <Header.Subheader data-public><Icon style={{ marginRight: 8 }} name='calendar' /> {(lastActiveDate) ? `Last Active: ${moment(lastActiveDate).fromNow()} (${moment(lastActiveDate).format('M/D/YYYY @ h:mm A')})` : 'No Activity'}</Header.Subheader>
                                        <Header.Subheader data-public><Icon className='fas fa-chalkboard-teacher' style={{ float: 'left', marginTop: 6 }} /> {displayName}</Header.Subheader>
                                      </Header.Content>
                                      {(room.isPrivate) && (
                                        <Popup
                                          hoverable
                                          content='Private Room'
                                          trigger={(
                                            <Label
                                              circular
                                              className='private-label base-red-bg'
                                              style={{
                                                position: 'absolute',
                                                border: 'white 2px solid',
                                                top: 14,
                                                left: 0,
                                              }}
                                            >
                                              <Icon
                                                name='lock'
                                                style={{ margin: '0 1px', color: 'white' }}
                                              />
                                            </Label>
                                          )}
                                        />
                                      )}

                                    </Header>
                                    <Button
                                      style={{ flexShrink: 2 }}
                                      disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                      color='blue'
                                      icon='edit'
                                      title='Update this room'
                                      onClick={() => {
                                        refetchStaff()
                                        this.setState({
                                          ...this.state, showEditRoomModal: true,
                                        })
                                      }}
                                    />
                                    <Button
                                      style={{ flexShrink: 2 }}
                                      disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                      color='red'
                                      icon='remove'
                                      title='Delete this room'
                                      loading={isDeleting}
                                      onClick={() => {
                                        if (!window.confirm(`Are you sure you want to delete this room?`)) {
                                          return
                                        }
                                        deleteRoom({
                                          variables: { id: room.id },
                                        })
                                      }}
                                    />
                                  </div>
                                  <Tab
                                    menu={{ secondary: true, size: 'huge' }}
                                    renderActiveOnly
                                    activeIndex={activeTabIndex}
                                    onTabChange={(e, data) => {
                                      this.setActiveTabIndex(data.activeIndex)
                                    }}
                                    panes={[
                                      {
                                        menuItem: (
                                          <Menu.Item data-public key='students'>
                                            <Icon className='base-teal' name='users' /> Students<Label size='mini' circular className='base-teal-bg'>{get(room, 'studentIds.length', 0)}</Label>
                                          </Menu.Item>
                                        ),
                                        render: () => {
                                          return (
                                            <Tab.Pane as='div'>
                                              {(!!room && !!room.requestedStudentIds.length && (room.ownerUserId === userId || EDIT_ROLES.includes(roleType))) && (
                                                <Mutation
                                                  mutation={PROCESS_STUDENT_REQUESTS}
                                                  awaitRefetchQueries={true}
                                                >
                                                  {(processStudentRequests, { loading: isProcessing }) => {
                                                    return (
                                                      <Message info>
                                                        <Message.Header data-public>
                                                          Add Students
                                                        </Message.Header>
                                                        <p data-public>
                                                          The students below have requested to join your room. Adding students to your room will allow them to see what module recommendations you have made, and will allow you to easily monitor their progress.
                                                        </p>
                                                        <p data-public>
                                                          Click the Add button to add them to your room. You may also deny them access to your room if you do not know who they are or think it was a mistake.
                                                        </p>
                                                        <Divider horizontal>
                                                          <Header as='h4' data-public>
                                                            <Icon name='user add' />
                                                            Student Requests ({room.requestedStudentIds.length})
                                                          </Header>
                                                        </Divider>
                                                        <div style={{ float: 'right' }}>
                                                          <Button
                                                            loading={isProcessing}
                                                            disabled={isProcessing}
                                                            color='red'
                                                            icon
                                                            size='mini'
                                                            data-public
                                                            onClick={() => {
                                                              processStudentRequests({
                                                                variables: {
                                                                  id: room.id,
                                                                  studentIds: room.requestedStudentIds,
                                                                  approve: false,
                                                                },
                                                              })
                                                            }}
                                                          >
                                                            <Icon name='remove' /> Deny All
                                                          </Button>
                                                          <Button
                                                            loading={isProcessing}
                                                            disabled={isProcessing}
                                                            color='green'
                                                            icon
                                                            size='mini'
                                                            data-public
                                                            onClick={() => {
                                                              processStudentRequests({
                                                                variables: {
                                                                  id: room.id,
                                                                  studentIds: room.requestedStudentIds,
                                                                  approve: true,
                                                                },
                                                              }).then(() => refetch())
                                                            }}
                                                          >
                                                            <Icon name='plus' /> Add All
                                                          </Button>
                                                        </div>
                                                        <br style={{ clear: 'both' }} />
                                                        <List className='requested-students-list' verticalAlign='middle'>
                                                          {room.requestedStudents.slice(0, 5).map((student) => {
                                                            const displayName = getDisplayName(student)
                                                            return (
                                                              <List.Item key={`${room.id}-${student.id}`}>
                                                                <List.Content style={{ display: 'flex', alignItems: 'center' }}>
                                                                  <Icon name='user' />
                                                                  <span>{displayName}</span>
                                                                  <Button
                                                                    loading={isProcessing}
                                                                    disabled={isProcessing}
                                                                    color='red'
                                                                    icon
                                                                    size='mini'
                                                                    data-public
                                                                    onClick={() => {
                                                                      processStudentRequests({
                                                                        variables: {
                                                                          id: room.id,
                                                                          studentIds: [ student.id ],
                                                                          approve: false,
                                                                        },
                                                                      }).then(() => refetch())
                                                                    }}
                                                                  >
                                                                    <Icon name='remove' /> Deny
                                                                  </Button>
                                                                  <Button
                                                                    loading={isProcessing}
                                                                    disabled={isProcessing}
                                                                    color='green'
                                                                    icon
                                                                    size='mini'
                                                                    data-public
                                                                    onClick={() => {
                                                                      processStudentRequests({
                                                                        variables: {
                                                                          id: room.id,
                                                                          studentIds: [ student.id ],
                                                                          approve: true,
                                                                        },
                                                                      }).then(() => refetch())
                                                                    }}
                                                                  >
                                                                    <Icon name='plus' /> Add
                                                                  </Button>
                                                                </List.Content>
                                                              </List.Item>
                                                            )
                                                          })}
                                                        </List>
                                                        {(room.requestedStudents.length > 5) && (
                                                          <Header as='h5' data-public style={{ float: 'left', marginTop: 4 }}>
                                                            <Icon name='users' /> {room.requestedStudentIds.length - 5} more...
                                                          </Header>
                                                        )}

                                                      </Message>
                                                    )
                                                  }}
                                                </Mutation>
                                              )}
                                              {(!room || !room.studentIds.length) && (
                                                <Segment placeholder>
                                                  <Header data-public icon>
                                                    <Icon name='users' />
                                                    There are no students in this room.
                                                  </Header>
                                                  <Button
                                                    data-public
                                                    disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                                    className='text white base-green-bg'
                                                    onClick={() => this.setState({ showAddStudentsModal: true })}
                                                  >
                                                    Add Some Students
                                                  </Button>
                                                </Segment>
                                              )}
                                              {(!!room && !!room.studentIds.length) && (
                                                <>
                                                  <div style={{
                                                    display: 'flex', alignItems: 'center',
                                                  }}>
                                                    <SearchInput
                                                      style={{ flexGrow: 2, marginRight: 10 }}
                                                      basic
                                                      fluid
                                                      autoFocus
                                                      color='grey'
                                                      initialValue=''
                                                      submitOnChange={true}
                                                      onSubmit={this.setTextFilter}
                                                    />
                                                    <Button
                                                      data-public
                                                      disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                                      style={{ flexShrink: 2 }}
                                                      color='blue'
                                                      onClick={() => this.setState({ showAddStudentsModal: true })}
                                                    >
                                                      Add/Remove Students
                                                    </Button>
                                                  </div>
                                                  {(!filteredStudentData.length) && (
                                                    <Segment placeholder>
                                                      <Header data-public icon>
                                                        <Icon name='users' />
                                                        No students found
                                                      </Header>
                                                    </Segment>
                                                  )}
                                                  {(!!filteredStudentData.length) && (
                                                    <Pager
                                                      itemsPerPage={PAGE_SIZE}
                                                      items={filteredStudentData}
                                                      onPageChange={this.setPageNumber}
                                                      activePage={activePageNumber}
                                                      basic={true}
                                                      render={(items) => {
                                                        return (
                                                          <Table padded selectable stackable={false}>
                                                            <Table.Header>
                                                              <Table.Row>
                                                                <Table.HeaderCell data-public>User</Table.HeaderCell>
                                                                <Table.HeaderCell data-public>Last Active</Table.HeaderCell>
                                                                <Table.HeaderCell data-public>Progress</Table.HeaderCell>
                                                                {(organization.firewordsEnabled) && (<Table.HeaderCell collapsing textAlign='center' data-public>Unreviewed Firewords</Table.HeaderCell>)}
                                                              </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                              {items.map(this.renderClientItem)}
                                                            </Table.Body>
                                                          </Table>
                                                        )
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )}

                                            </Tab.Pane>
                                          )
                                        },
                                      },
                                      {
                                        menuItem: (
                                          <Menu.Item data-public key='courses'>
                                            <Icon className={(deprecatedModulesCount) ? 'base-red fas fa-chalkboard' : 'base-teal fas fa-chalkboard'} /> <span className={(deprecatedModulesCount) ? 'base-red' : ''}>Recommended Modules</span><Label size='mini' circular className='base-teal-bg'>{get(room, 'courseIds.length', 0)}</Label>
                                          </Menu.Item>
                                        ),
                                        render: () => {
                                          return (
                                            <Tab.Pane as='div'>
                                              {(!room || !room.courseIds.length) && (
                                                <Segment placeholder>
                                                  <Header data-public icon>
                                                    <Icon className='fas fa-chalkboard' />
                                                    There are no recommended modules for this room.
                                                  </Header>
                                                  <Button
                                                    data-public
                                                    disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                                    className='text white base-green-bg'
                                                    onClick={() => this.setState({ showAddCoursesModal: true })}
                                                  >
                                                    Add Modules
                                                  </Button>
                                                </Segment>
                                              )}
                                              {(!!room && !!room.hiddenCourseIds && !!room.hiddenCourseIds.length) && (
                                                <Popup
                                                  hoverable
                                                  position='right center'
                                                  header='Hidden Modules'
                                                  content={(
                                                    <>
                                                      <p data-public>Students will still have access to modules that you have hidden, but they will not show up in their recommended modules.</p>
                                                      <p data-public>This is useful if you want to be able to view student responses from modules that have been completed, but you want to remove them from their recommended module list.</p>
                                                    </>
                                                  )}
                                                  trigger={(
                                                    <div data-public className='pointer text bold blue inline-block' style={{ marginBottom: 10 }}><Icon name='info circle'/> A note about hidden modules</div>
                                                  )}
                                                />
                                              )}
                                              {(!!room && !!room.courseIds.length) && (
                                                <>
                                                  <div style={{
                                                    display: 'flex', alignItems: 'center',
                                                  }}>
                                                    <SearchInput
                                                      style={{ flexGrow: 2, marginRight: 10 }}
                                                      basic
                                                      fluid
                                                      autoFocus
                                                      color='grey'
                                                      initialValue=''
                                                      submitOnChange={true}
                                                      onSubmit={this.setTextFilter}
                                                    />
                                                    <Button
                                                      data-public
                                                      disabled={(room.ownerUserId !== userId && !EDIT_ROLES.includes(roleType))}
                                                      style={{ flexShrink: 2 }}
                                                      color='blue'
                                                      onClick={() => this.setState({ showAddCoursesModal: true })}
                                                    >
                                                      Add/Remove Modules
                                                    </Button>
                                                  </div>
                                                  <Pager
                                                    itemsPerPage={24}
                                                    items={sortedCourses}
                                                    onPageChange={this.setPageNumber}
                                                    activePage={activePageNumber}
                                                    basic={true}
                                                    render={(items) => {
                                                      return (
                                                        <Card.Group style={{ marginTop: 10 }} centered={true}>
                                                          {items.map(this.renderCourse.bind(this, room))}
                                                        </Card.Group>
                                                      )
                                                    }}
                                                  />
                                                </>
                                              )}
                                            </Tab.Pane>
                                          )
                                        },
                                      },
                                      {
                                        menuItem: (
                                          <Menu.Item data-public key='activity'>
                                            <Icon className='base-teal' name='line chart' /> Room Activity
                                          </Menu.Item>
                                        ),
                                        render: () => {
                                          return (
                                            <Tab.Pane as='div'>
                                              <UserActivityChart
                                                rolesWithActivityByRoleType={actvityByRole}
                                                hideRoleSelection={true}
                                                forRoom={true}
                                                height='300px'
                                              />
                                              <CourseUsageChart
                                                rolesWithUsageByRoleType={usageByRoleType}
                                                courseTitlesById={courseTitlesById}
                                                courseTypesById={courseTypesById}
                                                hideRoleSelection={true}
                                                forRoom={true}
                                              />
                                            </Tab.Pane>
                                          )
                                        },
                                      },
                                    ]}
                                  />

                                </Segment>
                              </>
                            )
                          }}
                        </Mutation>
                      )
                    }}
                  </Mutation>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    contentItems: {
      [FETCH_ACTIONS.GET_ALL]: getContentItemsState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getCoursesState,
    getContentItemsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  logout,
  getAllContentItems,
}
const ClientRoomDetailsViewContainer = connect(mapStateToProps, mapDispatchToProps)(RoomDetailsView)

export default translate([ 'components' ])(ClientRoomDetailsViewContainer)
