import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'

import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
import FirePhraseForm from '../../forms/fire-phrase/fire-phrase'
import {
  createFirePhrase,
  updateFirePhrase,
  getAllFirePhrases,
  acknowledgeCreateFirePhrase,
  acknowledgeUpdateFirePhrase,
} from '../../../actions/fire-phrases'
import isStale from '../../../helpers/is-stale'
import FetchResultMessage from '../../common/fetch-result-message/fetch-result-message'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'

const CREATE_FIRE_PRHASE_PATH = '/firePhrases/new'

export class SaveFirePhraseView extends Component {
  static propTypes = {
    accessToken: PropTypes.string,
    postFirePhrases: PropTypes.object.isRequired,
    putFirePhrases: PropTypes.object.isRequired,
    getFirePhrases: PropTypes.object.isRequired,
    createFirePhrase: PropTypes.func.isRequired,
    updateFirePhrase: PropTypes.func.isRequired,
    getAllFirePhrases: PropTypes.func.isRequired,
    acknowledgeCreateFirePhrase: PropTypes.func.isRequired,
    acknowledgeUpdateFirePhrase: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static path = {
    new: '/firePhrases/new',
    existing: '/firePhrases/:id',
  }

  getAllFirePhrasesIfNeeded = () => {
    const {
      accessToken,
      getFirePhrases,
      getAllFirePhrases,
    } = this.props

    if (isStale(getFirePhrases)) {
      getAllFirePhrases({ accessToken })
    }
  }

  handleSave = (data) => {
    const {
      updateFirePhrase,
      createFirePhrase,
      accessToken,
      match,
    } = this.props
    const firePhrase = {
      category: data.category,
      phrases: {
        en: data.phrases,
        es: data.phrases,
      },
      keyWords: {
        en: data.keyWords,
        es: data.keyWords,
      },
    }
    if (!this.isNew()) {
      firePhrase.id = match.params.id
      updateFirePhrase({ accessToken, data: firePhrase })
    } else {
      createFirePhrase({ accessToken, data: firePhrase })
    }
  }

  dismissMessage = () => {
    const {
      acknowledgeCreateFirePhrase,
      acknowledgeUpdateFirePhrase,
    } = this.props
    if (this.isNew()) {
      acknowledgeCreateFirePhrase()
    } else {
      acknowledgeUpdateFirePhrase()
    }
  }

  isNew = () => (this.props.location.pathname === CREATE_FIRE_PRHASE_PATH)

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.getAllFirePhrasesIfNeeded()
  }

  componentWillUnmount () {
    this.dismissMessage()
  }

  render () {
    const {
      getFirePhrases,
      putFirePhrases: {
        succeeded: putSucceeded,
        isLoading: putIsLoading,
        error: putError,
      },
      postFirePhrases: {
        succeeded: postSucceeded,
        isLoading: postIsLoading,
        error: postError,
      },
      showFriendlyApiErrorMessages,
      match,
    } = this.props
    const headerPrefix = (this.isNew()) ? 'Create New' : 'Update'
    const currentFirePhrase = (this.isNew() || !getFirePhrases.value) ? null : getFirePhrases.value[match.params.id]
    // TODO: translate
    return (
      <Container>
        <FullScreenLoadingOverlay isActive={getFirePhrases.isLoading}/>
        <h2>{headerPrefix} Fire Phrase</h2>
        <FetchResultMessage
          itemType='fire phrase'
          success={(this.isNew()) ? postSucceeded : putSucceeded}
          error={(this.isNew()) ? postError : putError}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}/>
        <FirePhraseForm
          onSave={this.handleSave}
          isLoading={putIsLoading || postIsLoading}
          keyWords={(currentFirePhrase) ? currentFirePhrase.keyWords.en : undefined}
          phrases={(currentFirePhrase) ? currentFirePhrase.phrases.en : undefined}
          category={(currentFirePhrase) ? currentFirePhrase.category : undefined} />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    firePhrases: {
      [FETCH_ACTIONS.POST]: postFirePhrases,
      [FETCH_ACTIONS.PUT]: putFirePhrases,
      [FETCH_ACTIONS.GET_ALL]: getFirePhrases,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    postFirePhrases,
    putFirePhrases,
    getFirePhrases,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  createFirePhrase,
  updateFirePhrase,
  getAllFirePhrases,
  acknowledgeCreateFirePhrase,
  acknowledgeUpdateFirePhrase,
}
const SaveFirePhraseViewContainer = connect(mapStateToProps, mapDispatchToProps)(SaveFirePhraseView)

export default SaveFirePhraseViewContainer
