import React from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Grid,
  Button,
  Icon,
  Progress,
  Input,
  Dropdown,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  isNil,
  isArray,
  isString,
  find,
} from 'lodash'

import RadioGroup from '../radio-group/radio-group'
import CheckboxGroup from '../checkbox-group/checkbox-group'
import { RTL_LANGUAGE_CODES } from '../../../helpers/languages'
import classNames from '../../../helpers/class-names'

const TRANSLATION_PREFIX = 'common.assessment-card'
const noop = () => 0

class AssessmentCard extends React.Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    type: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
    })),
    questionNumber: PropTypes.number,
    questionText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    hasNext: PropTypes.bool,
    progress: PropTypes.number,
    isComplete: PropTypes.bool,
    t: PropTypes.func.isRequired,
    answer: PropTypes.any,
    onBackToCourse: PropTypes.func,
    onBack: PropTypes.func,
    i18n: PropTypes.object.isRequired,
  }

  static defaultProps = {
    color: 'blue',
    type: 'radio',
    onSubmit: console.log.bind(console, 'onSubmit'),
    isLoading: false,
    isSaving: false,
    hasNext: false,
    progress: 0,
    isComplete: false,
    choices: [],
    questionNumber: 0,
    questionText: '',
    onBackToCourse: console.log.bind(console, 'onBackToCourse'),
    onBack: console.log.bind(console, 'onBack'),
    i18n: { language: 'en' },
  }

  constructor (props) {
    super(props)
    this.state = { answer: props.answer }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.answer !== this.props.answer || prevProps.questionNumber !== this.props.questionNumber) {
      this.setState({ answer: this.props.answer })
    }
  }

  onChoiceChange = (value) => {
    this.setState({ answer: value })
  }

  onNext = () => {
    const { choices, type } = this.props
    if (type.startsWith('number')) {
      this.props.onSubmit({ value: this.state.answer.toString(), type: 'number' })
      return
    }
    const selectedChoice = (type === 'checkbox') ? { type: find(choices, { value: this.state.answer[0] }).type, value: this.state.answer.join(',') } : find(choices, { value: this.state.answer })
    this.props.onSubmit({ value: selectedChoice.value, type: selectedChoice.type })
  }

  renderRadioChoices = (options) => (
    <RadioGroup
      vertical
      disabled={this.props.isSaving}
      onChange={this.onChoiceChange}
      selectedValue={this.state.answer}
      options={options}
      onBlur={noop}
      onFocus={noop}
    />
  )

  renderCheckboxChoices = (options) => (
    <CheckboxGroup
      vertical
      disabled={this.props.isSaving}
      onChange={this.onChoiceChange}
      selectedValues={(isArray(this.state.answer)) ? this.state.answer : []}
      options={options}
      onBlur={noop}
      onFocus={noop}
    />
  )

  renderNumberInput = ([ min, max, step ]) => {
    const numDecimalPlaces = (step.includes('.')) ? step.replace(/^.*\./, '').length : 0
    return (
      <Input
        type='number'
        min={min * 1}
        max={max * 1}
        step={step * 1}
        value={(isNil(this.state.answer)) ? '' : this.state.answer.toString()}
        onChange={(e, props) => {
          const valueNum = props.value * 1
          const boundValue = Math.min(max * 1, Math.max(min * 1, valueNum * 1)).toFixed(numDecimalPlaces) * 1
          this.onChoiceChange(boundValue)
        }}
      />
    )
  }

  generateNumberOptions = (min, max, step) => {
    const numDecimalPlaces = (step.toString().includes('.')) ? step.toString().replace(/^.*\./, '').length : 0
    const numberOptions = []
    let current = min
    while (current <= max) {
      numberOptions.push({ text: current.toString(), value: current })
      current = (current + step).toFixed(numDecimalPlaces) * 1
    }
    return numberOptions
  }

  renderNumberDropdownInput = ([ min, max, step ]) => {
    const numDecimalPlaces = (step.includes('.')) ? step.replace(/^.*\./, '').length : 0
    return (
      <Dropdown
        search={(options, val) => {
          return options.filter((opt) => (val.startsWith('.')) ? opt.text.startsWith(`0${val}`) : opt.text.startsWith(val))
        }}
        selection
        options={this.generateNumberOptions(min * 1, max * 1, step * 1)}
        name='number-options'
        value={(isNil(this.state.answer)) ? null : this.state.answer * 1}
        onChange={(e, props) => {
          const valueNum = props.value * 1
          const boundValue = Math.min(max * 1, Math.max(min * 1, valueNum * 1)).toFixed(numDecimalPlaces) * 1
          this.onChoiceChange(boundValue)
        }}
      />
    )
  }

  render () {
    const {
      color,
      choices,
      questionNumber,
      questionText,
      type,
      isLoading,
      isSaving,
      hasNext,
      progress,
      isComplete,
      onBackToCourse,
      onBack,
      t,
      i18n,
    } = this.props
    const {
      answer,
    } = this.state
    const rtl = RTL_LANGUAGE_CODES.includes(i18n.language)
    return (
      <Segment.Group>
        <Segment placeholder={isComplete}>
          {isComplete && (
            <React.Fragment>
              <Header icon data-public>
                <Icon name='flag checkered' />
                {t(`${TRANSLATION_PREFIX}.complete_message`)}
              </Header>
              <Button
                icon
                color='blue'
                labelPosition='right'
                onClick={() => onBackToCourse()}
                data-public
              >
                {t(`${TRANSLATION_PREFIX}.back_to_course`)}
                <Icon name='arrow right' />
              </Button>
            </React.Fragment>
          )}
          <Progress percent={progress} indicating attached='top'/>
          {(!isLoading && !isComplete) && (
            <Grid className={classNames({ rtl })}>
              <Grid.Column style={{ paddingRight: 2 }} width={1} textAlign={(rtl) ? 'left' : 'right'}>
                <Header data-public>{questionNumber})</Header>
              </Grid.Column>
              <Grid.Column width={15}>
                <Header className={classNames({ rtl })} data-public>{questionText} {(type === 'checkbox') && ` (${t(`${TRANSLATION_PREFIX}.all_that_apply`)})`}</Header>
                {(type === 'radio') && this.renderRadioChoices(choices)}
                {(type === 'checkbox') && this.renderCheckboxChoices(choices)}
                {/* {(type.startsWith('number|')) && this.renderNumberInput(type.replace('number|', '').split('|'))} */}
                {(type.startsWith('number|')) && this.renderNumberDropdownInput(type.replace('number|', '').split('|'))}
              </Grid.Column>
            </Grid>
          )}
        </Segment>
        {(!isComplete) && (
          <Segment>
            {(questionNumber !== 1) && (
              <Button
                icon
                labelPosition='left'
                loading={isSaving}
                disabled={isSaving || isComplete}
                onClick={onBack}
                data-public
                className={classNames({ rtl })}
              >
                {t(`${TRANSLATION_PREFIX}.back`)}
                <Icon name='left arrow' />
              </Button>
            )}
            {hasNext && <Button
              icon
              color={color}
              labelPosition='right'
              loading={isSaving}
              disabled={isSaving || isNil(answer) || (isArray(answer) && !answer.length) || (isString(answer) && !answer.length)}
              onClick={this.onNext}
              data-public
              className={classNames({ rtl })}
            >
              {t(`${TRANSLATION_PREFIX}.next`)}
              <Icon name='right arrow' />
            </Button>}
            {!hasNext && <Button
              icon
              color='green'
              labelPosition='right'
              loading={isSaving}
              disabled={isSaving || isNil(answer) || (isArray(answer) && !answer.length) || (isString(answer) && !answer.length)}
              onClick={this.onNext}
              data-public
              className={classNames({ rtl })}
            >
              {t(`${TRANSLATION_PREFIX}.finish`)}
              <Icon name='check' />
            </Button>}
            <br style={{ clear: 'both' }}/>
          </Segment>
        )}
      </Segment.Group>
    )
  }
}

export default translate([ 'components' ])(AssessmentCard)
