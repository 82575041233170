import gql from 'graphql-tag'

const GET_USER_ASSESSMENTS = gql`
query getUserAssessments($userId: String!, $assessmentId: String, $first: PositiveInt, $after: ID) {
  userAssessments(
    userId: $userId
    assessmentId: $assessmentId
    first: $first
    after: $after
  ) {
    userAssessments {
      id
      attemptNumber
      schoolYear
      schoolYearAttempt
      answers {
        questionId
        value
        type
      }
      completionDate
      updated
      created
    }
    endCursor
    hasNextPage
  }
}
`

export default GET_USER_ASSESSMENTS
