import to from 'await-to-js'
import {
  isString,
  isArray,
  omit,
} from 'lodash'

import { FETCH_ACTIONS, FETCH_TYPES } from '../helpers/fetch-constants'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import fetch from '../helpers/fetch'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import setValueById from '../reducers/fetch/set-value-by-id'
import mergeValueById from '../reducers/fetch/merge-value-by-id'
import removeValueById from '../reducers/fetch/remove-value-by-id'
import { logout } from './authentication'

// TODO: make this one obj param
const callApi = (httpMethod, resourceId, path, data, options, fetchAction, storePathPrefix, actionName, skipUpdate = false, shouldMerge = false) => {
  return [
    () => async (dispatch) => {
      const storePath = `${storePathPrefix}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(resourceId), storePath, actionName ])
      // TODO: make this less-specific to Base (checking for _meta field)
      const postData = (!isArray(data) && httpMethod !== FETCH_ACTIONS.POST) ? omit(data, '_meta') : data
      const [ err, result ] = await to(fetch(path, Object.assign({ data: postData, method: httpMethod }, options)))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
      if (skipUpdate) {
        return
      }
      const updateStrategy = (shouldMerge) ? mergeValueById : setValueById
      // TODO: handle non-conforming POST responses (this assumes result is the id of the thing that was just created)
      if (fetchAction === FETCH_ACTIONS.POST && isString(result)) {
        const now = new Date().toISOString()
        const newData = {
          id: result,
          created: now,
          modified: now,
          ...data,
        }
        dispatch([ updateStrategy(newData), `${storePathPrefix}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      } else if (fetchAction === FETCH_ACTIONS.PUT) {
        const now = new Date().toISOString()
        const newData = {
          id: path.split('/').pop(),
          modified: now,
          ...data,
        }
        dispatch([ updateStrategy(newData), `${storePathPrefix}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      } else if (fetchAction === FETCH_ACTIONS.DELETE) {
        dispatch([ removeValueById(path.split('/').pop()), `${storePathPrefix}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      } else if (fetchAction === FETCH_ACTIONS.GET) {
        dispatch([ updateStrategy(result), `${storePathPrefix}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      }
    },
  ]
}

export default callApi
