import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Segment, Grid, Button, Dropdown, Header, Icon,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  filter,
  sortBy,
  flatten,
  isEqual,
  compact,
} from 'lodash'

import isStale from '../../../../helpers/is-stale'
import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import CourseEnrollmentsTable from '../../../common/course-enrollments-table'
import { getAllClientsWithOverviews, invalidateClients } from '../../../../actions/clients'
import { getAllContentItems, invalidateContentItems } from '../../../../actions/content-items'
import {
  createClientEnrollments,
  acknowledgeCreateEnrollment,
  deleteClientEnrollments,
  acknowledgeDeleteEnrollment,
  createCourseEnrollments,
  deleteCourseEnrollments,
  getCourseEnrollments,
  invalidateClientEnrollments,
} from '../../../../actions/enrollments'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import getRoles from '../../../../helpers/get-roles'
import Pager from '../../../common/pager/uncontrolled-pager'
import SearchInput from '../../../common/search-input'

const courseIdPathParam = ':courseId'
const path = `/courses/${courseIdPathParam}`
const TRANSLATION_PREFIX = 'views.organization.course-details'
const ROLES = getRoles({ externalOnly: true })
const PAGE_SIZE = 50

export const helpers = {
  // all urls under a given course
  getPath: (courseId) => `${path.replace(courseIdPathParam, courseId)}`,
}

export class CourseDetailsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    postEnrollmentsState: PropTypes.object.isRequired,
    deleteEnrollmentsState: PropTypes.object.isRequired,
    getEnrollmentsState: PropTypes.object.isRequired,
    getAllClientsWithOverviews: PropTypes.func.isRequired,
    createClientEnrollments: PropTypes.func.isRequired,
    acknowledgeCreateEnrollment: PropTypes.func.isRequired,
    deleteClientEnrollments: PropTypes.func.isRequired,
    acknowledgeDeleteEnrollment: PropTypes.func.isRequired,
    createCourseEnrollments: PropTypes.func.isRequired,
    deleteCourseEnrollments: PropTypes.func.isRequired,
    getCourseEnrollments: PropTypes.func.isRequired,
    invalidateClientEnrollments: PropTypes.func.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    onEditClick: PropTypes.func,
    searchParams: PropTypes.object,
    onSearchParamsChange: PropTypes.func.isRequired,
    getContentItemsState: PropTypes.object.isRequired,
    getAllContentItems: PropTypes.func.isRequired,
    invalidateContentItems: PropTypes.func.isRequired,
    organization: PropTypes.object,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    onEditClick: () => console.log('onEditClick'),
  }

  static path = path

  constructor (props) {
    super(props)
    this.state = {
      selectedRoleTypes: [],
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  setPageNumber = (pageNumber) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1
    onSearchParamsChange({
      ...searchParams,
      skip: ((pageNumber - 1) * limit),
      limit,
    })
    invalidateClients()
  }

  setTextFilter = (value) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1
    onSearchParamsChange({
      ...searchParams,
      skip: 0,
      limit,
      filter: value,
    })
    invalidateClients()
  }

  setFilter = (type) => (e, props) => {
    const {
      searchParams,
      onSearchParamsChange,
      invalidateClients,
    } = this.props
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1
    onSearchParamsChange({
      ...searchParams,
      skip: 0,
      limit,
      [type]: props.value,
    })
    invalidateClients()
  }

  enrollAll = () => {
    const {
      match: { params: { courseId } },
      authState: { accessToken },
      createCourseEnrollments,
      getClientsState,
      getCoursesState: { value: coursesById },
      getEnrollmentsState: { value: enrollmentsById },
      t,
    } = this.props

    const clientsById = get(getClientsState, 'value', {}) || {}
    const clients = Object.values(clientsById)
    const enrollments = Object.values(enrollmentsById || {})
    const courseTitle = get(coursesById, `${courseId}.titles`, 'UNKNOWN COURSE')
    const filteredClients = Object.values(clients).filter(this.filterUserByRoles)
    const eligibleClients = filteredClients.filter((client) => {
      const clientEnrollments = enrollments.filter((enrollment) => enrollment.clientId === client.id)
      const hasEnrollmentForCourse = clientEnrollments.some((enrollment) => enrollment.courseId === courseId)
      return !hasEnrollmentForCourse
    })
    if (!eligibleClients.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_users_to_enroll`))
      return
    }
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.enroll_all_confirm`, { courseTitle, count: eligibleClients.length }))
    if (!areYouSure) {
      return
    }

    createCourseEnrollments({
      courseId,
      clientAttempts: eligibleClients.map((client) => ({ clientId: client.id, attemptNumber: 1 })),
      accessToken,
    })
  }

  reenrollAll = () => {
    const {
      match: { params: { courseId } },
      authState: { accessToken },
      createCourseEnrollments,
      getClientsState,
      getCoursesState: { value: coursesById },
      getEnrollmentsState: { value: enrollmentsById },
      t,
    } = this.props

    const clientsById = get(getClientsState, 'value', {}) || {}
    const clients = Object.values(clientsById)
    const enrollments = Object.values(enrollmentsById || {})
    const courseTitle = get(coursesById, `${courseId}.titles`, 'UNKNOWN COURSE')
    const filteredClients = Object.values(clients).filter(this.filterUserByRoles)
    const eligibleClientAttempts = compact(filteredClients.map((client) => {
      const clientEnrollments = enrollments.filter((enrollment) => (enrollment.clientId === client.id && enrollment.courseId === courseId && !!enrollment.startedOn))
      const latestEnrollment = sortBy(clientEnrollments, 'attemptNumber').pop()
      if (latestEnrollment && !!latestEnrollment.startedOn && latestEnrollment.attemptNumber < 5) {
        return { clientId: client.id, attemptNumber: latestEnrollment.attemptNumber + 1 }
      }
      return null
    }))
    if (!eligibleClientAttempts.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_users_to_enroll`))
      return
    }
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.reenroll_all_confirm`, { courseTitle, count: eligibleClientAttempts.length }))
    if (!areYouSure) {
      return
    }
    createCourseEnrollments({
      courseId,
      clientAttempts: eligibleClientAttempts,
      accessToken,
    })
  }

  unenrollAll = () => {
    const {
      match: { params: { courseId } },
      authState: { accessToken },
      deleteCourseEnrollments,
      getClientsState,
      getCoursesState: { value: coursesById },
      getEnrollmentsState: { value: enrollmentsById },
      t,
    } = this.props

    const clientsById = get(getClientsState, 'value', {}) || {}
    const clients = Object.values(clientsById)
    const enrollments = Object.values(enrollmentsById || {})
    const courseTitle = get(coursesById, `${courseId}.titles`, 'UNKNOWN COURSE')
    const filteredClients = Object.values(clients).filter(this.filterUserByRoles)
    const enrollmentData = filteredClients.filter((client) => {
      const clientEnrollments = enrollments.filter((enrollment) => enrollment.clientId === client.id)
      const courseEnrollments = filter(clientEnrollments, { courseId })
      const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
      return (currentEnrollment && !currentEnrollment.startedOn)
    }).map(function (client) {
      const clientEnrollments = enrollments.filter((enrollment) => enrollment.clientId === client.id)
      const courseEnrollments = filter(clientEnrollments, { courseId })
      const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
      return { clientId: client.id, enrollmentId: currentEnrollment.id }
    })
    if (!enrollmentData.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_users_to_unenroll`))
      return
    }
    const reason = window.confirm(t(`${TRANSLATION_PREFIX}.unenroll_all_confirm`, { courseTitle, count: enrollmentData.length }))
    if (!reason) {
      return
    }
    deleteCourseEnrollments({
      enrollmentData,
      accessToken,
    })
  }

  handleEnroll = ({ itemId, attemptNumber }) => {
    const {
      match: { params: { courseId } },
      authState: { accessToken },
      createClientEnrollments,
    } = this.props

    createClientEnrollments({
      clientId: itemId,
      data: [ {
        courseId: courseId,
        attemptNumber,
      } ],
      accessToken,
    })
  }

  handleReenroll = ({ itemId, attemptNumber }) => {
    const {
      match: { params: { courseId } },
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props
    const userName = get(clientsById, `${itemId}.userName`, 'UNKNOWN USER')
    const courseTitle = get(coursesById, `${courseId}.titles`, 'UNKNOWN COURSE')
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.reenroll_confirm`, { userName, courseTitle }))
    if (!areYouSure) {
      return
    }
    this.handleEnroll({ itemId, attemptNumber })
  }

  handleUnenroll = ({ itemId, enrollmentId }) => {
    const {
      match: { params: { courseId } },
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      authState: { accessToken },
      deleteClientEnrollments,
      t,
    } = this.props
    const userName = get(clientsById, `${itemId}.userName`, 'UNKNOWN USER')
    const courseTitle = get(coursesById, `${courseId}.titles`, 'UNKNOWN COURSE')
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.unenroll_confirm`, { userName, courseTitle }))
    if (!areYouSure) {
      return
    }
    deleteClientEnrollments({
      ids: [ enrollmentId ],
      clientId: itemId,
      accessToken,
    })
  }

  onRolesChange = (e, data) => {
    this.setState({ selectedRoleTypes: data.value })
  }

  filterUserByRoles = (user) => {
    if (!user.roleType) {
      return false
    }
    return !this.state.selectedRoleTypes.length || this.state.selectedRoleTypes.includes(user.roleType)
  }

  renderRoleOption = (role) => ({
    text: role.name,
    value: role.type,
    key: role.type,
  })

  dismissMessage = () => {
    this.props.acknowledgeCreateEnrollment()
    this.props.acknowledgeDeleteEnrollment()
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getClientsState,
      getEnrollmentsState,
      getAllClientsWithOverviews,
      getCourseEnrollments,
      getContentItemsState,
      getAllContentItems,
      organizationId,
      searchParams,
      match: { params: { courseId } },
    } = this.props
    const skip = (!searchParams.skip) ? 0 : searchParams.skip * 1
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1

    if (isStale(getClientsState)) {
      getAllClientsWithOverviews({
        accessToken,
        organizationId,
        ...searchParams,
        skip,
        limit,
        sortBy: 'userName',
        sortOrder: 'asc',
      })
    }
    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getEnrollmentsState)) {
      getCourseEnrollments({
        accessToken,
        orgId: organizationId,
        courseId,
        ...searchParams,
        sortBy: 'userName',
        sortOrder: 'asc',
      })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      getClientsState,
      getEnrollmentsState,
      getAllClientsWithOverviews,
      getCourseEnrollments,
      organizationId,
      getContentItemsState,
      getAllContentItems,
      match: { params: { courseId } },
      searchParams,
    } = this.props
    const skip = (!searchParams.skip) ? 0 : searchParams.skip * 1
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1

    if (isStale(getClientsState) || !isEqual(searchParams, prevProps.searchParams)) {
      getAllClientsWithOverviews({
        accessToken,
        organizationId,
        ...searchParams,
        skip,
        limit,
      })
    }
    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getEnrollmentsState)) {
      getCourseEnrollments({
        accessToken,
        orgId: organizationId,
        courseId,
      })
    }
  }

  componentWillUnmount () {
    setTimeout(this.props.invalidateClientEnrollments.bind(this), 100)
    setTimeout(this.props.acknowledgeCreateEnrollment.bind(this), 100)
    setTimeout(this.props.acknowledgeDeleteEnrollment.bind(this), 100)
    setTimeout(this.props.invalidateClients.bind(this), 100)
    setTimeout(this.props.invalidateContentItems.bind(this), 100)
  }

  render () {
    const {
      t,
      authState: { roleType },
      getClientsState,
      getCoursesState,
      getEnrollmentsState,
      match: { params: { courseId } },
      postEnrollmentsState: {
        isLoading: postEnrollmentsIsLoading,
        error: postEnrollmentsError,
        succeeded: postEnrollmentsSucceeded,
      },
      deleteEnrollmentsState: {
        isLoading: deleteEnrollmentsIsLoading,
        error: deleteEnrollmentsError,
        succeeded: deleteEnrollmentsSucceeded,
      },
      getContentItemsState,
      showFriendlyApiErrorMessages,
      onEditClick,
      searchParams,
      onSearchParamsChange,
      organization,
    } = this.props
    // since we know we have to reload the data if it's stale, don't bother rendering anything under this condition
    if (isStale(getClientsState) || isStale(getCoursesState) || isStale(getEnrollmentsState)) {
      return null
    }

    if (getClientsState.error || getCoursesState.error || getEnrollmentsState.error || getContentItemsState.error) {
      return (
        <FetchResultMessage
          success={false}
          error={getClientsState.error || getCoursesState.error || getEnrollmentsState.error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      )
    }

    const isSaving = postEnrollmentsIsLoading || deleteEnrollmentsIsLoading
    const isLoading = getClientsState.isLoading || getCoursesState.isLoading || getEnrollmentsState.isLoading || getContentItemsState.isLoading
    const course = get(getCoursesState, `value.${courseId}`, {})
    const clientsById = get(getClientsState, 'value', {}) || {}
    const clients = Object.values(clientsById)
    const clientCounts = get(getClientsState, 'value.totalCount.totalCount', 0) || 0
    const enrollments = Object.values(getEnrollmentsState.value || {})
    const error = postEnrollmentsError || deleteEnrollmentsError
    const wasSuccesful = ((postEnrollmentsSucceeded || deleteEnrollmentsSucceeded) && !error)
    const editText = t('edit', { postProcess: 'titleCase' })
    const courseText = t('resource_types.course', { postProcess: 'titleCase' })
    const canEditCourse = (roleType === 'superAdmin' || roleType === 'courseEditor')
    const roleTypes = (!searchParams.roleTypes) ? [] : flatten([ searchParams.roleTypes ])
    const skip = (!searchParams.skip) ? 0 : searchParams.skip * 1
    const limit = (!searchParams.limit) ? PAGE_SIZE : searchParams.limit * 1
    return (
      <Container>
        <FullScreenLoadingOverlay isActive={isLoading}/>
        <FetchResultMessage
          itemType='course enrollments'
          success={wasSuccesful}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        {/* NOTE: This is for vision of self only for now */}
        {/* {([ '54fcbcbe03572d8b0368a169', '5c9e68025b564d22a2a4776d' ].includes(courseId)) && (
          <Button
            data-public
            as='a'
            icon
            color='blue'
            floated='right'
            href={'https://media.base.education/documents/courses/Vision-of-Self-Facilitator-Guide.pdf'}
            target='_blank'
          >
            <Icon name='download' /> Download Course Guide
          </Button>
        )} */}
        <Header style={{ margin: 0 }} as='h2' floated='left'>{t('resource_types.course', { postProcess: 'titleCase' })}: {course.titles} {(course.isActive) ? '' : ` (${t('discontinued')})`}</Header>
        {canEditCourse && (
          <Button data-public color='blue' icon floated='right' size='small' onClick={() => onEditClick(courseId)}>
            <Icon name='edit' /> {`${editText} ${courseText}`}
          </Button>
        )}
        <br style={{ clear: 'both' }} />
        {(!organization.sessionsEnabled) && (
          <>
            <Segment>
              <Grid>
                <Grid.Column width={10}>
                  <SearchInput
                    fluid
                    autoFocus
                    initialValue={searchParams.filter}
                    onSubmit={this.setTextFilter}
                  />
                </Grid.Column>
                <Grid.Column width={6} floated='right'>
                  <div style={{ float: 'right' }}>
                    <div data-public className='text bold inline-block' style={{ padding: 8 }}>Users per page:</div>
                    <Button.Group color='blue'>
                      <Button
                        data-public
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                        onClick={() => onSearchParamsChange({
                          ...searchParams,
                          limit: 50,
                        })}
                        active={limit === 50}
                      >
                  50
                      </Button>
                      <Button
                        data-public
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                        onClick={() => onSearchParamsChange({
                          ...searchParams,
                          skip: (skip % 100 !== 0) ? Math.floor(skip / 100) * 100 : skip, // get closest starting page
                          limit: 100,
                        })}
                        active={limit === 100}
                      >
                  100
                      </Button>
                      <Button
                        data-public
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                        onClick={() => onSearchParamsChange({
                          ...searchParams,
                          skip: (skip % 500 !== 0) ? Math.floor(skip / 500) * 500 : skip, // get closest starting page
                          limit: 500,
                        })}
                        active={limit === 500}
                      >
                  500
                      </Button>
                    </Button.Group>

                  </div>
                </Grid.Column>
              </Grid>
              <br/>
              <p data-public>{t(`${TRANSLATION_PREFIX}.role_select_decription`)}</p>
              <Dropdown
                data-public
                placeholder={t(`${TRANSLATION_PREFIX}.role_select_label`)}
                fluid
                multiple
                search
                selection
                value={roleTypes}
                onChange={this.setFilter('roleTypes')}
                options={ROLES.map(this.renderRoleOption)}
              />
            </Segment>
            <Segment.Group>
              <Segment>
                <Grid columns='equal'>
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      icon
                      color='green'
                      onClick={this.enrollAll}
                      loading={isSaving}
                      disabled={isSaving || !course.isActive}
                    >
                      <Icon name='plus' style={{ paddingRight: 18 }}/>
                      {t(`${TRANSLATION_PREFIX}.enroll_all`)}
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      icon
                      color='blue'
                      onClick={this.reenrollAll}
                      loading={isSaving}
                      disabled={isSaving || !course.isActive}
                    >
                      <Icon name='undo' style={{ paddingRight: 20 }}/>
                      {t(`${TRANSLATION_PREFIX}.reenroll_all`)}
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      data-public
                      fluid
                      icon
                      color='red'
                      onClick={this.unenrollAll}
                      loading={isSaving}
                      disabled={isSaving}
                    >
                      <Icon name='undo' style={{ paddingRight: 8 }}/>
                      {t(`${TRANSLATION_PREFIX}.unenroll_all`)}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment>
                <CourseEnrollmentsTable
                  users={clients.filter(this.filterUserByRoles)}
                  course={course}
                  enrollments={enrollments}
                  isSaving={isSaving}
                  header={t('resource_types.client', { count: 2, postProcess: 'titleCase' }) + ` (${skip + 1} - ${skip + limit})`}
                  onEnroll={this.handleEnroll}
                  onUnenroll={this.handleUnenroll}
                  onReenroll={this.handleReenroll}
                  color='green'
                />
                <Pager
                  itemsPerPage={limit}
                  items={clients}
                  onPageChange={this.setPageNumber}
                  activePage={(skip) ? (skip / limit) + 1 : 1}
                  itemsArePaged={true}
                  totalItemCount={clientCounts}
                  render={(items) => null}
                />
              </Segment>
            </Segment.Group>
          </>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: { [FETCH_ACTIONS.GET_ALL]: getClientsState, [FETCH_ACTIONS.DELETE]: deleteClientsState },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    enrollments: {
      [FETCH_ACTIONS.POST]: postEnrollmentsState,
      [FETCH_ACTIONS.DELETE]: deleteEnrollmentsState,
      [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState,
    },
    contentItems: {
      [FETCH_ACTIONS.GET_ALL]: getContentItemsState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getContentItemsState,
    getClientsState,
    deleteClientsState,
    getCoursesState,
    postEnrollmentsState,
    deleteEnrollmentsState,
    getEnrollmentsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  getAllContentItems,
  getAllClientsWithOverviews,
  createClientEnrollments,
  acknowledgeCreateEnrollment,
  deleteClientEnrollments,
  acknowledgeDeleteEnrollment,
  createCourseEnrollments,
  deleteCourseEnrollments,
  getCourseEnrollments,
  invalidateClientEnrollments,
  invalidateClients,
  invalidateContentItems,
}
const CourseDetailsViewContainer = connect(mapStateToProps, mapDispatchToProps)(CourseDetailsView)

export default translate([ 'components' ])(CourseDetailsViewContainer)
