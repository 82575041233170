import React from 'react'
import PropTypes from 'prop-types'
import { Table, List } from 'semantic-ui-react'
import { translate } from 'react-i18next'
import moment from 'moment'
import { without } from 'lodash'

import MessageModal from '../message-modal/message-modal'

import './compact-messages.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.messages'

class CompactMessages extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      currentlyOpenMessageIds: props.messageIdsToStartOpen.slice(0),
    }
  }

  static propTypes = {
    color: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.object),
    messageIdsToStartOpen: PropTypes.arrayOf(PropTypes.string),
    onMessageRead: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    hide: PropTypes.bool,
  }

  static defaultProps = {
    color: 'blue',
    messages: [],
    messageIdsToStartOpen: [],
    t: (key, opts = {}) => opts.defaultValue || key,
    hide: false,
  }

  handleMessgeClick = (msgId) => {
    this.setState({ currentlyOpenMessageIds: [ msgId ] })
  }

  handleMessgeClose = (msgId) => {
    this.setState({ currentlyOpenMessageIds: without(this.state.currentlyOpenMessageIds, msgId) })
    this.props.onMessageRead(msgId)
  }

  renderMessageLine = (msg) => (
    <Table.Row
      key={`message-line-${msg.id}`}
      className='pointer'
      onClick={this.handleMessgeClick.bind(this, msg.id)}
    >
      <Table.Cell collapsing>
        <List divided relaxed>
          <List.Item>
            <List.Icon className='base-green' name='mail' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header data-public>{msg.header}</List.Header>
              <List.Description data-public>{moment(msg.date).fromNow()}</List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Table.Cell>
    </Table.Row>
  )

  renderMessageModal = (msg) => {
    const isOpen = this.state.currentlyOpenMessageIds.includes(msg.id)
    return (
      <MessageModal
        key={`message-modal-${msg.id}`}
        open={isOpen}
        message={msg}
        onCloseClick={this.handleMessgeClose.bind(this, msg.id)}
      />
    )
  }

  render () {
    const {
      messages,
      hide,
      t,
    } = this.props

    return (
      <React.Fragment>
        {(!hide) && (
          <Table className='compact-messages' striped selectable={!!messages.length}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell data-public collapsing>{t(`${TRANSLATION_PREFIX}.messages_header`)}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(!messages.length) && (
                <Table.Row>
                  <Table.Cell data-public collapsing className='text bold italic grey'>
                    There are no recent messages
                  </Table.Cell>
                </Table.Row>
              )}
              {(!!messages.length) && messages.map(this.renderMessageLine)}
            </Table.Body>
          </Table>
        )}
        {messages.map(this.renderMessageModal)}
      </React.Fragment>
    )
  }
}

export default translate([ 'components' ])(CompactMessages)
