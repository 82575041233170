export const RTL_LANGUAGE_CODES = [ 'ar', 'am', 'az', 'fa', 'he', 'ur' ]

const LANGUAGES = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Español (Spanish)' },
  { value: 'ar', text: 'عربى (Arabic)' },
  { value: 'fr', text: 'Français (French)' },
  { value: 'ko', text: '한국어 (Korean)' },
  { value: 'hi', text: 'हिंदी (Hindi)' },
  { value: 'zh', text: '简化字 (Chinese - Simplified)' },
  { value: 'zh-TW', text: '正體字/繁體字 (Chinese - Traditional)' },
  { value: 'id', text: 'bahasa Indonesia (Indonesian)' },
  { value: 'de', text: 'Deutsche (German)' },
  { value: 'ru', text: 'русский (Russian)' },
  { value: 'pt', text: 'Português (Portuguese)' },
  { value: 'ja', text: '日本語 (Japanese)' },
  { value: 'ms', text: 'Melayu (Malay)' },
  { value: 'fa', text: 'فارسی (Persian / Farsi)' },
  { value: 'tl', text: 'Tagalog (Tagalog)' },
  { value: 'th', text: 'ไทย (Thai)' },
  { value: 'vi', text: 'Tiếng Việt (Vietnamese)' },
  { value: 'bn', text: 'বাংলা (Bengali)' },
  { value: 'am', text: 'አማርኛ (Amharic)' },
  { value: 'af', text: 'Afrikaans (Afrikaans)' },
  { value: 'sq', text: 'Shqiptar (Albanian)' },
  { value: 'az', text: 'Azərbaycan (Azerbaijani)' },
  { value: 'bs', text: 'bosanski (Bosnian)' },
  { value: 'bg', text: 'Български (Bulgarian)' },
  { value: 'hr', text: 'Hrvatski (Croatian)' },
  { value: 'cs', text: 'český (Czech)' },
  { value: 'da', text: 'Dansk (Danish)' },
  { value: 'fa-AF', text: 'دری (Dari)' },
  { value: 'nl', text: 'Nederlands (Dutch)' },
  { value: 'et', text: 'eesti keeles (Estonian)' },
  { value: 'fi', text: 'Suomalainen (Finnish)' },
  { value: 'ka', text: 'ქართული (Georgian)' },
  { value: 'el', text: 'Έλληνας (Greek)' },
  { value: 'ha', text: 'Hausa (Hausa)' },
  { value: 'he', text: 'עברית (Hebrew)' },
  { value: 'hu', text: 'Magyar (Hungarian)' },
  { value: 'it', text: 'Italiano (Italian)' },
  { value: 'lv', text: 'Latviešu valoda (Latvian)' },
  { value: 'no', text: 'Norsk (Norwegian)' },
  { value: 'ps', text: 'پښتو (Pashto)' },
  { value: 'pl', text: 'Polski (Polish)' },
  { value: 'ro', text: 'Romanian (Romanian)' },
  { value: 'sr', text: 'Srpski (Serbian)' },
  { value: 'sk', text: 'Slovensky (Slovak)' },
  { value: 'sl', text: 'slovensko (Slovenian)' },
  { value: 'so', text: 'Soomaali (Somali)' },
  { value: 'sw', text: 'Kiswahili (Swahili)' },
  { value: 'sv', text: 'Svenska (Swedish)' },
  { value: 'ta', text: 'தமிழ் (Tamil)' },
  { value: 'tr', text: 'Türk (Turkish)' },
  { value: 'uk', text: 'Українська (Ukrainian)' },
  { value: 'ur', text: 'اردو (Urdu)' },
  { value: 'pa', text: 'ਪੰਜਾਬੀ (Punjabi)' },
]

export default LANGUAGES
