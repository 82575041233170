import gql from 'graphql-tag'

const GET_ASSESSMENT = gql`
query getAssessment($id: ID!, $orgId: String) {
  assessment(
    id: $id
    orgId: $orgId
  ) {
    id
    name
    description
    sections {
      name
      key
      scoringDefinitions {
        minPercentile
        maxPercentile
        description
      }
      questions {
        id
        text
        type
        key
        choices {
          value
          type
          label
          subLabel
        }
      }
    }
  }
}
`

export default GET_ASSESSMENT
