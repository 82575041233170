import React from 'react'
import PropTypes from 'prop-types'
import { Input, Icon } from 'semantic-ui-react'

import './search-input.css'

class SearchInput extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    basic: PropTypes.bool,
    submitOnChange: PropTypes.bool,
  }

  static defaultProps = {
    onSubmit: console.log.bind(console, 'onSubmit'),
    color: 'blue',
    disabled: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
    }
  }

  handleTextChanged = (event, props) => {
    this.setState({ value: props.value })
    if (this.props.submitOnChange) {
      this.props.onSubmit(props.value)
    }
  }

  handleSubmit = () => {
    const { disabled, initialValue } = this.props
    if (!disabled && this.state.value !== initialValue) {
      this.props.onSubmit(this.state.value)
    }
  }

  handleKeyUp = (event) => {
    const {
      disabled, initialValue = '', onSubmit,
    } = this.props
    if (event.keyCode === 13 && !disabled && this.state.value !== initialValue) {
      onSubmit(this.state.value)
    } else if (event.keyCode === 27 && !disabled && this.state.value !== '') {
      this.setState({ value: '' })
      if (initialValue !== '') {
        setTimeout(() => onSubmit(''), 1)
      }
    }
  }

  render () {
    const {
      disabled,
      loading,
      initialValue = '',
      onSubmit,
      submitOnChange,
      basic,
      ...otherProps
    } = this.props
    const { value } = this.state
    return (
      <Input
        placeholder='Search...'
        {...otherProps}
        className='search-input'
        icon={!!value && (
          <Icon
            name='close'
            size='small'
            inverted
            circular
            link
            onClick={() => {
              this.setState({ value: '' })
              onSubmit('')
            }}
          />
        )}
        action={{
          className: 'blue',
          icon: 'search',
          onClick: this.handleSubmit,
          disabled: disabled || value === initialValue,
          loading,
          basic,
        }}
        value={value}
        disabled={disabled}
        onChange={this.handleTextChanged}
        onKeyUp={this.handleKeyUp}
      />
    )
  }
}

export default SearchInput
