import gql from 'graphql-tag'

const GET_STAFF_BY_ORG_ID = gql`
query getStaffByOrg($orgId: String!) {
  staffByOrgId(
    orgId: $orgId
  ) {
      id
      userName
      profile {
        firstName
        lastName
      }
    }
}
`

export default GET_STAFF_BY_ORG_ID
