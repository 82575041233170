import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import to from 'await-to-js'
import pDebounce from 'p-debounce'
import {
  get,
  pick,
  orderBy,
  without,
  compact,
} from 'lodash'
import { invalidateClients } from '../../../../../actions/clients'
import {
  Segment,
  Header,
  Button,
  Icon,
  Message,
} from 'semantic-ui-react'

import Query from '../../../../common/query/query'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ROOMS_BY_OWNER_USER_ID from '../../../../../helpers/graphql-queries/get-rooms-by-owner-user-id'
import CREATE_ROOM from '../../../../../helpers/graphql-queries/create-room'
import { HTTP_METHODS, FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import Mutation from '../../../../common/mutation/mutation'
import CreateRoomFormModal from '../../../../forms/create-room/create-room-modal'
import fetch from '../../../../../helpers/fetch'
import isUnauthorizedError from '../../../../../helpers/is-unauthorized-error'
import { logout } from '../../../../../actions/authentication'
import {
  getAllChildrenById,
} from '../../../../../helpers/organization'
import RoomItem from '../../../../common/room-item'
import UserActivityChart from '../../../../charts/user-activity'
import CourseUsageChart from '../../../../charts/course-usage'
import getWhatsNewMessages from '../../../organization/clients/get-whats-new-messages'
import CompactMessages from '../../../../common/compact-messages'
// import isStale from '../../../../../helpers/is-stale'
import { EXTERNAL_ROLE_TYPES } from '../../../../../helpers/get-roles'

import './rooms.css'

const path = `/rooms`
const LOCAL_STORAGE_PREFIX = 'com.base-ed.whats-new'

const sixMonthsAgo = new Date(Date.now() - (1000 * 60 * 60 * 24 * 180))

export class ClientRoomsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    homeOrganization: PropTypes.object,
    onReviewProgressClick: PropTypes.func,
    onReviewFirewordsClick: PropTypes.func,
    getCoursesState: PropTypes.object.isRequired,
    showCreateSession: PropTypes.bool,
    showFriendlyApiErrorMessages: PropTypes.bool,
    getStaffState: PropTypes.object,
    onRoomClick: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  static path = path

  constructor (props) {
    super(props)
    const isStaff = [ EXTERNAL_ROLE_TYPES.ADMIN, EXTERNAL_ROLE_TYPES.SUPERVISOR, EXTERNAL_ROLE_TYPES.ADVISOR ].includes(props.authState.roleType)
    const unreadMessageIds = (!isStaff) ? [] : [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38' ].filter((msgId) => {
      const isStored = window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${msgId}.${props.authState.userId}`)
      return !isStored
    })
    this.state = {
      showCreateRoomModal: false,
      searchResults: [],
      isSearching: false,
      unreadMessageIds,
    }
  }

  handleMessageRead = (messageId) => {
    this.setState({ ...this.state, unreadMessageIds: without(this.state.unreadMessageIds, messageId) })
    window.localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.${messageId}.${this.props.authState.userId}`, 'true')
  }

  render () {
    const {
      organization,
      homeOrganization,
      authState: {
        accessToken,
        userId,
        roleType,
        orgId,
      },
      getCoursesState,
      onRoomClick,
      logout,
      t,
    } = this.props
    const {
      showCreateRoomModal,
      searchResults,
      isSearching,
      unreadMessageIds,
    } = this.state

    const oldCoursesById = get(getCoursesState, 'value', {}) || {}
    const coursesById = {
      ...oldCoursesById,
      // ...keyBy(newCourses, 'id'),
    }
    const orgChildrenById = getAllChildrenById(homeOrganization.children)
    const orgNamesById = Object.keys(orgChildrenById).reduce((accum, id) => {
      return {
        ...accum,
        [id]: orgChildrenById[id].name,
      }
    }, { [homeOrganization.id]: homeOrganization.name })
    const onSearchChange = async (search, skip, limit, excludedIds) => {
      this.setState({ ...this.state, isSearching: true })
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/studentSearch`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          search,
          orgId: organization.id,
          excludedIds,
          skip,
          limit,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
      }
      this.setState({
        ...this.state,
        isSearching: false,
        searchResults: (err) ? [] : result.map((res) => ({ ...res, className: (res.belongsToARoom) ? 'has-room' : '' })),
      })
    }

    const validateUserName = async (userName) => {
      if (!userName) {
        return {
          message: 'forms.error_required',
        }
      }
      if (userName.length < 3) {
        return {
          message: 'forms.error_min_length',
          count: 3,
        }
      }
      if (userName.length > 1000) {
        return {
          message: 'forms.error_max_length',
          count: 1000,
        }
      }
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/userNameAvailability`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
        return {
          message: err.message,
        }
      }
      if (result.isAvalable) {
        return
      }
      return {
        message: (!!result.suggestions && !!result.suggestions.length) ? 'forms.error_not_available_with_suggestions' : 'forms.error_not_available',
        field: 'user name',
        suggestions: (!!result.suggestions && !!result.suggestions.length) ? result.suggestions.join(', ') : '',
      }
    }
    const onValidateUserName = pDebounce(validateUserName, 300)

    const onCreateStudent = async (student) => {
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/clients`
      const method = HTTP_METHODS.POST
      const [ err, id ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: homeOrganization.id,
          isArchived: false,
          ssoOverride: false,
          secret: student.password,
          graduationYear: student.graduationYear,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error creating student', err)
        throw err
      }

      const profileUrl = `/clients/${id}/profiles`
      const [ profileErr ] = await to(fetch(profileUrl, {
        method,
        headers,
        data: {
          firstName: student.firstName,
          lastName: student.lastName,
        },
      }))
      isUnauthorizedError(profileErr) && logout({ userInitiated: false })
      if (profileErr) {
        console.error('Error creating student profile', profileErr)
        throw profileErr
      }

      this.setState({
        ...this.state,
        searchResults: [ {
          id,
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: homeOrganization.id,
          isArchived: false,
          ssoOverride: false,
          profile: {
            firstName: student.firstName,
            lastName: student.lastName,
          },
          className: 'new-student',
        } ],
      })
    }

    const courseTitlesById = {}
    const courseTypesById = {}
    if (getCoursesState.value) {
      Object.keys(getCoursesState.value).forEach((courseId) => {
        courseTitlesById[courseId] = getCoursesState.value[courseId].titles || getCoursesState.value[courseId].title.en
        courseTypesById[courseId] = getCoursesState.value[courseId].type
      })
    }

    // if (selectedRoom) {
    //   return (
    //     <RoomDetails
    //       room={selectedRoom}
    //       organization={homeOrganization}
    //       coursesById={coursesById}
    //       userId={userId}
    //       accessToken={accessToken}
    //       refreshToken={refreshToken}
    //       onReviewProgressClick={() => console.log('click')}
    //       onClientClick={() => console.log('click')}
    //       onReviewFirewordsClick={() => console.log('click')}
    //       onBack={() => this.setState({ ...this.state, selectedRoom: null })}
    //       searchResults={searchResults}
    //       onSearchChange={onSearchChange}
    //       isSearching={isSearching}
    //     />
    //   )
    // }

    return (
      <Mutation
        mutation={CREATE_ROOM}
        awaitRefetchQueries={true}
        onCompleted={({ createRoom }) => {
          this.setState({
            ...this.state, showCreateRoomModal: false, isSearching: false, searchResults: [],
          })
        }}
      >
        {(createRoom, { loading: isSaving }) => {
          return (
            <Query
              variables={{
                userId,
              }}
              query={GET_ROOMS_BY_OWNER_USER_ID}
              fetchPolicy='network-only'
            >
              {({
                loading,
                data,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const rooms = get(data, 'roomsByOwnerUserId.rooms') || []
                const activity = get(data, 'roomsByOwnerUserId.activity') || {}
                const usage = get(data, 'roomsByOwnerUserId.usage') || []
                const hasRoomWithStudentRequests = rooms.some((room) => room.isOpenForRequests && !!room.requestedStudentIds.length)
                const actvityByRole = {
                  student: {
                    activity,
                  },
                }
                const usageByRoleType = {
                  student: {
                    usage: usage.reduce((accum, courseUsage) => {
                      return {
                        ...accum,
                        [courseUsage.courseId]: courseUsage.data,
                      }
                    }, {}),
                  },
                }
                return (
                  <>
                    <CreateRoomFormModal
                      open={!!showCreateRoomModal}
                      onClose={() => this.setState({
                        ...this.state, showCreateRoomModal: false,
                      })}
                      orgNamesById={orgNamesById}
                      courses={Object.values(coursesById).filter((course) => organization.courseIds.includes(course.id))}
                      searchResults={searchResults}
                      isSearching={isSearching}
                      isSaving={isSaving}
                      showTour={!rooms || !rooms.length}
                      forceFirewords={homeOrganization.requireRoomFirewords}
                      enablePrivateRooms={homeOrganization.privateRoomsEnabled}
                      canCreateStudents={roleType !== 'advisor'}
                      onSave={(roomData) => {
                        const room = {
                          ...pick(roomData, [
                            'firewordNotificationsEnabled',
                            'firewordNotificationsForRoomCoursesOnly',
                            'isOpenForRequests',
                            'isPrivate',
                            'imageUrl',
                            'name',
                            'studentIds',
                            'courseIds',
                          ]),
                          ownerUserId: userId,
                          orgId,
                        }
                        createRoom({
                          refetchQueries: [ {
                            query: GET_ROOMS_BY_OWNER_USER_ID,
                            variables: {
                              userId,
                            },
                          } ],
                          variables: { room },
                        })
                      }}
                      onSearchChange={onSearchChange}
                      onCreateStudent={onCreateStudent}
                      validateUserName={onValidateUserName}
                    />
                    {/* <div style={{
                        marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center',
                      }}>
                        <Header as='h2' style={{ flexGrow: 2, margin: 0 }}>My Dashboard</Header>
                        <Button style={{ flexShrink: 2 }} primary onClick={() => this.setState({ showCreateRoomModal: true })}>Create a Room</Button>
                      </div> */}
                    <div className='rooms-grid'>
                      <div>
                        <Segment placeholder={(!rooms || !rooms.length)}>
                          {(!rooms || !rooms.length) && (
                            <>
                              <Header data-public icon>
                                <Icon style={{ width: 65 }} className='base-teal fas fa-door-open'/>
                                  You have no rooms
                              </Header>
                              <Button data-public className='base-teal-bg' primary onClick={() => this.setState({ showCreateRoomModal: true })}><Icon name='stopwatch' /> Get Started on BASE</Button>
                            </>

                          )}
                          {(!!rooms && !!rooms.length) && (
                            <>
                              <Header as='h3' dividing><Icon style={{
                                verticalAlign: 'bottom',
                              }} className='base-teal fas fa-door-open'/> My Rooms</Header>

                              {(hasRoomWithStudentRequests) && (
                                <Message icon info>
                                  <Icon name='user add' />
                                  <Message.Content>
                                    <Message.Header data-public>Student Requests</Message.Header>
                                    <p data-public>There are students that have requested to join one of your rooms. Click on the room to approve or deny the requests.</p>
                                  </Message.Content>
                                </Message>
                              )}
                              {rooms.map((room) => {
                                return (
                                  <RoomItem
                                    key={room.id}
                                    id={`room-${room.id}`}
                                    room={room}
                                    lastActiveDate={room.lastActiveDate}
                                    hasUnreviewedFirewords={!!room.unreviewedFirewordCount}
                                    highestFirewordSeverity={room.highestUnreviewedFirewordSeverity}
                                    onClick={() => onRoomClick(room.id)}
                                  />
                                )
                              })}
                              <Button className='base-teal-bg' style={{ marginTop: 10 }} fluid primary onClick={() => this.setState({ showCreateRoomModal: true })}><Icon name='plus' /> Create Another Room</Button>
                            </>
                          )}
                        </Segment>
                        <CompactMessages
                          messages={compact(orderBy(getWhatsNewMessages(t, 'views.organization'), 'date', [ 'desc' ]).filter((msg) => msg.date > sixMonthsAgo && (!msg.forOrgId || [ homeOrganization.id, ...homeOrganization.parentIds ].includes(msg.forOrgId))).slice(0, 3))}
                          messageIdsToStartOpen={unreadMessageIds}
                          onMessageRead={this.handleMessageRead}
                        />
                      </div>
                      {(!rooms || !rooms.length) && (
                        <div>
                          <Segment placeholder>
                            <Header data-public icon>
                              <Icon style={{ width: 65 }} className='base-teal fas fa-chart-line'/>
                                  There is no room activity
                            </Header>
                            <p className='text base-teal italic' style={{ textAlign: 'center' }}><Icon size='large' color='yellow' name='lightbulb outline' /> Create a room and add some students to view their recent room activity.</p>
                          </Segment>
                          <Segment placeholder style={{ marginTop: 0 }}>
                            <Header data-public icon>
                              <Icon name='base-teal chart bar'/>
                                  There is no module activity
                            </Header>
                            <p className='text base-teal italic' style={{ textAlign: 'center' }}><Icon size='large' color='yellow' name='lightbulb outline' /> Create a room and add some students to view their recent module activity.</p>
                          </Segment>
                        </div>
                      )}
                      {(!!rooms && !!rooms.length) && (
                        <div>
                          <UserActivityChart
                            rolesWithActivityByRoleType={actvityByRole}
                            hideRoleSelection={true}
                            forRoom={true}
                            height='300px'
                          />
                          <CourseUsageChart
                            rolesWithUsageByRoleType={usageByRoleType}
                            courseTitlesById={courseTitlesById}
                            courseTypesById={courseTypesById}
                            hideRoleSelection={true}
                            forRoom={true}
                          />

                        </div>
                      )}
                    </div>
                  </>
                )
              }}
            </Query>
          )
        }}
      </Mutation>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getCoursesState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  invalidateClients,
  logout,
}
const ClientRoomsViewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientRoomsView)

export default translate([ 'components' ])(ClientRoomsViewContainer)
