import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Header,
  Tab,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  sortBy,
  values,
  filter,
} from 'lodash'

import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import {
  createClients,
  createClientsWithEmail,
  acknowledgeCreateClient,
  invalidateClients,
} from '../../../../actions/clients'
import CreateClientsForm from '../../../forms/create-clients/create-clients-new'
import CreateClientForm from '../../../forms/create-client/create-client'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import getRoles, { ROLE_VALUES, EXTERNAL_ROLE_TYPES } from '../../../../helpers/get-roles'
import {
  getOrgTypesOfParents,
} from '../../../../helpers/organization'

import './create-clients.css'

export class CreateClientView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    createClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    createClients: PropTypes.func.isRequired,
    createClientsWithEmail: PropTypes.func.isRequired,
    acknowledgeCreateClient: PropTypes.func.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    organization: PropTypes.object,
    homeOrganization: PropTypes.object,
  }

  static path = '/users/new'

  createNewClient = (newClientData) => {
    const {
      authState: { accessToken },
      createClients,
      organizationId,
    } = this.props
    const data = {
      clients: [ {
        userName: ([ 'advisor', 'supervisor', 'admin' ].includes(newClientData.roleType)) ? newClientData.email : newClientData.userName,
        email: newClientData.email,
        secret: newClientData.password,
        isShared: newClientData.isShared,
        type: 'user',
        roleType: newClientData.role,
        orgId: organizationId,
        graduationYear: newClientData.graduationYear,
        profile: (newClientData.firstName || newClientData.lastName || newClientData.jobTitle) ? {
          firstName: newClientData.firstName,
          lastName: newClientData.lastName,
          jobTitle: newClientData.jobTitle,
        } : undefined,
      } ],
      enrollmentCourseIds: newClientData.courses || [],
    }
    createClients({ accessToken, data })
  }

  createNewClients = (data) => {
    const {
      authState: { accessToken },
      createClientsWithEmail,
    } = this.props
    data.clients.forEach((client) => {
      if ([ 'advisor', 'supervisor', 'admin' ].includes(client.roleType)) {
        client.userName = client.email
      }
    })
    createClientsWithEmail({ accessToken, data })
  }

  isAssociatedToOrg = (org, course) => {
    const associatedToOrg = org._meta.relationships.some((rel) => rel.type === 'courses' && rel.id === course.id)
    return associatedToOrg
  }

  renderCreateClientTab = (roles, courses) => {
    const { createClientsState, organization } = this.props

    return (
      <Tab.Pane key='createClent' as={Container}>
        <CreateClientForm
          roles={roles}
          courses={courses}
          isLoading={createClientsState.isLoading}
          onSave={this.createNewClient}
          hideCourseSelection={organization.sessionsEnabled || organization.roomsEnabled}
          color='green'
        />
      </Tab.Pane>
    )
  }

  renderCreateClientsTab = (roles, courses) => {
    const {
      createClientsState, organizationId, organization,
    } = this.props

    return (
      <Tab.Pane key='createClients' as={Container}>
        <CreateClientsForm
          roles={roles}
          courses={courses}
          isLoading={createClientsState.isLoading}
          onSave={this.createNewClients}
          organizationId={organizationId}
          hideCourseSelection={organization.sessionsEnabled || organization.roomsEnabled}
          color='green'
        />
      </Tab.Pane>
    )
  }

  componentDidUpdate (prevProps) {
    if (prevProps.createClientsState.isLoading && !this.props.createClientsState.isLoading) {
      window.scrollTo(0, 0)
      if (!this.props.createClientsState.error) {
        this.props.invalidateClients()
      }
    }
  }

  componentWillUnmount () {
    this.props.acknowledgeCreateClient()
  }

  render () {
    const {
      authState: { roleType },
      getCoursesState,
      createClientsState: {
        error,
        succeeded,
        id,
      },
      showFriendlyApiErrorMessages,
      organization,
      homeOrganization,
      t,
    } = this.props

    const isCustomer = !!organization.contractStartDate
    const parentOrgTypes = (homeOrganization.id === organization.id) ? [] : getOrgTypesOfParents(homeOrganization, organization)
    const showExternalRoleTypesOnly = Object.values(EXTERNAL_ROLE_TYPES).includes(roleType) || isCustomer || parentOrgTypes.includes('customer')
    const filteredRoles = getRoles({ externalOnly: showExternalRoleTypesOnly }).filter((role) => {
      const authenticatedUserRoleValue = ROLE_VALUES[roleType]
      const targetRoleValue = ROLE_VALUES[role.type]
      return (roleType === EXTERNAL_ROLE_TYPES.SUPERVISOR || roleType === EXTERNAL_ROLE_TYPES.ADVISOR)
        ? (authenticatedUserRoleValue > targetRoleValue)
        : (authenticatedUserRoleValue >= targetRoleValue)
    })
    const courses = sortBy(values(get(getCoursesState, 'value', {})), 'titles')

    if (getCoursesState.error) {
      return (
        <FetchResultMessage
          success={false}
          error={getCoursesState.error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      )
    }
    // NOTE: these should no longer be needed since switching to the new batch creation api
    // const conflictingUserNames = (error && error.statusCode === 409) ? error.message.split(': ')[1].split('.')[0] : ''
    const lineNumber = (error && error.statusCode === 400) ? error.message.split('"clients" at position ')[1].split(' ')[0] : '0'
    return (
      <Container className='create-clients-view'>
        <FullScreenLoadingOverlay isActive={getCoursesState.isLoading}/>
        <FetchResultMessage
          itemType='users'
          success={succeeded}
          error={error}
          customSuccessMessage={(!id) ? t('views.organization.clients.success_message') : undefined}
          showFriendlyError={showFriendlyApiErrorMessages}
          friendlyErrorOverrides={{
            '400': t('views.organization.clients.error_messages.400', { lineNumber: (lineNumber * 1) + 1 }),
            '409': get(error, 'message', ''),
          }}
        />
        <Header data-public as='h2' className='text'>{t('create', { postProcess: 'titleCase' })} {t('resource_types.client', { postProcess: 'titleCase', count: 2 })}</Header>
        <Tab
          onTabChange={this.handleTabChange}
          menu={{ pointing: true }}
          renderActiveOnly={false}
          panes={[
            { menuItem: `${t('single')} ${t('resource_types.client', { postProcess: 'titleCase' })}`, pane: this.renderCreateClientTab(filteredRoles, courses) },
            { menuItem: `${t('multiple')} ${t('resource_types.client', { postProcess: 'titleCase', count: 2 })}`, pane: this.renderCreateClientsTab(filter(filteredRoles, { isInternal: false }), courses) },
          ]}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: { [FETCH_ACTIONS.POST]: createClientsState },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    createClientsState,
    getCoursesState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  createClients,
  createClientsWithEmail,
  acknowledgeCreateClient,
  invalidateClients,
}
const CreateClientViewContainer = connect(mapStateToProps, mapDispatchToProps)(CreateClientView)

export default translate([ 'components' ])(CreateClientViewContainer)
