import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  Button,
  Dropdown,
  Form,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'
import {
  isEqual,
  omitBy,
  isEmpty,
  isArray,
} from 'lodash'

import {
  trimInputOnBlur,
  dropdownOnChange,
  rangeValueParser,
  checkboxOnChange,
} from '../../../helpers/form'
import CheckboxGroup from '../../common/checkbox-group'
import BaseForm from '../base-form'
// import { required } from '../../../helpers/form-validators'
import {
  JOB_TITLE_OPTIONS,
} from '../../../helpers/user'

import './user-profile.css'

const GENDER_OPTIONS = [ 'male', 'female', 'notmention' ]
const ETHNICITY_OPTIONS = [ 'native_american', 'asian', 'african_american', 'pacific_islander', 'white', 'hispanic' ]
const TRANSLATION_PREFIX = 'forms.user-profile'

const areArraysEqual = (a, b) => {
  if (isArray(a) && isArray(b)) {
    return isEqual(a.sort(), b.sort())
  }
  return false
}

export class UserProfileForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    school: PropTypes.string,
    age: PropTypes.number,
    gender: PropTypes.string,
    ethnicity: PropTypes.array,
    assessmentsDeclinedReason: PropTypes.string,
    showAssessmentsDeclinedReason: PropTypes.bool,
    jobTitle: PropTypes.string,
    worksWithStudents: PropTypes.bool,
    isDisabled: PropTypes.bool,
    t: PropTypes.func,
    isSSO: PropTypes.bool,
    ssoName: PropTypes.string,
    isStaffMember: PropTypes.bool,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'teal',
    isLoading: false,
    isDisabled: false,
    roles: [],
    t: (key, opts = {}) => opts.defaultValue || key,
    showAssessmentsDeclinedReason: true,
    isSSO: false,
    ssoName: 'ClassLink',
  }

  handleSaveForm = (data) => {
    const newData = {
      ...data,
      assessmentsDeclinedReason: (!data.assessmentsDeclined) ? undefined : data.assessmentsDeclinedReason,
      assessmentsDeclined: undefined,
    }
    this.props.onSave(omitBy(newData, (val) => !val || (isArray(val) && isEmpty(val))))
  }

  getEducationLevelOption = (level) => ({ value: level, text: level })

  getGenderOption = (gender) => ({ value: gender, text: this.props.t(`${TRANSLATION_PREFIX}.gender_options.${gender}`) })

  getEthnicityOption = (ethnicity) => ({ value: ethnicity, label: this.props.t(`${TRANSLATION_PREFIX}.ethnicity_options.${ethnicity}`) })

  renderForm = ({
    handleSubmit,
    submitDisabled,
  }) => {
    const {
      color,
      isLoading,
      isDisabled,
      t,
      isSSO,
      ssoName,
      isStaffMember,
    } = this.props
    return (
      <Form className='user-profile-form' onSubmit={handleSubmit}>
        {(isSSO) && (
          <Message
            data-public
            info
            icon='info circle'
            header={`Some parts of this form may be disabled for ${ssoName} users`}
            content={`The data in the disabled fields will be automatically synchronized from ${ssoName}.`}
          />
        )}
        <Form.Group widths='equal'>
          <Field
            name='firstName'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='user-profile-input'
                  placeholder={t(`${TRANSLATION_PREFIX}.first_name_label`)}
                  label={t(`${TRANSLATION_PREFIX}.first_name_label`)}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading || isDisabled || isSSO}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                  onBlur={trimInputOnBlur(input)}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            name='lastName'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='user-profile-input'
                  placeholder={t(`${TRANSLATION_PREFIX}.last_name_label`)}
                  label={t(`${TRANSLATION_PREFIX}.last_name_label`)}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading || isDisabled || isSSO}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                  onBlur={trimInputOnBlur(input)}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        </Form.Group>
        {(!isStaffMember) && (
          <>
            <Form.Group widths='equal'>
              <Field
                name='school'
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <Form.Field>
                    <Form.Input
                      className='user-profile-input'
                      placeholder={t(`${TRANSLATION_PREFIX}.school_label`)}
                      label={t(`${TRANSLATION_PREFIX}.school_label`)}
                      error={!!message && touched}
                      type='text'
                      fluid
                      disabled={isLoading || isDisabled || isSSO}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                      onBlur={trimInputOnBlur(input)}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </Form.Field>
                )}
              />
              <Field
                name='age'
                parse={rangeValueParser(0, 150)}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <Form.Field>
                    <Form.Input
                      className='user-profile-input'
                      placeholder={t(`${TRANSLATION_PREFIX}.age_label`)}
                      label={t(`${TRANSLATION_PREFIX}.age_label`)}
                      error={!!message && touched}
                      type='number'
                      fluid
                      disabled={isLoading || isDisabled || isSSO}
                      {...input}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </Form.Field>
                )}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field
                name='gender'
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <Form.Field disabled={isLoading || isDisabled || isSSO}>
                    <label>{t(`${TRANSLATION_PREFIX}.gender_label`)}</label>
                    <Dropdown
                      className='user-profile-input'
                      placeholder={t(`${TRANSLATION_PREFIX}.gender_label`)}
                      error={!!message && touched}
                      fluid
                      search
                      selection
                      clearable
                      disabled={isLoading || isDisabled || isSSO}
                      {...input}
                      options={GENDER_OPTIONS.map(this.getGenderOption)}
                      onChange={dropdownOnChange(input)}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </Form.Field>
                )}
              />
            </Form.Group>
            <Field
              name='ethnicity'
              isEqual={areArraysEqual}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field disabled={isLoading || isDisabled || isSSO}>
                  <label>{t(`${TRANSLATION_PREFIX}.ethnicity_label`)}</label>
                  <CheckboxGroup
                    vertical
                    options={ETHNICITY_OPTIONS.map(this.getEthnicityOption)}
                    selectedValues={input.value}
                    disabled={isLoading || isDisabled || isSSO}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                  />
                  {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
          </>
        )}
        {(isStaffMember) && (
          <Form.Group widths='equal'>
            <Field
              name='jobTitle'
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field disabled={isLoading || isDisabled || isSSO}>
                  <label>{t(`${TRANSLATION_PREFIX}.job_title_label`)}</label>
                  <Dropdown
                    className='user-profile-input'
                    placeholder={t(`${TRANSLATION_PREFIX}.job_title_label`)}
                    error={!!message && touched}
                    fluid
                    search
                    selection
                    clearable
                    disabled={isLoading || isDisabled || isSSO}
                    {...input}
                    options={JOB_TITLE_OPTIONS}
                    onChange={dropdownOnChange(input)}
                  />
                  {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
            <Field
              name='worksWithStudents'
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Checkbox
                  className='pushed'
                  style={{ marginTop: '1.6em' }}
                  label={t(`${TRANSLATION_PREFIX}.works_with_students_label`)}
                  toggle
                  disabled={isLoading || isDisabled}
                  {...input}
                  checked={input.value}
                  value='worksWithStudents'
                  onChange={checkboxOnChange(input)}/>
              )}
            />
          </Form.Group>
        )}
        <Button
          type='submit'
          className='user-profile-button'
          floated='right'
          loading={isLoading}
          color={color}
          disabled={submitDisabled}>
          {t('forms.save_button')}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      firstName,
      lastName,
      school,
      age,
      gender,
      ethnicity = [],
      jobTitle,
      worksWithStudents,
      isSSO,
      isLoading,
      t,
    } = this.props
    return (
      <BaseForm
        header={t(`${TRANSLATION_PREFIX}.header`)}
        onSubmit={this.handleSaveForm}
        isLoading={isLoading}
        initialValues={{
          firstName,
          lastName,
          school,
          age,
          gender,
          ethnicity,
          jobTitle,
          worksWithStudents,
          isSSO,
        }}
        render={this.renderForm}
      />
    )
  }
}

export default translate([ 'components' ])(UserProfileForm)
