import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  Button,
  Form,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'

import {
  minLength,
  matchesField,
  composeValidators,
} from '../../../helpers/form-validators'
import BaseForm from '../base-form/base-form'

import './change-password.css'

const validateNewPassword = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  matchesField({
    message: 'forms.error_matches_field', fieldName: 'newPasswordAgain', fields: 'passwords',
  }),
)

const validateNewPasswordAgain = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  matchesField({
    message: 'forms.error_matches_field', fieldName: 'newPassword', fields: 'passwords',
  }),
)

const TRANSLATION_PREFIX = 'forms.change-password'

export class ChangePasswordForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'teal',
    isLoading: false,
    isDisabled: false,
    roles: [],
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = (data) => {
    this.props.onSave(data)
  }

  getRoleOption = (role) => ({
    value: role.id,
    label: role.name,
    subLabel: role.description,
  })

  renderForm = ({
    handleSubmit,
    submitDisabled,
  }) => {
    const {
      color,
      isLoading,
      isDisabled,
      t,
    } = this.props
    const newPasswordText = `${t('new', { postProcess: 'titleCase' })} ${t('forms.password_label', { postProcess: 'titleCase' })}`
    const reenterPasswordText = `${t('re_enter')} ${t('new', { postProcess: 'titleCase' })} ${t('forms.password_label', { postProcess: 'titleCase' })}`
    return (
      <Form className='change-password-form' onSubmit={handleSubmit}>
        <Form.Group widths='equal'>
          <Field
            name='newPassword'
            validate={validateNewPassword}
            validateFields={[ 'newPassword', 'newPasswordAgain' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='change-password-input'
                  placeholder={newPasswordText}
                  label={newPasswordText + ' *'}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading || isDisabled}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            name='newPasswordAgain'
            validate={validateNewPasswordAgain}
            validateFields={[ 'newPassword', 'newPasswordAgain' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='change-password-input'
                  placeholder={reenterPasswordText}
                  label={reenterPasswordText + ' *'}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading || isDisabled}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        </Form.Group>
        <br/>
        <Button
          data-public
          type='submit'
          className='change-password-button'
          floated='right'
          loading={isLoading}
          color={color}
          disabled={submitDisabled}>
          {t(`${TRANSLATION_PREFIX}.change_button`)}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      t,
      isLoading,
    } = this.props
    return (
      <BaseForm
        header={t(`${TRANSLATION_PREFIX}.header`)}
        onSubmit={this.handleSaveForm}
        isLoading={isLoading}
        resetOnSubmit={true}
        render={this.renderForm}
      />
    )
  }
}

export default translate([ 'components' ])(ChangePasswordForm)
