import to from 'await-to-js'

import fetch from '../helpers/fetch'
import buildQueryString from '../helpers/build-query-string'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'
import {
  FETCH_ACTIONS,
  HTTP_METHODS,
  FETCH_TYPES,
} from '../helpers/fetch-constants'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'

const STORE_PATH_PREFIX = 'clients'

export function getAllClients ({
  accessToken,
  organizationId,
  isArchived = false,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, `/clients?orgId=${organizationId}&isArchived=${isArchived}`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getAllClients')
}

export function getAllClientsWithOverviews ({
  accessToken,
  organizationId,
  isArchived = false,
  skip = 0,
  limit = 24,
  sortBy = 'lastActiveCourse.date',
  sortOrder = 'desc',
  roleTypes,
  completedCourseIds,
  incompleteCourseIds,
  activeSince,
  filter,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const queryParams = {
    orgId: organizationId,
    isArchived,
    skip,
    limit,
    sortBy,
    sortOrder,
    roleTypes,
    completedCourseIds,
    incompleteCourseIds,
    activeSince,
    enrollmentsUsage: true,
    filter,
  }
  const queryStr = buildQueryString(queryParams)
  const url = `/clientOverviews?${queryStr}`
  const method = HTTP_METHODS.GET
  const fetchAction = FETCH_ACTIONS.GET_ALL
  const actionName = 'getAllClientsWithOverviews'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ err, result ] = await to(fetch(url, { method, headers }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }
      dispatch([ requestSuccess([ ...result.clients, { id: 'totalCount', totalCount: result.totalCount } ]), storePath, `${actionName}Success` ])
    },
  ]
}

export function invalidateClients () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateClients' ]
}

export function getClient ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, id, `/clients/${id}`, null, { headers }, FETCH_ACTIONS.GET, STORE_PATH_PREFIX, 'getClient')
}

export function getClientWithOverview ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, id, `/clientOverviews/${id}`, null, { headers }, FETCH_ACTIONS.GET, STORE_PATH_PREFIX, 'getClientWithOverview')
}

export function createClient ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, '/clients', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createClient')
}

export function createClients ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, '/batchClients', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createClient', true)
}

export function createClientsWithEmail ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, '/batchClientsWithEmailV2', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createClient', true)
}

export function acknowledgeCreateClient () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateClient' ]
}

export function updateClient ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.PUT, data.id, `/clients/${data.id}`, data, { headers }, FETCH_ACTIONS.PUT, STORE_PATH_PREFIX, 'updateClient', false, true)
}

export function acknowledgeUpdateClient () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUpdateClient' ]
}

export function deleteClient ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.DELETE, id, `/clients/${id}`, null, { headers }, FETCH_ACTIONS.DELETE, STORE_PATH_PREFIX, 'deleteClient')
}

export function acknowledgeDeleteClient () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.DELETE}`
  return [ acknowledge(), storePath, 'acknowledgeDeleteClient' ]
}

export function batchPasswordReset ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, 'batchPasswordReset', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'batchPasswordReset', true)
}
