import React, {
  useState,
} from 'react'
import { translate } from 'react-i18next'
import to from 'await-to-js'
import PropTypes from 'prop-types'
import {
  get,
  map,
  findIndex,
  find,
} from 'lodash'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import AssessmentCard from '../assessment-card/assessment-card'
import GraphQlErrorMessage from '../graphql-error-message/graphql-error-message'
import FullScreenLoadingOverlay from '../full-screen-loading-overlay'

import '../../common.css'

// const TRANSLATION_PREFIX = 'common.assessment'

export const GET_ASSESSMENT_QUERY = gql`
query getAssessment($id: ID!, $lang: String) {
  assessment(
    id: $id
    lang: $lang
  ) {
    id
    name
    description
    sections {
      name
      questions {
        id
        text
        type
        choices {
          value
          type
          label
          subLabel
        }
      }
    }
  }
}
`

export const GET_USER_ASSESSMENT_QUERY = gql`
query getUserAssessment($id: ID!) {
  userAssessment(
    id: $id
  ) {
    id
    assessmentId
    userId
    attemptNumber
    completionDate
    updated
    created
    answers {
      type
      value
      questionId
    }
  }
}
`

export const SAVE_USER_ASSESSMENT_ANSWER = gql`
mutation saveAnswer($id: ID!, $answer: AnswerInput!) {
  setUserAssessmentAnswer(
    id: $id
    answer: $answer
  ) {
    id
    assessmentId
    userId
    attemptNumber
    completionDate
    updated
    created
    answers {
      type
      value
      questionId
    }
  }
}
`

export const COMPLETE_USER_ASSESSMENT = gql`
mutation completeAssessment($id: ID!) {
  completeUserAssessment(id: $id) {
    id
    assessmentId
    userId
    attemptNumber
    completionDate
    updated
    created
    answers {
      type
      value
      questionId
    }
  }
}
`

function AssessmentContainer ({
  userAssessmentId,
  assessmentId,
  onBackToCourse,
  i18n,
}) {
  const {
    loading: userAssessmentLoading,
    error: userAssessmentError,
    data: userAssessmentData,
  } = useQuery(GET_USER_ASSESSMENT_QUERY, { variables: { id: userAssessmentId } })
  const {
    loading: assessmentLoading,
    error: assessmentError,
    data: assessmentData,
  } = useQuery(GET_ASSESSMENT_QUERY, { variables: { id: assessmentId, lang: i18n.language } })
  const [
    completeAssessment,
    {
      loading: isCompleting,
      error: completionError,
    },
  ] = useMutation(COMPLETE_USER_ASSESSMENT, {
    // NOTE: Not sure why I had to explicitly update the cache... thought this would happen automatically
    update (cache, { data: { completeUserAssessment } }) {
      cache.writeQuery({
        query: GET_USER_ASSESSMENT_QUERY,
        variables: { id: userAssessmentId },
        data: {
          userAssessment: completeUserAssessment,
        },
      })
    },
  })
  const [
    saveAnswer,
    {
      loading: isSaving,
      error: saveError,
    },
  ] = useMutation(SAVE_USER_ASSESSMENT_ANSWER, {
    onCompleted (data) {
      const answers = get(data, `setUserAssessmentAnswer.answers`, [])
      if (answers.length >= questions.length) {
        completeAssessment({ variables: { id: userAssessmentId } })
      }
    },
  })
  const [ hasAnswered, setHasAnswered ] = useState(false)
  const loading = userAssessmentLoading || assessmentLoading
  const error = userAssessmentError || assessmentError || saveError || completionError
  const sections = get(assessmentData, `assessment.sections`, [])
  const questions = sections.flatMap((section) => section.questions)
  const answers = get(userAssessmentData, `userAssessment.answers`, [])
  const completionDate = get(userAssessmentData, 'userAssessment.completionDate')
  if (!isSaving && !isCompleting && !loading && !error && !!questions.length && !completionDate && answers.length >= questions.length && !hasAnswered) {
    completeAssessment({ variables: { id: userAssessmentId } })
  }
  return (
    <React.Fragment>
      <GraphQlErrorMessage error={error} />
      <FullScreenLoadingOverlay isActive={loading} />
      {(userAssessmentData && userAssessmentData.userAssessment && questions && questions.length) && (
        <Assessment
          questions={questions}
          answers={answers}
          saveAnswer={(answer) => {
            setHasAnswered(true)
            return saveAnswer({ variables: { id: userAssessmentId, answer } })
          }}
          isUpdating={isSaving || isCompleting}
          isComplete={!!completionDate || answers.length >= questions.length}
          onBackToCourse={onBackToCourse}
        />
      )}
    </React.Fragment>
  )
}

AssessmentContainer.propTypes = {
  userAssessmentId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  onBackToCourse: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
}

function Assessment ({
  questions,
  answers,
  saveAnswer,
  isUpdating,
  isComplete,
  onBackToCourse,
}) {
  const completedQuestionIds = map(answers, 'questionId')
  const progress = (isComplete) ? 100 : Math.ceil(((completedQuestionIds.length - 1) * 100) / questions.length)

  // this value is only used to set the initial state
  const defaultLastAnsweredQuestionIndex = (completedQuestionIds.length === questions.length && questions.length !== 0) ? questions.length - 1 : findIndex(questions, (q) => !completedQuestionIds.includes(q.id)) - 1
  const defaultLastAnsweredQuestionId = (!answers.length) ? null : questions[defaultLastAnsweredQuestionIndex].id
  const [ lastAnsweredQuestionId, setLastAnsweredQuestionId ] = useState(defaultLastAnsweredQuestionId)
  const currentQuestionIndex = (lastAnsweredQuestionId) ? findIndex(questions, { id: lastAnsweredQuestionId }) + 1 : 0
  const currentQuestion = questions[currentQuestionIndex]
  const currentAnswer = (currentQuestion) ? find(answers, { questionId: currentQuestion.id }) : null
  const answerValue = (currentAnswer) ? (currentQuestion.type === 'checkbox') ? currentAnswer.value.split(',') : currentAnswer.value : null

  return (
    <AssessmentCard
      questionNumber={currentQuestionIndex + 1}
      questionText={(currentQuestion) ? currentQuestion.text : ''}
      choices={(currentQuestion) ? currentQuestion.choices : []}
      type={(currentQuestion) ? currentQuestion.type : ''}
      answer={answerValue}
      onSubmit={async (answer) => {
        if (!currentAnswer || answer.value !== currentAnswer.value) {
          const [ err ] = await to(saveAnswer({ ...answer, questionId: currentQuestion.id }))
          if (err) {
            throw err
          }
        }
        setLastAnsweredQuestionId(currentQuestion.id)
      }}
      hasNext={(currentQuestionIndex + 1) < questions.length}
      hasPrevious={currentQuestionIndex > 0}
      isComplete={isComplete}
      progress={progress}
      isSaving={isUpdating}
      onBackToCourse={onBackToCourse}
      onBack={() => {
        if (currentQuestionIndex > 1) {
          setLastAnsweredQuestionId(questions[currentQuestionIndex - 2].id)
        } else if (currentQuestionIndex <= 1) {
          setLastAnsweredQuestionId(null)
        }
      }}
    />
  )
}

Assessment.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.array,
  })).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  saveAnswer: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  onBackToCourse: PropTypes.func.isRequired,
}

export default translate([ 'components' ])(AssessmentContainer)
