import to from 'await-to-js'

import {
  FETCH_ACTIONS,
  HTTP_METHODS,
  FETCH_TYPES,
} from '../helpers/fetch-constants'
import acknowledge from '../reducers/fetch/acknowledge'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'

const STORE_PATH_PREFIX = 'ignisRatings'

export function createIgnisRating ({
  data,
  accessToken,
  clientId,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.POST
  const fetchAction = FETCH_ACTIONS.POST
  const url = `/clients/${clientId}/ignisRatings`
  const actionName = 'createClientIgnisRatings'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ err, result ] = await to(fetch(url, {
        data, method, headers,
      }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeCreateIgnisRating () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateIgnisRating' ]
}
