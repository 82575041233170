import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Label,
  Button,
  Dropdown,
  Icon,
  List,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'
import {
  sortBy,
} from 'lodash'

import {
  minLength,
  maxLength,
  required,
  composeValidators,
} from '../../../helpers/form-validators'
import {
  trimInputOnBlur,
  dropdownOnChange,
  checkboxOnChange,
} from '../../../helpers/form'
import BaseForm from '../base-form/base-form'

import './update-course.css'

const validatCourseName = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
)
const validateDescription = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 100000 }),
)

const TRANSLATION_PREFIX = 'forms.update-course'

const requiredLabel = ' *'

const AUDIENCES = [ {
  text: 'Elementary',
  value: 'elementary',
  key: 'elementary',
}, {
  text: 'Middle/High School',
  value: 'secondary',
  key: 'secondary',
}, {
  text: 'High School',
  value: 'high',
  key: 'high',
}, {
  text: 'Middle School',
  value: 'middle',
  key: 'middle',
}, {
  text: 'Higher Ed.',
  value: 'higher_ed',
  key: 'higher_ed',
}, {
  text: 'Parents/Guardians',
  value: 'parent',
  key: 'parent',
}, {
  text: 'Professional Development',
  value: 'professional',
  key: 'professional',
}, {
  text: 'Adult',
  value: 'adult',
  key: 'adult',
} ]

const REASONS = sortBy([ {
  text: 'Prevention',
  value: 'prevention',
  key: 'prevention',
}, {
  text: 'Anger/Fighting',
  value: 'anger_fighting',
  key: 'anger_fighting',
}, {
  text: 'Drug Prevention and Education',
  value: 'substance_issues',
  key: 'substance_issues',
}, {
  text: 'Disruptive Behavior',
  value: 'disruptive_behavior',
  key: 'disruptive_behavior',
}, {
  text: 'Truancy',
  value: 'truancy',
  key: 'truancy',
}, {
  text: 'Tardy/Chronic Absenteeism',
  value: 'tardy_chronic_absenteeism',
  key: 'tardy_chronic_absenteeism',
}, {
  text: 'Inappropriate Peer Behavior',
  value: 'inappropriate_peer_behavior',
  key: 'inappropriate_peer_behavior',
}, {
  text: 'Domestic Issues',
  value: 'domestic_issues',
  key: 'domestic_issues',
}, {
  text: 'Self-Defeating Behaviors',
  value: 'self_defeating_behaviors',
  key: 'self_defeating_behaviors',
}, {
  text: 'Stress',
  value: 'stress',
  key: 'stress',
}, {
  text: 'Depression and Anxiety',
  value: 'depression_anxiety',
  key: 'depression_anxiety',
}, {
  text: 'Bullying/Relational Aggression/Cyberbullying/Technology Issues',
  value: 'bullying_relational_agression_cyber_bullying_technology_issues',
  key: 'bullying_relational_agression_cyber_bullying_technology_issues',
}, {
  text: 'School Disengagement',
  value: 'school_disengagement',
  key: 'school_disengagement',
}, {
  text: 'Gang Affiliation',
  value: 'gang_affiliation',
  key: 'gang_affiliation',
}, {
  text: 'Unhealthy Relationships/Exploitation/Trafficking',
  value: 'unhealthy_relationships_exploitation_trafficking',
  key: 'unhealthy_relationships_exploitation_trafficking',
}, {
  text: 'Cultural Issues',
  value: 'cultural_issues',
  key: 'cultural_issues',
}, {
  text: 'Poor Choices',
  value: 'poor_choices',
  key: 'poor_choices',
}, {
  text: 'LGBTQ',
  value: 'lgbtq',
  key: 'lgbtq',
}, {
  text: 'Suspension/Expulsion',
  value: 'suspension_expulsion',
  key: 'suspension_expulsion',
}, {
  text: 'Getting to Know Student/IEP Transition Questions',
  value: 'getting_to_know_student_iep_transition_questions',
  key: 'getting_to_know_student_iep_transition_questions',
} ], 'text')

const GUIDES = [ {
  type: 'high-school',
  label: 'High School',
}, {
  type: 'middle-school',
  label: 'Middle School',
} ]

export class UpdateCourseForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onUploadGuide: PropTypes.func.isRequired,
    onDeleteGuide: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    course: PropTypes.shape({
      id: PropTypes.string.isRequired,
      titles: PropTypes.string.isRequired,
      descriptions: PropTypes.string.isRequired,
      referralReasons: PropTypes.array.isRequired,
      isInstructional: PropTypes.bool,
      type: PropTypes.string,
      imageUrl: PropTypes.string,
      guides: PropTypes.array,
    }).isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    onUploadGuide: console.log.bind(console, 'onUploadGuide'),
    onDeleteGuide: console.log.bind(console, 'onDeleteGuide'),
    color: 'green',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = (data) => {
    this.props.onSave({
      id: this.props.course.id,
      titles: { en: data.name },
      descriptions: { en: data.description },
      referralReasons: data.referralReasons,
      isActive: true,
      isInstructional: data.isInstructional,
      type: data.type,
      imageUrl: this.props.course.imageUrl,
    })
  }

  renderForm = ({
    handleSubmit,
    submitDisabled,
  }) => {
    const {
      color,
      course,
      isLoading,
      onUploadGuide,
      onDeleteGuide,
      t,
    } = this.props
    return (
      <Form className='update-course-form' onSubmit={handleSubmit}>
        <Field
          name='name'
          validate={validatCourseName}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <Form.Input
                placeholder={t(`${TRANSLATION_PREFIX}.name_label`)}
                label={t(`${TRANSLATION_PREFIX}.name_label`) + requiredLabel}
                error={!!message && touched}
                type='text'
                fluid
                disabled={isLoading}
                {...input}
                autoCorrect='off'
                autoCapitalize='words'
                spellCheck='false'
                onBlur={trimInputOnBlur(input)}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />
        <Field
          name='description'
          validate={validateDescription}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <Form.Input
                placeholder={t(`${TRANSLATION_PREFIX}.description_label`)}
                label={t(`${TRANSLATION_PREFIX}.description_label`) + requiredLabel}
                error={!!message && touched}
                type='text'
                fluid
                disabled={isLoading}
                {...input}
                autoCorrect='on'
                autoCapitalize='on'
                spellCheck='true'
                onBlur={trimInputOnBlur(input)}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />
        <Field
          name='referralReasons'
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <label>{t(`${TRANSLATION_PREFIX}.referral_reasons_label`)}</label>
              <Dropdown
                placeholder={t(`${TRANSLATION_PREFIX}.referral_reasons_label`)}
                fluid
                multiple
                search
                selection
                options={REASONS}
                {...input}
                disabled={isLoading}
                onChange={dropdownOnChange(input)}
              />
            </Form.Field>
          )}
        />
        <Form.Group widths='equal'>
          <Field
            name='type'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <label>{'Audience'}</label>
                <Dropdown
                  placeholder={'Audience'}
                  fluid
                  search
                  selection
                  options={AUDIENCES}
                  {...input}
                  disabled={isLoading}
                  onChange={dropdownOnChange(input)}
                />
              </Form.Field>
            )}
          />
          <Field
            name='isInstructional'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Checkbox
                className='pushed'
                label={'Instructional'}
                toggle
                {...input}
                checked={input.value}
                value='isInstructional'
                onChange={checkboxOnChange(input)}
              />
            )}
          />
        </Form.Group>
        <strong className='text small'>{'Educator Guides'}</strong>
        <List divided relaxed>
          {(GUIDES.map((guide) => {
            const matchingGuide = (course.guides) ? course.guides.find((existingGuide) => existingGuide.name.en.includes(guide.label)) : null
            const guideFileName = (matchingGuide) ? matchingGuide.url.en.replace(/^.*courses\/([a-z0-9-]+\.pdf)\?.*$/, '$1') : null
            return (
              <List.Item key={`${course.id}-${guide.type}`}>
                <List.Content floated='right'>
                  <Button as='label' color='blue' size='mini' loading={isLoading} htmlFor={`${guide.type}-upload`} type='button' title={(matchingGuide) ? 'Replace Guide' : 'Upload Guide'}>
                    <Icon name='cloud upload' /> {(matchingGuide) ? 'Replace Guide' : 'Upload Guide'}
                  </Button>
                  <input
                    id={`${guide.type}-upload`}
                    hidden
                    type='file'
                    accept='application/pdf'
                    onChange={(e) => onUploadGuide({
                      courseType: course.type, type: guide.type, file: [ ...e.target.files ][0],
                    }) }
                  />
                  {(!!matchingGuide) && (
                    <Button icon color='red' size='mini' loading={isLoading} type='button' title={'Delete Guide'} onClick={() => onDeleteGuide(guide.type)}>
                      <Icon name='remove' />
                    </Button>
                  )}
                </List.Content>
                <List.Icon name={(matchingGuide) ? 'file pdf outline' : 'file pdf outline'} className={(matchingGuide) ? 'base-teal' : 'base-red'} size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header>{guide.label}</List.Header>
                  <List.Description className={(guideFileName) ? '' : 'text base-red'}>{(guideFileName) || 'Missing!'}</List.Description>
                </List.Content>
              </List.Item>
            )
          }))}
        </List>

        <Button
          type='submit'
          floated='right'
          loading={isLoading}
          color={color}
          disabled={submitDisabled}>
          {t('forms.save_button')}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      course,
      isLoading,
      t,
    } = this.props
    return (
      <BaseForm
        header={t(`${TRANSLATION_PREFIX}.header`)}
        basic={false}
        disableWarning={false}
        onSubmit={this.handleSaveForm}
        isLoading={isLoading}
        initialValues={{
          name: course.titles,
          description: course.descriptions,
          referralReasons: course.referralReasons,
          isInstructional: course.isInstructional || false,
          type: course.type,
        }}
        render={this.renderForm}
      />
    )
  }
}

export default translate([ 'components' ])(UpdateCourseForm)
