import gql from 'graphql-tag'

export const NAME = 'updateUserProfile'
const UPDATE_USER_PROFILE = gql`
mutation ${NAME}($profile: ClientProfileInput!) {
  ${NAME}(profile: $profile) {
    assessmentsDeclinedReason
  }
}
`

export default UPDATE_USER_PROFILE
