import React from 'react'
import { Mutation as ApolloMutation } from 'react-apollo'
import PropTypes from 'prop-types'

import GraphqlErrorMessage from '../graphql-error-message/graphql-error-message'

// NOTE: default onError handler returns false, otherwise error bubbles up and can crash the app
const Mutation = ({ children, ...rest }) => (
  <ApolloMutation
    errorPolicy='all'
    onError={() => false}
    {...rest}
  >
    {(operation, result) => (
      <React.Fragment>
        <GraphqlErrorMessage error={result.error} result={result}/>
        {children(operation, result)}
      </React.Fragment>
    )}
  </ApolloMutation>
)

Mutation.propTypes = {
  children: PropTypes.any,
}

export default Mutation
