import React, {
  useState, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import { debounce } from 'lodash'

export const DebouncedInput = ({
  wait,
  debounceOptions,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  const [ text, setText ] = useState(defaultValue || value || '')
  const previousValue = useRef(value)
  const previousOnChange = useRef(onChange)
  const debouncedOnChange = useRef(debounce(onChange, wait, debounceOptions))
  // TODO: watch for changes to wait and debounceOptions as well
  useEffect(() => {
    if (value !== previousValue.current) {
      setText(value)
      previousValue.current = value
    }
    if (onChange !== previousOnChange.current) {
      previousOnChange.current = onChange
      debouncedOnChange.current = debounce(onChange, wait, debounceOptions)
    }
  }, [ value, onChange, wait, debounceOptions ])
  return (
    <Input
      value={text}
      onChange={(e, data) => {
        setText(data.value)
        debouncedOnChange.current(data.value)
      }}
      {...rest}
    />
  )
}

DebouncedInput.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  wait: PropTypes.number.isRequired,
  debounceOptions: PropTypes.object,
  onChange: PropTypes.func,
}

DebouncedInput.defaultProps = {
  onChange: console.log.bind(console, 'onChange'),
}

export default DebouncedInput
