import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Step,
  Modal,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  union, map, defer,
} from 'lodash'
import Joyride, {
  STATUS,
} from 'react-joyride'

import Page1 from './create-room-page-1'
import Page2 from './create-room-page-2'
import Page3 from './create-room-page-3'
import Page4 from './create-room-page-4'

import '../../common.css'

// const WELCOME_COURSE_IDS = [
//   '5771f05d14aae69912bad0d7', // Welcome Course
//   '584ecbb7261e9fe97149376a', // Adult Welcome Course
// ]

const JOYRIDE_STEPS_BY_ROOM_STEP = {
  '0': [
    {
      target: '#create-room-steps',
      content: (
        <div>
          <h3>Let{`'`}s get started!</h3>
          <p>The first task is to set up your room.</p>
          <p>A room is a virtual classroom or office in which you can manage your students and their modules. Simply follow the steps to get started.</p>
        </div>
      ),
      disableBeacon: true,
      hideCloseButton: true,
    }, {
      placement: 'bottom-start',
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-name-input',
      content: (
        <div>
          <p className='text bold base-teal'>Step 1/5:</p>
          <p>Give your room a name.</p>
          <p><Icon name='lightbulb' color='yellow'/> <span className='text bold base-teal'>Helpful Hint:</span> {`The name of your room will be visible to students, so give it a name that will make sense to them. Something like "Ms. Doe's Homeroom" or "Mr. Brown's Office."`}</p>
        </div>
      ),
    }, {
      placement: 'bottom-end',
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-image-input',
      content: (
        <div>
          <p className='text bold base-teal'>Step 2/5:</p>
          <p>Great job!</p>
          <p>Now let{`'`}s pick an image for your room.</p>
          <p><Icon name='lightbulb' color='yellow'/> <span className='text bold base-teal'>Helpful Hint:</span> This gives your room a little more personality and helps your students find it easily.</p>
        </div>
      ),
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-firewords-input',
      content: (
        <div>
          <p className='text bold base-teal'>Step 3/5:</p>
          <p>Well done!</p>
          <p>Next, select whether or not you would like to receive <Icon name='fire' color='red' /><strong>fireword</strong> notifications.</p>
          <p><Icon name='lightbulb' color='yellow'/> <span className='text bold base-teal'>Helpful Hint:</span> These are emails that alert you to potentially harmful language that your students type.</p>
        </div>
      ),
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-firewords-courses-input',
      content: (
        <div>
          <p>Please click here if you would only like to receive fireword notifications for the modules you assign. By leaving this unchecked, you will receive notifications for all modules your students take.</p>
        </div>
      ),
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-private-input',
      content: (
        <div>
          <p className='text bold base-teal'>Step 4/5:</p>
          <p>By making a room private, student progress and responses will only be visible to you and school/district admins.</p>
          <p><Icon name='lightbulb' color='yellow'/> <span className='text bold base-teal'>Helpful Hint:</span> After the room is created, this option cannot be changed.</p>
        </div>
      ),
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#room-requests-input',
      content: (
        <div>
          <p className='text bold base-teal'>Step 5/5:</p>
          <p>Allow students to find your room and request access for non-private rooms.</p>
          <p><Icon name='lightbulb' color='yellow'/> <span className='text bold base-teal'>Helpful Hint:</span> By allowing students to request access, you can add them directly to your room in one easy step. This option is not available for private rooms.</p>
        </div>
      ),
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#create-room-next-button',
      content: (
        <div>
          <p>Fantastic job!</p>
          <p>Now you can add your students.</p>
        </div>
      ),
    },
  ],
  '1': [
    {
      disableBeacon: true,
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#create-room-student-search',
      content: `Find your students and click on them to add them to your room.`,
    }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#create-room-next-button',
      content: `Great!  click Next to begin adding your recommended modules.`,
    },
  ],
  '2': [
    {
    //   disableBeacon: true,
    //   disableOverlay: true,
    //   hideCloseButton: true,
    //   spotlightClicks: true,
    //   target: '#create-room-course-search',
    //   content: `You also don't have to add any recommended modules to your room now. Modules that you choose to recommend will be presented to your students first.`,
    // }, {
    //   disableOverlay: true,
    //   hideCloseButton: true,
    //   spotlightClicks: true,
    //   placement: 'right',
    //   target: '#create-room-course-reasons',
    //   content: `If you are unsure of what modules to recommend, you can choose one or more module categories to get a curated list of modules are related to those categories.`,
    // }, {
    //   disableOverlay: true,
    //   hideCloseButton: true,
    //   spotlightClicks: true,
    //   placement: 'bottom-start',
    //   target: '#create-room-instructional-courses',
    //   content: `If you plan on leading the instruction of the courses, or if you do not want your students to type in their answers, check this box.`,
    // }, {
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'right-start',
      target: '.room-courses-selection',
      content: `Click on modules to add them to your room.`,
    // }, {
    //   disableOverlay: true,
    //   hideCloseButton: true,
    //   spotlightClicks: true,
    //   target: '#create-room-next-button',
    //   content: `Once you've added your recommended courses, click Next to review your room settings.`,
    },
  ],
  '3': [
    {
      disableBeacon: true,
      disableOverlay: true,
      hideCloseButton: true,
      spotlightClicks: true,
      target: '#create-room-preview',
      content: (
        <div>
          <p>This is a preview of what your room will look like on your dashboard.</p>
          <p>If everything looks OK, click the Create button below.</p>
        </div>
      ),
    },
  ],
}

const FONT_COLORS = [
  '#335973',
  '#4D9965',
  '#CC3366',
  '#339CFF',
]

export class CreateRoomModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    courses: PropTypes.array.isRequired,
    searchResults: PropTypes.arrayOf(PropTypes.object),
    isSearching: PropTypes.bool,
    canCreateStudents: PropTypes.bool,
    open: PropTypes.bool,
    showTour: PropTypes.bool,
    forceFirewords: PropTypes.bool,
    enablePrivateRooms: PropTypes.bool,
    onSearchChange: PropTypes.func.isRequired,
    onCreateStudent: PropTypes.func.isRequired,
    validateUserName: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    orgNamesById: PropTypes.object.isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    isSaving: false,
    t: (key, opts = {}) => opts.defaultValue || key,
    showTour: true,
    forceFirewords: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      firstPageIsValid: false,
      currentStep: 0,
      completedPages: [],
      roomData: {},
      startTour: true,
      forceShowTour: false,
    }
    this.helpers = null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open && !this.props.open) {
      this.setState({
        ...this.state,
        firstPageIsValid: false,
        currentStep: 0,
        completedPages: [],
        roomData: {},
        startTour: true,
        forceShowTour: false,
      })
    }
  }

  render () {
    const {
      isSaving,
      isSearching,
      searchResults,
      onSearchChange,
      onCreateStudent,
      validateUserName,
      courses,
      onSave,
      open,
      onClose,
      showTour,
      forceFirewords,
      canCreateStudents,
      orgNamesById,
      enablePrivateRooms,
      t,
    } = this.props
    const {
      firstPageIsValid,
      currentStep,
      completedPages,
      roomData,
      startTour,
      forceShowTour,
    } = this.state
    const steps = [ {
      title: 'Your Room',
      description: 'Define your room settings',
      icon: 'university',
    }, {
      title: 'Add Your Students',
      description: 'Select your students',
      icon: 'users',
    }, {
      title: 'Select Modules',
      description: 'Pick modules to recommend',
      icon: 'fas fa-chalkboard',
    }, {
      title: 'Confirm Details',
      description: 'Check and go',
      icon: 'info circle',
    } ]
    return (
      <>
        <Joyride
          continuous={true}
          run={open && (showTour || forceShowTour) && startTour && currentStep === 0}
          showSkipButton={true}
          disableOverlayClose={true}
          locale={{ skip: 'Exit', last: 'OK' }}
          getHelpers={(helpers) => (this.helpers = helpers)}
          styles={{
            options: {
              primaryColor: '#003050',
              zIndex: 10000,
            },
          }}
          callback={({
            action, index, status, type,
          }) => {
            if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
              this.setState({ ...this.state, startTour: false })
            }
          }}
          steps={JOYRIDE_STEPS_BY_ROOM_STEP['0']}
        />
        <Joyride
          continuous={true}
          run={open && (showTour || forceShowTour) && startTour && currentStep === 1}
          showSkipButton={true}
          disableOverlayClose={true}
          locale={{ skip: 'Exit', last: 'OK' }}
          getHelpers={(helpers) => (this.helpers = helpers)}
          styles={{
            options: {
              primaryColor: '#003050',
              zIndex: 10000,
            },
          }}
          callback={({
            action, index, status, type,
          }) => {
            if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
              this.setState({ ...this.state, startTour: false })
            }
          }}
          steps={JOYRIDE_STEPS_BY_ROOM_STEP['1']}
        />
        <Joyride
          continuous={true}
          run={open && (showTour || forceShowTour) && startTour && currentStep === 2}
          showSkipButton={true}
          disableOverlayClose={true}
          locale={{ skip: 'Exit', last: 'OK' }}
          getHelpers={(helpers) => (this.helpers = helpers)}
          styles={{
            options: {
              primaryColor: '#003050',
              zIndex: 10000,
            },
          }}
          callback={({
            action, index, status, type,
          }) => {
            if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
              this.setState({
                ...this.state, startTour: false, forceShowTour: false,
              })
            }
          }}
          steps={JOYRIDE_STEPS_BY_ROOM_STEP['2']}
        />
        <Joyride
          continuous={true}
          run={open && (showTour || forceShowTour) && startTour && currentStep === 3}
          showSkipButton={true}
          disableOverlayClose={true}
          locale={{ skip: 'Exit', last: 'OK' }}
          getHelpers={(helpers) => (this.helpers = helpers)}
          styles={{
            options: {
              primaryColor: '#003050',
              zIndex: 10000,
            },
          }}
          callback={({
            action, index, status, type,
          }) => {
            if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
              this.setState({
                ...this.state, startTour: false, forceShowTour: false,
              })
            }
          }}
          steps={JOYRIDE_STEPS_BY_ROOM_STEP['3']}
        />
        <Modal
          className='create-room-modal'
          closeOnDimmerClick={false}
          open={open}
          onClose={onClose}
        >
          <Modal.Content>
            <Step.Group id='create-room-steps' widths={steps.length} size='small' unstackable>
              {steps.map((step, i) => (
                <Step
                  key={`create-room-step-${i}`}
                  id={`create-room-step-${i}`}
                  completed={completedPages.includes(i)}
                  active={(currentStep === i)}
                // onClick={(currentStep !== i && (completedPages.includes(i) || completedPages.includes(i - 1))) ? (_, d) => this.setState({ ...this.state, currentStep: d.id.replace('create-room-step-', '') * 1 }) : undefined}
                >
                  <Step.Content>
                    <div
                      style={{
                        fontSize: '3.5em',
                        marginRight: 15,
                        fontFamily: 'Brush Script MT',
                        color: FONT_COLORS[i],
                      }}
                    >
                      {i + 1}
                    </div>
                  </Step.Content>
                  <Icon
                    style={{
                      color: FONT_COLORS[i],
                    }}
                    name={(completedPages.includes(i)) ? 'check' : step.icon} />
                  <Step.Content>
                    <Step.Title data-public>{step.title}</Step.Title>
                    <Step.Description data-public>{step.description}</Step.Description>
                  </Step.Content>
                </Step>
              ))}
            </Step.Group>

            {(currentStep === 0) && (
              <Page1
                onValidChange={(isValid) => {
                  this.setState({
                    ...this.state,
                    firstPageIsValid: isValid,
                  })
                }}
                onChange={(d) => {
                  this.setState({
                    ...this.state,
                    roomData: {
                      ...roomData,
                      ...d,
                      students: roomData.students || [],
                      studentIds: roomData.studentIds || [],
                      courses: roomData.courses || [],
                      courseIds: roomData.courseIds || [],
                    },
                  })
                }}
                forceFirewords={forceFirewords}
                enablePrivateRooms={enablePrivateRooms}
                name={roomData.name}
                imageUrl={roomData.imageUrl}
                firewordNotificationsEnabled={roomData.firewordNotificationsEnabled}
                firewordNotificationsForRoomCoursesOnly={roomData.firewordNotificationsForRoomCoursesOnly}
                isOpenForRequests={roomData.isOpenForRequests}
                isPrivate={roomData.isPrivate}
              />
            )}
            {(currentStep === 1) && (
              <Page2
                onChange={(d) => {
                  this.setState({
                    ...this.state,
                    roomData: {
                      ...roomData,
                      students: d,
                      studentIds: map(d, 'id'),
                    },
                  })
                }}
                orgNamesById={orgNamesById}
                students={roomData.students}
                isSearching={isSearching}
                searchResults={searchResults}
                onSearchChange={onSearchChange}
                onCreateStudent={onCreateStudent}
                validateUserName={validateUserName}
                canCreateStudents={canCreateStudents}
              />
            )}
            {(currentStep === 2) && (
              <Page3
                onChange={(d) => {
                  this.setState({
                    ...this.state,
                    roomData: {
                      ...roomData,
                      courses: d,
                      courseIds: map(d, 'id'),
                    },
                  })
                }}
                courses={roomData.courses}
                availableCourses={courses}
              />
            )}
            {(currentStep === 3) && (
              <Page4
                roomData={roomData}
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-public
              color='black'
              disabled={isSaving}
              onClick={() => {
                this.setState({
                  ...this.state,
                  startTour: true,
                  forceShowTour: true,
                })
              }}
            >
              <Icon name='question' /> {t('forms.help_button')}
            </Button>
            <Button
              data-public
              color='red'
              disabled={isSaving}
              onClick={() => {
                this.setState({
                  ...this.state,
                  firstPageIsValid: false,
                  currentStep: 0,
                  completedPages: [],
                  roomData: {},
                  forceShowTour: false,
                })
                defer(onClose)
              }}
            >
              {t('forms.cancel_button')}
            </Button>
            {(!!currentStep) && (
              <Button
                data-public
                content={t('forms.back_button')}
                labelPosition='left'
                icon='arrow left'
                disabled={isSaving}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    currentStep: currentStep - 1,
                  })
                }}
              />
            )}
            <Button
              data-public
              id='create-room-next-button'
              content={(currentStep === 3) ? t('forms.create_button') : t('forms.next_button')}
              labelPosition='right'
              loading={isSaving}
              icon={(currentStep === 3) ? 'checkmark' : 'arrow right'}
              color={(currentStep === 3) ? 'green' : 'blue'}
              disabled={isSaving || (!firstPageIsValid && !currentStep)}
              onClick={() => {
                if (currentStep === 3) {
                  return onSave(roomData)
                }
                this.setState({
                  ...this.state,
                  completedPages: union(completedPages, [ currentStep ]),
                  currentStep: currentStep + 1,
                  startTour: true,
                })
              }}
            />
          </Modal.Actions>
        </Modal>
      </>
    )
  }
}

export default translate([ 'components' ])(CreateRoomModal)
