import React from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Message,
} from 'semantic-ui-react'
import {
  isNumber,
} from 'lodash'
import { translate } from 'react-i18next'
import moment from 'moment'

import QuestionAnswerSegment from '../question-answer-segment'
import FirewordSegment from '../fireword-segment'

import './question-card.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.question-card'

const QuestionCard = ({
  color,
  questionNumber,
  questionText,
  answerText,
  unsubmitted,
  analyzedAnswer,
  analyzedCaptures,
  courseTitle,
  sessionTitle,
  answerTimestamp,
  attemptNumber,
  notes,
  onReviewedClick,
  onIgnisRate,
  previousRating,
  isLoading,
  isSaving,
  t,
}) => {
  const attemptText = (isNumber(attemptNumber) && attemptNumber) ? t(`common.attempt_interval`, { count: attemptNumber, postProcess: 'interval' }) : sessionTitle || null
  const title = (attemptText && courseTitle)
    ? `${courseTitle} (${attemptText})`
    : (attemptText && !courseTitle)
      ? attemptText
      : (courseTitle && !attemptText)
        ? courseTitle
        : null
  let headerElement
  if (title && answerTimestamp) {
    headerElement = (
      <Header as='h5'>{title} <span className='timestamp'>{moment(answerTimestamp).fromNow()} ({moment(answerTimestamp).format('M/D/YYYY @ h:mm A')})</span></Header>
    )
  } else if (title && !answerTimestamp) {
    headerElement = (
      <Header as='h5'>{title}</Header>
    )
  } else if (!title && answerTimestamp) {
    headerElement = (
      <Header as='h5'>&nbsp;<span className='timestamp'>{moment(answerTimestamp).fromNow()} ({moment(answerTimestamp).format('M/D/YYYY @ h:mm A')})</span></Header>
    )
  }
  return (
    <React.Fragment>
      <Segment loading={isLoading} className='question-card no-print'>
        {headerElement}
        {(notes) && (
          <Message
            data-public
            info
            header={t(`${TRANSLATION_PREFIX}.educator_notes_header`)}
            content={notes}
          />
        )}
        <QuestionAnswerSegment number={questionNumber} text={questionText} color={color}/>
        {unsubmitted && <Message warning header={'This student has not submitted a final response'}/>}
        {answerText && <QuestionAnswerSegment type='answer' text={answerText}/>}
        {(analyzedCaptures && analyzedCaptures.length > 0) && (
          <FirewordSegment
            analyzedCaptures={analyzedCaptures}
            analyzedAnswer={analyzedAnswer}
            onReviewedClick={onReviewedClick}
            onIgnisRate={onIgnisRate}
            previousRating={previousRating}
            isSaving={isSaving}
          />
        )}
      </Segment>
      <div className='question-card print-only'>
        <QuestionAnswerSegment number={questionNumber} text={questionText} color={color}/>
        {answerText && <QuestionAnswerSegment type='answer' text={answerText}/>}
        {(analyzedCaptures && analyzedCaptures.length > 0) && (
          <FirewordSegment
            analyzedCaptures={analyzedCaptures}
            analyzedAnswer={analyzedAnswer}
            onReviewedClick={onReviewedClick}
            onIgnisRate={onIgnisRate}
            previousRating={previousRating}
            isSaving={isSaving}
          />
        )}
      </div>
    </React.Fragment>
  )
}

QuestionCard.propTypes = {
  color: PropTypes.string.isRequired,
  questionNumber: PropTypes.number,
  questionText: PropTypes.string,
  answerText: PropTypes.string,
  unsubmitted: PropTypes.bool,
  analyzedAnswer: PropTypes.object,
  analyzedCaptures: PropTypes.arrayOf(PropTypes.object),
  courseTitle: PropTypes.string,
  sessionTitle: PropTypes.string,
  answerTimestamp: PropTypes.instanceOf(Date),
  attemptNumber: PropTypes.any,
  onReviewedClick: PropTypes.func,
  onIgnisRate: PropTypes.func,
  previousRating: PropTypes.object,
  onUnsafeClick: PropTypes.func,
  notes: PropTypes.string,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

QuestionCard.defaultProps = {
  color: 'blue',
  questionNumber: 0,
  onReviewedClick: console.log.bind(console, 'onReviewedClick'),
  onIgnisRate: console.log.bind(console, 'onIgnisRate'),
  onUnsafeClick: console.log.bind(console, 'onUnsafeClick'),
  isLoading: false,
  isSaving: false,
}

export default translate([ 'components' ])(QuestionCard)
