import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import BaselineMeanScoresByDomainChart from './baseline-mean-scores-by-domain'
import BaselineMeanScoresByAttemptBarGraph from './baseline-mean-scores-by-attempt-bar-graph'

const BaselineMeanScores = ({
  byAttempt,
  isForIndividual,
  groupedOrgScores,
  isGlobal,
  ...otherProps
}) => {
  if (isForIndividual) {
    return (
      <BaselineMeanScoresByAttemptBarGraph
        groupedOrgScores={groupedOrgScores}
        isGlobal={isGlobal}
        {...otherProps}
      />
    )
  }
  const Chart = (byAttempt) ? BaselineMeanScoresByAttemptBarGraph : BaselineMeanScoresByDomainChart
  return (
    <Chart
      isGlobal={true}
      {...otherProps}
    />
  )
}

BaselineMeanScores.propTypes = {
  byAttempt: PropTypes.bool,
  isForIndividual: PropTypes.bool,
  t: PropTypes.func.isRequired,
  groupedOrgScores: PropTypes.object,
  isGlobal: PropTypes.bool,
}

export default translate([ 'components' ])(BaselineMeanScores)
