import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { connect } from 'react-redux'

import { refresh, logout } from '../../../actions/authentication'
import isAuthenticated from '../../../helpers/is-authenticated'

const USER_ACTIVITY_WAIT_MS = 1000 * 5 // track user activity at most every 5 seconds
const USER_ACTIVITY_MAX_WAIT_MS = 1000 * 30 // track user activity at least every 30 seconds
const REFRESH_WINDOW_MS = 1000 * 60 * 20 // refresh only if within 20 minutes of token expiration
const IS_ACTIVE_THRESHOLD_MS = 1000 * 60 * 5 // user is only active if there is activity within the last 5 minutes
const MIN_AUTH_WAIT_TIME_MS = 1000 * 60 * 3 // wait at least 3 minutes before refreshing the token since last successful auth
const CHECK_AUTH_INTERVAL = 1000 * 60 * 3 // check if the user is authed every 3 minutes

export class UserActivityAuthRefresher extends Component {
  static propTypes = {
    children: PropTypes.any,
    logout: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    expires: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    lastSuccessfulAuthentication: PropTypes.number,
    userId: PropTypes.string,
    orgId: PropTypes.string,
    email: PropTypes.string,
    roleType: PropTypes.string,
  }

  constructor (props) {
    super(props)
    this.state = {
      lastActive: 0,
    }
    this.handleUserActivity = debounce(
      () => this.setState({ lastActive: Date.now() }),
      USER_ACTIVITY_WAIT_MS,
      {
        leading: true,
        trailing: false,
        maxWait: USER_ACTIVITY_MAX_WAIT_MS,
      },
    )
    this.checkAuthInterval = setInterval(this.checkAuth.bind(this), CHECK_AUTH_INTERVAL)
  }

  checkAuth () {
    const {
      logout,
      accessToken,
      expires,
      isLoading,
      isRefreshing,
      userId,
      orgId,
      email,
      roleType,
    } = this.props
    const isAuthed = isAuthenticated({
      accessToken,
      expires,
      userId,
      orgId,
      email,
      roleType,
    })
    // if we were previously authed, but are no longer, logout
    if (!isAuthed && accessToken && !isLoading && !isRefreshing) {
      clearInterval(this.checkAuthInterval)
      logout()
    }
  }

  componentDidUpdate () {
    const {
      logout,
      refresh,
      accessToken,
      refreshToken,
      expires,
      isLoading,
      isRefreshing,
      lastSuccessfulAuthentication,
      userId,
      orgId,
      email,
      roleType,
    } = this.props
    const { lastActive } = this.state
    const now = Date.now()
    const isActive = (now - lastActive < IS_ACTIVE_THRESHOLD_MS)
    const isAuthed = isAuthenticated({
      accessToken,
      expires,
      userId,
      orgId,
      email,
      roleType,
    })
    // if we were previously authed, but are no longer, logout
    if (!isAuthed && accessToken && !isLoading && !isRefreshing) {
      clearInterval(this.checkAuthInterval)
      return logout()
    }
    const isTimeToRefresh = (expires - now < REFRESH_WINDOW_MS) &&
      (now - lastSuccessfulAuthentication > MIN_AUTH_WAIT_TIME_MS)
    if (isAuthed && isActive && isTimeToRefresh && !!refreshToken && !isLoading && !isRefreshing) {
      refresh({ refreshToken })
    }
  }

  componentWillUnmount () {
    clearInterval(this.checkAuthInterval)
  }

  render () {
    return (
      <div
        style={{ height: '100%' }}
        onMouseMove={this.handleUserActivity}
        onKeyDown={this.handleUserActivity}>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state.authentication
}
const mapDispatchToProps = { logout, refresh }
const UserActivityAuthRefresherContainer = connect(mapStateToProps, mapDispatchToProps)(UserActivityAuthRefresher)

export default UserActivityAuthRefresherContainer
