import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
  List,
  Checkbox,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import '../../common.css'
import './staff-help-modal.css'

const StaffHelpModal = ({
  onClose,
  onOptionSelect,
  open,
  worksWithStudents,
  hasRooms,
  isAdmin,
  t,
}) => {
  const [ hideForever, setHideForever ] = useState(false)
  return (
    <Modal
      className='staff-help-modal'
      closeOnDimmerClick={false}
      closeIcon={true}
      open={open}
      onClose={() => onClose(hideForever)}
    >
      <Modal.Content style={{ display: 'flex' }}>
        <div style={{ flexGrow: 2 }}>
          <Header data-public style={{ marginTop: 0 }}>
            <Icon style={{ fontSize: '2.5em' }} className='base-teal' name='handshake' />
            <Header.Content>
              Hi, Welcome to BASE.
              <Header.Subheader>What would you like to do today?</Header.Subheader>
            </Header.Content>
          </Header>
          <div className='text bold grey'>Please select below:</div>
          <List relaxed selection size='big'>
            {(worksWithStudents) && (
              <List.Item onClick={() => onOptionSelect('dashboard', hideForever)} tabIndex={0}>
                <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
                <List.Content data-public>
                  <List.Header>{(hasRooms) ? `Check on my students' progress` : `Get my students set up`}</List.Header>
                  <List.Description>{(hasRooms) ? `View your dashboard or jump into a room to see how your students are doing` : `Create a room where you can begin to recommend modules to your own students`}</List.Description>
                </List.Content>
              </List.Item>
            )}
            <List.Item onClick={() => onOptionSelect('users', hideForever)} tabIndex={0}>
              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
              <List.Content data-public>
                <List.Header>View a specific student{`'`}s information</List.Header>
                <List.Description>Search for a specific student and view their progress and responses across all rooms.</List.Description>
              </List.Content>
            </List.Item>
            <List.Item onClick={() => onOptionSelect('courses', hideForever)} tabIndex={0}>
              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
              <List.Content data-public>
                <List.Header>Access module materials</List.Header>
                <List.Description>Launch a course, download educator guides, or view information about a course</List.Description>
              </List.Content>
            </List.Item>
            <List.Item onClick={() => onOptionSelect('activity', hideForever)} tabIndex={0}>
              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
              <List.Content data-public>
                <List.Header>View student activity reports</List.Header>
                <List.Description>Learn how often your students are using BASE</List.Description>
              </List.Content>
            </List.Item>
            <List.Item onClick={() => onOptionSelect('settings', hideForever)} tabIndex={0}>
              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
              <List.Content data-public>
                <List.Header>{(isAdmin) ? 'Manage' : 'View'} my organization settings</List.Header>
                <List.Description>{(isAdmin) ? 'Manage' : 'View'} when your students can log in to BASE</List.Description>
              </List.Content>
            </List.Item>
          </List>
          <Checkbox
            data-public
            checked={hideForever}
            onChange={(e, data) => setHideForever(data.checked)}
            label={`Don't show this again`}
          />
        </div>
        {/* <div style={{
          flexShrink: 2, width: 80, height: 80, marginRight: 10,
        }}>
          <Image src='https://media.base.education/img/base-guide-2.png' />
        </div> */}
      </Modal.Content>
    </Modal>
  )
}

StaffHelpModal.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  open: PropTypes.bool,
  worksWithStudents: PropTypes.bool,
  isAdmin: PropTypes.bool,
  hasRooms: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func,
}

StaffHelpModal.defaultProps = {
  onOptionSelect: console.log.bind(console, 'onOptionSelect'),
  isSaving: false,
  hasRooms: false,
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(StaffHelpModal)
