import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  Button,
  Header,
  Icon,
  Popup,
  Menu,
  Accordion,
  Divider,
  List,
  Label,
} from 'semantic-ui-react'
import {
  map,
  flatMap,
  filter,
  find,
  sortBy,
  debounce,
  every,
} from 'lodash'

import RemoteSearchInput from '../remote-search-input'
import OrgTree from './org-tree'
import { getOrgDetails } from '../../../helpers/organization'
import ClassLinkLogo from '../logos/class-link'
import CleverLogo from '../logos/clever'

import './sidebar.css'
import '../../common.css'

const getAllChildren = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return []
  }
  const mappedChildren = map(childOrgs, (child) => ({
    id: child.id,
    name: child.name,
    children: child.children,
    isActive: child.isActive,
    contractStartDate: child.contractStartDate,
    contractEndDate: child.contractEndDate,
    type: child.type,
  }))
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  return mappedChildren.concat(getAllChildren(children))
}

const markOrg = (searchText, organization) => {
  const showChild = (!organization.children) ? false : !!organization.children.filter(markOrg.bind(null, searchText)).length
  organization.show = (!searchText || organization.name.toLowerCase().includes(searchText) || showChild)
  return organization.show
}

const SHOW_MORE_INCREMENT = 100
const POPUP_MOUSEOVER_DELAY_MS = 300
const SEARCH_SESSION_STORAGE_KEY = 'education.base.admin.sidebar-search'
const FOCUSED_ORG_SESSION_STORAGE_KEY = 'education.base.admin.sidebar-focused-org'
const SHOW_COUNT_SESSION_STORAGE_KEY = 'education.base.admin.sidebar-show-count'
const SCROLL_POS_SESSION_STORAGE_KEY = 'education.base.admin.sidebar-scroll-pos'
const SHOW_ORGS_SESSION_STORAGE_KEY = 'education.base.admin.sidebar-show-orgs'

const Sidebar = ({
  render,
  organization,
  selectedOrgId,
  onOrganizationClick,
  onMenuItemClick,
  selectedMenuItem,
  hideRoomsMenuItem,
  currentOrgActive,
  hideBaselineMenuItem,
  onChange,
  defaultOpen,
  isSearching,
  onSearchChange,
  searchResults,
  hasMoreSearchResults,
  isInternalUser,
}) => {
  const sidebarSearch = window.sessionStorage.getItem(SEARCH_SESSION_STORAGE_KEY)
  const sidebarFocusedOrg = window.sessionStorage.getItem(FOCUSED_ORG_SESSION_STORAGE_KEY)
  const sidebarShowOrgs = window.sessionStorage.getItem(SHOW_ORGS_SESSION_STORAGE_KEY)
  const sidebarShowCount = window.sessionStorage.getItem(SHOW_COUNT_SESSION_STORAGE_KEY) || SHOW_MORE_INCREMENT
  const sidebarScrollPos = window.sessionStorage.getItem(SCROLL_POS_SESSION_STORAGE_KEY) || 0

  const menuRef = useRef(null)
  const debouncedSetScrollPos = useRef(debounce((val) => window.sessionStorage.setItem(SCROLL_POS_SESSION_STORAGE_KEY, val), 500))
  const [ isOpen, setIsOpen ] = useState(defaultOpen)
  const [ orgTreeIsOpen, setOrgTreeIsOpen ] = useState(!!sidebarShowOrgs)
  const [ showCount, setShowCount ] = useState(sidebarShowCount * 1)
  const [ searchText, setSearchText ] = useState(sidebarSearch || '')
  const [ focusedOrgId, setFocusedOrgId ] = useState(sidebarFocusedOrg)
  const [ viewInactive, setviewInactive ] = useState(!currentOrgActive)
  const renderCount = { count: 0, hasMore: false }

  // Runs once
  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.scrollTop = sidebarScrollPos * 1
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderOrg = (organization) => {
    if (organization.show) {
      renderCount.count += 1
    } else {
      return null
    }
    if (!organization.isActive && !viewInactive && organization.id !== selectedOrgId) {
      return null
    }
    if (renderCount.count > showCount) {
      renderCount.hasMore = true
      return null
    }
    const hasChildren = (organization.children && !!organization.children.length)
    const orgDetails = getOrgDetails(organization)
    return (
      <OrgTree
        {...organization}
        key={organization.id}
        selected={(organization.id === selectedOrgId)}
        onClick={() => onOrganizationClick(organization)}
        icon={orgDetails.icon}
        ssoType={orgDetails.ssoType}
        ssoId={orgDetails.ssoId}
      >
        {hasChildren && sortBy(organization.children, 'name').map(renderOrg)}
      </OrgTree>
    )
  }

  let orgName = 'Loading...'
  let startingOrg = organization
  let allChildrenActive = (startingOrg.children && startingOrg.children.length) ? every(startingOrg.children, { isActive: true }) : true
  if (organization && selectedOrgId === organization.id) {
    orgName = organization.name
  } else if (organization) {
    const allOrgs = [ { id: organization.id, name: organization.name }, ...getAllChildren(organization.children) ]
    const matchingOrg = find(allOrgs, { id: selectedOrgId })
    allChildrenActive = (matchingOrg.children && matchingOrg.children.length) ? every(matchingOrg.children, { isActive: true }) : true
    orgName = (matchingOrg) ? matchingOrg.name : 'Unknown Organization'
    startingOrg = (focusedOrgId) ? find(allOrgs, { id: focusedOrgId }) : organization
  }

  if (startingOrg) {
    markOrg(searchText, startingOrg)
  }
  const hasChildren = (organization && organization.children && !!organization.children.length)
  const setSideBarIsOpen = (isOpen) => {
    setIsOpen(isOpen)
    onChange && onChange(isOpen)
  }
  return (
    <div className={(isOpen) ? 'sidebar-container open' : 'sidebar-container'}>
      <div className='sidebar-new no-print'>
        <div className='sidebar-top'>
          <Popup
            hoverable
            content={orgName}
            position='bottom center'
            size='mini'
            mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
            trigger={(
              <Header data-public className='sidebar-header text clip'>{orgName}</Header>
            )}
          />

          <Button
            className='sidebar-trigger-container'
            onClick={() => setSideBarIsOpen(!isOpen)}
          >
            <div className='sidebar-trigger'>
              <div></div>
            </div>
          </Button>
        </div>
        <hr />
        {(!isOpen) && (

          <Button.Group className='mini-buttons' vertical>
            <Popup
              hoverable
              content='Home'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'home'}
                  onClick={() => onMenuItemClick('home')}
                >
                  <Icon className='base-green' name='home' />
                </Button>
              )}
            />
            <Popup
              hoverable
              content='Users'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'users'}
                  onClick={() => onMenuItemClick('users')}
                >
                  <Icon className='base-green' name='user' />
                </Button>
              )}
            />
            <Popup
              hoverable
              content='Modules'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'modules'}
                  onClick={() => onMenuItemClick('modules')}
                >
                  <Icon style={{ height: 25, width: 25 }} className='base-green fas fa-chalkboard' />
                </Button>
              )}
            />
            <Popup
              hoverable
              content='All Rooms'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'rooms'}
                  onClick={() => onMenuItemClick('rooms')}
                >
                  <Icon style={{ height: 25, width: 25 }} className='base-green fas fa-door-open' />
                </Button>
              )}
            />
            <Popup
              hoverable
              content='Activity'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'activity'}
                  onClick={() => onMenuItemClick('activity')}
                >
                  <Icon className='base-green' name='line chart' />
                </Button>
              )}
            />
            {(!hideBaselineMenuItem) && (
              <Popup
                hoverable
                content='Baseline'
                disabled={isOpen}
                position='right center'
                size='small'
                mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
                trigger={(
                  <Button
                    icon
                    active={selectedMenuItem === 'baseline'}
                    onClick={() => onMenuItemClick('baseline')}
                  >
                    <Icon className='base-green' name='area chart' />
                  </Button>
                )}
              />
            )}
            <Popup
              hoverable
              content='Settings'
              disabled={isOpen}
              position='right center'
              size='small'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <Button
                  icon
                  active={selectedMenuItem === 'settings'}
                  onClick={() => onMenuItemClick('settings')}
                >
                  <Icon className='base-green' name='setting' />
                </Button>
              )}
            />
          </Button.Group>
        )}
        {(isOpen) && (
          <div className='sidebar-menu-container no-print'>
            <div className='sidebar-main-menu'>
              <div className='sidebar-main-menu-top'>
                <Menu fluid secondary vertical>
                  <Menu.Item
                    data-public
                    active={selectedMenuItem === 'home'}
                    onClick={() => onMenuItemClick('home')}
                  >
                    <Icon className='base-green' name='home' /> Home
                  </Menu.Item>
                </Menu>
                <Divider style={{ margin: '.5rem 0' }} />
                <Menu fluid secondary vertical>
                  <Menu.Item
                    data-public
                    active={selectedMenuItem === 'users'}
                    onClick={() => onMenuItemClick('users')}
                  >
                    <Icon className='base-green' name='user' /> Users
                  </Menu.Item>
                  <Menu.Item
                    data-public
                    active={selectedMenuItem === 'modules'}
                    onClick={() => onMenuItemClick('modules')}
                  >
                    <Icon style={{ marginRight: 3 }} className='base-green fas fa-chalkboard' /> Modules
                  </Menu.Item>
                  {(!hideRoomsMenuItem) && (
                    <Menu.Item
                      data-public
                      active={selectedMenuItem === 'rooms'}
                      onClick={() => onMenuItemClick('rooms')}
                    >
                      <Icon style={{ marginRight: 3 }} className='base-green fas fa-door-open' /> All Rooms
                    </Menu.Item>
                  )}
                  <Menu.Item
                    data-public
                    active={selectedMenuItem === 'activity'}
                    onClick={() => onMenuItemClick('activity')}
                  >
                    <Icon className='base-green' name='line chart' /> Activity
                  </Menu.Item>
                  {(!hideBaselineMenuItem) && (
                    <Menu.Item
                      data-public
                      active={selectedMenuItem === 'baseline'}
                      onClick={() => onMenuItemClick('baseline')}
                    >
                      <Icon className='base-green' name='area chart' /> Baseline
                    </Menu.Item>
                  )}
                  <Menu.Item
                    data-public
                    active={selectedMenuItem === 'settings'}
                    onClick={() => onMenuItemClick('settings')}
                  >
                    <Icon className='base-green' name='setting' /> Settings
                  </Menu.Item>
                </Menu>
                <Divider style={{ margin: '.5rem 0' }} />
              </div>
              {(hasChildren) && (
                <Accordion as={Menu} fluid secondary vertical className={(hideBaselineMenuItem && hideRoomsMenuItem) ? 'no-baseline no-rooms' : (hideBaselineMenuItem) ? 'no-baseline' : (hideRoomsMenuItem) ? 'no-rooms' : ''}>
                  <Menu.Item>
                    <Accordion.Title
                      data-public
                      active={orgTreeIsOpen}
                      content={<>
                        <Icon className='base-green' name='university' /> Organizations
                      </>}
                      index={0}
                      onClick={() => {
                        if (orgTreeIsOpen) {
                          window.sessionStorage.removeItem(SHOW_ORGS_SESSION_STORAGE_KEY)
                        } else {
                          window.sessionStorage.setItem(SHOW_ORGS_SESSION_STORAGE_KEY, 1)
                        }
                        setOrgTreeIsOpen(!orgTreeIsOpen)
                      }}
                    />
                    <Accordion.Content active={orgTreeIsOpen}>
                      <div className='sidebar-search'>
                        <RemoteSearchInput
                          fluid
                          placeholder='Search Organizations...'
                          isLoading={isSearching}
                          popupWidth={275}
                          searchResults={searchResults.map((result) => ({ ...result, ...getOrgDetails(result) }))}
                          isHidden={!orgTreeIsOpen}
                          showRefineSearchMessage={hasMoreSearchResults}
                          onChange={onSearchChange}
                          onSelect={(org) => {
                            if (focusedOrgId) {
                              window.sessionStorage.removeItem(FOCUSED_ORG_SESSION_STORAGE_KEY)
                              setFocusedOrgId(null)
                            }
                            onOrganizationClick(org)
                          }}
                          renderItemContent={(item) => {
                            const isPastContract = (item.isActive && item.contractEndDate && new Date(item.contractEndDate) < new Date())
                            const orgDetails = getOrgDetails(item)
                            const icon = orgDetails.icon
                            const ssoType = orgDetails.ssoType
                            const ssoElement = (ssoType === 'classlink')
                              ? <ClassLinkLogo height={16} width={16} />
                              : (ssoType === 'clever')
                                ? <CleverLogo height={16} width={16} />
                                : null
                            return (
                              <>
                                <List.Content style={{ display: 'flex', alignItems: 'center' }}>
                                  <div style={{ flexShrink: 2 }}><Icon style={{ minWidth: 20, padding: 0 }} name={icon}/></div>
                                  <div style={{ flexGrow: 2, marginRight: 4 }}>{item.name}</div>
                                  <div style={{ flexShrink: 2, marginRight: 4 }}>{ssoElement}</div>
                                  <div style={{ flexShrink: 2 }}><Label
                                    empty
                                    circular
                                    color={(isPastContract) ? 'yellow' : (item.isActive) ? 'green' : 'red'}
                                  />
                                  </div>
                                </List.Content>
                              </>
                            )
                          }}
                        />
                        {isInternalUser && (!allChildrenActive || !currentOrgActive) && (<Popup
                          content={(viewInactive) ? 'Hide inactive organizations' : 'Show inactive organizations'}
                          position='bottom center'
                          size='mini'
                          mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
                          trigger={(
                            <Button
                              className='sidebar-focus-button text white'
                              icon
                              size='tiny'
                              color={(viewInactive) ? 'base-teal-bg' : 'grey'}
                              onClick={() => { setviewInactive(!viewInactive) }}
                            >
                              <Icon name='sitemap'/>
                            </Button>
                          )}
                        />)}
                        {(organization && organization.id !== selectedOrgId) && (
                          <Popup
                            content={(focusedOrgId) ? 'Return focus to my organization' : 'Focus on selected organization'}
                            position='bottom center'
                            size='mini'
                            mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
                            trigger={(
                              <Button
                                className='sidebar-focus-button text white'
                                icon
                                style={{ marginLeft: 3 }}
                                size='tiny'
                                color={(focusedOrgId) ? 'base-teal-bg' : 'grey'}
                                onClick={() => {
                                  // TODO: move the sidebar org list to it's own component so it doesn't force a re-render on the main content.
                                  if (focusedOrgId) {
                                    window.sessionStorage.removeItem(FOCUSED_ORG_SESSION_STORAGE_KEY)
                                    setFocusedOrgId(null)
                                  } else {
                                    window.sessionStorage.setItem(FOCUSED_ORG_SESSION_STORAGE_KEY, selectedOrgId)
                                    setFocusedOrgId(selectedOrgId)
                                  }
                                  window.sessionStorage.setItem(SEARCH_SESSION_STORAGE_KEY, '')
                                  setSearchText('')
                                }}
                              >
                                <Icon name={(focusedOrgId) ? 'eye slash' : 'eye'}/>
                              </Button>
                            )}
                          />
                        )}
                      </div>
                      <div ref={menuRef} className='sidebar-menu' onScroll={(e) => debouncedSetScrollPos.current(e.target.scrollTop)}>
                        <div className='organizations-list'>
                          {(startingOrg) && renderOrg(startingOrg)}
                        </div>
                        {(renderCount.hasMore) && (
                          <div className='show-more-container'>
                            <Button basic size='mini' onClick={() => {
                              window.sessionStorage.setItem(SHOW_COUNT_SESSION_STORAGE_KEY, showCount + SHOW_MORE_INCREMENT)
                              setShowCount(showCount + SHOW_MORE_INCREMENT)
                            }}>Show More...</Button>
                          </div>
                        )}
                      </div>
                    </Accordion.Content>
                  </Menu.Item>
                </Accordion>
              )}
            </div>
          </div>
        )}

        <div className='sidebar-logo'>
          <Image className='open-sidebar-logo' src={'https://media.base.education/img/base-7m-logo-color-no-solution.jpg'} />
          <Image className='closed-sidebar-logo' src={'https://media.base.education/img/base-7m-new-favicon.png'} />
        </div>
      </div>
      <main className='main-content no-margin-print'>
        {render(setSideBarIsOpen)}
      </main>
    </div>
  )
}

Sidebar.propTypes = {
  render: PropTypes.func.isRequired,
  organization: PropTypes.object,
  selectedOrgId: PropTypes.string,
  isInternalUser: PropTypes.bool,
  onOrganizationClick: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  selectedMenuItem: PropTypes.string,
  onChange: PropTypes.func,
  defaultOpen: PropTypes.bool,
  hideRoomsMenuItem: PropTypes.bool,
  currentOrgActive: PropTypes.bool,
  hideBaselineMenuItem: PropTypes.bool,
  isSearching: PropTypes.bool,
  onSearchChange: PropTypes.func,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  hasMoreSearchResults: PropTypes.bool,
}

Sidebar.defaultProps = {
  onOrganizationClick: console.log.bind(console, 'onOrganizationClick'),
  onMenuItemClick: console.log.bind(console, 'onMenuItemClick'),
  onChange: console.log.bind(console, 'onChange'),
  selectedMenuItem: 'home',
  defaultOpen: true,
  onSearchChange: console.log.bind(console, 'onSearchChange'),
}

export default Sidebar
