import gql from 'graphql-tag'
const GET_COURSES = gql`
query coursesByType($type: String!, $isActive: Boolean, $first: PositiveInt, $after: ID) {
  coursesByType(
    type: $type
    isActive: $isActive
    first: $first
    after: $after
  ) {
      courses {
        id
        title {
          en
        }
        description {
          en
        }
        guides {
          name { en }
          url { en }
        }
        videoUrl {
          en
          en_sub
          es_sub
          ar_sub
          fr_sub
          ko_sub
          zh_sub
          hi_sub
          uk_sub
          ru_sub
          pl_sub
          pa_sub
          fa_sub
        }
        imageUrl
        tags
        type
        isActive
        isInstructional
        created
        updated
      }
      endCursor
      hasNextPage
    }
}
`

export default GET_COURSES
