import React from 'react'
import PropTypes from 'prop-types'
import to from 'await-to-js'
import {
  Dropdown,
  Segment,
  Header,
  Popup,
  Icon,
  Message,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { ResponsiveBar } from '@nivo/bar'
import { get, keyBy } from 'lodash'

import exportToCsv from '../../../helpers/export-to-csv'
import Query from '../../common/query/query'
import GET_COURSES from '../../../helpers/graphql-queries/get-courses'
import GET_ORG_ACTIVITY from '../../../helpers/graphql-queries/get-org-activity'

import './video-usage.css'
import GET_STAFF_BY_ORG_ID from '../../../helpers/graphql-queries/get-staff-by-org-id'
import { getDisplayName } from '../../../helpers/user'

const TRANSLATION_PREFIX = 'charts.video-usage'

class VideoUsageChart extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedTimePeriod: 7,
    }
  }

  static propTypes = {
    courseTitlesById: PropTypes.object,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    forRoom: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    videoUsage: PropTypes.object,
    orgId: PropTypes.string,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    courseTitlesById: {},
    isLoading: false,
    width: '100%',
  }

  changeTimePeriod = (data) => {
    this.setState({ ...this.state, selectedTimePeriod: data.value })
  }

  colorBy = (d) => d.data[`${d.id}Color`]

  downloadData = async (courseTitlesById, courseTypesById, staffDict, refetchActivity) => {
    const {
      selectedTimePeriod,
    } = this.state

    const today = new Date()
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - selectedTimePeriod).toISOString().split('T')[0]
    const [ dataErr, data ] = await to(refetchActivity({ startDate }))
    if (dataErr) {
      console.error(dataErr)
    }
    const courseData = get(data, 'data.activitiesByOrgId').map((activity) => {
      const courseTitle = courseTitlesById[activity.courseId]
      const courseType = courseTypesById[activity.courseId]
      return {
        clientId: activity.clientId,
        displayName: getDisplayName(staffDict[activity.clientId]) || 'N/A',
        courseTitle,
        courseType,
        launches: activity.launches,
      }
    })

    const date = new Date().toISOString().split('T')[0]

    exportToCsv(`BASE-video-activity-${date}-last-${selectedTimePeriod}-days.csv`, courseData, [ 'clientId', 'displayName', 'courseTitle', 'courseType', 'launches' ])
  }

  render () {
    const {
      isLoading,
      width,
      height,
      forRoom,
      t,
      videoUsage,
      orgId,
    } = this.props
    const {
      selectedTimePeriod: period,
    } = this.state
    const daysToTimePeriod = {
      '7': 'weekCounts', '30': 'monthCounts', '90': 'quarterCounts',
    }
    const dataByCourseIdMinutes = {}
    const dataByCourseIdHours = {}
    const maxValue = 0

    const data = (maxValue > 180) ? dataByCourseIdHours : dataByCourseIdMinutes

    const xAxisLabel = t(`${TRANSLATION_PREFIX}.y_axis_label`)
    const minHeightPerBar = 40
    Object.keys(data).forEach((courseId) => {
      const totalTime = Object.keys(data[courseId]).reduce((sum, roleName) => {
        if (typeof data[courseId][roleName] !== 'number') {
          return sum
        }
        return sum + data[courseId][roleName]
      }, 0)
      if (!totalTime) {
        delete data[courseId]
      }
    })
    const dropdownOptions = [
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_week`), value: 7 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_30_days`), value: 30 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_90_days`), value: 90 },
    ]
    return (
      <Query
        variables={{
          type: 'wellness',
          isActive: true,
          first: 100,
        }}
        query={GET_COURSES}
      >{({
          data: wellnessCourseData,
        }) => {
          const wellnessCourses = get(wellnessCourseData, 'coursesByType.courses') || []

          return (
            <Query
              variables={{
                orgId,
              }}
              query={GET_STAFF_BY_ORG_ID}
            >{({
                data: staffByOrgId,
              }) => {
                const staffDict = staffByOrgId ? keyBy(staffByOrgId.staffByOrgId, 'id') : {}
                return (<Query
                  variables={{
                    type: 'video',
                    orgId,
                  }}
                  skip={true}
                  query={GET_ORG_ACTIVITY}
                >{({
                    data: orgActivityData,
                    refetch: refetchActivity,
                  }) => {
                    return (
                      <Query
                        variables={{
                          type: 'elementary',
                          isActive: true,
                          first: 100,
                        }}
                        query={GET_COURSES}
                      >{({
                          data: courseData,
                        }) => {
                          const elementaryCourses = get(courseData, 'coursesByType.courses') || []
                          const allVideoCourses = [ ...wellnessCourses, ...elementaryCourses ]
                          const courseTitlesById = {}
                          const courseTypesById = {}
                          allVideoCourses.forEach((c) => {
                            courseTitlesById[c.id] = c.title.en
                            courseTypesById[c.id] = c.type
                          })
                          const data = (videoUsage && videoUsage[daysToTimePeriod[period]].map((d) => {
                            return {
                              courseTitle: courseTitlesById[d.courseId], Launches: d.launches || 1, LaunchesColor: '#B8D6C2',
                            }
                          })) || []
                          const hasData = data.length > 0
                          const idealHeight = (data.length * minHeightPerBar) + 60 || 0
                          return (
                            <Segment.Group className='videoUsage' >
                              <Segment loading={isLoading}>
                                <div style={{
                                  display: 'flex', alignItems: 'center', alignContent: 'flex-start',
                                }}>
                                  <Header style={{ margin: 0 }} data-public className='usageHeader'>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
                                  <Popup
                                    trigger={(
                                      <div style={{
                                        paddingLeft: 8, flexGrow: 2, fontSize: '1.2em',
                                      }}>
                                        <Icon className='base-teal' name='help circle' />
                                      </div>
                                    )}
                                    position='bottom left'
                                    hoverable
                                  >
                                    <p data-public>{t((forRoom) ? `${TRANSLATION_PREFIX}.help.first_sentence_room` : `${TRANSLATION_PREFIX}.help.first_sentence`)}</p>
                                    <p data-public>{t(`${TRANSLATION_PREFIX}.help.second_sentence`)}</p>
                                  </Popup>
                                  <div>
                                    {t(`${TRANSLATION_PREFIX}.time_period_prefix`) + ' '}
                                    <Dropdown
                                      data-public
                                      className='video-usage-input'
                                      inline
                                      options={dropdownOptions}
                                      defaultValue={this.state.selectedTimePeriod}
                                      onChange={(_, data) => this.changeTimePeriod(data)}
                                    />
                                  </div>
                                  <Button
                                    data-public
                                    className='downloadButton'
                                    basic
                                    size='small'
                                    icon='download'
                                    title={t(`${TRANSLATION_PREFIX}.download_button_label`)}
                                    onClick={() => this.downloadData(courseTitlesById, courseTypesById, staffDict, refetchActivity) }
                                  />
                                </div>
                                {!hasData && !isLoading && <Message info>
                                  <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.no_activity_header`)}</Message.Header>
                                  <p data-public>{t(`${TRANSLATION_PREFIX}.no_activity_description`)}</p>
                                </Message>}
                                {hasData && <div style={{
                                  height: height || idealHeight,
                                  width,
                                }}>
                                  <ResponsiveBar
                                    padding={0.25}
                                    layout='horizontal'
                                    margin={{
                                      top: 10,
                                      right: 10,
                                      bottom: 40,
                                      left: 150,
                                    }}
                                    // onClick={this.handleChartClick}
                                    colors={this.colorBy}
                                    labelSkipHeight={15}
                                    labelSkipWidth={60}
                                    borderWidth={1}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', '1.1' ] ] }}
                                    axisLeft={{
                                      orient: 'left',
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                    }}
                                    valueFormat={(value) => `${value} ${(value === 1) ? xAxisLabel.split(' ')[0].replace('es', '') : xAxisLabel.split(' ')[0]}`}
                                    axisBottom={{
                                      orient: 'bottom',
                                      tickSize: 5,
                                      tickPadding: 5,
                                      legend: xAxisLabel,
                                      legendPosition: 'middle',
                                      legendOffset: 30,
                                    }}
                                    data={data}
                                    animate={false}
                                    indexBy='courseTitle'
                                    keys={[ 'Launches' ]}
                                  />
                                </div>}
                              </Segment>
                            </Segment.Group>
                          )
                        }}
                      </Query>
                    )
                  }}
                </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>

    )
  }
}

export default translate([ 'components' ])(VideoUsageChart)
