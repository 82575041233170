import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  Label,
  Input,
  Rating,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { reject } from 'lodash'

import './fireword-segment.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.fireword-segment'
const severityColors = [ 'green', 'yellow', 'orange', 'red' ]

const FirewordSegment = ({
  analyzedAnswer,
  analyzedCaptures,
  onReviewedClick,
  onIgnisRate,
  previousRating,
  isSaving,
  t,
}) => {
  // const labelText = t(`${TRANSLATION_PREFIX}.${type}_label`)
  const relevantCaptures = reject(analyzedCaptures, { isSafe: true })
  const relevantSeverities = relevantCaptures.map((capture) => { return capture.ignisSeverity || capture.receptivitiSeverity || 3 })
  const maxSeverity = Math.max(...relevantSeverities)
  const classes = 'fireword-segment ' + severityColors[maxSeverity] + ((analyzedAnswer) ? ' reviewed' : '')
  const firewordsLabel = t('resource_types.fireword', { count: relevantCaptures.length, postProcess: 'titleCase' })
  const reviewedByLabel = t(`${TRANSLATION_PREFIX}.reviewed_by`)
  const reasonLabel = t(`${TRANSLATION_PREFIX}.reason`)
  const reviewLabel = (!analyzedAnswer)
    ? t(`${TRANSLATION_PREFIX}.review_labels.needs_review`)
    : t(`${TRANSLATION_PREFIX}.review_labels.reviewed`)
  const [ isSafeReason, setIsSafeReason ] = useState('')
  const [ ratingSubmitted, setRatingSubmitted ] = useState(false)

  // this checks to see if the analyzed answer was created before we removed the Safe/Not Safe option
  const reviewNotes = (!analyzedAnswer)
    ? ''
    : (new Date(analyzedAnswer.created) < new Date('2019-11-02'))
      ? `(Marked As: ${(analyzedAnswer.isSafe) ? 'Safe' : 'Not Safe'}) ${analyzedAnswer.isSafeReason}`
      : analyzedAnswer.isSafeReason
  if (relevantCaptures.length === 0) {
    return null
  }
  return (
    <div className={classes}>
      <div data-public className='segment-title'>
        <Label color='black' circular>{relevantCaptures.length}</Label>
        {firewordsLabel}
        <span data-public className='safe-text'>{reviewLabel}</span>
      </div>
      <div className='segment-content'>
        <List>
          {relevantCaptures.map(function (capture, i) {
            if (capture.isSafe) {
              return null
            }
            return (
              <List.Item key={capture.sentence.replace(/\s/g, '_') + i}>
                <List.Icon name='fire' />
                <List.Content>
                  <React.Fragment>
                    {(!!capture.ignisSeverity || !!capture.receptivitiSeverity) && <List.Header>Flagged sentence/phrase: </List.Header>}

                    {!capture.ignisSeverity && !capture.receptivitiSeverity && capture.matchedPhrases[0] &&
                    <React.Fragment>
                      <List.Header>{capture.matchedPhrases[0].matchedSentenceSegment}</List.Header>
                      <List.Description className='text italic'>
                        Matches word/phrase: {capture.matchedPhrases[0].matchedPhraseText.replace(/\{\d+\}/g, '').replace(/\s+/g, ' ')}
                      </List.Description>
                    </React.Fragment>}
                  </React.Fragment>
                  <List.Description>
                    &quot;{capture.sentence}&quot;
                  </List.Description>
                </List.Content>
              </List.Item>
            )
          })}
        </List>
        {analyzedAnswer && <div className='reviewer-info'>
          <div><strong data-public>{reviewedByLabel}: </strong> {analyzedAnswer.reviewer.displayName || analyzedAnswer.reviewer.userName}</div>
          <div><strong data-public>{reasonLabel}: </strong> {reviewNotes}</div>
        </div>}
        {!analyzedAnswer && (
          <React.Fragment>
            <Input
              className='no-print'
              fluid
              action={{
                loading: isSaving,
                labelPosition: 'right',
                icon: 'check',
                content: 'Mark as Reviewed',
                onClick: () => onReviewedClick(isSafeReason || 'N/A'),
              }}
              onChange={(e, data) => setIsSafeReason(data.value)}
              placeholder={'Write Optional Notes Here'}
            />
          </React.Fragment>
        )}

        <List.Description className='text bold italic' style={{ marginTop: 15 }}>
          How accurate is this Fireword severity?
          <Rating icon='star'
            disabled={ratingSubmitted || !!previousRating }
            defaultRating={!previousRating ? 0 : previousRating.rating}
            maxRating={5} style={{ marginLeft: 10, marginRight: 5 }} onRate={(e, { rating }) => { setRatingSubmitted(true); onIgnisRate(rating) }}/>
          <Popup
            hoverable
            position='right center'
            header='Was this flagged accurately?'
            content={(
              <>
                <p></p>
                <p data-public>One star means that you think this is a very innacurate severity rating, and five stars means you think the severity is fully accurate for the student response.</p>
                <p data-public>Our system learns from your feedback, and each rating, positive or negative, helps our system improve.</p>
              </>
            )}
            trigger={(
              <div style={{ display: 'inline' }}>
                <Icon className='base-teal' name='info circle' />
              </div>)}
          />
        </List.Description>

      </div>
    </div>
  )
}

FirewordSegment.propTypes = {
  analyzedAnswer: PropTypes.object,
  analyzedCaptures: PropTypes.arrayOf(PropTypes.object).isRequired,
  onReviewedClick: PropTypes.func,
  isSaving: PropTypes.bool,
  onIgnisRate: PropTypes.func,
  previousRating: PropTypes.object,
  t: PropTypes.func.isRequired,
}

FirewordSegment.defaultProps = {
  isSaving: false,
  onReviewedClick: console.log.bind(console, 'onReviewedClick'),
  onIgnisRate: console.log.bind(console, 'onIgnisRate'),
}

export default translate([ 'components' ])(FirewordSegment)
