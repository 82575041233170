import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
  Dropdown,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  reject,
  sortBy,
} from 'lodash'

import RoomOrgChangeModal from '../../common/room-org-change-modal/room-org-change-modal'
import RoomOrgTransferModal from '../../common/room-org-change-modal/room-org-transfer-modal'
import '../../common.css'
import './../staff-help-modal/staff-help-modal.css'

const OrgChangeModal = ({
  onClose,
  open,
  currentOrg,
  allowedOrgs,
  userIds,
  onChange,
  onLoadingChange,
  callback,
}) => {
  const [ roomTransferModalOpen, setRoomTransferModalOpen ] = useState(false)
  const [ roomChangeModalOpen, setRoomChangeModalOpen ] = useState(false)
  const [ roomTransferSelection, setRoomTransferSelection ] = useState()
  const getOrgOption = (org) => ({
    value: org.id,
    text: org.name,
  })
  if (!open) {
    return null
  }
  const formattedUserText = `${userIds.length} user${userIds.length > 1 ? 's' : ''}`

  return (
    <>
      <RoomOrgChangeModal
        open={roomChangeModalOpen}
        orgId={currentOrg.id}
        userIds={userIds}
        onClose={() => setRoomChangeModalOpen(false)}
        onTransferSelect={() => {
          setRoomTransferModalOpen(true)
          setRoomChangeModalOpen(false)
        }}
        callback={() => {
          callback()
          onLoadingChange()
        }} />
      <RoomOrgTransferModal
        open={roomTransferModalOpen}
        orgId={currentOrg.id}
        userIds={userIds}
        onClose={() => setRoomTransferModalOpen(false)}
        userTransferSelection={roomTransferSelection}
        onOptionSelect={(userId) => setRoomTransferSelection(userId)}
        callback={() => {
          callback()
          onLoadingChange()
        }}
        onBack={() => {
          setRoomChangeModalOpen(true)
          setRoomTransferModalOpen(false)
        }}
      />
      <Modal
        className='org-change-modal'
        closeOnDimmerClick={false}
        closeIcon={true}
        open={open}
        onClose={onClose}
      >
        <Modal.Content style={{ display: 'flex' }}>
          <div style={{ flexGrow: 2 }}>
            <Header data-public style={{ marginTop: 0 }}>
              <Icon style={{ fontSize: '2.5em' }} className='base-teal' name='warning sign' />
              <Header.Content>
                {`We have noticed you are trying to transfer ${formattedUserText}`}.
                <Header.Subheader>What organization would you like to assign them to?</Header.Subheader>
              </Header.Content>
            </Header>
            <div className='text bold grey'>Please select below:</div>
            <Dropdown
              className='client-account-input'
              placeholder={currentOrg.name}
              fluid
              search
              selection
              options={reject(sortBy(allowedOrgs.map(getOrgOption), (o) => o.text), { value: currentOrg.id })}
              onChange={(e, input) => onChange(input.value)}
              searchInput={{ type: 'search' }}
            />
            <Button color='green' as='a' target='new' style={{ margin: '15px 0px 0px', float: 'right' }} onClick={() => {
              if (!window.confirm(`Are you sure you want to transfer ${formattedUserText}?`)) {
                return
              }
              callback()
            }}>
              <Icon name='check'/> Submit
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>)
}

OrgChangeModal.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  currentOrg: PropTypes.object.isRequired,
  allowedOrgs: PropTypes.array.isRequired,
  userIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onLoadingChange: PropTypes.func,
  callback: PropTypes.func.isRequired,
}

export default translate([ 'components' ])(OrgChangeModal)
