import querystring from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Icon,
  Button,
  Popup,
  Message,
  Image,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  flatten, isEqual, get,
} from 'lodash'
import { Switch, Redirect } from 'react-router'
import to from 'await-to-js'

import PrivateRoute from '../../common/private-route/private-route'
import ClientsView from './clients/clients'
import CreateClientsView from './clients/create-clients'
import ClientDetailsView, { helpers as clientDetailsViewHelpers } from './client-details/client-details'
import CourseDetailsView, { helpers as courseDetailsViewHelpers } from './course-details/course-details'
import CourseEditView, { helpers as courseEditViewHelpers } from './course-details/course-edit'
import CourseEditDetailsView, { helpers as courseEditDetailsViewHelpers } from './course-details/course-edit-details'
import ActivityView from './activity/activity'
import OrgBaselineView from './baseline/baseline'
import ManageOrganizationView from './manage-organization'
import CoursesView from './courses/courses'
import AllRoomsView from './rooms/rooms'
import RoomsView from './client-details/rooms/rooms'
import RoomDetailsView, { helpers as roomDetailsHelpers } from './client-details/rooms/room-details'
import RoomDetailsStudentView, { helpers as roomDetailsStudentHelpers } from './client-details/rooms/room-details-student'
import RoomDetailsCourseView, { helpers as roomDetailsCourseHelpers } from './client-details/rooms/room-details-course'
// import getRoles from '../../../helpers/get-roles'
import buildQueryString from '../../../helpers/build-query-string'
import { invalidateClients } from '../../../actions/clients'
import { getAllCourses } from '../../../actions/courses'
import { invalidateClientActivity } from '../../../actions/client-activity'
import { invalidateCourseImages } from '../../../actions/course-images'
import { logout, setAuthProfile } from '../../../actions/authentication'
import isStale from '../../../helpers/is-stale'
import { FETCH_ACTIONS, HTTP_METHODS } from '../../../helpers/fetch-constants'
import isUnauthorizedError from '../../../helpers/is-unauthorized-error'
import fetch from '../../../helpers/fetch'
import { findParentContractedCustomerOrSiteOrg, getOrgType } from '../../../helpers/organization'
import StaffProfileModal from '../../common/staff-profile-modal'
import StaffHelpModal from '../../common/staff-help-modal'
import HelpModal from '../../common/help-modal'
import Query from '../../common/query/query'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ROOMS_BY_OWNER_USER_ID from '../../../helpers/graphql-queries/get-rooms-by-owner-user-id'
import GET_NOTIFICATIONS_ON_BY_ORG_ID from '../../../helpers/graphql-queries/get-notifications-on-by-org-id'

import './organization.css'
import { ROLE_TYPES } from '../../../helpers/get-roles'

const orgIdPathParam = ':orgId'
const path = `/organizations/${orgIdPathParam}` // Not used anymore
// const ROLES = getRoles({ externalOnly: true })

// Jest apparently doesn't like static methods on a class,
// so these need to be exported separately
export const helpers = {
  getOrgIdFromPath: (pathname) => {
    const match = pathname.match(new RegExp(`${path.replace(orgIdPathParam, '([a-f0-9]{24})').replace(/\//g, '\\/')}`))
    return (match && match[1]) ? match[1] : null
  },
  // all urls under a given org
  getOrgPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}`, // go to clients page
  getOrgClientsPath: (orgId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${(isArchived) ? ClientsView.archivedUsersPath : ClientsView.path}`,
  getOrgCreateClientsPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${CreateClientsView.path}`,
  getOrgClientDetailsPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientAccountPath(clientId, isArchived)}`,
  getOrgClientDetailsBaselinePath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientBaselinePath(clientId, isArchived)}`,
  getOrgClientDetailsCourseProgressPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientCourseProgressPath(clientId, isArchived)}`,
  getOrgClientDetailsFirewordsPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientUnreviewedFirewordsPath(clientId, isArchived)}`,
  getOrgCoursesPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${CoursesView.path}`,
  getOrgCourseDetailsPath: (orgId, courseId) => `${path.replace(orgIdPathParam, orgId)}${courseDetailsViewHelpers.getPath(courseId)}`,
  getOrgSettingsPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${ManageOrganizationView.path}`,
  getOrgActivityPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${ActivityView.path}`,
  getMyDashboardPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${RoomsView.path}`,
  getOrgRoomsPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${AllRoomsView.path}`,
  getOrgRoomDetailsPath: (orgId, roomId) => `${path.replace(orgIdPathParam, orgId)}${roomDetailsHelpers.getRoomDetailsPath(roomId)}`,
  getOrgAllRoomsDetailsPath: (orgId, roomId) => `${path.replace(orgIdPathParam, orgId)}${roomDetailsHelpers.getRoomDetailsPath(roomId, true)}`,
  getOrgRoomDetailsStudentPath: (orgId, roomId, studentId, isAllRooms) => `${path.replace(orgIdPathParam, orgId)}${roomDetailsStudentHelpers.getRoomDetailsStudentPath(roomId, studentId, isAllRooms)}`,
  getOrgRoomDetailsCoursePath: (orgId, roomId, studentId, isAllRooms) => `${path.replace(orgIdPathParam, orgId)}${roomDetailsCourseHelpers.getRoomDetailsCoursePath(roomId, studentId, isAllRooms)}`,
  getOrgEditCoursePath: (orgId, courseId) => `${path.replace(orgIdPathParam, orgId)}${courseEditViewHelpers.getPath(courseId)}`,
  getOrgEditCourseDetailsPath: (orgId, courseId, contentItemId) => `${path.replace(orgIdPathParam, orgId)}${courseEditDetailsViewHelpers.getPath(courseId, contentItemId)}`,
  getOrgBaselinePath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${OrgBaselineView.path}`,
  // getOrgRolesPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${RolesView.path}`,
}
export class OrganizationView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    homeOrg: PropTypes.object,
    currentOrg: PropTypes.object,
    invalidateClients: PropTypes.func.isRequired,
    invalidateClientActivity: PropTypes.func.isRequired,
    invalidateCourseImages: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setAuthProfile: PropTypes.func.isRequired,
    refectchChildOrgList: PropTypes.func,
    getCoursesState: PropTypes.object.isRequired,
    getAllCourses: PropTypes.func.isRequired,
  }

  static path = path

  constructor (props) {
    super(props)
    const hideStaffModalForever = !!window.localStorage.getItem(`hide-staff-help.base.education.${props.authState.userId}`)
    const hideStaffModalForSession = !!window.sessionStorage.getItem(`hide-staff-help.base.education.${props.authState.userId}`)
    this.state = {
      isSavingProfile: false,
      showStaffHelpModal: !hideStaffModalForever && !hideStaffModalForSession,
      showHelp: false,
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  navigateToPage = (searchParams) => {
    const queryStr = buildQueryString(searchParams)
    this.navigateTo(`${this.props.location.pathname}?${queryStr}`)
  }

  navigateToMyDashboard = () => this.navigateTo(helpers.getMyDashboardPath(this.props.homeOrg.id))

  navigateToClients = () => this.navigateTo(helpers.getOrgClientsPath(this.props.match.params.orgId, false))

  navigateToOrgSettings = () => this.navigateTo(helpers.getOrgSettingsPath(this.props.match.params.orgId))

  navigateToOrgCourses = () => this.navigateTo(helpers.getOrgCoursesPath(this.props.match.params.orgId))

  navigateToOrgActivity = () => this.navigateTo(helpers.getOrgActivityPath(this.props.match.params.orgId))

  navigateToOrgRooms = () => this.navigateTo(helpers.getOrgRoomsPath(this.props.match.params.orgId))

  navigateToCreateClients = () => this.navigateTo(helpers.getOrgCreateClientsPath(this.props.match.params.orgId))

  navigateToManageCourses = () => this.navigateTo(helpers.getOrgManageCoursesPath(this.props.match.params.orgId))

  navigateToClientDetails = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToClientBaseline = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsBaselinePath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToRoomDetails = (roomId, orgId) => this.navigateTo(helpers.getOrgRoomDetailsPath(orgId || this.props.match.params.orgId, roomId))

  navigateToAllRoomDetails = (roomId, orgId) => this.navigateTo(helpers.getOrgAllRoomsDetailsPath(orgId || this.props.match.params.orgId, roomId))

  navigateToRoomDetailsStudent = (roomId, studentId, orgId, isAllRooms) => this.navigateTo(helpers.getOrgRoomDetailsStudentPath(orgId || this.props.match.params.orgId, roomId, studentId, isAllRooms))

  navigateToRoomDetailsCourse = (roomId, courseId, orgId, isAllRooms) => this.navigateTo(helpers.getOrgRoomDetailsCoursePath(orgId || this.props.match.params.orgId, roomId, courseId, isAllRooms))

  navigateToClientDetailsCourseProgress = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsCourseProgressPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToClientDetailsFirewords = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsFirewordsPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToCourseDetails = (courseId) => this.navigateTo(helpers.getOrgCourseDetailsPath(this.props.match.params.orgId, courseId))

  navigateToEditCourse = (courseId) => this.navigateTo(helpers.getOrgEditCoursePath(this.props.match.params.orgId, courseId))

  navigateToEditCourseDetails = (courseId, contentItemId) => this.navigateTo(helpers.getOrgEditCourseDetailsPath(this.props.match.params.orgId, courseId, contentItemId))

  navigateToOrgBaseline = () => this.navigateTo(helpers.getOrgBaselinePath(this.props.match.params.orgId))

  renderClientsView = (org, homeOrg, routeProps) => {
    const {
      location: { search },
    } = this.props
    const searchParams = querystring.parse(search.replace('?', ''))
    if (searchParams.roleTypes) {
      searchParams.roleTypes = flatten([ searchParams.roleTypes ])
    }
    if (searchParams.completedCourseIds) {
      searchParams.completedCourseIds = flatten([ searchParams.completedCourseIds ])
    }
    return (
      <ClientsView
        basePath={helpers.getOrgPath(org.id)}
        organization={org}
        homeOrganization={homeOrg}
        onSearchParamsChange={this.navigateToPage}
        searchParams={searchParams}
        onCreateClick={this.navigateToCreateClients}
        onClientClick={(clientId) => {
          this.navigateToClientDetails(false, clientId)
        }}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        isNewVersion={true}
        {...routeProps}
      />
    )
  }

  renderCreateClientsView = (org, homeOrg, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CreateClientsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        homeOrganization={homeOrg}
        {...routeProps}
      />
    )
  }

  renderClientDetailsView = (org, homeOrg, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ClientDetailsView
        organization={org}
        homeOrganization={homeOrg}
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        showArchived={false}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        onUserClick={this.navigateToClientDetails.bind(this, false)}
        {...routeProps}
      />
    )
  }

  renderArchivedClientDetailsView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ClientDetailsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        showArchived={true}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        onUserClick={this.navigateToClientDetails.bind(this, false)}
        {...routeProps}
      />
    )
  }

  renderCoursesView = (org, routeProps) => {
    const { location: { search } } = this.props
    const activePage = querystring.parse(search.replace('?', '')).page || 1

    return (
      <CoursesView
        organization={org}
        onPageChange={this.navigateToPage}
        activePageNumber={activePage * 1}
        onManageClick={this.navigateToManageCourses}
        onCourseClick={this.navigateToEditCourse}
        {...routeProps}
      />
    )
  }

  renderCourseDetailsView = (org, routeProps) => {
    const { match: { params: { orgId } }, location: { search } } = this.props
    const searchParams = querystring.parse(search.replace('?', ''))
    if (searchParams.roleTypes) {
      searchParams.roleTypes = flatten([ searchParams.roleTypes ])
    }
    return (
      <CourseDetailsView
        organization={org}
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditClick={this.navigateToEditCourse}
        onSearchParamsChange={this.navigateToPage}
        searchParams={searchParams}
        {...routeProps}
      />
    )
  }

  renderCourseEditView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CourseEditView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditItemClick={this.navigateToEditCourseDetails}
        {...routeProps}
      />
    )
  }

  renderCourseEditDetailsView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CourseEditDetailsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditItemClick={this.navigateToEditCourseDetails}
        onBack={this.navigateToEditCourse}
        {...routeProps}
      />
    )
  }

  renderManageOrganizationView = (routeProps) => {
    const {
      homeOrg, refectchChildOrgList, match: { params: { orgId } },
    } = this.props
    return (
      <ManageOrganizationView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        homeOrganization={homeOrg}
        refectchChildOrgList={refectchChildOrgList}
        {...routeProps}
      />
    )
  }

  renderActivityView = (org, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ActivityView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        {...routeProps}
      />
    )
  }

  renderRoomsView = (org, routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <RoomsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        homeOrganization={homeOrg}
        organization={org}
        onRoomClick={this.navigateToRoomDetails.bind(this)}
        {...routeProps}
      />
    )
  }

  renderAllRoomsView = (org, routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <AllRoomsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        homeOrganization={homeOrg}
        organization={org}
        onRoomClick={this.navigateToAllRoomDetails.bind(this)}
        {...routeProps}
      />
    )
  }

  renderRoomDetailsView = (org, isAllRooms, routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <RoomDetailsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        homeOrganization={homeOrg}
        onReviewProgressClick={() => console.log('click')}
        onClientClick={() => console.log('click')}
        onReviewFirewordsClick={() => console.log('click')}
        onStudentClick={(roomId, studentId) => this.navigateToRoomDetailsStudent(roomId, studentId, orgId, isAllRooms)}
        onCourseClick={(roomId, courseId) => this.navigateToRoomDetailsCourse(roomId, courseId, orgId, isAllRooms)}
        {...routeProps}
      />
    )
  }

  renderRoomDetailsStudentView = (org, routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <RoomDetailsStudentView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        homeOrganization={homeOrg}
        onReviewProgressClick={() => console.log('click')}
        onClientClick={() => console.log('click')}
        onReviewFirewordsClick={() => console.log('click')}
        {...routeProps}
      />
    )
  }

  renderRoomDetailsCourseView = (org, routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <RoomDetailsCourseView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        homeOrganization={homeOrg}
        onReviewProgressClick={() => console.log('click')}
        onClientClick={() => console.log('click')}
        onReviewFirewordsClick={() => console.log('click')}
        {...routeProps}
      />
    )
  }

  renderBaselineView = (org, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <OrgBaselineView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        onUserClick={this.navigateToClientBaseline.bind(this, false)}
        {...routeProps}
      />
    )
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getAllCourses,
      homeOrg,
      getCoursesState,
    } = this.props

    if (isStale(getCoursesState)) {
      getAllCourses({ accessToken, organizationId: homeOrg.id })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      match: { params: { orgId: currentOrgId } },
      location,
      invalidateClients,
      invalidateClientActivity,
      getAllCourses,
      homeOrg,
      getCoursesState,
    } = this.props

    const searchParams = querystring.parse(location.search.replace('?', ''))
    const prevSearchParams = querystring.parse(prevProps.location.search.replace('?', ''))
    if ((!!location.search.replace('?', '').length && !isEqual(searchParams, prevSearchParams)) || !location.pathname.includes('/users')) {
      invalidateClients()
    }
    if (isStale(getCoursesState)) {
      getAllCourses({ accessToken, organizationId: homeOrg.id })
    }

    if (prevProps.match.params.orgId !== currentOrgId) {
      invalidateClients()
      invalidateClientActivity()
    }
  }

  render () {
    const {
      homeOrg,
      currentOrg,
      authState: {
        userId,
        roleType,
        accessToken,
        refreshToken,
        userName,
        profile,
      },
      location: { pathname },
      logout,
      setAuthProfile,
    } = this.props
    const {
      isSavingProfile, showStaffHelpModal, showHelp,
    } = this.state
    const onHome = (pathname.includes('/rooms'))
    const onCourses = (pathname.includes('/courses') && !pathname.includes('all-rooms'))
    const onOrgSettings = (pathname.endsWith('/manage'))
    const onUsers = (pathname.includes('/users'))
    const onActivity = (pathname.endsWith('/activity'))
    const onAllRooms = (pathname.includes('/all-rooms'))
    const onOrgBaseline = (pathname.endsWith('/baseline-summary'))
    // const currentOrgDetails = getOrgDetails(currentOrg)

    const name = get(profile, 'firstName') || userName
    const icon = (onHome) ? <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='home' />
      : (onUsers) ? <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='user' />
        : (onCourses) ? <span><Icon style={{ fontSize: '2rem' }} className='base-green fas fa-chalkboard' /></span>
          : (onAllRooms) ? <span><Icon style={{ fontSize: '2rem' }} className='base-green fas fa-door-open' /></span>
            : (onActivity) ? <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='line chart' />
              : (onOrgBaseline) ? <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='area chart' />
                : (onOrgSettings) ? <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='setting' /> : <Icon className='base-green' style={{ fontSize: '2rem', verticalAlign: 'baseline' }} name='hand paper' />
    const header = (onHome) ? `Hi, ${name}`
      : (onUsers) ? 'Users'
        : (onCourses) ? 'Modules'
          : (onActivity) ? 'Activity'
            : (onAllRooms) ? 'All Rooms'
              : (onOrgBaseline) ? 'Baseline'
                : (onOrgSettings) ? 'Settings' : currentOrg.name || 'Loading...'

    const onSaveProfile = async (updatedProfile) => {
      this.setState({ ...this.state, isSavingProfile: true })
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = (!profile) ? `/clients/${userId}/profiles` : `/clients/${userId}/profiles/${profile.id}`
      const method = (!profile) ? HTTP_METHODS.POST : HTTP_METHODS.PUT
      const [ err ] = await to(fetch(url, {
        method,
        headers,
        data: {
          ...updatedProfile,
        },
      }))
      this.setState({ ...this.state, isSavingProfile: false })
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error saving profile', err)
        throw new Error('Error saving profile')
      }
      return updatedProfile
    }

    // const lastLogin = new Date(accessToken.split(':')[3] * 1 || 0)
    // const showNewUserModal = (lastLogin.getTime() < (new Date().getTime() - (1000 * 60 * 60 * 24 * 60)))
    const staffRoleTypes = [
      ROLE_TYPES.ADMIN,
      ROLE_TYPES.SUPERVISOR,
      ROLE_TYPES.ADVISOR,
    ]
    const isCustomer = !!findParentContractedCustomerOrSiteOrg(homeOrg, currentOrg) || (getOrgType(currentOrg) === 'group' && !homeOrg.children)

    return (
      <Query
        variables={{
          userId,
        }}
        query={GET_ROOMS_BY_OWNER_USER_ID}
      >
        {({
          loading,
          data,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const rooms = get(data, 'roomsByOwnerUserId') || []
          return (
            <Query
              variables={{
                orgId: currentOrg.id,
              }}
              ignoreError={true}
              query={GET_NOTIFICATIONS_ON_BY_ORG_ID}
            >
              {({ data: notificationData }) => {
                const firewordsOff = notificationData && notificationData.notificationsOnByOrgId && !notificationData.notificationsOnByOrgId.hasFirewordNotificationsEnabled && currentOrg.firewordsEnabled
                const helpOff = notificationData && notificationData.notificationsOnByOrgId && !notificationData.notificationsOnByOrgId.hasHelpNotificationsEnabled
                return (
                  <>
                    <HelpModal
                      open={showHelp}
                      onClose={() => this.setState({ ...this.state, showHelp: false })}
                      roomsEnabled={currentOrg.roomsEnabled}
                    />
                    <StaffHelpModal
                      open={showStaffHelpModal && staffRoleTypes.includes(roleType) && homeOrg.id === currentOrg.id}
                      onOptionSelect={(option, hideForever) => {
                        if (hideForever) {
                          window.localStorage.setItem(`hide-staff-help.base.education.${userId}`, 'true')
                        }
                        window.sessionStorage.setItem(`hide-staff-help.base.education.${userId}`, 'true')
                        if (option === 'dashboard') {
                          this.navigateToMyDashboard()
                        } else if (option === 'users') {
                          this.navigateToClients()
                        } else if (option === 'courses') {
                          this.navigateToOrgCourses()
                        } else if (option === 'activity') {
                          this.navigateToOrgActivity()
                        } else if (option === 'settings') {
                          this.navigateToOrgSettings()
                        }
                        this.setState({ ...this.state, showStaffHelpModal: false })
                      }}
                      worksWithStudents={get(profile, 'worksWithStudents', false)}
                      isAdmin={roleType === ROLE_TYPES.ADMIN}
                      hasRooms={!!rooms.length}
                      onClose={(hideForever) => {
                        if (hideForever) {
                          window.localStorage.setItem(`hide-staff-help.base.education.${userId}`, 'true')
                        }
                        window.sessionStorage.setItem(`hide-staff-help.base.education.${userId}`, 'true')
                        this.setState({ ...this.state, showStaffHelpModal: false })
                      }}
                    />
                    <StaffProfileModal
                      open={(!profile || !profile.firstName || !profile.lastName || !profile.jobTitle) && staffRoleTypes.includes(roleType) && homeOrg.id === currentOrg.id}
                      firstName={get(profile, 'firstName', '')}
                      lastName={get(profile, 'lastName', '')}
                      jobTitle={get(profile, 'jobTitle', '')}
                      worksWithStudents={get(profile, 'worksWithStudents', false)}
                      isSaving={isSavingProfile}
                      onSave={(updatedProfile) => {
                        onSaveProfile(updatedProfile).then((p) => {
                          setAuthProfile({
                            ...profile,
                            ...p,
                          })
                        })
                      }}
                    />
                    <div className='new-org-page-header no-print'>
                      <div style={{ flexGrow: 2, margin: 0 }} className='org-name-header'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Header as='h2' style={{ flexGrow: 2, margin: 0 }} data-public>{icon} {header}</Header>
                          {/* {(currentOrgDetails.ssoType === 'classlink') && (
                <ClassLinkLogo height={27} width={27} color={'#ffffff'} />
              )}
              {(currentOrgDetails.ssoType === 'clever') && (
                <CleverLogo height={27} width={27} color={'#ffffff'} />
              )} */}
                        </div>
                      </div>
                      {(currentOrg.sevenMindsetsEnabled) && (
                        <Popup
                          hoverable
                          position='bottom center'
                          trigger={(
                            <Button
                              icon
                              as='a'
                              className='top-button seven-m-green-border'
                              href={(process.env.REACT_APP_ENV === 'stg') ? 'https://www.7mindsetsportalstage.com/' : 'https://www.7mindsetsportal.com/'}
                              target='_blank'
                            >
                              <Image style={{ width: 21.483, height: 18.188 }} src={'https://media.base.education/img/new-7m-icon-fullcolor-without-spaces.svg'} />
                            </Button>
                          )}
                          content='Launch 7 Mindsets'
                        />
                      )}
                      <Popup
                        hoverable
                        position='bottom center'
                        trigger={(
                          <Button
                            icon
                            as='a'
                            className='top-button base-blue base-blue-border'
                            href={`${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}course.base.education?auth=${window.btoa(JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))}`}
                            target='_blank'
                          >
                            <Icon name='graduation' />
                          </Button>
                        )}
                        content='Launch the Learner Portal'
                      />
                      <Popup
                        hoverable
                        position='bottom center'
                        trigger={(
                          <Button
                            icon
                            className='top-button base-purple base-purple-border'
                            onClick={() => this.setState({ ...this.state, showHelp: true })}
                          >
                            <Icon name='help' />
                          </Button>
                        )}
                        content='Help'
                      />
                      <Popup
                        hoverable
                        position='bottom center'
                        trigger={(
                          <Button
                            icon
                            className='top-button base-red base-red-border'
                            onClick={() => this.navigateToClientDetails(false, userId, homeOrg.id)}
                          >
                            <Icon name='id card' />
                          </Button>
                        )}
                        content='My Account'
                      />
                      <Button
                        color='black'
                        icon
                        data-public
                        style={{ flexShrink: 2, marginLeft: 10 }}
                        onClick={() => logout({ isUserInitiated: true })}
                      >
                        <Icon name='log out' /> Log Out
                      </Button>
                    </div>
                    {
                      (firewordsOff || helpOff) && (isCustomer) && (<Message error>
                        <Message.Header>WARNING: {currentOrg.name} has nobody to notify if
                          {helpOff ? ' a student needs help!' : ' a student generates firewords!'}
                        </Message.Header>
                        <p>Soon this setting will go from optional to required. To sign up for notifications,
                          <span style={{ color: 'blue' }} onClick={() => this.navigateToClientDetails(false, userId)}> click here</span></p>
                      </Message>)
                    }

                    <Switch>
                      <PrivateRoute exact path={path + ClientsView.path} render={this.renderClientsView.bind(this, currentOrg, homeOrg)}/>
                      <PrivateRoute exact path={path + CreateClientsView.path} render={this.renderCreateClientsView.bind(this, currentOrg, homeOrg)}/>
                      <PrivateRoute path={path + ClientDetailsView.path} render={this.renderClientDetailsView.bind(this, currentOrg, homeOrg)}/>
                      <PrivateRoute exact path={path + CoursesView.path} render={this.renderCoursesView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + CourseDetailsView.path} render={this.renderCourseDetailsView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + CourseEditView.path} render={this.renderCourseEditView}/>
                      <PrivateRoute exact path={path + CourseEditDetailsView.path} render={this.renderCourseEditDetailsView}/>
                      <PrivateRoute exact path={path + ManageOrganizationView.path} render={this.renderManageOrganizationView}/>
                      <PrivateRoute exact path={path + RoomsView.path} render={this.renderRoomsView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + AllRoomsView.path} render={this.renderAllRoomsView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + RoomDetailsView.path} render={this.renderRoomDetailsView.bind(this, currentOrg, false)}/>
                      <PrivateRoute exact path={path + RoomDetailsView.alternatePath} render={this.renderRoomDetailsView.bind(this, currentOrg, true)}/>
                      <PrivateRoute exact path={path + RoomDetailsStudentView.path} render={this.renderRoomDetailsStudentView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + RoomDetailsStudentView.alternatePath} render={this.renderRoomDetailsStudentView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + RoomDetailsCourseView.path} render={this.renderRoomDetailsCourseView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + RoomDetailsCourseView.alternatePath} render={this.renderRoomDetailsCourseView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + ActivityView.path} render={this.renderActivityView.bind(this, currentOrg)}/>
                      <PrivateRoute exact path={path + OrgBaselineView.path} render={this.renderBaselineView.bind(this, currentOrg)}/>
                      <Redirect to={path + ClientsView.path} />
                    </Switch>
                  </>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
  } = state
  return {
    getCoursesState,
  }
}
const mapDispatchToProps = {
  invalidateClients,
  invalidateClientActivity,
  invalidateCourseImages,
  getAllCourses,
  logout,
  setAuthProfile,
}
const OrganizationViewContainer = connect(mapStateToProps, mapDispatchToProps)(OrganizationView)

export default translate([ 'components' ])(OrganizationViewContainer)
