import to from 'await-to-js'
import { findIndex, cloneDeep } from 'lodash'

import {
  FETCH_ACTIONS,
  HTTP_METHODS,
  FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import callApi from './call-api'

const STORE_PATH_PREFIX = 'analyzedAnswers'

export function getClientAnalyzedAnswers ({
  accessToken,
  clientId,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, `/clients/${clientId}/analyzedAnswers`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getClientAnalyzedAnswers')
}

export function invalidateClientAnalyzedAnswers () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateClientAnalyzedAnswers' ]
}
// TODO: this sucks so bad
const customAnalyzedAnswerReducer = (value, questionId, answerId) => (state) => {
  const answers = state[questionId] || []
  const answerIndex = findIndex(answers, { id: answerId })
  if (answerIndex < 0) {
    return cloneDeep(state)
  }
  const clonedAnswers = cloneDeep(answers)
  clonedAnswers[answerIndex].analyzedAnswer = value
  clonedAnswers[answerIndex].hasUnreviewedFirePhrases = false
  return Object.assign({}, state, { [questionId]: clonedAnswers })
}

export function createAnalyzedAnswer ({
  data,
  accessToken,
  clientId,
  answerId,
  skipCustomerReducer = false,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.POST
  const fetchAction = FETCH_ACTIONS.POST
  const url = `/clients/${clientId}/analyzedAnswers`
  const actionName = 'createClientAnalyzedAnswers'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ err, result ] = await to(fetch(url, {
        data, method, headers,
      }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const now = new Date()
      const qId = data._meta.relationships[0].id
      if (!skipCustomerReducer) {
        dispatch([ customAnalyzedAnswerReducer({
          ...data,
          id: result,
          modified: now,
          created: now,
        }, qId, answerId), `answers.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      }
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeCreateAnalyzedAnswer () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateAnalyzedAnswer' ]
}
