import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'semantic-ui-react'

import './radio-group.css'

const TYPE_CONVERTERS = {
  number: (val) => val * 1,
  boolean: (val) => (val === 'true'),
  string: (val) => val,
}

// This is intended to be a controlled component
// https://reactjs.org/docs/forms.html#controlled-components
class RadioGroup extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
    })),
    selectedValue: PropTypes.any,
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    onFocus: console.log.bind(console, 'onFocus'),
    onBlur: console.log.bind(console, 'onBlur'),
    options: [],
    selectedValue: null,
    vertical: false,
    disabled: false,
  }

  constructor (props) {
    super(props)
    this.state = { idPrefix: Math.random().toString() }
  }

  handleRadioChanged = (event, data) => {
    const { onChange } = this.props
    const [ , type, value ] = data.id.split('|')
    onChange(TYPE_CONVERTERS[type](value))
  }

  renderCheckbox = ({
    value,
    label,
    subLabel,
  }) => {
    const {
      selectedValue,
      vertical,
      onBlur,
      onFocus,
      disabled,
    } = this.props
    const className = (vertical) ? 'radio-group-vertical' : 'radio-group'
    const labelElement = (
      <label>
        <span>{label}</span>
        {subLabel && <span className='subLabel'>{subLabel}</span>}
      </label>
    )
    return (
      <Checkbox
        radio
        className={className}
        key={`${this.state.idPrefix}|${typeof (value)}|${value}`}
        id={`${this.state.idPrefix}|${typeof (value)}|${value}`}
        label={labelElement}
        disabled={disabled}
        checked={selectedValue === value}
        onChange={this.handleRadioChanged}
        onBlur={onBlur}
        onFocus={onFocus}/>
    )
  }

  render () {
    const { options } = this.props
    return (
      <React.Fragment>
        {options.map(this.renderCheckbox)}
      </React.Fragment>
    )
  }
}

export default RadioGroup
