import gql from 'graphql-tag'

export const NAME = 'processStudentRequestsToRoom'
const PROCESS_STUDENT_REQUESTS = gql`
  mutation ${NAME}($id: ID!, $studentIds: [String]!, $approve: Boolean!) {
    ${NAME}(id: $id, studentIds: $studentIds, approve: $approve) {
      id
      name
      imageUrl
      orgId
      ownerUserId
      studentIds
      requestedStudentIds
      courseIds
      firewordNotificationsEnabled
      firewordNotificationsForRoomCoursesOnly
      isOpenForRequests
      created
      modified
    }
  }
`
export default PROCESS_STUDENT_REQUESTS
