const getInitialAuthenticationState = () => {
  return {
    accessToken: null,
    refreshToken: null,
    userId: null,
    expires: 0,
    isLoading: false,
    isRefreshing: false,
    error: null,
    lastSuccessfulAuthentication: 0,
    roleType: null,
  }
}

export default getInitialAuthenticationState
