import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { get } from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import { Modal, Icon } from 'semantic-ui-react'

import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import AssessmentMeanScoresSummaryCard from '../../../common/assessment-mean-scores-summary-card/assessment-mean-scores-summary-card'
import Query from '../../../common/query/query'
import GET_ORG_USER_ASSESSMENT_SCORES from '../../../../helpers/graphql-queries/get-org-user-assessment-scores'
import GET_ASSESSMENT from '../../../../helpers/graphql-queries/get-assessment'
import GET_INDIVIDUAL_USER_ASSESSMENT_SCORES_REPORT from '../../../../helpers/graphql-queries/get-individual-user-assessment-scores-by-org-report'
import config from '../../../../config.js'
import { getOrgType } from '../../../../helpers/organization'
import { INTERNAL_ROLE_TYPES, EXTERNAL_ROLE_TYPES } from '../../../../helpers/get-roles'

const OrgBaselineView = ({
  organizationId,
  organization,
  authState: { roleType },
  onUserClick,
}) => {
  const now = new Date()
  const currentSchoolYear = (now.getMonth() <= 6) ? now.getFullYear() - 1 : now.getFullYear()
  const [ schoolYear, setSchoolYear ] = useState(currentSchoolYear)
  const [ demographic, setDemographic ] = useState()
  const [ baselineReportLink, setBaselineReportLink ] = useState(false)
  const getBaselineReportOptions = {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setBaselineReportLink(get(data, 'getIndividualUserAssessmentScoresByOrgIdReport')),
    onError: () => window.alert('There was a problem while attempting to download the report. Please try again.'),
  }
  const [ getBaselineReportData, { loading: baselineReportLoading } ] = useLazyQuery(GET_INDIVIDUAL_USER_ASSESSMENT_SCORES_REPORT, getBaselineReportOptions)

  const firstBaselineSchooYear = 2019
  const schoolYearOptions = Array.apply(null, Array(currentSchoolYear - firstBaselineSchooYear + 1)).map((_, i) => ({
    text: (!i) ? 'Current School Year' : `${currentSchoolYear - i}-${currentSchoolYear - i + 1} School Year`,
    value: currentSchoolYear - i,
  }))

  const orgType = getOrgType(organization)
  return (
    <Query
      variables={{
        id: config.baseline.currentAssessmentId,
        orgId: organization.id,
      }}
      query={GET_ASSESSMENT}
    >
      {({
        loading: assessmentLoading,
        data: assessmentData,
      }) => {
        if (assessmentLoading) {
          return (<FullScreenLoadingOverlay isActive={true}/>)
        }
        const assessment = get(assessmentData, 'assessment')
        return (
          <Query
            variables={{
              orgId: organizationId,
              assessmentId: config.baseline.currentAssessmentId,
              schoolYear,
              childrenDepth: (orgType === 'group') ? 0 : 3,
              groupByDemographic: demographic,
            }}
            query={GET_ORG_USER_ASSESSMENT_SCORES}
          >
            {({
              loading,
              data,
            }) => {
              const userAssessmentScores = get(data, 'userAssessmentScoresByOrgId', [])
              return (
                <>
                  <Modal
                    closeIcon
                    open={baselineReportLink}
                    onClose={() => setBaselineReportLink(null)}
                  >
                    <Modal.Header><Icon name='table' />Baseline Report</Modal.Header>
                    <Modal.Content>
                      <h4>
                        The report was generated successfully.
                      </h4>
                      <p>
                        <a href={baselineReportLink}>Click here</a> to download the report.
                      </p>
                    </Modal.Content>
                  </Modal>
                  <AssessmentMeanScoresSummaryCard
                    loading={loading}
                    showCompareControls={true}
                    assessment={assessment}
                    userAssessmentScores={userAssessmentScores}
                    onSchoolYearChange={(year) => setSchoolYear(year)}
                    onUserClick={onUserClick}
                    schoolYear={schoolYear}
                    onCompareByChange={(demo) => setDemographic(demo)}
                    showNumberOfResponsesChart={true}
                    schoolYearOptions={schoolYearOptions}
                    orgId={organization.id}
                    isInternalRole={Object.values(INTERNAL_ROLE_TYPES).includes(roleType)}
                    onDownloadClick={(![ ...Object.values(INTERNAL_ROLE_TYPES), EXTERNAL_ROLE_TYPES.ADMIN ].includes(roleType)) ? undefined : () => getBaselineReportData({
                      variables: {
                        orgId: organization.id,
                        schoolYear,
                        assessmentId: config.baseline.currentAssessmentId,
                      },
                    })}
                    isDownloading={baselineReportLoading}
                  />
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}
OrgBaselineView.path = '/baseline-summary'
OrgBaselineView.propTypes = {
  organizationId: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  showFriendlyApiErrorMessages: PropTypes.bool,
}
OrgBaselineView.defaultProps = {
  t: (key, opts = {}) => opts.defaultValue || key,
}
const mapStateToProps = (state) => {
  const {
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state
  return {
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
}
const OrgBaselineViewContainer = connect(mapStateToProps, mapDispatchToProps)(OrgBaselineView)
export default translate([ 'components' ])(OrgBaselineViewContainer)
