import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Button,
  Form,
  Segment,
  Message,
  Grid,
  Icon,
} from 'semantic-ui-react'
import { Field } from 'react-final-form'
import { translate } from 'react-i18next'
import { get } from 'lodash'

import './access-schedule.css'

import WeeklyAccessTimeRanges from '../../common/weekly-access-time-ranges/weekly-access-time-ranges'
import OneTimeExceptions from '../../common/one-time-exceptions/one-time-exceptions'
import ClockLabel from '../../common/clock-label/clock-label'
import BaseForm from '../base-form'
import { dropdownOnChange } from '../../../helpers/form'

const TIMEZONES = [
  'America/Adak',
  'America/Anchorage',
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Los_Angeles',
  'America/Menominee',
  'America/Metlakatla',
  'America/New_York',
  'America/Nome',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Sitka',
  'America/Yakutat',
  'Pacific/Honolulu',
]
const TRANSLATION_PREFIX = 'forms.access-schedule'

export class AccessScheduleForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    accessSchedule: PropTypes.object,
    hideTimezone: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'blue',
    isLoading: false,
    disabled: false,
    hideTimezone: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = ({
    timezone,
    weeklyTimes,
    oneTimeExceptions,
  }) => {
    this.props.onSave({
      timezone: (this.props.hideTimezone) ? undefined : timezone,
      weeklyTimes,
      oneTimeExceptions,
    })
  }

  renderTimezoneOption = (timezone) => ({ text: timezone, value: timezone })

  renderForm = ({
    handleSubmit,
    submitDisabled,
  }) => {
    const {
      color,
      isLoading,
      disabled,
      hideTimezone,
      t,
    } = this.props
    return (
      <Form className='access-schedule-form' onSubmit={handleSubmit}>
        {disabled && (
          <Message icon warning visible={true}>
            <Icon name='warning' />
            <Message.Content data-public>
              {t(`${TRANSLATION_PREFIX}.disabled_message`)}
            </Message.Content>
          </Message>
        )}
        {(!hideTimezone) && (
          <Field
            name='timezone'
            render={({ input }) => (
              <React.Fragment>
                <Message attached='top'>
                  <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.timezone`, { postProcess: 'titleCase' })}</Message.Header>
                </Message>
                <Segment attached='bottom'>
                  <Grid stackable>
                    <Grid.Column width={10}>
                      <Dropdown
                        className='timezone-input'
                        placeholder={t(`${TRANSLATION_PREFIX}.timezone`, { postProcess: 'titleCase' })}
                        disabled={disabled}
                        fluid
                        search
                        selection
                        options={TIMEZONES.map(this.renderTimezoneOption)}
                        name={input.name}
                        value={input.value}
                        onChange={dropdownOnChange(input)}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}>
                      </Dropdown>

                    </Grid.Column>
                    <Grid.Column width={6} textAlign='right'>
                      <ClockLabel timeZone={input.value} />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </React.Fragment>
            )}
          />
        )}
        <Field
          name='weeklyTimes'
          render={({ input }) => (
            <WeeklyAccessTimeRanges
              disabled={disabled}
              weeklyTimes={input.value}
              onChange={input.onChange}
              color={color}
            />
          )}
        />
        <Field
          name='oneTimeExceptions'
          render={({ input }) => (
            <OneTimeExceptions
              disabled={disabled}
              oneTimeExceptions={input.value}
              onChange={input.onChange}
              color={color}
            />
          )}
        />
        <Button
          data-public
          type='submit'
          className='access-schedule-button'
          floated='right'
          loading={isLoading}
          color='green'
          disabled={submitDisabled}
        >
          {t('forms.save_button')}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      accessSchedule,
      isLoading,
      t,
    } = this.props
    const timezone = get(accessSchedule, 'timezone', 'America/Denver')
    const weeklyTimes = get(accessSchedule, 'weeklyTimes', [])
    const oneTimeExceptions = get(accessSchedule, 'oneTimeExceptions', [])
    return (
      <BaseForm
        header={t(`${TRANSLATION_PREFIX}.header`)}
        onSubmit={this.handleSaveForm}
        isLoading={isLoading}
        initialValues={{
          timezone,
          weeklyTimes,
          oneTimeExceptions,
        }}
        render={this.renderForm}
      />
    )
  }
}

export default translate([ 'components' ])(AccessScheduleForm)
