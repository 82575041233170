import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  sortBy,
  intersection,
  isEqual,
  groupBy,
} from 'lodash'
import {
  Segment,
  Grid,
  Button,
  Dropdown,
  Icon,
} from 'semantic-ui-react'

import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import {
  saveExtendedProfile,
  acknowledgeSaveExtendedProfile,
} from '../../../../../actions/user-extended-profile'
import {
  createClientEnrollments,
  acknowledgeCreateEnrollment,
  deleteClientEnrollments,
  acknowledgeDeleteEnrollment,
} from '../../../../../actions/enrollments'
import UserEnrollmentsTable from '../../../../common/user-enrollments-table'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'

const TRANSLATION_PREFIX = 'views.organization.client-details.enrollments'
const REASONS = sortBy([ {
  text: 'Prevention',
  value: 'prevention',
  key: 'prevention',
}, {
  text: 'Anger/Fighting',
  value: 'anger_fighting',
  key: 'anger_fighting',
}, {
  text: 'Drug Prevention and Education',
  value: 'substance_issues',
  key: 'substance_issues',
}, {
  text: 'Disruptive Behavior',
  value: 'disruptive_behavior',
  key: 'disruptive_behavior',
}, {
  text: 'Truancy',
  value: 'truancy',
  key: 'truancy',
}, {
  text: 'Tardy/Chronic Absenteeism',
  value: 'tardy_chronic_absenteeism',
  key: 'tardy_chronic_absenteeism',
}, {
  text: 'Inappropriate Peer Behavior',
  value: 'inappropriate_peer_behavior',
  key: 'inappropriate_peer_behavior',
}, {
  text: 'Domestic Issues',
  value: 'domestic_issues',
  key: 'domestic_issues',
}, {
  text: 'Self-Defeating Behaviors',
  value: 'self_defeating_behaviors',
  key: 'self_defeating_behaviors',
}, {
  text: 'Stress',
  value: 'stress',
  key: 'stress',
}, {
  text: 'Depression and Anxiety',
  value: 'depression_anxiety',
  key: 'depression_anxiety',
}, {
  text: 'Bullying/Relational Aggression/Cyberbullying/Technology Issues',
  value: 'bullying_relational_agression_cyber_bullying_technology_issues',
  key: 'bullying_relational_agression_cyber_bullying_technology_issues',
}, {
  text: 'School Disengagement',
  value: 'school_disengagement',
  key: 'school_disengagement',
}, {
  text: 'Gang Affiliation',
  value: 'gang_affiliation',
  key: 'gang_affiliation',
}, {
  text: 'Unhealthy Relationships/Exploitation/Trafficking',
  value: 'unhealthy_relationships_exploitation_trafficking',
  key: 'unhealthy_relationships_exploitation_trafficking',
}, {
  text: 'Cultural Issues',
  value: 'cultural_issues',
  key: 'cultural_issues',
}, {
  text: 'Poor Choices',
  value: 'poor_choices',
  key: 'poor_choices',
}, {
  text: 'LGBTQ',
  value: 'lgbtq',
  key: 'lgbtq',
}, {
  text: 'Suspension/Expulsion',
  value: 'suspension_expulsion',
  key: 'suspension_expulsion',
}, {
  text: 'Getting to Know Student/IEP Transition Questions',
  value: 'getting_to_know_student_iep_transition_questions',
  key: 'getting_to_know_student_iep_transition_questions',
} ], 'text')

export class EnrollmentsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    postExtendedProfileState: PropTypes.object.isRequired,
    putExtendedProfileState: PropTypes.object.isRequired,
    postEnrollmentsState: PropTypes.object.isRequired,
    deleteEnrollmentsState: PropTypes.object.isRequired,
    getEnrollmentsState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    saveExtendedProfile: PropTypes.func.isRequired,
    acknowledgeSaveExtendedProfile: PropTypes.func.isRequired,
    createClientEnrollments: PropTypes.func.isRequired,
    acknowledgeCreateEnrollment: PropTypes.func.isRequired,
    deleteClientEnrollments: PropTypes.func.isRequired,
    acknowledgeDeleteEnrollment: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
  }

  static path = '/enrollments'

  constructor (props) {
    super(props)
    const {
      clientId,
      getClientsState: {
        value: clientsById,
      },
    } = this.props
    const client = (!clientsById) ? {} : clientsById[clientId]
    const userReasons = get(client, 'extendedProfile.referralReasons', [])
    this.state = {
      selectedReasons: userReasons,
    }
  }

  dismissMessage = () => {
    this.props.acknowledgeSaveExtendedProfile()
    this.props.acknowledgeCreateEnrollment()
    this.props.acknowledgeDeleteEnrollment()
  }

  onReasonsChange = (e, input) => {
    this.setState({ selectedReasons: input.value })
  }

  enrollAll = () => {
    const {
      clientId,
      authState: { accessToken },
      createClientEnrollments,
      getEnrollmentsState: { value: enrollmentsById },
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props

    const client = get(clientsById, clientId, {})
    const courses = Object.values(coursesById)
    const enrollments = (!enrollmentsById) ? [] : Object.values(enrollmentsById)
    const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
    const selectedCourses = courses.filter((course) => course.isActive && intersection(course.referralReasons, this.state.selectedReasons).length > 0)
    const enrollmentsToCreate = selectedCourses
      .filter((course) => (!enrollmentsByCourseId[course.id] || !enrollmentsByCourseId[course.id].length))
      .map((course) => ({ courseId: course.id, attemptNumber: 1 }))

    if (!enrollmentsToCreate.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_courses_to_enroll`))
      return
    }
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.enroll_all_confirm`, { userName: client.userName, count: enrollmentsToCreate.length }))
    if (!areYouSure) {
      return
    }

    createClientEnrollments({
      clientId,
      data: enrollmentsToCreate,
      accessToken,
    })
  }

  reenrollAll = () => {
    const {
      clientId,
      authState: { accessToken },
      createClientEnrollments,
      getEnrollmentsState: { value: enrollmentsById },
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props

    const client = get(clientsById, clientId, {})
    const courses = Object.values(coursesById)
    const enrollments = (!enrollmentsById) ? [] : Object.values(enrollmentsById)
    const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
    const selectedCourses = courses.filter((course) => course.isActive && intersection(course.referralReasons, this.state.selectedReasons).length > 0)
    const enrollmentsToCreate = selectedCourses
      .filter((course) => {
        const courseEnrollments = enrollmentsByCourseId[course.id] || []
        const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
        return (currentEnrollment && !!currentEnrollment.startedOn && currentEnrollment.attemptNumber < 5)
      })
      .map((course) => {
        const courseEnrollments = enrollmentsByCourseId[course.id] || []
        const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
        return { courseId: course.id, attemptNumber: currentEnrollment.attemptNumber + 1 }
      })

    if (!enrollmentsToCreate.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_courses_to_enroll`))
      return
    }
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.reenroll_all_confirm`, { userName: client.userName, count: enrollmentsToCreate.length }))
    if (!areYouSure) {
      return
    }

    createClientEnrollments({
      clientId,
      data: enrollmentsToCreate,
      accessToken,
    })
  }

  unenrollAll = () => {
    const {
      clientId,
      authState: { accessToken },
      deleteClientEnrollments,
      getEnrollmentsState: { value: enrollmentsById },
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props

    const client = get(clientsById, clientId, {})
    const courses = Object.values(coursesById)
    const enrollments = (!enrollmentsById) ? [] : Object.values(enrollmentsById)
    const enrollmentsByCourseId = groupBy(enrollments, 'courseId')
    const enrollmentIds = courses.filter((course) => {
      const courseEnrollments = enrollmentsByCourseId[course.id] || []
      const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
      return (currentEnrollment && !currentEnrollment.startedOn)
    }).map((course) => {
      const courseEnrollments = enrollmentsByCourseId[course.id] || []
      const currentEnrollment = sortBy(courseEnrollments, 'attemptNumber').pop()
      return currentEnrollment.id
    })

    if (!enrollmentIds.length) {
      window.alert(t(`${TRANSLATION_PREFIX}.no_courses_to_enroll`))
      return
    }
    const reason = window.confirm(t(`${TRANSLATION_PREFIX}.enroll_all_confirm`, { userName: client.userName, count: enrollmentIds.length }))
    if (!reason) {
      return
    }
    deleteClientEnrollments({
      ids: enrollmentIds,
      clientId,
      accessToken,
    })
  }

  handleEnroll = ({ itemId, attemptNumber }) => {
    const {
      clientId,
      authState: { accessToken },
      createClientEnrollments,
    } = this.props

    createClientEnrollments({
      clientId,
      data: [ {
        courseId: itemId,
        attemptNumber,
      } ],
      accessToken,
    })
  }

  handleReenroll = ({ itemId, attemptNumber }) => {
    const {
      clientId,
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props
    const userName = get(clientsById, `${clientId}.userName`, 'UNKNOWN USER')
    const courseTitle = get(coursesById, `${itemId}.titles`, 'UNKNOWN COURSE')
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.reenroll_confirm`, { userName, courseTitle }))
    if (!areYouSure) {
      return
    }
    this.handleEnroll({ itemId, attemptNumber })
  }

  handleUnenroll = ({ itemId, enrollmentId }) => {
    const {
      clientId,
      authState: { accessToken },
      deleteClientEnrollments,
      getClientsState: { value: clientsById },
      getCoursesState: { value: coursesById },
      t,
    } = this.props
    const userName = get(clientsById, `${clientId}.userName`, 'UNKNOWN USER')
    const courseTitle = get(coursesById, `${itemId}.titles`, 'UNKNOWN COURSE')
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.unenroll_confirm`, { userName, courseTitle }))
    if (!areYouSure) {
      return
    }
    deleteClientEnrollments({
      ids: [ enrollmentId ],
      clientId,
      accessToken,
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      authState: { accessToken },
      clientId,
      getClientsState: { value: clientsById },
      saveExtendedProfile,
    } = this.props
    const client = (!clientsById) ? {} : clientsById[clientId]

    if (!isEqual(prevState.selectedReasons.sort(), this.state.selectedReasons.sort())) {
      setTimeout(() => {
        // save reasons
        saveExtendedProfile({
          clientId,
          data: {
            id: get(client, 'extendedProfile.id'),
            referralReasons: this.state.selectedReasons,
          },
          accessToken,
        })
      }, 100)
    }
  }

  componentWillUnmount () {
    setTimeout(this.dismissMessage.bind(this), 100)
  }

  render () {
    const {
      getEnrollmentsState: {
        value: enrollmentsById,
      },
      putExtendedProfileState: {
        error: putExtendedProfileError,
      },
      postExtendedProfileState: {
        error: postExtendedProfileError,
      },
      postEnrollmentsState: {
        isLoading: postEnrollmentsIsLoading,
        error: postEnrollmentsError,
        succeeded: postEnrollmentsSucceeded,
      },
      deleteEnrollmentsState: {
        isLoading: deleteEnrollmentsIsLoading,
        error: deleteEnrollmentsError,
        succeeded: deleteEnrollmentsSucceeded,
      },
      getCoursesState: { value: coursesById },
      showFriendlyApiErrorMessages,
      disabled,
      t,
    } = this.props

    const profileError = putExtendedProfileError || postExtendedProfileError
    const error = postEnrollmentsError || deleteEnrollmentsError
    const isLoading = postEnrollmentsIsLoading || deleteEnrollmentsIsLoading
    const wasSuccesful = ((postEnrollmentsSucceeded || deleteEnrollmentsSucceeded) && !error)
    const courses = (coursesById) ? Object.values(coursesById) : []
    const enrollments = (!enrollmentsById) ? [] : Object.values(enrollmentsById)
    const suggestedCourses = sortBy(courses.filter((course) => {
      return course.isActive && intersection(course.referralReasons, this.state.selectedReasons).length > 0
    }), 'titles')
    const otherCourses = sortBy(courses.filter((course) => {
      return course.isActive && intersection(course.referralReasons, this.state.selectedReasons).length <= 0
    }), 'titles')
    const discontinuedCourses = sortBy(courses.filter((course) => !course.isActive), 'titles')

    return (
      <React.Fragment>
        <FetchResultMessage
          itemType='reasons for use'
          success={false}
          error={profileError}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        <FetchResultMessage
          itemType='user enrollments'
          success={wasSuccesful}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        <Segment.Group>
          <Segment>
            <Grid columns='equal'>
              <Grid.Column>
                <Button
                  data-public
                  fluid
                  icon
                  color='green'
                  onClick={this.enrollAll}
                  disabled={disabled}
                >
                  <Icon name='plus' style={{ paddingRight: 18 }}/>
                  {t('views.organization.client-details.enrollments.enroll_all')}
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  data-public
                  fluid
                  icon
                  color='blue'
                  onClick={this.reenrollAll}
                  disabled={disabled}
                >
                  <Icon name='undo' style={{ paddingRight: 20 }}/>
                  {t('views.organization.client-details.enrollments.reenroll_all')}
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  data-public
                  fluid
                  icon
                  color='red'
                  onClick={this.unenrollAll}
                  disabled={disabled}
                >
                  <Icon name='close' style={{ paddingRight: 8 }}/>
                  {t('views.organization.client-details.enrollments.unenroll_all')}
                </Button>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment>
            <h4>{t('views.organization.client-details.enrollments.reasons_for_use_header')}</h4>
            <p>{t('views.organization.client-details.enrollments.reasons_for_use_description')}</p>
            <Dropdown
              data-public
              placeholder={t('views.organization.client-details.enrollments.reasons_for_use_header')}
              fluid
              multiple
              search
              selection
              value={this.state.selectedReasons}
              onChange={this.onReasonsChange}
              options={REASONS}
            />
            <UserEnrollmentsTable
              enrollments={enrollments}
              isSaving={isLoading}
              courses={suggestedCourses}
              header={t('views.organization.client-details.enrollments.suggested_courses')}
              color='green'
              onEnroll={this.handleEnroll}
              onUnenroll={this.handleUnenroll}
              onReenroll={this.handleReenroll}
              disabled={disabled}
            />
            <UserEnrollmentsTable
              enrollments={enrollments}
              isSaving={isLoading}
              courses={otherCourses}
              header={t('views.organization.client-details.enrollments.other_courses')}
              color='yellow'
              onEnroll={this.handleEnroll}
              onUnenroll={this.handleUnenroll}
              onReenroll={this.handleReenroll}
              disabled={disabled}
            />
            <UserEnrollmentsTable
              enrollments={enrollments}
              isSaving={isLoading}
              courses={discontinuedCourses}
              header={t('views.organization.client-details.enrollments.discontinued_courses')}
              color='red'
              onEnroll={this.handleEnroll}
              onUnenroll={this.handleUnenroll}
              onReenroll={this.handleReenroll}
              disabled={disabled}
            />
          </Segment>
        </Segment.Group>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
    },
    enrollments: {
      [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState,
    },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    enrollments: {
      [FETCH_ACTIONS.POST]: postEnrollmentsState,
      [FETCH_ACTIONS.DELETE]: deleteEnrollmentsState,
    },
    userExtendedProfile: {
      [FETCH_ACTIONS.POST]: postExtendedProfileState,
      [FETCH_ACTIONS.PUT]: putExtendedProfileState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getClientsState,
    getCoursesState,
    postEnrollmentsState,
    deleteEnrollmentsState,
    postExtendedProfileState,
    putExtendedProfileState,
    getEnrollmentsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  saveExtendedProfile,
  acknowledgeSaveExtendedProfile,
  createClientEnrollments,
  acknowledgeCreateEnrollment,
  deleteClientEnrollments,
  acknowledgeDeleteEnrollment,
}
const EnrollmentsViewContainer = connect(mapStateToProps, mapDispatchToProps)(EnrollmentsView)

export default translate([ 'components' ])(EnrollmentsViewContainer)
