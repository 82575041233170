import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Header,
  Button,
  Icon,
  Dropdown,
  Message,
  Form,
  Divider,
  Popup,
  List,
  Image,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  sortBy,
  groupBy,
  find,
  filter,
  map,
  round,
} from 'lodash'
import converter from 'number-to-words'
import { capitalCase } from 'capital-case'

import BaselineMeanScoresChart from '../../charts/baseline-scores/baseline-mean-scores'
import BaselineCountsChart from '../../charts/baseline-counts'
import AssessmentAnswerCard from '../assessment-answer-card'
import AssessmentMeanScoresUserSort from '../assessment-mean-scores-user-sort/assessment-mean-scores-user-sort'
import config from '../../../config'

const MIN_GROUP_DATA_POINTS = 25

const COMPARE_OPTIONS = [
  { text: 'Gender', value: 'gender' },
  { text: 'Grade Level', value: 'grade' },
  { text: 'Age', value: 'age' },
  { text: 'Ethnicity', value: 'ethnicity' },
  { text: 'GPA', value: 'gpa' },
]
const getChartHeader = (compareBy, compareByValue) => {
  compareBy = compareBy || 'overall'
  const funcsByCompareBy = {
    overall: () => 'Overall',
    gender: (value) => {
      const values = {
        female: 'Female',
        male: 'Male',
        not_mention: 'Not Mention',
        neither: 'Neither',
      }
      return values[value]
    },
    grade: (value) => converter.toOrdinal(+value),
    age: (value) => `${value} Years`,
    ethnicity: (value) => capitalCase(value),
    gpa: (value) => value,
  }
  return funcsByCompareBy[compareBy](compareByValue)
}

const DEFAULT_BAR_COLOR = '#224147'
const COLORS_BY_ATTEMPT = {
  '1': DEFAULT_BAR_COLOR,
  '2': '#3e6870',
  '3': '#5c929c',
  '4': '#7cbecb',
}

const ChartGroup = ({
  showResponseCounts,
  data,
  minimumEnabled,
  compareBy,
  showByAttempt,
  isForIndividual,
  groupedOrgScores,
  isGlobal,
}) => {
  const notEnoughData = (!minimumEnabled) ? false : data.countsData.every((point) => point.Count < MIN_GROUP_DATA_POINTS)
  const compareByText = (!compareBy) ? '' : find(COMPARE_OPTIONS, { value: compareBy }).text + ': '
  return (
    <>
      {(!!compareBy) && (<Header as='h3'>{compareByText}<span className='base-teal' data-public>{getChartHeader(compareBy, data.key)}</span></Header>)}
      <div className='adjust-width-print' style={
        {
          display: 'flex', flexWrap: 'wrap', flex: 1, width: '100%',
        }
      }>
        <BaselineMeanScoresChart
          width={'100%'}
          chartData={data.dataByDomain}
          byAttempt={showByAttempt}
          isForIndividual={isForIndividual}
          groupedOrgScores={groupedOrgScores}
          isGlobal={isGlobal}
        />
        {(showResponseCounts) && (
          <BaselineCountsChart
            chartData={data.countsData}
            indexBy='Attempt'
            keys={[ 'Count' ]}
            width={'50%'}
            marginTop={200}
            attemptLabel='Attempt'
            responsesLabel='Number of Responses'
            layout='horizontal'
            maxValue={(notEnoughData) ? MIN_GROUP_DATA_POINTS + 5 : 'auto'}
          />
        )}
        <BaselineCountsChart
          chartData={data.timeData}
          indexBy='Attempt'
          keys={[ 'Hours' ]}
          width={(showResponseCounts ? '50%' : '100%')}
          marginTop={200}
          attemptLabel='Attempt'
          responsesLabel={(showResponseCounts) ? 'Average Time Spent in BASE' : 'Time Spent in BASE'}
          layout='horizontal'
          maxValue={(data.timeData.every((point) => point.Hours < 1)) ? 1 : 'auto'}
        />
      </div>
    </>
  )
}

ChartGroup.propTypes = {
  isForIndividual: PropTypes.bool,
  data: PropTypes.object,
  showResponseCounts: PropTypes.bool,
  minimumEnabled: PropTypes.bool,
  showByAttempt: PropTypes.bool,
  compareBy: PropTypes.string,
  groupedOrgScores: PropTypes.object,
  isGlobal: PropTypes.bool,
}

const AssessmentMeanScoresSummaryCard = ({
  t,
  assessment,
  userAssessmentScores,
  userAssessments,
  onSchoolYearChange,
  onCompareByChange,
  onDownloadClick,
  loading,
  isForIndividual,
  schoolYear,
  schoolYearOptions,
  orgId,
  insightsEnabled,
  recommendedCourses,
  schoolYearEnrollmentsByCourseId,
  isDownloading,
  isInternalRole,
  onUserClick,
  userAssessmentScoresByOrgId,
  focusOrganization,
  onFocusOrganizationChange,
  startOrg,
  isGlobal,
}) => {
  const [ compareBy, setCompareBy ] = useState()
  const [ showByAttempt, setShowByAttempt ] = useState(false)
  const compareByScores = (compareBy) ? groupBy(userAssessmentScores, compareBy) : { overall: userAssessmentScores }
  const sectionNamesBySectionKey = assessment.sections.reduce((accum, section) => ({
    ...accum,
    [section.key]: section.name,
  }), {})
  const sectionKeys = map(assessment.sections, 'key')

  // flattens parent and children active orgs into an array of orgs
  const makeOrganizationsArray = (org, result = []) => {
    if (org.type && org.isActive) {
      result.push(org)

      if (org.children && org.children.length) {
        org.children.forEach((childOrg) => {
          makeOrganizationsArray(childOrg, result)
        })
      }
    }

    return result
  }
  const globalObj = {
    id: 'global',
    name: 'BASE Global Average',
  }
  const availableOrganizationsForUser = makeOrganizationsArray(startOrg).concat(globalObj)
  const availableOrganizationNames = availableOrganizationsForUser.map((org) => {
    return {
      text: org.name,
      value: org.id,
    }
  })

  const allData = (loading) ? [] : Object.keys(compareByScores).map((compareByKey) => {
    const scores = compareByScores[compareByKey]
    const groupedScoresDomain = groupBy(scores, 'sectionKey')
    const dataByDomain = sortBy(Object.keys(groupedScoresDomain), (sectionKey) => sectionKeys.indexOf(sectionKey)).map((sectionKey) => {
      const sortedData = sortBy(groupedScoresDomain[sectionKey], 'schoolYearAttempt')
      const id = sectionNamesBySectionKey[sectionKey]
      return sortedData.map((d, idx) => ([ {
        key: capitalCase(converter.toWordsOrdinal(d.schoolYearAttempt)),
        groupAvgScore: d.groupAvgScore,
        id,
        buckets: d.buckets,
        popAvgScore: d.popAvgScore,
        popNormalizedAvgScore: d.popNormalizedAvgScore,
        groupNormalizedAvgScore: d.groupNormalizedAvgScore,
      },
      ]))
    }).reverse()
    const groupedScores = groupBy(scores, 'schoolYearAttempt')
    const groupedOrgScores = groupBy(userAssessmentScoresByOrgId, 'schoolYearAttempt')
    const dataByAttempt = Object.keys(groupedScores).map((attempt) => {
      const sortedData = sortBy(groupedScores[attempt], (score) => sectionKeys.indexOf(score.sectionKey))
      const id = capitalCase(converter.toWordsOrdinal(attempt))
      return {
        id,
        color: COLORS_BY_ATTEMPT[attempt],
        data: sortedData.map((d) => ({
          d: d,
          x: sectionNamesBySectionKey[d.sectionKey],
          y: round(d.groupAvgScore, 2),
        })),
      }
    }).reverse()
    const countsData = Object.keys(groupedScores).map((attempt) => {
      const sortedData = sortBy(groupedScores[attempt], 'sectionKey')
      const id = capitalCase(converter.toWordsOrdinal(attempt))
      return sortedData.reduce((accum, d) => {
        return ({
          ...accum,
          CountColor: COLORS_BY_ATTEMPT[attempt],
          Count: d.count,
        })
      }, { Attempt: id + ' Attempt' })
    })
    const timeData = Object.keys(groupedScores).map((attempt) => {
      const sortedData = sortBy(groupedScores[attempt], 'sectionKey')
      const id = capitalCase(converter.toWordsOrdinal(attempt))
      return sortedData.reduce((accum, d) => {
        return ({
          ...accum,
          HoursColor: COLORS_BY_ATTEMPT[attempt],
          Hours: (d.groupAvgTimeSeconds) ? +(d.groupAvgTimeSeconds / 60 / 60).toFixed(2) : 0,
        })
      }, { Attempt: id + ' Attempt' })
    })
    return {
      key: compareByKey,
      dataByDomain,
      dataByAttempt,
      countsData,
      timeData,
      groupedOrgScores,
    }
  })
  const sortedData = sortBy(allData, (d) => d.countsData.reduce((accum, countData) => accum + countData.Count, 0)).reverse()
  const schoolYearUserAssessments = filter(userAssessments, { schoolYear })
  const hasAssessments = (isForIndividual && !!schoolYearUserAssessments && !!schoolYearUserAssessments.length) || (!isForIndividual && !!userAssessmentScores && !!userAssessmentScores.length)
  return (
    <React.Fragment>
      <Segment loading={loading}>
        <div style={{
          display: 'flex', alignItems: 'center', marginBottom: 10,
        }}>
          <Header as='h3' style={{ flexGrow: 2, marginBottom: 0 }}>
            <Icon name={(insightsEnabled) ? 'area chart' : 'file alternate outline'} />
            <Header.Content data-public>
              {(insightsEnabled) ? 'Baseline Summary' : 'Baseline Responses'}
            </Header.Content>
          </Header>
          {(onDownloadClick) && (
            <Button
              className='no-print'
              data-public
              icon='download'
              style={{ flexShrink: 2 }}
              size='mini'
              title='Download Data'
              loading={isDownloading}
              disabled={!hasAssessments && !loading}
              onClick={onDownloadClick}
            />
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Form style={{ flexGrow: 2 }}>
            <Form.Group>
              <Form.Field
                width={6}
                label='Time Range'
                control={Dropdown}
                style={{
                  fontSize: '.8rem',
                }}
                data-public
                selection
                size='mini'
                options={schoolYearOptions}
                value={schoolYear}
                onChange={(e, data) => {
                  onSchoolYearChange(data.value)
                }}
              />
              {(isForIndividual) && (
                <Form.Field
                  width={6}
                  label='Comparison Group'
                  control={Dropdown}
                  style={{
                    fontSize: '.8rem',
                  }}
                  selection
                  size='mini'
                  options={availableOrganizationNames}
                  value={!isGlobal ? focusOrganization.id : 'global'}
                  onChange={(e, data) => {
                    onFocusOrganizationChange(availableOrganizationsForUser.find((org) => org.id === data.value))
                  }}
                />
              )}
              {(!!onCompareByChange && !isForIndividual && insightsEnabled) && (
                <Form.Field
                  width={6}
                  control={Dropdown}
                  label='Compare'
                  style={{
                    fontSize: '.8rem',
                  }}
                  data-public
                  selection
                  clearable
                  size='mini'
                  options={COMPARE_OPTIONS}
                  value={compareBy}
                  onChange={(e, data) => {
                    setCompareBy(data.value)
                    onCompareByChange(data.value)
                  }}
                />
              )}
            </Form.Group>
          </Form>
          {(!isForIndividual) && (
            <Button.Group size='small' style={{ flexShrink: 2, alignSelf: 'center' }}>
              <Popup
                content='View Organization Data'
                size='small'
                trigger={(
                  <Button disabled={!hasAssessments && !loading} color='blue' icon onClick={() => {
                    setShowByAttempt(false)
                  }}>
                    <Icon name='chart building' />
                  </Button>
                )}
                hoverable
              />
              <Popup
                content='View Average Scores'
                size='small'
                trigger={(
                  <Button disabled={!hasAssessments && !loading} color='blue' active={showByAttempt} icon onClick={() => setShowByAttempt(true)}>
                    <Icon name='chart line' />
                  </Button>
                )}
                hoverable
              />
            </Button.Group>)}
        </div>
        {(!hasAssessments && !loading) && (
          <Message
            style={{ marginTop: 0 }}
            icon='warning circle'
            header='There are no completed assessments for this time range'
            content='Please select a different time range or wait for this student to complete the assessment.'
          />
        )}
        {(hasAssessments && !loading) && (
          <>
            {sortedData.map((d) => {
              return (
                <Segment
                  key={d.key}
                >
                  {(insightsEnabled) && (
                    <ChartGroup
                      isForIndividual={isForIndividual}
                      minimumEnabled={!isForIndividual}
                      showResponseCounts={!isForIndividual}
                      data={d}
                      compareBy={compareBy}
                      showByAttempt={showByAttempt}
                      groupedOrgScores={allData[0].groupedOrgScores}
                      isGlobal={isGlobal}
                    />
                  )}
                  {(isForIndividual) && (
                    <>
                      {(insightsEnabled) && (
                        <Divider horizontal>
                          <Header as='h4' data-public>
                            <Icon name='file alternate outline' className='base-teal' />
                          Responses
                          </Header>
                        </Divider>
                      )}
                      <AssessmentAnswerCard
                        assessment={assessment}
                        userAssessments={schoolYearUserAssessments}
                        userAssessmentScores={d.dataByAttempt}
                        insightsEnabled={insightsEnabled}
                        canDeleteAssessment={isInternalRole}
                      />
                      <Divider horizontal>
                        <Header as='h4' data-public>
                          <Icon name='fas fa-chalkboard' className='base-teal' />
                        Recommended Modules
                        </Header>
                      </Divider>
                      <List>
                        {recommendedCourses.map((c) => {
                          const enrollment = schoolYearEnrollmentsByCourseId[c.id] || {}
                          const typeLabel = (c.type === 'high') ? 'High School' : (c.type === 'middle') ? 'Middle School' : ''
                          const desc = (enrollment.completedOn) ? 'Completed' : (enrollment.startedOn) ? 'In Progress' : 'Not Started'
                          const descColor = (enrollment.completedOn) ? 'base-green' : (enrollment.startedOn) ? 'base-blue' : ''
                          return (
                            <List.Item data-public key={c.id}>
                              <Image style={{ width: 45 }} rounded size='mini' src={c.imageUrl} />
                              <List.Content>
                                <List.Header>{c.titles} ({typeLabel})</List.Header>
                                <List.Description className={`text italic ${descColor}`}>
                                  {desc}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          )
                        })}
                      </List>
                    </>
                  )}
                </Segment>
              )
            })}
          </>
        )}
      </Segment>
      {((config.featureFlags.baselineUserSortEnabled || isInternalRole) && !isForIndividual) &&
        <AssessmentMeanScoresUserSort
          orgId={orgId}
          assessment={assessment}
          schoolYear={schoolYear}
          onUserClick={onUserClick}
          isInternalRole={isInternalRole}
        />
      }
    </React.Fragment>
  )
}
AssessmentMeanScoresSummaryCard.propTypes = {
  userAssessmentScores: PropTypes.arrayOf(PropTypes.object),
  userAssessments: PropTypes.arrayOf(PropTypes.object),
  schoolYearOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number,
  })),
  orgId: PropTypes.string,
  assessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        choices: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          subLabel: PropTypes.string,
        })),
      })),
    })),
  }),
  onDownloadClick: PropTypes.func,
  onUserClick: PropTypes.func,
  isForIndividual: PropTypes.bool,
  isDownloading: PropTypes.bool,
  isInternalRole: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSchoolYearChange: PropTypes.func,
  onCompareByChange: PropTypes.func,
  showCompareControls: PropTypes.bool,
  loading: PropTypes.bool,
  showNumberOfResponsesChart: PropTypes.bool,
  insightsEnabled: PropTypes.bool,
  schoolYear: PropTypes.number.isRequired,
  recommendedCourses: PropTypes.array,
  schoolYearEnrollmentsByCourseId: PropTypes.object,
  userAssessmentScoresByOrgId: PropTypes.arrayOf(PropTypes.object),
  onFocusOrganizationChange: PropTypes.func,
  homeOrganization: PropTypes.object,
  focusOrganization: PropTypes.object,
  startOrg: PropTypes.object,
  isGlobal: PropTypes.bool,
}
AssessmentMeanScoresSummaryCard.defaultProps = {
  recommendedCourses: [],
  schoolYearEnrollmentsByCourseId: {},
  userAssessmentScores: [],
  userAssessments: [],
  individualUserAssessmentScores: [],
  isForIndividual: false,
  isDownloading: false,
  onSchoolYearChange: console.log.bind(console, 'onSchoolYearChange'),
  onCompareByChange: console.log.bind(console, 'onCompareByChange'),
  showCompareControls: false,
  loading: false,
  showNumberOfResponsesChart: false,
  insightsEnabled: true,
  userAssessmentScoresByOrgId: [],
  onFocusOrganizationChange: console.log.bind(console, 'onFocusOrganizationChange'),
  homeOrganization: {},
  focusOrganization: {},
  startOrg: {},
  isGlobal: false,
}
export default translate([ 'components' ])(AssessmentMeanScoresSummaryCard)
