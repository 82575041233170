import React from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Segment,
  Header,
  Popup,
  Icon,
  Message,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  takeRight,
  sortBy,
  sumBy,
  map,
  compact,
  flatten,
  keyBy,
} from 'lodash'
import { ResponsiveBar } from '@nivo/bar'

import dropdownSearch from '../../../helpers/dropdown-search'
import exportToCsv from '../../../helpers/export-to-csv'
import getRoles from '../../../helpers/get-roles'

import './course-usage.css'

const ROLES = getRoles()
const ROLES_BY_TYPE = keyBy(ROLES, 'type')

const BAR_COLORS_BY_ROLE_TYPE = {
  admin: '#8dd3c7',
  supervisor: '#f9de11',
  student: '#B8D6C2',
  adult: '#95cff9',
  advisor: '#aeddff',
  courseEditor: '#fdb462',
  superAdmin: '#b3de69',
}
// '#8dd3c7',
// '#f9de11',
// '#bebada',
// '#f9513e',
// '#80b1d3',
// '#fdb462',
// '#b3de69',
// '#fccde5',
// '#d9d9d9',
// '#bc80bd',
// '#ccebc5',
// '#ffed6f',
// '#70ef64',
// '#fc84ff',
// '#ff907c',
// '#7cf4ff',
// '#8cff84',
// '#deff83',
// '#ffdd83',
// '#ffa883',
// '#ffc4e6',

const virticalTick = ({
  // eslint-disable-next-line react/prop-types
  textAnchor, textBaseline, value, x, y,
}) => {
  const MAX_LINE_LENGTH = 20
  const MAX_LINES = 3
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    'gm',
  )
  // eslint-disable-next-line react/prop-types
  const isOverLimit = value.length > TRIM_LENGTH
  const splitValues = value
    // eslint-disable-next-line react/prop-types
    .match(groupWordsByLength)
    .slice(0, MAX_LINES)
    .map((val, i, arr) => (
      <tspan
        key={val}
        dy={(i === 0) ? (arr.length - 1) * -5 : 12}
        x={-20}
        style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
      >
        {(isOverLimit && arr.length === i + 1) ? `${val}…` : val}
      </tspan>
    ))
  return (
    <g transform={`translate(${x},${y || 0})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  )
}

const TRANSLATION_PREFIX = 'charts.course-usage'

class CourseUsageChart extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedTimePeriod: 7,
      selectedRoleTypes: [],
    }
  }

  static propTypes = {
    rolesWithUsageByRoleType: PropTypes.object,
    courseTitlesById: PropTypes.object,
    courseTypesById: PropTypes.object,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    hideRoleSelection: PropTypes.bool,
    forRoom: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    rolesWithUsageByRoleType: {},
    courseTitlesById: {},
    courseTypesById: {},
    isLoading: false,
    hideRoleSelection: false,
    width: '100%',
  }

  doesRoleHaveUsage = (period, role) => {
    return Object.values(role.usage).some((data) => {
      const usage = +Math.ceil(sumBy(takeRight(data, period), 'y') / 60).toFixed(1)
      return usage > 0
    })
  }

  changeTimePeriod = (e, data) => {
    this.setState({ ...this.state, selectedTimePeriod: data.value })
  }

  changeRoleIds = (e, data) => {
    this.setState({ ...this.state, selectedRoleTypes: data.value })
  }

  handleChartClick = (data, e) => {
    const {
      rolesWithUsageByRoleType,
    } = this.props
    const {
      selectedTimePeriod: period,
    } = this.state
    const relaventRoles = Object.values(rolesWithUsageByRoleType || {}).filter(this.doesRoleHaveUsage.bind(this, period))

    if (e.shiftKey) {
      const otherRoles = (this.state.selectedRoleTypes.length)
        ? relaventRoles.filter((role) => role.name !== data.id && this.state.selectedRoleTypes.includes(role.id))
        : relaventRoles.filter((role) => role.name !== data.id)
      const otherRoleIds = map(otherRoles, 'id')
      this.setState({ ...this.state, selectedRoleTypes: otherRoleIds })
    } else {
      const matchingRole = Object.keys(rolesWithUsageByRoleType).find((roleType) => roleType === data.id.toLowerCase())
      if (this.state.selectedRoleTypes.length === 1 && this.state.selectedRoleTypes[0] === matchingRole.id) {
        this.setState({ ...this.state, selectedRoleTypes: [] })
      } else {
        this.setState({ ...this.state, selectedRoleTypes: [ matchingRole.id ] })
      }
    }
  }

  renderRoleOption = (role) => ({ text: ROLES_BY_TYPE[role.roleType].name, value: role.roleType })

  colorBy = (d) => d.data[`${d.id}Color`]

  downloadData = () => {
    const {
      rolesWithUsageByRoleType,
      courseTitlesById,
      courseTypesById,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
    } = this.state

    const rolesByType = rolesWithUsageByRoleType || {}
    const sortedRoles = Object.keys(rolesByType)
      .sort()
      .filter((roleType) => !selectedRoleTypes.length || selectedRoleTypes.includes(roleType))
      .map((roleType) => ({ usage: rolesByType[roleType].usage, roleType }))
    const rolesWithUsage = sortedRoles.filter(this.doesRoleHaveUsage.bind(this, period))

    const data = rolesWithUsage.map((role, i) => {
      const sortedCourseIds = sortBy(Object.keys(role.usage), (courseId) => courseTitlesById[courseId])
      const courseData = sortedCourseIds.map((courseId) => {
        if (!courseTitlesById[courseId]) {
          return null
        }
        const courseData = role.usage[courseId]
        const courseTitle = courseTitlesById[courseId]
        const courseType = courseTypesById[courseId]
        const activitySeconds = sumBy(takeRight(courseData, period), 'y')
        if (!courseTitle || !courseData || !activitySeconds) {
          return null
        }
        return {
          roleType: role.roleType,
          courseTitle,
          courseType,
          minutesOfActivity: Math.ceil(activitySeconds / 60).toFixed(1),
        }
      })
      return compact(courseData)
    })
    const date = new Date().toISOString().split('T')[0]

    exportToCsv(`BASE-course-activity-${date}-last-${period}-days.csv`, flatten(data), [ 'roleType', 'courseTitle', 'courseType', 'minutesOfActivity' ])
  }

  componentDidUpdate (prevProps) {
    const {
      rolesWithUsageByRoleType,
    } = this.props
    const {
      selectedRoleTypes,
    } = this.state
    if (selectedRoleTypes && selectedRoleTypes.length) {
      const rolesByType = rolesWithUsageByRoleType || {}
      const roleTypes = Object.keys(rolesByType)
      // if the org has changed, let's reset the selected roles
      if (!roleTypes.includes(selectedRoleTypes[0])) {
        this.setState({ ...this.state, selectedRoleTypes: [] })
      }
    }
  }

  render () {
    const {
      rolesWithUsageByRoleType,
      courseTitlesById,
      hideRoleSelection,
      isLoading,
      width,
      height,
      forRoom,
      t,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
    } = this.state

    const rolesByType = rolesWithUsageByRoleType || {}
    const sortedRoles = Object.keys(rolesByType)
      .sort()
      .filter((roleType) => !selectedRoleTypes.length || selectedRoleTypes.includes(roleType))
      .map((roleType) => ({ usage: rolesByType[roleType].usage, roleType }))
    const rolesWithUsage = sortedRoles.filter(this.doesRoleHaveUsage.bind(this, period))

    const dataByCourseIdMinutes = {}
    const dataByCourseIdHours = {}
    let maxValue = 0
    let longestCourseTitle = ''
    rolesWithUsage.forEach((role) => {
      Object.keys(role.usage).forEach((courseId) => {
        if (!courseTitlesById[courseId]) {
          return
        }
        const roleName = ROLES_BY_TYPE[role.roleType].name
        const courseData = role.usage[courseId]
        const courseTitle = courseTitlesById[courseId]
        if (!courseTitle || !courseData) {
          return
        }
        dataByCourseIdMinutes[courseId] = dataByCourseIdMinutes[courseId] || {}
        dataByCourseIdMinutes[courseId].courseTitle = courseTitle
        dataByCourseIdMinutes[courseId][roleName] = +Math.ceil(sumBy(takeRight(courseData, period), 'y') / 60).toFixed(1) // convert to number with 1 decimal place
        dataByCourseIdMinutes[courseId][`${roleName}Color`] = BAR_COLORS_BY_ROLE_TYPE[role.roleType]
        if (maxValue < dataByCourseIdMinutes[courseId][roleName]) {
          maxValue = dataByCourseIdMinutes[courseId][roleName]
        }

        dataByCourseIdHours[courseId] = dataByCourseIdHours[courseId] || {}
        dataByCourseIdHours[courseId].courseTitle = courseTitle
        dataByCourseIdHours[courseId][roleName] = sumBy(takeRight(courseData, period), 'y') / 3600
        dataByCourseIdHours[courseId][roleName] = +dataByCourseIdHours[courseId][roleName].toFixed(1) // convert to number with 1 decimal place
        dataByCourseIdHours[courseId][`${roleName}Color`] = BAR_COLORS_BY_ROLE_TYPE[role.roleType]

        if (dataByCourseIdMinutes[courseId][roleName] > 0 && longestCourseTitle.length < courseTitle.length) {
          longestCourseTitle = courseTitle
        }
      })
    })
    const data = (maxValue > 180) ? dataByCourseIdHours : dataByCourseIdMinutes
    const xAxisLabel = (maxValue > 180) ? t(`${TRANSLATION_PREFIX}.y_axis_label_hours`) : t(`${TRANSLATION_PREFIX}.y_axis_label_minutes`)
    const minHeightPerBar = 40
    Object.keys(data).forEach((courseId) => {
      const totalTime = Object.keys(data[courseId]).reduce((sum, roleName) => {
        if (typeof data[courseId][roleName] !== 'number') {
          return sum
        }
        return sum + data[courseId][roleName]
      }, 0)
      if (!totalTime) {
        delete data[courseId]
      }
    })
    const idealHeight = (Object.values(data).length * minHeightPerBar) + 60 || 0
    const dropdownOptions = [
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_week`), value: 7 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_30_days`), value: 30 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_90_days`), value: 90 },
    ]
    const hasData = !!Object.keys(data).length
    return (
      <Segment.Group className='courseUsage'>
        <Segment loading={isLoading}>
          <div style={{
            display: 'flex', alignItems: 'center', alignContent: 'flex-start',
          }}>
            <Header style={{ margin: 0 }} data-public className='usageHeader'>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
            <Popup
              trigger={(
                <div style={{
                  paddingLeft: 8, flexGrow: 2, fontSize: '1.2em',
                }}>
                  <Icon className='base-teal' name='help circle' />
                </div>
              )}
              position='bottom left'
              hoverable
            >
              <p data-public>{t((forRoom) ? `${TRANSLATION_PREFIX}.help.first_sentence_room` : `${TRANSLATION_PREFIX}.help.first_sentence`)}</p>
              <p data-public>{t(`${TRANSLATION_PREFIX}.help.second_sentence`)}</p>
              {(!hideRoleSelection) && (
                <>
                  <p data-public>{t(`${TRANSLATION_PREFIX}.help.third_sentence`)}</p>
                  <p data-public>{t(`${TRANSLATION_PREFIX}.help.fourth_sentence`)}</p>
                </>
              )}
            </Popup>
            <div>
              {t(`${TRANSLATION_PREFIX}.time_period_prefix`) + ' '}
              <Dropdown
                data-public
                className='course-usage-input'
                inline
                options={dropdownOptions}
                defaultValue={this.state.selectedTimePeriod}
                onChange={this.changeTimePeriod}
              />
            </div>
            <Button
              data-public
              className='downloadButton'
              basic
              size='small'
              icon='download'
              title={t(`${TRANSLATION_PREFIX}.download_button_label`)}
              onClick={this.downloadData}
            />
          </div>
          {!hasData && !isLoading && <Message info>
            <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.no_activity_header`)}</Message.Header>
            <p data-public>{t(`${TRANSLATION_PREFIX}.no_activity_description`)}</p>
          </Message>}
          {hasData && <div style={{
            height: height || idealHeight,
            width,
          }}>
            <ResponsiveBar
              padding={0.25}
              layout='horizontal'
              margin={{
                top: 10,
                right: 10,
                bottom: 40,
                left: 150,
              }}
              onClick={this.handleChartClick}
              colors={this.colorBy}
              labelSkipHeight={15}
              labelSkipWidth={60}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', '1.1' ] ] }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                renderTick: virticalTick,
              }}
              valueFormat={(value) => `${value} ${(value === 1) ? xAxisLabel.split(' ')[0].replace('s', '') : xAxisLabel.split(' ')[0]}`}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                legend: xAxisLabel,
                legendPosition: 'middle',
                legendOffset: 30,
              }}
              data={sortBy(Object.values(data), 'Student')}
              animate={false}
              indexBy='courseTitle'
              keys={ROLES.map((role) => role.name)}
            />
          </div>}
        </Segment>
        {(hasData && !hideRoleSelection) && (
          <Segment>
            <Dropdown
              data-public
              className='course-usage-input'
              multiple
              search={dropdownSearch()}
              upward
              selection
              fluid
              placeholder={t(`${TRANSLATION_PREFIX}.role_filter_label`)}
              options={rolesWithUsage.map(this.renderRoleOption)}
              value={this.state.selectedRoleTypes}
              onChange={this.changeRoleIds}
            />
          </Segment>
        )}
      </Segment.Group>
    )
  }
}

export default translate([ 'components' ])(CourseUsageChart)
