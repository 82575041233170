import gql from 'graphql-tag'

const GET_ORGS_BY_PARENT_ID = gql`

query getOrganizationsByParentId($parentId: ID, $first: PositiveInt, $after: ID) {
  organizationsByParentId(
    parentId: $parentId
    first: $first
    after: $after
  ) {
    endCursor
    hasNextPage
    organizations {
      id
      name
      isActive
      type
      contractStartDate
      contractEndDate
      parentIds
    }
  }
}
`

export default GET_ORGS_BY_PARENT_ID
