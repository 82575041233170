import querystring from 'querystring'

import {
  pickBy, isNil, isArray, isString, compact,
} from 'lodash'

const shouldBeIncludedInQueryString = (val) => {
  if (isArray(val)) {
    return compact(val).length
  }
  return (isString(val) && !!val.length) || (!isString(val) && !isNil(val))
}

const buildQueryString = (object) => {
  return querystring.stringify(pickBy(object, shouldBeIncludedInQueryString))
}

export default buildQueryString
