import gql from 'graphql-tag'

const PUT_ACTIVITY = gql`
mutation putActivity($activity: PutActivityInput!, $timeZoneOffset: String) {
  putActivity(activity: $activity, timeZoneOffset: $timeZoneOffset) {
    date
    orgId
    clientId
    courseId
    launches
  }
}
`

export default PUT_ACTIVITY
