
import React, {
  useState, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'

import Editable from './editable'

const EditableTextArea = ({
  name,
  text,
  type,
  placeholder,
  onChange,
  onEditStart,
  ...props
}) => {
  const inputRef = useRef()
  const [ t, setText ] = useState(text || '')
  const previousText = useRef(text)

  // watch for changes on the text prop and update the internal state
  useEffect(() => {
    if (text !== previousText.current) {
      setText(text)
      previousText.current = text
    }
  }, [ text ])

  return (
    <Editable
      text={t}
      name={name}
      placeholder={placeholder}
      childRef={inputRef}
      onEditComplete={() => onChange(t)}
      onEditStart={onEditStart}
      {...props}
    >
      <textarea
        ref={inputRef}
        rows={t.split('\n').length + 1}
        name={name}
        value={t}
        onChange={(e) => setText(e.target.value)}
        style={{ width: '100%' }}
      />
    </Editable>
  )
}

EditableTextArea.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEditStart: PropTypes.func,
}

EditableTextArea.defaultProps = {
  width: '100%',
  onChange: console.log.bind(console, 'onChange'),
  onEditStart: console.log.bind(console, 'onEditStart'),
}

export default EditableTextArea
