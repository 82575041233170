import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { withRouter } from 'react-router'
import { translate } from 'react-i18next'
import {
  Header,
  Segment,
  Icon,
  Label,
} from 'semantic-ui-react'
import {
  isEqual,
  pick,
  isFunction,
  isString,
} from 'lodash'

import './base-form.css'

// global unblock func
let unblock
// global before unload function
const onBeforeUnload = (event) => {
  // Prompts the user before closing the page, see:
  // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
  event.preventDefault()
  event.returnValue = ''
}
const TRANSLATION_PREFIX = 'forms.base-form'
// NOTE: any additional props that need to cause the form to re-render should go into "initialValues" for now
const PROPS_THAT_TRIGGER_UPDATES = [
  'subscription',
  'initialValues',
  'renderOnBlur',
  'header',
  'isLoading',
  'showLoadingOverlay',
  'disableWarning',
  'basic',
  'keepDirtyOnReinitialize',
  'initialValuesEqual',
  // NOTE: don't update when these funcs change - they should never update anyway.
  // 'render',
  // 'onSubmit',
  // 'validate',
  // 'mutators',
  // 'decorators',
]

export class BaseForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    subscription: PropTypes.object,
    t: PropTypes.func,
    render: PropTypes.func.isRequired,
    header: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    showLoadingOverlay: PropTypes.bool,
    disableWarning: PropTypes.bool,
    basic: PropTypes.bool,
    resetOnSubmit: PropTypes.bool,
  }

  static defaultProps = {
    subscription: {},
    header: 'Default Header',
    showLoadingOverlay: false,
    disableWarning: false,
    basic: false,
    resetOnSubmit: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      id: Math.random() + '-' + new Date().getTime(),
    }
  }

  componentDidMount () {
    const { history } = this.props
    history.__BASE_DIRTY_FORMS__ = history.__BASE_DIRTY_FORMS__ || {}
  }

  componentWillUnmount () {
    this.setBlocker(false)
  }

  shouldComponentUpdate (nextProps) {
    const next = pick(nextProps, PROPS_THAT_TRIGGER_UPDATES)
    const current = pick(this.props, PROPS_THAT_TRIGGER_UPDATES)
    return !isEqual(next, current)
  }

  // NOTE: all this logic is meant to get around the problem that the history module has with
  // the browser back button and how it actually still transitions the URL even if it is blocked.
  // The popstate listener is meant to be the handler for the browser back button, while the
  // beforeunload listener is meant to be the handler for when the browser closes or reloads.
  setBlocker = (shouldBlock, force = false) => {
    const {
      history,
      t,
    } = this.props
    const headerKey = this.state.id
    if (!history.__BASE_DIRTY_FORMS__ || !!history.__BASE_DIRTY_FORMS__[headerKey] === shouldBlock) {
      return
    }
    if (shouldBlock) {
      history.__BASE_DIRTY_FORMS__[headerKey] = this.formRef
    } else {
      delete history.__BASE_DIRTY_FORMS__[headerKey]
    }

    if (Object.keys(history.__BASE_DIRTY_FORMS__).length && !unblock) {
      window.addEventListener('beforeunload', onBeforeUnload)
      window.addEventListener('popstate', this.onPopState)
      unblock = history.block(() => {
        window.removeEventListener('popstate', this.onPopState)
        const elem = Object.values(history.__BASE_DIRTY_FORMS__)[0]
        setTimeout(() => {
          elem && elem.current && elem.current.scrollIntoView()
        }, 100)
        return t(`${TRANSLATION_PREFIX}.confirm_unsaved_changes`)
      })
    }

    if (!Object.keys(history.__BASE_DIRTY_FORMS__).length) {
      isFunction(unblock) && unblock()
      unblock = null
      window.removeEventListener('beforeunload', onBeforeUnload)
      window.removeEventListener('popstate', this.onPopState)
    }
  }

  onPopState = (event) => {
    const {
      history,
      t,
    } = this.props
    const headerKey = this.state.id
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.confirm_unsaved_changes`))
    if (!areYouSure) {
      const elem = Object.values(history.__BASE_DIRTY_FORMS__)[0]
      window.removeEventListener('popstate', this.onPopState)
      isFunction(unblock) && unblock()
      unblock = null
      delete history.__BASE_DIRTY_FORMS__[headerKey]
      history.push(history.location.pathname)
      setTimeout(() => {
        this.setBlocker(true)
        elem && elem.current && elem.current.scrollIntoView()
      }, 100)
    } else {
      this.setBlocker(false)
    }
  }

  renderForm = ({
    pristine,
    invalid,
    form,
    handleSubmit,
    ...renderProps
  }) => {
    const {
      t,
      render,
      header,
      isLoading,
      showLoadingOverlay,
      disableWarning,
      basic,
      resetOnSubmit,
    } = this.props
    this.setBlocker(!pristine)
    const headerElem = (isString(header)) ? <Header data-public className='inline'>{header}</Header> : (header) || null
    return (
      <Segment
        basic={basic}
        className={(!pristine && !invalid && !disableWarning) ? 'base-form warning' : 'base-form'}
        loading={showLoadingOverlay && isLoading}
      >
        {headerElem}
        {(!pristine && !invalid && !disableWarning) && (
          <Label
            className='float-right'
            color='orange'
            basic
            data-public
          >
            <Icon name='edit' />
            {t(`${TRANSLATION_PREFIX}.unsaved_changes`)}
          </Label>
        )}
        {render({
          pristine,
          invalid,
          submitDisabled: isLoading || pristine || invalid,
          form,
          handleSubmit: (e, data) => {
            handleSubmit(e, data)
            if (resetOnSubmit) {
              setTimeout(() => {
                // for some reason, resetting the form does not reset the "touched" flag on each field
                form.reset()
                const fields = form.getRegisteredFields()
                fields.forEach((fieldName) => form.resetFieldState(fieldName))
              }, 200)
            }
          },
          ...renderProps,
        })}
      </Segment>
    )
  }

  render () {
    const {
      history,
      t,
      render,
      subscription,
      header,
      isLoading,
      disableWarning,
      basic,
      ...props
    } = this.props
    return (
      <div className='base-form-container' ref={this.formRef}>
        <Form
          subscription={{
            ...subscription,
            pristine: true,
            invalid: true,
          }}
          {...props}
          render={this.renderForm}
        />
      </div>
    )
  }
}

export default withRouter(translate([ 'components' ])(BaseForm))
