import React, { Component } from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Container,
  Menu,
  Icon,
  Image,
} from 'semantic-ui-react'
import { connect } from 'react-redux'

import LanguageSelect from '../../common/language-select/language-select'
import Baseline from '../../common/baseline'
import { logout } from '../../../actions/authentication'
import config from '../../../config.js'

import './baseline-home.css'

export class BaselineHomeView extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
  }

  static path = '/'

  render () {
    const {
      t,
      logout,
      authState: {
        userId,
        orgId,
        accessToken,
        refreshToken,
      },
      i18n,
    } = this.props
    return (
      <div className='baseline-home'>
        <Menu className='top-nav'>
          <Menu.Item header className='logo' style={{ padding: 0 }}>
            <Image inline size='small' src={'https://media.base.education/img/base-7m-logo-color-no-solution.png'} />
          </Menu.Item>
          <Menu.Menu position='right'>
            <LanguageSelect/>
            <Menu.Item
              className='logout'
              onClick={() => {
                // reset language to english on logout
                i18n.changeLanguage('en')
                logout({ isUserInitiated: true })
              }}
              data-public
            >
              <Icon size='large' name='sign out'/>
              {t('views.home.menu_item_logout')}
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container>
          <Baseline
            userId={userId}
            orgId={orgId}
            assessmentId={config.baseline.currentAssessmentId}
            onBackToCourse={() => {
              // reset language to english on navigation away
              i18n.changeLanguage('en')
              window.open(`${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}course.base.education?auth=${window.btoa(JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))}`)
            }}
          />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
  logout,
}
const BaselineHomeViewContainer = connect(mapStateToProps, mapDispatchToProps)(BaselineHomeView)

export default translate([ 'components' ])(BaselineHomeViewContainer)
