import React from 'react'
import PropTypes from 'prop-types'

import '../../common.css'
import './progress-dots.css'
const BASE_COLOR = '#eeeeee'
const BASE_OUTLINE_COLOR = '#cccccc'
// Blue gradient
const BLUE_PROGRESS_COLORS = [
  '#055998',
  '#1a7ac3',
  '#4b9ddc',
  '#8bc2ec',
  '#b1d6f3',
].reverse()
// Green gradient
const GREEN_PROGRESS_COLORS = [
  '#b8d6c2',
  '#94c2a3',
  '#70ad85',
  '#4D9965',
  '#255b36',
]

const PROGRESS_COLORS = [
  '#de5626',
  '#f8bf00',
  '#90ad43',
  '#0892a5',
  '#71bbdb',
]

// Rainbow
// const PROGRESS_COLORS = [
//   '#e94e8e',
//   '#f1dB67',
//   '#c4e448',
//   '#f0a260',
//   '#b873da',
// ]
const ProgressDots = ({
  completed,
  radius,
  color,
  totalDots,
}) => {
  completed = Math.min(Math.max(0, completed), 100)
  const DEFAULT_PROGRESS_COLORS = (color === 'blue') ? BLUE_PROGRESS_COLORS : (color === 'scale') ? PROGRESS_COLORS : GREEN_PROGRESS_COLORS
  const increment = 100 / totalDots
  return (
    <React.Fragment>
      <svg height={radius * 2} width={radius * 14}>
        {
          // NOTE: solution for getting stroke to appear on the inside of the circle: https://stackoverflow.com/a/32162431
        }
        <defs>
          {Array.apply(null, Array(totalDots)).map((_, i) => {
            const threshold = increment * i + (increment / 2)
            return (<circle
              key={i}
              id={`circle${i}-${completed}`}
              cx={radius + (radius * i * 3)}
              cy={radius}
              r={radius}
              fill={(completed >= threshold) ? DEFAULT_PROGRESS_COLORS[i] : BASE_COLOR}
              stroke={BASE_OUTLINE_COLOR}
              strokeWidth={(completed >= threshold) ? 0 : '2%'}
            />
            )
          },
          )}

          {Array.apply(null, Array(totalDots)).map((_, i) => (
            <clipPath key={i} id={`clip${i}-${completed}`}>
              <use xlinkHref={`#circle${i}-${completed}`}/>
            </clipPath>
          ),
          )}

        </defs>

        {Array.apply(null, Array(totalDots)).map((_, i) => (
          <use key={i} xlinkHref={`#circle${i}-${completed}`} clipPath={`url(#clip${i}-${completed})`}/>

        ),
        )}
      </svg>
    </React.Fragment>
  )
}

ProgressDots.propTypes = {
  completed: PropTypes.number,
  radius: PropTypes.number,
  color: PropTypes.string,
  totalDots: PropTypes.number,
}

ProgressDots.defaultProps = {
  completed: 0,
  radius: 10,
  totalDots: 5,
}

export default ProgressDots
