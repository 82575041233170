import querystring from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import {
  Menu,
  Header,
  Icon,
  Button,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { flatten, isEqual } from 'lodash'
import { Switch, Redirect } from 'react-router'

import PrivateRoute from '../../common/private-route/private-route'
import ClientsView from './clients/clients'
import CreateClientsView from './clients/create-clients'
import ClientDetailsView, { helpers as clientDetailsViewHelpers } from './client-details/client-details'
import CourseDetailsView, { helpers as courseDetailsViewHelpers } from './course-details/course-details'
import CourseEditView, { helpers as courseEditViewHelpers } from './course-details/course-edit'
import CourseEditDetailsView, { helpers as courseEditDetailsViewHelpers } from './course-details/course-edit-details'
import ActivityView from './activity/activity'
import OrgBaselineView from './baseline/baseline'
import ManageOrganizationView from './manage-organization'
import CoursesView from './courses/courses'
// import getRoles from '../../../helpers/get-roles'
import buildQueryString from '../../../helpers/build-query-string'
import { invalidateClients } from '../../../actions/clients'
import { getAllCourses } from '../../../actions/courses'
import { invalidateClientActivity } from '../../../actions/client-activity'
import { invalidateCourseImages } from '../../../actions/course-images'
import { logout } from '../../../actions/authentication'
import { getOrgDetails } from '../../../helpers/organization'
import ClassLinkLogo from '../../common/logos/class-link'
import CleverLogo from '../../common/logos/clever'
import isStale from '../../../helpers/is-stale'
import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
import config from '../../../config.js'

import './organization.css'

const orgIdPathParam = ':orgId'
const path = `/organizations/${orgIdPathParam}` // Not used anymore
// const ROLES = getRoles({ externalOnly: true })

// Jest apparently doesn't like static methods on a class,
// so these need to be exported separately
export const helpers = {
  getOrgIdFromPath: (pathname) => {
    const match = pathname.match(new RegExp(`${path.replace(orgIdPathParam, '([a-f0-9]{24})').replace(/\//g, '\\/')}`))
    return (match && match[1]) ? match[1] : null
  },
  // all urls under a given org
  getOrgPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}`, // go to clients page
  getOrgClientsPath: (orgId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${(isArchived) ? ClientsView.archivedUsersPath : ClientsView.path}`,
  getOrgCreateClientsPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${CreateClientsView.path}`,
  getOrgClientDetailsPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientAccountPath(clientId, isArchived)}`,
  getOrgClientDetailsCourseProgressPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientCourseProgressPath(clientId, isArchived)}`,
  getOrgClientDetailsFirewordsPath: (orgId, clientId, isArchived) => `${path.replace(orgIdPathParam, orgId)}${clientDetailsViewHelpers.getClientUnreviewedFirewordsPath(clientId, isArchived)}`,
  getOrgCoursesPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${CoursesView.path}`,
  getOrgCourseDetailsPath: (orgId, courseId) => `${path.replace(orgIdPathParam, orgId)}${courseDetailsViewHelpers.getPath(courseId)}`,
  getOrgSettingsPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${ManageOrganizationView.path}`,
  getOrgActivityPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${ActivityView.path}`,
  getOrgEditCoursePath: (orgId, courseId) => `${path.replace(orgIdPathParam, orgId)}${courseEditViewHelpers.getPath(courseId)}`,
  getOrgEditCourseDetailsPath: (orgId, courseId, contentItemId) => `${path.replace(orgIdPathParam, orgId)}${courseEditDetailsViewHelpers.getPath(courseId, contentItemId)}`,
  getOrgBaselinePath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${OrgBaselineView.path}`,
  // getOrgRolesPath: (orgId) => `${path.replace(orgIdPathParam, orgId)}${RolesView.path}`,
}

export class OrganizationView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    homeOrg: PropTypes.object,
    currentOrg: PropTypes.object,
    invalidateClients: PropTypes.func.isRequired,
    invalidateClientActivity: PropTypes.func.isRequired,
    invalidateCourseImages: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    isNewVersion: PropTypes.bool.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getAllCourses: PropTypes.func.isRequired,
  }

  static path = path

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  navigateToPage = (searchParams) => {
    const queryStr = buildQueryString(searchParams)
    this.navigateTo(`${this.props.location.pathname}?${queryStr}`)
  }

  navigateToClients = () => this.navigateTo(helpers.getOrgClientsPath(this.props.match.params.orgId, false))

  navigateToOrgSettings = () => this.navigateTo(helpers.getOrgSettingsPath(this.props.match.params.orgId))

  navigateToOrgCourses = () => this.navigateTo(helpers.getOrgCoursesPath(this.props.match.params.orgId))

  navigateToOrgActivity = () => this.navigateTo(helpers.getOrgActivityPath(this.props.match.params.orgId))

  navigateToOrgRooms = () => this.navigateTo(helpers.getOrgRoomsPath(this.props.match.params.orgId))

  navigateToCreateClients = () => this.navigateTo(helpers.getOrgCreateClientsPath(this.props.match.params.orgId))

  navigateToManageCourses = () => this.navigateTo(helpers.getOrgManageCoursesPath(this.props.match.params.orgId))

  navigateToClientDetails = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToClientDetailsCourseProgress = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsCourseProgressPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToClientDetailsFirewords = (isArchived, clientId, orgId) => this.navigateTo(helpers.getOrgClientDetailsFirewordsPath(orgId || this.props.match.params.orgId, clientId, isArchived))

  navigateToCourseDetails = (courseId) => this.navigateTo(helpers.getOrgCourseDetailsPath(this.props.match.params.orgId, courseId))

  navigateToEditCourse = (courseId) => this.navigateTo(helpers.getOrgEditCoursePath(this.props.match.params.orgId, courseId))

  navigateToEditCourseDetails = (courseId, contentItemId) => this.navigateTo(helpers.getOrgEditCourseDetailsPath(this.props.match.params.orgId, courseId, contentItemId))

  navigateToOrgBaseline = () => this.navigateTo(helpers.getOrgBaselinePath(this.props.match.params.orgId))

  renderClientsView = (org, homeOrg, routeProps) => {
    const {
      location: { search },
      isNewVersion,
    } = this.props
    const searchParams = querystring.parse(search.replace('?', ''))
    if (searchParams.roleTypes) {
      searchParams.roleTypes = flatten([ searchParams.roleTypes ])
    }
    if (searchParams.completedCourseIds) {
      searchParams.completedCourseIds = flatten([ searchParams.completedCourseIds ])
    }
    return (
      <ClientsView
        basePath={helpers.getOrgPath(org.id)}
        organization={org}
        homeOrganization={homeOrg}
        onSearchParamsChange={this.navigateToPage}
        searchParams={searchParams}
        onCreateClick={this.navigateToCreateClients}
        onClientClick={(clientId) => {
          this.navigateToClientDetails(false, clientId)
        }}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        isNewVersion={isNewVersion}
        {...routeProps}
      />
    )
  }

  renderCreateClientsView = (org, homeOrg, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CreateClientsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        homeOrganization={homeOrg}
        {...routeProps}
      />
    )
  }

  renderClientDetailsView = (org, homeOrg, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ClientDetailsView
        organization={org}
        homeOrganization={homeOrg}
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        showArchived={false}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        onUserClick={this.navigateToClientDetails.bind(this, false)}
        {...routeProps}
      />
    )
  }

  renderArchivedClientDetailsView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ClientDetailsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        showArchived={true}
        onReviewProgressClick={this.navigateToClientDetailsCourseProgress.bind(this, false)}
        onReviewFirewordsClick={this.navigateToClientDetailsFirewords.bind(this, false)}
        onUserClick={this.navigateToClientDetails.bind(this, false)}
        {...routeProps}
      />
    )
  }

  renderCoursesView = (org, routeProps) => {
    const { location: { search } } = this.props
    const activePage = querystring.parse(search.replace('?', '')).page || 1

    return (
      <CoursesView
        organization={org}
        onPageChange={this.navigateToPage}
        activePageNumber={activePage * 1}
        onManageClick={this.navigateToManageCourses}
        onCourseClick={this.navigateToCourseDetails}
        {...routeProps}
      />
    )
  }

  renderCourseDetailsView = (org, routeProps) => {
    const { match: { params: { orgId } }, location: { search } } = this.props
    const searchParams = querystring.parse(search.replace('?', ''))
    if (searchParams.roleTypes) {
      searchParams.roleTypes = flatten([ searchParams.roleTypes ])
    }
    return (
      <CourseDetailsView
        organization={org}
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditClick={this.navigateToEditCourse}
        onSearchParamsChange={this.navigateToPage}
        searchParams={searchParams}
        {...routeProps}
      />
    )
  }

  renderCourseEditView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CourseEditView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditItemClick={this.navigateToEditCourseDetails}
        {...routeProps}
      />
    )
  }

  renderCourseEditDetailsView = (routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <CourseEditDetailsView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        onEditItemClick={this.navigateToEditCourseDetails}
        onBack={this.navigateToEditCourse}
        {...routeProps}
      />
    )
  }

  renderManageOrganizationView = (routeProps) => {
    const { homeOrg, match: { params: { orgId } } } = this.props
    return (
      <ManageOrganizationView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        homeOrganization={homeOrg}
        {...routeProps}
      />
    )
  }

  renderActivityView = (org, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <ActivityView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        {...routeProps}
      />
    )
  }

  renderBaselineView = (org, routeProps) => {
    const { match: { params: { orgId } } } = this.props
    return (
      <OrgBaselineView
        basePath={helpers.getOrgPath(orgId)}
        organizationId={orgId}
        organization={org}
        {...routeProps}
      />
    )
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getAllCourses,
      homeOrg,
      getCoursesState,
    } = this.props

    if (isStale(getCoursesState)) {
      getAllCourses({ accessToken, organizationId: homeOrg.id })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      match: { params: { orgId: currentOrgId } },
      location,
      isNewVersion,
      invalidateClients,
      invalidateClientActivity,
      getAllCourses,
      homeOrg,
      getCoursesState,
    } = this.props

    const searchParams = querystring.parse(location.search.replace('?', ''))
    const prevSearchParams = querystring.parse(prevProps.location.search.replace('?', ''))
    if (isNewVersion && ((!!location.search.replace('?', '').length && !isEqual(searchParams, prevSearchParams)) || !location.pathname.includes('/users'))) {
      invalidateClients()
    }
    if (isStale(getCoursesState)) {
      getAllCourses({ accessToken, organizationId: homeOrg.id })
    }

    if (prevProps.match.params.orgId !== currentOrgId) {
      invalidateClients()
      invalidateClientActivity()
    }
  }

  render () {
    const {
      homeOrg,
      currentOrg,
      isNewVersion,
      authState: {
        // userId,
        accessToken,
        refreshToken,
        roleType,
      },
      location: { pathname },
      logout,
    } = this.props
    const onCourses = (pathname.includes('/courses'))
    const onOrgSettings = (pathname.endsWith('/manage'))
    const onUsers = (pathname.includes('/users'))
    const onActivity = (pathname.endsWith('/activity'))
    const onBaseline = (pathname.endsWith('/baseline-summary'))
    const currentOrgDetails = getOrgDetails(currentOrg)

    return (
      <React.Fragment>
        {isNewVersion && (
          <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 2, margin: 0 }} className='org-name-header'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Header as='h2' style={{ flexGrow: 2, margin: 0 }} data-public><Icon className='base-teal' name={currentOrgDetails.icon} /> {currentOrg.name}</Header>
                  {(currentOrgDetails.ssoType === 'classlink') && (
                    <ClassLinkLogo height={27} width={27} color={'#333333'} />
                  )}
                  {(currentOrgDetails.ssoType === 'clever') && (
                    <CleverLogo height={27} width={27} color={'#333333'} />
                  )}
                </div>
              </div>
              <Button
                icon
                as='a'
                style={{
                  flexShrink: 2,
                  marginLeft: 10,
                  backgroundColor: 'white',
                  color: 'black',
                  borderColor: 'black',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  paddingTop: 10,
                }}
                href={`${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}course.base.education?auth=${window.btoa(JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }))}`}
                target='_blank'
              >
                <Icon name='external' /> Learner Portal
              </Button>
              <Button
                color='black'
                icon
                style={{ flexShrink: 2, marginLeft: 10 }}
                onClick={() => logout({ isUserInitiated: true })}
              >
                <Icon name='log out' /> Log Out
              </Button>
            </div>
            <Menu pointing>
              <Menu.Item
                data-public
                id='new-users-btn'
                name='Users'
                icon='users'
                active={onUsers}
                onClick={this.navigateToClients}
              />
              <Menu.Item
                data-public
                id='new-courses-btn'
                name='Modules'
                icon='university'
                active={onCourses}
                onClick={this.navigateToOrgCourses}
              />
              <Menu.Item
                data-public
                id='new-activity-btn'
                name='Activity'
                icon='line chart'
                active={onActivity}
                onClick={this.navigateToOrgActivity}
              />
              {(currentOrg.baselineAnalysisEnabled && config.baseline.enabledRoleTypes.includes(roleType)) && (
                <Menu.Item
                  data-public
                  id='new-baseline-btn'
                  name='baseline'
                  content='Baseline'
                  icon='area chart'
                  active={onBaseline}
                  onClick={this.navigateToOrgBaseline}
                />
              )}
              <Menu.Item
                data-public
                id='new-settings-btn'
                name='Settings'
                icon='setting'
                active={onOrgSettings}
                onClick={this.navigateToOrgSettings}
              />
            </Menu>
          </React.Fragment>
        )}
        <Switch>
          <PrivateRoute exact path={path + ClientsView.path} render={this.renderClientsView.bind(this, currentOrg, homeOrg)}/>
          <PrivateRoute exact path={path + CreateClientsView.path} render={this.renderCreateClientsView.bind(this, currentOrg, homeOrg)}/>
          <PrivateRoute path={path + ClientDetailsView.path} render={this.renderClientDetailsView.bind(this, currentOrg, homeOrg)}/>
          <PrivateRoute exact path={path + CoursesView.path} render={this.renderCoursesView.bind(this, currentOrg)}/>
          <PrivateRoute exact path={path + CourseDetailsView.path} render={this.renderCourseDetailsView.bind(this, currentOrg)}/>
          <PrivateRoute exact path={path + CourseEditView.path} render={this.renderCourseEditView}/>
          <PrivateRoute exact path={path + CourseEditDetailsView.path} render={this.renderCourseEditDetailsView}/>
          <PrivateRoute exact path={path + ManageOrganizationView.path} render={this.renderManageOrganizationView}/>
          <PrivateRoute exact path={path + OrgBaselineView.path} render={this.renderBaselineView.bind(this, currentOrg)}/>
          {(isNewVersion) && (
            <PrivateRoute exact path={path + ActivityView.path} render={this.renderActivityView.bind(this, currentOrg)}/>
          )}
          <Redirect to={path + ClientsView.path} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
  } = state
  return {
    getCoursesState,
  }
}
const mapDispatchToProps = {
  invalidateClients,
  invalidateClientActivity,
  invalidateCourseImages,
  getAllCourses,
  logout,
}
const OrganizationViewContainer = connect(mapStateToProps, mapDispatchToProps)(OrganizationView)

export default translate([ 'components' ])(OrganizationViewContainer)
