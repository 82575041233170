import React from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Message,
  Button,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  sortBy,
  reject,
  map,
  isEqual,
  cloneDeep,
  omit,
} from 'lodash'

import SpecificDayRangeAccessException from '../specific-day-range-access-exception/specific-day-range-access-exception'

import './one-time-exceptions.css'

const DATES_SORT_ORDER = [ 'start.year', 'start.month', 'start.date' ]

class OnetimeExceptions extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    oneTimeExceptions: PropTypes.array,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    color: 'teal',
    oneTimeExceptions: [],
    disabled: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    const nowString = this.getDateString(new Date())
    const { oneTimeExceptions } = props
    const exceptionsWithIds = oneTimeExceptions.map((e) => {
      const startDateStr = this.getDateString(new Date(`${e.start.year}-${e.start.month}-${e.start.date}`))
      const endDateStr = this.getDateString(new Date(`${e.end.year}-${e.end.month}-${e.end.date}`))
      return {
        ...e,
        id: `${startDateStr}|${endDateStr}`,
      }
    })
    const pastExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[1] < nowString)
    const currentExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[0] <= nowString && e.id.split('|')[1] >= nowString)
    const upcomingExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[0] > nowString)

    this.state = {
      pastExceptions: sortBy(pastExceptions, DATES_SORT_ORDER),
      currentExceptions: sortBy(currentExceptions, DATES_SORT_ORDER),
      upcomingExceptions: sortBy(upcomingExceptions, DATES_SORT_ORDER),
      isDirty: false,
    }
  }

  getDateString = (date) => {
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${date.getFullYear()}-${(month < 10) ? `0${month}` : month}-${(day < 10) ? `0${day}` : day}`
  }

  onRemoveOneTimeExceptions = (ids, type) => {
    const { [type]: exceptions } = this.state
    const newExceptions = exceptions.filter((e) => !ids.includes(e.id))
    this.setState({
      ...this.state, [type]: newExceptions, isDirty: true,
    })
  }

  onChangeOneTimeExceptions = (id, type, { startDate, endDate }) => {
    if (!startDate || !endDate) {
      return
    }
    const { [type]: exceptions } = this.state
    const newOneTimeException = {
      id,
      start: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        date: startDate.getDate(),
      },
      end: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        date: endDate.getDate(),
      },
    }
    const newExceptions = reject(exceptions, { id }).concat(newOneTimeException)
    this.setState({
      ...this.state, [type]: sortBy(newExceptions, DATES_SORT_ORDER), isDirty: true,
    })
  }

  addNewOneTimeException = (day) => {
    const { upcomingExceptions } = this.state
    const newExceptions = upcomingExceptions.slice(0).concat({ id: `${Date.now()}` })
    this.setState({ ...this.state, upcomingExceptions: newExceptions })
  }

  renderOneTimeExceptionSegment = (type, disabled, oneTimeException) => {
    const startDate = (oneTimeException.start) ? new Date() : undefined
    const endDate = (oneTimeException.end) ? new Date() : undefined
    // using this method to modify a local date, otherwise it could be in UTC and show one day off
    if (startDate) {
      startDate.setDate(oneTimeException.start.date)
      startDate.setMonth(oneTimeException.start.month - 1)
      startDate.setFullYear(oneTimeException.start.year)
    }
    if (endDate) {
      endDate.setDate(oneTimeException.end.date)
      endDate.setMonth(oneTimeException.end.month - 1)
      endDate.setFullYear(oneTimeException.end.year)
    }
    return (
      <Segment className={(type === 'currentExceptions') ? 'restricted' : ''} attached key={oneTimeException.id}>
        <SpecificDayRangeAccessException
          startDate={startDate}
          endDate={endDate}
          disabled={(disabled || type === 'pastExceptions')}
          onRemove={this.onRemoveOneTimeExceptions.bind(this, [ oneTimeException.id ], type)}
          onChange={this.onChangeOneTimeExceptions.bind(this, oneTimeException.id, type)}
        />
      </Segment>
    )
  }

  componentDidUpdate (prevProps, prevState) {
    const { onChange, oneTimeExceptions } = this.props
    const {
      pastExceptions,
      currentExceptions,
      upcomingExceptions,
      isDirty,
    } = this.state
    const exceptionsPropChanged = (!isEqual(prevProps.oneTimeExceptions, oneTimeExceptions))
    const stateChanged = (!isEqual(prevState.pastExceptions, pastExceptions) || !isEqual(prevState.currentExceptions, currentExceptions) || !isEqual(prevState.upcomingExceptions, upcomingExceptions.filter((e) => !!e.start)))
    if (exceptionsPropChanged && !stateChanged) {
      const nowString = this.getDateString(new Date())
      const exceptionsWithIds = oneTimeExceptions.map((e) => {
        const startDateStr = this.getDateString(new Date(`${e.start.year}-${e.start.month}-${e.start.date}`))
        const endDateStr = this.getDateString(new Date(`${e.end.year}-${e.end.month}-${e.end.date}`))
        return {
          ...e,
          id: `${startDateStr}|${endDateStr}`,
        }
      })
      const pastExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[1] < nowString)
      const currentExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[0] <= nowString && e.id.split('|')[1] >= nowString)
      const upcomingExceptions = exceptionsWithIds.filter((e) => e.id.split('|')[0] > nowString)
      this.setState({
        pastExceptions: sortBy(pastExceptions, DATES_SORT_ORDER),
        currentExceptions: sortBy(currentExceptions, DATES_SORT_ORDER),
        upcomingExceptions: sortBy(upcomingExceptions, DATES_SORT_ORDER),
        isDirty: false,
      })
    } else if (!exceptionsPropChanged && stateChanged && isDirty) {
      const allExceptions = pastExceptions
        .concat(currentExceptions)
        .concat(upcomingExceptions)
        .filter((e) => !!e.start && !!e.end)
        .map((e) => cloneDeep(omit(e, 'id')))
      onChange(allExceptions)
    }
  }

  render () {
    const {
      t,
      disabled,
    } = this.props

    const {
      pastExceptions,
      currentExceptions,
      upcomingExceptions,
    } = this.state
    return (
      <React.Fragment>
        <Message attached='top'>
          <Message.Header data-public>{t('common.one-time-exceptions.dates_header')}</Message.Header>
        </Message>
        {(currentExceptions.length > 0) && (<Message
          data-public
          negative
          attached
          icon='delete calendar'
          header='Students are currently prohibited from logging into BASE!'
          content='Modify the dates below to end the restriction if needed.'
        />)}

        <Message attached info>
          <Message.Content>
            <p data-public>{t('common.one-time-exceptions.description')}</p>
            <Message.List>
              <Message.Item data-public>{t('common.one-time-exceptions.description_list_item_1')}</Message.Item>
              <Message.Item data-public>{t('common.one-time-exceptions.description_list_item_2')}</Message.Item>
            </Message.List>
          </Message.Content>
        </Message>
        {(pastExceptions.length === 0 && currentExceptions.length === 0 && upcomingExceptions.length === 0) && (
          <Message data-public attached className='text grey italic'><Icon name='info circle' />{t('common.one-time-exceptions.no_restricted_dates')}</Message>
        )}
        {pastExceptions.map(this.renderOneTimeExceptionSegment.bind(this, 'pastExceptions', disabled))}
        {currentExceptions.map(this.renderOneTimeExceptionSegment.bind(this, 'currentExceptions', disabled))}
        {upcomingExceptions.map(this.renderOneTimeExceptionSegment.bind(this, 'upcomingExceptions', disabled))}
        <Segment
          className='one-time-exceptions-buttons'
          clearing
          attached='bottom'
        >
          <Button
            data-public
            type='button'
            icon='calendar plus'
            floated='right'
            color='blue'
            disabled={disabled}
            content={t('common.one-time-exceptions.add_restricted_dates')}
            onClick={this.addNewOneTimeException}
          />
          {!!pastExceptions.length && (
            <Button
              data-public
              type='button'
              icon='close'
              floated='right'
              color='red'
              disabled={disabled}
              content={t('common.one-time-exceptions.clear_past_dates')}
              onClick={this.onRemoveOneTimeExceptions.bind(this, map(pastExceptions, 'id'), 'pastExceptions')}
            />
          )}
        </Segment>
      </React.Fragment>
    )
  }
}

export default translate([ 'components' ])(OnetimeExceptions)
