import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  Segment,
  Label,
  Button,
  Message,
  Input,
  Form as SemanticForm,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'

import {
  pattern,
  minLength,
  maxLength,
  composeValidators,
  required,
} from '../../../helpers/form-validators'
import { trimInputOnBlur, lowerCaseFormatter } from '../../../helpers/form'

import './login.css'

const validateUserName = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
  required({ message: 'forms.error_required' }),
  pattern({ message: 'forms.login.user_name_error_pattern', pattern: /^[^\s]+$/ }),
)
const validatePassword = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
  required({ message: 'forms.error_required' }),
)

export class LoginForm extends Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showInvalidCredentialsError: PropTypes.bool,
    showNotConnectedError: PropTypes.bool,
    showNotAllowedError: PropTypes.bool,
    showStudentsNotAllowedError: PropTypes.bool,
    showRestrictedError: PropTypes.bool,
    showAuthExpiredError: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onLogin: console.log.bind(console, 'onLogin'),
    color: 'teal',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  static path = '/login'

  handleLogin = ({ userName, password }) => {
    userName = userName.trim().toLowerCase()
    this.props.onLogin({ userName, password })
  }

  render () {
    const {
      isLoading,
      showInvalidCredentialsError,
      showNotConnectedError,
      showNotAllowedError,
      showStudentsNotAllowedError,
      showRestrictedError,
      showAuthExpiredError,
      t,
    } = this.props

    const hasError = (
      showInvalidCredentialsError ||
      showNotConnectedError ||
      showNotAllowedError ||
      showStudentsNotAllowedError ||
      showRestrictedError ||
      showAuthExpiredError
    )
    let errorMessageHeader = null
    let errorMessageContent = null

    if (hasError) {
      errorMessageHeader = t('forms.login.error_header_login')
      errorMessageContent = (showInvalidCredentialsError)
        ? t('forms.login.error_invalid_credentials')
        : (showNotAllowedError)
          ? t('forms.login.error_not_allowed')
          : (showStudentsNotAllowedError)
            ? t('forms.login.error_students_not_allowed')
            : (showRestrictedError)
              ? t('forms.login.error_restricted')
              : (showAuthExpiredError)
                ? t('forms.login.error_auth_expired')
                : t('forms.error_not_connected')
    }

    return (
      <Form
        onSubmit={this.handleLogin}
        subscription={{ pristine: true, invalid: true }}
        initialValues={{ userName: '', password: '' }}
        render={({
          handleSubmit,
          pristine,
          invalid,
        }) => (
          <SemanticForm className='login-form' onSubmit={handleSubmit} error={hasError}>
            <Segment attached='top'>
              <Image src='https://media.base.education/img/base-7m-logo-color.png' size='large' centered />
            </Segment>
            <Segment attached inverted color='black'>
              <Field
                data-public
                name='userName'
                validate={validateUserName}
                format={lowerCaseFormatter}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <div>
                    <Input
                      data-public
                      className='login-input'
                      placeholder={t('forms.user_name_label', { postProcess: 'titleCase' })}
                      error={!!message && touched}
                      type='text'
                      label={{ icon: 'user', className: 'base-green-bg text white' }}
                      labelPosition='left'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                      onBlur={trimInputOnBlur(input)}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </div>
                )}
              />
              <br/>
              <Field
                name='password'
                validate={validatePassword}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <div>
                    <Input
                      className='login-input'
                      placeholder={t('forms.password_label', { postProcess: 'titleCase' })}
                      error={!!message && touched}
                      type='password'
                      label={{ icon: 'lock', className: 'base-green-bg text white' }}
                      labelPosition='left'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </div>
                )}
              />
              {hasError && <br/>}
              <Message
                data-public
                error
                header={errorMessageHeader}
                content={errorMessageContent}
              />
            </Segment>
            <Segment className='bottom-segment' attached='bottom'>
              <Button
                data-public
                type='submit'
                className='login-button base-green-bg text white'
                loading={isLoading}
                disabled={isLoading || pristine || invalid}>
                {t('forms.login.login_button')}
              </Button>
            </Segment>
          </SemanticForm>
        )}
      />
    )
  }
}

export default translate([ 'components' ])(LoginForm)
