import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Button,
  Input,
  Header,
  Popup,
  Segment,
  Icon,
  Label,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  find,
  flatMap,
  filter,
  pick,
  map,
  partialRight,
  sortBy,
} from 'lodash'

const TRANSLATION_PREFIX = 'common.notification-settings'
const INITIAL_ORG_COUNT = 10
const SHOW_MORE_ORGS_COUNT = 10
const getAllOrgs = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return []
  }
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  return map(childOrgs, partialRight(pick, [ 'id', 'name' ])).concat(getAllOrgs(children))
}

export class NotificationSettings extends Component {
  static propTypes = {
    onEnableSetting: PropTypes.func.isRequired,
    onDisableSetting: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    notificationSettings: PropTypes.array,
    notificationMethods: PropTypes.object.isRequired,
    orgWithChildren: PropTypes.object.isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onEnableSetting: console.log.bind(console, 'onEnableSetting'),
    onDisableSetting: console.log.bind(console, 'onDisableSetting'),
    notificationMethods: {},
    color: 'blue',
    isLoading: false,
    isSaving: false,
    isDisabled: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      count: INITIAL_ORG_COUNT,
      orgFilter: '',
    }
  }

  handleClick = (org, type, isEnable) => {
    const {
      onEnableSetting,
      onDisableSetting,
    } = this.props
    if (isEnable) {
      onEnableSetting(org.id, type.value)
    } else {
      onDisableSetting(org.id, type.value)
    }
  }

  showMore = () => {
    this.setState({ ...this.state, count: this.state.count + SHOW_MORE_ORGS_COUNT })
  }

  setOrgFilter = (e, data) => {
    this.setState({ ...this.state, orgFilter: data.value })
  }

  clearOrgFilter = () => {
    this.setState({ ...this.state, orgFilter: '' })
  }

  renderSetting = (org, type) => {
    const {
      isSaving,
      notificationSettings,
      notificationMethods,
      isDisabled,
      t,
      color,
    } = this.props

    const matchingSetting = find(notificationSettings, { type: type.value, orgId: org.id })
    return (
      <Table.Row key={`notification-setting-${type.value}`}>
        <Table.Cell collapsing textAlign='center'>
          <Popup
            hoverable
            content={(matchingSetting) ? t(`${TRANSLATION_PREFIX}.active_status`) : t(`${TRANSLATION_PREFIX}.inactive_status`)}
            position='right center'
            trigger={(
              <Label
                style={{ cursor: 'help' }}
                circular
                color={(matchingSetting) ? 'green' : 'red'}
                size='mini'
              />
            )}
          />
        </Table.Cell>
        <Table.Cell singleLine>
          <Popup
            hoverable
            content={type.help}
            position='right center'
            trigger={(
              <Header as='h5' floated='left' className='help'>
                <Icon name={type.icon} color={type.color} />
                <Header.Content data-public>{type.label}</Header.Content>
              </Header>
            )}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            label={{
              content: t(`${TRANSLATION_PREFIX}.delivery_methods.email`),
              icon: 'mail',
              color,
            }}
            value={notificationMethods.email || '< Unknown >'}
          />
        </Table.Cell>
        <Table.Cell collapsing textAlign='right'>
          <Button
            data-public
            disabled={isDisabled || isSaving}
            loading={isSaving}
            onClick={this.handleClick.bind(this, org, type, !matchingSetting)}
          >
            {(matchingSetting) ? t(`${TRANSLATION_PREFIX}.disable`) : t(`${TRANSLATION_PREFIX}.enable`)}
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  }

  render () {
    const {
      t,
      orgWithChildren,
      isLoading,
      isDisabled,
    } = this.props
    const {
      count,
      orgFilter,
    } = this.state

    const NOTIFICATION_TYPES = [
      {
        label: t(`${TRANSLATION_PREFIX}.types.fire_words.label`),
        help: t(`${TRANSLATION_PREFIX}.types.fire_words.help`),
        value: 'fireWord',
        icon: 'fire',
        color: 'black',
      },
      {
        label: t(`${TRANSLATION_PREFIX}.types.reviewed_fire_words.label`),
        help: t(`${TRANSLATION_PREFIX}.types.reviewed_fire_words.help`),
        value: 'reviewedFireWord',
        icon: 'fire extinguisher',
        color: 'black',
      },
      {
        label: t(`${TRANSLATION_PREFIX}.types.help.label`),
        help: t(`${TRANSLATION_PREFIX}.types.help.help`),
        value: 'help',
        icon: 'question circle',
        color: 'black',
      },
    ]

    const orgs = [ pick(orgWithChildren, [ 'id', 'name' ]) ].concat(getAllOrgs(orgWithChildren.children))
    const filteredOrgs = (orgFilter) ? orgs.filter((org) => org.name.toLowerCase().includes(orgFilter.toLowerCase())) : orgs
    const orgsSlice = (isLoading) ? [] : sortBy(filteredOrgs, 'name').slice(0, count)
    const showMore = (count < filteredOrgs.length)

    return (
      <React.Fragment>
        <Segment attached='top' loading={isLoading}>
          <Header>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
          <Message info>
            <Message.Content>
              <p>{t(`${TRANSLATION_PREFIX}.description`)}</p>
            </Message.Content>
          </Message>
          {isDisabled && (
            <Message icon warning>
              <Icon name='warning' />
              <Message.Content>
                {t(`${TRANSLATION_PREFIX}.description_disabled`)}
              </Message.Content>
            </Message>
          )}

          <Input
            fluid
            icon='search'
            placeholder={t(`${TRANSLATION_PREFIX}.search_orgs`)}
            onChange={this.setOrgFilter}
            value={orgFilter}
          />
        </Segment>
        {orgsSlice.map((org, index) => (
          <Segment attached={(index === orgs.length - 1) ? 'bottom' : true} key={`org-notifications-${org.id}`}>
            <Header as='h3'>
              <Icon name='building' />
              <Header.Content>{org.name}</Header.Content>
            </Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.table_headers.status`)}</Table.HeaderCell>
                  <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.table_headers.type`)}</Table.HeaderCell>
                  <Table.HeaderCell data-public singleLine>{t(`${TRANSLATION_PREFIX}.table_headers.delivery`)}</Table.HeaderCell>
                  <Table.HeaderCell> </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {NOTIFICATION_TYPES.map(this.renderSetting.bind(this, org))}
              </Table.Body>
            </Table>
          </Segment>
        ))}
        {(orgFilter && !orgsSlice.length) && (
          <Segment attached='bottom' placeholder>
            <Header data-public icon>
              <Icon name='search' />
              {t(`${TRANSLATION_PREFIX}.no_matches`)}
            </Header>
            <Segment.Inline>
              <Button
                data-public
                primary
                onClick={this.clearOrgFilter}
              >
                {t(`${TRANSLATION_PREFIX}.clear_filter`)}
              </Button>
            </Segment.Inline>
          </Segment>
        )}
        {showMore && (
          <Button
            data-public
            attached='bottom'
            onClick={this.showMore}
          >
            {t(`${TRANSLATION_PREFIX}.show_more`)}
          </Button>
        )}
      </React.Fragment>
    )
  }
}

export default translate([ 'components' ])(NotificationSettings)
