import gql from 'graphql-tag'

export const NAME = 'reparentOrganization'
const REPARENT_ORG = gql`
  mutation ${NAME}($id: ID!, $newParentId: ID!) {
    ${NAME}(id: $id, newParentId: $newParentId) {
      id
      name
    }
  }
`
export default REPARENT_ORG
