import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { translate } from 'react-i18next'
import {
  Grid, Button, Icon, Header,
} from 'semantic-ui-react'

import 'rc-slider/assets/index.css'
import './weekday-access-time-range.css'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

class WeekdayAccessTimeRange extends React.Component {
  constructor (props) {
    super(props)

    this.marks = {}
    this.step = 5
    this.min = 0
    this.max = 1440
    this.minRange = 60
    let current = this.min
    while (current <= this.max) {
      if (current % 240 === 0) {
        this.marks[current] = this.formatMinutes(current)
      }
      current += this.step
    }

    this.state = {
      value: [ props.startTime, props.endTime ],
    }
  }

  static propTypes = {
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    onRemove: console.log.bind(console, 'onRemove'),
    disabled: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  formatMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60)
    let formattedHour = hour % 12
    const suffix = (minutes < 720 || minutes === 1440) ? ' AM' : ' PM'
    if (formattedHour === 0) {
      formattedHour = '12'
    }
    let min = Math.min(minutes % 60)
    if (min < 10) {
      min = '0' + min
    }
    return `${formattedHour}:${min} ${suffix}`
  }

  updateValue = (newValue) => this.setState({ value: newValue })

  componentDidUpdate (prevProps) {
    const { startTime, endTime } = this.props
    if (prevProps.startTime !== startTime || prevProps.endTime !== endTime) {
      this.setState({ value: [ startTime, endTime ] })
    }
  }

  render () {
    const {
      label,
      t,
      onChange,
      onRemove,
      disabled,
    } = this.props
    const [ startMinute, endMinute ] = this.state.value
    const rangeText = (startMinute === 0 && endMinute === 1440)
      ? t('common.weekday-access-time-range.all_day')
      : `${this.formatMinutes(this.state.value[0])} - ${this.formatMinutes(this.state.value[1])}`
    return (
      <Grid textAlign='center' stackable className='time-range-sliders'>
        <Grid.Column verticalAlign='middle' width={2}><Header as='h5'>{label}</Header></Grid.Column>
        {disabled && (
          <Grid.Column verticalAlign='middle' width={12}>
            <Header as='h5'>{rangeText}</Header>
          </Grid.Column>
        )}
        {!disabled && (
          <Grid.Column width={12}>
            <Range
              onChange={this.updateValue}
              onAfterChange={onChange}
              tipFormatter={this.formatMinutes}
              placement='top'
              min={this.min}
              max={this.max}
              step={this.step}
              value={this.state.value}
              pushable={this.minRange}
              marks={this.marks}
              disabled={disabled}
              trackStyle={[ {
                backgroundColor: '#4D9965',
              } ]}
              handleStyle={[ {
                borderColor: '#4D9965',
              } ]}
              activeDotStyle={{
                borderColor: '#4D9965',
              }}
            />
          </Grid.Column>
        )}
        <Grid.Column width={2}>
          <Button
            icon
            color='red'
            type='button'
            onClick={onRemove}
            disabled={disabled}
          >
            <Icon name='remove'/>
          </Button>
        </Grid.Column>
      </Grid>
    )
  }
}

export default translate([ 'components' ])(WeekdayAccessTimeRange)
