import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form as SemanticForm, Segment, Input, Label, Dropdown, Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { map } from 'lodash'

import {
  minLength, maxLength, required, composeValidators,
} from '../../../helpers/form-validators'
import { dropdownOnChange, trimInputOnBlur } from '../../../helpers/form'

const validateKeyWords = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 2 }),
  maxLength({ message: 'forms.error_max_length', count: 10000 }),
)
const validatePhrases = maxLength({ message: 'forms.error_max_length', count: 10000 })
const validateCategory = required({ message: 'forms.error_required' })

// TODO: translate these
const CATEGORY_OPTIONS = {
  aggressive: 'Aggressive',
  depressive: 'Depressive',
  substance: 'Substance',
  sexual: 'Sexual',
  general_high_risk: 'General High Risk',
}

export class FirePhraseForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    keyWords: PropTypes.array,
    phrases: PropTypes.array,
    category: PropTypes.oneOf(Object.keys(CATEGORY_OPTIONS)),
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'teal',
    isLoading: false,
    keyWords: [],
    phrases: [],
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = ({
    keyWords,
    phrases,
    category,
  }) => {
    this.props.onSave({
      keyWords: (keyWords) ? keyWords.split(':').map((kw) => kw.trim()) : [],
      phrases: (phrases) ? phrases.split(':').map((kw) => kw.trim()) : [],
      category,
    })
  }

  render () {
    const {
      color,
      isLoading,
      keyWords,
      phrases,
      category,
      t,
    } = this.props
    return (
      <Segment>
        <Form
          onSubmit={this.handleSaveForm}
          subscription={{ pristine: true, invalid: true }}
          initialValues={{
            keyWords: keyWords.join(':'), phrases: phrases.join(':'), category,
          }}
          render={({
            handleSubmit, pristine, invalid,
          }) => (
            <SemanticForm className='fire-phrase-form' onSubmit={handleSubmit}>
              <Field
                name='keyWords'
                validate={validateKeyWords}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field disabled={isLoading}>
                    <label>{t('forms.fire-phrase.keywords_placeholder')} *</label>
                    <Input
                      className='fire-phrase-input'
                      placeholder={t('forms.fire-phrase.keywords_placeholder')}
                      error={!!message && touched}
                      type='text'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                      onBlur={trimInputOnBlur(input)}>
                    </Input>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <Field
                name='phrases'
                validate={validatePhrases}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field disabled={isLoading}>
                    <label>{t('forms.fire-phrase.phrases_placeholder')}</label>
                    <Input
                      className='fire-phrase-input'
                      placeholder={t('forms.fire-phrase.phrases_placeholder')}
                      error={!!message && touched}
                      type='text'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                      onBlur={trimInputOnBlur(input)}>
                    </Input>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <Field
                name='category'
                validate={validateCategory}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field disabled={isLoading}>
                    <label>{t('forms.fire-phrase.category_placeholder')} *</label>
                    <Dropdown
                      className='fire-phrase-input'
                      placeholder={t('forms.fire-phrase.category_placeholder')}
                      error={!!message && touched}
                      fluid
                      search
                      selection
                      disabled={isLoading}
                      {...input}
                      options={map(CATEGORY_OPTIONS, (val, key) => ({ text: val, value: key }))}
                      onChange={dropdownOnChange(input)}>
                    </Dropdown>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <br/>
              <Button
                type='submit'
                className='fire-phrase-button'
                floated='right'
                loading={isLoading}
                color={color}
                disabled={isLoading || pristine || invalid}>
                {t('forms.save_button')}
              </Button>
              <br style={{ clear: 'both' }}/>
            </SemanticForm>
          )}
        />
      </Segment>
    )
  }
}

export default translate([ 'components' ])(FirePhraseForm)
