import gql from 'graphql-tag'

const GET_ROOMS_BY_ORG_ID = gql`
query getRoomsByOrgId($orgId: String!) {
  roomsByOrgId(
    orgId: $orgId
  ) {
    rooms {
      id
      name
      imageUrl
      orgId
      ownerUserId
      studentIds
      requestedStudentIds
      courseIds
      firewordNotificationsEnabled
      firewordNotificationsForRoomCoursesOnly
      isOpenForRequests
      isPrivate
      lastActiveDate
      unreviewedFirewordCount
      highestUnreviewedFirewordSeverity
      created
      modified
    }
  }
}
`

export default GET_ROOMS_BY_ORG_ID
