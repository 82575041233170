import assert from 'assert'
import { keyBy, isFunction } from 'lodash'
import copyError from 'utils-copy-error'

import { FETCH_ACTIONS, FETCH_TYPES } from '../../helpers/fetch-constants'

const copyFetchError = (error) => {
  const newError = copyError(error)
  // httperr lib puts these props on an inherited prototype which
  // get lost when serializing to an Object when saving in local DB
  newError.statusCode = error.statusCode
  newError.title = error.title
  return newError
}

const defaultValueTransformStrategy = (value) => keyBy(value, 'id')

const reduceGetAllRequest = () => (state) => {
  return Object.assign({}, state, {
    isLoading: true,
    loadingStartTime: Date.now(),
    isStale: false,
    error: null,
    value: null,
  })
}

// valueTransformStrategy should always return new or cloned data
const reduceGetAllRequestSuccess = (value, valueTransformStrategy = defaultValueTransformStrategy) => (state) => {
  assert(isFunction(valueTransformStrategy), 'valueTransformStrategy must be a function')
  value = valueTransformStrategy(value)
  return Object.assign({}, state, {
    isLoading: false,
    isStale: false,
    error: null,
    value,
    lastUpdated: Date.now(),
  })
}

const reduceGetAllRequestFailure = (error) => (state) => {
  return Object.assign({}, state, {
    isLoading: false,
    error: copyFetchError(error),
    value: null,
    lastUpdated: Date.now(),
  })
}

const reduceGetRequest = (id) => (state) => {
  return Object.assign({}, state, {
    id,
    value: null,
    isLoading: true,
    loadingStartTime: Date.now(),
    error: null,
  })
}

const reduceGetRequestSuccess = (value) => (state) => {
  return Object.assign({}, state, {
    value,
    isLoading: false,
    error: null,
    lastUpdated: Date.now(),
  })
}

const reduceGetRequestFailure = (error) => (state) => {
  return Object.assign({}, state, {
    value: null,
    isLoading: false,
    error: copyFetchError(error),
    lastUpdated: Date.now(),
  })
}

const reducePostRequest = () => (state) => {
  return Object.assign({}, state, {
    id: null,
    isLoading: true,
    loadingStartTime: Date.now(),
    succeeded: false,
    error: null,
  })
}

// TODO: don't always assume the value is an id
const reducePostRequestSuccess = (value) => (state) => {
  return Object.assign({}, state, {
    id: value,
    isLoading: false,
    succeeded: true,
    error: null,
    lastUpdated: Date.now(),
  })
}

const reducePostRequestFailure = (error) => (state) => {
  return Object.assign({}, state, {
    id: null,
    isLoading: false,
    succeeded: false,
    error: copyFetchError(error),
    lastUpdated: Date.now(),
  })
}

const reducePutRequest = (id) => (state) => {
  return Object.assign({}, state, {
    id,
    isLoading: true,
    loadingStartTime: Date.now(),
    succeeded: false,
    error: null,
  })
}

const reducePutRequestSuccess = () => (state) => {
  return Object.assign({}, state, {
    isLoading: false,
    succeeded: true,
    error: null,
    lastUpdated: Date.now(),
  })
}

const reducePutRequestFailure = (error) => (state) => {
  return Object.assign({}, state, {
    isLoading: false,
    succeeded: false,
    error: copyFetchError(error),
    lastUpdated: Date.now(),
  })
}

const reduceDeleteRequest = (id) => (state) => {
  return Object.assign({}, state, {
    id,
    isLoading: true,
    loadingStartTime: Date.now(),
    succeeded: false,
    error: null,
  })
}

const reduceDeleteRequestSuccess = () => (state) => {
  return Object.assign({}, state, {
    isLoading: false,
    succeeded: true,
    error: null,
    lastUpdated: Date.now(),
  })
}

const reduceDeleteRequestFailure = (error) => (state) => {
  return Object.assign({}, state, {
    isLoading: false,
    succeeded: false,
    error: copyFetchError(error),
    lastUpdated: Date.now(),
  })
}

const reducersByAction = {
  [FETCH_ACTIONS.GET_ALL]: {
    [FETCH_TYPES.REQUEST]: reduceGetAllRequest,
    [FETCH_TYPES.SUCCESS]: reduceGetAllRequestSuccess,
    [FETCH_TYPES.FAILURE]: reduceGetAllRequestFailure,
  },
  [FETCH_ACTIONS.GET]: {
    [FETCH_TYPES.REQUEST]: reduceGetRequest,
    [FETCH_TYPES.SUCCESS]: reduceGetRequestSuccess,
    [FETCH_TYPES.FAILURE]: reduceGetRequestFailure,
  },
  [FETCH_ACTIONS.POST]: {
    [FETCH_TYPES.REQUEST]: reducePostRequest,
    [FETCH_TYPES.SUCCESS]: reducePostRequestSuccess,
    [FETCH_TYPES.FAILURE]: reducePostRequestFailure,
  },
  [FETCH_ACTIONS.PUT]: {
    [FETCH_TYPES.REQUEST]: reducePutRequest,
    [FETCH_TYPES.SUCCESS]: reducePutRequestSuccess,
    [FETCH_TYPES.FAILURE]: reducePutRequestFailure,
  },
  [FETCH_ACTIONS.DELETE]: {
    [FETCH_TYPES.REQUEST]: reduceDeleteRequest,
    [FETCH_TYPES.SUCCESS]: reduceDeleteRequestSuccess,
    [FETCH_TYPES.FAILURE]: reduceDeleteRequestFailure,
  },
}

export default function getFetchReducer (fetchAction, fetchType) {
  if (!fetchAction || !fetchType) {
    throw new Error('Missing or invalid parameters')
  }
  if (!Object.values(FETCH_ACTIONS).includes(fetchAction)) {
    throw new Error('Invalid fetch action')
  }
  if (!Object.values(FETCH_TYPES).includes(fetchType)) {
    throw new Error('Invalid fetch type')
  }
  return reducersByAction[fetchAction][fetchType]
}
