const dropdownSearch = (options) => {
  options = options || {}
  return (items, query) => {
    query = query || ''
    return items.filter((item) => {
      const text = (!item.text) ? '' : (options.caseSensitive) ? item.text : item.text.toLowerCase()
      const queryText = (options.caseSensitive) ? query : query.toLowerCase()
      return text && queryText && text.includes(queryText)
    })
  }
}

export default dropdownSearch
