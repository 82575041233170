import gql from 'graphql-tag'

const FIND_ORGS = gql`

query findOrganizations($searchText: String!, $limit: PositiveInt) {
  findOrganizations(
    searchText: $searchText
    limit: $limit
  ) {
    results {
      id
      name
      contractEndDate
      type
      isActive
    }
    hasMore
  }
}
`

export default FIND_ORGS
