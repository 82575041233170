import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Icon, Header,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import CourseUsageChart from '../../charts/course-usage'

import './course-usage-thumb.css'

const TRANSLATION_PREFIX = 'common.course-usage-thumb'

class UserActivityThumb extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    courseTitlesById: PropTypes.object,
    rolesWithUsageByRoleType: PropTypes.object,
    t: PropTypes.func,
  }

  static defaultProps = {
    isLoading: true,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  showModal = () => {
    this.setState({ isOpen: true })
  }

  hideModal = () => {
    this.setState({ isOpen: false })
  }

  render () {
    const {
      isLoading,
      courseTitlesById,
      rolesWithUsageByRoleType,
      t,
    } = this.props
    return (
      <React.Fragment>
        <Header
          as='h4'
          className='course-usage-chart-icon'
          icon
          textAlign='center'
          onClick={this.showModal}
        >
          <Icon name='chart bar' />
          {t(`${TRANSLATION_PREFIX}.label`)}
        </Header>

        <Modal
          className='messageModal'
          open={this.state.isOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={this.hideModal}
          size='large'
        >
          <Modal.Header><Icon size='large' name='chart bar'></Icon> {t(`${TRANSLATION_PREFIX}.label`)}</Modal.Header>
          <Modal.Content scrolling>
            <CourseUsageChart
              isLoading={isLoading}
              courseTitlesById={courseTitlesById}
              rolesWithUsageByRoleType={rolesWithUsageByRoleType}
            />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
}

export default translate([ 'components' ])(UserActivityThumb)
