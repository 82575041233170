import invalidateCollection from '../reducers/fetch/invalidate-collection'
import callApi from './call-api'
import {
  FETCH_ACTIONS,
  HTTP_METHODS,
} from '../helpers/fetch-constants'

const STORE_PATH_PREFIX = 'staff'

export function getAllStaff ({
  accessToken,
  organizationId,
  isArchived = false,
  allOrgs = false,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, `/staffOverviews?orgId=${organizationId}&isArchived=${isArchived}&allOrgs=${allOrgs}`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getAllStaff')
}

export function invalidateStaff () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateStaff' ]
}
