import gql from 'graphql-tag'

const GET_USER_ASSESSMENT_SCORES = gql`
query userAssessmentScoresByUserId($userId: String!, $assessmentId: String, $schoolYear: Int) {
  userAssessmentScoresByUserId(
    userId: $userId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
  ) {
    sectionKey
    popAvgTimeSeconds
    popTimeSecondsStdDev
    popAvgScore
    popNormalizedAvgScore
    popScoreStdDev
    groupAvgTimeSeconds
    groupAvgScore
    groupNormalizedAvgScore
    orgId
    schoolYearAttempt
    schoolYear
    grade
    age
    ethnicity
    gpa
    gender
    count
  }
}
`

export default GET_USER_ASSESSMENT_SCORES
