import React from 'react'
import PropTypes from 'prop-types'
import { Label, Icon } from 'semantic-ui-react'
import moment from 'moment-timezone'

class ClockLabel extends React.Component {
  static propTypes = {
    size: PropTypes.string,
    timeZone: PropTypes.string,
    hideIcon: PropTypes.bool,
  }

  static defaultProps = {
    size: 'big',
    hideIcon: false,
  }

  constructor (props) {
    super(props)
    const interval = setInterval(() => this.setState({ ...this.state, now: Date.now() }), 1000)
    this.state = {
      interval,
      now: Date.now(),
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  render () {
    const {
      timeZone,
      size,
      hideIcon,
    } = this.props
    return (
      <Label
        basic
        size={size}
        style={{
          whiteSpace: 'nowrap',
          marginLeft: 0,
          lineHeight: 'unset',
        }}
      >
        {(!hideIcon) && (
          <Icon name='clock' />
        )}
        {(timeZone) ? moment().tz(timeZone).format('h:mm:ss A z') : 'Choose a time zone'}
      </Label>
    )
  }
}

export default ClockLabel
