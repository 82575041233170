import React, {
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { Input, Grid } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import './date-range-input.css'

export const DateRangeInput = ({
  startDateLabel,
  endDateLabel,
  defaultStartDate,
  defaultEndDate,
  minimumStartDate,
  maximumStartDate,
  maximumEndDate,
  onChange,
  onFocus,
  onBlur,
  disabled,
}) => {
  const [ dates, setDates ] = useState({ startDate: defaultStartDate, endDate: defaultEndDate })
  const updateStartDate = (newValue) => {
    const endDate = (newValue > dates.endDate) ? newValue : dates.endDate
    setDates({ startDate: newValue, endDate })
    onChange({ startDate: newValue, endDate })
  }

  const updateEndDate = (newValue) => {
    setDates({ ...dates, endDate: newValue })
    onChange({ ...dates, endDate: newValue })
  }
  return (
    <Grid stackable className='date-range-input'>
      <Grid.Column width={8}>
        <DatePicker
          selectsStart
          startDate={dates.startDate}
          endDate={dates.endDate}
          showYearDropdown={true}
          showMonthDropdown={true}
          minDate={minimumStartDate}
          maxDate={maximumStartDate}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          customInput={(
            <Input
              type='text'
              fluid
              label={{
                basic: true,
                icon: 'calendar',
                content: startDateLabel,
              }}
            />
          )}
          selected={dates.startDate}
          onChange={updateStartDate}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <DatePicker
          selectsEnd
          startDate={dates.startDate}
          endDate={dates.endDate}
          showYearDropdown={true}
          showMonthDropdown={true}
          minDate={dates.startDate}
          maxDate={maximumEndDate}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          customInput={(
            <Input
              type='text'
              fluid
              label={{
                basic: true,
                icon: 'calendar',
                content: endDateLabel,
              }}
            />
          )}
          selected={dates.endDate}
          onChange={updateEndDate}
        />
      </Grid.Column>
    </Grid>
  )
}

DateRangeInput.propTypes = {
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  defaultStartDate: PropTypes.instanceOf(Date),
  defaultEndDate: PropTypes.instanceOf(Date),
  minimumStartDate: PropTypes.instanceOf(Date),
  maximumStartDate: PropTypes.instanceOf(Date),
  maximumEndDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
}

DateRangeInput.defaultProps = {
  onChange: console.log.bind(console, 'onChange'),
  disabled: false,
}

export default DateRangeInput
