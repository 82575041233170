import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { filter } from 'lodash'

import EnrollmentItem from '../enrollment-item'

import './user-enrollments-table.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.user-enrollments-table'

class UserEnrollmentsTable extends React.Component {
  static propTypes = {
    courses: PropTypes.arrayOf(PropTypes.object).isRequired,
    enrollments: PropTypes.arrayOf(PropTypes.object).isRequired,
    isSaving: PropTypes.bool,
    color: PropTypes.string,
    header: PropTypes.string,
    t: PropTypes.func.isRequired,
    onEnroll: PropTypes.func,
    onUnenroll: PropTypes.func,
    onReenroll: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    color: 'blue',
    header: 'Modules',
    isSaving: false,
    disabled: false,
  }

  renderEnrollmentRow = (course) => {
    const {
      enrollments,
      isSaving,
      onEnroll,
      onUnenroll,
      onReenroll,
      disabled,
    } = this.props
    const courseEnrollments = filter(enrollments, { courseId: course.id })
    return (
      <EnrollmentItem
        key={course.id}
        isSaving={isSaving}
        displayName={course.titles}
        itemId={course.id}
        contentItemCount={course.contentItems.length}
        courseEnrollments={courseEnrollments}
        onEnroll={onEnroll}
        onUnenroll={onUnenroll}
        onReenroll={onReenroll}
        disabled={disabled}
        discontinued={!course.isActive}
      />
    )
  }

  render () {
    const {
      courses,
      color,
      header,
      t,
    } = this.props

    return (
      <Table
        className='user-enrollments-table'
        color={color}
        striped={true}
        celled={true}
        selectable={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell data-public>{header}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.progress`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.last_activity`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.attempt_number`)}</Table.HeaderCell>
            <Table.HeaderCell data-public>{t(`${TRANSLATION_PREFIX}.actions`)}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {courses.filter((course) => ![ '5771f05d14aae69912bad0d7', '584ecbb7261e9fe97149376a' ].includes(course.id)).map(this.renderEnrollmentRow)}
        </Table.Body>
      </Table>
    )
  }
}

export default translate([ 'components' ])(UserEnrollmentsTable)
