const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.base.education',
    baseGraphQlUrl: process.env.REACT_APP_GRAPHQL_API_BASE_URL || 'https://api-prd.base.education/prd',
    timeoutSeconds: (process.env.REACT_APP_API_TIMEOUT_SECONDS) ? process.env.REACT_APP_API_TIMEOUT_SECONDS * 1 : 60,
  },
  baseline: {
    enabledRoleTypes: [ 'superAdmin', 'internalAdmin', 'accountManager', 'viewer', 'admin', 'supervisor', 'advisor' ],
    currentAssessmentId: 'a_90194f65_b1d0_4aea_b6e8_c1461fec1956',
  },
  featureFlags: {
    roomsTabEnabled: process.env.REACT_APP_ROOMS_TAB_ENABLED === 'true',
    baselineUserSortEnabled: process.env.REACT_APP_BASELINE_USER_SORT_ENABLED === 'true',
  },
  showFriendlyApiErrorMessages: true,
  logStoreEvents: false,
  redirectOnError: true,
}

export default config
