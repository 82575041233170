import gql from 'graphql-tag'

const GET_ROOMS_BY_OWNER_USER_ID = gql`
query getRoomsByOwnerUserId($userId: String!) {
  roomsByOwnerUserId(
    userId: $userId
  ) {
    rooms {
      id
      name
      imageUrl
      orgId
      ownerUserId
      studentIds
      requestedStudentIds
      courseIds
      firewordNotificationsEnabled
      firewordNotificationsForRoomCoursesOnly
      isOpenForRequests
      isPrivate
      lastActiveDate
      unreviewedFirewordCount
      highestUnreviewedFirewordSeverity
    }
    activity {
      daily {
        x
        y
      }
      weekly {
        x
        y
      }
      monthly {
        x
        y
      }
      quarterly {
        x
        y
      }
    }
    usage {
      courseId
      data {
        x
        y
      }
    }
  }
}
`

export default GET_ROOMS_BY_OWNER_USER_ID
