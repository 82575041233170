import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import moment from 'moment'
import {
  get,
  groupBy,
  sortBy,
  map,
  compact,
  difference,
  find,
  keyBy,
  isNumber,
  isString,
  last,
  filter,
  pick,
  omitBy,
  isNil,
} from 'lodash'
import {
  Segment,
  Header,
  Icon,
  Tab,
  List,
  Message,
  Button,
  Popup,
} from 'semantic-ui-react'

import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'
import RESET_REGISTRATION from '../../../../../helpers/graphql-queries/reset-registration'
import UNLOCK_REGISTRATION from '../../../../../helpers/graphql-queries/unlock-registration'
import GET_USER_COURSE_INFO from '../../../../../helpers/graphql-queries/get-user-course-info'
import GET_CLIENT_ACTIVITY_OVERVIEW from '../../../../../helpers/graphql-queries/get-client-activity-overview'
import { FETCH_ACTIONS } from '../../../../../helpers/fetch-constants'
import FetchResultMessage from '../../../../common/fetch-result-message/fetch-result-message'
import CourseAnswers from '../../../../common/course-answers'
import ClientTotalCourseUsageChart from '../../../../charts/client-total-course-usage'
import ClientTotalCourseUsageSessionsChart from '../../../../charts/client-total-course-usage/client-total-course-usage-sessions'
import ClientTotalCourseUsagePrivateRoomsChart from '../../../../charts/client-total-course-usage/client-total-course-usage-private-rooms'
import VideoUsageChart from '../../../../charts/video-usage'

import {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
} from '../../../../../actions/analyzed-answers'
import { createIgnisRating, acknowledgeCreateIgnisRating } from '../../../../../actions/ignis-ratings'
import { invalidateClientAnswers } from '../../../../../actions/answers'
import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay'
import {
  deleteClientEnrollments,
  invalidateClientEnrollments,
} from '../../../../../actions/enrollments'
import { getDisplayName } from '../../../../../helpers/user'
import { ROLE_TYPES } from '../../../../../helpers/get-roles'
import ResetEnrollmentModal from '../../../../common/reset-enrollment-modal/reset-enrollment-modal'

import './course-progress.css'
import { WELCOME_COURSE_IDS } from '../../../../../helpers/module-constants'

export class CourseProgressView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    postAnalyzedAnswersState: PropTypes.object.isRequired,
    getEnrollmentsState: PropTypes.object.isRequired,
    createAnalyzedAnswer: PropTypes.func.isRequired,
    createIgnisRating: PropTypes.func.isRequired,
    acknowledgeCreateIgnisRating: PropTypes.func.isRequired,
    invalidateClientAnswers: PropTypes.func.isRequired,
    invalidateClientEnrollments: PropTypes.func.isRequired,
    acknowledgeCreateAnalyzedAnswer: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    answersByQuestionId: PropTypes.object.isRequired,
    firePhrases: PropTypes.array,
    organization: PropTypes.object.isRequired,
    getStaffState: PropTypes.object,
    room: PropTypes.object,
    deleteClientEnrollments: PropTypes.func.isRequired,
    onReview: PropTypes.func,
  }

  static defaultProps = {
    onReview: () => null,
  }

  static path = '/course-progress'

  constructor (props) {
    super(props)
    this.state = {
      activeTabIndex: 0,
      resetEnrollmentId: null,
      shouldRefetch: false,
    }
  }

  dismissMessage = () => {
    this.props.acknowledgeCreateAnalyzedAnswer()
    this.props.invalidateClientAnswers()
  }

  handleUnenroll = (enrollmentIds) => {
    const {
      clientId,
      authState: { accessToken },
      deleteClientEnrollments,
    } = this.props
    if (!window.confirm('Are you sure you want to make this user retake the welcome module?')) {
      return
    }
    deleteClientEnrollments({
      ids: enrollmentIds,
      clientId,
      accessToken,
    })
  }

  handleAcknowledgeFireword = (isSafe, reason, questionId, answerId, attemptNumber) => {
    const {
      authState: { accessToken },
      clientId,
      createAnalyzedAnswer,
    } = this.props
    createAnalyzedAnswer({
      data: {
        isSafe,
        isSafeReason: reason,
        attemptNumber: attemptNumber,
        _meta: {
          relationships: [ {
            type: 'questions',
            id: questionId,
          } ],
        },
      },
      answerId,
      accessToken,
      clientId,
    })
    setTimeout(() => {
      this.props.onReview()
    }, 500)
  }

  handleRateIgnis = (rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData) => {
    const {
      authState: { accessToken },
      clientId,
      createIgnisRating,
    } = this.props
    analyzedCaptures = analyzedCaptures.map((ac) => ({
      ...pick(ac, [ 'ignisSeverity', 'receptivitiSeverity', 'isSafe', 'sentence' ]), matchedPhrases: [], sentenceTokens: [],
    }))
    analyzedCaptures = analyzedCaptures.map((ac) => (omitBy(ac, isNil)))
    createIgnisRating({
      data: {
        rating,
        questionText,
        questionId,
        sentence,
        answerId,
        analyzedCaptures,
      },
      accessToken,
      clientId,
    })
    refetchStudentData()
  }

  handleTabChange = (event, data) => this.setState({ ...this.state, activeTabIndex: data.activeIndex })

  componentDidUpdate (prevProps) {
    if (prevProps.postAnalyzedAnswersState.isLoading && !this.props.postAnalyzedAnswersState.isLoading) {
      this.setState({
        ...this.state,
        shouldRefetch: true,
      })
    }
  }

  componentWillUnmount () {
    setTimeout(this.dismissMessage.bind(this), 100)
  }

  render () {
    const {
      authState: { roleType },
      getClientsState,
      getCoursesState: { value: coursesById, isLoading: coursesLoading },
      getEnrollmentsState: { value: enrollmentsById, isLoading: enrollmentsLoading },
      getStaffState,
      showFriendlyApiErrorMessages,
      answersByQuestionId,
      postAnalyzedAnswersState,
      clientId,
      organization,
      invalidateClientEnrollments,
      room,
    } = this.props
    if (coursesLoading || enrollmentsLoading) {
      return (<FullScreenLoadingOverlay isActive={true}/>)
    }
    const staffUsersById = (getStaffState && getStaffState.value) ? getStaffState.value : {}
    const clientsById = (getClientsState && getClientsState.value) ? getClientsState.value : {}
    const client = get(clientsById, clientId) || {}
    const isStaffRole = ![ ROLE_TYPES.STUDENT, ROLE_TYPES.ADULT ].includes(client.roleType)
    const displayName = getDisplayName(client)
    const courses = (coursesById) ? Object.values(coursesById) : []
    const roomHasCourses = (!!room && !!room.courseIds && !!room.courseIds.length)
    const courseTitlesById = {}
    courses.forEach((course) => {
      courseTitlesById[course.id] = course.titles
    })
    const enrollments = (room) ? client.enrollments || [] : Object.values(enrollmentsById) || []
    const completedEnrollments = enrollments.filter((e) => !!e.completedOn)
    const completedEnrollmentsByCourseId = groupBy(completedEnrollments, 'courseId')
    const completedCourses = roomHasCourses ? sortBy(courses.filter((course) => room.courseIds.includes(course.id) && !!completedEnrollmentsByCourseId[course.id]), 'titles') : sortBy(courses.filter((course) => !!completedEnrollmentsByCourseId[course.id]), 'titles')

    const filteredEnrollments = enrollments.filter((e) => !WELCOME_COURSE_IDS.includes(e.courseId))
    const enrollmentsByCourseId = groupBy(filteredEnrollments, 'courseId')
    const filteredCourses = roomHasCourses ? sortBy(courses.filter((course) => room.courseIds.includes(course.id)), 'titles') : sortBy(courses.filter((course) => !!enrollmentsByCourseId[course.id]), 'titles')
    const orderedQuestionsByCourseId = {}
    filteredCourses.forEach((c) => {
      c.contentItems = sortBy(c.contentItems, 'ordinal')
      var orderedQuestions = map(c.contentItems, (contentItem) => {
        if (!contentItem.questionId) {
          return null
        }
        const matchingQuestion = find(c.questions, { id: contentItem.questionId })
        if (matchingQuestion && contentItem.notes) {
          return Object.assign({ notes: contentItem.notes }, matchingQuestion)
        }
        return matchingQuestion
      })
      orderedQuestions = compact(orderedQuestions)
      if (orderedQuestions.length !== c.questions.length) {
        console.warn(`Course: ${c.titles} - Exam questions length mismatch. Unable to match at least one question to a content item. Total question count:`, c.questions.length, 'related to CIs count:', orderedQuestions.length)
        console.warn('Question IDs without a content item:', difference(c.questions, orderedQuestions))
      }
      orderedQuestionsByCourseId[c.id] = orderedQuestions
    })
    const renderRegularAnswersPane = () => {
      const sessionEnrollments = filteredEnrollments.filter((e) => isString(e.attemptNumber) && e.attemptNumber.startsWith('s_'))
      const privateRoomEnrollments = filteredEnrollments.filter((e) => isString(e.attemptNumber) && e.attemptNumber.startsWith('room_'))
      const nonSessionEnrollments = filteredEnrollments.filter((e) => !isString(e.attemptNumber) || parseInt(e.attemptNumber))
      const nonSessionEnrollmentsByCourseId = groupBy(nonSessionEnrollments, 'courseId')
      const relevantCourses = roomHasCourses ? filteredCourses : filteredCourses.filter((course) => !!nonSessionEnrollmentsByCourseId[course.id])
      const placeholderSesssions = sessionEnrollments.map((e) => ({
        id: e.attemptNumber,
        name: 'A Previous Session',
        code: moment(e.modified).fromNow(),
      }))
      return (
        <Mutation
          mutation={UNLOCK_REGISTRATION}
        >
          {(unlockRegistration, { loading: isUnlocking }) => {
            return (
              <Mutation
                mutation={RESET_REGISTRATION}
                onCompleted={() => {
                  this.setState({
                    ...this.state,
                    resetEnrollmentId: null,
                  })
                }}
              >
                {(resetRegistration, { loading: isSaving }) => {
                  return (
                    <Query
                      variables={{
                        clientId: clientId,
                        courseIds: Object.keys(nonSessionEnrollmentsByCourseId),
                      }}
                      query={GET_USER_COURSE_INFO}
                      skip={!Object.keys(nonSessionEnrollmentsByCourseId).length}
                      fetchPolicy='network-only'
                      onCompleted={() => {
                        this.setState({
                          ...this.state,
                          shouldRefetch: false,
                        })
                      }}
                    >
                      {({
                        loading: studentDataLoading,
                        data: studentData,
                        refetch: refetchStudentData,
                      }) => {
                        if (studentDataLoading) {
                          return (<FullScreenLoadingOverlay isActive={true}/>)
                        }
                        if (this.state.shouldRefetch) {
                          setTimeout(refetchStudentData, 10)
                        }
                        const user = get(studentData, 'userCourseDetails[0]') || {}
                        const nonPrivateEnrollments = (!user.enrollments) ? [] : user.enrollments.map((enrollment) => ({
                          ...enrollment,
                          attemptNumber: enrollment.attemptNumber * 1 || enrollment.attemptNumber,
                        })).filter((enrollment) => isNumber(enrollment.attemptNumber))
                        const answers = ((!user.answers) ? [] : user.answers.map((answer) => ({
                          ...answer,
                          attemptNumber: answer.attemptNumber * 1 || answer.attemptNumber,
                        })))
                        const aas = (!user.analyzedAnswers) ? [] : user.analyzedAnswers.map((analyzedAnswer) => ({
                          ...analyzedAnswer,
                          attemptNumber: analyzedAnswer.attemptNumber * 1 || analyzedAnswer.attemptNumber,
                        }))
                        const fpcs = (!user.firePhraseCaptures) ? [] : user.firePhraseCaptures.map((firePhraseCapture) => ({
                          ...firePhraseCapture,
                          attemptNumber: firePhraseCapture.attemptNumber * 1 || firePhraseCapture.attemptNumber,
                        }))
                        const nonPrivateAnalyzedAnswersByQuestionId = groupBy(aas, 'questionId')
                        const nonPrivateFirePhrasesByQuestionId = groupBy(fpcs, 'questionId')
                        const nonPrivateEnrollmentsByCourseId = groupBy(nonPrivateEnrollments, 'courseId')
                        const hydratedAnswers = answers.map((ans) => {
                          const firePhraseCaptures = (!nonPrivateFirePhrasesByQuestionId[ans.questionId]) ? [] : filter(nonPrivateFirePhrasesByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                          const analyzedAnswer = (!nonPrivateAnalyzedAnswersByQuestionId[ans.questionId]) ? null : find(nonPrivateAnalyzedAnswersByQuestionId[ans.questionId], { attemptNumber: ans.attemptNumber })
                          return {
                            ...ans,
                            firePhraseCaptures,
                            analyzedAnswer,
                            hasUnreviewedFirePhrases: (!!firePhraseCaptures.length && !analyzedAnswer),
                          }
                        })
                        const nonPrivateAnswersByQuestionId = groupBy(hydratedAnswers, 'questionId')

                        return (
                          <>
                            <ResetEnrollmentModal
                              open={!!this.state.resetEnrollmentId}
                              onClose={() => this.setState({ ...this.state, resetEnrollmentId: null })}
                              onOptionSelect={(option) => {
                                resetRegistration({
                                  variables: {
                                    id: this.state.resetEnrollmentId,
                                    createNew: (option === 'new'),
                                  },
                                })
                                  .then(invalidateClientEnrollments)
                              }}
                            />
                            <FetchResultMessage
                              itemType='fireword acknowledgement'
                              success={false}
                              error={postAnalyzedAnswersState.error}
                              showFriendlyError={showFriendlyApiErrorMessages}
                              onDismiss={this.dismissMessage}
                            />
                            {(!!relevantCourses.length && !room) && (<ClientTotalCourseUsageChart
                              courseTitlesById={courseTitlesById}
                              clientUsageByCourse={client.enrollmentsUsage}
                            />)}
                            {(isStaffRole && !room) && (
                              <Query
                                query={GET_CLIENT_ACTIVITY_OVERVIEW}
                                variables={{ clientId: client.id, courseTypes: [ 'video' ] }}
                              >{({
                                  loading: videoLoading,
                                  data: videoUsage,
                                }) => {
                                  return (<VideoUsageChart
                                    isLoading={videoLoading}
                                    videoUsage={videoUsage && videoUsage.activityOverviewsByClientId}
                                    orgId={this.props.organization.id}
                                  />)
                                }}
                              </Query>
                            )}
                            {(!room || !room.isPrivate) && <Segment placeholder={!relevantCourses.length}>
                              {(!!relevantCourses.length && !room) && (<Header>Modules</Header>)}
                              {(!relevantCourses.length) && (<Header icon><Icon name='info circle' /> This user does not have any module progress</Header>)}
                              {relevantCourses.map((course) => (
                                <CourseAnswers
                                  key={`normal-enrollments-${course.id}`}
                                  id={`normal-enrollments-${course.id}`}
                                  isLoading={postAnalyzedAnswersState.isLoading}
                                  courseTitle={course.titles}
                                  questions={orderedQuestionsByCourseId[course.id]}
                                  answersByQuestionId={nonPrivateAnswersByQuestionId}
                                  firePhrasesByQuestionId={nonPrivateFirePhrasesByQuestionId}
                                  analyzedAnswersByQuestionId={nonPrivateAnalyzedAnswersByQuestionId}
                                  courseEnrollments={nonPrivateEnrollmentsByCourseId[course.id]}
                                  clientsById={staffUsersById}
                                  userName={client.userName}
                                  displayName={displayName}
                                  isSaving={postAnalyzedAnswersState.isLoading}
                                  onReviewedClick={this.handleAcknowledgeFireword}
                                  onIgnisRate={(rating, questionText, questionId, sentence, answerId, analyzedCaptures) => this.handleRateIgnis(rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData)}
                                  previousIgnisRatings={user.ignisRatings}
                                  firewordsEnabled={organization.firewordsEnabled}
                                  courseContentItems={course.contentItems}
                                  onResetClick={(enrollmentId) => this.setState({ ...this.state, resetEnrollmentId: enrollmentId })}
                                  onUnlockClick={(enrollmentId) => unlockRegistration({
                                    variables: {
                                      id: enrollmentId,
                                    },
                                  })
                                    .then(invalidateClientEnrollments)
                                  }
                                  isUnlocking={isUnlocking}
                                />
                              ),
                              )}
                            </Segment>}
                            {(!room || room.isPrivate) && ([ ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.INTERNAL_ADMIN, ROLE_TYPES.ACCOUNT_MANAGER, ROLE_TYPES.ADMIN ].includes(roleType) && privateRoomEnrollments.length >= 1) && (
                              <Segment>
                                {!room && <Header>Private Coursework (From Private Rooms)</Header>}
                                {!room && <ClientTotalCourseUsagePrivateRoomsChart
                                  courseTitlesById={courseTitlesById}
                                  clientUsageByCourse={client.enrollmentsUsage}
                                  sessionsById={keyBy(privateRoomEnrollments, 'id')}
                                />}
                                {privateRoomEnrollments.map((e) => {
                                  const course = coursesById && coursesById[e.courseId]
                                  if (!course) {
                                    return null
                                  }
                                  if (room && room.isPrivate && room.id !== e.attemptNumber.split('_')[1]) {
                                    return null // don't show responses from wrong private room
                                  }
                                  return (
                                    <CourseAnswers
                                      key={`private-room-${e.id}`}
                                      id={`private-room-${e.id}`}
                                      sessionId={e.attemptNumber}
                                      isLoading={postAnalyzedAnswersState.isLoading}
                                      courseTitle={course.titles}
                                      questions={orderedQuestionsByCourseId[course.id]}
                                      answersByQuestionId={answersByQuestionId}
                                      courseEnrollments={enrollmentsByCourseId[course.id]}
                                      clientsById={staffUsersById}
                                      userName={client.userName}
                                      displayName={displayName}
                                      isSaving={postAnalyzedAnswersState.isLoading}
                                      onReviewedClick={this.handleAcknowledgeFireword}
                                      onIgnisRate={(rating, questionText, questionId, sentence, answerId, analyzedCaptures) => this.handleRateIgnis(rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData)}
                                      previousIgnisRatings={user.ignisRatings}
                                      firewordsEnabled={organization.firewordsEnabled}
                                      courseContentItems={course.contentItems}
                                    />
                                  )
                                })}
                              </Segment>
                            )}
                            {(placeholderSesssions.length > 1 && !room) && (
                              <Segment>
                                <Header>Previous Coursework (From Sessions)</Header>
                                <ClientTotalCourseUsageSessionsChart
                                  courseTitlesById={courseTitlesById}
                                  clientUsageByCourse={client.enrollmentsUsage}
                                  sessionsById={keyBy(placeholderSesssions, 'id')}
                                />
                                {sessionEnrollments.map((e) => {
                                  const course = coursesById && coursesById[e.courseId]
                                  if (!course) {
                                    return null
                                  }
                                  return (
                                    <CourseAnswers
                                      key={`old-sessions-${e.id}`}
                                      id={`old-sessions-${e.id}`}
                                      sessionId={e.attemptNumber}
                                      isLoading={postAnalyzedAnswersState.isLoading}
                                      courseTitle={course.titles}
                                      questions={orderedQuestionsByCourseId[course.id]}
                                      answersByQuestionId={answersByQuestionId}
                                      courseEnrollments={enrollmentsByCourseId[course.id]}
                                      clientsById={staffUsersById}
                                      userName={client.userName}
                                      displayName={displayName}
                                      isSaving={postAnalyzedAnswersState.isLoading}
                                      onReviewedClick={this.handleAcknowledgeFireword}
                                      onIgnisRate={(rating, questionText, questionId, sentence, answerId, analyzedCaptures) => this.handleRateIgnis(rating, questionText, questionId, sentence, answerId, analyzedCaptures, refetchStudentData)}
                                      previousIgnisRatings={user.ignisRatings}
                                      firewordsEnabled={organization.firewordsEnabled}
                                      courseContentItems={course.contentItems}
                                    />
                                  )
                                })}
                              </Segment>
                            )}
                          </>
                        )
                      }}
                    </Query>
                  )
                }}
              </Mutation>
            )
          }}
        </Mutation>
      )
    }

    const renderCompletedCoursesTab = () => {
      const aYearAgo = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 365)).toISOString()
      const recentlyCompletedWelcomeEnrollments = sortBy(completedEnrollments.filter((e) => isString(e.attemptNumber) && e.attemptNumber.startsWith('welcome_session') && e.completedOn > aYearAgo), 'completedOn')
      const lastCompletedWelcomeEnrollment = last(recentlyCompletedWelcomeEnrollments)
      const recentlyCompletedWelcomeEnrollmentIds = map(recentlyCompletedWelcomeEnrollments, 'id')
      const completedWelcomeCourse = (lastCompletedWelcomeEnrollment) ? completedCourses.find((c) => c.id === lastCompletedWelcomeEnrollment.courseId) : null
      return (
        <Segment>
          <Header>Completed Modules</Header>
          {(!!completedWelcomeCourse) && (
            <Message icon>
              <Icon name='check' />
              <Message.Content>
                <Message.Header style={{ display: 'inline-block' }}>{completedWelcomeCourse.titles}</Message.Header>
                <Popup
                  hoverable
                  size='large'
                  position='left center'
                  mouseEnterDelay={300}
                  trigger={(
                    <Button
                      floated='right'
                      icon
                      color='black'
                      onClick={() => this.handleUnenroll(recentlyCompletedWelcomeEnrollmentIds)}
                    >
                      <Icon name='redo' /> Retake
                    </Button>
                  )}
                >
                  <Popup.Content>
                    <p>This will make this user retake the welcome module before gaining access to the rest of their modules.</p>
                  </Popup.Content>
                </Popup>
                <p>Completed On: <span className='text bold'>{moment(lastCompletedWelcomeEnrollment.completedOn).format('MMM D, YYYY')}</span></p>
              </Message.Content>
            </Message>
          )}
          <List>
            {completedCourses.map((course) => {
              const filteredAttempts = room ? completedEnrollmentsByCourseId[course.id].filter((e) => !WELCOME_COURSE_IDS.includes(e.courseId) && (!room.isPrivate || (!isNumber(e.attemptNumber) && e.attemptNumber.split('_')[1] === room.id))) : completedEnrollmentsByCourseId[course.id].filter((e) => (isNumber(e.attemptNumber) || parseInt(e.attemptNumber)) && !WELCOME_COURSE_IDS.includes(e.courseId))
              if (!filteredAttempts.length) {
                return null
              }
              const latestAttempt = last(sortBy(filteredAttempts, 'completedOn'))
              return (
                <List.Item key={`session-course-${course.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <List.Icon style={{ fontSize: '1.5em' }} className='base-teal fas fa-chalkboard'/>
                  <List.Content style={{ flexGrow: 2, width: 'auto' }}>
                    <List.Header>{course.titles}</List.Header>{!room && <span className='text grey italic'>Attempt: {latestAttempt.attemptNumber}</span>}
                  </List.Content>
                  <List.Content>
                    <p>Completed On: <span className='text bold'>{moment(latestAttempt.completedOn).format('MMM D, YYYY')}</span></p>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        </Segment>
      )
    }

    const panes = [
      {
        menuItem: 'Responses',
        render: renderRegularAnswersPane,
        key: `${clientId}-answers`,
      },
      {
        menuItem: 'List of Completed Modules',
        render: renderCompletedCoursesTab,
        key: `${clientId}-completed-courses`,
      },
    ]

    return (
      <Tab
        className='course-progress no-print'
        renderActiveOnly={true}
        onTabChange={this.handleTabChange}
        menu={{ secondary: true }}
        activeIndex={this.state.activeTabIndex}
        panes={panes}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: {
      [FETCH_ACTIONS.GET_ALL]: getClientsState,
    },
    analyzedAnswers: {
      [FETCH_ACTIONS.POST]: postAnalyzedAnswersState,
    },
    staff: { [FETCH_ACTIONS.GET_ALL]: getStaffState },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    enrollments: { [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getClientsState,
    getCoursesState,
    getStaffState,
    getEnrollmentsState,
    postAnalyzedAnswersState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  createAnalyzedAnswer,
  acknowledgeCreateAnalyzedAnswer,
  createIgnisRating,
  acknowledgeCreateIgnisRating,
  invalidateClientAnswers,
  deleteClientEnrollments,
  invalidateClientEnrollments,
}
const CourseProgressViewContainer = connect(mapStateToProps, mapDispatchToProps)(CourseProgressView)

export default translate([ 'components' ])(CourseProgressViewContainer)
