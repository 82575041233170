import gql from 'graphql-tag'

const GET_ORG_ACTIVITY = gql`
query activitiesByOrgId($orgId: ID!, $courseTypes: [String], $startDate: String) {
  activitiesByOrgId(
    orgId: $orgId
    courseTypes: $courseTypes
    startDate: $startDate
  ) {
      clientId
      courseType
      roleType
      orgId
      courseId
      date
      secondsElapsed
      courseProgress
      launches
  }
}
`

export default GET_ORG_ACTIVITY
