export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

export const FETCH_ACTIONS = {
  GET_ALL: 'getAll',
  ...HTTP_METHODS,
}

export const FETCH_TYPES = {
  REQUEST: 'request',
  SUCCESS: 'success',
  FAILURE: 'failure',
  INVALIDATE: 'invalidate',
  ACKNOWLEDGE: 'acknowledge',
}
