import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  List,
  Icon,
  Header,
  Grid,
  Segment,
  Popup,
  Modal,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  unionBy, reject, sortBy, map, union, uniqBy,
} from 'lodash'

import RemoteSearchInput from '../../common/remote-search-input'
import CreateStudentForm from './create-student'
import { getDisplayName } from '../../../helpers/user'

import '../../common.css'
import './create-room.css'

// const TRANSLATION_PREFIX = 'forms.create-room'

export class CreateRoomPage2 extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    canCreateStudents: PropTypes.bool,
    students: PropTypes.arrayOf(PropTypes.object),
    searchResults: PropTypes.arrayOf(PropTypes.object),
    isSearching: PropTypes.bool,
    onSearchChange: PropTypes.func.isRequired,
    onCreateStudent: PropTypes.func.isRequired,
    validateUserName: PropTypes.func,
    orgNamesById: PropTypes.object.isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    selectedStudents: [],
    searchResults: [],
    isSearching: false,
    canCreateStudents: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedStudents: props.students || [],
      showCreateStudentModal: false,
      storedSearchResults: props.searchResults,
    }
  }

  componentDidUpdate (prevProps) {
    const prevStudents = map(prevProps.students || [], 'id')
    const nextStudents = map(this.props.students || [], 'id')
    const prevSearchResults = map(prevProps.searchResults || [], 'id')
    const nextSearchResults = map(this.props.searchResults || [], 'id')
    const shouldUpdateStudents = (prevStudents.length !== nextStudents.length || union(prevStudents, nextStudents).length !== prevStudents.length)
    const shouldUpdateSearchResults = (prevSearchResults.length !== nextSearchResults.length || union(prevSearchResults, nextSearchResults).length !== prevSearchResults.length)
    if (shouldUpdateStudents || shouldUpdateSearchResults) {
      this.setState({
        ...this.state,
        selectedStudents: (shouldUpdateStudents) ? this.props.students : this.state.selectedStudents,
        storedSearchResults: (shouldUpdateSearchResults) ? this.props.searchResults : this.state.storedSearchResults,
      })
    }
  }

  render = () => {
    const {
      isSearching,
      onSearchChange,
      onChange,
      onCreateStudent,
      validateUserName,
      canCreateStudents,
      orgNamesById,
    } = this.props
    const {
      selectedStudents,
      showCreateStudentModal,
      storedSearchResults,
    } = this.state
    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={showCreateStudentModal}
          onClose={() => this.setState({ ...this.state, showCreateStudentModal: false })}
        >
          <Modal.Content>
            <Header as='h3'>
              <Icon name='user' />
              <Header.Content>Create Student Account</Header.Content>
            </Header>
            <CreateStudentForm
              onSave={onCreateStudent}
              onCancel={() => this.setState({ ...this.state, showCreateStudentModal: false })}
              validateUserName={validateUserName}
            />
          </Modal.Content>
        </Modal>
        <Grid divided columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 2, marginRight: 10 }}>
                  <RemoteSearchInput
                    id='create-room-student-search'
                    fluid
                    placeholder='Search for Students...'
                    isLoading={isSearching}
                    usePopup={false}
                    size={null}
                    searchResults={storedSearchResults}
                    preserveSearchOnSelect
                    searchOnInitialRender={true}
                    maxHeightStyle='100%'
                    pageSize={25}
                    enablePaging={true}
                    excludedItemIds={map(selectedStudents, 'id')}
                    onChange={(searchText, skip, limit) => {
                      const excludedIds = map(selectedStudents, 'id')
                      onSearchChange(searchText, skip, limit, excludedIds)
                    }}
                    onSelect={(item) => {
                      const students = sortBy(unionBy(selectedStudents, [ item ], 'id'), [ 'profile.lastName', 'profile.firstName', 'userName' ])
                      this.setState({ selectedStudents: students })
                      onChange(students)
                    }}
                    renderItemContent={(item) => {
                      const displayName = getDisplayName(item)
                      return (
                        <>
                          <List.Icon name={'user'} size='big' className='base-teal' verticalAlign='middle' />
                          <List.Content>
                            <List.Header className='base-teal'>{displayName}</List.Header>
                            <List.Description><Icon name='mail' /> {item.email}</List.Description>
                            <List.Description><Icon name='university' /> {orgNamesById[item.orgId] || 'Unknown Organization'}</List.Description>
                            {(!item.belongsToARoom) && (<List.Description><Icon className='base-red' name='remove user' /> Does not belong to a room</List.Description>)}
                          </List.Content>
                        </>
                      )
                    }}
                  />
                </div>
                {(canCreateStudents) && (
                  <div style={{ flexShrink: 2 }}>
                    <Popup
                      hoverable
                      header={`Can't find the student you are looking for?`}
                      content={`Create an account for them.`}
                      position='top center'
                      trigger={
                        <Button
                          id='create-room-create-student-button'
                          icon
                          color='blue'
                          onClick={() => this.setState({ ...this.state, showCreateStudentModal: true })}
                        >
                          <Icon name='plus' />
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' className='selected-room-items-header'>
                <Icon className='base-teal' name='users' />
                <Header.Content>Selected Students</Header.Content>
              </Header>
              {(!selectedStudents.length) && (
                <Segment placeholder>
                  <Header icon>
                    <Icon name='users' />
                    No students have been selected.
                  </Header>
                </Segment>
              )}
              {(!!selectedStudents.length) && (
                <Segment className='selected-room-items'>

                  <List
                    divided
                    verticalAlign='middle'
                  >
                    {selectedStudents.map((student) => {
                      const displayName = getDisplayName(student)
                      return (
                        <List.Item key={`room-student-${student.id}`}>
                          <List.Content floated='right'>
                            <Button
                              size='mini'
                              icon
                              color='red'
                              title='Remove Student'
                              onClick={() => {
                                const students = reject(selectedStudents, { id: student.id })
                                this.setState({ selectedStudents: students, storedSearchResults: uniqBy(storedSearchResults.concat([ student ]), 'id') })
                                onChange(students)
                              }}
                              style={{ marginTop: 13 }}
                            >
                              <Icon name='remove'/>
                            </Button>
                          </List.Content>
                          <List.Icon name={'user'} size='big' className='base-teal' verticalAlign='middle' />
                          <List.Content>
                            <List.Header className='base-teal'>{displayName}</List.Header>
                            <List.Description><Icon name='mail' /> {student.email}</List.Description>
                            <List.Description><Icon name='university' /> {orgNamesById[student.orgId] || 'Unknown Organization'}</List.Description>
                            {(!student.belongsToARoom) && (<List.Description><Icon className='base-green fas fa-medal' /> This student{`'`}s first room!</List.Description>)}
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

export default translate([ 'components' ])(CreateRoomPage2)
