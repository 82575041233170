import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { ResponsiveBar } from '@nivo/bar'
const defaultColorBy = ({ id, data }) => data[`${id}Color`]
// const roundToNearest = (x, interval) => Math.ceil(x / interval) * interval
const BaselineScores = ({
  height,
  width,
  chartData,
  indexBy,
  keys,
  attemptLabel,
  responsesLabel,
  colorBy,
  markers,
  maxValue,
  layout,
}) => {
  return (
    <div className={'adjust-width-smaller-chart-print'} style={{
      width,
      height: (height / 6) * (chartData.length + 3),
      fontWeight: 'bold',
    }}>
      <ResponsiveBar
        padding={0.3}
        margin={{
          top: 75,
          right: 60,
          bottom: 50,
          left: (layout === 'horizontal') ? 130 : 80,
        }}
        theme={{
          fontSize: 13,
        }}
        groupMode='grouped'
        layout={layout}
        enableLabel={true}
        labelTextColor={('white')}
        colors={colorBy}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', '1.1' ] ] }}
        labelSkipHeight={15}
        labelSkipWidth={20}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: (layout === 'horizontal') ? undefined : responsesLabel,
          legendPosition: 'middle',
          legendOffset: (layout === 'horizontal') ? -70 : -50,
          // tickValues: (layout === 'horizontal') ? undefined : tickValues,
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          legend: (layout === 'horizontal') ? responsesLabel : attemptLabel,
          legendPosition: 'middle',
          legendOffset: 40,
          // tickValues: (layout === 'horizontal') ? tickValues : undefined,
        }}
        data={chartData}
        animate={false}
        indexBy={indexBy}
        keys={keys}
        minValue={0}
        maxValue={maxValue}
        markers={markers}
      />
    </div>
  )
}
BaselineScores.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  keys: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.any,
  width: PropTypes.any,
  indexBy: PropTypes.string,
  attemptLabel: PropTypes.string,
  responsesLabel: PropTypes.string,
  colorBy: PropTypes.any,
  markers: PropTypes.array,
  maxValue: PropTypes.any,
  layout: PropTypes.string,
  t: PropTypes.func.isRequired,
}
BaselineScores.defaultProps = {
  chartData: [],
  height: 300,
  width: '100%',
  keys: [],
  colorBy: defaultColorBy,
  maxValue: 'auto',
  layout: 'horizontal',
}
export default translate([ 'components' ])(BaselineScores)
