import gql from 'graphql-tag'

const GET_RECOMMENED_COURSE_IDS_BY_USER_ID = gql`
query recommendedCourseIdsByUserId($userId: String!, $assessmentId: String, $schoolYear: Int) {
  recommendedCourseIdsByUserId(
    userId: $userId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
  )
}
`

export default GET_RECOMMENED_COURSE_IDS_BY_USER_ID
