import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const ConditionalField = ({
  when,
  condition,
  render,
  name, // if no name is specified, we don't need to render a Field component
  alwaysRender,
  validate,
  ...props
}) => (
  <Field
    name={when}
    subscription={{ value: true }}
    render={({ input: { value } }) => {
      const conditionMet = condition(value)
      const shouldRender = (alwaysRender || conditionMet)
      const renderFunc = (!name) ? () => render({ conditionMet, conditionalValue: value }) : () => (
        <Field
          {...props}
          validate={(conditionMet) ? validate : undefined} // only validate when condition is met
          name={name}
          render={(fieldData) => render({
            ...fieldData, conditionMet, conditionalValue: value,
          })}
        />
      )
      return (shouldRender) ? renderFunc() : null
    }}
  />
)

ConditionalField.propTypes = {
  when: PropTypes.string.isRequired,
  condition: PropTypes.func,
  render: PropTypes.func.isRequired,
  name: PropTypes.string,
  alwaysRender: PropTypes.bool,
  validate: PropTypes.func,
}

ConditionalField.defaultProps = {
  alwaysRender: false,
  condition: (value) => value,
}

export default ConditionalField
