import querystring from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Message, Icon } from 'semantic-ui-react'
import { translate } from 'react-i18next'

import { resetPassword, acknowledgePasswordResult } from '../../../actions/password'

import SetPasswordForm from '../../forms/set-password/set-password'
import LoginView from '../login/login'

import './set-password.css'

export class SetPasswordView extends React.Component {
  static propTypes = {
    password: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    acknowledgePasswordResult: PropTypes.func.isRequired,
    t: PropTypes.func,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static path = '/set-password'

  handleSetPassword = ({ newSecret }) => {
    const { resetPassword, location } = this.props
    const resetToken = querystring.parse(location.search.replace('?', '')).token || ''
    resetPassword({ newSecret, resetToken })
  }

  componentWillUnmount () {
    this.props.acknowledgePasswordResult()
  }

  render () {
    const {
      password,
      t,
    } = this.props

    const errorStatus = get(password, 'post.error.statusCode')
    return (
      <div className='set-password-page'>
        <SetPasswordForm
          onSetPassword={this.handleSetPassword}
          isLoading={password.post.isLoading}
          showNotValidError={errorStatus === 400 || errorStatus === 500}
          showNotConnectedError={errorStatus === 0} />
        <Message icon hidden={!password.post.succeeded}>
          <Icon name='info circle' />
          <Message.Content>
            <Message.Header>
              {t('views.set-password.success')}
            </Message.Header>
            <p><a href={`#${LoginView.path}`}>{t('views.set-password.click_here')}</a></p>
          </Message.Content>
        </Message>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    password,
  } = state
  return {
    password,
  }
}
const mapDispatchToProps = { resetPassword, acknowledgePasswordResult }
const SetPasswordViewContainer = connect(mapStateToProps, mapDispatchToProps)(SetPasswordView)

export default translate([ 'components' ])(SetPasswordViewContainer)
