// the amount of time to wait since the data was
// last retrieved before flagging the data as stale
const STALE_TIMEOUT_MS = 1000 * 60 * 30
// the amount of time to wait if an error occurred when the
// data was last retrieved before flagging the data as stale
const ERROR_STALE_TIMEOUT_MS = 1000 * 10
// the amount of time to allow for a loading resource before
// the loading is considered stale and attempts can be retried
const LOADING_STALE_TIMEOUT_MS = 1000 * 15

const checkIfStale = ({
  isLoading,
  loadingStartTime,
  isStale,
  lastUpdated,
  value,
  error,
}) => {
  const now = new Date().getTime()
  const hasNoData = (!value && !error)
  const retrievedDataIsOld = (!!value && (now - lastUpdated) > STALE_TIMEOUT_MS)
  const retrievedDataErrorIsOld = (!!error && (now - lastUpdated) > ERROR_STALE_TIMEOUT_MS)
  const isRecentlyLoading = (isLoading && (now - loadingStartTime) <= LOADING_STALE_TIMEOUT_MS)
  const hasBeenLoadingTooLong = (isLoading && (now - loadingStartTime) > LOADING_STALE_TIMEOUT_MS)
  if (hasBeenLoadingTooLong) {
    return true
  }
  return ((hasNoData || isStale || retrievedDataIsOld || retrievedDataErrorIsOld) && !isRecentlyLoading)
}

export default checkIfStale
