import React from 'react'
import PropTypes from 'prop-types'
import {
  Item,
  Statistic,
  Icon,
  Header,
  Popup,
  Label,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import moment from 'moment'

import CourseSelector from '../course-selector'

import './room-item.css'
const RoomItem = ({
  room,
  lastActiveDate,
  hasUnreviewedFirewords,
  t,
  tReady,
  staffName,
  ...props
}) => {
  const firewordColors = [ 'grey', 'yellow', 'orange', 'red' ]
  const lastActivity = (lastActiveDate) ? `Latest Activity: ${moment(lastActiveDate).fromNow()}` : 'No Activity Yet'
  const deprecatedModulesCount = room.courseIds.filter((id) => CourseSelector.coursePacks.secondary.courseIds.includes(id)).length
  return (
    <Item.Group unstackable className={(!!room.requestedStudentIds && room.requestedStudentIds.length) ? 'room-items requests' : 'room-items'}>
      <Item
        className='room-item'
        {...props}
      >
        <Item.Image size='small' src={room.imageUrl} />
        <Item.Content>
          <div style={{ display: 'flex' }}>
            <Header style={{
              margin: 0, flexGrow: 2, textAlign: 'left',
            }}>
              <span className={(deprecatedModulesCount) ? 'base-red' : ''}>{room.name}</span>
            </Header>
            {(hasUnreviewedFirewords) && (
              <Popup
                hoverable
                content='There are students in this room with unreviewed firewords'
                position='top right'
                trigger={(
                  <div style={{ marginLeft: 10, flexShrink: 2 }}>
                    <Icon name='fire' color={firewordColors[room.highestUnreviewedFirewordSeverity]} size='large' />
                  </div>
                )}
              />
            )}
          </div>
          <Item.Description>
            <Statistic.Group size='mini'>
              {(!!deprecatedModulesCount) && (
                <Popup
                  content='This room contains modules that have newer versions available. Please update the modules within this room.'
                  position='top center'
                  trigger={(
                    <Statistic>
                      <Statistic.Value data-public>
                        <Icon className='base-red' name='warning sign' />
                      </Statistic.Value>
                      <Statistic.Label data-public>Update Needed</Statistic.Label>
                    </Statistic>
                  )}
                />
              )}
              {(!!room.requestedStudentIds && !!room.requestedStudentIds.length) && (
                <Statistic>
                  <Statistic.Value data-public className='base-green'>
                    <Icon className='base-teal' name='add user' /> {room.requestedStudentIds.length}
                  </Statistic.Value>
                  <Statistic.Label data-public>{(room.requestedStudentIds.length === 1) ? 'Request' : 'Requests'}</Statistic.Label>
                </Statistic>
              )}
              <Statistic>
                <Statistic.Value data-public>
                  <Icon className='base-teal' name='user' /> {room.studentIds.length}
                </Statistic.Value>
                <Statistic.Label data-public>{(room.studentIds.length === 1) ? 'Student' : 'Students'}</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value data-public>
                  <Icon style={{ marginRight: 3 }} className='base-teal fas fa-chalkboard' /> {room.courseIds.length}
                </Statistic.Value>
                <Statistic.Label data-public>{(room.courseIds.length === 1) ? 'Module' : 'Modules'}</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value data-public>
                  <Icon className='base-teal' name='fire' /> {(room.firewordNotificationsEnabled) ? 'ON' : 'OFF'}
                </Statistic.Value>
                <Statistic.Label data-public>Alerts</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Item.Description>
          <Item.Extra data-public style={{ textAlign: 'left' }}>
            {(!!staffName) && (<span style={{ marginRight: 20 }}><Icon style={{ marginRight: 8 }} className='fas fa-chalkboard-teacher' /> {staffName}</span>)}
            <Icon name='calendar' /> {lastActivity}
          </Item.Extra>
        </Item.Content>
        {(room.isPrivate) && (
          <Popup
            hoverable
            content='Private Room'
            trigger={(
              <Label
                circular
                className='private-label base-red-bg'
              >
                <Icon
                  name='lock'
                />
              </Label>
            )}
          />
        )}
      </Item>
    </Item.Group>
  )
}

RoomItem.propTypes = {
  room: PropTypes.object.isRequired,
  lastActiveDate: PropTypes.string,
  t: PropTypes.func,
  tReady: PropTypes.bool,
  hasUnreviewedFirewords: PropTypes.bool,
  staffName: PropTypes.string,
}

RoomItem.defaultProps = {
  hasUnreviewedFirewords: false,
}

export default translate([ 'components' ])(RoomItem)
