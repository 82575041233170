import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import {
  get,
  sortBy,
  last,
  filter,
  find,
} from 'lodash'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Message, Button } from 'semantic-ui-react'

import Assessment from '../assessment/assessment'
import GraphQlErrorMessage from '../graphql-error-message/graphql-error-message'
import FullScreenLoadingOverlay from '../full-screen-loading-overlay'
import GET_ORG from '../../../helpers/graphql-queries/get-org'
import { RTL_LANGUAGE_CODES } from '../../../helpers/languages'
import classNames from '../../../helpers/class-names'

// import './baseline.css'
import '../../common.css'

const TRANSLATION_PREFIX = 'common.baseline'

const ONE_DAY_MS = 1000 * 60 * 60 * 24
const DEFAULT_MINIMUM_TIME_BETWEEN_ATTEMPTS_MS = ONE_DAY_MS * 90

export const GET_USER_ASSESSMENTS_QUERY = gql`
query getUserAssessments($userId: String!, $assessmentId: String) {
  userAssessments(userId: $userId, assessmentId: $assessmentId) {
    userAssessments {
      id
      userId
      assessmentId
      attemptNumber
      schoolYear
      schoolYearAttempt
      completionDate
      created
      updated
      answers {
        questionId
        value
        type
      }
    }
  }
}
`

export const CREATE_USER_ASSESSMENT = gql`
mutation createAssessment($userAssessment: CreateUserAssessmentInput!) {
  createUserAssessment(userAssessment: $userAssessment) {
    id
    userId
    assessmentId
    attemptNumber
    schoolYear
    schoolYearAttempt
    completionDate
    created
    updated
    answers {
      questionId
      value
      type
    }
  }
}
`

function Baseline ({
  userId,
  orgId,
  assessmentId,
  onBackToCourse,
  t,
  i18n,
}) {
  const rtl = RTL_LANGUAGE_CODES.includes(i18n.language)
  const {
    loading,
    error,
    data,
  } = useQuery(GET_USER_ASSESSMENTS_QUERY, { variables: { userId, assessmentId } })
  const {
    loading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery(GET_ORG, { variables: { organizationId: orgId, childrenDepth: 0 } })
  const [
    createAssessment,
    {
      loading: isCreating,
      error: createError,
    },
  ] = useMutation(CREATE_USER_ASSESSMENT, {
    update (cache, { data: { createUserAssessment } }) {
      const { userAssessments } = cache.readQuery({ query: GET_USER_ASSESSMENTS_QUERY, variables: { userId, assessmentId } })
      cache.writeQuery({
        query: GET_USER_ASSESSMENTS_QUERY,
        variables: { userId, assessmentId },
        data: {
          userAssessments: {
            ...userAssessments,
            userAssessments: userAssessments.userAssessments.concat([ createUserAssessment ]),
          },
        },
      })
    },
  })

  const isLoading = loading || orgLoading || isCreating
  const assessments = get(data, 'userAssessments.userAssessments', [])
  const org = get(orgData, 'organization', {})
  const sortedAssessments = sortBy(assessments, 'attemptNumber')
  const lastAssessment = last(sortedAssessments)
  let currentAssessment
  const now = new Date()
  const beginningOfSchoolYear = new Date(`${(now.getMonth() <= 6) ? now.getFullYear() - 1 : now.getFullYear()}-08-01T12:00:00Z`)
  const schoolYear = beginningOfSchoolYear.getFullYear()
  const schoolYearAssessments = sortBy(filter(sortedAssessments, { schoolYear }), 'schoolYearAttempt')
  const latestSchoolYearAssessment = last(schoolYearAssessments)
  const latestSchoolYearAttempt = (latestSchoolYearAssessment) ? latestSchoolYearAssessment.schoolYearAttempt : null
  const relevantStartingPoint = (org.baselineStartingPoints && latestSchoolYearAttempt) ? find(org.baselineStartingPoints, {
    schoolYear,
    schoolYearAttempt: latestSchoolYearAttempt + 1,
  }) : null

  let attemptNumber = 1

  if (!isLoading && lastAssessment) {
    attemptNumber = lastAssessment.attemptNumber + 1
    if (latestSchoolYearAssessment) {
      const prevCompletedOn = new Date(latestSchoolYearAssessment.completionDate)
      if (!latestSchoolYearAssessment.completionDate) {
        currentAssessment = latestSchoolYearAssessment
      } else if (relevantStartingPoint) {
        const minDaysMs = ONE_DAY_MS * relevantStartingPoint.minDays
        if (now - prevCompletedOn < minDaysMs || now < new Date(relevantStartingPoint.startsOn)) {
          currentAssessment = latestSchoolYearAssessment
        }
      } else if (org.baselineDefaultMinDays && now - prevCompletedOn < (org.baselineDefaultMinDays * ONE_DAY_MS)) {
        currentAssessment = latestSchoolYearAssessment
      } else if (!org.baselineDefaultMinDays && now - prevCompletedOn < DEFAULT_MINIMUM_TIME_BETWEEN_ATTEMPTS_MS) {
        currentAssessment = latestSchoolYearAssessment
      }
    }
  }

  return (
    <React.Fragment>
      <GraphQlErrorMessage error={error || orgError || createError} />
      <FullScreenLoadingOverlay isActive={isLoading} />
      <Message info style={{ marginTop: 20 }}>
        <Message.Header className={classNames({ rtl })} data-public>{t(`${TRANSLATION_PREFIX}.welcome_header`)}</Message.Header>
        <ul>
          <li className={classNames({ rtl })} data-public>{t(`${TRANSLATION_PREFIX}.welcome_description1`)}</li>
          <li className={classNames({ rtl })} data-public>{t(`${TRANSLATION_PREFIX}.welcome_description2`)}</li>
        </ul>
      </Message>
      {(!currentAssessment) && (
        <Button
          onClick={() => {
            createAssessment({
              variables: {
                userAssessment: {
                  assessmentId,
                  userId,
                  attemptNumber,
                },
              },
            })
          }}
        >
          {t(`${TRANSLATION_PREFIX}.baseline_start_btn`)}
        </Button>
      )}
      {(!!currentAssessment) && (
        <Assessment
          userAssessmentId={currentAssessment.id}
          assessmentId={assessmentId}
          onBackToCourse={onBackToCourse}
        />
      )}
    </React.Fragment>
  )
}

Baseline.propTypes = {
  userId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onBackToCourse: PropTypes.func,
  i18n: PropTypes.object.isRequired,
}

Baseline.defaultProps = {
  isLoading: false,
  onBackToCourse: console.log.bind(console, 'onBackToCourse'),
}

export default translate([ 'components' ])(Baseline)
