import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Image, TextArea, Dropdown, Segment, Label, Button, Header, Message, Form as SemanticForm, Icon, Modal, Input, Tab,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import {
  intersection,
  map,
  isEqual,
  without,
  isArray,
  last,
  uniq,
} from 'lodash'

// import { required } from '../../../helpers/form-validators'
import { dropdownOnChange } from '../../../helpers/form'
import CheckboxGroup from '../../common/checkbox-group'
import ConditionalField from '../../common/conditional-field'
import Pager from '../../common/pager/uncontrolled-pager'

import './content-item.css'

// const validateBgImage = required({ message: 'forms.error_required' })

const TYPE_OPTIONS = [
  {
    value: 'text',
    text: 'Text',
  }, {
    value: 'freeform',
    text: 'Freeform Textbox',
  }, {
    value: 'freeform-na',
    text: 'Freeform Textbox with Does Not Apply Option',
  }, {
    value: 'true-false',
    text: 'True / False',
  }, {
    value: 'yes-no',
    text: 'Yes / No',
  }, {
    value: 'multiple-choice',
    text: 'Multiple Choice',
  }, {
    value: 'scale-1-10',
    text: 'Scale of 1 to 10',
  }, {
    value: 'accept',
    text: 'Accept',
  }, {
    value: 'video-player',
    text: 'Video Player',
  },
]

const HORIZONTAL_ALIGN_OPTIONS = [
  {
    value: 'pull-left',
    text: '50% Left',
    icon: { name: 'align left' },
  }, {
    value: 'pull-center',
    text: '50% Center',
    icon: { name: 'align center' },
  }, {
    value: 'pull-right',
    text: '50% Right',
    icon: { name: 'align right' },
  }, {
    value: 'pull-full-width',
    text: '100% Width',
    icon: { name: 'align justify' },
  },
]

const VERTICAL_ALIGN_OPTIONS = [
  {
    value: 'pull-up',
    text: 'Top Aligned',
    icon: { name: 'sort up' },
  }, {
    value: 'pull-vertical',
    text: 'Middle Aligned',
    icon: { name: 'sort' },
  }, {
    value: 'pull-down',
    text: 'Bottom Aligned',
    icon: { name: 'sort down' },
  },
]

const SCREEN_OPTIONS = [
  {
    value: 'no-screen',
    text: 'No Screen',
    icon: { name: 'circle outline' },
  }, {
    value: 'light-screen',
    text: 'Light Screen',
    icon: { color: 'grey', name: 'circle' },
  }, {
    value: 'dark-screen',
    text: 'Dark Screen',
    icon: { name: 'circle' },
  },
]

const COLUMN_OPTIONS = [
  {
    value: '---',
    text: '1',
  }, {
    value: 'two',
    text: '2',
  }, {
    value: 'three',
    text: '3',
  }, {
    value: 'four',
    text: '4',
  }, {
    value: 'five',
    text: '5',
  },
]

const TITLE_CLASSES_OPTIONS = [
  {
    value: 'large',
    label: 'Large',
  }, {
    value: 'shadow',
    label: 'Shadow',
  },
]

const BODY_CLASSES_OPTIONS = [
  {
    value: 'medium',
    label: 'Medium',
  }, {
    value: 'large',
    label: 'Large',
  }, {
    value: 'shadow',
    label: 'Shadow',
  }, {
    value: 'list-item',
    label: 'List Item',
  },
]

const bgImagesBaseUrl = 'https://course.base.education/img'
const areArraysEqual = (a, b) => {
  if (isArray(a) && isArray(b)) {
    return isEqual(a.sort(), b.sort())
  }
  return false
}
const recentImagesKey = `education.base.admin.recent-images`

export class BasicImageSelectionModal extends Component {
  static propTypes = {
    onOpen: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    availableImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onOpen: console.log.bind(console, 'onOpen'),
    onSelect: console.log.bind(console, 'onSelect'),
    onCancel: console.log.bind(console, 'onCancel'),
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      currentImg: null,
      imgSearch: '',
      imgPage: 1,
      activeIndex: 0,
    }
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  render () {
    const {
      onOpen,
      onSelect,
      onCancel,
      availableImages,
      t,
    } = this.props
    const {
      imgSearch,
      imgPage,
      currentImg,
      activeIndex,
    } = this.state
    const filteredImages = (imgSearch) ? availableImages.filter((img) => {
      return (!!img && img.toLowerCase().includes(imgSearch.toLowerCase()))
    }) : availableImages
    const slicedImages = filteredImages.slice(((imgPage - 1) * 20), (((imgPage - 1) * 20) + 20))
    const recentImages = JSON.parse(window.localStorage.getItem(recentImagesKey) || '[]')
    return (
      <Modal
        trigger={(
          <Button
            style={{ marginTop: 10 }}
            type='button'
            icon
            color='blue'
            title={t('Pick a new image')}
          >
            <Icon name='image' /> {t('Pick a new image')}
          </Button>
        )}
        closeOnDimmerClick={false}
        closeIcon={true}
        onOpen={() => {
          onOpen()
          this.setState({
            ...this.state, imgSearch: '', imgPage: 1,
          })
        }}
        actions={[
          {
            key: 'cancel',
            content: 'Cancel',
            onClick: () => {
              this.setState({
                ...this.state, imgSearch: '', currentImg: null,
              })
              onCancel()
            },
          },
          {
            key: 'done',
            content: 'Select',
            positive: true,
            disabled: !currentImg,
            onClick: (e) => {
              const recentImages = JSON.parse(window.localStorage.getItem(recentImagesKey) || '[]')
              recentImages.unshift(currentImg)
              window.localStorage.setItem(recentImagesKey, JSON.stringify(uniq(recentImages).slice(0, 20)))

              onSelect(`${bgImagesBaseUrl}/${currentImg}`)
            },
          },
        ]}
        header={t('Select an Image')}
        content={(
          <div style={{ textAlign: 'center', margin: '20px' }}>
            <Tab
              menu={{ pointing: true }}
              panes={[
                {
                  menuItem: 'Search All Images',
                  render: () => {
                    return (
                      <Tab.Pane>
                        <Input
                          placeholder='Search...'
                          fluid
                          autoFocus
                          onChange={(e, data) => {
                            this.setState({
                              ...this.state,
                              imgSearch: data.value,
                              imgPage: 1,
                            })
                          }}
                          style={{ marginBottom: 10 }}
                        />
                        <Pager
                          itemsPerPage={20}
                          items={slicedImages}
                          onPageChange={(pageNumber) => this.setState({
                            ...this.state,
                            imgPage: pageNumber,
                            currentImg: null,
                          })}
                          activePage={imgPage}
                          totalItemCount={filteredImages.length}
                          showJumpToPage={true}
                          render={(items) => (
                            <div>
                              {[ 0, 1, 2, 3 ].map((rowNumber) => (
                                <Image.Group key={`row-${rowNumber}`} size='small'>
                                  {items.slice((rowNumber * 5), ((rowNumber * 5) + 5)).map((item) => (
                                    <Image
                                      tabIndex='0'
                                      className='bg-image-select'
                                      key={item}
                                      src={`${bgImagesBaseUrl}/${item}`}
                                      onClick={() => this.setState({ ...this.state, currentImg: item })}
                                    />
                                  ))}
                                </Image.Group>
                              ))}
                            </div>
                          )}
                        />
                      </Tab.Pane>
                    )
                  },
                }, {
                  menuItem: 'Recently Used',
                  render: () => {
                    return (
                      <Tab.Pane>
                        {(!recentImages.length) && (
                          <Segment
                            placeholder
                          >
                            <Header icon>
                              <Icon name='image' />
                              There are no recently used images.
                            </Header>
                          </Segment>
                        )}
                        {[ 0, 1, 2, 3 ].map((rowNumber) => (
                          <Image.Group key={`row-${rowNumber}`} size='small'>
                            {recentImages.slice((rowNumber * 5), ((rowNumber * 5) + 5)).map((item) => (
                              <Image
                                tabIndex='0'
                                className='bg-image-select'
                                key={item}
                                src={`${bgImagesBaseUrl}/${item}`}
                                onClick={() => this.setState({ ...this.state, currentImg: item })}
                              />
                            ))}
                          </Image.Group>
                        ))}
                      </Tab.Pane>
                    )
                  },
                },
              ]}
              activeIndex={activeIndex}
              onTabChange={this.handleTabChange}
            />

          </div>
        )}
      />
    )
  }
}

const ImageSelectionModal = translate([ 'components' ])(BasicImageSelectionModal)

export class ContentItemForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    titles: PropTypes.arrayOf(PropTypes.string),
    bodies: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
    ordinal: PropTypes.number.isRequired,
    view: PropTypes.object.isRequired,
    voiceUrl: PropTypes.string,
    notes: PropTypes.shape({
      en: PropTypes.string,
    }),
    choices: PropTypes.array,
    availableImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'green',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = (data) => {
    const columns = (data.columns === '---') ? '' : `${data.columns} column`
    const trimmedTitles = data.titles && data.titles.trim()
    const trimmedBodies = data.bodies && data.bodies.trim()
    const trimmedChoices = data.choices && data.choices.trim()
    const existingBgImageName = (this.props.view.container) ? without(this.props.view.container.split(' '), 'c', 'n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw')[0] : undefined
    const bgImage = (data.bgImage) ? data.bgImage.split('/').pop() : existingBgImageName
    this.props.onSave({
      titles: (trimmedTitles) ? trimmedTitles.split('\n').filter((t) => !!t).map((en) => ({ en })) : [],
      bodies: (trimmedBodies) ? trimmedBodies.split('\n').filter((b) => !!b).map((en) => ({ en })) : [],
      choices: (trimmedChoices) ? trimmedChoices.split('\n').filter((c) => !!c).map((en) => ({ en })) : null,
      type: data.type,
      ordinal: data.ordinal,
      view: {
        classes: [ 'padding', data.horizontalAlign, data.verticalAlign, data.screen, columns ].join(' ').trim(),
        titleClasses: data.titleClasses.join(' '),
        textClasses: data.bodyClasses.join(' '),
        container: bgImage,
      },
      notes: (data.notes && data.notes.trim()) ? { en: data.notes } : null,
    })
  }

  render () {
    const {
      color,
      isLoading,
      titles,
      bodies,
      type,
      ordinal,
      view,
      notes,
      availableImages,
      // voiceUrl,
      choices,
      t,
    } = this.props

    const classesArray = (view.classes) ? view.classes.split(/\s/) : []
    const titleClassesArray = (view.titleClasses) ? view.titleClasses.split(/\s/) : []
    const bodyClassesArray = (view.textClasses) ? view.textClasses.split(/\s/) : []
    const padding = classesArray.includes('padding')
    const horizontalAlign = intersection(classesArray, map(HORIZONTAL_ALIGN_OPTIONS, 'value'))[0]
    const verticalAlign = intersection(classesArray, map(VERTICAL_ALIGN_OPTIONS, 'value'))[0]
    const screen = last(intersection(classesArray, map(SCREEN_OPTIONS, 'value'))) || 'no-screen'
    const columnOption = intersection(classesArray, map(COLUMN_OPTIONS, 'value'))[0]
    const columns = (classesArray.includes('column') && columnOption) ? (columnOption) : '---'
    const titleClasses = intersection(titleClassesArray, map(TITLE_CLASSES_OPTIONS, 'value'))
    const bodyClasses = intersection(bodyClassesArray, map(BODY_CLASSES_OPTIONS, 'value'))
    const matchingImg = availableImages.find((img) => img === view.container)
    const bgImage = (matchingImg) ? `${bgImagesBaseUrl}/${matchingImg}` : undefined
    const imgMsg = (!!view.container && !matchingImg) ? (
      <Message
        warning
        icon='warning'
        header={`Unable to find the background image (${view.container})`}
        content='This is probably OK. The image may exist under a similar name. If so, you can leave the image dropdown empty to leave it unchanged.'
      />
    ) : null
    return (
      <Segment className='content-item'>
        <Header as='h3'>{t(`forms.content-item.header`)} (#{ordinal})</Header>
        {imgMsg}
        <Form
          onSubmit={this.handleSaveForm}
          subscription={{ pristine: true, invalid: true }}
          initialValues={{
            titles: titles.join('\n'),
            bodies: bodies.join('\n'),
            type,
            choices: (choices) ? choices.join('\n') : '',
            ordinal,
            horizontalAlign,
            verticalAlign,
            padding,
            screen,
            columns,
            titleClasses,
            bodyClasses,
            bgImage,
            notes: (notes) ? notes.en : null,
          }}
          render={({
            handleSubmit,
            pristine,
            invalid,
          }) => (
            <SemanticForm className='content-item-form' onSubmit={handleSubmit}>
              <SemanticForm.Group widths='equal'>
                <Field
                  name='titles'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field>
                      <label>{t('forms.content-item.titles_label')}</label>
                      <TextArea
                        className='content-item-input'
                        placeholder={t('forms.content-item.titles_label')}
                        rows={4}
                        {...input}
                        autoCorrect='off'
                        autoCapitalize='none'
                        spellCheck='false'
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <ConditionalField
                  name='bodies'
                  when='type'
                  condition={(value) => value === 'video-player'}
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field>
                      <label>{t('forms.content-item.bodies_video_label')}</label>
                      <TextArea
                        className='content-item-input'
                        placeholder={t('forms.content-item.bodies_video_label')}
                        rows={4}
                        {...input}
                        autoCorrect='off'
                        autoCapitalize='none'
                        spellCheck='false'
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <ConditionalField
                  name='bodies'
                  when='type'
                  condition={(value) => value !== 'video-player'}
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field>
                      <label>{t('forms.content-item.bodies_label')}</label>
                      <TextArea
                        className='content-item-input'
                        placeholder={t('forms.content-item.bodies_label')}
                        rows={4}
                        {...input}
                        autoCorrect='off'
                        autoCapitalize='none'
                        spellCheck='false'
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
              </SemanticForm.Group>
              <SemanticForm.Group widths='equal'>
                <Field
                  name='titleClasses'
                  isEqual={areArraysEqual}
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <CheckboxGroup
                        options={TITLE_CLASSES_OPTIONS}
                        selectedValues={input.value}
                        disabled={isLoading}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <Field
                  name='bodyClasses'
                  isEqual={areArraysEqual}
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <CheckboxGroup
                        options={BODY_CLASSES_OPTIONS}
                        selectedValues={input.value}
                        disabled={isLoading}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
              </SemanticForm.Group>
              <SemanticForm.Group widths='equal'>
                <Field
                  name='horizontalAlign'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <label>{t('forms.content-item.horizontal_align_label')}</label>
                      <Dropdown
                        className='content-item-input'
                        placeholder={t('forms.content-item.horizontal_align_label')}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        disabled={isLoading}
                        {...input}
                        options={HORIZONTAL_ALIGN_OPTIONS}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <Field
                  name='verticalAlign'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <label>{t('forms.content-item.vertical_align_label')}</label>
                      <Dropdown
                        className='content-item-input'
                        placeholder={t('forms.content-item.vertical_align_label')}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        disabled={isLoading}
                        {...input}
                        options={VERTICAL_ALIGN_OPTIONS}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <Field
                  name='screen'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <label>{t('forms.content-item.screen_label')}</label>
                      <Dropdown
                        className='content-item-input'
                        placeholder={t('forms.content-item.screen_label')}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        disabled={isLoading}
                        {...input}
                        options={SCREEN_OPTIONS}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <ConditionalField
                  name='columns'
                  when='type'
                  condition={(value) => value !== 'video-player'}
                  alwaysRender={true}
                  render={({
                    input, conditionMet, meta: { error: { message, ...options } = {}, touched },
                  }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <label className={(conditionMet) ? '' : 'text grey'}>{t('forms.content-item.columns_label')}</label>
                      <Dropdown
                        className='content-item-input'
                        placeholder={t('forms.content-item.columns_label')}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        disabled={!conditionMet || isLoading}
                        {...input}
                        options={COLUMN_OPTIONS}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
                <Field
                  name='type'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <SemanticForm.Field disabled={isLoading}>
                      <label>{t('forms.content-item.panel_type_label')}</label>
                      <Dropdown
                        className='content-item-input'
                        placeholder={t('forms.content-item.panel_type_label')}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        disabled={isLoading}
                        {...input}
                        options={TYPE_OPTIONS}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </SemanticForm.Field>
                  )}
                />
              </SemanticForm.Group>
              <Field
                name='notes'
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field>
                    <label>{t('forms.content-item.notes_label')}</label>
                    <TextArea
                      className='content-item-input'
                      placeholder={t('forms.content-item.notes_label')}
                      rows={2}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <ConditionalField
                when='type'
                condition={(value) => value === 'multiple-choice'}
                name='choices'
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field>
                    <label>{t('forms.content-item.choices_label')}</label>
                    <TextArea
                      className='content-item-input'
                      placeholder={t('forms.content-item.choices_label')}
                      rows={4}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'/>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <Field
                name='bgImage'
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <SemanticForm.Field disabled={isLoading}>
                    <label>{t('forms.content-item.bg_image_label')}</label>
                    <Image size='small' src={input.value} />
                    <div>{input.value.replace(`${bgImagesBaseUrl}/`, '')}</div>
                    <ImageSelectionModal
                      onOpen={() => input.onFocus()}
                      onSelect={(file) => {
                        input.onChange(file)
                        input.onBlur()
                      }}
                      onCancel={() => input.onBlur()}
                      availableImages={availableImages}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </SemanticForm.Field>
                )}
              />
              <ConditionalField
                when='bgImage'
                condition={(value) => !!value && availableImages.includes(value.replace(`${bgImagesBaseUrl}/`, ''))}
                render={({ value }) => {
                  return (<Image size='large' src={value}/>)
                }}
              />
              <br/>
              <Button
                type='submit'
                className='content-item-button'
                floated='right'
                loading={isLoading}
                color={color}
                disabled={isLoading || pristine || invalid}>
                {t('forms.save_button')}
              </Button>
              <br style={{ clear: 'both' }}/>
            </SemanticForm>
          )}
        />
      </Segment>
    )
  }
}

export default translate([ 'components' ])(ContentItemForm)
