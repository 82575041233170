import {
  isArray,
  merge,
  cloneDeep,
} from 'lodash'

const mergeValueById = (value) => (state) => {
  if (isArray(value)) {
    const updatedState = value.reduce((accum, val) => {
      return Object.assign(accum, { [val.id]: merge(cloneDeep(state[val.id]), val) })
    }, {})
    return Object.assign({}, state, updatedState)
  }
  return Object.assign({}, state, { [value.id]: merge(cloneDeep(state[value.id]), value) })
}

export default mergeValueById
