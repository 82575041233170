import gql from 'graphql-tag'

export const NAME = 'createUserNotificationSetting'
const CREATE_NOTIFICATION_SETTING = gql`
mutation ${NAME}($notificationSetting: CreateNotificationInput!) {
  ${NAME}(notificationSetting: $notificationSetting) {
    id
    orgId
    userId
    type
    deliveryMethods
    created
  }
}
`

export default CREATE_NOTIFICATION_SETTING
