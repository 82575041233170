import {
  trim,
  isString,
  isUndefined,
  isNumber,
} from 'lodash'

export const trimInputOnBlur = (input) => (e) => {
  input.onChange(trim(input.value))
  input.onBlur(e)
}

export const checkboxOnChange = (input) => (e, props) => input.onChange(props.checked)

export const dropdownOnChange = (input, receivesDirectValue = false) => (e, props) => {
  input.onChange((receivesDirectValue) ? e : props.value)
}

export const lowerCaseFormatter = (value, name) => (isString(value)) ? value.toLowerCase() : ''

export const minValueParser = (minValue) => {
  if (!isNumber(minValue)) {
    throw new Error(`Invalid parameters for minValueParser (minValue=${minValue})`)
  }
  return (value, name) => {
    if (isUndefined(value) || value === '') {
      return
    }
    value = value * 1
    return Math.max(minValue, value)
  }
}

export const maxValueParser = (maxValue) => {
  if (!isNumber(maxValue)) {
    throw new Error(`Invalid parameters for maxValueParser (maxValue=${maxValue})`)
  }
  return (value, name) => {
    if (isUndefined(value) || value === '') {
      return
    }
    value = value * 1
    return Math.min(maxValue, value)
  }
}

export const rangeValueParser = (minValue, maxValue) => {
  if (!isNumber(minValue) || !isNumber(maxValue) || minValue > maxValue) {
    throw new Error(`Invalid parameters for rangeValueParser (minValue=${minValue} maxValue=${maxValue})`)
  }
  return (value, name) => {
    if (isUndefined(value) || value === '') {
      return
    }
    value = value * 1
    return Math.max(Math.min(maxValue, value), minValue)
  }
}
