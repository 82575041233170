import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react'
import { flatten } from 'lodash'
import UPDATE_CLIENTS_ORGANIZATION_BY_USER_IDS from '../../../../helpers/graphql-queries/update-clients-organization-by-user-id'

import Mutation from '../../../common/mutation/mutation'
import OrgChangeModal from '../../../common/org-change-modal/org-change-modal'

const TRANSLATION_PREFIX = 'views.organization.clients'

const ChangeClientsOrgMenuItem = (props) => {
  const {
    userIds,
    currentOrg,
    homeOrg,
    onLoadingChange,
  } = props
  const [ orgChangeModalOpen, setOrgChangeModalOpen ] = useState(false)
  const [ currentSelectedOrgId, setCurrentSelectedOrgId ] = useState(currentOrg.id)
  const [ complete, setComplete ] = useState(false)
  const [ count, setCount ] = useState(0)

  const flattenAllOrgs = (org) => {
    const newOrg = {
      id: org.id,
      name: org.name,
      isActive: org.isActive,
    }
    if (!org.children || !org.children.length) {
      return [ newOrg ]
    }
    return [ newOrg ].concat(flatten(org.children.map(flattenAllOrgs)))
  }
  return (
    <Mutation
      mutation={UPDATE_CLIENTS_ORGANIZATION_BY_USER_IDS}
      onCompleted={(data) => {
        setCount(data.updateClientsOrganization.length)
        onLoadingChange(false)
        setComplete(true)
      }}
    >
      {(updateClientsOrganizationByIds) => {
        return (
          <>
            <Dropdown.Item data-public
              disabled={userIds && !userIds.length}
              onClick={() => {
                setOrgChangeModalOpen(true)
              }}
            ><Icon name='move' />Move</Dropdown.Item>

            <OrgChangeModal
              open={orgChangeModalOpen}
              currentOrg={currentOrg}
              userIds={userIds}
              allowedOrgs={flattenAllOrgs(homeOrg)}
              onChange={setCurrentSelectedOrgId}
              onOptionSelect={(orgId) => currentSelectedOrgId(orgId)}
              onClose={() => setOrgChangeModalOpen(false)}
              onLoadingChange={onLoadingChange}
              callback={() => {
                setOrgChangeModalOpen(false)
                onLoadingChange(true)
                updateClientsOrganizationByIds({ variables: { ids: userIds, newOrgId: currentSelectedOrgId } })
              }}
            />

            <CompletionModal
              {...props}
              count={count}
              open={complete}
              setOpen={setComplete}
            />
          </>
        )
      }
      }
    </Mutation>
  )
}

ChangeClientsOrgMenuItem.propTypes = {
  userIds: PropTypes.array,
  currentOrg: PropTypes.object,
  homeOrg: PropTypes.object,
  onLoadingChange: PropTypes.func,
}

const CompletionModal = ({
  count, invalidateClients, open, setOpen, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Content>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <Icon style={{ fontSize: '5em', margin: '40px auto -25px auto' }} name='check circle' />
      </Header>

      <Modal.Description>
        <div className='success-label'>{`Success! ${count} ${(count === 1) ? 'user was' : 'users were'} moved.`}</div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions style={{ justifyContent: 'flex-end' }}>
      <Button
        content={t(`${TRANSLATION_PREFIX}.done`)}
        onClick={() => {
          setOpen(false)
          invalidateClients()
        }}
        primary
      />
    </Modal.Actions>
  </Modal>
)

CompletionModal.propTypes = {
  count: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  invalidateClients: PropTypes.func,
  t: PropTypes.func,
}

export default ChangeClientsOrgMenuItem
