const loginRequest = () => (state) => {
  return Object.assign({}, state, {
    isLoading: true,
    isRefreshing: false,
    error: null,
    orgId: null,
    roleType: null,
    email: null,
  })
}

export default loginRequest
