import { FETCH_ACTIONS, HTTP_METHODS } from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'

const STORE_PATH_PREFIX = 'firePhrases'

export function getAllFirePhrases ({ accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, '/firePhrases', null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getAllFirePhrases')
}

export function invalidateFirePhrases () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateFirePhrases' ]
}

export function getFirePhrase ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, id, `/firePhrases/${id}`, null, { headers }, FETCH_ACTIONS.GET, STORE_PATH_PREFIX, 'getFirePhrase')
}

export function createFirePhrase ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.POST, null, '/firePhrases', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createFirePhrase')
}

export function acknowledgeCreateFirePhrase () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateFirePhrase' ]
}

export function updateFirePhrase ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.PUT, data.id, `/firePhrases/${data.id}`, data, { headers }, FETCH_ACTIONS.PUT, STORE_PATH_PREFIX, 'updateFirePhrase')
}

export function acknowledgeUpdateFirePhrase () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUpdateFirePhrase' ]
}

export function deleteFirePhrase ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.DELETE, id, `/firePhrases/${id}`, null, { headers }, FETCH_ACTIONS.DELETE, STORE_PATH_PREFIX, 'deleteFirePhrase')
}
