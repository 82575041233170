import gql from 'graphql-tag'

export const NAME = 'updateOrganizationActiveStatus'
const UPDATE_ORG_ACTIVE_STATUS = gql`
  mutation ${NAME}($organizationId: ID!, $activeStatus: Boolean!) {
    ${NAME}(id: $organizationId, isActive: $activeStatus) {
      id
      name
      isActive
    }
  }
`
export default UPDATE_ORG_ACTIVE_STATUS
