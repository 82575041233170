import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
  List,
  Button,
  Message,
  Dropdown,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { getDisplayName } from '../../../helpers/user'
import GET_STAFF_BY_ORG_ID from '../../../helpers/graphql-queries/get-staff-by-org-id'
import GET_ROOMS_BY_ORG_ID from '../../../helpers/graphql-queries/get-rooms-by-org-id'
import UPDATE_ROOM from '../../../helpers/graphql-queries/update-room'
import {
  get,
  keyBy,
  reject,
  filter,
} from 'lodash'
import Query from '../../common/query/query'
import Mutation from '../../common/mutation/mutation'

import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import '../../common.css'
import './../staff-help-modal/staff-help-modal.css'

const RoomOrgTransferModal = ({
  onClose,
  open,
  orgId,
  userIds,
  userTransferSelection,
  onBack,
  onOptionSelect,
  callback,
  t,
}) => {
  if (!open) {
    return null
  }
  return (
    <Query
      variables={{
        orgId: orgId,
      }}
      fetchPolicy='network-only'
      query={GET_STAFF_BY_ORG_ID}
    >
      {({
        loading,
        data,
      }) => {
        if (loading) {
          return (<FullScreenLoadingOverlay isActive={true}/>)
        }
        const staff = get(data, 'staffByOrgId') || []
        const staffById = keyBy(staff, 'id')
        const otherStaff = reject(staffById, (s) => userIds.includes(s.id) || staff.profile === null)

        const dropdownOptions = otherStaff.map((staff) => { return { text: getDisplayName(staff), value: staff.id } })
        const variables = { orgId }
        return (
          <Query
            variables={variables}
            query={GET_ROOMS_BY_ORG_ID}
          >

            {({
              loading,
              data,
            }) => {
              if (loading) {
                return (<FullScreenLoadingOverlay isActive={true}/>)
              } else {
                const rooms = filter(get(data, 'roomsByOrgId.rooms') || [], (room) => userIds.includes(room.ownerUserId))
                var counter = 0
                return (
                  <Mutation
                    mutation={UPDATE_ROOM}
                    onCompleted={() => {
                      counter++
                      if (counter === rooms.length || rooms.length === 0) {
                        onClose()
                        callback()
                      }
                    }}
                  >
                    {(updateRoom) => {
                      return (<Modal
                        className='room-org-change-modal'
                        closeOnDimmerClick={false}
                        closeIcon={true}
                        open={open}
                        onClose={onClose}
                      >
                        <Modal.Content style={{ display: 'flex' }}>
                          <div style={{ flexGrow: 2 }}>
                            <Header data-public style={{ marginTop: 0 }}>
                              <Icon style={{ fontSize: '2.5em' }} className='base-teal' name='user' />
                              <Header.Content>
                              Who would you like to transfer these rooms to?
                              </Header.Content>
                            </Header>
                            <List relaxed selection size='big'>
                              <Dropdown
                                selection
                                search={true}
                                options={dropdownOptions}
                                value={userTransferSelection}
                                placeholder={'Choose staff member'}
                                onChange={(e, selection) => onOptionSelect(selection.value)}
                              />
                              {otherStaff.length === 0 && (<Message
                                negative
                                style={{ marginTop: 0 }}
                                icon='warning circle'
                                header='You are the only staff in this organization'
                                content='Please go back and select a different option.'
                              />)}

                            </List>
                            <Button color='blue' as='a' target='new' onClick={() => { onBack() }}><Icon name='arrow left'/> Back</Button>
                            <Button color='green' as='a' target='new' style={{ marginLeft: 15 }} onClick={() => {
                              if (!window.confirm(`Are you sure you want to transfer all rooms?`)) {
                                return
                              }
                              rooms.forEach((room) => {
                                updateRoom({ variables: { room: { id: room.id, ownerUserId: userTransferSelection } } })
                              })
                            }
                            }>
                              <Icon name='check'/> Submit
                            </Button>

                          </div>
                        </Modal.Content>
                      </Modal>)
                    }}
                  </Mutation>)
              }
            }}

          </Query>
        )
      }}
    </Query>
  )
}

RoomOrgTransferModal.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.array.isRequired,
  userTransferSelection: PropTypes.string,
  callback: PropTypes.func,
  t: PropTypes.func,
}

RoomOrgTransferModal.defaultProps = {
  onOptionSelect: console.log.bind(console, 'onOptionSelect'),
  onBack: console.log.bind(console, 'onBack'),
  isSaving: false,
  hasRooms: false,
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(RoomOrgTransferModal)
