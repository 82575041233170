import gql from 'graphql-tag'

const GET_STUDENT_DETAILS = gql`
query getStudentDetails($clientIds: [String]!, $courseIds: [String], $privateRoomId: String) {
  studentDetails(
    clientIds: $clientIds
    courseIds: $courseIds
    privateRoomId: $privateRoomId
  ) {
      clientId
      userName
      profile {
        firstName
        lastName
      }
      enrollments {
        id
        courseId
        attemptNumber
        contentItemProgresses {
          ordinal
          startedOn
          completedOn
        }
        completedOn
        startedOn
        created
      }
      firePhraseCaptures {
        id
        attemptNumber
        questionId
        created
        analyzedCaptures {
          matchedPhrases {
            id
            matchedPhraseText
            matchedSentenceSegment
          }
          isSafe
          sentence
          receptivitiSeverity
          ignisSeverity
        }
      }
      answers {
        id
        answerText
        attemptNumber
        created
        questionId
      }
      analyzedAnswers {
        id
        attemptNumber
        reviewerClientId
        isSafeReason
        isSafeTimestamp
        isSafe
        questionId
      }
      ignisRatings {
        rating
        answerId
      }
    }
}
`

export default GET_STUDENT_DETAILS
