import to from 'await-to-js'
import {
  groupBy,
  find,
  filter,
  compact,
} from 'lodash'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import callApi from './call-api'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'

const STORE_PATH_PREFIX = 'answers'

export function getClientAnswers ({
  accessToken,
  clientId,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, `/clients/${clientId}/answers`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getClientAnswers')
}

export function getClientAnswersWithFirewords ({
  clientId,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.GET
  const fetchAction = FETCH_ACTIONS.GET_ALL
  const actionName = 'getClientAnswersWithFirewords'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const promises = [
        fetch(`/clients/${clientId}/answers`, { method, headers }),
        fetch(`/clients/${clientId}/analyzedAnswers`, { method, headers }),
        fetch(`/clients/${clientId}/firePhraseCaptures`, { method, headers }),
      ]
      const [ err, results ] = await to(Promise.all(promises))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }
      const answers = results[0]
      const analyzedAnswers = results[1]
      const firePhraseCaptures = results[2]

      const analyzedAnswersByQuestionId = groupBy(analyzedAnswers, (analyzedAnswer) => {
        const questionRel = find(analyzedAnswer._meta.relationships, { type: 'questions' })
        if (!questionRel) {
          console.error('Houston we have a problem, no related question... what?', analyzedAnswer)
          return 'unknown'
        }
        return questionRel.id
      })
      const capturesByQuestionId = groupBy(firePhraseCaptures, (capture) => {
        const questionRel = find(capture._meta.relationships, { type: 'questions' })
        if (!questionRel) {
          console.log('Question does not exist for this fire phrase capture:', capture)
          return 'unknown'
        }
        return questionRel.id
      })
      const aggregatedAnswers = compact(answers.map((answer) => {
        const questionRel = find(answer._meta.relationships, { type: 'questions' })
        if (!questionRel) {
          // console.error('Answer ' + answer.id + ' has no question relationship')
          return null
        }
        const questionId = questionRel.id
        const analyzedAnswer = (analyzedAnswersByQuestionId[questionId]) ? find(analyzedAnswersByQuestionId[questionId], { attemptNumber: answer.attemptNumber }) : null
        const firePhraseCaptures = (capturesByQuestionId[questionId]) ? filter(capturesByQuestionId[questionId], { attemptNumber: answer.attemptNumber }) : []
        return {
          ...answer,
          questionId,
          analyzedAnswer,
          firePhraseCaptures,
          hasUnreviewedFirePhrases: (firePhraseCaptures.length > 0 && !analyzedAnswer),
        }
      }))
      dispatch([ requestSuccess(aggregatedAnswers, (value) => groupBy(value, 'questionId')), storePath, `${actionName}Success` ])
    },
  ]
}

export function invalidateClientAnswers () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateClientAnswers' ]
}
