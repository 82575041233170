import { isArray, keyBy } from 'lodash'

const setValueById = (value) => (state) => {
  if (isArray(value)) {
    const aggregatedValue = keyBy(value, 'id')
    return Object.assign({}, state, aggregatedValue)
  }
  return Object.assign({}, state, { [value.id]: value })
}

export default setValueById
