import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  Icon,
  Segment,
  Header,
  Label,
  Dropdown,
  Button,
  Popup,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  get,
  last,
  flatten,
  filter,
  map,
  sortBy,
  // some,
  isString,
  isNumber,
  compact,
  find,
  flatMap,
  uniqBy,
  keyBy,
} from 'lodash'
import moment from 'moment'

import QuestionCard from '../question-card'
import printElement from '../../../helpers/print-element'
import { getDisplayName } from '../../../helpers/user'
import ProgressDots from '../progress-dots/progress-dots'
import './course-answers.css'

const TRANSLATION_PREFIX = 'common.course-answers'

class CourseAnswers extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    courseTitle: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    answersByQuestionId: PropTypes.object.isRequired,
    firePhrasesByQuestionId: PropTypes.object,
    analyzedAnswersByQuestionId: PropTypes.object,
    clientsById: PropTypes.object.isRequired,
    userName: PropTypes.string,
    displayName: PropTypes.string,
    courseEnrollments: PropTypes.arrayOf(PropTypes.object),
    onReviewedClick: PropTypes.func,
    onIgnisRate: PropTypes.func,
    previousIgnisRatings: PropTypes.array,
    isSaving: PropTypes.bool,
    t: PropTypes.func.isRequired,
    onResetClick: PropTypes.func,
    onUnlockClick: PropTypes.func,
    firewordsEnabled: PropTypes.bool,
    hideEmpty: PropTypes.bool,
    isUnlocking: PropTypes.bool,
    courseContentItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    sessionId: PropTypes.string,
    id: PropTypes.string,
  }

  static defaultProps = {
    color: 'blue',
    userName: 'UNKNOWN USER',
    displayName: '',
    isLoading: false,
    isSaving: false,
    courseEnrollments: [],
    onReviewedClick: console.log.bind(console, 'onReviewedClick'),
    onIgnisRate: console.log.bind(console, 'onIgnisRate'),
    firewordsEnabled: true,
    hideEmpty: false,
    courseContentItems: [],
    firePhrasesByQuestionId: {},
    analyzedAnswersByQuestionId: {},
  }

  state = { activeIndex: -1, attemptNumber: 0 }

  constructor (props) {
    super(props)
    this.answersRef = React.createRef()
  }

  handleClick = (index) => {
    const { activeIndex, attemptNumber } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex, attemptNumber })
  }

  handleDropdownChange = (e, data) => {
    const { activeIndex } = this.state

    this.setState({ activeIndex, attemptNumber: data.value })
  }

  renderQuestionCard = (questionData, index) => {
    const {
      color,
      isSaving,
      onReviewedClick,
      onIgnisRate,
      previousIgnisRatings,
      firePhrasesByQuestionId,
    } = this.props
    const ratingsByAnswerId = keyBy(previousIgnisRatings, 'answerId')
    const { attemptNumber } = this.state
    const relevantFirePhrases = filter(firePhrasesByQuestionId[questionData.id] || [], { attemptNumber })
    const relevantCaptures = flatMap(relevantFirePhrases, 'analyzedCaptures')
    if (!questionData.answerId && !relevantCaptures.length) {
      return null
    }
    const analyzedCaptures = questionData.answerText ? questionData.analyzedCaptures : relevantCaptures
    const answerId = questionData.answerId || relevantFirePhrases[0].id
    const answerText = questionData.answerText || relevantCaptures[0].sentence
    return (
      <QuestionCard
        key={`${questionData.id}-${questionData.answerId}`}
        color={color}
        isSaving={isSaving}
        questionNumber={index + 1}
        questionText={questionData.title}
        answerText={questionData.answerText}
        unsubmitted={!questionData.answerText}
        notes={questionData.notes}
        analyzedCaptures={analyzedCaptures}
        analyzedAnswer={questionData.analyzedAnswer}
        onReviewedClick={(reason) => onReviewedClick(true, reason, questionData.id, answerId, attemptNumber)}
        onIgnisRate={(rating) => onIgnisRate(rating, questionData.title, questionData.id, answerText, answerId, analyzedCaptures)}
        previousRating={ratingsByAnswerId[answerId]}
      />
    )
  }

  getAttemptOption = (enrollment, index) => {
    const { t } = this.props
    if (index === 0) {
      const completedText = (enrollment.completedOn)
        ? t(`${TRANSLATION_PREFIX}.completed`, { date: new Date(enrollment.completedOn) })
        : t(`${TRANSLATION_PREFIX}.in_progress`)
      return {
        key: enrollment.id,
        value: enrollment.attemptNumber,
        text: t(`${TRANSLATION_PREFIX}.current_attempt`, { postProcess: 'interval' }),
        description: completedText,
      }
    }
    const completedText = (enrollment.completedOn)
      ? t(`${TRANSLATION_PREFIX}.completed`, { date: new Date(enrollment.completedOn) })
      : t(`${TRANSLATION_PREFIX}.incomplete`)
    return {
      key: enrollment.id,
      value: enrollment.attemptNumber,
      text: t(`common.attempt_interval`, { count: enrollment.attemptNumber, postProcess: 'interval' }),
      description: completedText,
    }
  }

  printAnswers = () => printElement(this.answersRef.current)

  componentDidMount () {
    const { activeIndex } = this.state
    const { courseEnrollments, sessionId } = this.props
    if (!courseEnrollments || !courseEnrollments.length) {
      return null
    }
    const sortedEnrollments = sortBy(courseEnrollments, 'attemptNumber').reverse()

    this.setState({ activeIndex, attemptNumber: (sessionId) || ((sortedEnrollments[0]) ? sortedEnrollments[0].attemptNumber : 1) })
  }

  render () {
    const {
      courseTitle,
      clientsById,
      questions,
      answersByQuestionId,
      courseEnrollments,
      isLoading,
      userName,
      displayName,
      onResetClick,
      onUnlockClick,
      isUnlocking,
      firewordsEnabled,
      hideEmpty,
      courseContentItems,
      firePhrasesByQuestionId,
      analyzedAnswersByQuestionId,
      id,
      t,
    } = this.props
    const qs = compact(questions)
    const { activeIndex, attemptNumber } = this.state
    const sortedEnrollments = sortBy(courseEnrollments || [], 'attemptNumber').reverse()
    const attNum = get(sortedEnrollments, '[0].attemptNumber', 1)
    const isSessionEnrollment = (!sortedEnrollments.length) ? false : isString(attNum)
    const currentEnrollment = find(sortedEnrollments, { attemptNumber })
    const allAttemptNumbers = filter(map(sortedEnrollments, 'attemptNumber'), isNumber)
    const isLatestEnrollment = (isString(attNum) || allAttemptNumbers[0] === attemptNumber)
    const highestOrdinal = last(sortBy(map(courseContentItems, 'ordinal'))) || 1
    const highestCompletedOrdinal = (!currentEnrollment) ? 0 : get(last(sortBy(filter(currentEnrollment.contentItemProgresses, 'completedOn'), 'ordinal')), 'ordinal', 0)
    const lastActiveDate = (!currentEnrollment || !currentEnrollment.contentItemProgresses.length) ? null : last(currentEnrollment.contentItemProgresses).startedOn
    const lastActivity = (lastActiveDate) ? `Active ${moment(lastActiveDate).fromNow()}` : 'No Activity Yet'
    const qIds = map(qs, 'id')
    // const hasUnreviewedFirePhrases = qIds.some((qId) => (answersByQuestionId[qId] && ((isSessionEnrollment && some(answersByQuestionId[qId], { hasUnreviewedFirePhrases: true, attemptNumber: attNum })) || (!isSessionEnrollment && answersByQuestionId[qId].some((ans) => isNumber(ans.attemptNumber) && ans.hasUnreviewedFirePhrases)))))
    const firePhraseCaptures = flatten(qIds.map((qId) => (answersByQuestionId[qId])).filter((ans) => !!ans)).filter((ans) => ans.hasUnreviewedFirePhrases).map((ans) => ans.firePhraseCaptures)
    const unansweredFirePhraseCaptures = flatMap(qIds, (qId) => {
      const answeredAttemptNumbers = map(answersByQuestionId[qId] || [], 'attemptNumber')
      return (get(firePhrasesByQuestionId, qId) || []).filter((fpc) => !answeredAttemptNumbers.includes(fpc.attemptNumber))
    })
    const hasNoFirePhrases = !unansweredFirePhraseCaptures.length && qIds.every((qId) => (!answersByQuestionId[qId] || answersByQuestionId[qId].filter((ans) => {
      return (isSessionEnrollment && isString(ans.attemptNumber) && ans.attemptNumber === attNum) || (!isSessionEnrollment && isNumber(ans.attemptNumber))
    }).every((ans) => !ans.firePhraseCaptures.length)))
    const allFirePhraseCaptures = uniqBy([ ...flatten(firePhraseCaptures), ...unansweredFirePhraseCaptures ], 'id')
    const relevantSeverities = flatMap(allFirePhraseCaptures || [], (capture) => {
      const relevantAnalyzedAnswer = filter(analyzedAnswersByQuestionId[capture.questionId] || [], { attemptNumber: capture.attemptNumber })[0]
      if (relevantAnalyzedAnswer) {
        return 0
      }
      return capture.analyzedCaptures.map((analyzedCapture) => (analyzedCapture.ignisSeverity && analyzedCapture.ignisSeverity) || (analyzedCapture.receptivitiSeverity > 0 && analyzedCapture.receptivitiSeverity) || 3)
    })
    const maxSeverity = Math.max(...relevantSeverities, 0)

    const severityColors = [ 'grey', 'yellow', 'orange', 'red' ]
    let answerCount = 0
    const questionData = qs.map((q) => {
      const answers = get(answersByQuestionId, `${q.id}`, [])
      const answersForAttempt = sortBy(filter(answers, { attemptNumber }), 'created')
      const answer = last(answersForAttempt)
      const answerText = (answer) ? answer.answerText : null
      if (answerText) {
        answerCount++
      }
      const analyzedCaptures = (firewordsEnabled && answer) ? flatten(map(answer.firePhraseCaptures, 'analyzedCaptures')) : []
      const relevantAnalyzedAnswer = filter(analyzedAnswersByQuestionId[q.id] || [], { attemptNumber })[0]
      const analyzedAnswer = (firewordsEnabled) ? (answer) ? answer.analyzedAnswer : relevantAnalyzedAnswer : null
      if (analyzedAnswer) {
        const reviewer = clientsById[analyzedAnswer.reviewerClientId] || {}
        const displayName = getDisplayName(reviewer) || t(`${TRANSLATION_PREFIX}.unknown_user`, { id: analyzedAnswer.reviewerClientId })

        analyzedAnswer.reviewer = {
          id: analyzedAnswer.reviewerClientId,
          userName: (clientsById[analyzedAnswer.reviewerClientId]) ? clientsById[analyzedAnswer.reviewerClientId].userName : t(`${TRANSLATION_PREFIX}.unknown_user`, { id: analyzedAnswer.reviewerClientId }),
          displayName,
        }
      }
      return {
        ...q,
        answerId: (answer) ? answer.id : null,
        answerText,
        analyzedCaptures,
        analyzedAnswer,
      }
    })
    if (hideEmpty && !answerCount) {
      return null
    }
    const isCompleted = (currentEnrollment && currentEnrollment.completedOn)
    const progress = (isCompleted) ? 100 : Math.floor((highestCompletedOrdinal + 1) * 100 / (highestOrdinal + 1))
    return (
      <div id={`answer-${id}`} className='course-answers' ref={this.answersRef}>
        <Segment loading={isLoading}>
          {(firewordsEnabled && !hasNoFirePhrases) && <Label corner='right' size='mini' icon='fire' color={severityColors[maxSeverity]}/>}
          <Header className='print-only' size='small'>User: {(!displayName) ? userName : `${userName} (${displayName})`}</Header>
          <Accordion>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={(e, titleProps) => {
              const { index } = titleProps
              this.handleClick(index)
            }}>
              <Icon name='dropdown' className='no-print' />
              <span className='text bold'>{courseTitle}</span> {(isCompleted) && (<Icon color='yellow' name='trophy'/>)}<span className='text italic base-teal'>{(!qs.length) ? '(No Questions)' : (!answerCount) ? '(No Responses)' : `( ${answerCount} / ${questionData.length} )`}</span>
              <Popup
                content={`${progress}% Complete`}
                position='top center'
                trigger={(
                  <div className='float-right' style={{
                    marginTop: 2, marginRight: 16,
                  }}>
                    <ProgressDots radius={8} completed={progress} />
                  </div>
                )}
                hoverable
              />
              <span style={{ marginRight: 16 }} className='text italic grey float-right'><Icon name={(!lastActiveDate) ? 'calendar times' : 'calendar'} /> {lastActivity}</span>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <div className='controls'>
                {(!isSessionEnrollment) && (
                  <Dropdown
                    className='attempt-selector'
                    fluid
                    selection
                    value={attemptNumber}
                    disabled={!sortedEnrollments.length}
                    options={sortedEnrollments.map(this.getAttemptOption)}
                    onChange={this.handleDropdownChange}
                  />
                )}
                <Button
                  className='no-print'
                  color='blue'
                  size='tiny'
                  disabled={!currentEnrollment || (!!currentEnrollment && !currentEnrollment.startedOn)}
                  onClick={this.printAnswers}
                >
                  <Icon name='print' /> {t(`${TRANSLATION_PREFIX}.print_answers`)}
                </Button>
                {(isLatestEnrollment && !!onResetClick) && (
                  <Button
                    className='no-print'
                    color='red'
                    size='tiny'
                    disabled={!currentEnrollment || (!!currentEnrollment && !currentEnrollment.startedOn)}
                    onClick={() => onResetClick(currentEnrollment.id)}
                  >
                    <Icon name='undo' /> {t(`${TRANSLATION_PREFIX}.reset`)}
                  </Button>
                )}
                {(isCompleted && isLatestEnrollment && !!onUnlockClick) && (
                  <Button
                    className='no-print'
                    color='black'
                    size='tiny'
                    loading={isUnlocking}
                    onClick={() => onUnlockClick(currentEnrollment.id)}
                  >
                    <Icon name='unlock' /> Unlock
                  </Button>
                )}
              </div>
              <br style={{ clear: 'both' }}/>
              {(!answerCount) && (
                <Message
                  data-public
                  icon='warning circle'
                  header='There are no responses for this module'
                  content='When the student begins to respond to questions, their answers will appear here.'
                />
              )}
              {questionData.map(this.renderQuestionCard)}
            </Accordion.Content>
          </Accordion>
        </Segment>
      </div>
    )
  }
}

export default translate([ 'components' ])(CourseAnswers)
