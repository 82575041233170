import React from 'react'
import PropTypes from 'prop-types'
import {
  Header, Message, Item, Statistic, Icon, Label, Popup,
} from 'semantic-ui-react'
import {

  filter,
  reject,
  get,
} from 'lodash'

import Query from '../../../../common/query/query'
import Mutation from '../../../../common/mutation/mutation'

import FullScreenLoadingOverlay from '../../../../common/full-screen-loading-overlay/full-screen-loading-overlay'

import GET_ROOMS_BY_STUDENT_ID from '../../../../../helpers/graphql-queries/get-rooms-by-student-id'
import UPDATE_ROOM from '../../../../../helpers/graphql-queries/update-room'
import GET_ROOM_BY_ID from '../../../../../helpers/graphql-queries/get-room-by-id'

import { INTERNAL_ROLE_TYPES, EXTERNAL_ROLE_TYPES } from '../../../../../helpers/get-roles'

export class StudentRoomsView extends React.Component {
  static propTypes = {
    orgId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    authState: PropTypes.object.isRequired,
  }

  static path = '/rooms'

  shouldComponentUpdate (nextProps) {
    return (
      this.props.clientId !== nextProps.clientId ||
      this.props.orgId !== nextProps.orgId
    )
  }

  render () {
    const {
      clientId,
      authState,
    } = this.props
    return (
      <Mutation
        mutation={UPDATE_ROOM}
        awaitRefetchQueries={true}
      >
        {(updateRoom) => {
          return (
            <Query variables={{ userId: clientId }}
              query={GET_ROOMS_BY_STUDENT_ID}>{({
                loading,
                data,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                } else {
                  const rooms = filter(get(data, 'roomsByStudentId') || [], function (r) {
                    return r.studentIds.includes(clientId)
                  })
                  if (rooms.length === 0) {
                    return (<Message warning>
                      <Header>This student is not in any rooms</Header>
                    </Message>)
                  }
                  return (
                    <>
                      {rooms.map((room) => {
                        const canEdit = room.ownerUserId === authState.userId || Object.values(INTERNAL_ROLE_TYPES).includes(authState.roleType) || EXTERNAL_ROLE_TYPES.ADMIN === authState.roleType
                        return (
                          <Item.Group unstackable key={room.id} className='room-items static'>
                            <Item
                              className='room-item static'
                            >

                              <Item.Image size='small' src={room.imageUrl} />

                              <Item.Content>
                                <div style={{ display: 'flex' }}>
                                  <Header style={{
                                    margin: 0, flexGrow: 2, textAlign: 'left',
                                  }}>

                                    {room.name}
                                  </Header>
                                  <Popup
                                    hoverable
                                    content='Remove student'
                                    position='top right'
                                    trigger={(
                                      <div style={{ marginLeft: 10, flexShrink: 2 }}>
                                        <Icon name='x' disabled={!canEdit} onClick={() => onRemoveStudent(clientId, room, updateRoom)}color={canEdit ? 'red' : 'grey'} size='large' />
                                      </div>
                                    )}
                                  />
                                </div>

                                <Item.Description>
                                  <Statistic.Group size='mini'>
                                    <Statistic>
                                      <Statistic.Value data-public>
                                        <Icon className='base-teal' name='user' /> {room.studentIds.length}
                                      </Statistic.Value>
                                      <Statistic.Label data-public>{(room.studentIds.length === 1) ? 'Student' : 'Students'}</Statistic.Label>
                                    </Statistic>
                                    <Statistic>
                                      <Statistic.Value data-public>
                                        <Icon style={{ marginRight: 3 }} className='base-teal fas fa-chalkboard' /> {room.courseIds.length}
                                      </Statistic.Value>
                                      <Statistic.Label data-public>{(room.courseIds.length === 1) ? 'Module' : 'Modules'}</Statistic.Label>
                                    </Statistic>
                                    <Statistic>
                                      <Statistic.Value data-public>
                                        <Icon className='base-teal' name='fire' /> {(room.firewordNotificationsEnabled) ? 'ON' : 'OFF'}
                                      </Statistic.Value>
                                      <Statistic.Label data-public>Alerts</Statistic.Label>
                                    </Statistic>
                                  </Statistic.Group>
                                </Item.Description>
                              </Item.Content>
                              {(room.isPrivate) && (
                                <Popup
                                  hoverable
                                  content='Private Room'
                                  trigger={(
                                    <Label
                                      circular
                                      className='private-label base-red-bg'
                                    >
                                      <Icon
                                        name='lock'
                                      />
                                    </Label>
                                  )}
                                />
                              )}
                            </Item>
                          </Item.Group>
                        )
                      })}
                    </>
                  )
                }
              }}
            </Query>
          )
        }}

      </Mutation>
    )
  }
}

const onRemoveStudent = (clientId, room, updateRoom) => {
  if (!window.confirm(`Are you sure you want to remove this student from ${room.name}?`)) {
    return
  }
  updateRoom({
    variables: {
      room: {
        id: room.id,
        studentIds: reject(room.studentIds, (id) => id === clientId),
      },

    },
    refetchQueries: [ {
      query: GET_ROOM_BY_ID,
      variables: {
        id: room.id,
      },
    } ],
  })
}

export default StudentRoomsView
