import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Header,
  Label,
  Button,
  Dropdown,
  Segment,
  Popup,
  Icon,
  Message,
  Accordion,
  Modal,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'
import {
  map,
  xor,
  sortBy,
  intersection,
  groupBy,
  get,
  defer,
  find,
  pick,
  isNil,
} from 'lodash'
import COUNTRY_REGION_DATA from 'country-region-data'

import {
  minLength,
  maxLength,
  required,
  composeValidators,
  composeObjectValidators,
} from '../../../helpers/form-validators'
import {
  trimInputOnBlur,
  checkboxOnChange,
  dropdownOnChange,
  rangeValueParser,
} from '../../../helpers/form'
import ConditionalField from '../../common/conditional-field/conditional-field'
import DateRangeInput from '../../common/date-range-input'
import WeeklyAccessTimeRanges from '../../common/weekly-access-time-ranges/weekly-access-time-ranges'
import OneTimeExceptions from '../../common/one-time-exceptions/one-time-exceptions'
import BaseForm from '../base-form'
// import getRoles, { ROLE_TYPES } from '../../../helpers/get-roles'
import ClockLabel from '../../common/clock-label/clock-label'
import TIMEZONE_DATA from '../../../helpers/timezone-data'
import CourseSelector from '../../common/course-selector'
// import BaselineSelector from './baseline-selector'
import Slider from '../../common/slider'

import './put-organization.css'

// const notificationRoles = getRoles().filter((role) => [ ROLE_TYPES.SUPERVISOR, ROLE_TYPES.ADVISOR ].includes(role.type))

const validateOrgName = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
)
const validateOrgSsoId = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 1 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
)

const validateContractDates = composeObjectValidators({
  startDate: required({ message: 'forms.error_required' }),
  endDate: required({ message: 'forms.error_required' }),
})

const TRANSLATION_PREFIX = 'forms.put-organization'

const requiredLabel = ' *'

const PRIORITY_COUNTRY_CODES = [ 'US' ]
const SORTED_COUNTRY_REGION_DATA = sortBy(COUNTRY_REGION_DATA, [
  (data) => {
    const priorityIndex = PRIORITY_COUNTRY_CODES.indexOf(data.countryShortCode)
    if (priorityIndex >= 0) {
      return `${'_'.repeat(PRIORITY_COUNTRY_CODES.length - priorityIndex)}${data.countryName.toLowerCase()}`
    }
    return data.countryName.toLowerCase()
  },
])
const COUNTRY_CODES_BY_COUNTRY_NAME = COUNTRY_REGION_DATA.reduce((accum, data) => ({ ...accum, [data.countryName]: data.countryShortCode }), {})
const GROUPED_TIMEZONE_DATA = groupBy(TIMEZONE_DATA, 'shortCountryCode')
// const DEFAULT_START_DATE = new Date()
// const DEFAULT_END_DATE = new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)) // 1 year out
const MIN_DATE = new Date(Date.now() - (1000 * 60 * 60 * 24 * 365 * 3)) // 3 years ago
const MAX_START_DATE = new Date(Date.now() + (1000 * 60 * 60 * 24 * 180)) // 180 days out
const MAX_END_DATE = new Date(Date.now() + (1000 * 60 * 60 * 24 * 365 * 5)) // 5 years out

// TODO: translate these
const ORG_TYPE_OPTIONS = [ {
  name: 'Contracted Customer',
  description: 'This is the top-level organization identifier for a contracted customer.',
  value: 'customer',
  icon: 'address card',
}, {
  name: 'Site',
  description: 'This organization type is used to group users under different site locations if the customer has more than one site.',
  value: 'site',
  icon: 'building',
}, {
  name: 'Group',
  description: 'This organization type can be used to further group users for the customer within a site location.',
  value: 'group',
  icon: 'users',
} ]

const renderTimezoneOption = (timezone) => ({ text: timezone.timezone, value: timezone.timezone })

// TODO: translate this component
const OrgTypeModal = ({
  orgType,
  onSelect,
  canUpdateToCustomer,
  canUpdateToSite,
}) => {
  const [ selectedOrgType, setOrgType ] = useState(orgType)
  if (!canUpdateToCustomer && !canUpdateToSite && orgType === 'group') {
    return null
  }
  return (
    <Modal
      className='org-type-modal'
      trigger={(
        <Button
          style={{ marginLeft: 10 }}
          icon
          color='blue'
          size='mini'
          title='Change Organization Type'
        ><Icon name='list alternate'/>
        </Button>
      )}
      header='Select an Organization Type'
      content={(
        <div className='modal-content'>
          {ORG_TYPE_OPTIONS.filter((option) => {
            return (orgType === option.value || option.value === 'group' || (option.value === 'customer' && canUpdateToCustomer) || (option.value === 'site' && canUpdateToSite))
          }).map((option, index) => (
            <Segment
              key={option.value}
              attached={(index === 0) ? 'top' : (index === ORG_TYPE_OPTIONS.length - 1) ? 'bottom' : true}
              className={(selectedOrgType === option.value) ? 'selected' : ''}
              onClick={() => setOrgType(option.value)}
            >
              <div className='modal-segment-content'>
                <Header as='h3'>
                  <Icon name={option.icon} />
                  <Header.Content>
                    {option.name}
                    <Header.Subheader>{option.description}</Header.Subheader>
                  </Header.Content>
                </Header>
                {(selectedOrgType === option.value) && (
                  <div><Label color='green'>Selected</Label></div>
                )}
              </div>
            </Segment>
          ))}
        </div>
      )}
      onActionClick={() => {
        if (orgType !== selectedOrgType) {
          onSelect(selectedOrgType)
        }
      }}
      actions={[ {
        key: 'ok', content: 'OK', positive: true,
      } ]}
    />
  )
}

OrgTypeModal.propTypes = {
  orgType: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  canUpdateToCustomer: PropTypes.bool,
  canUpdateToSite: PropTypes.bool,
}

const GenericOrgForm = ({
  formValues,
  isBlankForm,
  onSubmit,
  isLoading,
  orgType,
  isNewOrg,
  courses,
  isSSO,
  showAdvancedOptions,
  showGlobalOptions,
  canUpdateCourses,
  canCreateIntegrationOrgs,
  header,
  t,
}) => {
  const [ activeIndex, setActiveIndex ] = useState(-1)
  const [ orgSource, setOrgSource ] = useState('base')

  const renderForm = ({
    // eslint-disable-next-line react/prop-types
    handleSubmit,
    // eslint-disable-next-line react/prop-types
    submitDisabled,
  }) => {
    const sortedCourses = sortBy(courses, 'titles')
    const nameLabel = (orgType === 'customer') ? t(`${TRANSLATION_PREFIX}.customer_name_label`) : (orgType === 'site') ? t(`${TRANSLATION_PREFIX}.site_name_label`) : t(`${TRANSLATION_PREFIX}.group_name_label`)
    const idLabel = (orgSource === 'clever') ? 'Clever District ID' : 'ClassLink Tenant ID'
    const INSTITUTION_TYPES = [ {
      value: 'district',
      text: 'District',
    }, {
      value: 'elementary',
      text: 'Elementary School',
    }, {
      value: 'middle',
      text: 'Middle School',
    }, {
      value: 'senior',
      text: 'Senior School',
    }, {
      value: 'combined',
      text: 'Combined School',
    }, {
      value: 'charter',
      text: 'Charter School',
    }, {
      value: 'virtual',
      text: 'Virtual School',
    }, {
      value: 'university',
      text: 'University/College',
    }, {
      value: 'healthcare',
      text: 'Healthcare Facility',
    }, {
      value: 'other',
      text: 'Other',
    } ]

    const EDUCATION_LEVELS = [ {
      value: 'elementary',
      text: 'Grades 1-5',
    }, {
      value: 'secondary',
      text: 'Grades 6-12',
    }, {
    //   value: 'parent',
    //   text: 'Parent/Guardian',
    // }, {
      value: 'professional',
      text: 'Professional Development',
    } ]
    return (
      <React.Fragment>
        {(isNewOrg && canCreateIntegrationOrgs && orgType === 'customer') && (
          <Button.Group>
            <Button
              data-public
              active={orgSource === 'base'}
              onClick={() => setOrgSource('base')}
            >
              BASE Org
            </Button>
            <Button
              data-public
              active={orgSource === 'clever'}
              onClick={() => setOrgSource('clever')}
            >
              Clever Org
            </Button>
            <Button
              data-public
              active={orgSource === 'classlink'}
              onClick={() => setOrgSource('classlink')}
            >
              ClassLink Org
            </Button>
          </Button.Group>
        )}
        <Form className='put-organization-form' onSubmit={handleSubmit}>
          <input type='password' style={{ display: 'none' }} readOnly/>
          {(isSSO) && (
            <Message
              info
              icon='info circle'
              header='The name of the organization cannot be changed'
              content='The name will be automatically synchronized from the SSO provider.'
            />
          )}
          <Form.Group>
            <Field
              name='name'
              validate={(isNewOrg && canCreateIntegrationOrgs && orgSource !== 'base') ? validateOrgSsoId : validateOrgName}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field width={(orgType === 'site' || orgType === 'customer') ? 4 : 8}>
                  <Form.Input
                    placeholder={(isNewOrg && canCreateIntegrationOrgs && orgSource !== 'base') ? idLabel : nameLabel}
                    label={(isNewOrg && canCreateIntegrationOrgs && orgSource !== 'base') ? idLabel + requiredLabel : nameLabel + requiredLabel}
                    error={!!message && touched}
                    type='text'
                    fluid
                    disabled={isLoading || isSSO}
                    {...input}
                    autoCorrect='off'
                    autoCapitalize='words'
                    spellCheck='false'
                    onBlur={trimInputOnBlur(input)}/>
                  {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
            <Field
              name='orgSource'
              render={() => (
                <Form.Field style={{ display: 'none' }}>
                  <Form.Input
                    value={orgSource}
                  />
                </Form.Field>
              )}
            />
            <ConditionalField
              name='type'
              when='orgType'
              condition={(orgType) => (orgType === 'site' || orgType === 'customer')}
              validate={required({ message: 'forms.error_required' })}
              render={({
                input, meta: { error: { message, ...options } = {}, touched },
              }) => (
                <Form.Field disabled={isLoading} width={4}>
                  <label>{t(`${TRANSLATION_PREFIX}.organization_type_label`) + requiredLabel}</label>
                  <Dropdown
                    placeholder={t(`${TRANSLATION_PREFIX}.organization_type_label`)}
                    error={(message && (!isNewOrg || touched))}
                    fluid
                    selection
                    disabled={isLoading}
                    {...input}
                    options={INSTITUTION_TYPES}
                    onChange={dropdownOnChange(input)}
                  />
                  {(message && (!isNewOrg || touched)) && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
            <Field
              name='educationLevels'
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field disabled={isLoading} width={8}>
                  <label style={{ display: 'inline-block', marginRight: '8px' }}>{t(`${TRANSLATION_PREFIX}.organization_education_levels_label`)}</label>
                  <Popup
                    trigger={(<Icon className='base-teal' name='help circle' />)}
                    wide
                    hoverable
                    position='top center'>
                    <p>{t(`${TRANSLATION_PREFIX}.education_levels_help_1`)}</p>
                    <p>{t(`${TRANSLATION_PREFIX}.education_levels_help_2`)}</p>
                  </Popup>
                  <Dropdown
                    placeholder={t(`${TRANSLATION_PREFIX}.organization_education_levels_label`)}
                    error={!!message && touched}
                    fluid
                    multiple
                    selection
                    disabled={isLoading}
                    {...input}
                    options={EDUCATION_LEVELS}
                    onChange={dropdownOnChange(input)}
                  />
                  {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />

          </Form.Group>
          <Form.Group>
            <Field
              name='country'
              validate={required({ message: 'forms.error_required' })}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field disabled={isLoading} width={4}>
                  <label>{t(`${TRANSLATION_PREFIX}.organization_country_label`)}{requiredLabel}</label>
                  <Dropdown
                    placeholder={t(`${TRANSLATION_PREFIX}.organization_country_label`, { postProcess: 'titleCase' })}
                    disabled={isLoading}
                    error={!!message && touched}
                    fluid
                    search
                    // Used to disable auto complete
                    searchInput={{
                      type: 'search',
                      autoComplete: 'new-password',
                    }}
                    selection
                    options={SORTED_COUNTRY_REGION_DATA.map((data) => ({
                      text: data.countryName,
                      value: data.countryName,
                    }))}
                    name={input.name}
                    value={input.value}
                    onChange={dropdownOnChange(input)}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                  />
                  {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )
              }
            />
            <ConditionalField
              name='region'
              when='country'
              alwaysRender={true}
              validate={required({ message: 'forms.error_required' })}
              render={({
                conditionalValue, conditionMet, input, meta: { error: { message, ...options } = {}, touched },
              }) => {
                const dropdownOptions = (conditionalValue) ? COUNTRY_REGION_DATA.find((data) => data.countryName === conditionalValue).regions.map((data) => ({ text: data.name, value: data.name })) : []
                const value = (dropdownOptions && dropdownOptions.find((option) => option.value === input.value)) ? input.value : null
                if (input.value && !value) {
                  defer(input.onChange, value)
                }
                return (
                  <Form.Field disabled={isLoading} width={4}>
                    <label>{t(`${TRANSLATION_PREFIX}.organization_region_label`)}{requiredLabel}</label>
                    <Dropdown
                      autoComplete='new-password'
                      placeholder={t(`${TRANSLATION_PREFIX}.organization_region_label`)}
                      disabled={!conditionMet || isLoading}
                      error={!!message && touched}
                      fluid
                      search
                      // Used to disable auto complete
                      searchInput={{
                        type: 'search',
                        autoComplete: 'new-password',
                      }}
                      selection
                      options={dropdownOptions}
                      name={input.name}
                      value={value}
                      onChange={dropdownOnChange(input)}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </Form.Field>
                )
              }}
            />
            <ConditionalField
              name='timezone'
              when='country'
              alwaysRender={true}
              validate={required({ message: 'forms.error_required' })}
              render={({
                conditionalValue, conditionMet, input, meta: { error: { message, ...options } = {}, touched },
              }) => {
                const dropdownOptions = (conditionalValue && GROUPED_TIMEZONE_DATA[COUNTRY_CODES_BY_COUNTRY_NAME[conditionalValue]]) ? GROUPED_TIMEZONE_DATA[COUNTRY_CODES_BY_COUNTRY_NAME[conditionalValue]].map(renderTimezoneOption) : []
                const value = (dropdownOptions && dropdownOptions.find((option) => option.value === input.value)) ? input.value : null
                if (input.value && !value) {
                  defer(input.onChange, value)
                }
                return (
                  <Form.Field disabled={isLoading} width={8}>
                    <label style={{ display: 'inline-block', marginRight: '8px' }}>{t(`${TRANSLATION_PREFIX}.timezone_label`) + requiredLabel}</label>
                    <div style={{ display: 'flex' }}>
                      <Dropdown
                        className='timezone-input'
                        style={{ marginRight: '1em' }}
                        placeholder={t(`${TRANSLATION_PREFIX}.timezone_label`, { postProcess: 'titleCase' })}
                        disabled={!conditionMet || isLoading}
                        error={!!message && touched}
                        fluid
                        search
                        selection
                        options={dropdownOptions}
                        name={input.name}
                        value={value}
                        onChange={dropdownOnChange(input)}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                      <ClockLabel timeZone={input.value} size='large'/>
                    </div>
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </Form.Field>
                )
              }}
            />
          </Form.Group>
          <Form.Group>
            <ConditionalField
              name='contractDates'
              when='orgType'
              condition={(orgType) => (orgType === 'customer')}
              validate={validateContractDates}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field disabled={isLoading} width={8} error={(message && (!isNewOrg || touched))}>
                  <label style={{ display: 'inline-block', marginRight: '8px' }}>{t(`${TRANSLATION_PREFIX}.contract_date_label`) + requiredLabel}</label>
                  <DateRangeInput
                    defaultStartDate={(input.value.startDate) ? new Date(input.value.startDate) : undefined}
                    defaultEndDate={(input.value.endDate) ? new Date(input.value.endDate) : undefined}
                    minimumStartDate={MIN_DATE}
                    maximumStartDate={MAX_START_DATE}
                    maximumEndDate={MAX_END_DATE}
                    startDateLabel={t(`${TRANSLATION_PREFIX}.contract_start_date`)}
                    endDateLabel={t(`${TRANSLATION_PREFIX}.contract_end_date`)}
                    onChange={(newDates) => input.onChange(newDates)}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    disabled={isLoading}
                  />
                  {(message && (!isNewOrg || touched)) && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
            <ConditionalField
              name='numberOfSites'
              when='orgType'
              condition={(orgType) => (orgType === 'customer')}
              parse={rangeValueParser(1, 9999)}
              validate={required({ message: 'forms.error_required' })}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field width={4}>
                  <Form.Input
                    placeholder={t(`${TRANSLATION_PREFIX}.contract_sites_label`)}
                    label={t(`${TRANSLATION_PREFIX}.contract_sites_label`) + requiredLabel}
                    error={(message && (!isNewOrg || touched))}
                    type='number'
                    fluid
                    disabled={isLoading}
                    {...input}
                  />
                  {(message && (!isNewOrg || touched)) && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
            <ConditionalField
              name='studentLimit'
              when='orgType'
              condition={(orgType) => (orgType === 'customer')}
              parse={rangeValueParser(1, 999999999)}
              validate={required({ message: 'forms.error_required' })}
              render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                <Form.Field width={4}>
                  <Form.Input
                    placeholder={t(`${TRANSLATION_PREFIX}.student_limit_label`)}
                    label={t(`${TRANSLATION_PREFIX}.student_limit_label`) + requiredLabel}
                    error={(message && (!isNewOrg || touched))}
                    type='number'
                    fluid
                    disabled={isLoading}
                    {...input}
                  />
                  {(message && (!isNewOrg || touched)) && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                </Form.Field>
              )}
            />
          </Form.Group>
          <ConditionalField
            name='notes'
            when='orgType'
            condition={(orgType) => (orgType === 'customer') && showAdvancedOptions}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field width={8}>
                <Form.TextArea
                  label={t(`${TRANSLATION_PREFIX}.notes_label`)}
                  error={(message && (!isNewOrg || touched))}
                  type='string'
                  disabled={isLoading}
                  {...input}
                />
                {(message && (!isNewOrg || touched)) && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            name='courseIds'
            isEqual={(a, b) => !xor(a, b).length}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <CourseSelector
                availableCourses={sortedCourses}
                selectedCourseIds={input.value}
                onChange={(ids) => input.onChange(ids)}
                isSaving={isLoading}
                isDisabled={!canUpdateCourses}
              />
            )}
          />

          {(isNewOrg) && (
            <React.Fragment>
              <Header as='h4'>{t(`${TRANSLATION_PREFIX}.access_schedule_header`)}</Header>
              <Field
                name='weeklyTimes'
                render={({ input }) => (
                  <WeeklyAccessTimeRanges
                    disabled={isLoading}
                    weeklyTimes={input.value}
                    onChange={input.onChange}
                    color='blue'
                  />
                )}
              />
              <Field
                name='oneTimeExceptions'
                render={({ input }) => (
                  <OneTimeExceptions
                    disabled={isLoading}
                    oneTimeExceptions={input.value}
                    onChange={input.onChange}
                    color='blue'
                  />
                )}
              />
            </React.Fragment>
          )}

          {(showGlobalOptions) && (
            <>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={() => setActiveIndex((activeIndex === 1) ? -1 : 1)}
                >
                  <Header as='h4' icon style={{ marginBottom: 0 }}><Icon name='dropdown' /> Options</Header>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <ConditionalField
                    name='privateRoomsEnabled'
                    when='roomsEnabled'
                    render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                      <Form.Checkbox
                        label={t(`${TRANSLATION_PREFIX}.private_rooms_enabled_label`)}
                        toggle
                        disabled={isLoading}
                        {...input}
                        checked={input.value}
                        onChange={checkboxOnChange(input)}/>
                    )}
                  />
                </Accordion.Content>
              </Accordion>
            </>
          )}

          {(showAdvancedOptions) && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() => setActiveIndex((activeIndex === 0) ? -1 : 0)}
              >
                <Header as='h4' icon style={{ marginBottom: 0 }}><Icon name='dropdown' /> Internal Options</Header>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                {/* <Field
                  name='selfManagedNotificationRoleTypes'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <Form.Field disabled={isLoading}>
                      <label style={{ display: 'inline-block', marginRight: '8px' }}>{t(`${TRANSLATION_PREFIX}.organization_notifications_header`)}</label>
                      <Popup
                        hoverable
                        trigger={(<Icon color='blue' name='help circle' />)}
                        wide='very'
                        position='right center'>
                        <p>{t(`${TRANSLATION_PREFIX}.notifications_help_1`)}</p>
                        <p>{t(`${TRANSLATION_PREFIX}.notifications_help_2`)}</p>
                      </Popup>
                      <Dropdown
                        placeholder={``}
                        error={!!message && touched}
                        fluid
                        multiple
                        selection
                        disabled={isLoading}
                        {...input}
                        options={notificationRoles.map((role) => ({ text: role.name, value: role.type }))}
                        onChange={dropdownOnChange(input)}
                      />
                      {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    </Form.Field>
                  )}
                /> */}

                <Field
                  name='firewordsEnabled'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <Form.Checkbox
                      label={t(`${TRANSLATION_PREFIX}.firewords_enabled_label`)}
                      toggle
                      disabled={isLoading}
                      {...input}
                      checked={input.value}
                      onChange={checkboxOnChange(input)}/>
                  )}
                />

                <Field
                  name='sevenMindsetsEnabled'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <Form.Checkbox
                      label={t(`${TRANSLATION_PREFIX}.seven_mindsets_enabled_label`)}
                      toggle
                      disabled={isLoading}
                      {...input}
                      checked={input.value}
                      onChange={checkboxOnChange(input)}/>
                  )}
                />

                <Field
                  name='helpButtonDisabled'
                  render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                    <Form.Checkbox
                      label={t(`${TRANSLATION_PREFIX}.help_button_disabled_label`)}
                      toggle
                      disabled={isLoading}
                      {...input}
                      checked={input.value}
                      onChange={checkboxOnChange(input)} />
                  )}
                />

                <div className='fireword-notifications-toggle'>
                  <Field
                    name='firewordNotificationsThreshold'
                    render={({ input }) => (
                      <Slider
                        disabled={isLoading}
                        label='Fireword Email Notifications'
                        onChange={(value) => input.onChange(parseInt(value))}
                        value={input.value}
                      />
                    )}
                  />
                </div>

                {/* // <Form.Field disabled={isLoading}>
                    //   <Dropdown
                    //     placeholder={`${t('select', { postProcess: 'titleCase' })} ${t('one_or_more')} ${t('resource_types.course', { count: 2 })}`}
                    //     error={!!message && touched}
                    //     fluid
                    //     search
                    //     multiple
                    //     selection
                    //     disabled={isLoading}
                    //     {...input}
                    //     options={sortedCourses.map((course) => ({ text: course.titles, value: course.id }))}
                    //     onChange={dropdownOnChange(input)}>
                    //   </Dropdown>
                    //   {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                    // </Form.Field> */}

              </Accordion.Content>
            </Accordion>
          )}
          <br style={{ clear: 'both' }}/>
          <Button
            data-public
            type='submit'
            floated='right'
            loading={isLoading}
            color='green'
            disabled={submitDisabled}>
            {(isNewOrg) ? t('forms.create_button') : t('forms.save_button')}
          </Button>
          <br style={{ clear: 'both' }}/>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <BaseForm
      header={header}
      basic={isBlankForm}
      disableWarning={isBlankForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
      initialValues={{
        ...formValues,
        type: formValues.type || undefined,
        activeIndex,
        orgSource,
      }}
      render={renderForm}
    />
  )
}

GenericOrgForm.propTypes = {
  orgType: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  isBlankForm: PropTypes.bool,
  isSSO: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNewOrg: PropTypes.bool,
  onSubmit: PropTypes.func,
  courses: PropTypes.arrayOf(PropTypes.object),
  showAdvancedOptions: PropTypes.bool,
  showGlobalOptions: PropTypes.bool,
  t: PropTypes.func,
  header: PropTypes.any,
  canUpdateCourses: PropTypes.bool,
  canCreateIntegrationOrgs: PropTypes.bool,
}

const PutCustomerForm = (props) => {
  return (
    <GenericOrgForm
      orgType='customer'
      {...props}
    />
  )
}

const PutSiteForm = (props) => {
  return (
    <GenericOrgForm
      orgType='site'
      {...props}
    />
  )
}

const PutGroupForm = (props) => {
  return (
    <GenericOrgForm
      orgType='group'
      {...props}
    />
  )
}

export class PutOrganizationForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    courses: PropTypes.array.isRequired,
    name: PropTypes.string,
    notes: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.object),
    courseIds: PropTypes.arrayOf(PropTypes.string),
    selfManagedNotificationRoleTypes: PropTypes.arrayOf(PropTypes.string),
    sessionsEnabled: PropTypes.bool,
    roomsEnabled: PropTypes.bool,
    privateRoomsEnabled: PropTypes.bool,
    firewordsEnabled: PropTypes.bool,
    sevenMindsetsEnabled: PropTypes.bool,
    helpButtonDisabled: PropTypes.bool,
    firewordNotificationsThreshold: PropTypes.number,
    requireSessionPasswords: PropTypes.bool,
    isNewOrg: PropTypes.bool,
    isSSO: PropTypes.bool,
    showAdvancedOptions: PropTypes.bool,
    orgType: PropTypes.string,
    type: PropTypes.string,
    educationLevels: PropTypes.arrayOf(PropTypes.string),
    studentAccessSchedule: PropTypes.object,
    country: PropTypes.string,
    region: PropTypes.string,
    t: PropTypes.func,
    contractStartDate: PropTypes.string,
    contractEndDate: PropTypes.string,
    numberOfSites: PropTypes.number,
    studentLimit: PropTypes.number,
    hideHeader: PropTypes.bool,
    canUpdateToCustomer: PropTypes.bool,
    canUpdateToSite: PropTypes.bool,
    showGlobalOptions: PropTypes.bool,
    onRemoveNotifications: PropTypes.func,
    canUpdateCourses: PropTypes.bool,
    canCreateIntegrationOrgs: PropTypes.bool,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'green',
    isLoading: false,
    isNewOrg: true,
    courseIds: [],
    isSSO: false,
    sessionsEnabled: false,
    roomsEnabled: true,
    privateRoomsEnabled: false,
    firewordsEnabled: true,
    sevenMindsetsEnabled: true,
    helpButtonDisabled: false,
    firewordNotificationsThreshold: 3,
    notes: '',
    requireSessionPasswords: false,
    showAdvancedOptions: false,
    hideHeader: false,
    t: (key, opts = {}) => opts.defaultValue || key,
    onRemoveNotifications: console.log.bind(console, 'onRemoveNotifications'),
    canUpdateCourses: false,
    canCreateIntegrationOrgs: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      orgType: props.orgType,
    }
  }

  handleSaveForm = (data) => {
    const {
      onSave,
      studentAccessSchedule,
    } = this.props
    onSave({
      ...pick(data, [
        'name',
        'notes',
        'type',
        'educationLevels',
        'type',
        'country',
        'region',
        'numberOfSites',
        'studentLimit',
        'courseIds',
        'orgSource',
      ]),
      selfManagedNotificationRoleTypes: data.selfManagedNotificationRoleTypes || [],
      sessionsEnabled: false,
      notes: data.notes || null,
      roomsEnabled: true,
      privateRoomsEnabled: (isNil(data.privateRoomsEnabled)) ? false : data.privateRoomsEnabled,
      firewordsEnabled: (isNil(data.firewordsEnabled)) ? true : data.firewordsEnabled,
      sevenMindsetsEnabled: (isNil(data.sevenMindsetsEnabled)) ? true : data.sevenMindsetsEnabled,
      helpButtonDisabled: (isNil(data.helpButtonDisabled)) ? false : data.helpButtonDisabled,
      firewordNotificationsThreshold: data?.firewordNotificationsThreshold || 3,
      requireSessionPasswords: false,
      contractStartDate: get(data, 'contractDates.startDate', null),
      contractEndDate: get(data, 'contractDates.endDate', null),
      studentAccessSchedule: {
        timezone: data.timezone || studentAccessSchedule.timezone,
        weeklyTimes: data.weeklyTimes || studentAccessSchedule.weeklyTimes,
        oneTimeExceptions: data.oneTimeExceptions || studentAccessSchedule.oneTimeExceptions,
        specificTimes: [] || studentAccessSchedule.specificTimes,
        yearlyExceptions: [] || studentAccessSchedule.yearlyExceptions,
      },
    })
  }

  render () {
    const {
      courses,
      name,
      type,
      educationLevels,
      studentAccessSchedule,
      country,
      region,
      courseIds,
      sessionsEnabled,
      roomsEnabled,
      privateRoomsEnabled,
      firewordsEnabled,
      sevenMindsetsEnabled,
      helpButtonDisabled,
      firewordNotificationsThreshold,
      requireSessionPasswords,
      selfManagedNotificationRoleTypes,
      isLoading,
      isSSO,
      contractStartDate,
      contractEndDate,
      numberOfSites,
      studentLimit,
      notes,
      hideHeader,
      isNewOrg,
      canUpdateToCustomer,
      canUpdateToSite,
      showAdvancedOptions,
      showGlobalOptions,
      onRemoveNotifications,
      canUpdateCourses,
      canCreateIntegrationOrgs,
      t,
    } = this.props
    const {
      orgType,
    } = this.state
    const sortedCourses = sortBy(courses, 'titles')
    const allCourseIds = map(sortedCourses, 'id')
    const sortedCourseIds = intersection(allCourseIds, courseIds)
    const isBlankForm = !name
    const weeklyTimes = get(studentAccessSchedule, 'weeklyTimes', [
      {
        day: 2, startMinute: 450, endMinute: 930,
      },
      {
        day: 3, startMinute: 450, endMinute: 930,
      },
      {
        day: 4, startMinute: 450, endMinute: 930,
      },
      {
        day: 5, startMinute: 450, endMinute: 930,
      },
      {
        day: 6, startMinute: 450, endMinute: 930,
      },
    ])
    const timezone = get(studentAccessSchedule, 'timezone')
    const oneTimeExceptions = get(studentAccessSchedule, 'oneTimeExceptions', [])

    const SelectedForm = (orgType === 'customer') ? PutCustomerForm : (orgType === 'site') ? PutSiteForm : PutGroupForm
    const headerText = (orgType === 'customer') ? t(`${TRANSLATION_PREFIX}.customer_details`) : (orgType === 'site') ? t(`${TRANSLATION_PREFIX}.site_details`) : t(`${TRANSLATION_PREFIX}.group_details`)
    const header = (hideHeader) ? null : (
      <React.Fragment>
        <Header data-public style={{ marginTop: 0 }} className='inline'><Icon name={find(ORG_TYPE_OPTIONS, { value: orgType }).icon} />{headerText}</Header>
        <OrgTypeModal
          orgType={orgType}
          canUpdateToCustomer={canUpdateToCustomer}
          canUpdateToSite={canUpdateToSite}
          onSelect={(newOrgType) => {
            this.setState({ orgType: newOrgType })
            if (orgType === 'customer' && newOrgType === 'site') {
              this.handleSaveForm({
                contractDates: null,
                numberOfSites: null,
                studentLimit: -1,
              })
            }
            if (orgType === 'customer' && newOrgType === 'group') {
              this.handleSaveForm({
                contractDates: null,
                numberOfSites: null,
                type: null,
                studentLimit: -1,
              })
            }
            if (orgType === 'site' && newOrgType === 'group') {
              this.handleSaveForm({
                type: null,
                numberOfSites: null,
              })
            }
          }}
        />
        {(!isNewOrg && showAdvancedOptions) && (
          <Button
            floated='right'
            color='orange'
            icon
            size='tiny'
            loading={isLoading}
            disabled={isLoading}
            onClick={() => onRemoveNotifications()}
          >
            <Icon name='remove'/> Disable All User Notifications
          </Button>
        )}
      </React.Fragment>
    )
    return (
      <SelectedForm
        onSubmit={this.handleSaveForm}
        isBlankForm={isBlankForm}
        isLoading={isLoading}
        isNewOrg={isNewOrg}
        isSSO={isSSO}
        showAdvancedOptions={showAdvancedOptions}
        showGlobalOptions={showGlobalOptions}
        courses={courses}
        canUpdateCourses={canUpdateCourses}
        canCreateIntegrationOrgs={canCreateIntegrationOrgs}
        t={t}
        formValues={{
          name,
          type,
          educationLevels: educationLevels || [],
          sessionsEnabled,
          roomsEnabled,
          privateRoomsEnabled,
          firewordsEnabled,
          sevenMindsetsEnabled,
          helpButtonDisabled,
          firewordNotificationsThreshold,
          requireSessionPasswords,
          selfManagedNotificationRoleTypes: selfManagedNotificationRoleTypes || [],
          courseIds: sortedCourseIds,
          isSSO,
          weeklyTimes,
          oneTimeExceptions,
          country: country || 'United States',
          region,
          timezone,
          contractDates: {
            startDate: contractStartDate,
            endDate: contractEndDate,
          },
          numberOfSites,
          studentLimit,
          notes,
          activeIndex: this.state.activeIndex,
          orgType,
        }}
        header={header}
      />
    )
  }
}

export default translate([ 'components' ])(PutOrganizationForm)
