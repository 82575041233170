import gql from 'graphql-tag'

const GET_INDIVIDUAL_USER_ASSESSMENT_SCORES = gql`
query individualUserAssessmentScoresByOrgId($orgId: String!, $assessmentId: String!, $schoolYear: Int!, $schoolYearAttempt: Int!, $sortBy: String, $sortOrder: Int, $skip: Int, $limit: Int, $searchText: String) {
  individualUserAssessmentScoresByOrgId(
    orgId: $orgId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
    schoolYearAttempt: $schoolYearAttempt
    sortBy: $sortBy
    sortOrder: $sortOrder
    skip: $skip
    limit: $limit
    searchText: $searchText
  ) {
    clientId
    orgName
    orgId
    userName
    firstName
    lastName
    schoolYearAttempt
    timeSeconds
    created
    scores {
      sectionKey
      percentile
      percentileGroup
      meanScore
      normalizedMeanScore
    }
  }
}
`

export default GET_INDIVIDUAL_USER_ASSESSMENT_SCORES
