import React from 'react'
import PropTypes from 'prop-types'
import {
  Segment, Header, Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  sortBy, isString, isNumber,
} from 'lodash'
import { ResponsiveBar } from '@nivo/bar'

import './client-total-course-usage.css'

const TRANSLATION_PREFIX = 'charts.client-total-course-usage'

const virticalTick = ({
  // eslint-disable-next-line react/prop-types
  textAnchor, textBaseline, value, x, y,
}) => {
  const MAX_LINE_LENGTH = 20
  const MAX_LINES = 3
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    'gm',
  )
  // eslint-disable-next-line react/prop-types
  const isOverLimit = value.length > TRIM_LENGTH
  const splitValues = value
    // eslint-disable-next-line react/prop-types
    .match(groupWordsByLength)
    .slice(0, MAX_LINES)
    .map((val, i, arr) => (
      <tspan
        key={val}
        dy={(i === 0) ? (arr.length - 1) * -5 : 12}
        x={-20}
        style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
      >
        {(isOverLimit && arr.length === i + 1) ? `${val}…` : val}
      </tspan>
    ))
  return (
    <g transform={`translate(${x},${y || 0})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  )
}

class ClientTotalCourseUsageChart extends React.Component {
  static propTypes = {
    clientUsageByCourse: PropTypes.object,
    courseTitlesById: PropTypes.object,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    clientUsageByCourse: {},
    courseTitlesById: {},
    isLoading: false,
    width: '100%',
  }

  render () {
    const {
      clientUsageByCourse,
      courseTitlesById,
      isLoading,
      width,
      height,
      t,
    } = this.props

    const usageByCourseId = clientUsageByCourse || {}

    const dataByCourseIdMinutes = {}
    const dataByCourseIdHours = {}
    let maxValue = 0
    let longestCourseTitle = ''
    Object.keys(usageByCourseId)
      .forEach((courseId) => {
        usageByCourseId[courseId]
          .filter((usage) => !isString(usage.attemptNumber))
          .forEach((usage) => {
            if (!usage.seconds) {
              return
            }
            const courseTitle = courseTitlesById[courseId]
            if (!courseTitle) {
              return
            }
            const attemptNumberLabel = `Attempt #${usage.attemptNumber}`
            dataByCourseIdMinutes[courseId] = dataByCourseIdMinutes[courseId] || {}
            dataByCourseIdMinutes[courseId].courseTitle = courseTitle
            dataByCourseIdMinutes[courseId][attemptNumberLabel] = +Math.ceil(usage.seconds / 60).toFixed(1) // convert to number with 1 decimal place
            if (maxValue < dataByCourseIdMinutes[courseId][attemptNumberLabel]) {
              maxValue = dataByCourseIdMinutes[courseId][attemptNumberLabel]
            }

            dataByCourseIdHours[courseId] = dataByCourseIdHours[courseId] || {}
            dataByCourseIdHours[courseId].courseTitle = courseTitle
            dataByCourseIdHours[courseId][attemptNumberLabel] = usage.seconds / 3600
            dataByCourseIdHours[courseId][attemptNumberLabel] = +dataByCourseIdHours[courseId][attemptNumberLabel].toFixed(1) // convert to number with 1 decimal place

            if (dataByCourseIdMinutes[courseId][attemptNumberLabel] > 0 && longestCourseTitle.length < courseTitle.length) {
              longestCourseTitle = courseTitle
            }
          })
      })
    const data = (maxValue > 180) ? dataByCourseIdHours : dataByCourseIdMinutes
    const xAxisLabel = (maxValue > 180) ? t(`${TRANSLATION_PREFIX}.y_axis_label_hours`) : t(`${TRANSLATION_PREFIX}.y_axis_label_minutes`)
    const hasData = !!Object.keys(usageByCourseId).length
    const minHeightPerBar = 40
    const idealHeight = (Object.values(data).length * minHeightPerBar) + 60 || 0
    return (
      <Segment className='clientTotalCourseUsage' loading={isLoading}>
        <Header className='usageHeader'>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
        {!hasData && <Message info>
          <Message.Header>{t(`${TRANSLATION_PREFIX}.no_activity_header`)}</Message.Header>
          <p>{t(`${TRANSLATION_PREFIX}.no_activity_description`)}</p>
        </Message>}
        {hasData && <div style={{
          height: height || idealHeight,
          width,
        }}>
          <ResponsiveBar
            padding={0.25}
            layout='horizontal'
            margin={{
              top: 10,
              right: 10,
              bottom: 40,
              left: 150,
            }}
            colors={() => '#B8D6C2'}
            labelSkipHeight={15}
            labelSkipWidth={60}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', '1.1' ] ] }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              renderTick: virticalTick,
            }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              legend: xAxisLabel,
              legendPosition: 'middle',
              legendOffset: 30,
            }}
            valueFormat={(value) => `${value} ${(value === 1) ? xAxisLabel.split(' ')[0].replace('s', '') : xAxisLabel.split(' ')[0]}`}
            data={sortBy(Object.values(data), (d) => Object.values(d).reduce((accum, val) => (isNumber(val)) ? accum + val : accum, 0))}
            animate={false}
            indexBy='courseTitle'
            keys={[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15' ].map((num) => `Attempt #${num}`)}
          />
        </div>}
      </Segment>
    )
  }
}

export default translate([ 'components' ])(ClientTotalCourseUsageChart)
