import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Segment,
  Item,
  Button,
  Header,
  Icon,
  Label,
  Placeholder,
  Sticky,
  Ref,
  Divider,
  Modal,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  isEqual,
  get,
  map,
  sortBy,
  // without,
  isNumber,
  flatten,
  debounce,
} from 'lodash'
// import AWS from 'aws-sdk'
// import to from 'await-to-js'
// import delay from 'delay'

import isStale from '../../../../helpers/is-stale'
import {
  FETCH_ACTIONS,
  // HTTP_METHODS
} from '../../../../helpers/fetch-constants'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import exportToCsv from '../../../../helpers/export-to-csv'
import {
  getAllContentItems,
  invalidateContentItems,
  updateContentItem,
  createContentItem,
  createContentItemsInEmptyCourse,
  deleteContentItem,
  acknowledgeCreateContentItem,
  acknowledgeUpdateContentItem,
  moveContentItems,
} from '../../../../actions/content-items'
import {
  getCourseImages,
  invalidateCourseImages,
  uploadCourseImages,
  acknowledgeUploadCourseImages,
} from '../../../../actions/course-images'
import {
  updateCourse,
  acknowledgeUpdateCourse,
  uploadCourseGuide,
  deleteCourseGuide,
} from '../../../../actions/courses'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import UpdateCourseForm from '../../../forms/update-course'
import Pager from '../../../common/pager/pager'
import EditableTextArea from '../../../common/editable/editable-text-area'
import UploadCourseCsvForm from '../../../forms/upload-course-csv/upload-course-csv'
// import fetch from '../../../../helpers/fetch'

import './course-edit.css'

// const Bucket = 'course.base.education'
// const Prefix = 'img/'
// AWS.config.region = 'us-west-2'

const courseIdPathParam = ':courseId'
const path = `/courses/${courseIdPathParam}/edit`
const SCROLL_POSITION_MEMORY_KEY = 'ci-id-scroll-postion.base.education'
const PAGE_NUMBER_MEMORY_KEY = 'ci-page-number.base.education'
// const TRANSLATION_PREFIX = 'views.organization.course-edit'

export const helpers = {
  // all urls under a given course
  getPath: (courseId) => `${path.replace(courseIdPathParam, courseId)}`,
}

const bgImagesBaseUrl = 'https://course.base.education/img'
const TYPE_OPTIONS = {
  text: 'Text',
  freeform: 'Freeform Textbox',
  'freeform-na': 'Freeform Textbox with Does Not Apply Option',
  'true-false': 'True / False',
  'yes-no': 'Yes / No',
  'multiple-choice': 'Multiple Choice',
  'scale-1-10': 'Scale of 1 to 10',
  accept: 'Accept',
  'video-player': 'Video Player',
}
const ICONS_BY_TYPE = {
  freeform: 'edit',
  'freeform-na': 'edit',
  'true-false': 'thumbs up',
  'yes-no': 'thumbs up',
  'scale-1-10': 'tachometer alternate',
  'multiple-choice': 'check square',
  'video-player': 'video',
  accept: 'handshake',
}
const removeScrollMemory = debounce(() => {
  window.sessionStorage.removeItem(SCROLL_POSITION_MEMORY_KEY)
}, 2000, { maxWait: 2000 })

export class CourseEditView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    putCoursesState: PropTypes.object.isRequired,
    getContentItemsState: PropTypes.object.isRequired,
    postContentItemsState: PropTypes.object.isRequired,
    putContentItemsState: PropTypes.object.isRequired,
    deleteContentItemsState: PropTypes.object.isRequired,
    getCourseImagesState: PropTypes.object.isRequired,
    getAllContentItems: PropTypes.func.isRequired,
    invalidateContentItems: PropTypes.func.isRequired,
    updateCourse: PropTypes.func.isRequired,
    updateContentItem: PropTypes.func.isRequired,
    createContentItem: PropTypes.func.isRequired,
    createContentItemsInEmptyCourse: PropTypes.func.isRequired,
    deleteContentItem: PropTypes.func.isRequired,
    acknowledgeCreateContentItem: PropTypes.func.isRequired,
    acknowledgeUpdateContentItem: PropTypes.func.isRequired,
    moveContentItems: PropTypes.func.isRequired,
    getCourseImages: PropTypes.func.isRequired,
    putCourseImagesState: PropTypes.object.isRequired,
    uploadCourseImages: PropTypes.func.isRequired,
    uploadCourseGuide: PropTypes.func.isRequired,
    deleteCourseGuide: PropTypes.func.isRequired,
    invalidateCourseImages: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    onEditItemClick: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    acknowledgeUpdateCourse: PropTypes.func.isRequired,
    acknowledgeUploadCourseImages: PropTypes.func.isRequired,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  static path = path

  constructor () {
    super()
    this.state = {
      isEditing: false,
      showUploadCisPopup: false,
    }
  }

  contextRef = createRef()

  saveContentItem = (contentItemId, data) => {
    const {
      authState: { accessToken },
      updateContentItem,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props

    const oldContentItem = get(getContentItemsState, `value.${contentItemId}`)
    const titles = oldContentItem.titles.map((line) => ({ en: line }))
    const bodies = oldContentItem.bodies.map((line) => ({ en: line }))
    const titlesNotChanged = (data.titles) ? isEqual(titles, data.titles) : true
    const bodiesNotChanged = (data.bodies) ? isEqual(bodies, data.bodies) : true
    if (titlesNotChanged && bodiesNotChanged) {
      return
    }

    updateContentItem({
      data: {
        titles: oldContentItem.titles.map((line) => ({ en: line })),
        bodies: oldContentItem.bodies.map((line) => ({ en: line })),
        ordinal: oldContentItem.ordinal,
        notes: (oldContentItem.notes && oldContentItem.notes.en) ? oldContentItem.notes : undefined,
        type: oldContentItem.type,
        view: oldContentItem.view,
        ...data,
        id: contentItemId,
      },
      courseId,
      accessToken,
      oldContentItem,
    })
  }

  moveContentItems = (contentItem, toOrdinal) => {
    const {
      authState: { accessToken },
      moveContentItems,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props

    if (toOrdinal !== 0 && !toOrdinal) {
      const newOrdinal = window.prompt('Enter the ordinal you would like to move this item to:')
      toOrdinal = newOrdinal * 1
      if (!newOrdinal || (toOrdinal !== 0 && !toOrdinal)) {
        window.alert('Cancelling')
        return false
      }
    }

    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    if (!contentItems.length || contentItem.ordinal === toOrdinal) {
      alert('Unable to move content item')
      return
    }
    const contentItemIds = map(contentItems, 'id')
    contentItemIds.splice(contentItem.ordinal, 1)
    contentItemIds.splice(toOrdinal, 0, contentItem.id)
    moveContentItems({
      accessToken,
      courseId,
      contentItemIds,
    })
  }

  // fixContentItemImages = async () => {
  //   const {
  //     authState: { accessToken },
  //     updateContentItem,
  //     getContentItemsState,
  //     match: { params: { courseId } },
  //   } = this.props

  //   const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
  //   if (!contentItems.length) {
  //     alert('Unable to fix content items')
  //     return
  //   }
  //   const headers = { authorization: `Bearer ${accessToken}` }
  //   const [ s3KeyErr, s3KeyInfo ] = await to(fetch(`/s3AccessKeys`, { method: HTTP_METHODS.GET, headers }))
  //   if (s3KeyErr) {
  //     throw s3KeyErr
  //   }

  //   AWS.config.update(s3KeyInfo)
  //   const s3 = new AWS.S3()

  //   const knownGoodImgFromBadImg = {
  //     'ideas-for-success': 'light_bulb_colored_paper',
  //     lightening: 'ligntening_over_ocean',
  //     'barriers-to-success': 'barrier-to-success',
  //     'red-x-and-green-check': 'red_x_and_green_check_mark',
  //     'moving-forward': 'starting-line',
  //     'downward-cycle': 'bkrd_blue_paint_peeling',
  //     'water-ripples1': 'gray_water_ripples',
  //     'water-ripples2': 'blue_water_ripples',
  //     'motivation-starting-point': 'starting_line_on_road',
  //     'primary-impacts': 'furry_dog',
  //     'stepping-stones-on-water': 'stepping_stones_in_water',
  //     a151H: '151H',
  //     a159H: '159H',
  //     a217H: '217H',
  //     a218H: '218H',
  //     a48H: '48H',
  //     a5FGWJW4Z5D: '5FGWJW4Z5D',
  //     a6R7KCXBEEE: '6R7KCXBEEE',
  //     a71H: '71H',
  //     a733CQRSNF3: '733CQRSNF3',
  //     'a94H--1-': '94H--1-',
  //     't-rex-in-jungle': 't-rex_in_jungle',
  //     'boy-with-cartoon-rocket': 'boy_sleeping_with_book_on_face',
  //     'walking-path': 'walking_path',
  //     'plant-through-asphalt': 'plant_growing_through_asphalt',
  //     'man-looking-thoughtful': 'man_with_hands_in_pockets_with_dark_t-shirt',
  //     'sad-teenage-girl': 'sad_tennage_girl',
  //     'flower-growing-from-red-soil': 'flower_growing_out_of_red_soil',
  //     'sad-man-on-couch': 'sad_man_on_couch_grey',
  //     'gossip-girls': 'teenage_girl_with_gossip_girls',
  //     'girl-against-tree-bullied': 'lonely_teen_girl_being_bullied',
  //     'blurry-lights': 'blurry_colorful_lights',
  //     'pug-party-dog': 'pug_dog_with_party_hat',
  //     'silhouettes-at-dusk': 'silhouetted_friends_jumping_at_sunset',
  //     'bird-on-wire': 'bird_on_a_wire',
  //     'tree-of-people': 'tree_formed_by_people',
  //     'brown-wood-deck': 'bkrd_brown_wood_planks_going_up_wall',
  //     'man-on-sand-island': 'man_on_sand_island_in_ocean',
  //     'moving-forward-blocks': 'starting-line',
  //     'rock-climber-ledge': 'rock_climber_hanging_on_ledge',
  //     'boy-with-basketball': 'boy_with_basketball_against_fence',
  //     'young-man-with-glasses': 'young_man_with_glasses_thinking',
  //     'stones-stacked-on-wood': 'stones_stacked_against_dark_wood_floor',
  //     'african-american-kids-playing-soccer': 'african_american_boys_playing_with_soccer_ball',
  //     'superboy-on-roof': 'boy_dreams_of_becoming_superman',
  //     'dock-over-water': 'dock_into_swiming_pool',
  //     'speak-calmly': 'woman_on_beach',
  //     'deck-into-pool': 'dock_into_swiming_pool',
  //     'winning-race': 'man_winning_race_against_silhouette_of_self',
  //     'team-on-mountain': 'team_on_top_of_the_mountain',
  //     bkrd_old_brick_wall_in_alley: 'barrier-to-success',
  //     dictionary: 'dictionary-open',
  //     'kid-one': 'kid1',
  //     'kid-two': 'kid2',
  //     'kid-three': 'kid3',
  //     'kid-four': 'kid4',
  //     'kid-five': 'kid5',
  //     'angry-father': 'angry_father_talking_to_child',
  //     impact: 'wall_broken_by_fist',
  //     'banana-peel': 'stepping_on_banana_peel',
  //     a10JJR40XTG: '10JJR40XTG',
  //     'lost-motivation': 'fuel_gauge_on_empty',
  //     'plant-through-wood': 'plant_growing_through_wood',
  //     'speakers-loud': 'speakers_blaring',
  //     'woman-staring-at-wall': 'woman_staring_at_brick_wall',
  //     'child-motivation2': 'boy_against_chalkboard_with_dinosaur',
  //     'child-with-backpack': 'smiling_girl_with_backpack',
  //     unmotivated: 'boy_sleeping_with_book_on_face',
  //     'extrinsic-motivation': 'young_boy_hates_doing_homework',
  //     'boxing-gloves': 'red_boxing_glove_against_blue_boxing_gloves',
  //     'ants-pushing-rock': 'ants_pushing_a_rock',
  //     'basketball-hoop-against-sky': 'basektball_hoop_against_sky',
  //     'girl-painting': 'little_girl_painting',
  //     'ideas-for-success2': 'light_bulb_colored_paper',
  //     'teen-with-headphones': 'teen_with_headphones_playing_game',
  //     'girl-pulling-on-moon': 'little_girl_pulling_on_moon_with_rope',
  //     world: 'earth_from_space',
  //     'secondary-impacts': 'man_sneezing',
  //     'impacting-your-class': 'frustrated_teacher',
  //     'look-for-solution': 'solution_compass',
  //     'consider-others-feelings': 'colorful_hands_touching',
  //     'part-of-puzzle': 'puzzle_with_gold_piece',
  //     'stress-free': 'man_with_headphones_looking_at_sky',
  //     'deck-into-clouds': 'bkrd_deck_into_clouds',
  //     move_foward_block_puzzle: 'starting-line',
  //     hourglass: 'hourglass1',
  //     'the-problem2': 'bkrd_grass',
  //     'fish-in-tree': 'fish_climbing_tree',
  //     'deck-into-trees': 'bkrd_deck_into_green_trees',
  //     'stick-and-carrot': 'stick_and_carrot',
  //     'light-brown-wood-deck': 'bkrd_light_brown_wood_deck',
  //   }

  //   const mapper = async (ci, idx) => {
  //     if (ci.view.container.indexOf('.jpg') >= 0 || ci.view.container.indexOf('.png') >= 0 || ci.view.container.indexOf('.jpeg') >= 0 || ci.view.container.indexOf('.svg') >= 0) {
  //       console.log('No need to update CI: ', ci.ordinal, ci.view.container)
  //       return null
  //     }

  //     const bgImageParts = without(ci.view.container.trim().split(' '), 'c', 'n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw', 'dark-screen', 'light-screen', 'show', '2')
  //     let bgImage = (bgImageParts.length) ? bgImageParts[0].replace(/^(\d)/, 'a$1') : ''
  //     if (!bgImage) {
  //       const containerStr = contentItems[idx - 1].view.container.trim()
  //       console.log('Found image from prev CI:', containerStr, 'Updating CI', ci.ordinal)

  //       updateContentItem({
  //         data: {
  //           ...ci,
  //           titles: (ci.titles.length) ? ci.titles.map((en) => ({ en })) : [],
  //           bodies: (ci.bodies.length) ? ci.bodies.map((en) => ({ en })) : [],
  //           view: { ...ci.view, container: containerStr },
  //           notes: (ci.notes.en) ? ci.notes : null,
  //           voiceUrl: (ci.voiceUrl.en) ? ci.voiceUrl : null,
  //           _meta: undefined,
  //         },
  //         courseId,
  //         accessToken,
  //         oldContentItem: ci,
  //       })
  //       return
  //     }
  //     bgImage = knownGoodImgFromBadImg[bgImage] || bgImage
  //     const fileExt = 'jpg'

  //     const [ firstTryErr ] = await to(s3.headObject({
  //       Bucket,
  //       Key: `${Prefix}${bgImage}.${fileExt}`,
  //     }).promise())
  //     if (firstTryErr) {
  //       bgImage = bgImage.replace(/-/g, '_')
  //       const [ secondTryErr ] = await to(s3.headObject({
  //         Bucket,
  //         Key: `${Prefix}${bgImage}.${fileExt}`,
  //       }).promise())
  //       if (secondTryErr) {
  //         bgImage = bgImage.replace(/_/g, '-')
  //         const [ thirdTryErr ] = await to(s3.headObject({
  //           Bucket,
  //           Key: `${Prefix}${bgImage}.${fileExt}`,
  //         }).promise())
  //         if (thirdTryErr) {
  //           const [ fourthTryErr ] = await to(s3.headObject({
  //             Bucket,
  //             Key: `${Prefix}${bgImage}.svg`,
  //           }).promise())
  //           if (fourthTryErr) {
  //             const [ fifthTryErr ] = await to(s3.headObject({
  //               Bucket,
  //               Key: `${Prefix}${bgImage}.png`,
  //             }).promise())
  //             if (fifthTryErr) {
  //               console.error('Unable to find image for panel', ci.ordinal, ci.view.container)
  //               return null
  //             }
  //           }
  //         }
  //       }
  //     }
  //     console.log('Found image:', bgImage, 'Updating CI', ci.ordinal)

  //     updateContentItem({
  //       data: {
  //         ...ci,
  //         titles: (ci.titles.length) ? ci.titles.map((en) => ({ en })) : [],
  //         bodies: (ci.bodies.length) ? ci.bodies.map((en) => ({ en })) : [],
  //         view: { ...ci.view, container: `${bgImage}.${fileExt}` },
  //         notes: (ci.notes.en) ? ci.notes : null,
  //         voiceUrl: (ci.voiceUrl.en) ? ci.voiceUrl : null,
  //         _meta: undefined,
  //       },
  //       courseId,
  //       accessToken,
  //       oldContentItem: ci,
  //     })

  //     return delay(500)
  //   }
  //   pMap(contentItems, mapper, { concurrency: 5 })
  // }

  addContentItem = (newOrdinal) => {
    const {
      authState: { accessToken },
      createContentItem,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    const contentItemIds = map(contentItems, 'id')
    const ordinal = (isNumber(newOrdinal)) ? newOrdinal : contentItems.length
    createContentItem({
      data: {
        bodies: [],
        titles: [],
        ordinal,
        view: {
          container: 'air-bubbles-1426439.jpg',
          classes: 'padding pull-left no-screen pull-up',
          textClasses: '',
          titleClasses: '',
        },
        type: 'text',
      },
      contentItemIdsBefore: contentItemIds.slice(0, ordinal),
      contentItemIdsAfter: contentItemIds.slice(ordinal),
      accessToken,
      courseId,
    })
  }

  addContentItemsToEmptyCourse = (items) => {
    const {
      authState: { accessToken },
      createContentItemsInEmptyCourse,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props
    if (getContentItemsState.value && Object.keys(getContentItemsState.value).length) {
      throw new Error('Unable to add panels to a non-empty module')
    }
    createContentItemsInEmptyCourse({
      data: items,
      accessToken,
      courseId,
    })
  }

  removeContentItem = (contentItem) => {
    const {
      authState: { accessToken },
      deleteContentItem,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props
    if (!window.confirm('Are you sure?')) {
      return
    }
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    const allContentItemIds = map(contentItems, 'id')
    deleteContentItem({
      id: contentItem.id,
      accessToken,
      courseId,
      allContentItemIds,
    })
  }

  moveGroup = () => {
    const {
      authState: { accessToken },
      moveContentItems,
      getContentItemsState,
      match: { params: { courseId } },
    } = this.props
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    let contentItemIds = map(contentItems, 'id')

    let beginningOrdinal = window.prompt('Enter the beginning ordinal')
    if (!beginningOrdinal) {
      window.alert('Cancelling')
      return false
    }
    beginningOrdinal = beginningOrdinal * 1
    if (beginningOrdinal < 0 || beginningOrdinal >= contentItemIds.length) {
      window.alert(`Cancelling: beginning ordinal must be between 0 and ${contentItemIds.length - 1}`)
      return false
    }

    let endingOrdinal = window.prompt('Enter the ending ordinal')
    if (!endingOrdinal) {
      window.alert('Cancelling')
      return false
    }
    endingOrdinal = endingOrdinal * 1
    if (endingOrdinal < 0 || endingOrdinal >= contentItemIds.length) {
      window.alert(`Cancelling: ending ordinal must be between 0 and ${contentItemIds.length - 1}`)
      return false
    }
    if (endingOrdinal < beginningOrdinal) {
      window.alert(`Cancelling: ending ordinal must be after the beginning ordinal (${beginningOrdinal})`)
      return false
    }

    let toOrdinal = window.prompt('Enter the current ordinal you wish to place the selected group after. (put -1 to move the group to the beginning)')
    if (!toOrdinal) {
      window.alert('Cancelling')
      return false
    }
    toOrdinal = toOrdinal * 1
    if (toOrdinal < -1 || toOrdinal >= contentItemIds.length) {
      window.alert(`Cancelling: ordinal must be between -1 and ${contentItemIds.length - 1}`)
      return false
    }
    if (toOrdinal >= beginningOrdinal && toOrdinal <= endingOrdinal) {
      window.alert(`Cancelling: ordinal must not be within the group range that you selected (${beginningOrdinal} and ${endingOrdinal})`)
      return false
    }
    const toOrdinalId = (toOrdinal >= 0) ? contentItemIds[toOrdinal] : null
    const selectedContentItemIds = contentItemIds.splice(beginningOrdinal, endingOrdinal - beginningOrdinal + 1)
    const newToOrdinal = (toOrdinalId) ? contentItemIds.indexOf(toOrdinalId) : -1
    contentItemIds.splice(newToOrdinal + 1, 0, selectedContentItemIds)
    contentItemIds = flatten(contentItemIds)

    moveContentItems({
      accessToken,
      courseId,
      contentItemIds,
    })
  }

  downloadCourseData = () => {
    const {
      getContentItemsState,
      getCoursesState,
      match: { params: { courseId } },
    } = this.props
    const course = get(getCoursesState, `value.${courseId}`, {})
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    const date = new Date().toISOString().split('T')[0]
    const title = course.titles.toLowerCase().replace(/\s/g, '-')
    exportToCsv(`BASE-course-content-${date}-${title}.csv`, contentItems, [ 'id', 'ordinal', 'titles', 'bodies', 'type' ])
  }

  dismissMessage = () => {
    this.props.acknowledgeCreateContentItem()
    this.props.acknowledgeUpdateContentItem()
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getContentItemsState,
      getCourseImagesState,
      getAllContentItems,
      getCourseImages,
      match: { params: { courseId } },
    } = this.props

    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getCourseImagesState)) {
      getCourseImages({ accessToken })
    }
    window.addEventListener('scroll', removeScrollMemory)
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      authState: { accessToken },
      getContentItemsState,
      getCourseImagesState,
      getAllContentItems,
      getCourseImages,
      postContentItemsState,
      putContentItemsState,
      deleteContentItemsState,
      match: { params: { courseId } },
    } = this.props
    // if we are in the middle of creating, updating, deleting, or editing items, don't check if data is stale or scroll the view
    if (postContentItemsState.isLoading ||
        deleteContentItemsState.isLoading ||
        putContentItemsState.isLoading ||
        this.state.isEditing ||
        (!this.state.isEditing && prevState.isEditing)) {
      return
    }
    if (this.state.showUploadCisPopup && !postContentItemsState.isLoading && prevProps.postContentItemsState.isLoading) {
      setTimeout(() => this.setState({ ...this.state, showUploadCisPopup: false }), 100)
    }
    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getCourseImagesState)) {
      getCourseImages({ accessToken })
    }
    const ciId = window.sessionStorage.getItem(SCROLL_POSITION_MEMORY_KEY)
    if (ciId) {
      const item = document.querySelector(`#ci-${ciId}`)
      if (item) {
        item.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'start',
        })
      }
      if (!getContentItemsState.isLoading && !getContentItemsState.value[ciId]) {
        removeScrollMemory()
        window.sessionStorage.removeItem(`${courseId}-${PAGE_NUMBER_MEMORY_KEY}`)
      }
    }
  }

  componentWillUnmount () {
    setTimeout(this.props.acknowledgeCreateContentItem.bind(this), 100)
    setTimeout(this.props.acknowledgeUpdateContentItem.bind(this), 100)
    this.props.invalidateContentItems()
    window.removeEventListener('scroll', removeScrollMemory)
  }

  render () {
    const {
      t,
      authState: { accessToken },
      updateCourse,
      uploadCourseGuide,
      deleteCourseGuide,
      acknowledgeUpdateCourse,
      acknowledgeCreateContentItem,
      acknowledgeUpdateContentItem,
      putCoursesState,
      getContentItemsState,
      putContentItemsState,
      postContentItemsState,
      deleteContentItemsState,
      getCourseImagesState,
      putCourseImagesState,
      getCoursesState,
      onEditItemClick,
      match: { params: { courseId } },
      showFriendlyApiErrorMessages,
      uploadCourseImages,
      acknowledgeUploadCourseImages,
    } = this.props
    if (getCoursesState.error || getContentItemsState.error) {
      return (
        <FetchResultMessage
          success={false}
          error={getCoursesState.error || getContentItemsState.error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      )
    }
    const isSaving = putContentItemsState.isLoading || deleteContentItemsState.isLoading || postContentItemsState.isLoading
    const isLoading = getCoursesState.isLoading || getContentItemsState.isLoading
    if (isLoading || putCourseImagesState.isLoading) {
      return <FullScreenLoadingOverlay isActive={true}/>
    }
    const course = get(getCoursesState, `value.${courseId}`, {})
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    const images = (getCourseImagesState.value) ? get(getCourseImagesState, 'value', []) : []
    const imageNames = {}
    images.forEach((img) => {
      imageNames[img] = true
    })
    const savedPageNumber = window.sessionStorage.getItem(`${courseId}-${PAGE_NUMBER_MEMORY_KEY}`) || 1
    // const error = postEnrollmentsError || deleteEnrollmentsError
    // const wasSuccesful = ((postEnrollmentsSucceeded || deleteEnrollmentsSucceeded) && !error)
    return (
      <Ref innerRef={this.contextRef}>
        <Container className='course-edit'>
          <Modal
            closeIcon
            open={this.state.showUploadCisPopup}
            onClose={() => {
              this.setState({ ...this.state, showUploadCisPopup: false })
            }}
          >
            <UploadCourseCsvForm
              onSave={this.addContentItemsToEmptyCourse}
              isSaving={isSaving}
            />
          </Modal>
          <Modal
            closeIcon
            open={!!putContentItemsState.error}
            onClose={acknowledgeUpdateContentItem}
          >
            <Modal.Header>There was an error</Modal.Header>
            <Modal.Content>
              <Modal.Description>
              Unable to save content item: {get(putContentItemsState, 'error.message') || 'Unknown error'}
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <Sticky context={this.contextRef}>
            <div style={{
              display: 'flex', backgroundColor: 'white', paddingTop: 10, paddingBottom: 10, marginLeft: -2, marginRight: -2, alignItems: 'center',
            }}>
              <Header
                as='h2'
                className='inline text'
                style={{ flexGrow: 1, marginBottom: 0 }}
              >
                {course.titles} {(course.isActive) ? '' : ` (${t('discontinued')})`}
              </Header>
              <Button data-public style={{ flexShrink: 1 }} as='label' icon color='blue' size='tiny' loading={isSaving} htmlFor='image-upload' type='button' title='Upload Images'>
                <Icon name='cloud upload' />
              </Button>
              <Button data-public style={{ flexShrink: 1 }} icon color='blue' size='tiny' loading={isSaving} onClick={this.moveGroup}>
                <Icon name='arrows alternate vertical' /> Move a group of panels
              </Button>
              <Button data-public style={{ flexShrink: 1 }} icon color='blue' size='tiny' loading={isSaving} onClick={this.addContentItem}>
                <Icon name='plus' /> Add Panel To End
              </Button>
              <Button data-public style={{ flexShrink: 1 }} icon color='blue' size='tiny' loading={isSaving} disabled={!!contentItems.length} onClick={() => this.setState({ ...this.state, showUploadCisPopup: true })} title='Upload Panels from CSV'>
                <Icon name='upload'/>
              </Button>
              <Button data-public style={{ flexShrink: 1 }} icon color='blue' size='tiny' loading={isSaving} onClick={this.downloadCourseData}>
                <Icon name='download' />
              </Button>
            </div>
            <Divider fitted />
          </Sticky>
          <input
            id='image-upload'
            hidden
            type='file'
            multiple='multiple'
            accept='image/jpg'
            onChange={(e) => {
              removeScrollMemory()
              uploadCourseImages({ accessToken, files: e.target.files })
            }}
          />
          {(putCoursesState.error || putContentItemsState.error || postContentItemsState.error || putCourseImagesState.error) && (
            <FetchResultMessage
              success={false}
              error={putCoursesState.error || putContentItemsState.error || postContentItemsState.error || putCourseImagesState.error}
              showFriendlyError={showFriendlyApiErrorMessages}
              onDismiss={() => {
                acknowledgeUpdateCourse()
                acknowledgeCreateContentItem()
                acknowledgeUpdateContentItem()
                acknowledgeUploadCourseImages()
              }}
            />
          )}
          <UpdateCourseForm
            course={course}
            isLoading={putCoursesState.isLoading}
            onSave={(data) => updateCourse({ data, accessToken })}
            onUploadGuide={(data) => uploadCourseGuide({
              ...data, courseId, accessToken,
            })}
            onDeleteGuide={(type) => deleteCourseGuide({
              type, courseId, accessToken,
            })}
          />
          <Segment>
            <Pager
              itemsPerPage={200}
              items={contentItems}
              onPageChange={(number) => window.sessionStorage.setItem(`${courseId}-${PAGE_NUMBER_MEMORY_KEY}`, number)}
              activePage={savedPageNumber * 1}
              scrollToTopOnPageChange={false}
              showJumpToPage={true}
              render={(items, startIndex) => {
                return (
                  <Item.Group>
                    {items.map((ci, index) => {
                      const idx = index + startIndex
                      const bgImage = bgImagesBaseUrl + '/' + ci.view.container
                      const imgElement = (!images || !images.length)
                        ? <Item.Image size='small'><Placeholder><Placeholder.Image /></Placeholder></Item.Image>
                        : (imageNames[ci.view.container])
                          ? <Item.Image src={bgImage} size='small'/>
                          : <Item.Image src={bgImage} size='small'><Label content={`Image not found: ${bgImage}`} color='red' size='mini' icon='warning' /></Item.Image>
                      return (
                        <Item id={`ci-${ci.id}`} key={ci.id}>
                          {imgElement}

                          <Item.Content className='content-item-text'>
                            <Item.Header>
                              #{ci.ordinal}
                              {(ci.type !== 'text') && (
                                <Label circular size='large' basic style={{ marginLeft: 5 }} color='blue'>
                                  <Icon
                                    name={ICONS_BY_TYPE[ci.type] || 'lightning'}
                                  />
                                  {TYPE_OPTIONS[ci.type]}
                                </Label>
                              )}
                            </Item.Header>
                            <Item.Description>
                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  flexShrink: 1, fontWeight: 'bold', paddingRight: 5,
                                }}>Title:</div>
                                <EditableTextArea
                                  style={{ flexGrow: 1 }}
                                  name={ci.id + '-titles'}
                                  text={ci.titles.join('\n')}
                                  onChange={(text) => {
                                    const titles = text.split('\n')
                                      .filter((line) => !!line)
                                      .map((line) => ({ en: line }))
                                    this.saveContentItem(ci.id, { titles })
                                    this.setState({ ...this.state, isEditing: false })
                                    window.addEventListener('scroll', removeScrollMemory)
                                  }}
                                  onEditStart={() => {
                                    removeScrollMemory.cancel()
                                    window.removeEventListener('scroll', removeScrollMemory)
                                    window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                    this.setState({ ...this.state, isEditing: true })
                                  }}
                                />
                              </div>
                            </Item.Description>
                            <Item.Description>
                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  flexShrink: 1, fontWeight: 'bold', paddingRight: 5,
                                }}>Body:</div>
                                <EditableTextArea
                                  style={{ flexGrow: 1 }}
                                  name={ci.id + '-bodies'}
                                  text={ci.bodies.join('\n')}
                                  onChange={(text) => {
                                    const bodies = text.split('\n')
                                      .filter((line) => !!line)
                                      .map((line) => ({ en: line }))
                                    this.saveContentItem(ci.id, { bodies })
                                    this.setState({ ...this.state, isEditing: false })
                                    window.addEventListener('scroll', removeScrollMemory)
                                  }}
                                  onEditStart={() => {
                                    removeScrollMemory.cancel()
                                    window.removeEventListener('scroll', removeScrollMemory)
                                    window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                    this.setState({ ...this.state, isEditing: true })
                                  }}
                                />
                              </div>
                            </Item.Description>
                            <Item.Extra>
                              <Button
                                data-public
                                color='black'
                                size='tiny'
                                loading={isSaving}
                                onClick={() => {
                                  // prevents the scroll memory from resetting after this asynchronously
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                  onEditItemClick(courseId, ci.id)
                                }}
                              >
                                <Icon name='edit'/>
                                Edit
                              </Button>
                              <Button
                                data-public
                                primary
                                size='tiny'
                                loading={isSaving} onClick={() => {
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                  this.addContentItem(idx)
                                }}
                              >
                                <Icon name='plus' />
                                Add new panel above
                              </Button>
                              <Button
                                data-public
                                primary
                                size='tiny'
                                loading={isSaving}
                                onClick={() => {
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                  this.moveContentItems(ci, idx - 1)
                                }}
                                disabled={idx === 0}
                              >
                                <Icon name='arrow up' />
                                Move
                              </Button>
                              <Button
                                data-public
                                primary
                                size='tiny'
                                loading={isSaving}
                                onClick={() => {
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                  this.moveContentItems(ci, idx + 1)
                                }}
                                disabled={idx === contentItems.length - 1}
                              >
                                <Icon name='arrow down' />
                                Move
                              </Button>
                              <Button
                                data-public
                                color='teal'
                                size='tiny'
                                loading={isSaving}
                                onClick={() => {
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, ci.id)
                                  this.moveContentItems(ci, null)
                                }}
                              >
                                <Icon name='arrows alternate vertical' />
                                Move anywhere
                              </Button>
                              <Button
                                data-public
                                color='red'
                                size='tiny'
                                loading={isSaving}
                                onClick={() => {
                                  removeScrollMemory.cancel()
                                  window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, (index) ? get(items[index - 1], 'id') : get(items[index + 1], 'id'))
                                  this.removeContentItem(ci)
                                }}
                              >
                                <Icon name='remove' />
                                Delete
                              </Button>
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                      )
                    })}

                  </Item.Group>
                )
              }}
            />
          </Segment>
        </Container>
      </Ref>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    contentItems: {
      [FETCH_ACTIONS.GET_ALL]: getContentItemsState,
      [FETCH_ACTIONS.POST]: postContentItemsState,
      [FETCH_ACTIONS.PUT]: putContentItemsState,
      [FETCH_ACTIONS.DELETE]: deleteContentItemsState,
    },
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
      [FETCH_ACTIONS.PUT]: putCoursesState,
    },
    courseImages: {
      [FETCH_ACTIONS.GET_ALL]: getCourseImagesState,
      [FETCH_ACTIONS.PUT]: putCourseImagesState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getCoursesState,
    putCoursesState,
    getContentItemsState,
    postContentItemsState,
    putContentItemsState,
    deleteContentItemsState,
    getCourseImagesState,
    putCourseImagesState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  updateCourse,
  uploadCourseGuide,
  deleteCourseGuide,
  acknowledgeUpdateCourse,
  getAllContentItems,
  invalidateContentItems,
  updateContentItem,
  createContentItem,
  createContentItemsInEmptyCourse,
  deleteContentItem,
  acknowledgeCreateContentItem,
  acknowledgeUpdateContentItem,
  moveContentItems,
  getCourseImages,
  invalidateCourseImages,
  uploadCourseImages,
  acknowledgeUploadCourseImages,
}
const CourseEditViewContainer = connect(mapStateToProps, mapDispatchToProps)(CourseEditView)

export default translate([ 'components' ])(CourseEditViewContainer)
