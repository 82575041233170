// import querystring from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Tab,
  Menu,
  Label,
  Header,
  Button,
  Icon,
  Popup,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  filter,
  flatten,
  get,
  groupBy,
  sortBy,
  find,
  pick,
  isNumber,
} from 'lodash'
import to from 'await-to-js'
import moment from 'moment'
import pDebounce from 'p-debounce'

import isStale from '../../../../helpers/is-stale'
import {
  FETCH_ACTIONS,
  HTTP_METHODS,
} from '../../../../helpers/fetch-constants'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import AccountView from './account/account'
import ProfileView from './profile/profile'
import EnrollmentsView from './enrollments/enrollments'
import UnreviewedFirewordsView from './unreviewed-firewords/unreviewed-firewords'
import CourseProgressView from './course-progress/course-progress'
import BaselineView from './baseline/baseline'
import StudentRoomsView from './rooms/student-rooms'
import FetchResultMessage from '../../../common/fetch-result-message'
import {
  getClientWithOverview, deleteClient, invalidateClients,
} from '../../../../actions/clients'
import { invalidateStaff, getAllStaff } from '../../../../actions/staff'
import { getClientAnswersWithFirewords, invalidateClientAnswers } from '../../../../actions/answers'
import { getClientEnrollments, invalidateClientEnrollments } from '../../../../actions/enrollments'
import {
  INTERNAL_ROLE_TYPES, ROLE_TYPES, ROLE_VALUES,
} from '../../../../helpers/get-roles'
import {
  findParentContractedCustomerOrSiteOrg, getAllChildrenById, getOrgType,
} from '../../../../helpers/organization'

import fetch from '../../../../helpers/fetch'
import isUnauthorizedError from '../../../../helpers/is-unauthorized-error'
import { logout } from '../../../../actions/authentication'
import printElement from '../../../../helpers/print-element'
import { isSsoUser } from '../../../../helpers/user'
import config from '../../../../config.js'

import CREATE_ROOM from '../../../../helpers/graphql-queries/create-room'
import Mutation from '../../../common/mutation/mutation'
import CreateRoomFormModal from '../../../forms/create-room/create-room-modal'

const clientIdPathParam = ':clientId'
const path = `/users/${clientIdPathParam}`
const archivedUsersPath = `/archivedUsers/${clientIdPathParam}`
const TRANSLATION_PREFIX = 'views.organization.client-details'

const getRelevantAnswer = (ans) => (ans.hasUnreviewedFirePhrases && (isNumber(ans.attemptNumber)))
const getRelevantFirePhraseCapture = (fpc) => (isNumber(fpc.attemptNumber))

export const helpers = {
  // all urls under a given client
  getClientAccountPath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${AccountView.path}`,
  getClientProfilePath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${ProfileView.path}`,
  getClientEnrollmentsPath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${EnrollmentsView.path}`,
  getClientUnreviewedFirewordsPath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${UnreviewedFirewordsView.path}`,
  getClientCourseProgressPath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${CourseProgressView.path}`,
  getClientBaselinePath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}${BaselineView.path}`,
  getClientRoomsPath: (clientId, isArchived) => `${(isArchived ? archivedUsersPath : path).replace(clientIdPathParam, clientId)}/rooms`,
}

export class ClientDetailsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getClientsState: PropTypes.object.isRequired,
    deleteClientsState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getAnswersState: PropTypes.object.isRequired,
    getEnrollmentsState: PropTypes.object.isRequired,
    getClientWithOverview: PropTypes.func.isRequired,
    deleteClient: PropTypes.func.isRequired,
    getClientAnswersWithFirewords: PropTypes.func.isRequired,
    invalidateClientAnswers: PropTypes.func.isRequired,
    getAllStaff: PropTypes.func.isRequired,
    invalidateStaff: PropTypes.func.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    getClientEnrollments: PropTypes.func.isRequired,
    invalidateClientEnrollments: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
    homeOrganization: PropTypes.object.isRequired,
    basePath: PropTypes.string.isRequired,
    showArchived: PropTypes.bool,
    showFriendlyApiErrorMessages: PropTypes.bool,
    t: PropTypes.func.isRequired,
    onReviewProgressClick: PropTypes.func.isRequired,
    onReviewFirewordsClick: PropTypes.func.isRequired,
    getStaffState: PropTypes.object,
    onUserClick: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  static path = path

  static archivedUsersPath = archivedUsersPath

  constructor (props) {
    super(props)
    const { match: { params: { clientId } } } = props
    this.state = {
      isAboutToImpersonate: false,
      helpIsHidden: !!window.localStorage.getItem(`hide-staff-help.base.education.${clientId}`),
      showCreateRoomModal: false,
      searchResults: [],
      isSearching: false,
    }
    this.userDataRef = React.createRef()
  }

  printUserData = () => printElement(this.userDataRef.current)

  renderAcountTab = () => {
    const {
      organization,
      homeOrganization,
      match: { params: { clientId } },
      authState,
    } = this.props

    return (
      <Tab.Pane as='div'>
        <AccountView
          clientId={clientId}
          organization={organization}
          homeOrganization={homeOrganization}
          authState={authState}
        />
      </Tab.Pane>
    )
  }

  renderProfileTab = () => {
    const {
      organization,
      match: { params: { clientId } },
      authState,
    } = this.props

    return (
      <Tab.Pane as='div'>
        <ProfileView
          clientId={clientId}
          authState={authState}
          organization={organization}
        />
      </Tab.Pane>
    )
  }

  renderEnrollmentsTab = () => {
    const {
      match: { params: { clientId } },
      showArchived,
      authState,
    } = this.props

    return (
      <Tab.Pane as='div'>
        <EnrollmentsView clientId={clientId} disabled={showArchived} authState={authState}/>
      </Tab.Pane>
    )
  }

  renderUnreviewedFirewordsTab = (unreviewedAnswers, allFirePhraseCaptures) => {
    const {
      match: { params: { clientId } },
      authState,
    } = this.props
    return (
      <Tab.Pane as='div'>
        <UnreviewedFirewordsView
          clientId={clientId}
          unreviewedAnswers={unreviewedAnswers}
          allFirePhraseCaptures={allFirePhraseCaptures}
          authState={authState}
        />
      </Tab.Pane>
    )
  }

  renderCourseProgressTab = (answersByQuestionId, firePhrases) => {
    const {
      match: { params: { clientId } },
      authState,
      organization,
      getClientsState,
      getCoursesState,
      getEnrollmentsState,
    } = this.props
    const client = (!getClientsState.value || !getClientsState.value[clientId]) ? {} : getClientsState.value[clientId]
    const name = (client.profile && client.profile.firstName && client.profile.lastName)
      ? `${client.profile.firstName} ${client.profile.lastName}`
      : (client.profile && client.profile.firstName && !client.profile.lastName)
        ? `${client.profile.firstName}`
        : ''
    const isSSO = isSsoUser(client.userName)
    const userName = (isSSO) ? name : name || client.userName || clientId
    const enrollmentsById = get(getEnrollmentsState, 'value', {}) || {}
    const enrollmentsByCourseId = groupBy(Object.values(enrollmentsById), 'courseId')

    return (
      <Tab.Pane as='div'>
        <CourseProgressView
          clientId={clientId}
          answersByQuestionId={answersByQuestionId}
          firePhrases={firePhrases}
          authState={authState}
          organization={organization}
        />
        <div ref={this.userDataRef} className='print-only'>
          <Header as='h2'>BASE User Data for: {userName}</Header>
          <div>First Name: {get(client, 'profile.firstName', '')}</div>
          <div>Last Name: {get(client, 'profile.lastName', '')}</div>
          <div>School: {get(client, 'profile.school', '')}</div>
          <div>Age: {get(client, 'profile.age', '')}</div>
          <div>Education Level: {get(client, 'profile.educationLevel', '')}</div>
          <div>Gender: {get(client, 'profile.gender', '')}</div>
          <div>Ethnicity: {(get(client, 'profile.ethnicity', []) || []).map((e) => e.key).join(', ')}</div>
          <div>Total time spent in BASE: {Math.round(get(client, 'totalUsageSeconds', 0) / 60)} minutes</div>
          <Header as='h2'>Module Data:</Header>
          {Object.keys(enrollmentsByCourseId).map((courseId) => {
            const attempts = enrollmentsByCourseId[courseId]
            const course = get(getCoursesState, `value[${courseId}]`, {}) || {}
            const courseCIQuestions = sortBy(filter(course.contentItems, 'questionId'), 'ordinal')
            return (
              <div key={`${courseId}-${client.id}-print-data`} style={{ marginBottom: 15 }}>
                <Header as='h4'>Course Title: {get(course, 'titles', 'Unknown Course')}</Header>
                {attempts.map((a) => {
                  if (!a.startedOn) {
                    return null
                  }
                  const usage = get(client, `enrollmentsUsage[${courseId}]`, []) || []
                  const matchingUsage = usage.find((u) => u.attemptNumber === a.attemptNumber) || { seconds: 0 }
                  const accessedOnDates = a.contentItemProgresses.reduce((accum, prog) => {
                    const dateStr = moment(prog.startedOn).format('YYYY-MM-DD')
                    if (!accum.includes(dateStr)) {
                      return [ ...accum, dateStr ]
                    }
                    return [ ...accum ]
                  }, [])
                  const highestCompletedOrdinal = a.contentItemProgresses.reduce((accum, prog) => {
                    if (prog.completedOn && accum < prog.ordinal) {
                      return prog.ordinal
                    }
                    return accum
                  }, 0)
                  const progress = Math.round(((highestCompletedOrdinal + 1) * 100) / (get(course, 'contentItems.length', 1) || 1))
                  return (
                    <div key={`${courseId}-${client.id}-${a.attemptNumber}-print-data`} style={{ borderBottom: '1px solid black' }}>
                      <div>Time Spent: {Math.round(matchingUsage.seconds / 60)} minutes</div>
                      <div>Started On: {moment(a.startedOn).format('YYYY-MM-DD')}</div>
                      <div>Accessed On: {sortBy(accessedOnDates).join(', ')}</div>
                      <div>Completed On: {a.completedOn && moment(a.completedOn).format('YYYY-MM-DD')}</div>
                      <div>Completion Percentage: {(a.completedOn) ? '100' : progress}%</div>
                      <div className='text bold'>Answers:</div>
                      {courseCIQuestions.map((ci) => {
                        const answer = find((get(answersByQuestionId, ci.questionId, []) || []), { attemptNumber: a.attemptNumber })
                        const question = course.questions.find((q) => q.id === ci.questionId)
                        if (!answer || !question) {
                          return null
                        }
                        return (
                          <div key={`${courseId}-${client.id}-${a.attemptNumber}-${question.id}-print-data`} style={{ marginBottom: 5 }}>
                            <div><span className='text bold'>Question:</span> {question.title}</div>
                            <div><span className='text bold'>Answer:</span> {answer.answerText}</div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </Tab.Pane>
    )
  }

  renderBaselineTab = () => {
    const {
      match: { params: { clientId } },
      authState,
      organization,
      homeOrganization,
    } = this.props
    return (
      <Tab.Pane as='div'>
        <BaselineView
          clientId={clientId}
          authState={authState}
          organization={organization}
          homeOrganization={homeOrganization}
        />
      </Tab.Pane>
    )
  }

  renderRoomsTab = () => {
    const {
      match: { params: { clientId } },
      organization,
      authState,
    } = this.props
    return (
      <Tab.Pane as='div'>
        <StudentRoomsView
          clientId={clientId}
          orgId={organization.id}
          authState={authState}
        />
      </Tab.Pane>
    )
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  handleTabChange = (event, data) => this.navigateTo(this.props.basePath + data.panes[data.activeIndex].key)

  deleteUser = () => {
    const {
      deleteClient,
      authState: { accessToken },
      t,
      match: { params: { clientId } },
    } = this.props
    const areYouSure = window.confirm(t(`${TRANSLATION_PREFIX}.delete_confirm`))
    if (!areYouSure) {
      return
    }
    deleteClient({ accessToken, id: clientId })
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getAnswersState,
      getEnrollmentsState,
      getClientWithOverview,
      getClientAnswersWithFirewords,
      getClientEnrollments,
      organizationId,
      homeOrganization,
      organization,
      getAllStaff,
      match: { params: { clientId } },
    } = this.props

    getClientWithOverview({
      accessToken,
      id: clientId,
    })
    // NOTE: the organizationId updates first without the new org data, so we need to wait until we get the new org before loading staff
    if (organizationId === organization.id) {
      const org = findParentContractedCustomerOrSiteOrg(homeOrganization, organization)
      getAllStaff({
        accessToken, organizationId: (org) ? org.id : organization.id, allOrgs: (!!org),
      })
    }
    if (isStale(getAnswersState)) {
      getClientAnswersWithFirewords({ accessToken, clientId })
    }
    if (isStale(getEnrollmentsState)) {
      getClientEnrollments({ accessToken, clientId })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      getAnswersState,
      getEnrollmentsState,
      deleteClientsState,
      getClientAnswersWithFirewords,
      getClientEnrollments,
      organizationId,
      invalidateClients,
      invalidateClientAnswers,
      invalidateClientEnrollments,
      getStaffState,
      getAllStaff,
      homeOrganization,
      organization,
      match: { params: { clientId } },
      basePath,
    } = this.props

    if (deleteClientsState.succeeded && !prevProps.deleteClientsState.succeeded) {
      invalidateClients()
      this.navigateTo(basePath + '/users')
      return
    }
    // NOTE: the organizationId updates first without the new org data, so we need to wait until we get the new org before loading staff
    if (organizationId === organization.id) {
      if (isStale(getStaffState) || prevProps.organization.id !== organization.id) {
        const org = findParentContractedCustomerOrSiteOrg(homeOrganization, organization)
        getAllStaff({
          accessToken, organizationId: (org) ? org.id : organization.id, allOrgs: (!!org),
        })
      }
    }
    if (prevProps.match.params.clientId !== clientId) {
      invalidateClients()
      invalidateClientAnswers()
      invalidateClientEnrollments()
    }
    if (isStale(getAnswersState)) {
      getClientAnswersWithFirewords({ accessToken, clientId })
    }
    if (isStale(getEnrollmentsState)) {
      getClientEnrollments({ accessToken, clientId })
    }
  }

  componentWillUnmount () {
    setTimeout(this.props.invalidateClientAnswers.bind(this), 100)
    setTimeout(this.props.invalidateClientEnrollments.bind(this), 100)
    setTimeout(this.props.invalidateStaff.bind(this), 100)
    setTimeout(this.props.invalidateClients.bind(this), 100)
  }

  render () {
    const {
      t,
      location,
      showArchived,
      getClientsState,
      getCoursesState,
      getAnswersState,
      getEnrollmentsState,
      deleteClientsState,
      showFriendlyApiErrorMessages,
      authState: {
        userId, roleType, accessToken,
      },
      match: { params: { clientId } },
      organizationId,
      organization,
      logout,
      homeOrganization,
    } = this.props

    const {
      showCreateRoomModal,
      searchResults,
      isSearching,
      isAboutToImpersonate,
      helpIsHidden,
    } = this.state
    // since we know we have to reload the data if it's stale, don't bother rendering anything under this condition
    if (organizationId !== organization.id || isStale(getCoursesState) || isStale(getAnswersState) || isStale(getEnrollmentsState)) {
      return null
    }

    if (getClientsState.error || getCoursesState.error || getAnswersState.error || getEnrollmentsState.error) {
      return (
        <Container>
          <FetchResultMessage
            success={false}
            error={getClientsState.error || getCoursesState.error || getAnswersState.error || getEnrollmentsState.error}
            showFriendlyError={showFriendlyApiErrorMessages}/>
        </Container>
      )
    }
    const onSearchChange = async (search, skip, limit, excludedIds) => {
      this.setState({ ...this.state, isSearching: true })
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/studentSearch`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          search,
          orgId: organization.id,
          excludedIds,
          skip,
          limit: limit,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
      }
      this.setState({
        ...this.state,
        isSearching: false,
        searchResults: (err) ? [] : result.map((res) => ({ ...res, className: (res.belongsToARoom) ? 'has-room' : '' })),
      })
    }
    const validateUserName = async (userName) => {
      if (!userName) {
        return {
          message: 'forms.error_required',
        }
      }
      if (userName.length < 7) {
        return {
          message: 'forms.error_min_length',
          count: 7,
        }
      }
      if (userName.length > 1000) {
        return {
          message: 'forms.error_max_length',
          count: 1000,
        }
      }
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/userNameAvailability`
      const method = HTTP_METHODS.POST
      const [ err, result ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error searching for students', err)
        return {
          message: err.message,
        }
      }
      if (result.isAvalable) {
        return
      }
      return {
        message: (!!result.suggestions && !!result.suggestions.length) ? 'forms.error_not_available_with_suggestions' : 'forms.error_not_available',
        field: 'user name',
        suggestions: (!!result.suggestions && !!result.suggestions.length) ? result.suggestions.join(', ') : '',
      }
    }
    const onValidateUserName = pDebounce(validateUserName, 300)

    const onCreateStudent = async (student) => {
      const headers = { authorization: `Bearer ${accessToken}` }
      const url = `/clients`
      const method = HTTP_METHODS.POST
      const [ err, id ] = await to(fetch(url, {
        method,
        headers,
        data: {
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: organization.id,
          isArchived: false,
          ssoOverride: false,
          secret: student.password,
          graduationYear: student.graduationYear,
        },
      }))
      isUnauthorizedError(err) && logout({ userInitiated: false })
      if (err) {
        console.error('Error creating student', err)
        throw err
      }

      const profileUrl = `/clients/${id}/profiles`
      const [ profileErr ] = await to(fetch(profileUrl, {
        method,
        headers,
        data: {
          firstName: student.firstName,
          lastName: student.lastName,
        },
      }))
      isUnauthorizedError(profileErr) && logout({ userInitiated: false })
      if (profileErr) {
        console.error('Error creating student profile', profileErr)
        throw profileErr
      }

      this.setState({
        ...this.state,
        searchResults: [ {
          id,
          userName: student.userName,
          email: student.email,
          roleType: 'student',
          type: 'user',
          orgId: organization.id,
          isArchived: false,
          ssoOverride: false,
          profile: {
            firstName: student.firstName,
            lastName: student.lastName,
          },
          className: 'new-student',
        } ],
      })
    }

    const oldCoursesById = get(getCoursesState, 'value', {}) || {}
    const coursesById = {
      ...oldCoursesById,
      // ...keyBy(newCourses, 'id'),
    }
    const orgChildrenById = getAllChildrenById(organization.children)
    const orgNamesById = Object.keys(orgChildrenById).reduce((accum, id) => {
      return {
        ...accum,
        [id]: orgChildrenById[id].name,
      }
    }, { [organization.id]: organization.name })
    const activeTab = location.pathname.split('/').pop()
    const isLoading = getClientsState.isLoading || getCoursesState.isLoading || getAnswersState.isLoading || getEnrollmentsState.isLoading
    const answersByQuestionId = getAnswersState.value
    const unreviewedAnswers = filter(flatten(Object.values(answersByQuestionId || {})), getRelevantAnswer)
    const client = (!getClientsState.value || !getClientsState.value[clientId]) ? {} : getClientsState.value[clientId]

    const allRelevantSeverities = (client && client.unreviewedFirePhraseCaptures && client.unreviewedFirePhraseCaptures.map((fp) => {
      return fp.ignisSeverity || fp.receptivitiSeverity || 3
    })) || [ 3 ]
    const maxRelevantSeverities = allRelevantSeverities.map((scores) => Math.max(flatten(scores)) || 3)
    // const redAnswers = unreviewedAnswers.filter((_, idx) => maxReceptivitiSeverities[idx] === 3)
    // const orangeAnswers = unreviewedAnswers.filter((_, idx) => maxReceptivitiSeverities[idx] === 2)
    // const yellowAnswers = unreviewedAnswers.filter((_, idx) => maxReceptivitiSeverities[idx] === 1)
    // const greenAnswers = unreviewedAnswers.filter((_, idx) => maxReceptivitiSeverities[idx] === 0)
    // const highestPriorityAnswers = (redAnswers.length > 0 && redAnswers) || (orangeAnswers.length > 0 && orangeAnswers) || (yellowAnswers.length > 0 && yellowAnswers) || greenAnswers
    const severityColors = [ 'green', 'yellow', 'orange', 'red' ]
    const badgeColor = severityColors[Math.max(...maxRelevantSeverities)]
    const name = (client.profile && client.profile.firstName && client.profile.lastName)
      ? `${client.profile.firstName} ${client.profile.lastName}`
      : (client.profile && client.profile.firstName && !client.profile.lastName)
        ? `${client.profile.firstName}`
        : ''
    const isSelf = (clientId === userId)
    const isSSO = isSsoUser(client.userName)
    const userName = (isSSO) ? name : name || client.userName || clientId
    const canImpersonateUser = (ROLE_VALUES[roleType] > ROLE_VALUES[client.roleType] && [ INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER, INTERNAL_ROLE_TYPES.INTERNAL_ADMIN, INTERNAL_ROLE_TYPES.SUPER_ADMIN ].includes(roleType))
    // const hasOldEnrollments = Object.values(enrollmentsById).some((enrollment) => typeof (enrollment.attemptNumber) === 'number')

    const isStaffRole = ![ ROLE_TYPES.STUDENT, ROLE_TYPES.ADULT ].includes(client.roleType)
    // no rooms, no firewords: account, profile, enrollments, progress
    // no rooms, firewords: account, profile, enrollments, firewords, progress
    const isCustomer = !!findParentContractedCustomerOrSiteOrg(homeOrganization, organization) || (getOrgType(organization) && organization.children && organization.children.length === 0)

    const defaultActiveIndexByTabName = {
      account: 0,
      profile: 1,
      enrollments: 2,
      'unreviewed-firewords': (!organization.firewordsEnabled) ? -1 : 3,
      'course-progress': (!organization.firewordsEnabled) ? 3 : 4,
      rooms: (!isStaffRole && organization.roomsEnabled && isCustomer) ? (!organization.firewordsEnabled ? 4 : 5) : -1,
    }
    // no rooms, no firewords: account, profile, progress
    // no rooms, firewords: account, profile, firewords, progress
    const isInternalRole = Object.values(INTERNAL_ROLE_TYPES).includes(roleType)
    const canViewBaseline = (!isStaffRole && organization.assessmentsEnabled && config.baseline.enabledRoleTypes.includes(roleType))
    const canViewFirewords = (organization.firewordsEnabled)
    const canViewRooms = ((config.featureFlags.roomsTabEnabled || isInternalRole) && !isStaffRole && organization.roomsEnabled && isCustomer)
    // rooms, no firewords: account, profile, progress
    // rooms, firewords: account, profile, firewords, progress
    const roomsActiveIndexByTabName = {
      account: 0,
      profile: 1,
      enrollments: -1,
      'unreviewed-firewords': canViewFirewords ? 2 : -1,
      'course-progress': 2 + !!canViewFirewords,
      'baseline-summary': canViewBaseline ? 3 + !!canViewFirewords : -1,
      rooms: canViewRooms ? 3 + !!canViewBaseline + !!canViewFirewords : -1,
    }
    const activeIndexByTabName = (organization.roomsEnabled) ? roomsActiveIndexByTabName : defaultActiveIndexByTabName
    const nonPrivateUnreviewedFirePhraseCaptures = (client.unreviewedFirePhraseCaptures || []).filter(getRelevantFirePhraseCapture)
    const panes = [
      {
        menuItem: t(`${TRANSLATION_PREFIX}.account_label`),
        render: this.renderAcountTab,
        key: helpers.getClientAccountPath(clientId, showArchived),
      },
      {
        menuItem: t(`${TRANSLATION_PREFIX}.profile_label`),
        render: this.renderProfileTab,
        key: helpers.getClientProfilePath(clientId, showArchived),
      },
      {
        menuItem: t(`${TRANSLATION_PREFIX}.enrollments_label`),
        render: this.renderEnrollmentsTab,
        key: helpers.getClientEnrollmentsPath(clientId, showArchived),
      },
      {
        menuItem: (
          <Menu.Item key='messages'>
            {t(`${TRANSLATION_PREFIX}.unreviewed_firewords_label`)}
            {(nonPrivateUnreviewedFirePhraseCaptures.length > 0) && <Label data-public color={badgeColor} circular floating>{nonPrivateUnreviewedFirePhraseCaptures.length}</Label>}
          </Menu.Item>
        ),
        render: this.renderUnreviewedFirewordsTab.bind(this, unreviewedAnswers, nonPrivateUnreviewedFirePhraseCaptures),
        key: helpers.getClientUnreviewedFirewordsPath(clientId, showArchived),
      },
      {
        menuItem: t(`${TRANSLATION_PREFIX}.course_progress_label`),
        render: this.renderCourseProgressTab.bind(this, answersByQuestionId, nonPrivateUnreviewedFirePhraseCaptures),
        key: helpers.getClientCourseProgressPath(clientId, showArchived),
      },
      {
        menuItem: t(`${TRANSLATION_PREFIX}.baseline_label`),
        render: this.renderBaselineTab,
        key: helpers.getClientBaselinePath(clientId, showArchived),
      },
      {
        menuItem: 'Rooms',
        render: this.renderRoomsTab,
        key: helpers.getClientRoomsPath(clientId, showArchived),
      },
    ].filter((pane) => {
      return activeIndexByTabName[pane.key.split('/').pop()] >= 0
    })
    return (
      <div>
        <div>

          <FullScreenLoadingOverlay isActive={isLoading || isAboutToImpersonate || !answersByQuestionId}/>
          {/* show error if delete fails */}
          {(isSelf) && (
            <div style={{
              display: 'flex', marginTop: 10, marginBottom: 10, alignItems: 'center',
            }}>
              <Header as='h2' style={{ flexGrow: 2, margin: 0 }} data-public>My Account</Header>
              {(helpIsHidden) && (
                <Button
                  size='tiny'
                  color='grey'
                  onClick={() => {
                    window.localStorage.removeItem(`hide-staff-help.base.education.${userId}`)
                    this.setState({ ...this.state, helpIsHidden: false })
                  }}
                >
                  Show Help on Login
                </Button>
              )}
            </div>
          )}
          {(!isSelf) && (
            <div style={{
              marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center',
            }}>
              <Header className='no-print' as='h2' style={{ margin: 0, flexGrow: 2 }}>{t('resource_types.client', { postProcess: 'titleCase' })}: {userName}</Header>

              <Popup
                hoverable
                content='Print User Data'
                position='bottom center'
                mouseEnterDelay={300}
                trigger={(
                  <Button
                    className='no-print'
                    data-public
                    icon
                    onClick={this.printUserData}
                    loading={deleteClientsState.isLoading}
                  >
                    <Icon name='print'/>
                  </Button>
                )}
              />
              {(canImpersonateUser) && (
                <Button
                  className='no-print'
                  data-public
                  color='black'
                  icon
                  onClick={async () => {
                    if (!window.confirm(`This will log you out of your session and log you into BASE as ${name || client.userName}. Are you sure you want to do this?`)) {
                      window.alert('Cancelling')
                      return
                    }
                    this.setState({ ...this.state, isAboutToImpersonate: true })
                    const headers = { authorization: `Bearer ${accessToken}` }
                    const method = HTTP_METHODS.GET
                    const [ err, data ] = await to(fetch(`/clients/${clientId}/token`, {
                      method,
                      headers,
                    }))
                    isUnauthorizedError(err) && logout({ userInitiated: false })
                    if (err) {
                      this.setState({ ...this.state, isAboutToImpersonate: false })
                      console.log('Error getting token for another user', err)
                      window.alert('There was a problem with impersonating this user. Please try again later.')
                      return
                    }
                    window.location.href = `${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education?auth=${window.btoa(JSON.stringify(data))}`
                  }}
                >
                  <Icon name='user secret'/> Impersonate User
                </Button>

              )}
              {(organization.roomsEnabled && [ ROLE_TYPES.ADMIN, ROLE_TYPES.ADVISOR, ROLE_TYPES.SUPERVISOR ].includes(client.roleType) && [ ROLE_TYPES.ADMIN, INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER, INTERNAL_ROLE_TYPES.INTERNAL_ADMIN, INTERNAL_ROLE_TYPES.SUPER_ADMIN ].includes(roleType)) && (
                <>
                  <Button
                    data-public
                    className='base-teal-bg text white'
                    icon
                    disabled={deleteClientsState.isLoading}
                    loading={deleteClientsState.isLoading}
                    onClick={() => this.setState({ ...this.state, showCreateRoomModal: true })}
                  >
                    <Icon name='fas fa-door-open'/> Create Room for User
                  </Button>
                  <Mutation
                    mutation={CREATE_ROOM}
                    awaitRefetchQueries={true}
                    onCompleted={({ createRoom }) => {
                      this.setState({
                        ...this.state, showCreateRoomModal: false, isSearching: false, searchResults: [],
                      })
                    }}
                  >
                    {(createRoom, { loading: isSaving }) => {
                      return (
                        <CreateRoomFormModal
                          open={!!showCreateRoomModal}
                          onClose={() => this.setState({
                            ...this.state, showCreateRoomModal: false,
                          })}
                          orgNamesById={orgNamesById}
                          courses={Object.values(coursesById).filter((course) => organization.courseIds.includes(course.id))}
                          searchResults={searchResults}
                          isSearching={isSearching}
                          isSaving={isSaving}
                          showTour={false}
                          forceFirewords={organization.requireRoomFirewords}
                          enablePrivateRooms={organization.privateRoomsEnabled}
                          canCreateStudents={roleType !== 'advisor'}
                          onSave={(roomData) => {
                            const room = {
                              ...pick(roomData, [
                                'firewordNotificationsEnabled',
                                'firewordNotificationsForRoomCoursesOnly',
                                'isOpenForRequests',
                                'isPrivate',
                                'imageUrl',
                                'name',
                                'studentIds',
                                'courseIds',
                              ]),
                              ownerUserId: clientId,
                              orgId: organization.id,
                            }
                            createRoom({ variables: { room } })
                          }}
                          onSearchChange={onSearchChange}
                          onCreateStudent={onCreateStudent}
                          validateUserName={onValidateUserName}
                        />
                      )
                    }}
                  </Mutation>
                </>
              )}
              <Button
                className='no-print'
                data-public
                color='red'
                icon
                disabled={(roleType === ROLE_TYPES.ADVISOR || isSSO || deleteClientsState.isLoading)}
                onClick={this.deleteUser}
                loading={deleteClientsState.isLoading}
              >
                <Icon name='remove'/> {t('delete', { postProcess: 'titleCase' })} {t('resource_types.client', { postProcess: 'titleCase' })}
              </Button>
              <br style={{ clear: 'both' }}/>
            </div>
          )}

          {answersByQuestionId && (
            <Tab
              renderActiveOnly={true}
              onTabChange={this.handleTabChange}
              menu={{ pointing: true }}
              activeIndex={activeIndexByTabName[activeTab]}
              panes={panes}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    clients: { [FETCH_ACTIONS.GET_ALL]: getClientsState, [FETCH_ACTIONS.DELETE]: deleteClientsState },
    staff: { [FETCH_ACTIONS.GET_ALL]: getStaffState },
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    answers: { [FETCH_ACTIONS.GET_ALL]: getAnswersState },
    enrollments: { [FETCH_ACTIONS.GET_ALL]: getEnrollmentsState },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getClientsState,
    deleteClientsState,
    getCoursesState,
    getAnswersState,
    getEnrollmentsState,
    getStaffState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  getClientWithOverview,
  getClientAnswersWithFirewords,
  deleteClient,
  invalidateClientAnswers,
  getClientEnrollments,
  invalidateClientEnrollments,
  invalidateClients,
  invalidateStaff,
  getAllStaff,
  logout,
}
const ClientDetailsViewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientDetailsView)

export default translate([ 'components' ])(ClientDetailsViewContainer)
