import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { Segment } from 'semantic-ui-react'

import RoomItem from '../../common/room-item'
import '../../common.css'

// const TRANSLATION_PREFIX = 'forms.create-room'

export class CreateRoomPage4 extends Component {
  static propTypes = {
    roomData: PropTypes.object,
    t: PropTypes.func,
    isSaving: PropTypes.bool,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  render = () => {
    const {
      roomData,
    } = this.props
    return (
      <Segment id='create-room-preview' style={{
        width: '50%', margin: 'auto', textAlign: 'center',
      }}>
        <RoomItem
          room={roomData}
        />
      </Segment>
    )
  }
}

export default translate([ 'components' ])(CreateRoomPage4)
