import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Message, Icon } from 'semantic-ui-react/dist/commonjs'
import { translate } from 'react-i18next'

import './not-found.css'

export class LoginView extends React.Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static path = '/notfound'

  componentDidMount () {
    setTimeout(() => {
      this.props.history.push('/')
    }, 5000)
  }

  render () {
    const {
      t,
    } = this.props

    return (
      <div className='not-found-page'>
        <Message icon>
          <Icon name='circle notched' loading />
          <Message.Content>
            <Message.Header>
              {t('views.not-found.header')}
            </Message.Header>
            <p>{t('views.not-found.message')}</p>
          </Message.Content>
        </Message>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    authentication,
  } = state
  return {
    authentication,
  }
}
const LoginViewContainer = connect(mapStateToProps)(LoginView)

export default translate([ 'components' ])(LoginViewContainer)
