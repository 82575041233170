import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Image,
  Progress,
  Dimmer,
  Loader,
  Popup,
  Label,
  Icon,
  Header,
  List,
  Dropdown,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { sortBy } from 'lodash'

import './course-card.css'

const EDUCATOR_GUIDE_DISPLAY_ORDER = {
  'Educator Guide (Grades 1-2)': 0,
  'Educator Guide (Grades 3-5)': 1,
  'Educator Guide (Middle School)': 2,
  'Educator Guide (High School)': 3,
}

const AUDIENCE_TYPES = {
  elementary: 'Elementary',
  secondary: 'Middle/High School',
  middle: 'Middle School',
  high: 'High School',
  higher_ed: 'Higher Ed.',
  parent: 'Parents/Guardians',
  professional: 'Professional Development',
  adult: 'Adult',
}

const CATEGORIES = {
  prevention: 'Prevention',
  anger_fighting: 'Anger/Fighting',
  substance_issues: 'Drug Prevention and Education',
  disruptive_behavior: 'Disruptive Behavior',
  truancy: 'Truancy',
  tardy_chronic_absenteeism: 'Tardy/Chronic Absenteeism',
  inappropriate_peer_behavior: 'Inappropriate Peer Behavior',
  domestic_issues: 'Domestic Issues',
  self_defeating_behaviors: 'Self-Defeating Behaviors',
  stress: 'Stress',
  depression_anxiety: 'Depression and Anxiety',
  bullying_relational_agression_cyber_bullying_technology_issues: 'Bullying/Relational Aggression/Cyberbullying/Technology Issues',
  school_disengagement: 'School Disengagement',
  gang_affiliation: 'Gang Affiliation',
  unhealthy_relationships_exploitation_trafficking: 'Unhealthy Relationships/Exploitation/Trafficking',
  cultural_issues: 'Cultural Issues',
  poor_choices: 'Poor Choices',
  lgbtq: 'LGBTQ',
  suspension_expulsion: 'Suspension/Expulsion',
  getting_to_know_student_iep_transition_questions: 'Getting to Know Student/IEP Transition Questions',
}

const CourseCard = ({
  id,
  title,
  description,
  progress,
  imageUrl,
  videoUrl,
  guideUrl,
  guides,
  audience,
  categories,
  isLoading,
  showProgress,
  isActive,
  canManage,
  canPrint,
  canLaunch,
  onManage,
  onViewResponses,
  onPrint,
  onLaunch,
  colorBar,
  showHiddenOverlay,
  instructionalId,
  interactiveId,
  onToggleVisibility,
  isDeprecated,
  t,
}) => {
  const progressElement = (showProgress && !!imageUrl) ? <Progress style={{ zIndex: 2 }} indicating percent={progress} progress/> : null
  const colorBarElement = (colorBar && !!imageUrl) ? <div style={{
    height: 155, width: 40, backgroundColor: colorBar, position: 'absolute', zIndex: 1, borderRadius: '.28571429rem 0 0 0',
  }} /> : null
  const guideOptions = (!!guides) && sortBy(guides.map((guide) => ({ text: guide.name, value: guide.url })), (guide) => EDUCATOR_GUIDE_DISPLAY_ORDER[guide.text] || 0)
  const middleSchoolVideo = (audience === 'middle') ? videoUrl : null
  const printAndLaunchOptions = [ {
    key: 'interactive',
    icon: { name: 'pencil', className: 'base-teal' },
    text: 'Interactive',
    value: interactiveId,
    description: '(enter responses to questions)',
  }, {
    key: 'instructional',
    icon: { name: 'book', className: 'base-teal' },
    text: 'Instructional',
    value: instructionalId,
    description: '(think or talk about the questions)',
  } ].filter((option) => option.value)
  return (
    <Card id={`course-${id}`} className='course-card' raised as='div'>
      {(!!imageUrl) && <Image src={imageUrl} />}
      {colorBarElement}
      <Dimmer active={isLoading}>
        <Loader size='massive'/>
      </Dimmer>
      <Dimmer style={{ height: 155 }} active={showHiddenOverlay}>
        <Header as='h3' icon inverted>
          <Icon name='eye slash' />
          Hidden from Students
        </Header>
      </Dimmer>
      {progressElement}
      {(!isActive) && (
        <div
          style={{
            width: 30,
            height: 30,
            padding: 8,
            right: 5,
            top: 5,
            position: 'absolute',
            borderRadius: 30,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,.7)',
          }}
        >
          <Popup
            content={(isActive) ? 'Active' : 'Discontinued'}
            position='right center'
            size='mini'
            mouseEnterDelay={300}
            trigger={(
              <Label
                style={{
                  cursor: 'pointer',
                  width: 14,
                  height: 14,
                }}
                empty
                circular
                color={(isActive) ? 'green' : 'red'}
              />
            )}
            hoverable
          />
        </div>
      )}

      <Card.Content>
        <div data-public style={{ display: 'flex' }}>
          <Header className={(isDeprecated) ? 'base-red' : ''} style={{ flexGrow: 2, marginBottom: 0 }}>
            {title.split(' - ').map((t) => (<span key={`${id}-title-${t.replace(/\s+/g, '-')}`}>{t}<br/></span>))}
          </Header>
          <div>
            <Popup
              content={(
                <div>
                  <Header as='h4'><Icon className='base-teal' name='info circle' />About this Module:</Header>
                  {(isDeprecated) && (
                    <>
                      <div className='base-red text bold'>This module has been updated and there are new middle and high school-specific versions available now. Students no longer have access to this module, however read-only access for staff will remain through Dec. 31, 2023.</div>
                      <br/>
                    </>
                  )}
                  <div>{description}</div>
                  {(videoUrl && audience !== 'middle') && (
                    <>
                      <br/>
                      <div>This module is video-based, and users can watch this directly or as a part of a class discussion.</div>
                    </>
                  )}
                  <Header as='h4'><Icon className='base-teal' name='list' />Categories</Header>
                  <List bulleted>
                    {categories.map((category) => (
                      <List.Item
                        key={category}
                        color='blue'
                        size='mini'
                      >
                        {CATEGORIES[category]}
                      </List.Item>
                    ))}
                  </List>
                </div>
              )}
              hoverable
              wide='very'
              on={[ 'hover', 'focus' ]}
              mouseEnterDelay={200}
              trigger={(<Icon tabIndex={0} className={(isDeprecated) ? 'base-red' : 'base-teal'} size='large' style={{ margin: 0, cursor: 'pointer' }} name='info circle' />)}
            />
          </div>
        </div>
        <Card.Meta style={{ marginBottom: 6 }}>
          {AUDIENCE_TYPES[audience]}
        </Card.Meta>
        {(canManage || guideUrl || canPrint || canLaunch || (!!guides && !!guides.length) || !!onViewResponses || !!onToggleVisibility) && (
          <hr/>
        )}
        <div className='card-controls'>
          {(canManage && (!videoUrl || audience === 'middle') && (!interactiveId || !instructionalId)) && (
            <button type='button' onClick={() => onManage(id)} tabIndex={0} className='control'>
              <Icon className='base-teal' name='settings' />
              <div data-public>Manage</div>
            </button>
          )}
          {(canManage && (!videoUrl || audience === 'middle') && (!!interactiveId && !!instructionalId)) && (
            <div
              tabIndex={0}
              className='ui control text black'
              rel='noopener noreferrer'
            >
              <Icon className='base-teal' name='settings' />
              <Dropdown
                data-public
                className='course-menu-dropdown'
                inline
                options={printAndLaunchOptions}
                text='Manage'
                onChange={(e, v) => onManage(v.value)}
                selectOnBlur={false}
                value={null}
              />
            </div>
          )}
          {(!!guideUrl) && (
            <a tabIndex={0} className='ui control text black' href={guideUrl} target='_blank' rel='noopener noreferrer'>
              <Icon className='base-teal' name='file alternate outline' />
              <span data-public>Module Guide</span>
            </a>
          )}
          {(!!guides && guides.length > 0) && (
            <div
              tabIndex={0}
              className='ui control text black'
              rel='noopener noreferrer'
            >
              <Icon className='base-teal' name='file alternate outline' />
              <Dropdown
                data-public
                className='course-menu-dropdown'
                inline
                options={guideOptions}
                text="Educator Resources"
                onChange={(e, v) => window.open(v.value)}
                selectOnBlur={false}
                value={null}
              />
            </div>
          )}
          {(canPrint && (!interactiveId || !instructionalId)) && (
            <button type='button' onClick={() => onPrint(interactiveId || instructionalId)} tabIndex={0} className='control'>
              <Icon className='base-teal' name='print' />
              <div data-public>Print Module</div>
            </button>
          )}
          {(canPrint && (!!interactiveId && !!instructionalId)) && (
            <div
              tabIndex={0}
              className='ui control text black'
              rel='noopener noreferrer'
            >
              <Icon className='base-teal' name='print' />
              <Dropdown
                data-public
                className='course-menu-dropdown'
                inline
                options={printAndLaunchOptions}
                text='Print Module'
                onChange={(e, v) => onPrint(v.value)}
                selectOnBlur={false}
                value={null}
              />
            </div>
          )}

          {(canLaunch && (!!middleSchoolVideo || !!videoUrl)) && (
            <button type='button' onClick={() => onLaunch(id, true)} tabIndex={0} className='control'>
              <Icon className='base-teal' name='video' />
              <div data-public>Launch Video</div>
            </button>
          )}
          {((canLaunch && canPrint) && (!interactiveId || !instructionalId)) && (
            <button type='button' onClick={() => onLaunch(interactiveId || instructionalId, false)} tabIndex={0} className='control'>
              <Icon className='base-teal' name='share square' />
              <div data-public>Launch Module</div>
            </button>
          )}
          {((canLaunch && canPrint) && (!!interactiveId && !!instructionalId)) && (
            <div
              tabIndex={0}
              className='ui control text black'
              rel='noopener noreferrer'
            >
              <Icon className='base-teal' name='share square' />
              <Dropdown
                data-public
                className='course-menu-dropdown'
                inline
                options={printAndLaunchOptions}
                text='Launch Module'
                onChange={(e, v) => onLaunch(v.value)}
                selectOnBlur={false}
                value={null}
              />
            </div>
          )}
          {(!!onViewResponses && !!interactiveId) && (
            <button type='button' onClick={() => onViewResponses(interactiveId)} tabIndex={0} className='control'>
              <Icon className='base-teal' name='write' />
              <div data-public>View Student Responses</div>
            </button>
          )}
          {(!!onToggleVisibility) && (
            <button type='button' onClick={() => onToggleVisibility(id, !showHiddenOverlay)} tabIndex={0} className='control'>
              <Icon className='base-teal' name={(showHiddenOverlay) ? 'eye' : 'eye slash'} />
              <div data-public>{(showHiddenOverlay) ? 'Make Visible to Students' : 'Hide From Students'}</div>
            </button>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

CourseCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  progress: PropTypes.number,
  imageUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  showProgress: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  t: PropTypes.func.isRequired,
  videoUrl: PropTypes.string,
  guideUrl: PropTypes.string,
  guides: PropTypes.array,
  audience: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  canManage: PropTypes.bool,
  canPrint: PropTypes.bool,
  canLaunch: PropTypes.bool,
  onManage: PropTypes.func,
  onPrint: PropTypes.func,
  onLaunch: PropTypes.func,
  colorBar: PropTypes.string,
  onViewResponses: PropTypes.func,
  showHiddenOverlay: PropTypes.bool,
  isDeprecated: PropTypes.bool,
  instructionalId: PropTypes.string,
  interactiveId: PropTypes.string,
  onToggleVisibility: PropTypes.func,
}

CourseCard.defaultProps = {
  progress: 0,
  isLoading: false,
  showProgress: true,
  isActive: true,
  canManage: false,
  canPrint: false,
  canLaunch: false,
  isDeprecated: false,
  onManage: console.log.bind(console, 'onManage'),
  onPrint: console.log.bind(console, 'onPrint'),
  onLaunch: console.log.bind(console, 'onLaunch'),
  categories: [],
  audience: 'secondary',
  guides: [],
}

export default translate([ 'components' ])(CourseCard)
