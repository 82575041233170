import React, { Component } from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Icon,
  Modal,
  Header,
  Button,
  Grid,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Switch, Redirect } from 'react-router'
import {
  get,
  orderBy,
  without,
  compact,
  cloneDeep,
} from 'lodash'
import Joyride, {
  STATUS,
} from 'react-joyride'
import config from '../../../config.js'

// import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
// import isStale from '../../../helpers/is-stale'
import OrganizationView, { helpers as orgViewHelpers } from '../organization/organization'
import OrganizationNewView, { helpers as orgNewViewHelpers } from '../organization/organization-new'
// import ManageOrganizationView from '../organization/manage-organization'
// import FirePhrasesView from '../fire-phrases/fire-phrases'
// import SaveFirePhraseView from '../fire-phrases/save-fire-phrase'
import PrivateRoute from '../../common/private-route/private-route'
// import LanguageSelect from '../../common/language-select/language-select'
import { logout } from '../../../actions/authentication'
import { invalidateClients } from '../../../actions/clients'
import Query from '../../common/query/query'
import GET_ORG from '../../../helpers/graphql-queries/get-org'
import FIND_ORGS from '../../../helpers/graphql-queries/find-organizations'
import GET_ORG_WITH_PARENTS from '../../../helpers/graphql-queries/get-org-with-parents'
import GET_ORGS_BY_PARENT_ID from '../../../helpers/graphql-queries/get-orgs-by-parent-id'
import {
  findChildOrg, getOrgType, findParentContractedCustomerOrSiteOrg,
} from '../../../helpers/organization'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import { invalidateClientActivity } from '../../../actions/client-activity'
import SidebarNew from '../../common/sidebar-new'
import getWhatsNewMessages from '../organization/clients/get-whats-new-messages'
import CompactMessages from '../../common/compact-messages'
import MainContentContainer from '../../common/main-content-container'
import { EXTERNAL_ROLE_TYPES, INTERNAL_ROLE_TYPES } from '../../../helpers/get-roles'

import './home-new.css'
// import { ROLE_TYPES } from '../../../helpers/get-roles'

const LOCAL_STORAGE_PREFIX = 'com.base-ed.whats-new'

const sixMonthsAgo = new Date(Date.now() - (1000 * 60 * 60 * 24 * 180))

export class HomeNewWrapper extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    invalidateClientActivity: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  static path = '/'

  constructor (props) {
    super(props)
    this.state = {
      searchText: '',
    }
  }

  render () {
    const {
      location,
      authState,
    } = this.props
    const orgIdMatch = location.pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : authState.orgId
    return (
      <Query
        variables={{ organizationId: currentOrgId, childrenDepth: 0 }}
        query={GET_ORG}
      >
        {({
          loading: currentOrgLoading,
          data: currentOrgData,
        }) => {
          // NOTE: for some reason the query below isn't caching the data properly, or the cache is getting modified somewhere
          //       so set fetch policy to network-only to keep data fresh
          return (
            <Query
              variables={{ id: currentOrgId }}
              query={GET_ORG_WITH_PARENTS}
              fetchPolicy='network-only'
            >
              {({
                loading: homeOrgLoading,
                data: homeOrgData,
              }) => {
                const isLoading = homeOrgLoading || currentOrgLoading
                // NOTE: if we are still loading any of the data, don't try to render with partial data
                let homeOrg = (isLoading) ? null : cloneDeep(get(homeOrgData, 'organizationWithParents'))
                const currentOrg = (isLoading) ? null : get(currentOrgData, 'organization')
                if (isLoading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                const homeOrgType = getOrgType(homeOrg)
                const currentOrgType = getOrgType(currentOrg)
                const parentCustomerOrSite = findParentContractedCustomerOrSiteOrg(homeOrg, currentOrg)
                const isInternalGroup = (homeOrgType === 'group' && currentOrgType === 'group' && !parentCustomerOrSite)
                return (
                  <Query
                    variables={(isInternalGroup) ? { parentId: currentOrgId, first: 1000 } : { organizationId: (homeOrgType === 'group' && !parentCustomerOrSite) ? currentOrgId : (parentCustomerOrSite) ? parentCustomerOrSite.id : homeOrg.id, childrenDepth: 10 }}
                    query={(isInternalGroup) ? GET_ORGS_BY_PARENT_ID : GET_ORG}
                    fetchPolicy='network-only'
                  >
                    {({
                      loading: currentOrgChildrenLoading,
                      data: currentOrgChildrenData,
                      refetch: refectchChildOrgList,
                    }) => {
                      if (currentOrgChildrenLoading) {
                        return (<FullScreenLoadingOverlay isActive={true}/>)
                      }
                      let currentOrgIsAGroupWithNoChildren = false
                      const currentOrgChildren = (isInternalGroup) ? get(currentOrgChildrenData, 'organizationsByParentId.organizations') : get(currentOrgChildrenData, 'organization')

                      if (homeOrgType !== 'group' && !parentCustomerOrSite) {
                        homeOrg = cloneDeep(currentOrgChildren)
                      } else {
                        const relevantId = (parentCustomerOrSite) ? parentCustomerOrSite.id : currentOrgId
                        const currentOrgInTree = findChildOrg([ homeOrg ], relevantId)
                        currentOrgInTree.children = (currentOrgType === 'group' && !parentCustomerOrSite) ? currentOrgChildren : currentOrgChildren.children
                        currentOrgIsAGroupWithNoChildren = (currentOrgType === 'group' && (!currentOrgInTree.children || !currentOrgInTree.children.length))
                      }
                      return (
                        <>
                          {(!!homeOrg && !!currentOrg) && (
                            <Query
                              skip={!this.state.searchText}
                              variables={{ searchText: this.state.searchText, limit: 25 }}
                              fetchPolicy='network-only'
                              query={FIND_ORGS}
                            >
                              {({
                                loading: searchLoading,
                                data: searchData,
                              }) => {
                                return (
                                  <HomeNewView
                                    homeOrg={homeOrg}
                                    currentOrg={currentOrg}
                                    currentOrgIsAGroupWithNoChildren={currentOrgIsAGroupWithNoChildren}
                                    isSearching={searchLoading}
                                    onSearch={(value) => this.setState({ searchText: value })}
                                    searchResults={get(searchData, 'findOrganizations.results', []) || []}
                                    hasMoreSearchResults={get(searchData, 'findOrganizations.hasMore', false) || false}
                                    refectchChildOrgList={refectchChildOrgList}
                                    {...this.props}
                                  />
                                )
                              }
                              }
                            </Query>
                          )}
                        </>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
  logout,
  invalidateClients,
  invalidateClientActivity,
}
const HomeNewWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(HomeNewWrapper)

export default translate([ 'components' ])(HomeNewWrapperContainer)

class HomeNewView extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    invalidateClientActivity: PropTypes.func.isRequired,
    refectchChildOrgList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    authState: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    homeOrg: PropTypes.object.isRequired,
    currentOrg: PropTypes.object.isRequired,
    isSearching: PropTypes.bool,
    currentOrgIsAGroupWithNoChildren: PropTypes.bool,
    searchResults: PropTypes.arrayOf(PropTypes.object),
    onSearch: PropTypes.func.isRequired,
    hasMoreSearchResults: PropTypes.bool,
  }

  static path = '/'

  constructor (props) {
    super(props)
    const isStaff = [ EXTERNAL_ROLE_TYPES.ADMIN, EXTERNAL_ROLE_TYPES.SUPERVISOR, EXTERNAL_ROLE_TYPES.ADVISOR ].includes(props.authState.roleType)
    const unreadMessageIds = (!isStaff) ? [] : [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38' ].filter((msgId) => {
      const isStored = window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${msgId}.${props.authState.userId}`)
      return !isStored
    })
    this.state = {
      runTour: false,
      showHelp: false,
      showMessages: false,
      unreadMessageIds,
    }
  }

  componentDidMount () {
    const {
      location,
      homeOrg,
    } = this.props

    if (location.pathname === HomeNewView.path) {
      this.navigateToSelectedOrg(homeOrg)
    }
  }

  componentDidUpdate (prevProps) {
    // const { location } = prevProps
    const {
      location: currentLocation,
      homeOrg,
    } = this.props
    if (currentLocation.pathname === HomeNewView.path) {
      this.navigateToSelectedOrg(homeOrg)
    }
  }

  navigateTo = (path) => (this.props.location.pathname !== path) && this.props.history.push(path)

  navigateToSelectedOrg = (org) => {
    const {
      invalidateClients,
      invalidateClientActivity,
      location: { pathname },
      authState: { orgId },
    } = this.props
    const orgIdMatch = pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : null
    if (currentOrgId !== org.id) {
      invalidateClientActivity()
    }
    invalidateClients()
    if (org.roomsEnabled && orgId === org.id) {
      this.navigateTo(orgNewViewHelpers.getMyDashboardPath(org.id))
    } else if (org.roomsEnabled && orgId !== org.id) {
      this.navigateTo(orgNewViewHelpers.getOrgClientsPath(org.id))
    } else if (org.sessionsEnabled && orgId === org.id) {
      this.navigateTo(orgViewHelpers.getMyDashboardPath(org.id))
    } else if (org.sessionsEnabled && orgId !== org.id) {
      this.navigateTo(orgViewHelpers.getAllSessionsPath(org.id))
    } else {
      this.navigateTo(orgViewHelpers.getOrgClientsPath(org.id))
    }
  }

  navigateToMyDashboard = (orgId, userId, sessionsEnabled) => {
    const path = (sessionsEnabled) ? orgViewHelpers.getMyDashboardPath(orgId) : orgViewHelpers.getOrgClientsPath(orgId)
    this.navigateTo(path)
  }

  navigateToMyRoomsDashboard = (orgId) => this.navigateTo(orgNewViewHelpers.getMyDashboardPath(orgId))

  navigateToOrgClients = (orgId) => this.navigateTo(orgViewHelpers.getOrgClientsPath(orgId))

  navigateToOrgCourses = (orgId) => this.navigateTo(orgViewHelpers.getOrgCoursesPath(orgId))

  navigateToOrgRooms = (orgId) => this.navigateTo(orgNewViewHelpers.getOrgRoomsPath(orgId))

  navigateToOrgSettings = (orgId) => this.navigateTo(orgViewHelpers.getOrgSettingsPath(orgId))

  navigateToOrgActivity = (orgId) => this.navigateTo(orgViewHelpers.getOrgActivityPath(orgId))

  navigateToOrgBaseline = (orgId) => this.navigateTo(orgViewHelpers.getOrgBaselinePath(orgId))

  // navigateToManageFirePhrases = () => this.navigateTo(FirePhrasesView.path)

  handleMessageRead = (messageId) => {
    this.setState({ ...this.state, unreadMessageIds: without(this.state.unreadMessageIds, messageId) })
    window.localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.${messageId}.${this.props.authState.userId}`, 'true')
  }

  renderOrganizationView = (homeOrg, currentOrg, routeProps) => {
    const { refectchChildOrgList } = this.props
    if (currentOrg.roomsEnabled) {
      return (
        <OrganizationNewView
          homeOrg={homeOrg}
          currentOrg={currentOrg}
          refectchChildOrgList={refectchChildOrgList}
          {...routeProps}
        />
      )
    }
    return (
      <OrganizationView
        homeOrg={homeOrg}
        currentOrg={currentOrg}
        isNewVersion={true}
        {...routeProps}
      />
    )
  }

  renderSidebarMainContent = (openSidebar) => {
    const {
      t,
      location: { pathname },
      authState: {
        orgId,
        // roleType,
      },
      homeOrg,
      currentOrg,
    } = this.props
    const orgIdMatch = pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : orgId
    return (
      <div className='home-page'>
        <div>
          <MainContentContainer>
            {(!homeOrg.roomsEnabled) && (
              <>
                <Modal
                  open={this.state.showHelp}
                  onClose={() => this.setState({ ...this.state, showHelp: false })}
                  size='small'
                  closeIcon={true}
                >
                  <Grid centered divided celled='internally' columns={2}>
                    <Grid.Row>
                      {/* <Grid.Column textAlign='center'>
                            <Header as='h4'>Getting Started</Header>
                            <Button color='pink' onClick={() => {
                              if (!onDashboard) {
                                this.navigateToSelectedOrg(currentOrgId)
                              }
                              this.setState({
                                runTour: true, showHelp: false, showMessages: false,
                              })
                            }}><Icon name='binoculars'/> Take a Tour</Button>
                          </Grid.Column> */}
                      <Grid.Column textAlign='center'>
                        <Header as='h4'>Need Additional Help?</Header>
                        <Button color='blue' as='a' target='new' href='https://admin.base.education/BASE-User-Guide.pdf'><Icon name='eye'/> View User Guide</Button>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign='center'>
                        <Header as='h4'>Have questions?</Header>
                        <p>Give us a call at 678-878-3144 or send an email to <a href="mailto:basesupport@7mindsets.com">basesupport@7mindsets.com</a></p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal>

                <Modal
                  open={this.state.showMessages}
                  onClose={() => this.setState({ ...this.state, showMessages: false })}
                  size='small'
                  closeIcon={true}
                >
                  <Modal.Header data-public>What&apos;s New?</Modal.Header>
                  <Modal.Content>
                    <CompactMessages
                      messages={compact(orderBy(getWhatsNewMessages(t, 'views.organization'), 'date', [ 'desc' ]).filter((msg) => msg.date > sixMonthsAgo && (!msg.forOrgId || [ homeOrg.id, ...homeOrg.parentIds ].includes(msg.forOrgId))).slice(0, 3))}
                      messageIdsToStartOpen={this.state.unreadMessageIds}
                      onMessageRead={this.handleMessageRead}
                    />
                  </Modal.Content>
                </Modal>

                <CompactMessages
                  messages={compact(orderBy(getWhatsNewMessages(t, 'views.organization'), 'date', [ 'desc' ]).filter((msg) => msg.date > sixMonthsAgo && (!msg.forOrgId || [ homeOrg.id, ...homeOrg.parentIds ].includes(msg.forOrgId))).slice(0, 3))}
                  messageIdsToStartOpen={this.state.unreadMessageIds}
                  onMessageRead={this.handleMessageRead}
                  hide={true}
                />
              </>
            )}

            <Joyride
              continuous={true}
              run={this.state.runTour}
              showSkipButton={true}
              disableOverlayClose={true}
              locale={{ skip: 'Exit', last: 'Finish' }}
              styles={{
                options: {
                  primaryColor: '#2185d0',
                },
              }}
              callback={({
                action, index, status, type,
              }) => {
                if ([ STATUS.FINISHED, STATUS.SKIPPED ].includes(status)) {
                  // Need to set our running state to false, so we can restart if we click start again.
                  this.setState({ runTour: false, showHelp: false })
                  return
                }
                if (index === 0) {
                  openSidebar(true)
                  this.navigateToOrgClients(currentOrgId)
                }
                // if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 9 && !onDashboard) {
                //   this.navigateToOrgClients(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 8 && !onCourses) {
                //   this.navigateToOrgCourses(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 11 && !onCourses) {
                //   this.navigateToOrgCourses(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 10 && !onActivity) {
                //   this.navigateToOrgActivity(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 12 && !onActivity) {
                //   this.navigateToOrgActivity(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 11 && !onOrgSettings) {
                //   this.navigateToOrgSettings(currentOrgId)
                // } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 13 && !onOrgSettings) {
                //   this.navigateToOrgSettings(currentOrgId)
                // } else if (type === EVENTS.TOUR_STATUS && action === ACTIONS.STOP && !onDashboard) {
                //   this.navigateToOrgClients(currentOrgId)
                // }
              }}
              steps={[
                {
                  target: 'body',
                  content: (
                    <div>
                      <h3>Welcome to the BASE Education Admin Portal</h3>
                      <p>We would like to show you around in order to help you get started.</p>
                    </div>
                  ),
                  placement: 'center',
                  hideCloseButton: true,
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'right-start',
                  target: '.sidebar',
                  content: 'This is the main navigation point of the admin portal. Here you can navigate to different suborganizations within your main organization. If you aren\'t able to find the student(s) you are looking for, you may be able to find them in one of these suborganizations.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'right-end',
                  target: '.bottom-buttons',
                  content: 'The four buttons here can be used for navigating to your own account page, viewing messages about what is new, viewing our help options, and logging out of the admin portal.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'bottom',
                  target: '.home-page .body-segment .ui.pointing.menu',
                  content: 'These buttons will take you to different sections within the selected organization. We are currently on the Users section.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'auto',
                  target: '#users-list',
                  content: 'This is where you can see information about the users in your organization. By default, they are sorted by most recent activity so that the most relevant users are on top of the list. You can also click on an individual user to see their enrollment and module progress details.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#user-search',
                  content: 'If you are looking for a specific user, you can type in here to search by user name, first name, last name, and email address.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#user-filter-options',
                  content: 'Use these controls to narrow down your search for a specific set of users or to change the order in which they appear.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'bottom-end',
                  target: '#user-actions',
                  content: 'This button will open the user actions menu. These user actions will let you toggle between active and archived users, create new users, and download the user data in csv/spreadsheet format.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#new-users-btn',
                  content: 'If you ever need to get back to the Users section, you can click this button.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#new-courses-btn',
                  content: 'This section is where you can view a list of modules that we offer and their descriptions. You can also manage your student enrollments by module here.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  placement: 'right',
                  target: '.ui.raised.card.course-card:first-child .header',
                  content: 'You can click on a module to manage user enrollments for that specific module.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#new-activity-btn',
                  content: 'This section is where you can view your student\'s recent activity.',
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: '#new-settings-btn',
                  content: (
                    <div>
                      <p>This section is where you view and/or manage some of the organization settings, like the students&quot; hours of accessibility and the suborganizations within your current organization.</p>
                      <p>Managing the student hours of accessibility can only be done by admins and it lets you set the days and times of the week that your students are able to log into the system. If you need to disable access on specific days for your students, you can add restricted days.</p>
                    </div>
                  ),
                }, {
                  disableBeacon: true,
                  hideCloseButton: true,
                  target: 'body',
                  content: (
                    <div>
                      <h3>Thank You for touring the BASE Education Admin Portal</h3>
                      <p>If you need additional help, give us a call at 678-878-3144 or send an email to <a href="mailto:basesupport@7mindsets.com">basesupport@7mindsets.com.</a></p>
                    </div>
                  ),
                  placement: 'center',
                },
              ]}
            />
            <div className='body-segment'>
              <Switch>
                <PrivateRoute path={OrganizationView.path} render={this.renderOrganizationView.bind(this, homeOrg, currentOrg)}/>
                <Redirect to={HomeNewView.path} />
              </Switch>
            </div>
          </MainContentContainer>
        </div>
      </div>
    )
  }

  render () {
    const {
      location: { pathname },
      authState: {
        orgId,
        userId,
        roleType,
      },
      homeOrg,
      currentOrg,
      isSearching,
      onSearch,
      searchResults,
      hasMoreSearchResults,
      currentOrgIsAGroupWithNoChildren,
    } = this.props
    const orgIdMatch = pathname.match(/^\/organizations\/([^/]+)/)
    const currentOrgId = (orgIdMatch) ? orgIdMatch[1] : orgId
    const onCourses = (pathname.endsWith('/courses'))
    const onAllRooms = (pathname.includes('/all-rooms'))
    const onOrgSettings = (pathname.endsWith('/manage'))
    const onUsers = (pathname.includes('/users') || pathname.includes('/archivedUsers'))
    const onActivity = (pathname.endsWith('/activity'))
    const onBaseline = (pathname.endsWith('/baseline-summary'))
    // NOTE: This is flawed since techincally a customer could have groups under groups, but no one is using that now, and there is no other good way to do this
    const isCustomer = !!findParentContractedCustomerOrSiteOrg(homeOrg, currentOrg) || currentOrgIsAGroupWithNoChildren

    const selectedMenuItem = (onCourses) ? 'modules'
      : (onOrgSettings) ? 'settings'
        : (onActivity) ? 'activity'
          : (onAllRooms) ? 'rooms'
            : (onUsers) ? 'users'
              : (onBaseline) ? 'baseline' : 'home'
    return (
      <SidebarNew
        onOrganizationClick={this.navigateToSelectedOrg}
        isSearching={isSearching}
        onSearchChange={onSearch}
        searchResults={searchResults}
        hasMoreSearchResults={hasMoreSearchResults}
        selectedOrgId={currentOrgId}
        currentOrgActive={currentOrg.isActive}
        organization={homeOrg}
        selectedMenuItem={selectedMenuItem}
        hideRoomsMenuItem={!isCustomer || !currentOrg.roomsEnabled}
        isInternalUser={[ INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER, INTERNAL_ROLE_TYPES.INTERNAL_ADMIN, INTERNAL_ROLE_TYPES.SUPER_ADMIN ].includes(roleType)}
        hideBaselineMenuItem={!config.baseline.enabledRoleTypes.includes(roleType) || !currentOrg.baselineAnalysisEnabled}
        onMenuItemClick={(item) => {
          if (item === 'home') {
            if (homeOrg.roomsEnabled) {
              this.navigateToMyRoomsDashboard(orgId, userId)
            } else {
              this.navigateToMyDashboard(orgId, userId, homeOrg.sessionsEnabled)
            }
          } else if (item === 'users') {
            this.navigateToOrgClients(currentOrgId)
          } else if (item === 'modules') {
            this.navigateToOrgCourses(currentOrgId)
          } else if (item === 'activity') {
            this.navigateToOrgActivity(currentOrgId)
          } else if (item === 'settings') {
            this.navigateToOrgSettings(currentOrgId)
          } else if (item === 'rooms') {
            this.navigateToOrgRooms(currentOrgId)
          } else if (item === 'baseline') {
            this.navigateToOrgBaseline(currentOrgId)
          }
        }}
        onChange={(isOpen) => {
          !isOpen && window.localStorage.setItem(`education.base.admin.${userId}.new-nav-is-closed`, 1)
          isOpen && window.localStorage.removeItem(`education.base.admin.${userId}.new-nav-is-closed`)
        }}
        defaultOpen={!window.localStorage.getItem(`education.base.admin.${userId}.new-nav-is-closed`)}
        render={this.renderSidebarMainContent}
      />
    )
  }
}
