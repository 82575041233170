import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
  List,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import GET_ROOMS_BY_ORG_ID from '../../../helpers/graphql-queries/get-rooms-by-org-id'
import {
  get,
  filter,
  intersection,
} from 'lodash'
import Query from '../../common/query/query'
import Mutation from '../../common/mutation/mutation'
import DELETE_ROOMS from '../../../helpers/graphql-queries/delete-rooms'

import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import '../../common.css'
import './../staff-help-modal/staff-help-modal.css'

const RoomOrgChangeModal = ({
  onClose,
  onTransferSelect,
  open,
  orgId,
  userIds,
  callback,
}) => {
  const variables = { orgId }
  if (!open) {
    return null
  }
  return (
    <Query
      variables={variables}
      query={GET_ROOMS_BY_ORG_ID}
    >

      {({
        loading,
        data,
      }) => {
        if (loading) {
          return (<FullScreenLoadingOverlay isActive={true}/>)
        } else {
          const ownedRooms = filter(get(data, 'roomsByOrgId.rooms') || [], (room) => userIds.includes(room.ownerUserId))
          const studentIdsToMove = new Set()
          const roomOwners = new Set(ownedRooms.map((r) => r.ownerUserId))
          const rooms = filter(get(data, 'roomsByOrgId.rooms') || [], function (r) {
            intersection(r.studentIds, userIds).forEach(studentIdsToMove.add, studentIdsToMove)
            return intersection(r.studentIds, userIds).length > 0
          })
          if (rooms.length > 0) {
            if (!window.confirm(`${studentIdsToMove.size === 1 ? 'The selected student' : `${studentIdsToMove.size} students`} will be removed from all rooms. Do you wish to proceed?`)) {
              onClose()
              return
            }
          }
          if (ownedRooms.length === 0 && open) {
            callback()
            onClose()
          }
          return (
            <Mutation
              mutation={DELETE_ROOMS}
              onCompleted={() => {
                callback()
                onClose()
              }}
            >
              {(deleteRooms) => {
                return (
                  <>
                    <Modal
                      className='room-org-change-modal'
                      closeOnDimmerClick={false}
                      closeIcon={true}
                      open={open && !!ownedRooms.length}
                      onClose={() => {
                        onClose()
                      }}
                    >
                      <Modal.Content style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 2 }}>
                          <Header data-public style={{ marginTop: 0 }}>
                            <Icon style={{ fontSize: '2.5em' }} className='base-teal' name='warning sign' />
                            <Header.Content>
                              We have noticed you are trying to transfer {roomOwners.size === 1 ? 'a user' : 'multiple users'} with existing rooms.
                              <Header.Subheader>What would you like to do with them?</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <div className='text bold grey'>Please select below:</div>
                          <List relaxed selection size='big'>
                            <List.Item onClick={() => {
                              if (!window.confirm(`Are you sure you want to delete all rooms? This cannot be undone`)) {
                                return
                              }
                              deleteRooms({ variables: { ids: ownedRooms.map((room) => room.id) } })
                            }
                            } tabIndex={0}>
                              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
                              <List.Content data-public>
                                <List.Header>Delete all existing rooms</List.Header>
                                <List.Description>All rooms owned by this user will be deleted due to lack of ownership. This may make some modules unavailable to students accessing them through the room.</List.Description>
                              </List.Content>
                            </List.Item>
                            <List.Item onClick={() => {
                              onTransferSelect()
                            }} tabIndex={0}>
                              <List.Icon name='chevron right' className='base-teal' size='large' verticalAlign='middle' />
                              <List.Content data-public>
                                <List.Header>Reassign room owners</List.Header>
                                <List.Description>Choose another staff member in your organization to inherit ownership of this staff members rooms. Students will retain access.</List.Description>
                              </List.Content>
                            </List.Item>
                          </List>
                        </div>
                      </Modal.Content>
                    </Modal>
                  </>)
              }}
            </Mutation>
          )
        }
      }}

    </Query>

  )
}

RoomOrgChangeModal.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onTransferSelect: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.array.isRequired,
  callback: PropTypes.func,
}

RoomOrgChangeModal.defaultProps = {
  onOptionSelect: console.log.bind(console, 'onOptionSelect'),
  isSaving: false,
  hasRooms: false,
}

export default translate([ 'components' ])(RoomOrgChangeModal)
