import gql from 'graphql-tag'

const GET_ROOM_BY_ID = gql`
query getRoomById($id: ID!) {
  room(
    id: $id
  ) {
      id
      name
      imageUrl
      orgId
      ownerUserId
      studentIds
      activity {
        daily {
          x
          y
        }
        weekly {
          x
          y
        }
        monthly {
          x
          y
        }
        quarterly {
          x
          y
        }
      }
      usage {
        courseId
        data {
          x
          y
        }
      }
      students {
        userName
        id
        email
        orgId
        profile {
          firstName
          lastName
        }
        enrollmentsMetadata {
          id
          courseId
          attemptNumber
          lastActiveDate
          highestCompletedOrdinal
          isComplete
        }
        unreviewedFirePhraseCaptures {
          id
          analyzedCaptures {
            sentence
            receptivitiSeverity
            ignisSeverity
            isSafe
            questionText
            matchedPhrases {
              id
              matchedPhraseText
              matchedSentenceSegment
            }
          }
          attemptNumber
          created
          modified
          questionId
        }
      }
      requestedStudents {
        userName
        id
        email
        profile {
          firstName
          lastName
        }
      }
      requestedStudentIds
      courseIds
      hiddenCourseIds
      firewordNotificationsEnabled
      firewordNotificationsForRoomCoursesOnly
      isOpenForRequests
      isPrivate
      created
      modified
    }
}
`

export default GET_ROOM_BY_ID
