import getInitialFetchState from './helpers/get-initial-fetch-state'
import getInitialAuthenticationState from './helpers/get-initial-authentication-state'
import config from './config.js'

export const getInitialState = () => ({
  userProfile: getInitialFetchState(),
  userExtendedProfile: getInitialFetchState(),
  enrollments: getInitialFetchState(),
  firePhrases: getInitialFetchState(),
  organizations: getInitialFetchState(),
  organizationAggregations: getInitialFetchState(),
  clients: getInitialFetchState(),
  staff: getInitialFetchState(),
  courses: getInitialFetchState(),
  roles: getInitialFetchState(),
  relationships: getInitialFetchState(),
  authentication: getInitialAuthenticationState(),
  password: getInitialFetchState(),
  answers: getInitialFetchState(),
  analyzedAnswers: getInitialFetchState(),
  firePhraseCaptures: getInitialFetchState(),
  ignisRatings: getInitialFetchState(),
  accessSchedules: getInitialFetchState(),
  contentItems: getInitialFetchState(),
  courseImages: getInitialFetchState(),
  clientCounts: getInitialFetchState(),
  clientActivity: getInitialFetchState(),
  config,
})

export const getInitialBaselineState = () => ({
  userProfile: getInitialFetchState(),
  authentication: getInitialAuthenticationState(),
  config,
})
