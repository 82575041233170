import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { find } from 'lodash'

import LANGUAGES, { RTL_LANGUAGE_CODES } from '../../../helpers/languages'
import classNames from '../../../helpers/class-names'

const changeLanguage = (i18n, event, option) => {
  i18n.changeLanguage(option.value)
  // for now, reload the page, since our components are not bound to this change for perf reasons
  setTimeout(() => window.location.reload(), 1)
}

const LanguageSelect = ({
  options,
  t,
  i18n,
}) => {
  const defaultValue = find(options, { value: i18n.language }) || options[0]
  const rtl = RTL_LANGUAGE_CODES.includes(i18n.language)
  return (
    <Dropdown
      item
      text={defaultValue.text}
      scrolling
      direction='left'
    >
      <Dropdown.Menu>
        <Dropdown.Header
          className={classNames({ rtl })}
          icon='world'
          content={t('common.language-select.select_language')}
        />
        <Dropdown.Divider />
        {options.map((option) => (
          <Dropdown.Item
            onClick={changeLanguage.bind(null, i18n)}
            key={option.value}
            selected={defaultValue.value === option.value}
            text={option.text}
            value={option.value}
          />
        ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

LanguageSelect.propTypes = {
  options: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
}

LanguageSelect.defaultProps = {
  options: LANGUAGES,
}

export default translate([ 'components' ])(LanguageSelect)
