import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dropdown,
  Icon,
  Modal,
  Header,
} from 'semantic-ui-react'

import Mutation from '../../../common/mutation/mutation'
import UPDATE_CLIENTS_ARCHIVED_STATUS_BY_USER_IDS from '../../../../helpers/graphql-queries/update-clients-archved-status-by-user-ids'

const TRANSLATION_PREFIX = 'views.organization.clients'

const ArchiveClientsByUserIdsMenuItem = (props) => {
  const {
    isArchived, onLoadingChange, userIds,
  } = props
  const [ complete, setComplete ] = useState(false)
  const [ count, setCount ] = useState(0)

  return (
    <Mutation
      mutation={UPDATE_CLIENTS_ARCHIVED_STATUS_BY_USER_IDS}
      onCompleted={(data) => {
        setCount(data.updateClientsArchivedStatus.length)
        setComplete(true)
        onLoadingChange(false)
      }}
    >
      {(updateClientsArchivedStatusByIds, { loading }) => {
        return (
          <>
            <Dropdown.Item data-public
              disabled={userIds && !userIds.length}
              onClick={() => {
                if (!window.confirm(`Are you sure you want to ${isArchived ? 'unarchive' : 'archive'} ${userIds.length} user${userIds.length > 1 ? 's' : ''}?`)) {
                  return
                }
                onLoadingChange(true)
                updateClientsArchivedStatusByIds({
                  variables: {
                    ids: userIds,
                    newArchivedStatus: !isArchived,
                  },
                })
              }}
            >
              <Icon name='archive' />{isArchived ? 'Unarchive' : 'Archive'}
            </Dropdown.Item>

            <CompletionModal
              {...props}
              count={count}
              isArchived={isArchived}
              open={complete}
              setOpen={setComplete}
            />
          </>
        )
      }}
    </Mutation>
  )
}

ArchiveClientsByUserIdsMenuItem.propTypes = {
  userIds: PropTypes.array,
  isArchived: PropTypes.bool,
  onLoadingChange: PropTypes.func,
}

const CompletionModal = ({
  count, invalidateClients, isArchived, open, setOpen, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Content>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <Icon style={{ fontSize: '5em', margin: '40px auto -25px auto' }} name='check circle' />
      </Header>

      <Modal.Description>
        <div className='success-label'>{`Success! ${count} ${(count === 1) ? 'user was' : 'users were'} ${isArchived ? 'unarchived' : 'archived'}.`}</div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions style={{ justifyContent: 'flex-end' }}>
      <Button
        content={t(`${TRANSLATION_PREFIX}.done`)}
        onClick={() => {
          setOpen(false)
          invalidateClients()
        }}
        primary
      />
    </Modal.Actions>
  </Modal>
)

CompletionModal.propTypes = {
  count: PropTypes.number,
  isArchived: PropTypes.bool,
  open: PropTypes.bool,
  invalidateClients: PropTypes.func,
  setOpen: PropTypes.func,
  t: PropTypes.func,
}

export default ArchiveClientsByUserIdsMenuItem
