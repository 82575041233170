import React from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Icon,
} from 'semantic-ui-react'

import Mutation from '../../../common/mutation/mutation'
import INCREMENT_CLIENT_GRADE_LEVELS_BY_ORG_ID from '../../../../helpers/graphql-queries/increment-client-grade-levels-by-org-id'

const IncrementClientGradeLevelsByOrgIdMenuItem = ({
  orgId,
  onLoadingChange,
}) => (
  <Mutation
    mutation={INCREMENT_CLIENT_GRADE_LEVELS_BY_ORG_ID}
    onCompleted={(data) => {
      const numModified = data.incrementClientGradeLevelsByOrgId
      onLoadingChange(false)
      setTimeout(() => window.alert(`Success! ${numModified} ${(numModified === 1) ? 'student was' : 'students were'} updated.`), 100)
    }}
  >
    {(incrementClientGradeLevelsByOrgId, { loading }) => {
      return (
        <Dropdown.Item data-public onClick={() => {
          if (!window.confirm('Are you sure you want to increase the education level of all students in this orgainzation by 1? (This will only affect students who have an education level set in their profile)')) {
            return
          }
          onLoadingChange(true)
          incrementClientGradeLevelsByOrgId({
            variables: {
              orgId,
            },
          })
        }}><Icon name='add circle' />Increment All Student Education Levels</Dropdown.Item>
      )
    }}
  </Mutation>
)

IncrementClientGradeLevelsByOrgIdMenuItem.propTypes = {
  orgId: PropTypes.string,
  onLoadingChange: PropTypes.func,
}

export default IncrementClientGradeLevelsByOrgIdMenuItem
