import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Label,
  Popup,
  Icon,
  Input,
} from 'semantic-ui-react'

import ClassLinkLogo from '../logos/class-link'
import CleverLogo from '../logos/clever'
import { copyToClipboard } from '../../../helpers/clipboard'

import './org-tree.css'

const POPUP_MOUSEOVER_DELAY_MS = 500

const OrgTree = ({
  id,
  name,
  isActive,
  contractEndDate,
  children,
  selected,
  onClick,
  ssoType,
  ssoId,
  icon,
}) => {
  const isPastContract = (isActive && contractEndDate && new Date(contractEndDate) < new Date())
  return (
    <div className='org-tree'>
      <Header
        data-public
        className={(selected) ? 'org-header selected' : 'org-header'}
        tabIndex='0'
        onClick={() => onClick(id)}
      >
        <Header.Content><Icon name={icon} className='base-teal'/> {name}</Header.Content>
        {(ssoType === 'classlink') && (
          <Header.Content>
            <Popup
              hoverable
              content={'ClassLink Integration'}
              position='right center'
              size='mini'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <span style={{ paddingRight: 3 }}>
                  <ClassLinkLogo height={16} width={16} />
                </span>
              )}
            />
          </Header.Content>
        )}
        {(ssoType === 'clever') && (
          <Header.Content>
            <Popup
              hoverable
              flowing
              content={(
                <div onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                  <p className='text bold'>Clever Integration</p>
                  <div>
                    <div>Launch URL: </div>
                    <Input
                      type='text'
                      action={{
                        color: 'blue',
                        icon: 'copy',
                        title: 'Copy URL to Clipboard',
                        onClick: () => copyToClipboard(`https://clever.com/oauth/authorize?redirect_uri=${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education?sso=Clever&client_id=217a7a8fa41c2965c02c&response_type=code&district_id=${ssoId}`),
                      }}
                      value={`https://clever.com/oauth/authorize?redirect_uri=${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education?sso=Clever&client_id=217a7a8fa41c2965c02c&response_type=code&district_id=${ssoId}`}
                    />
                  </div>
                </div>
              )}
              position='right center'
              mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
              trigger={(
                <span style={{ paddingRight: 3 }}>
                  <CleverLogo height={16} width={16} />
                </span>
              )}
            />
          </Header.Content>
        )}
        <Header.Content className='active-label'>
          <Popup
            hoverable
            content={(isPastContract) ? 'Active - Contract Has Ended' : (isActive) ? 'Active' : 'Inactive'}
            position='right center'
            size='mini'
            mouseEnterDelay={POPUP_MOUSEOVER_DELAY_MS}
            trigger={(
              <Label
                empty
                circular
                color={(isPastContract) ? 'yellow' : (isActive) ? 'green' : 'red'}
              />
            )}
          />
        </Header.Content>
      </Header>
      <div className='children'>
        {children}
      </div>
    </div>
  )
}

OrgTree.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  contractEndDate: PropTypes.string,
  // children: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.element),
  //   PropTypes.element,
  // ]),
  children: PropTypes.any,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  ssoType: PropTypes.string,
  ssoId: PropTypes.string,
  icon: PropTypes.string,
}

OrgTree.defaultProps = {
  onClick: console.log.bind(console, 'onClick'),
}

export default memo(OrgTree)
