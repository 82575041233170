import {
  find,
  map,
  flatMap,
  filter,
  keyBy,
} from 'lodash'

export const getOrgType = (org) => {
  return (org.contractStartDate || org.contractEndDate) ? 'customer' : (org.type) ? 'site' : 'group'
}

export const getFlattenedChildOrgs = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return []
  }
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  return childOrgs.concat(getFlattenedChildOrgs(children))
}

export const findChildOrg = (children, childId) => {
  if (!children) {
    return null
  }
  return find(getFlattenedChildOrgs(children), { id: childId })
}

export const getOrgDetails = (org) => {
  const ssoType = (org.id.startsWith('clo_')) ? 'classlink' : (org.id.startsWith('co_')) ? 'clever' : null
  const orgType = getOrgType(org)
  return {
    ssoType,
    orgType,
    icon: (orgType === 'customer') ? 'address card' : (orgType === 'site') ? 'building' : 'users',
    ssoId: (ssoType) ? org.id.split('_')[1] : null,
  }
}

export const findParentContractedCustomerOrSiteOrg = (topOrg, currentOrg) => {
  const topOrgType = getOrgType(topOrg)
  if (topOrgType === 'customer' || topOrgType === 'site') {
    return topOrg
  }
  const currentOrgType = getOrgType(currentOrg)
  if (currentOrgType === 'customer') {
    return currentOrg
  }
  if (topOrg.id === currentOrg.id) {
    return null
  }
  const org = currentOrg.parentIds.slice(1).reduce((prev, parentId) => {
    if (prev.matchingOrg) {
      return prev
    }
    const matchingChild = find(prev.orgs, { id: parentId })
    if (!matchingChild) {
      console.error('Unable to get parent org types for parent', { parentId })
      throw new Error('Unable to get parent org types for parent')
    }
    const childType = getOrgType(matchingChild)
    return {
      matchingOrg: (childType === 'customer' || childType === 'site') ? matchingChild : null,
      orgs: matchingChild.children,
    }
  }, {
    matchingOrg: null,
    orgs: topOrg.children,
  }).matchingOrg
  return org
}

export const getOrgTypesOfParents = (topOrg, currentOrg) => {
  if (topOrg.id !== currentOrg.parentIds[0]) {
    console.error('Unable to get parent org types', { topOrg, currentOrg })
    throw new Error('Unable to get parent org types')
  }
  const types = currentOrg.parentIds.slice(1).reduce((prev, parentId) => {
    const matchingChild = find(prev.orgs, { id: parentId })
    if (!matchingChild) {
      console.error('Unable to get parent org types for parent', { parentId })
      throw new Error('Unable to get parent org types for parent')
    }
    return { types: prev.types.concat(getOrgType(matchingChild)), orgs: matchingChild.children }
  }, { types: [], orgs: topOrg.children }).types
  return [ getOrgType(topOrg), ...types ]
}

export const getOrgTypesOfChildren = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return []
  }
  const type = map(childOrgs, getOrgType)
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  return type.concat(getOrgTypesOfChildren(children))
}

export const getAllChildIds = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return []
  }
  const ids = map(childOrgs, 'id')
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  return ids.concat(getAllChildIds(children))
}

export const getAllChildrenById = (childOrgs) => {
  if (!childOrgs || !childOrgs.length) {
    return {}
  }
  const children = flatMap(filter(childOrgs, 'children'), 'children')
  const childrenById = keyBy(childOrgs, 'id')
  return { ...childrenById, ...getAllChildrenById(children) }
}
