import to from 'await-to-js'
import { omit } from 'lodash'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import acknowledge from '../reducers/fetch/acknowledge'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import setValueById from '../reducers/fetch/set-value-by-id'

const STORE_PATH_PREFIX = 'userExtendedProfile'

export function saveExtendedProfile ({
  clientId,
  data,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = (data.id) ? HTTP_METHODS.PUT : HTTP_METHODS.POST
  const fetchAction = (data.id) ? FETCH_ACTIONS.PUT : FETCH_ACTIONS.POST
  const url = (data.id) ? `/clients/${clientId}/extendedProfiles/${data.id}` : `/clients/${clientId}/extendedProfiles`
  const actionName = 'saveExtendedProfile'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(data.id), storePath, actionName ])
      const [ err, result ] = await to(fetch(url, {
        data: omit(data, '_meta'),
        method,
        headers,
      }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const [ clientErr, clientResult ] = await to(fetch(`/clientOverviews/${clientId}`, { method: FETCH_ACTIONS.GET, headers }))
      isUnauthorizedError(clientErr) && dispatch(logout({ userInitiated: false }))
      if (clientErr) {
        return dispatch([ requestFailure(err), storePath, `getClientWithOverviewFailure` ])
      }

      dispatch([ setValueById(clientResult), `clients.${FETCH_ACTIONS.GET_ALL}.value`, `getClientWithOverviewUpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeSaveExtendedProfile () {
  return [
    () => async (dispatch) => {
      dispatch([ acknowledge(), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`, 'acknowledgePostExtendedProfile' ])
      dispatch([ acknowledge(), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`, 'acknowledgePutExtendedProfile' ])
    },
  ]
}
