import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  List,
  Icon,
  Header,
  Grid,
  Segment,
  Input,
  Dropdown,
  Message,
  Checkbox,
  Popup,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  reject, sortBy, intersection, map, union,
} from 'lodash'

import '../../common.css'
import './create-room.css'
import { LGBTQ_COURSE_IDS, WELCOME_COURSE_IDS } from '../../../helpers/module-constants'
import CourseSelector from '../../common/course-selector/course-selector'

// const TRANSLATION_PREFIX = 'forms.create-room'

const REASONS = sortBy([ {
  text: 'Prevention',
  value: 'prevention',
  key: 'prevention',
}, {
  text: 'Anger/Fighting',
  value: 'anger_fighting',
  key: 'anger_fighting',
}, {
  text: 'Drug Prevention and Education',
  value: 'substance_issues',
  key: 'substance_issues',
}, {
  text: 'Disruptive Behavior',
  value: 'disruptive_behavior',
  key: 'disruptive_behavior',
}, {
  text: 'Truancy',
  value: 'truancy',
  key: 'truancy',
}, {
  text: 'Tardy/Chronic Absenteeism',
  value: 'tardy_chronic_absenteeism',
  key: 'tardy_chronic_absenteeism',
}, {
  text: 'Inappropriate Peer Behavior',
  value: 'inappropriate_peer_behavior',
  key: 'inappropriate_peer_behavior',
}, {
  text: 'Domestic Issues',
  value: 'domestic_issues',
  key: 'domestic_issues',
}, {
  text: 'Self-Defeating Behaviors',
  value: 'self_defeating_behaviors',
  key: 'self_defeating_behaviors',
}, {
  text: 'Stress',
  value: 'stress',
  key: 'stress',
}, {
  text: 'Depression and Anxiety',
  value: 'depression_anxiety',
  key: 'depression_anxiety',
}, {
  text: 'Bullying/Relational Aggression/Cyberbullying/Technology Issues',
  value: 'bullying_relational_agression_cyber_bullying_technology_issues',
  key: 'bullying_relational_agression_cyber_bullying_technology_issues',
}, {
  text: 'School Disengagement',
  value: 'school_disengagement',
  key: 'school_disengagement',
}, {
  text: 'Gang Affiliation',
  value: 'gang_affiliation',
  key: 'gang_affiliation',
}, {
  text: 'Unhealthy Relationships/Exploitation/Trafficking',
  value: 'unhealthy_relationships_exploitation_trafficking',
  key: 'unhealthy_relationships_exploitation_trafficking',
}, {
  text: 'Cultural Issues',
  value: 'cultural_issues',
  key: 'cultural_issues',
}, {
  text: 'Poor Choices',
  value: 'poor_choices',
  key: 'poor_choices',
}, {
  text: 'LGBTQ',
  value: 'lgbtq',
  key: 'lgbtq',
}, {
  text: 'Suspension/Expulsion',
  value: 'suspension_expulsion',
  key: 'suspension_expulsion',
}, {
  text: 'Getting to Know Student/IEP Transition Questions',
  value: 'getting_to_know_student_iep_transition_questions',
  key: 'getting_to_know_student_iep_transition_questions',
} ], 'text')

const AUDIENCE_TYPES = {
  elementary: 'Elementary School',
  secondary: 'Middle/High School',
  middle: 'Middle School',
  high: 'High School',
  higher_ed: 'Higher Ed.',
  parent: 'Parents/Guardians',
  professional: 'Professional Development',
  adult: 'Adult',
}
export class CreateRoomPage3 extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    courses: PropTypes.arrayOf(PropTypes.object),
    availableCourses: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedCourses: props.courses || [],
      audience: [],
      selectedReasons: [],
      instructionalOnly: false,
      search: '',
    }
  }

  componentDidUpdate (prevProps) {
    const prevCourses = map(prevProps.courses, 'id')
    const nextCourses = map(this.props.courses, 'id')
    if (prevCourses.length !== nextCourses.length || union(prevCourses, nextCourses).length !== prevCourses.length) {
      this.setState({ ...this.state, selectedCourses: this.props.courses })
    }
  }

  render = () => {
    const {
      onChange,
      availableCourses = [],
    } = this.props
    const {
      selectedCourses,
      audience,
      selectedReasons,
      instructionalOnly,
      search,
    } = this.state
    const filteredCourses = availableCourses.filter((course) => {
      const isWelcome = WELCOME_COURSE_IDS.includes(course.id)
      const isMsOrHs = [ 'secondary', 'high', 'middle', 'elementary' ].includes(course.type)
      const isActive = course.isActive
      const isTargetAudience = !audience || !audience.length || audience.includes(course.type)
      const courseTitle = (course.titles) ? course.titles : course.title.en
      const isInstructional = course.isInstructional || courseTitle.toLowerCase().includes('instructional')
      const matchesSearch = (search) ? courseTitle.toLowerCase().includes(search) : true
      return !isWelcome && isMsOrHs && isActive && isTargetAudience && isInstructional === instructionalOnly && matchesSearch
    })
    const suggestedCourses = sortBy(filteredCourses.filter((course) => {
      const isSelected = selectedCourses.some((c) => c.id === course.id)
      const tags = course.tags || course.referralReasons
      const hasMatchingTags = !selectedReasons.length || !!intersection(tags, selectedReasons).length
      return !isSelected && hasMatchingTags
    }), (course) => course.titles || course.title.en)
    // if an org does not have access to any of the lgbtq modules, remove the lgbtq reason
    const orgReasons = (intersection(map(filteredCourses, 'id'), LGBTQ_COURSE_IDS).length) ? REASONS : reject(REASONS, { value: 'lgbtq' })
    return (
      <>
        <Grid divided columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Input
                id='create-room-course-search'
                placeholder='Search for Modules...'
                type='text'
                fluid
                action={{
                  basic: true,
                  size: 'tiny',
                  color: 'grey',
                  icon: 'search',
                  disabled: true,
                }}
                onChange={(e, data) => {
                  this.setState({ ...this.state, search: data.value })
                }}
              />
              <br/>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown
                  id='create-room-course-reasons'
                  placeholder={'Select one or more module categories'}
                  fluid
                  multiple
                  search
                  selection
                  value={selectedReasons}
                  onChange={(e, data) => {
                    this.setState({ ...this.state, selectedReasons: data.value })
                  }}
                  options={orgReasons}
                />
                <Popup
                  hoverable
                  header='Not sure which modules to pick?'
                  content='If you are not sure which modules to recommend, choose a category, and the list of modules below will be updated with those that will help address that specific issue.'
                  position='right center'
                  trigger={(
                    <div
                      style={{
                        flexShrink: 2, fontSize: '1.8em', marginLeft: 10,
                      }}
                    >
                      <Icon className='base-teal' name='info circle'/>
                    </div>
                  )}
                />

              </div>
              <br/>
              <Checkbox
                id='create-room-instructional-courses'
                className='checkbox'
                label={`Use Instructional Modules`}
                checked={!!instructionalOnly}
                value='instructionalOnly'
                onChange={(e, data) => {
                  this.setState({ ...this.state, instructionalOnly: data.checked })
                }}
              />

              {(!suggestedCourses.length) && (
                <Message icon warning size='small'>
                  <Icon name='warning' style={{ fontSize: '2em' }} />
                  <Message.Header>No Modules Left to Select</Message.Header>
                </Message>
              )}
              {(!!suggestedCourses.length) && (
                <Segment className='room-courses-selection'>
                  <List divided selection verticalAlign='middle' className='room-courses-list'>
                    {suggestedCourses.map((course) => {
                      const isDeprecated = CourseSelector.coursePacks.secondary.courseIds.includes(course.id)
                      return (
                        <List.Item
                          style={{ display: 'flex', alignItems: 'center' }}
                          key={`session-course-${course.id}`}
                          onClick={() => {
                            const courses = [ ...selectedCourses, course ]
                            this.setState({ ...this.state, selectedCourses: courses })
                            onChange(courses)
                          }}
                        >
                          <List.Content style={{
                            textAlign: 'center',
                            marginRight: 14,
                          }}>
                            <Icon style={{ fontSize: '1.8em', width: '80px' }} className={(isDeprecated) ? 'base-red fas fa-chalkboard' : 'fas fa-chalkboard'} />
                            <p className={(isDeprecated) ? 'text bold base-red' : 'text bold'} align='center'>{AUDIENCE_TYPES[course.type]}</p>
                          </List.Content>
                          <List.Content>
                            <List.Header>{course.titles || course.title.en} <span className='base-red'>{(isDeprecated) ? ' (This module is being phased out)' : ''}</span></List.Header>
                            <span className='text grey italic'>{course.descriptions || course.description.en}</span>
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                </Segment>
              )}
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' className='selected-room-items-header'>
                <Icon color='yellow' name='star' />
                <Header.Content>Recommended Modules</Header.Content>
              </Header>
              {(!selectedCourses.length) && (
                <Segment placeholder>
                  <Header icon>
                    <Icon className='fas fa-chalkboard' />
                    No modules have been selected.
                  </Header>
                </Segment>
              )}
              {(!!selectedCourses.length) && (
                <Segment className='selected-room-items'>
                  <List
                    divided
                    verticalAlign='middle'
                  >
                    {selectedCourses.map((course) => {
                      const isDeprecated = CourseSelector.coursePacks.secondary.courseIds.includes(course.id)
                      return (
                        <List.Item style={{ display: 'flex', alignItems: 'center' }} key={`room-course-${course.id}`}>
                          <List.Content
                            style={{
                              textAlign: 'center',
                              marginRight: 14,
                            }}>
                            <Icon style={{ fontSize: '1.8em', width: '80px' }} className={(isDeprecated) ? 'base-red fas fa-chalkboard' : 'fas fa-chalkboard'} />
                            <p className={(isDeprecated) ? 'text bold base-red' : 'text bold'} align='center'>{AUDIENCE_TYPES[course.type]}</p>
                          </List.Content>
                          <List.Content>
                            <List.Header>{course.titles || course.title.en} <span className='base-red'>{(isDeprecated) ? ' (This module is being phased out)' : ''}</span></List.Header>
                            <List.Description>{course.descriptions || course.description.en}</List.Description>
                          </List.Content>
                          <div style={{ marginLeft: 6 }}>
                            <Button
                              size='mini'
                              icon
                              color='red'
                              title='Remove Module'
                              onClick={() => {
                                const courses = reject(selectedCourses, { id: course.id })
                                this.setState({ selectedCourses: courses })
                                onChange(courses)
                              }}
                            >
                              <Icon name='remove'/>
                            </Button>
                          </div>
                        </List.Item>
                      )
                    })}
                  </List>
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

export default translate([ 'components' ])(CreateRoomPage3)
