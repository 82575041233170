import gql from 'graphql-tag'

const GET_AGGREGATED_ORG_ACTIVITY = gql`
query aggregatedOrgActivity($startDate: String!, $endDate: String!, $orgIds: [String], $courseIds: [String], $roleTypes: [String], $groupAllCourses: Boolean) {
  aggregatedOrgActivity(
    startDate: $startDate
    endDate: $endDate
    orgIds: $orgIds
    courseIds: $courseIds
    roleTypes: $roleTypes
    groupAllCourses: $groupAllCourses
  ) {
      orgId
      orgName
      totalSeconds
      roleType
      courseId
      courseName
      startedCount
      progressCount
      completedCount
  }
}
`

export default GET_AGGREGATED_ORG_ACTIVITY
