import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'semantic-ui-react'
import { without } from 'lodash'

import './checkbox-group.css'

const TYPE_CONVERTERS = {
  number: (val) => val * 1,
  boolean: (val) => (val === 'true'),
  string: (val) => val,
}

// This is intended to be a controlled component
// https://reactjs.org/docs/forms.html#controlled-components
class CheckboxGroup extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
    })),
    selectedValues: PropTypes.arrayOf(PropTypes.any),
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
    toggle: PropTypes.bool,
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    onFocus: console.log.bind(console, 'onFocus'),
    onBlur: console.log.bind(console, 'onBlur'),
    options: [],
    selectedValues: [],
    vertical: false,
    disabled: false,
    toggle: false,
  }

  constructor (props) {
    super(props)
    this.state = { idPrefix: Math.random().toString() }
  }

  handleCheckboxChanged = (event, data) => {
    const { selectedValues, onChange } = this.props
    const [ , type, value ] = data.id.split('|')
    const val = TYPE_CONVERTERS[type](value)
    if (data.checked) {
      onChange(selectedValues.concat([ val ]))
    } else {
      onChange(without(selectedValues, val))
    }
  }

  renderCheckbox = ({
    value,
    label,
    subLabel,
  }) => {
    const {
      selectedValues,
      vertical,
      onBlur,
      onFocus,
      disabled,
      toggle,
    } = this.props
    const className = (vertical) ? 'checkbox-group-vertical' : 'checkbox-group'
    const labelElement = (
      <label>
        <span>{label}</span>
        {subLabel && <span className='subLabel'>{subLabel}</span>}
      </label>
    )
    return (
      <Checkbox
        className={className}
        key={`${this.state.idPrefix}|${typeof (value)}|${value}`}
        id={`${this.state.idPrefix}|${typeof (value)}|${value}`}
        label={labelElement}
        disabled={disabled}
        checked={selectedValues.includes(value)}
        onChange={this.handleCheckboxChanged}
        onBlur={onBlur}
        onFocus={onFocus}
        toggle={toggle}
      />
    )
  }

  render () {
    const { options } = this.props
    return (
      <React.Fragment>
        {options.map(this.renderCheckbox)}
      </React.Fragment>
    )
  }
}

export default CheckboxGroup
