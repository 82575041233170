import React from 'react'
import PropTypes from 'prop-types'
import {
  Form as SemanticForm,
  Button,
  Icon,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { xor } from 'lodash'

import BaselineSelector from './baseline-selector'

// import './baseline-selector.css'
const BaselineForm = ({
  assessmentsEnabled,
  baselineAnalysisEnabled,
  baselineRecommendationsEnabled,
  alternativeAssessmentEnabled,
  onSave,
  isSaving,
  saveError,
  t,
}) => {
  const baselineSelections = []
  assessmentsEnabled && baselineSelections.push('assessments')
  baselineAnalysisEnabled && baselineSelections.push('insights')
  baselineRecommendationsEnabled && baselineSelections.push('recommendations')
  alternativeAssessmentEnabled && baselineSelections.push('alternatives')
  return (
    (
      <Form
        subscription={{
          pristine: true,
          invalid: true,
          validating: true,
        }}
        onSubmit={(data) => {
          onSave({
            assessmentsEnabled: data.baselineSelections && data.baselineSelections.includes('assessments'),
            baselineAnalysisEnabled: data.baselineSelections && data.baselineSelections.includes('insights'),
            baselineRecommendationsEnabled: data.baselineSelections && data.baselineSelections.includes('recommendations'),
            alternativeAssessmentEnabled: data.baselineSelections && data.baselineSelections.includes('alternatives'),
          })
        }}
        initialValues={{
          baselineSelections,
        }}
        disableWarning={false}
        resetOnSubmit={false}
        render={({
          handleSubmit,
          pristine,
          invalid,
          validating,
        }) => {
          return (
            <SemanticForm onSubmit={handleSubmit} loading={isSaving}>
              {(!!saveError) && (
                <Message icon negative>
                  <Icon name='exclamation' />
                  <Message.Header data-public>Error</Message.Header>
                  <p data-public>There was a problem saving the Baseline changes.</p>
                  <p data-public>{saveError.message}</p>
                </Message>
              )}
              <Field
                name='baselineSelections'
                isEqual={(a, b) => !xor(a, b).length}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <BaselineSelector
                    onChange={(selections) => input.onChange(selections)}
                    selections={input.value}
                    isSaving={isSaving}
                  />
                )}
              />

              <br/>
              <Button
                type='submit'
                floated='right'
                loading={isSaving}
                color='green'
                disabled={isSaving || pristine || invalid || validating}
              >
                {t('forms.save_button')}
              </Button>
              <br style={{ clear: 'both' }}/>
            </SemanticForm>
          )
        }}
      />
    )
  )
}

BaselineForm.propTypes = {
  assessmentsEnabled: PropTypes.bool.isRequired,
  baselineAnalysisEnabled: PropTypes.bool.isRequired,
  baselineRecommendationsEnabled: PropTypes.bool.isRequired,
  alternativeAssessmentEnabled: PropTypes.bool.isRequired,
  validateUserName: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  saveError: PropTypes.object,
  t: PropTypes.func,
}

BaselineForm.defaultProps = {
  isSaving: false,
  onSave: console.log.bind(console, 'onSave'),
}

export default translate([ 'components' ])(BaselineForm)
