
export const INTERNAL_ROLE_TYPES = {
  SUPER_ADMIN: 'superAdmin',
  INTERNAL_ADMIN: 'internalAdmin',
  ACCOUNT_MANAGER: 'accountManager',
  VIEWER: 'viewer',
}

export const EXTERNAL_ROLE_TYPES = {
  ADMIN: 'admin',
  SUPERVISOR: 'supervisor',
  ADVISOR: 'advisor',
  STUDENT: 'student',
  ADULT: 'adult',
}

export const ROLE_TYPES = {
  ...INTERNAL_ROLE_TYPES,
  ...EXTERNAL_ROLE_TYPES,
}

export const ROLE_VALUES = {
  [INTERNAL_ROLE_TYPES.SUPER_ADMIN]: 10,
  [INTERNAL_ROLE_TYPES.INTERNAL_ADMIN]: 5,
  [INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER]: 4,
  [INTERNAL_ROLE_TYPES.VIEWER]: 0,
  [EXTERNAL_ROLE_TYPES.ADMIN]: 3,
  [EXTERNAL_ROLE_TYPES.SUPERVISOR]: 2,
  [EXTERNAL_ROLE_TYPES.ADVISOR]: 2,
  [EXTERNAL_ROLE_TYPES.STUDENT]: 0,
  [EXTERNAL_ROLE_TYPES.ADULT]: 0,
}

const getRoles = ({ externalOnly = false } = {}) => {
  const allRoles = [
    {
      type: ROLE_TYPES.SUPER_ADMIN,
      isInternal: true,
      name: 'Super Admin',
      description: 'A Super Admin has access to everything within their organization and all suborganizations, including being able to modify any data for any user. They also have access to create and modify modules in our system.',
    },
    {
      type: ROLE_TYPES.INTERNAL_ADMIN,
      isInternal: true,
      name: 'Internal Admin',
      description: 'An Internal Admin has access to everything within their organization and all suborganizations, including being able to modify any data for any user.',
    },
    {
      type: ROLE_TYPES.ACCOUNT_MANAGER,
      isInternal: true,
      name: 'Account Manager',
      description: 'An Account Manager has access to all users within their organization and all suborganizations. Account Managers can also manage organization details and student licenses.',
    },
    {
      type: ROLE_TYPES.VIEWER,
      isInternal: true,
      name: 'Viewer',
      description: 'A Viewer has read-only access to all users within their organization and all suborganizations. Viewers cannot make any modifications to anything in BASE, including their own account',
    },
    {
      type: ROLE_TYPES.ADMIN,
      isInternal: false,
      name: 'Admin',
      description: 'Admins manage all aspects of the BASE Education program within an organization. They can create and manage ALL user types, and they can view Student responses and can review all Fireword submissions from the Module Progress page.',
    },
    {
      type: ROLE_TYPES.SUPERVISOR,
      isInternal: false,
      name: 'Supervisor',
      description: 'Supervisors manage all student learners within an organization. They can create and manage Student users only. They can review Fireword submissions from the Firewords to Review or Module Progress pages.',
    },
    {
      type: ROLE_TYPES.ADVISOR,
      isInternal: false,
      name: 'Advisor',
      description: 'Advisors can view all student learners within an organization. They can only manage Student enrollments and not their account / profile information.',
    },
    {
      type: ROLE_TYPES.STUDENT,
      isInternal: false,
      name: 'Student',
      description: 'Students can launch modules and send Help requests. Students may also be restricted to certain times and days of the week.',
    },
    {
      type: ROLE_TYPES.ADULT,
      isInternal: false,
      name: 'Parent/Guardian',
      description: 'Parent/Guardians can launch modules and have 24/7 access.',
    },
  ]
  return allRoles.filter((role) => !externalOnly || (externalOnly && Object.values(EXTERNAL_ROLE_TYPES).includes(role.type)))
}

export default getRoles
