import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Header,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { map, union } from 'lodash'

import Page3 from './create-room-page-3'

import '../../common.css'

export class RoomCoursesModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    courses: PropTypes.array,
    availableCourses: PropTypes.array,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    isSaving: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      courses: props.courses,
      courseIds: map(props.courses, 'id'),
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.props.courses || !this.props.courses.length) {
      return
    }
    const prevCourses = map(prevProps.courses, 'id')
    const nextCourses = map(this.props.courses, 'id')
    if (prevCourses.length !== nextCourses.length || union(prevCourses, nextCourses).length !== prevCourses.length) {
      this.setState({
        ...this.state,
        courses: this.props.courses,
        courseIds: nextCourses,
      })
    }
  }

  render () {
    const {
      isSaving,
      onSave,
      open,
      onClose,
      availableCourses,
      t,
    } = this.props
    const {
      courses,
      courseIds,
    } = this.state
    return (
      <Modal
        className='create-room-modal'
        closeOnDimmerClick={false}
        open={open}
        onClose={onClose}
      >
        <Modal.Content>
          <Header>Add/Remove Modules</Header>
          <Page3
            onChange={(d) => {
              this.setState({
                courses: d,
                courseIds: map(d, 'id'),
              })
            }}
            courses={courses}
            availableCourses={availableCourses}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            onClick={onClose}
          >
            {t('forms.cancel_button')}
          </Button>
          <Button
            content={t('forms.save_button')}
            labelPosition='right'
            loading={isSaving}
            icon='checkmark'
            color='green'
            onClick={() => onSave(courseIds)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default translate([ 'components' ])(RoomCoursesModal)
