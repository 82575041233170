import gql from 'graphql-tag'

export const NAME = 'deleteRooms'
const DELETE_ROOMS = gql`
mutation ${NAME}($ids: [ID!]) {
  ${NAME}(ids: $ids)
}
`

export default DELETE_ROOMS
