import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Header,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { map, union } from 'lodash'

import Page2 from './create-room-page-2'

import '../../common.css'

export class RoomStudentsModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    searchResults: PropTypes.arrayOf(PropTypes.object),
    isSearching: PropTypes.bool,
    open: PropTypes.bool,
    canCreateStudents: PropTypes.bool,
    onSearchChange: PropTypes.func.isRequired,
    onCreateStudent: PropTypes.func.isRequired,
    validateUserName: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    students: PropTypes.array,
    orgNamesById: PropTypes.object.isRequired,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    isSaving: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      students: props.students,
      studentIds: map(props.students, 'id'),
    }
  }

  componentDidUpdate (prevProps) {
    const prevStudents = map(prevProps.students, 'id')
    const nextStudents = map(this.props.students, 'id')
    if (prevStudents.length !== nextStudents.length || union(prevStudents, nextStudents).length !== prevStudents.length) {
      this.setState({
        ...this.state,
        students: this.props.students,
        studentIds: map(this.props.students, 'id'),
      })
    }
  }

  render () {
    const {
      isSaving,
      isSearching,
      searchResults,
      onSearchChange,
      onCreateStudent,
      validateUserName,
      onSave,
      open,
      onClose,
      canCreateStudents,
      orgNamesById,
      t,
    } = this.props
    const {
      students,
      studentIds,
    } = this.state
    return (
      <Modal
        className='create-room-modal'
        closeOnDimmerClick={false}
        open={open}
        onClose={onClose}
      >
        <Modal.Content>
          <Header>Add/Remove Students</Header>
          <Page2
            onChange={(d) => {
              this.setState({
                students: d,
                studentIds: map(d, 'id'),
              })
            }}
            orgNamesById={orgNamesById}
            students={students}
            isSearching={isSearching}
            searchResults={searchResults}
            onSearchChange={onSearchChange}
            onCreateStudent={onCreateStudent}
            validateUserName={validateUserName}
            canCreateStudents={canCreateStudents}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            onClick={onClose}
          >
            {t('forms.cancel_button')}
          </Button>
          <Button
            content={t('forms.save_button')}
            labelPosition='right'
            loading={isSaving}
            icon='checkmark'
            color='green'
            onClick={() => onSave(studentIds)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default translate([ 'components' ])(RoomStudentsModal)
