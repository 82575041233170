import React, {
  useState, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Form,
  Icon,
  Container,
  Dropdown,
  Segment,
  Modal,
  Header,
  Tab,
  Menu,
  Label,
  Message,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  sortBy, intersection, without, get, reject, groupBy, find,
} from 'lodash'
import { Player } from 'video-react'

import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import CourseCard from '../../../common/course-card/course-card'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import exportToCsv from '../../../../helpers/export-to-csv'
import {
  createCourse,
  invalidateCourses,
} from '../../../../actions/courses'
import { getAllContentItems, invalidateContentItems } from '../../../../actions/content-items'
import Query from '../../../common/query/query'
import Mutation from '../../../common/mutation/mutation'
import GET_COURSES from '../../../../helpers/graphql-queries/get-courses'
import PUT_ACTIVITY from '../../../../helpers/graphql-queries/put-activity'
import CourseModal from '../../../common/course-modal'
import { LGBTQ_COURSE_IDS, WELCOME_COURSE_IDS } from '../../../../helpers/module-constants'

import 'video-react/dist/video-react.css'
import './courses.css'

const dropdownLanguageOptions = [
  { text: 'None', value: 'en' },
  { text: 'English', value: 'en_sub' },
  { text: 'Español (Spanish)', value: 'es_sub' },
  { text: 'عربى (Arabic)', value: 'ar_sub' },
  { text: 'Français (French)', value: 'fr_sub' },
  { text: '한국어 (Korean)', value: 'ko_sub' },
  { text: '简化字 (Chinese - Simplified)', value: 'zh_sub' },
  { text: 'हिंदी (Hindi)', value: 'hi_sub' },
  { text: 'украї́нська мо́ва (Ukrainian)', value: 'uk_sub' },
  { text: 'Русский язык  (Russian)', value: 'ru_sub' },
  { text: 'Polski (Polish)', value: 'pl_sub' },
  { text: 'فارسی (Persian / Farsi)', value: 'fa_sub' },
  { text: 'ਪੰਜਾਬੀ (Punjabi)', value: 'pa_sub' },
]

const REASONS = sortBy([ {
  text: 'Prevention',
  value: 'prevention',
  key: 'prevention',
}, {
  text: 'Anger/Fighting',
  value: 'anger_fighting',
  key: 'anger_fighting',
}, {
  text: 'Drug Prevention and Education',
  value: 'substance_issues',
  key: 'substance_issues',
}, {
  text: 'Disruptive Behavior',
  value: 'disruptive_behavior',
  key: 'disruptive_behavior',
}, {
  text: 'Truancy',
  value: 'truancy',
  key: 'truancy',
}, {
  text: 'Tardy/Chronic Absenteeism',
  value: 'tardy_chronic_absenteeism',
  key: 'tardy_chronic_absenteeism',
}, {
  text: 'Inappropriate Peer Behavior',
  value: 'inappropriate_peer_behavior',
  key: 'inappropriate_peer_behavior',
}, {
  text: 'Domestic Issues',
  value: 'domestic_issues',
  key: 'domestic_issues',
}, {
  text: 'Self-Defeating Behaviors',
  value: 'self_defeating_behaviors',
  key: 'self_defeating_behaviors',
}, {
  text: 'Stress',
  value: 'stress',
  key: 'stress',
}, {
  text: 'Depression and Anxiety',
  value: 'depression_anxiety',
  key: 'depression_anxiety',
}, {
  text: 'Bullying/Relational Aggression/Cyberbullying/Technology Issues',
  value: 'bullying_relational_agression_cyber_bullying_technology_issues',
  key: 'bullying_relational_agression_cyber_bullying_technology_issues',
}, {
  text: 'School Disengagement',
  value: 'school_disengagement',
  key: 'school_disengagement',
}, {
  text: 'Gang Affiliation',
  value: 'gang_affiliation',
  key: 'gang_affiliation',
}, {
  text: 'Unhealthy Relationships/Exploitation/Trafficking',
  value: 'unhealthy_relationships_exploitation_trafficking',
  key: 'unhealthy_relationships_exploitation_trafficking',
}, {
  text: 'Cultural Issues',
  value: 'cultural_issues',
  key: 'cultural_issues',
}, {
  text: 'Poor Choices',
  value: 'poor_choices',
  key: 'poor_choices',
}, {
  text: 'LGBTQ',
  value: 'lgbtq',
  key: 'lgbtq',
}, {
  text: 'Suspension/Expulsion',
  value: 'suspension_expulsion',
  key: 'suspension_expulsion',
}, {
  text: 'Getting to Know Student/IEP Transition Questions',
  value: 'getting_to_know_student_iep_transition_questions',
  key: 'getting_to_know_student_iep_transition_questions',
} ], 'text')

const SELWEB_DOMAINS = sortBy([ {
  text: 'Emotion Recognition',
  value: 'selweb:emotion_recognition',
  key: 'selweb:emotion_recognition',
}, {
  text: 'Social Perspective-Taking',
  value: 'selweb:social_perspective_taking',
  key: 'selweb:social_perspective_taking',
}, {
  text: 'Social Problem-Solving',
  value: 'selweb:social_problem_solving',
  key: 'selweb:social_problem_solving',
}, {
  text: 'Self-Control',
  value: 'selweb:self_control',
  key: 'selweb:self_control',
}, {
  text: 'Understanding Others',
  value: 'selweb:understanding_others',
  key: 'selweb:understanding_others',
} ], 'text')

const TYPES = sortBy([ {
  text: 'Wellness',
  value: 'wellness',
  key: 'wellness',
}, {
  text: 'Elementary',
  value: 'elementary',
  key: 'elementary',
}, {
  text: 'Middle School',
  value: 'middle',
  key: 'middle',
}, {
  text: 'High School',
  value: 'high',
  key: 'high',
}, {
  text: 'Middle/High School',
  value: 'secondary',
  key: 'z_secondary', // make this show up last in the order of the tabs
}, {
  text: 'Parent/Guardian',
  value: 'parent',
  key: 'parent',
}, {
  text: 'Higher Education',
  value: 'higher_ed',
  key: 'higher_ed',
}, {
  text: 'Professional Development',
  value: 'professional',
  key: 'professional',
} ], 'key')

const SA_DEFAULT_COLOR = 'darkblue'
const SA_COURSE_COLORS = {
  'drug facts': 'brown',
  'drugs and the mind': 'darkorange',
  'drugs and the body': 'gold',
  'drugs and relationships': '#8807bf',
  'overcoming drugs': '#b3196f',
  'living drug-free': '#0091d6',
}

const CoursesView = ({
  authState: {
    roleType,
    accessToken,
    refreshToken,
    userId,
  },
  organization,
  onCourseClick,
  createCourse,
  invalidateCourses,
  t,
  getCoursesState: {
    value,
    error,
    isLoading,
  },
  showFriendlyApiErrorMessages,
  getContentItemsState,
  getAllContentItems,
  invalidateContentItems,
}) => {
  const videoPlayerRef = useRef()
  const [ filter, setFilter ] = useState('')
  const [ selectedReasons, setSelectedsReasons ] = useState([])
  const [ selectedSelwebDomains, setSelectedSelwebDomains ] = useState([])
  const [ launchCourseId, setLaunchCourseId ] = useState(null)
  const [ printCourseId, setPrintCourseId ] = useState(null)
  const [ putActivityCourseId, setPutActivityCourseId ] = useState(null)
  const [ subtitleLanguage, setSubtitleLanguage ] = useState()
  const [ isVideo, setIsVideo ] = useState(false)
  const [ activeTabIndex, setActiveTabIndex ] = useState(0)
  useEffect(() => {
    if (printCourseId && !getContentItemsState.isLoading && getContentItemsState.isStale) {
      getAllContentItems({ accessToken, courseId: printCourseId })
    }
  }, [ printCourseId, getContentItemsState, getAllContentItems, accessToken ])

  const setFilterText = (e, data) => {
    setFilter(data.value.toLowerCase())
  }

  const filterCourses = (course) => {
    const matchesTitle = (!filter) ? true : (course.titles) ? course.titles.toLowerCase().includes(filter) : course.title.en.toLowerCase().includes(filter)
    const matchesDescription = (!filter) ? true : (course.descriptions) ? course.descriptions.toLowerCase().includes(filter) : course.description.en.toLowerCase().includes(filter)
    const matchesCategory = !selectedReasons.length || intersection(course.referralReasons || course.tags, selectedReasons).length > 0
    const matchesSelwebCategory = !selectedSelwebDomains.length || intersection(course.referralReasons || course.tags, selectedSelwebDomains).length > 0
    return (matchesSelwebCategory && matchesCategory && (matchesTitle || matchesDescription))
  }

  const renderCourse = (course) => {
    const canEditCourse = (roleType === 'superAdmin' || roleType === 'courseEditor')
    const title = course.titles || course.title.en
    const lowerCaseTitle = title.toLowerCase()
    const isSubstanceAbuse = lowerCaseTitle.includes('substance use and misuse')
    const substanceAbuseCategory = (isSubstanceAbuse) ? lowerCaseTitle.replace(/substance use and misuse: (section \d\d ?- ?)?/, '').split(' - ')[0] : null
    const colorBar = (substanceAbuseCategory) ? SA_COURSE_COLORS[substanceAbuseCategory] || SA_DEFAULT_COLOR : null
    const categories = (course.referralReasons || course.tags)
    const relevantCategories = (intersection(organization.courseIds, LGBTQ_COURSE_IDS).length) ? categories : without(categories, 'lgbtq')
    return (
      <CourseCard
        key={course.id}
        id={course.id}
        isDeprecated={course.type === 'secondary'}
        colorBar={colorBar}
        title={title}
        description={course.descriptions || course.description.en}
        showProgress={false}
        isLoading={printCourseId === course.id}
        isActive={course.isActive}
        imageUrl={course.imageUrl || 'https://media.base.education/img/base-7m-logo-color-no-solution.png'}
        videoUrl={course.videoUrl && course.videoUrl.en}
        guides={course.guides && course.guides.map((guide) => ({
          name: guide.name.en,
          url: guide.url.en,
        }))}
        categories={relevantCategories}
        audience={course.type}
        onManage={onCourseClick}
        onPrint={(cId) => setPrintCourseId(cId)}
        onLaunch={(cId, isVideo) => {
          setLaunchCourseId(cId)
          setIsVideo(isVideo)
        }}
        canManage={(!organization.sessionsEnabled && !organization.roomsEnabled) || canEditCourse}
        canLaunch={course.type !== 'secondary'}
        canPrint={!!course.contentItems && !!course.contentItems.length}
        instructionalId={course.instructionalId}
        interactiveId={course.interactiveId}
      />
    )
  }

  const onDownloadClick = () => {
    const courses = (!value) ? [] : Object.values(value)
    const filteredCourses = courses.filter(filterCourses)
    const sortedCourses = sortBy(filteredCourses, [ 'titles' ])
    const date = new Date().toISOString().split('T')[0]
    exportToCsv(`BASE-course-descriptions-${date}-${sortedCourses.length}.csv`, sortedCourses, [ 'id', 'titles', 'descriptions', 'type', 'isInstructional' ])
  }

  const onCreateClick = () => {
    var title = window.prompt('What is the title of this new module?')
    if (!title) {
      window.alert('Cancelling')
      return
    }
    var description = window.prompt('What is the description of this new module?')
    if (!description) {
      window.alert('Cancelling')
      return
    }
    createCourse({
      data: {
        titles: { en: title },
        descriptions: { en: description },
        referralReasons: [],
        isActive: true,
        imageUrl: 'https://media.base.education/img/base-7m-logo-color-no-solution.png',
        type: 'secondary',
        isInstructional: false,
        guides: [],
      },
      orgId: organization.id,
      accessToken,
    })
    setTimeout(() => invalidateCourses(), 100)
    // TODO: figure out how to update the org cache to add the module to the org as well
  }

  const cisById = get(getContentItemsState, 'value', {}) || {}
  const cis = sortBy(Object.values(cisById), 'ordinal')
  const printCourse = (printCourseId && value) ? value[printCourseId] : null
  if (!getContentItemsState.isLoading && !getContentItemsState.error && !isLoading && !error && !!printCourse && !!cis.length && cis[0]._meta.parent === `/courses/${printCourse.id}`) {
    const newWin = window.frames['printf']
    let content = '<body><h3>' + printCourse.titles + '</h3><ul>'
    content += cis.map((ci) => {
      const titles = (ci.titles && !!ci.titles.length) ? '<h4 style="margin-top:0px;margin-bottom:5px;">' + ci.titles.join('<br/>') + '</h4>' : ''
      const bodies = (ci.bodies && !!ci.bodies.length) ? '<p style="margin-top:0px; padding-top:0px;">' + ci.bodies.join('<br/>') + '</p>' : ''
      const notes = (ci.notes && ci.notes.en && roleType !== 'student' && roleType !== 'adult') ? '<p style="font-style: italic; color: #767676;">' + ((ci.notes.en.match(/[A-Z ]{2,}:/g)) ? ci.notes.en.match(/[A-Z ]{2,}:/g).map((n, i) => { const secondParts = ci.notes.en.split(/[A-Z ]{2,}:/).filter((r) => !!r); return n + ((secondParts[i]) ? secondParts[i] : '') }).join('<br/><br/>') : ci.notes.en) + '</p>' : ''
      const viewContainer = ci.view.container
      const isFileRef = (viewContainer.indexOf('.jpg') >= 0 || viewContainer.indexOf('.png') >= 0 || viewContainer.indexOf('.jpeg') >= 0 || viewContainer.indexOf('.svg') >= 0)
      const img = (isFileRef) ? viewContainer : without(viewContainer.split(' '), 'c', 'n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw')[0].replace(/^(\d)/, 'a$1')
      const fileExt = (img === 'grumpy-cat') ? 'png' : 'jpg'
      return '<div style="break-inside: avoid; display:flex; padding-bottom:10px;margin-bottom:10px; border-bottom:solid 1px #999;"><div style="align-self:start; margin-right:10px;"><h4 style="margin-top:0px;">' + (ci.ordinal + 1) + ')</h4></div><div style="margin-right:10px"><img style="min-width:200px;max-width:200px; max-height:140px;" src="https://course.base.education/img/' + ((isFileRef) ? img : img + '.' + fileExt) + '" /></div><div>' + titles + bodies + notes + '</div></div>'
    }).join(' ')
    content += '</body>'

    newWin.document.write(content)
    newWin.onload = () => {
      newWin.print()
      setPrintCourseId(null)
      invalidateContentItems()
    }
    newWin.document.close()
  }
  if (!organization) {
    return null
  }
  if (error) {
    return (
      <Container>
        <FetchResultMessage
          success={false}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      </Container>
    )
  }
  if (isLoading) {
    return (<FullScreenLoadingOverlay isActive={isLoading}/>)
  }
  return (
    <Query
      variables={{
        type: 'wellness',
        isActive: true,
        first: 100,
      }}
      query={GET_COURSES}
    >
      {({
        loading: wellnessCoursesLoading,
        data: wellnessCourseData,
        error: wellnessCoursesError,
      }) => {
        if (wellnessCoursesLoading) {
          return (<FullScreenLoadingOverlay isActive={wellnessCoursesLoading}/>)
        }
        if (wellnessCoursesError) {
          console.error('There was a problem loading new courses')
        }
        const wellnessCourses = get(wellnessCourseData, 'coursesByType.courses', []) || []
        return (
          <Query
            variables={{
              type: 'elementary',
              isActive: true,
              first: 100,
            }}
            query={GET_COURSES}
          >
            {({
              loading,
              data,
              error: newCoursesError,
            }) => {
              if (loading) {
                return (<FullScreenLoadingOverlay isActive={loading}/>)
              }
              if (newCoursesError) {
                console.error('There was a problem loading new courses')
              }
              const newCourses = get(data, 'coursesByType.courses', []) || []
              const courses = newCourses.concat(wellnessCourses).concat((!value) ? [] : Object.values(value))
              const orgCourses = courses.filter((course) => organization.courseIds.includes(course.id))
              const middleAndHighWelcomeCourse = orgCourses.find((course) => WELCOME_COURSE_IDS.includes(course.id) && course.type === 'secondary')
              const coursesByTypeAndTitle = groupBy(orgCourses, (c) => `${c.type}<>${c.titles || c.title.en}`.replace(' (Instructional Version)', ''))

              // HACK to get the welcome module to show up in both MS and HS lists (if the org has other modules in that list)
              if (find(orgCourses, { type: 'middle' })) {
                coursesByTypeAndTitle['middle<>Welcome Module'] = [ { ...middleAndHighWelcomeCourse, type: 'middle' } ]
              }
              if (find(orgCourses, { type: 'high' })) {
                coursesByTypeAndTitle['high<>Welcome Module'] = [ { ...middleAndHighWelcomeCourse, type: 'high' } ]
              }
              // Hack to not display the welcome module in the combined MS/HS list
              delete coursesByTypeAndTitle['secondary<>Welcome Module']
              const normalizedCourses = Object.values(coursesByTypeAndTitle).reduce((accum, groupedCourses) => {
                return [ ...accum, {
                  ...groupedCourses[0],
                  instructionalId: (find(groupedCourses, { isInstructional: true }) || {}).id,
                  interactiveId: (find(groupedCourses, { isInstructional: false }) || {}).id,
                } ]
              }, [])

              const filterText = t('search', { postProcess: 'titleCase' })
              const coursesText = t('resource_types.course', { count: 2, postProcess: 'titleCase' })
              const canEditCourse = (roleType === 'superAdmin' || roleType === 'courseEditor')
              const launchedVideoCourse = (launchCourseId && isVideo) ? [ ...newCourses, ...wellnessCourses, ...orgCourses ].find((course) => course.id === launchCourseId) : null
              const tzOffset = new Date().toString().split('GMT')[1].split(' ')[0]
              // if an org does not have access to any of the lgbtq modules, remove the lgbtq reason
              const orgReasons = (intersection(organization.courseIds, LGBTQ_COURSE_IDS).length) ? REASONS : reject(REASONS, { value: 'lgbtq' })
              const allCoursesByType = groupBy(normalizedCourses, 'type')
              const filteredCoursesByType = groupBy(normalizedCourses.filter(filterCourses), 'type')
              const visibleTypes = TYPES.filter((type) => !!allCoursesByType[type.value] && !!allCoursesByType[type.value].length)
              const selectedType = get(visibleTypes, `${activeTabIndex}.value`)
              return (
                <>
                  <Modal
                    closeIcon
                    open={!!launchCourseId && !!launchedVideoCourse}
                    onClose={() => {
                      setLaunchCourseId(null)
                      setPutActivityCourseId(null)
                    }}
                  >
                    <Header style={{ flexGrow: 1, margin: 0 }} data-public icon={{ name: 'video', className: 'base-teal' }} content={(launchedVideoCourse) ? get(launchedVideoCourse, 'title.en', launchedVideoCourse.titles) : 'Loading...'} />
                    <Modal.Content onContextMenu={(e) => e.preventDefault()} className='base-video'>
                      <Mutation
                        mutation={PUT_ACTIVITY}
                        variables={{
                          activity: {
                            clientId: userId,
                            roleType: roleType,
                            orgId: organization.id,
                            courseType: 'video',
                            courseId: launchCourseId,
                            launches: 1,
                          },
                          timeZoneOffset: tzOffset,
                        }}

                      >
                        {(putActivity) => {
                          return (
                            <Player
                              ref={(player) => { videoPlayerRef.current = player }}
                              playsInline
                              poster={(launchedVideoCourse) ? launchedVideoCourse.imageUrl : ''}
                              src={(launchedVideoCourse) ? launchedVideoCourse.videoUrl[subtitleLanguage || 'en'] : ''}
                              preload='auto'
                              onTimeUpdate={async () => {
                                if (putActivityCourseId !== launchCourseId) {
                                  const timeRange = videoPlayerRef.current.video.props.player.played
                                  if (timeRange.length && timeRange.end(timeRange.length - 1) > timeRange.start(timeRange.length - 1) + 10) { // must play 10 continuous seconds to log
                                    putActivity()
                                    setPutActivityCourseId(launchCourseId)
                                  }
                                }
                              }}
                            />
                          )
                        }}
                      </Mutation>
                      <div>
                        {'Subtitles:'}
                        <Dropdown
                          scrolling
                          selection
                          defaultValue={'en'}
                          value={subtitleLanguage}
                          style={{ margin: 10 }}
                          onChange={(e, data) => {
                            setSubtitleLanguage(data.value)
                            this.forceUpdate()
                          }}
                          options={dropdownLanguageOptions.filter((langOpt) => !!get(launchedVideoCourse, `videoUrl[${langOpt.value}]`))}
                        />
                      </div>
                    </Modal.Content>
                  </Modal>
                  <CourseModal
                    accessToken={accessToken}
                    refreshToken={refreshToken}
                    open={!!launchCourseId && !launchedVideoCourse}
                    onClose={() => setLaunchCourseId(null)}
                    id={launchCourseId}
                  />
                  <Segment>
                    <Form>
                      <Form.Group>
                        <Form.Field width={(canEditCourse) ? 12 : 14}>
                          <Form.Input icon='search' autoFocus data-public fluid placeholder={`${filterText} ${coursesText}`} onChange={setFilterText} />
                        </Form.Field>
                        <Form.Field width={2}>
                          <Form.Button type='button' data-public floated='right' icon onClick={onDownloadClick} title='Download Course Descriptions'>
                            <Icon name='download'/>
                          </Form.Button>
                        </Form.Field>
                        {canEditCourse && (
                          <Form.Field width={2}>
                            <Form.Button type='button' data-public floated='right' color='blue' icon onClick={onCreateClick} title='Create New Course'>
                              <Icon name='plus'/>
                            </Form.Button>
                          </Form.Field>
                        )}
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Field>
                          <label>Categories</label>
                          <Dropdown
                            data-public
                            placeholder={'Filter Modules By Categories'}
                            fluid
                            multiple
                            search
                            selection
                            value={selectedReasons}
                            onChange={(e, input) => setSelectedsReasons(input.value)}
                            options={orgReasons}
                          />
                        </Form.Field>
                        {(selectedType === 'elementary') && (
                          <Form.Field>
                            <label>Assessment Competencies</label>
                            <Dropdown
                              data-public
                              placeholder={'Filter Modules By Assessment Competencies'}
                              fluid
                              multiple
                              search
                              selection
                              value={selectedSelwebDomains}
                              onChange={(e, input) => setSelectedSelwebDomains(input.value)}
                              options={SELWEB_DOMAINS}
                            />
                          </Form.Field>
                        )}
                      </Form.Group>
                    </Form>
                  </Segment>
                  <Tab
                    className='courses-tabs'
                    menu={{ secondary: true, size: 'small' }}
                    renderActiveOnly
                    activeIndex={activeTabIndex}
                    onTabChange={(e, data) => {
                      if (data.activeIndex !== activeTabIndex) {
                        setActiveTabIndex(data.activeIndex)
                        setSelectedSelwebDomains([])
                      }
                    }}
                    panes={visibleTypes.map((type) => ({
                      menuItem: (
                        <Menu.Item data-public key={`module-types-${type.value}`}>
                          <span className={(type.value === 'secondary') ? 'base-red' : ''}>{type.text}</span><Label size='mini' circular className='base-teal-bg'>{get(filteredCoursesByType, `${type.value}.length`, 0)}</Label>
                        </Menu.Item>
                      ),
                      render: function CourseTab () {
                        return (
                          <Tab.Pane key={`course-${type.value}`} as={'div'}>
                            {(type.value === 'secondary') && (
                              <Message warning>
                                <Message.Header>Newer versions of these modules are available</Message.Header>
                                <p>These modules have been updated, and there are new middle and high school-specific versions available now. Students no longer have access to these modules, however read-only access for staff will remain through Dec. 31, 2023</p>
                              </Message>
                            )}
                            {(!filteredCoursesByType[type.value] || !filteredCoursesByType[type.value].length) && (
                              <Segment placeholder>
                                <Header data-public icon>
                                  <Icon name='exclamation triangle' />
                                  There are no modules that match this search criteria.
                                </Header>
                              </Segment>
                            )}
                            <Card.Group centered={true}>
                              {sortBy(filteredCoursesByType[type.value], (c) => c.titles || c.title.en).map(renderCourse)}
                            </Card.Group>
                          </Tab.Pane>
                        )
                      },
                    }))
                    }
                  />
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

CoursesView.propTypes = {
  authState: PropTypes.object.isRequired,
  getCoursesState: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  activePageNumber: PropTypes.number.isRequired,
  organization: PropTypes.object,
  t: PropTypes.func.isRequired,
  showFriendlyApiErrorMessages: PropTypes.bool,
  onManageClick: PropTypes.func,
  onCourseClick: PropTypes.func,
  createCourse: PropTypes.func.isRequired,
  invalidateCourses: PropTypes.func.isRequired,
  getAllContentItems: PropTypes.func.isRequired,
  invalidateContentItems: PropTypes.func.isRequired,
  getContentItemsState: PropTypes.object.isRequired,
}

CoursesView.defaultProps = {
  t: (key, opts = {}) => opts.defaultValue || key,
  onManageClick: () => console.log('onManageClick'),
  onCourseClick: () => console.log('onCourseClick'),
}

CoursesView.path = '/courses'

const mapStateToProps = (state) => {
  const {
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    config: {
      showFriendlyApiErrorMessages,
    },
    contentItems: {
      [FETCH_ACTIONS.GET_ALL]: getContentItemsState,
    },
  } = state

  return {
    getCoursesState,
    getContentItemsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  createCourse,
  invalidateCourses,
  getAllContentItems,
  invalidateContentItems,
}
const CoursesViewContainer = connect(mapStateToProps, mapDispatchToProps)(CoursesView)

export default translate([ 'components' ])(CoursesViewContainer)
