import React from 'react'
import {
  Button, Segment, Label, Icon,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'

const UncontrolledScrollPager = ({
  items,
  hasMore,
  isLoading,
  render,
  onShowMore,
  basic,
  showNoMoreResultsMessage,
  showMoreButtonColor,
  showMoreButtonSize,
}) => {
  return (
    <React.Fragment>
      {render(items)}
      <Segment textAlign='center' basic={basic}>
        {(hasMore) && (
          <Button
            data-public
            disabled={isLoading}
            loading={isLoading}
            page={1}
            color={showMoreButtonColor}
            size={showMoreButtonSize}
            onClick={() => onShowMore(items.length)}
          >
            Show More
          </Button>
        )}
        {(!hasMore && !isLoading && showNoMoreResultsMessage) && (
          <Label
            data-public
            basic={true}
            color='blue'
          >
            <Icon name='info circle' />
            There are no more results
          </Label>
        )}
      </Segment>
    </React.Fragment>
  )
}

UncontrolledScrollPager.propTypes = {
  items: PropTypes.array,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  basic: PropTypes.bool,
  showNoMoreResultsMessage: PropTypes.bool,
  render: PropTypes.func.isRequired,
  onShowMore: PropTypes.func,
  showMoreButtonColor: PropTypes.string,
  showMoreButtonSize: PropTypes.string,
}

UncontrolledScrollPager.defaultProps = {
  onShowMore: console.log.bind(console, 'onShowMore'),
  isLoading: false,
  basic: false,
  showNoMoreResultsMessage: false,
  showMoreButtonColor: 'blue',
  showMoreButtonSize: 'small',
}

export default UncontrolledScrollPager
