export default function printElement (element) {
  const iframe = document.createElement('iframe')
  iframe.name = 'print_frame'
  iframe.width = '0'
  iframe.height = '0'
  iframe.frameBorder = '0'
  iframe.src = 'about:blank'
  const html = element.innerHTML
  document.body.appendChild(iframe)
  const iFrameLoaded = () => {
    const afterPrintHandler = () => {
      iframe.contentWindow.removeEventListener('afterprint', afterPrintHandler)
      iframe.removeEventListener('load', iFrameLoaded)
      iframe.parentNode.removeChild(iframe)
    }
    iframe.contentWindow.addEventListener('afterprint', afterPrintHandler)
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }

  iframe.addEventListener('load', iFrameLoaded)
  iframe.contentWindow.document.open()
  iframe.contentWindow.document.write(html)
  const arr = []
  arr.slice.call(document.styleSheets).forEach((stylesheet) => iframe.contentDocument.head.appendChild(stylesheet.ownerNode.cloneNode(true)))
  arr.slice.call(document.querySelectorAll('link[rel=stylesheet]')).forEach((link) => iframe.contentDocument.head.appendChild(link.cloneNode(true)))
  iframe.contentWindow.document.close()
}
