import to from 'await-to-js'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import setValueById from '../reducers/fetch/set-value-by-id'
import removeValueById from '../reducers/fetch/remove-value-by-id'
import { without } from 'lodash'
import pMap from 'p-map'

const STORE_PATH_PREFIX = 'contentItems'

export function getAllContentItems ({ accessToken, courseId }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, null, `/courses/${courseId}/contentItems`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getAllContentItems')
}

export function moveContentItems ({
  accessToken, courseId, contentItemIds,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const actionName = 'moveContentItems'
  const fetchAction = FETCH_ACTIONS.PUT

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ err, result ] = await to(fetch(`/courses/${courseId}/contentItems/ordinals`, {
        method: HTTP_METHODS.PUT,
        headers,
        data: contentItemIds,
      }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const [ getErr, getResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getErr) && dispatch(logout({ userInitiated: false }))
      if (getErr) {
        return dispatch([ requestFailure(getErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ setValueById(getResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function invalidateContentItems () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateContentItems' ]
}

export function getContentItem ({
  id,
  courseId,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, id, `/courses/${courseId}/contentItems/${id}`, null, { headers }, FETCH_ACTIONS.GET, STORE_PATH_PREFIX, 'getContentItem')
}

export function createContentItemsInEmptyCourse ({
  data,
  courseId,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const actionName = 'createContentItem'
  const fetchAction = FETCH_ACTIONS.POST

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])

      const mapper = (item) => fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.POST,
        headers,
        data: item,
      })

      const [ postErr ] = await to(pMap(data, mapper, { concurrency: 5 }))
      isUnauthorizedError(postErr) && dispatch(logout({ userInitiated: false }))
      if (postErr) {
        return dispatch([ requestFailure(postErr), storePath, `${actionName}Failure` ])
      }

      const [ getErr, getResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getErr) && dispatch(logout({ userInitiated: false }))
      if (getErr) {
        return dispatch([ requestFailure(getErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ setValueById(getResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(), storePath, `${actionName}Success` ])
    },
  ]
}

export function createContentItem ({
  data,
  courseId,
  contentItemIdsBefore,
  contentItemIdsAfter,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  // return callApi(HTTP_METHODS.POST, null, `/courses/${courseId}/contentItems`, data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createContentItem')
  const actionName = 'createContentItem'
  const fetchAction = FETCH_ACTIONS.POST

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ postErr, newContentItemId ] = await to(fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.POST,
        headers,
        data,
      }))
      isUnauthorizedError(postErr) && dispatch(logout({ userInitiated: false }))
      if (postErr) {
        return dispatch([ requestFailure(postErr), storePath, `${actionName}Failure` ])
      }

      const [ putError ] = await to(fetch(`/courses/${courseId}/contentItems/ordinals`, {
        method: HTTP_METHODS.PUT,
        headers,
        data: [ ...contentItemIdsBefore, newContentItemId, ...contentItemIdsAfter ],
      }))
      isUnauthorizedError(putError) && dispatch(logout({ userInitiated: false }))
      if (putError) {
        return dispatch([ requestFailure(putError), storePath, `${actionName}Failure` ])
      }

      const [ getErr, getResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getErr) && dispatch(logout({ userInitiated: false }))
      if (getErr) {
        return dispatch([ requestFailure(getErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ setValueById(getResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeCreateContentItem () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateContentItem' ]
}

export function updateContentItem ({
  data,
  courseId,
  accessToken,
  oldContentItem,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  // return callApi(HTTP_METHODS.PUT, data.id, `/courses/${courseId}/contentItems/${data.id}`, data, { headers }, FETCH_ACTIONS.PUT, STORE_PATH_PREFIX, 'updateContentItem', true)
  const actionName = 'updateContentItem'
  const fetchAction = FETCH_ACTIONS.PUT

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ err, result ] = await to(fetch(`/courses/${courseId}/contentItems/${data.id}`, {
        method: HTTP_METHODS.PUT,
        headers,
        data,
      }))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const newCi = {
        _meta: oldContentItem._meta,
        ...data,
        titles: data.titles.map((t) => t.en),
        bodies: data.bodies.map((b) => b.en),
        choices: data.choices && data.choices.map((c) => c.en),
      }
      const needsQuestionsUpdate = (data.type === 'text' && oldContentItem.type !== 'text') || (data.type !== 'text' && oldContentItem.type === 'text')
      if (!needsQuestionsUpdate) {
        dispatch([ setValueById(newCi), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
        dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
        return
      }

      const [ getExamsErr, getExamsResult ] = await to(fetch(`/courses/${courseId}/exams`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getExamsErr) && dispatch(logout({ userInitiated: false }))
      if (getExamsErr) {
        return dispatch([ requestFailure(getExamsErr), storePath, `${actionName}Failure` ])
      }
      const exam = getExamsResult[0]

      const [ getQuestionsErr, getQuestionsResult ] = await to(fetch(`/courses/${courseId}/exams/${exam.id}/questions`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getQuestionsErr) && dispatch(logout({ userInitiated: false }))
      if (getQuestionsErr) {
        return dispatch([ requestFailure(getQuestionsErr), storePath, `${actionName}Failure` ])
      }
      const qRels = oldContentItem._meta.relationships.filter((rel) => rel.type === 'questions')
      const qIds = qRels.map((rel) => rel.id)
      const matchingQuestion = getQuestionsResult.find((q) => qIds.includes(q.id))

      if (data.type !== 'text' && (!qRels.length || !matchingQuestion)) {
        const text = (newCi.titles.length && newCi.bodies.length) ? newCi.titles.join('\n') + '\n' + newCi.bodies.join('\n') : (newCi.titles.length) ? newCi.titles.join('\n') : newCi.bodies.join('\n')
        const [ postQuestionsErr ] = await to(fetch(`/courses/${courseId}/exams/${exam.id}/questions`, {
          method: HTTP_METHODS.POST,
          headers,
          data: {
            title: text,
            description: text,
            ordinal: data.ordinal,
            tags: [],
            pointsPossible: 0,
            _meta: {
              relationships: [ { id: data.id, type: 'contentItems' } ],
            },
          },
        }))
        isUnauthorizedError(postQuestionsErr) && dispatch(logout({ userInitiated: false }))
        if (postQuestionsErr) {
          return dispatch([ requestFailure(postQuestionsErr), storePath, `${actionName}Failure` ])
        }

        const [ getAllErr, getAllResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
          method: HTTP_METHODS.GET,
          headers,
        }))
        isUnauthorizedError(getAllErr) && dispatch(logout({ userInitiated: false }))
        if (getAllErr) {
          return dispatch([ requestFailure(getAllErr), storePath, `${actionName}Failure` ])
        }

        dispatch([ setValueById(getAllResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
        dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
        return
      } else if (data.type === 'text' && qRels.length && !!matchingQuestion) {
        const [ delQuestionsErr ] = await to(fetch(`/courses/${courseId}/exams/${exam.id}/questions/${matchingQuestion.id}`, {
          method: HTTP_METHODS.DELETE,
          headers,
        }))
        isUnauthorizedError(delQuestionsErr) && dispatch(logout({ userInitiated: false }))
        if (delQuestionsErr) {
          return dispatch([ requestFailure(delQuestionsErr), storePath, `${actionName}Failure` ])
        }

        const [ getAllErr, getAllResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
          method: HTTP_METHODS.GET,
          headers,
        }))
        isUnauthorizedError(getAllErr) && dispatch(logout({ userInitiated: false }))
        if (getAllErr) {
          return dispatch([ requestFailure(getAllErr), storePath, `${actionName}Failure` ])
        }

        dispatch([ setValueById(getAllResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
        dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
        return
      }

      dispatch([ setValueById(newCi), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeUpdateContentItem () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUpdateContentItem' ]
}

export function deleteContentItem ({
  id,
  allContentItemIds,
  courseId,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const actionName = 'deleteContentItem'
  const fetchAction = FETCH_ACTIONS.DELETE
  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ delErr ] = await to(fetch(`/courses/${courseId}/contentItems/${id}`, {
        method: HTTP_METHODS.DELETE,
        headers,
      }))
      isUnauthorizedError(delErr) && dispatch(logout({ userInitiated: false }))
      if (delErr) {
        return dispatch([ requestFailure(delErr), storePath, `${actionName}Failure` ])
      }

      const [ putError ] = await to(fetch(`/courses/${courseId}/contentItems/ordinals`, {
        method: HTTP_METHODS.PUT,
        headers,
        data: without(allContentItemIds, id),
      }))
      isUnauthorizedError(putError) && dispatch(logout({ userInitiated: false }))
      if (putError) {
        return dispatch([ requestFailure(putError), storePath, `${actionName}Failure` ])
      }

      const [ getErr, getResult ] = await to(fetch(`/courses/${courseId}/contentItems`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getErr) && dispatch(logout({ userInitiated: false }))
      if (getErr) {
        return dispatch([ requestFailure(getErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ removeValueById(id), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ setValueById(getResult), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(), storePath, `${actionName}Success` ])
    },
  ]
}
