import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Menu, Loader, Dropdown, Input,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { sortBy } from 'lodash'

import './organizations-menu.css'

const TRANSLATION_PREFIX = 'common.organizations-menu'

// TODO: make this menu more keyboard-friendly
export class OrganizationsMenu extends Component {
  static propTypes = {
    onChangeOrg: PropTypes.func.isRequired,
    homeOrg: PropTypes.object,
    currentOrg: PropTypes.object,
    isLoading: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onChangeOrg: console.log.bind(console, 'onChangeOrg'),
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = { searchText: '' }
    this.inputRef = React.createRef()
  }

  itemClick = (event, data) => {
    if (data.value) {
      this.props.onChangeOrg(data.value)
      this.setState({ searchText: '' })
    }
  }

  renderOrgOptions = (isFiltered, org, i) => {
    const selectedClass = (isFiltered && !i) ? 'selected' : ''
    const activeClass = (org.isActive) ? 'org-active' : 'org-inactive'
    return (
      <Dropdown.Item
        key={org.id}
        className={[ selectedClass, activeClass ].join(' ')}
        text={org.name}
        value={org.id}
        label={{
          color: (org.isActive) ? 'green' : 'red',
          empty: true,
          circular: true,
        }}
        onClick={this.itemClick}
      />
    )
  }

  stopEvent = (e) => e.stopPropagation()

  handleKeyDown = (orgs, e) => {
    const sortedOrgs = sortBy(orgs, 'name')
    const filteredOrgs = sortedOrgs.filter(this.filterOrgs)
    if (e.keyCode === 13 && e.target.value && filteredOrgs.length) {
      this.props.onChangeOrg(filteredOrgs[0].id)
      this.setState({ searchText: '' })
    }
  }

  handleSearch = (event, input) => this.setState({ searchText: input.value })

  filterOrgs = (org) => !this.state.searchText || org.name.toLowerCase().includes(this.state.searchText.toLowerCase())

  focusInput = () => setTimeout(() => this.inputRef.current.focus(), 10)

  render () {
    const {
      t,
      currentOrg,
      homeOrg,
      onChangeOrg,
      isLoading,
    } = this.props

    const loadingText = t('loading', { postProcess: 'titleCase' })
    if (!homeOrg || !currentOrg || isLoading) {
      return (
        <Menu.Item
          data-public
          className='text bold'>
          {loadingText}
          <Loader
            className='organizations-menu-loader'
            active
            inline
            size='tiny'
          />
        </Menu.Item>
      )
    }
    const hasChildren = !!currentOrg.children && !!currentOrg.children.length
    if (homeOrg.id === currentOrg.id && !hasChildren) {
      return (
        <Menu.Item className='text bold'>{currentOrg.name}</Menu.Item>
      )
    }
    const filteredOrgs = (hasChildren) ? currentOrg.children.filter(this.filterOrgs) : []
    const isFiltered = (hasChildren && filteredOrgs.length !== currentOrg.children.length)
    const menuItems = (filteredOrgs.length)
      ? sortBy(filteredOrgs, 'name').map(this.renderOrgOptions.bind(this, isFiltered))
      : (<Dropdown.Header data-public className='organizations-menu-no-orgs' content={t(`${TRANSLATION_PREFIX}.no_orgs_label`)} icon='warning' onClick={this.stopEvent}/>)
    return (
      <Dropdown
        id='org-dropdown'
        className='organizations-menu'
        item
        text={currentOrg.name}
        closeOnBlur={false}
        onOpen={(hasChildren) ? this.focusInput : () => null}
      >
        <Dropdown.Menu>
          {(homeOrg.id !== currentOrg.id) && <Dropdown.Item
            data-public
            icon='home'
            text={t(`${TRANSLATION_PREFIX}.my_org`)}
            onClick={() => onChangeOrg(homeOrg.id)}
          />}
          {(homeOrg.id !== currentOrg.id) && <Dropdown.Item
            data-public
            icon='arrow left'
            text={t(`${TRANSLATION_PREFIX}.parent_org`)}
            onClick={() => onChangeOrg(currentOrg.parentId)}
          />}
          {hasChildren && <Dropdown.Divider />}
          {hasChildren && <Dropdown.Header
            data-public
            icon='building'
            content={t(`resource_types.suborganization`, { count: 2, postProcess: 'titleCase' }) + ' (' + currentOrg.children.length + ')'}
          />}
          {hasChildren && <Input
            data-public
            ref={this.inputRef}
            icon='search'
            iconPosition='left'
            className='search'
            onClick={this.stopEvent}
            onChange={this.handleSearch}
            value={this.state.searchText}
            onKeyDown={this.handleKeyDown.bind(this, currentOrg.children)}
            placeholder={t(`${TRANSLATION_PREFIX}.search_orgs_label`)}
            type='search'
          />}
          {hasChildren && <Dropdown.Menu scrolling>
            {menuItems}
          </Dropdown.Menu>}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default translate([ 'components' ])(OrganizationsMenu)
