import gql from 'graphql-tag'

export const NAME = 'updateRoom'
const UPDATE_ROOM = gql`
mutation ${NAME}($room: UpdateRoomInput!) {
  ${NAME}(room: $room) {
    id
    name
    imageUrl
    orgId
    ownerUserId
    studentIds
    requestedStudentIds
    courseIds
    firewordNotificationsEnabled
    firewordNotificationsForRoomCoursesOnly
    isOpenForRequests
    isPrivate
    created
    modified
  }
}
`

export default UPDATE_ROOM
