import gql from 'graphql-tag'

const GET_USER_NOTIFICATION_SETTINGS = gql`
query getUserNotifications($userId: String!) {
  notificationSettings(
    userId: $userId
  ) {
    notificationSettings {
      id
      orgId
      userId
      type
      deliveryMethods
      created
    }
  }
}
`

export default GET_USER_NOTIFICATION_SETTINGS
