import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form as SemanticForm,
  Label,
  Button,
  Popup,
  Icon,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Form, Field } from 'react-final-form'
// import { memoize } from 'lodash'

import {
  minLength,
  maxLength,
  numRange,
  pattern,
  matchesField,
  required,
  composeValidators,
} from '../../../helpers/form-validators'
import {
  trimInputOnBlur,
  lowerCaseFormatter,
} from '../../../helpers/form'

const setFieldValue = (args, state) => {
  const [ name, data ] = args
  const field = state.fields[name]
  if (field) {
    field.change(data)
  }
}

const validateUserNameInternal = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 7 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
  pattern({ message: 'forms.login.user_name_error_pattern', pattern: /^[^\s]*$/ }),
)
const validateEmail = composeValidators(
  pattern({ message: 'forms.client-account.error_email_pattern', pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/ }),
)
const validatePassword = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'reenterNewPassword',
    fields: 'passwords',
  }),
)
const validateReEnterPassword = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'newPassword',
    fields: 'passwords',
  }),
)

const validateName = maxLength({ message: 'forms.error_max_length', count: 1000 })

const validateGraduationYear = composeValidators(
  numRange({
    message: 'forms.client-account.graduation_year_error', min: 2001, max: 2100,
  }),
  required({ message: 'forms.error_required' }),
)

export class CreateStudentForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    validateUserName: PropTypes.func,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    onCancel: console.log.bind(console, 'onCancel'),
    color: 'blue',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  constructor (props) {
    super(props)
    this.state = {
      isSaving: false,
      saveError: null,
    }
  }

  handleSaveForm = (data) => {
    const promise = this.props.onSave({
      firstName: data.firstName,
      lastName: data.lastName,
      userName: data.newUserName,
      email: data.newEmail,
      graduationYear: data.graduationYear,
      password: data.newPassword,
      reenterPassword: data.reenterNewPassword,
    })
    if (!!promise && !!promise.then) {
      this.setState({ ...this.state, isSaving: true })
      promise
        .then(() => this.props.onCancel())
        .catch((error) => this.setState({
          ...this.state,
          isSaving: false,
          saveError: error,
        }))
    }
  }

  renderForm = ({
    handleSubmit,
    form,
    pristine,
    invalid,
    validating,
  }) => {
    const {
      isLoading,
      t,
      validateUserName,
      onCancel,
    } = this.props
    const { isSaving, saveError } = this.state

    return (
      <SemanticForm className='create-clients-form' onSubmit={handleSubmit}>
        {(!!saveError) && (
          <Message icon negative>
            <Icon name='exclamation' />
            <Message.Header>Error</Message.Header>
            <p>There was a problem creating the student.</p>
            <p>{saveError.message}</p>
          </Message>
        )}
        <Field
          data-public
          name='newEmail'
          validate={validateEmail}
          format={lowerCaseFormatter}
          validateFields={[ 'newUserName' ]}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <SemanticForm.Field>
              <SemanticForm.Input
                className='create-student-input'
                placeholder={t('forms.email_label', { postProcess: 'titleCase' })}
                label={t('forms.email_label', { postProcess: 'titleCase' })}
                error={!!message && touched}
                type='text'
                fluid
                disabled={isLoading}
                {...input}
                autoCorrect='off'
                autoCapitalize='none'
                spellCheck='false'
                onBlur={trimInputOnBlur(input)}
                onChange={(e, data) => {
                  input.onChange(e, data)
                  setTimeout(() => form.mutators.setFieldValue('newUserName', data.value), 100)
                }}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </SemanticForm.Field>
          )}
        />
        <Field
          data-public
          name='newUserName'
          validate={validateUserName || validateUserNameInternal}
          format={lowerCaseFormatter}
          validateFields={[]}
          render={({
            input, meta: {
              error: { message, ...options } = {}, pristine, valid, touched, validating,
            },
          }) => {
            return (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-student-input'
                  placeholder={t('forms.user_name_label', { postProcess: 'titleCase' })}
                  icon={(!input.value && !touched) ? null : {
                    name: (valid) ? 'check' : 'ban', color: (valid) ? 'green' : 'red',
                  }}
                  label={(
                    <label>{t('forms.user_name_label', { postProcess: 'titleCase' }) + ' *'}
                      <Popup
                        hoverable
                        trigger={(<Icon className='base-teal' name='help circle' style={{
                          marginLeft: 5, fontSize: '1.1em', verticalAlign: 'top',
                        }} />)}
                        position='right center'
                      >
                        <p>We suggest using the email address as the user name to ensure uniqueness, but you are able to change it if needed.</p>
                      </Popup>
                    </label>
                  )}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  loading={validating}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                  onBlur={trimInputOnBlur(input)}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )
          }}
        />

        <SemanticForm.Group widths='equal'>
          <Field
            data-public
            name='newPassword'
            validate={validatePassword}
            validateFields={[ 'newPassword', 'reenterNewPassword' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-student-input'
                  placeholder={t('forms.password_label', { postProcess: 'titleCase' })}
                  label={t('forms.password_label', { postProcess: 'titleCase' }) + ' *'}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
          <Field
            data-public
            name='reenterNewPassword'
            validate={validateReEnterPassword}
            validateFields={[ 'newPassword', 'reenterNewPassword' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-student-input'
                  placeholder={`${t('re_enter')} ${t('forms.password_label', { postProcess: 'titleCase' })}`}
                  label={`${t('re_enter')} ${t('forms.password_label', { postProcess: 'titleCase' })} *`}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
        </SemanticForm.Group>

        <SemanticForm.Group widths='equal'>
          <Field
            data-public
            name='firstName'
            validate={validateName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-student-input'
                  placeholder={'First Name'}
                  label={'First Name'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
          <Field
            data-public
            name='lastName'
            validate={validateName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-student-input'
                  placeholder={'Last Name'}
                  label={'Last Name'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />

          <Field
            data-public
            name='graduationYear'
            validate={validateGraduationYear}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <SemanticForm.Field>
                <SemanticForm.Input
                  className='create-client-input'
                  placeholder={'Graduation Year'}
                  label={'Graduation Year *'}
                  error={!!message && touched}
                  type='number'
                  min={2001}
                  max={2100}
                  fluid
                  disabled={isLoading}
                  {...input}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </SemanticForm.Field>
            )}
          />
        </SemanticForm.Group>

        <div className='button-frame'>
          <Button
            type='button'
            floated='right'
            loading={isLoading || isSaving}
            color='red'
            disabled={isLoading || isSaving}
            onClick={onCancel}
          >
            {t('forms.cancel_button')}
          </Button>

          <Button
            type='submit'
            className='create-student-button'
            floated='right'
            loading={isLoading || isSaving}
            color='green'
            disabled={isLoading || isSaving || pristine || invalid || validating}
          >
            {t('forms.create_button')}
          </Button>
        </div>
      </SemanticForm>
    )
  }

  render () {
    const { isLoading } = this.props

    return (
      <Form
        subscription={{
          pristine: true,
          invalid: true,
          validating: true,
        }}
        initialValues={{
          graduationYear: new Date().getFullYear(),
        }}
        onSubmit={this.handleSaveForm}
        disableWarning={true}
        isLoading={isLoading}
        resetOnSubmit={true}
        render={this.renderForm}
        mutators={{ setFieldValue }}
      />
    )
  }
}

export default translate([ 'components' ])(CreateStudentForm)
