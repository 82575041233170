import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'

import {
  Button, Dropdown, Header, Icon, Input, Modal,
} from 'semantic-ui-react'

import PropTypes from 'prop-types'

const TRANSLATION_PREFIX = 'views.organization.clients'

const BulkUpdateClientsPasswordMenuItem = (props) => {
  const {
    authState, batchPasswordReset, clientCardData, getClientsState2, onLoadingChange, t, userIds,
  } = props

  const [ open, setOpen ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  const [ password, setPassword ] = useState()
  const [ userHash, setUserHash ] = useState({})

  // setup user hash for quick lookup when saving
  useEffect(() => {
    const hash = {}

    clientCardData.forEach((user) => { hash[user.id] = user })

    setUserHash(hash)
  }, [ clientCardData ])

  useEffect(() => {
    const seconds = getSecondsDiffBetweenDates(new Date(getClientsState2.lastUpdated), new Date())

    if (password && getClientsState2?.succeeded && (seconds < 5)) {
      onLoadingChange(false)
      setComplete(true)
    }
  }, [ getClientsState2, onLoadingChange, password ])

  const disabled = (userIds && !userIds.length)

  function getSecondsDiffBetweenDates (dateInitial, dateFinal) {
    return (dateFinal - dateInitial) / 1000
  }

  function save () {
    if (password.length < 7) {
      window.alert('Password must be longer than 7 characters.')
    } else {
      // map userIds --> formatted user objects for REST API
      const data = userIds.map((s) => {
        const { userName } = userHash[s]

        return {
          userName,
          secret: password,
        }
      })

      setOpen(false)
      onLoadingChange(true)
      batchPasswordReset({ data, accessToken: authState.accessToken })
    }
  }

  return (
    <>
      <Dropdown.Item
        disabled={disabled}
        icon='lock open'
        text={t(`${TRANSLATION_PREFIX}.update_login_password`)}
        onClick={() => setOpen(true)}
      />

      <ConfirmationModal
        {...props}
        open={open}
        setOpen={setOpen}
        setPassword={setPassword}
        save={save}
        userIds={userIds}
      />

      <CompletionModal
        {...props}
        open={complete}
        setOpen={setComplete}
        setPassword={setPassword}
      />
    </>
  )
}

BulkUpdateClientsPasswordMenuItem.propTypes = {
  authState: PropTypes.object,
  getClientsState2: PropTypes.object,
  clientCardData: PropTypes.array.isRequired,
  onLoadingChange: PropTypes.func,
  batchPasswordReset: PropTypes.func,
  t: PropTypes.func,
  userIds: PropTypes.array,
}

const ConfirmationModal = ({
  open, save, setOpen, setPassword, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Header>
      <Header>{t(`${TRANSLATION_PREFIX}.update_login_password`)}</Header>
    </Modal.Header>

    <Modal.Content
      style={{ flexDirection: 'column' }}
    >
      <div className='text bold grey'>{t(`${TRANSLATION_PREFIX}.password`)}</div>

      <Input
        className='password-input'
        onChange={(e) => setPassword(e.target.value)}
        size='small'
        placeholder={t(`${TRANSLATION_PREFIX}.password_hint`)}
      />
    </Modal.Content>

    <Modal.Actions>
      <Button
        color='#339cff'
        onClick={() => {
          setPassword()
          setOpen(false)
        }}
      >
        {t(`${TRANSLATION_PREFIX}.cancel`)}
      </Button>

      <Button
        content={t(`${TRANSLATION_PREFIX}.submit`)}
        labelPosition='left'
        icon='checkmark'
        onClick={save}
        positive
      />
    </Modal.Actions>
  </Modal>
)

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  save: PropTypes.func,
  setOpen: PropTypes.func,
  setPassword: PropTypes.func,
  t: PropTypes.func,
}

const CompletionModal = ({
  invalidateClients, open, setOpen, setPassword, t,
}) => (
  <Modal
    basic
    className='small'
    open={open}
    onClose={() => setOpen(false)}
  >
    <Modal.Content>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <Icon style={{ fontSize: '5em', margin: '40px auto -25px auto' }} name='check circle' />
      </Header>

      <Modal.Description>
        <div className='success-label'>{t(`${TRANSLATION_PREFIX}.bulk_password_success`)}</div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions style={{ justifyContent: 'flex-end' }}>
      <Button
        content={t(`${TRANSLATION_PREFIX}.done`)}
        onClick={() => {
          setPassword()
          setOpen(false)
          invalidateClients()
        }}
        primary
      />
    </Modal.Actions>
  </Modal>
)

CompletionModal.propTypes = {
  open: PropTypes.bool,
  invalidateClients: PropTypes.func,
  setOpen: PropTypes.func,
  setPassword: PropTypes.func,
  t: PropTypes.func,
}

export default translate([ 'components' ])(BulkUpdateClientsPasswordMenuItem)
