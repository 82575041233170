import to from 'await-to-js'
import AWS from 'aws-sdk'
import { capitalCase } from 'change-case'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'
import fetch, { fetchGraphQl } from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import mergeValueById from '../reducers/fetch/merge-value-by-id'
import setValueById from '../reducers/fetch/set-value-by-id'

const STORE_PATH_PREFIX = 'courses'
const Bucket = 'videos.base.education'
const Prefix = 'private/courses/'

AWS.config.region = 'us-west-2'

export function getAllCourses ({ accessToken, organizationId }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const qs = (organizationId) ? `?limited=true&orgId=${organizationId}` : '?limited=true'
  return callApi(HTTP_METHODS.GET, null, `/courses${qs}`, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getAllCourses')
}

export function invalidateCourses () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateCourses' ]
}

export function getCourse ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.GET, id, `/courses/${id}`, null, { headers }, FETCH_ACTIONS.GET, STORE_PATH_PREFIX, 'getCourse')
}

export function createCourse ({
  data, orgId, accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  // return callApi(HTTP_METHODS.POST, null, '/courses', data, { headers }, FETCH_ACTIONS.POST, STORE_PATH_PREFIX, 'createCourse')

  const method = HTTP_METHODS.POST
  const fetchAction = FETCH_ACTIONS.POST
  const actionName = 'createCourse'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ postCourseErr, courseId ] = await to(fetch(`/courses`, {
        data,
        method,
        headers,
      }))
      isUnauthorizedError(postCourseErr) && dispatch(logout({ userInitiated: false }))
      if (postCourseErr) {
        return dispatch([ requestFailure(postCourseErr), storePath, `${actionName}Failure` ])
      }

      const [ postExamErr ] = await to(fetch(`/courses/${courseId}/exams`, {
        data: {
          title: data.titles.en,
          description: data.titles.en,
        },
        method,
        headers,
      }))
      isUnauthorizedError(postExamErr) && dispatch(logout({ userInitiated: false }))
      if (postExamErr) {
        return dispatch([ requestFailure(postExamErr), storePath, `${actionName}Failure` ])
      }

      const [ updateOrgErr ] = await to(fetchGraphQl(headers, 'addCourse', {
        orgId,
        courseIds: [ courseId ],
      }, `mutation addCourse ($orgId: ID!, $courseIds: [String]!) {
        addCoursesToOrganizationAndChildren(id: $orgId, courseIds: $courseIds, childrenDepth: 0) {
          id
          name
          courseIds
        }
      }`))
      isUnauthorizedError(updateOrgErr) && dispatch(logout({ userInitiated: false }))
      if (updateOrgErr) {
        return dispatch([ requestFailure(updateOrgErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ requestSuccess(courseId), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeCreateCourse () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateCourse' ]
}

export function updateCourse ({ data, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const fetchAction = FETCH_ACTIONS.PUT
  const actionName = 'updateCourse'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ postCourseErr, courseId ] = await to(fetch(`/courses/${data.id}`, {
        data,
        method: HTTP_METHODS.PUT,
        headers,
      }))
      isUnauthorizedError(postCourseErr) && dispatch(logout({ userInitiated: false }))
      if (postCourseErr) {
        return dispatch([ requestFailure(postCourseErr), storePath, `${actionName}Failure` ])
      }

      const now = new Date().toISOString()
      const newData = {
        modified: now,
        ...data,
        titles: data.titles.en,
        descriptions: data.descriptions.en,
      }
      dispatch([ mergeValueById(newData), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])

      dispatch([ requestSuccess(courseId), storePath, `${actionName}Success` ])
    },
  ]
}

export function uploadCourseGuide ({
  courseId, file, courseType, type, accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const fetchAction = FETCH_ACTIONS.PUT
  const actionName = 'updateCourse'
  const guideName = capitalCase(type)

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ getCourseErr, course ] = await to(fetch(`/courses/${courseId}`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getCourseErr) && dispatch(logout({ userInitiated: false }))
      const existingOtherGuides = (course.guides || []).filter((guide) => !guide.name.en.includes(guideName))

      const [ s3KeyErr, s3KeyInfo ] = await to(fetch(`/s3AccessKeys`, { method: HTTP_METHODS.GET, headers }))
      isUnauthorizedError(s3KeyErr) && dispatch(logout({ userInitiated: false }))
      if (s3KeyErr) {
        return dispatch([ requestFailure(s3KeyErr), storePath, `${actionName}Failure` ])
      }
      const fileName = `${course.titles.toLowerCase().replace(/\s/g, '-').replace(/[^a-z0-9-]/g, '')}-guide-${type}-course-${courseType}.pdf`
      AWS.config.update(s3KeyInfo)
      const s3 = new AWS.S3()
      const s3Params = {
        Key: `${Prefix}${fileName}`,
        Bucket,
        ContentType: file.type,
        Body: file,
      }
      const [ s3Err ] = await to(s3.putObject(s3Params).promise())
      if (s3Err) {
        return dispatch([ requestFailure(s3Err), storePath, `${actionName}Failure` ])
      }

      const cloudfront = new AWS.CloudFront()
      const cfParams = {
        DistributionId: 'E3S3ZZFVINOW7', // videos.base.education
        InvalidationBatch: {
          CallerReference: new Date().toISOString(),
          Paths: {
            Quantity: 1,
            Items: [
              '/private/courses/*.pdf',
            ],
          },
        },
      }
      const [ cfError ] = await to(cloudfront.createInvalidation(cfParams).promise())
      if (cfError) {
        return dispatch([ requestFailure(cfError), storePath, `${actionName}Failure` ])
      }

      const guideObj = {
        name: {
          en: `Educator Guide (${guideName})`,
        },
        url: {
          en: `https://videos.base.education/${Prefix}${fileName}`,
        },
      }
      const guides = existingOtherGuides.concat([ guideObj ])

      const [ putCourseErr ] = await to(fetch(`/courses/${courseId}`, {
        data: {
          ...course,
          titles: {
            en: course.titles,
          },
          descriptions: {
            en: course.descriptions,
          },
          guides,
          imageUrl: course.imageUrl || 'https://media.base.education/img/base-7m-logo-color-no-solution.png',
          _meta: undefined,
        },
        method: HTTP_METHODS.PUT,
        headers,
      }))
      isUnauthorizedError(putCourseErr) && dispatch(logout({ userInitiated: false }))
      if (putCourseErr) {
        return dispatch([ requestFailure(putCourseErr), storePath, `${actionName}Failure` ])
      }

      const [ getUpdatedCourseErr, updatedCourse ] = await to(fetch(`/courses/${courseId}`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getUpdatedCourseErr) && dispatch(logout({ userInitiated: false }))

      dispatch([ setValueById(updatedCourse), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])

      dispatch([ requestSuccess(courseId), storePath, `${actionName}Success` ])
    },
  ]
}

export function deleteCourseGuide ({
  courseId, type, accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const fetchAction = FETCH_ACTIONS.PUT
  const actionName = 'updateCourse'
  const guideName = capitalCase(type)

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const [ getCourseErr, course ] = await to(fetch(`/courses/${courseId}`, {
        method: HTTP_METHODS.GET,
        headers,
      }))
      isUnauthorizedError(getCourseErr) && dispatch(logout({ userInitiated: false }))
      const existingOtherGuides = (course.guides || []).filter((guide) => !guide.name.en.includes(guideName))

      const [ putCourseErr ] = await to(fetch(`/courses/${courseId}`, {
        data: {
          ...course,
          titles: {
            en: course.titles,
          },
          descriptions: {
            en: course.descriptions,
          },
          guides: existingOtherGuides,
          imageUrl: course.imageUrl || 'https://media.base.education/img/base-7m-logo-color-no-solution.png',
          _meta: undefined,
        },
        method: HTTP_METHODS.PUT,
        headers,
      }))
      isUnauthorizedError(putCourseErr) && dispatch(logout({ userInitiated: false }))
      if (putCourseErr) {
        return dispatch([ requestFailure(putCourseErr), storePath, `${actionName}Failure` ])
      }

      dispatch([ setValueById({ ...course, guides: existingOtherGuides }), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])

      dispatch([ requestSuccess(courseId), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeUpdateCourse () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUpdateCourse' ]
}

export function deleteCourse ({ id, accessToken }) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.DELETE, id, `/courses/${id}`, null, { headers }, FETCH_ACTIONS.DELETE, STORE_PATH_PREFIX, 'deleteCourse')
}
