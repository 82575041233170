import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  Button,
  Form,
  Dropdown,
  Message,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'
import { omit, get } from 'lodash'

import {
  minLength,
  maxLength,
  required,
  pattern,
  numRange,
  composeValidators,
} from '../../../helpers/form-validators'
import {
  trimInputOnBlur,
  checkboxOnChange,
  dropdownOnChange,
  lowerCaseFormatter,
  rangeValueParser,
} from '../../../helpers/form'
import RadioGroup from '../../common/radio-group'
import BaseForm from '../base-form/base-form'
import ConditionalField from '../../common/conditional-field'

import './client-account.css'

const validateUserName = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 10000 }),
  pattern({ message: 'forms.client-account.error_user_name_pattern', pattern: /^[^\s]+$/ }),
)
const validateEmailRequired = composeValidators(
  required({ message: 'forms.error_required' }),
  pattern({ message: 'forms.client-account.error_email_pattern', pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i }),
)
const validateEmail = pattern({ message: 'forms.client-account.error_email_pattern', pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i })
const validateRoleId = required({ message: 'forms.error_required' })

const validateGraduationYear = numRange({
  message: 'forms.client-account.graduation_year_error', min: 2001, max: 2100,
})

const TRANSLATION_PREFIX = 'forms.client-account'

export class ClientAccountForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    userName: PropTypes.string,
    email: PropTypes.string,
    isArchived: PropTypes.bool,
    graduationYear: PropTypes.number,
    roles: PropTypes.array,
    orgs: PropTypes.array,
    roleType: PropTypes.string,
    orgId: PropTypes.string,
    isSelf: PropTypes.bool,
    t: PropTypes.func,
    isSSO: PropTypes.bool,
    isShared: PropTypes.bool,
    ssoName: PropTypes.string,
    ssoOverride: PropTypes.bool,
    canOverrideSso: PropTypes.bool,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'teal',
    isLoading: false,
    isDisabled: false,
    roles: [],
    orgs: [],
    isSelf: false,
    isSSO: false,
    isShared: false,
    ssoName: 'ClassLink',
    t: (key, opts = {}) => opts.defaultValue || key,
    ssoOverride: false,
    canOverrideSso: false,
  }

  handleSaveForm = (data) => {
    const userData = (data.isSSO) ? omit(data, [ 'isSSO', 'userName', 'email' ]) : omit(data, [ 'isSSO' ])
    this.props.onSave({
      ...userData,
      isShared: (data.roleType === 'adult') ? data.isShared : false,
    })
  }

  getRoleOption = (role) => ({
    value: role.type,
    label: (role.isInternal) ? `${role.name} (Internal Only)` : role.name,
    subLabel: role.description,
  })

  getOrgOption = (org) => ({
    value: org.id,
    text: org.name,
  })

  renderForm = ({
    handleSubmit,
    submitDisabled,
    form,
  }) => {
    const ssoOverride = get(form.getState(), 'values.ssoOverride')
    const {
      color,
      isLoading,
      isDisabled,
      roles,
      isSelf,
      orgs,
      t,
      isSSO,
      ssoName,
      canOverrideSso,
    } = this.props
    const currentRole = get(form.getFieldState('roleType'), 'value')
    return (
      <Form className='client-account-form' onSubmit={handleSubmit}>
        {(isSSO) && (
          <Message
            data-public
            info
            icon='info circle'
            header={`This form is disabled for ${ssoName} users`}
            content={(
              <>
                The data in the disabled fields will be automatically synchronized from {ssoName}.
                {(canOverrideSso) && (
                  <Field
                    data-public
                    name='ssoOverride'
                    render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                      <Form.Checkbox
                        className='override-checkbox'
                        label={t(`${TRANSLATION_PREFIX}.sso_override_label`)}
                        toggle
                        disabled={isLoading}
                        checked={!!input.value}
                        value='ssoOverride'
                        onChange={checkboxOnChange(input)}
                      />
                    )}
                  />
                )}
              </>
            )}
          />
        )}
        <Form.Group widths='equal'>
          <Field
            name='userName'
            validate={(isSSO) ? undefined : validateUserName}
            format={lowerCaseFormatter}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='client-account-input'
                  placeholder={t(`${TRANSLATION_PREFIX}.user_name_label`)}
                  label={t(`${TRANSLATION_PREFIX}.user_name_label`)}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading || isDisabled || isSSO}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                  onBlur={trimInputOnBlur(input)}/>
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            name='email'
            validate={(isSSO) ? undefined : [ 'admin', 'supervisor', 'advisor' ].includes(currentRole) ? validateEmailRequired : validateEmail}
            format={lowerCaseFormatter}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='client-account-input'
                  placeholder={t(`${TRANSLATION_PREFIX}.email_label`)}
                  label={t(`${TRANSLATION_PREFIX}.email_label`)}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading || isDisabled || isSSO}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                  onBlur={trimInputOnBlur(input)}/>
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            when='roleType'
            condition={(value) => value === 'student'}
            validate={validateGraduationYear}
            parse={rangeValueParser(0, 2500)}
            name='graduationYear'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='client-graduation-year-input'
                  label={t(`${TRANSLATION_PREFIX}.graduation_year_label`)}
                  error={!!message && touched}
                  type='number'
                  value='graduationYear'
                  min={2001}
                  max={2100}
                  fluid
                  disabled={isLoading || isDisabled}
                  {...input}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            name='isArchived'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Checkbox
                className='checkbox'
                label={t(`${TRANSLATION_PREFIX}.archived_label`)}
                toggle
                disabled={isLoading || isSelf || isDisabled || (isSSO && !ssoOverride)}
                {...input}
                checked={input.value}
                value='isArchived'
                onChange={checkboxOnChange(input)}/>
            )}
          />
        </Form.Group>
        <Field
          name='roleType'
          validate={validateRoleId}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field disabled={isLoading || isDisabled || (isSSO && !ssoOverride)}>
              <label>{t(`${TRANSLATION_PREFIX}.roles_label`)}</label>
              <RadioGroup
                vertical
                options={roles.map(this.getRoleOption)}
                selectedValue={input.value}
                disabled={isLoading || isSelf || isDisabled || (isSSO && !ssoOverride)}
                onChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />
        <ConditionalField
          data-public
          when='roleType'
          condition={(value) => value === 'adult'}
          name='isShared'
          validateFields={[]}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <Form.Checkbox
                id='is-shared-input'
                label={(
                  <label>Shared Account
                    <Popup
                      hoverable
                      trigger={(<Icon className='base-teal' name='help circle' style={{
                        marginLeft: 5, fontSize: '1.1em', verticalAlign: 'top',
                      }}/>)}
                      position='right center'
                    >
                      <p>Shared parent/guardian accounts allow multiple people to share the login credentials to access BASE, and their module progress will not be saved on the account. Use this if you need an easy way to get parents/guardians access but do not need to see their progress.</p>
                    </Popup>
                  </label>
                )}
                {...input}
                checked={!!input.value}
                disabled={isLoading}
                value='isShared'
                onChange={checkboxOnChange(input)}
              />
            </Form.Field>
          )}
        />
        <Field
          name='orgId'
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field disabled={isLoading || isDisabled || (isSSO && !ssoOverride)}>
              <label>{t(`${TRANSLATION_PREFIX}.org_label`)}</label>
              <Dropdown
                className='client-account-input'
                placeholder={t(`${TRANSLATION_PREFIX}.org_label`)}
                error={!!message && touched}
                fluid
                search
                selection
                disabled={isLoading || isSelf || isDisabled || (isSSO && !ssoOverride)}
                {...input}
                options={orgs.map(this.getOrgOption)}
                onChange={dropdownOnChange(input)}
                searchInput={{ type: 'search' }}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />
        <Button
          data-public
          type='submit'
          className='client-account-button'
          floated='right'
          loading={isLoading}
          color={color}
          disabled={submitDisabled}>
          {t('forms.save_button')}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      userName,
      email,
      orgId,
      roleType,
      isArchived = false,
      graduationYear,
      isLoading,
      isSSO,
      isShared,
      ssoOverride,
      t,
    } = this.props
    return (
      <BaseForm
        header={t(`${TRANSLATION_PREFIX}.header`)}
        onSubmit={this.handleSaveForm}
        isLoading={isLoading}
        initialValues={{
          userName,
          email,
          isArchived,
          graduationYear,
          roleType,
          orgId,
          isSSO,
          isShared,
          ssoOverride,
        }}
        render={this.renderForm}
      />
    )
  }
}

export default translate([ 'components' ])(ClientAccountForm)
