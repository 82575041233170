import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'

import './slider.css'

const Slider = (props) => {
  const {
    disabled, min, max, label, onChange, value,
  } = props

  return (
    <div className={`slider-view${disabled ? ' disabled' : ''}`}>
      <div className='slider'>
        <input
          className='firewords-slider'
          disabled={disabled || false}
          min={min || 1}
          max={max || 3}
          onChange={(e) => onChange(e.target.value)}
          type='range'
          value={value || 0}
        />

        {label && <div className='slider-label'>{label}</div>}
      </div>

      <div className='firewords-tags-frame'>
        {(value <= 1) && <Label color='yellow'>YELLOW</Label>}
        {(value <= 2) && <Label color='orange'>ORANGE</Label>}
        {(value <= 3) && <Label color='red'>RED</Label>}
      </div>
    </div>
  )
}

Slider.propTypes = {
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
}

export default Slider
