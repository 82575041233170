import React, {
  useState,
  useRef,
  memo,
} from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Message,
  Button,
  Segment,
  Table,
  Icon,
  Popup,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { parse } from 'csv-parse/lib/sync'
import {
  countBy,
  difference,
  noop,
  set,
  compact,
} from 'lodash'

import {
  composeValidators,
  maxLength,
  contains,
} from '../../../helpers/form-validators'

const MAX_RECORDS = 5000
const REQUIRED_HEADERS = [ 'text', 'subtext', 'type' ]
const CI_TYPE_OPTIONS = [ 'text', 'freeform', 'freeform-na', 'true-false', 'yes-no', 'multiple-choice', 'scale-1-10', 'accept', 'video-player' ]

const normalizeHeader = (header) => header.replace(/[_-]/g, '').toLowerCase()
const NORMALIZED_HEADERS_TO_ACTUAL = {
  text: 'titles',
  subtext: 'bodies',
  type: 'type',
}

const VALIDATORS_BY_FIELD = {
  text: (data) => composeValidators(
    maxLength({ message: 'forms.error_max_length', count: 10000 }),
  )(data),
  subtext: (data) => composeValidators(
    maxLength({ message: 'forms.error_max_length', count: 10000 }),
  )(data),
  type: (data) => contains({
    message: 'forms.error_invalid', validValues: CI_TYPE_OPTIONS, allowEmpty: true,
  })(data),
}
const identity = (val) => val || undefined
const mapText = (txt) => {
  const trimmed = txt.trim().replace(/’/g, `'`).replace(/—/g, '-')
  if (!trimmed) return
  return {
    en: trimmed,
  }
}
const FORMATTERS_BY_FIELD = {
  type: (data) => (data) ? data.toLowerCase() : 'text',
  text: (data, forApi) => (forApi) ? compact(data.split('\n').map(mapText)) : data,
  subtext: (data, forApi) => (forApi) ? compact(data.split('\n').map(mapText)) : data,
}

const getFileSize = (number) => {
  if (number < 1024) {
    return `${number} bytes`
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`
  }
}

const CsvImportTable = memo(function CsvImportTable ({
  data,
  csvColumnData,
  t,
}) {
  return (
    <Table celled compact definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell/>
          {data[0].map((cell, colIndex) => (
            <Table.HeaderCell
              key={`csv-line-header-column-${colIndex}`}
            >
              {cell}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.slice(1).map((line, rowIndex) => (
          <Table.Row key={`csv-line-${rowIndex}`}>
            <Table.Cell>{rowIndex}</Table.Cell>
            {line.map((cell, colIndex) => {
              const header = csvColumnData[colIndex]
              const validator = VALIDATORS_BY_FIELD[header] || noop
              const formatter = FORMATTERS_BY_FIELD[header] || identity
              const formattedCell = formatter(cell)
              const validationErr = validator(formattedCell)
              return (
                <Table.Cell
                  key={`csv-line-header-column-${colIndex}`}
                  error={!!validationErr}
                >
                  {(formattedCell) ? formattedCell.split('\n').map((l, i) => (<div key={`${l}|${i}`}>{l}</div>)) : ''}
                  {(!!validationErr) && (
                    <Popup content={t(validationErr.message, validationErr)} trigger={<Icon style={{ paddingLeft: 5 }} name='attention' />} />
                  )}
                </Table.Cell>
              )
            })}
          </Table.Row>
        ))}
      </Table.Body>

    </Table>
  )
})

CsvImportTable.propTypes = {
  data: PropTypes.array,
  csvColumnData: PropTypes.object,
  t: PropTypes.func,
}

const UploadCourseCsvForm = ({
  t,
  isSaving,
  onSave,
}) => {
  const [ rawFileData, setRawFileData ] = useState()
  const [ csvData, setCsvData ] = useState()
  const [ csvColumnData, setCsvColumnData ] = useState()
  const [ isLoadingFile, setIsLoadingFile ] = useState(false)
  const [ fileFormatError, setFileFormatError ] = useState()
  const reader = useRef(new FileReader())
  const loadCsv = useRef((event) => {
    setIsLoadingFile(false)
    setRawFileData(event.target.result)
  })
  reader.current.addEventListener('load', loadCsv.current)

  // only reprocess the data if the rawFile data or roleType has changed.
  if (!fileFormatError && (rawFileData && !csvData)) {
    let fileError
    const data = (rawFileData) ? parse(rawFileData) : null
    if (data.length > MAX_RECORDS + 1) {
      fileError = t('forms.upload-course-csv.too_many_rows_error', { count: data.length - 1, maxCount: MAX_RECORDS })
    }
    const normalizedHeaders = data[0].map(normalizeHeader)
    data[0] = normalizedHeaders
    const validHeaders = normalizedHeaders.filter((header) => REQUIRED_HEADERS.includes(header))
    if (!fileError) {
      const missingRequiredHeaders = difference(REQUIRED_HEADERS, validHeaders)
      if (missingRequiredHeaders.length) {
        fileError = t('forms.upload-course-csv.missing_headers', { missingRequiredHeaders: missingRequiredHeaders.join(', ') })
      }
      const headersCount = countBy(validHeaders)
      const duplicateHeaders = Object.keys(headersCount).filter((header) => headersCount[header] > 1)
      if (duplicateHeaders.length) {
        fileError = t('forms.upload-course-csv.duplicate_headers', { duplicateHeaders: duplicateHeaders.join(', ') })
      }
    }
    setFileFormatError(fileError)
    if (!fileError) {
      const indexedColumnHeaders = normalizedHeaders.reduce((accum, header, index) => ({
        ...accum,
        [index]: header,
      }), {})
      const hasDataErrors = data.slice(1).some((line) => {
        return line.some((value, colIndex) => {
          const header = indexedColumnHeaders[colIndex]
          const formatter = FORMATTERS_BY_FIELD[header] || identity
          const formattedValue = formatter(value)
          const validator = VALIDATORS_BY_FIELD[header] || noop
          return !!validator(formattedValue)
        })
      })
      if (hasDataErrors) {
        setFileFormatError(t('forms.upload-course-csv.invalid_data'))
      }
      setCsvColumnData(indexedColumnHeaders)
      setCsvData(data)
    }
  }
  return (
    <Segment loading={isLoadingFile || isSaving} style={{ margin: 0, overflowX: 'auto' }}>
      <Message info>
        <Message.Content>
          <Message.Header>{t('forms.upload-course-csv.header')}</Message.Header>
          <p>{t('forms.upload-course-csv.description_part_1')}</p>
          <p className='text bold'>{t('forms.upload-course-csv.description_part_2')}</p>
          <p>{t('forms.upload-course-csv.description_part_3')}</p>
          <p>{t('forms.upload-course-csv.description_part_4')}</p>
        </Message.Content>
      </Message>
      <Form>
        <Form.Field
          style={{
            marginTop: 20,
          }}
        >
          <label
            htmlFor='file-upload'
            style={{
              borderRadius: 4,
              color: 'white',
              backgroundColor: '#4A8B9A',
              cursor: 'pointer',
              fontWeight: 'bold',
              padding: '0.78571429em 1.5em 0.78571429em',
              width: '100%',
              display: 'block',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            Select File
          </label>
          <Form.Input
            style={{ display: 'none' }}
            id='file-upload'
            className='create-clients-input'
            type='file'
            accept='.csv'
            disabled={isLoadingFile}
            onClick={(e) => {
              e.target.value = null // clears value so that user can re-select the same file (if they needed to make changes)
            }}
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                setCsvData()
                setRawFileData()
                setCsvColumnData()
                if (e.target.files[0].size > (1048576)) {
                  setFileFormatError(t('forms.upload-course-csv.file_too_large_error', { size: getFileSize(e.target.files[0].size), desiredSize: '5 MB' }))
                } else {
                  setIsLoadingFile(true)
                  setFileFormatError()
                  reader.current.readAsText(e.target.files[0])
                }
              }
            }}
          />
        </Form.Field>
      </Form>
      {(!!fileFormatError) && (
        <Message negative>
          <Message.Header>There was an error processing this file</Message.Header>
          <p>{fileFormatError}</p>
        </Message>
      )}
      {(!fileFormatError && !isLoadingFile && !!csvData) && (
        <Message positive>
          <Message.Header>Looks Good!</Message.Header>
          <p>The data is valid and can be submitted for upload</p>
        </Message>
      )}
      {(!!csvData && csvData.length > 1) && (
        <CsvImportTable
          data={csvData}
          csvColumnData={csvColumnData}
          t={t}
        />
      )}
      <Button
        className='base-green-bg text white'
        style={{
          float: 'right',
        }}
        disabled={!csvData || !!fileFormatError || isLoadingFile || isSaving}
        onClick={() => {
          const contentItems = csvData.slice(1).map((line, index) => {
            return line.reduce((accum, value, colIndex) => {
              const header = csvColumnData[colIndex]
              const formatter = FORMATTERS_BY_FIELD[header] || identity
              const formattedValue = formatter(value, true)
              return set({ ...accum }, NORMALIZED_HEADERS_TO_ACTUAL[header], formattedValue)
            }, {
              ordinal: index,
              view: {
                container: 'book-with-pen-on-top.jpg',
                classes: 'padding light-screen pull-left pull-vertical',
                textClasses: 'medium',
                titleClasses: 'medium',
              },
            })
          })
          setCsvData()
          setCsvColumnData()
          onSave(contentItems)
        }}
      >
        Upload
      </Button>
      <br style={{ clear: 'both' }}/>
    </Segment>
  )
}

UploadCourseCsvForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  t: PropTypes.func,
}

UploadCourseCsvForm.defaultProps = {
  onSave: console.log.bind(console, 'onSave'),
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(UploadCourseCsvForm)
