import { get, isString } from 'lodash'

const SSO_TYPES = {
  clever: 'Clever',
  classlink: 'ClassLink',
  lti: 'LTI',
}
export const JOB_TITLE_OPTIONS = [
  {
    key: 'district_administrator',
    text: 'District Administrator',
    value: 'district_administrator',
  },
  {
    key: 'principal',
    text: 'Principal',
    value: 'principal',
  },
  {
    key: 'assisstant_principal',
    text: 'Assistant Principal or Dean',
    value: 'assisstant_principal',
  },
  {
    key: 'counselor',
    text: 'Counselor',
    value: 'counselor',
  },
  {
    key: 'teacher',
    text: 'Teacher',
    value: 'teacher',
  },
  {
    key: 'para',
    text: 'Para - Educator',
    value: 'para',
  },
  {
    key: 'psychologist',
    text: 'School Psychologist',
    value: 'psychologist',
  },
  {
    key: 'lcsw',
    text: 'LCSW / Social Worker',
    value: 'lcsw',
  },
  {
    key: 'other',
    text: 'Other',
    value: 'other',
  },
]

export const isSsoUser = (username) => {
  if (!username || !isString(username)) {
    return false
  }
  return Object.keys(SSO_TYPES).some((type) => username.startsWith(`<<${type}>>`))
}

export const getSsoName = (username) => {
  if (!username || !isString(username)) {
    return
  }
  const match = Object.keys(SSO_TYPES).find((type) => username.startsWith(`<<${type}>>`))
  if (!match) {
    return
  }
  return SSO_TYPES[match]
}

export const getDisplayName = (user, divider = ' ') => {
  if (!user) {
    return ''
  }
  const userName = get(user, 'userName', '')
  const isSSO = isSsoUser(userName)
  const firstName = get(user, 'profile.firstName', '') || get(user, 'firstName', '')
  const lastName = get(user, 'profile.lastName', '') || get(user, 'lastName', '')
  const displayName = (isSSO) ? `${firstName} ${lastName}` : (firstName && lastName)
    ? `${firstName} ${lastName}${divider}(${user.userName})`
    : (user.profile && firstName)
      ? `${firstName}${divider}(${user.userName})`
      : user.userName
  return displayName
}

export const matchesSearchText = (searchText, user) => {
  if (!searchText) {
    return true
  }
  const lowerSearchText = searchText.toLowerCase()
  const userName = get(user, 'userName') || ''
  const isSSO = isSsoUser(userName)
  const firstName = get(user, 'profile.firstName') || get(user, 'firstName') || ''
  const lastName = get(user, 'profile.lastName') || get(user, 'lastName') || ''
  const matchesUserName = (isSSO) ? false : userName.includes(lowerSearchText)
  const matchesFirstName = firstName.toLowerCase().includes(lowerSearchText)
  const matchesLastName = lastName.toLowerCase().includes(lowerSearchText)
  return matchesUserName || matchesFirstName || matchesLastName
}
