import gql from 'graphql-tag'

const GET_ORG = gql`
query getOrganization($organizationId: ID!, $childrenDepth: Int) {
  organization(
    id: $organizationId
    childrenDepth: $childrenDepth
  ) {
    id
    name
    isActive
    parentId
    parentIds
    studentLimit
    notes
    courseIds
    openCourseIds
    parentCourseIds
    assessmentsEnabled
    baselineAnalysisEnabled
    baselineRecommendationsEnabled
    alternativeAssessmentEnabled
    baselineDefaultMinDays
    baselineStartingPoints {
      schoolYear
      schoolYearAttempt
      minDays
      startsOn
    }
    sessionsEnabled
    firewordsEnabled
    roomsEnabled
    privateRoomsEnabled
    requireSessionPasswords
    requireRoomFirewords
    helpNotificationsEmails
    firewordNotificationsEmails
    numberOfSites
    educationLevels
    type
    contractStartDate
    contractEndDate
    country
    region
    customerId
    selfManagedNotificationRoleTypes
    sevenMindsetsEnabled
    helpButtonDisabled
    firewordNotificationsThreshold
    studentAccessSchedule {
      timezone
      weeklyTimes {
        day
        startMinute
        endMinute
      }
      oneTimeExceptions {
        start {
          date
          month
          year
        }
        end {
          date
          month
          year
        }
      }
    }
    children {
      id
      name
      isActive
      studentLimit
      parentId
      parentIds
      type
      contractStartDate
      contractEndDate
      customerId
      sessionsEnabled
      roomsEnabled
      privateRoomsEnabled
      children {
        id
        name
        isActive
        studentLimit
        parentId
        parentIds
        type
        contractStartDate
        contractEndDate
        customerId
        sessionsEnabled
        roomsEnabled
        privateRoomsEnabled
        children {
          id
          name
          isActive
          studentLimit
          parentId
          parentIds
          type
          contractStartDate
          contractEndDate
          customerId
          sessionsEnabled
          roomsEnabled
          privateRoomsEnabled
          children {
            id
            name
            isActive
            studentLimit
            parentId
            parentIds
            type
            contractStartDate
            contractEndDate
            customerId
            sessionsEnabled
            roomsEnabled
            privateRoomsEnabled
            children {
              id
              name
              isActive
              studentLimit
              parentId
              parentIds
              type
              contractStartDate
              contractEndDate
              customerId
              sessionsEnabled
              roomsEnabled
              privateRoomsEnabled
              children {
                id
                name
                isActive
                studentLimit
                parentId
                parentIds
                type
                contractStartDate
                contractEndDate
                customerId
                sessionsEnabled
                roomsEnabled
                privateRoomsEnabled
                children {
                  id
                  name
                  isActive
                  studentLimit
                  parentId
                  parentIds
                  type
                  contractStartDate
                  contractEndDate
                  customerId
                  sessionsEnabled
                  roomsEnabled
                  privateRoomsEnabled
                  children {
                    id
                    name
                    isActive
                    studentLimit
                    parentId
                    parentIds
                    type
                    contractStartDate
                    contractEndDate
                    customerId
                    sessionsEnabled
                    roomsEnabled
                    privateRoomsEnabled
                    children {
                      id
                      name
                      isActive
                      studentLimit
                      parentId
                      parentIds
                      type
                      contractStartDate
                      contractEndDate
                      customerId
                      sessionsEnabled
                      roomsEnabled
                      privateRoomsEnabled
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default GET_ORG
