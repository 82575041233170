import gql from 'graphql-tag'

const GET_SESSION = gql`
query activityOverviewsByClientId($clientId: ID!) {
  activityOverviewsByClientId(
    clientId: $clientId
  ) {
      quarterCounts {
        count
        courseId
        launches
      }
      monthCounts {
        count
        courseId
        launches
      }
      weekCounts {
        count
        courseId
        launches
      }
    }
}
`

export default GET_SESSION
