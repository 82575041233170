import {
  isDate,
  isNumber,
} from 'lodash'

// NOTE: schoolYear is the year in which the school year starts. For example 2022 would mean from Aug 1st, 2022 through Jul 31st, 2023.
const isInSchoolYear = (date, schoolYear) => {
  if (!isDate(date)) {
    throw new Error('Invalid date')
  }
  if (!isNumber(schoolYear)) {
    throw new Error('Invalid schoolYear')
  }
  const schoolYearStartUtc = new Date(`${schoolYear}-08-01`)
  const schoolYearStartLocal = new Date(schoolYearStartUtc.getTime() + (date.getTimezoneOffset() * 60 * 1000))
  const schoolYearEndUtc = new Date(`${schoolYear + 1}-08-01`)
  const schoolYearEndLocal = new Date(schoolYearEndUtc.getTime() + (date.getTimezoneOffset() * 60 * 1000))

  return (date >= schoolYearStartLocal && date < schoolYearEndLocal)
}

export default isInSchoolYear
