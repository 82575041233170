import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Grid,
  Icon,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import '../../common.css'

const HelpModal = ({
  onClose,
  open,
  roomsEnabled,
  t,
}) => {
  return (
    <Modal
      className='staff-help-modal'
      closeOnDimmerClick={false}
      closeIcon={true}
      open={open}
      onClose={onClose}
    >
      <Modal.Content>
        <Grid centered divided celled='internally' columns={2}>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Header as='h3'>Need Additional Help?</Header>
              <Button color='blue' as='a' target='new' href='https://7mindsets.zendesk.com/hc/en-us/categories/14577367402253-BASE-Education'><Icon name='circle help'/> Visit Support Center</Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Header as='h3'>Still have questions?</Header>
              <p>Give us a call at 678-878-3144 or send an email to <a href="mailto:basesupport@7mindsets.com">basesupport@7mindsets.com</a></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

HelpModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  roomsEnabled: PropTypes.bool,
  t: PropTypes.func,
}

HelpModal.defaultProps = {
  t: (key, opts = {}) => opts.defaultValue || key,
  roomsEnabled: false,
}

export default translate([ 'components' ])(HelpModal)
