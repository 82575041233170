import to from 'await-to-js'
import {
  compact,
  isArray,
  keyBy,
  orderBy,
} from 'lodash'
import AWS from 'aws-sdk'
import pMap from 'p-map'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import acknowledge from '../reducers/fetch/acknowledge'

const STORE_PATH_PREFIX = 'courseImages'
const Bucket = 'course.base.education'
const Prefix = 'img/'

AWS.config.region = 'us-west-2'

const getImagesFromS3 = async (s3, ContinuationToken, combinedItems = []) => {
  const s3Params = {
    Bucket,
    Prefix,
    ContinuationToken,
  }
  const [ s3Err, s3Result ] = await to(s3.listObjectsV2(s3Params).promise())
  if (s3Err) {
    throw s3Err
  }
  const files = combinedItems.concat(s3Result.Contents)
  if (!s3Result.IsTruncated) {
    return files
  }
  return getImagesFromS3(s3, s3Result.NextContinuationToken, files)
}

const getImageNamesFromS3 = async (s3) => {
  const [ err, files ] = await to(getImagesFromS3(s3))
  if (err) {
    throw err
  }
  return compact(orderBy(files, [ 'LastModified' ], [ 'desc' ]).map((obj) => obj.Key.replace(/^img\//, '')).filter((file) => !file.startsWith('thumbnails/')))
}

export function getCourseImages ({
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.GET
  const fetchAction = FETCH_ACTIONS.GET_ALL
  const actionName = 'getCourseImages'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])

      const [ s3KeyErr, s3KeyInfo ] = await to(fetch(`/s3AccessKeys`, { method, headers }))
      isUnauthorizedError(s3KeyErr) && dispatch(logout({ userInitiated: false }))
      if (s3KeyErr) {
        return dispatch([ requestFailure(s3KeyErr), storePath, `${actionName}Failure` ])
      }

      AWS.config.update(s3KeyInfo)
      const s3 = new AWS.S3()

      const [ s3Err, files ] = await to(getImageNamesFromS3(s3))
      if (s3Err) {
        return dispatch([ requestFailure(s3Err), storePath, `${actionName}Failure` ])
      }

      dispatch([ requestSuccess(files, (value) => value), storePath, `${actionName}Success` ])
    },
  ]
}

export function uploadCourseImages ({
  accessToken,
  files,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const fetchAction = FETCH_ACTIONS.PUT
  const actionName = 'uploadCourseImages'
  const Bucket = 'course.base.education'

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)

      dispatch([ request(), storePath, actionName ])

      const [ s3KeyErr, s3KeyInfo ] = await to(fetch(`/s3AccessKeys`, { method: HTTP_METHODS.GET, headers }))
      isUnauthorizedError(s3KeyErr) && dispatch(logout({ userInitiated: false }))
      if (s3KeyErr) {
        return dispatch([ requestFailure(s3KeyErr), storePath, `${actionName}Failure` ])
      }

      AWS.config.update(s3KeyInfo)
      const s3 = new AWS.S3()
      const newFilesByName = keyBy([ ...files ], (file) => {
        // ensure an alpha char at the beginning, then remove file extension, then replace periods, then remove special chars
        const name = file.name.replace(/^(\d)/, 'a-$1').replace(/\.jpe?g$/, '').replace(/\./g, '-').replace(/[^a-zA-Z0-9\-_]/gi, '') + '-' + Math.random().toString().slice(3, 8)
        return `${name.toLowerCase()}.jpg`
      })
      const newFileNames = Object.keys(newFilesByName)

      const mapper = (fileName) => {
        const file = newFilesByName[fileName]
        const s3Params = {
          Key: `${Prefix}${fileName}`,
          Bucket,
          ContentType: file.type,
          Body: file,
        }
        return s3.putObject(s3Params).promise()
      }

      const [ s3Err ] = await to(pMap(newFileNames, mapper, { concurrency: 5 }))
      if (s3Err) {
        return dispatch([ requestFailure(s3Err), storePath, `${actionName}Failure` ])
      }

      // No longer using this file
      // const Key = `css/panel-backgrounds-generated.css`
      // const [ getErr, cssData ] = await to(s3.getObject({
      //   Bucket,
      //   Key,
      // }).promise())
      // if (getErr) {
      //   return dispatch([ requestFailure(getErr), storePath, `${actionName}Failure` ])
      // }
      // const cssText = cssData.Body.toString('ascii').split('\n').map((text) => `${text}\n`)
      // const newClasses = newFileNames.map((fileName) => {
      //   const className = fileName.split('.')[0]
      //   return `.${className} { background-image: url("https://${Bucket}/img/${fileName}"); }\n`
      // })

      // const blob = new Blob(newClasses.concat(cssText), {
      //   type: 'text/css',
      // })
      // const s3Params = {
      //   Key,
      //   Bucket,
      //   ContentType: blob.type,
      //   CacheControl: 'no-store',
      //   Expires: 0,
      //   Body: blob,
      // }
      // const [ putErr ] = await to(s3.putObject(s3Params).promise())
      // if (putErr) {
      //   return dispatch([ requestFailure(putErr), storePath, `${actionName}Failure` ])
      // }

      const reduceGetAllImages = (newFiles) => (state) => {
        return Object.assign({}, state, {
          isLoading: false,
          isStale: false,
          error: null,
          value: (isArray(state.value)) ? newFiles.concat(state.value) : newFiles,
          lastUpdated: Date.now(),
        })
      }
      dispatch([ requestSuccess(), storePath, `${actionName}Success` ])
      dispatch([ reduceGetAllImages(newFileNames), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`, `${actionName}UpdateData` ])
    },
  ]
}

export function invalidateCourseImages () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateCourseImages' ]
}

export function acknowledgeUploadCourseImages () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUploadCourseImages' ]
}
