import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Header,
  Icon,
  Segment,
  Message,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import '../../common.css'
import './reset-enrollment-modal.css'

const OPTIONS = {
  RESET: 'reset',
  NEW: 'new',
}

const ResetEnrollmentModal = ({
  onClose,
  onOptionSelect,
  open,
  courseTitle,
  forPrivateRoom,
  t,
}) => {
  const [ selectedOption, setSelectedOption ] = useState()
  useEffect(
    () => {
      if (!open) {
        setSelectedOption(null)
      }
    },
    [ open ],
  )
  return (
    <Modal
      className='reset-enrollment-modal'
      closeOnDimmerClick={false}
      closeIcon={true}
      open={open}
      onClose={onClose}
    >
      <Modal.Content>
        <Header data-public style={{ marginTop: 0 }}>{(forPrivateRoom) ? 'Click the button below to reset the student\'s progress in this private room' : 'Choose one of the following options:'}</Header>
        <div className='selection-button-group'>
          {!forPrivateRoom && (
            <div
              tabIndex={0}
              className='selection-button'
              onClick={() => {
                if (selectedOption) {
                  return
                }
                if (!window.confirm(`Are you sure you want to reset this student's progress?`)) {
                  return
                }
                setSelectedOption(OPTIONS.NEW)
                onOptionSelect(OPTIONS.NEW)
              }}
            >
              <Segment loading={selectedOption === OPTIONS.NEW} disabled={forPrivateRoom || selectedOption === OPTIONS.RESET}>
                <Header icon>
                  <Icon data-public name='plus' />
                  Create a New Attempt
                  <Header.Subheader style={{ marginTop: 10 }}>
                  This will leave their existing progress and responses and create a new attempt where students can start over and provide new responses.
                  </Header.Subheader>
                </Header>
                <div className='text grey italic' style={{ display: 'flex', marginTop: 10 }}>
                  <Icon color='yellow' name='lightbulb' />
                  <p>Choose this option if you want to be able to compare their answers between attempts.</p>
                </div>
              </Segment>
            </div>
          )}
          {(!forPrivateRoom) && (<div className='divider'>OR</div>)}
          <div
            tabIndex={0}
            className={(forPrivateRoom) ? 'selection-button full-width' : 'selection-button'}
            onClick={() => {
              if (selectedOption) {
                return
              }
              if (!window.confirm(`Are you sure you want to reset this student's progress?`)) {
                return
              }
              setSelectedOption(OPTIONS.RESET)
              onOptionSelect(OPTIONS.RESET)
            }}
          >
            <Segment loading={selectedOption === OPTIONS.RESET} disabled={selectedOption === OPTIONS.NEW}>
              <Header data-public icon>
                <Icon name='undo' />
                  Reset this Attempt
                <Header.Subheader style={{ marginTop: 10 }}>
                  This will <span className='text bold red'>permanently delete</span> the students progress and responses for this attempt and make them start over from the beginning.
                </Header.Subheader>
              </Header>
              <div className='text grey italic' style={{ display: 'flex', marginTop: 10 }}>
                <Icon color='yellow' name='lightbulb' />
                {(forPrivateRoom) && (<p>Use this if you feel the student did not take the module seriously.</p>)}
                {(!forPrivateRoom) && (<p>Choose this option if you feel the student did not take the module seriously.</p>)}
              </div>
            </Segment>
          </div>
        </div>
        {(!forPrivateRoom) && (<Message
          warning
          icon='warning circle'
          header='Warning!'
          content={`This will affect the student's progress in ${courseTitle} for all rooms that this student belongs to.`}
        />)}
      </Modal.Content>
    </Modal>
  )
}

ResetEnrollmentModal.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func,
  courseTitle: PropTypes.string,
  forPrivateRoom: PropTypes.bool,
}

ResetEnrollmentModal.defaultProps = {
  onOptionSelect: console.log.bind(console, 'onOptionSelect'),
  courseTitle: 'this module',
  t: (key, opts = {}) => opts.defaultValue || key,
  forPrivateRoom: false,
}

export default translate([ 'components' ])(ResetEnrollmentModal)
