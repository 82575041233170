import React, {
  useState, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import {
  flatten,
  round,
  floor,
} from 'lodash'
import {
  Header,
  Popup,
  Icon,
  Message,
} from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'

const PROGRESS_COLORS = [
  '#de5626',
  '#f8bf00',
  '#90ad43',
  '#0892a5',
  '#71bbdb',
]
const BaselineMeanScoresByAttemptBarGraph = ({
  chartData,
  height,
  width,
  groupedOrgScores,
  isGlobal,
}) => {
  // creates group normalized averages based on attempt data for selected group
  const calculateGroupDomainAverages = (groupDataObj) => {
    let behaviorCount = 0
    let behaviorTotal = 0
    let engagementCount = 0
    let engagementTotal = 0
    let academicCount = 0
    let academicTotal = 0
    let socialCount = 0
    let socialTotal = 0
    for (const attempt in groupDataObj) {
      for (const domain of groupDataObj[attempt]) {
        switch (domain.sectionKey) {
          case 'behavioral_infraction':
            behaviorCount += domain.count
            behaviorTotal += domain.count * domain.groupNormalizedAvgScore
            break
          case 'engagement':
            engagementCount += domain.count
            engagementTotal += domain.count * domain.groupNormalizedAvgScore
            break
          case 'academic_self_confidence':
            academicCount += domain.count
            academicTotal += domain.count * domain.groupNormalizedAvgScore
            break
          case 'social_connectivity_in_school':
            socialCount += domain.count
            socialTotal += domain.count * domain.groupNormalizedAvgScore
            break
          default:
            break
        }
      }
    }
    return {
      behavior: behaviorTotal / behaviorCount,
      engagement: engagementTotal / engagementCount,
      academic: academicTotal / academicCount,
      social: socialTotal / socialCount,
    }
  }

  const organizationDomainAverages = calculateGroupDomainAverages(groupedOrgScores)

  const CustomTooltip = (data) => {
    const isGroupAvg = (data.data.attempt === 'Global Avg' || data.data.attempt === 'Group Avg')
    const attemptNumber = data.data.attempt
    const average = data.data.meanScore
    const groupAvgText = isGlobal ? `The average for all BASE users is ${average}` : `The average for all users in this group is ${average}`
    const headerText = isGroupAvg ? groupAvgText : `${attemptNumber} Attempt Average: ${average}`
    return (
      <Message
        size='mini'
        icon='info circle'
        header={headerText}
      />
    )
  }
  const barChartData = chartData.toReversed().map((domain) => {
    return flatten(domain).map((attempt) => {
      return {
        [`${floor(attempt.groupNormalizedAvgScore)}`]: round(attempt.groupNormalizedAvgScore, 2),
        attempt: attempt.key,
        domain: attempt.id,
        meanScore: round(attempt.groupNormalizedAvgScore, 2),
      }
    })
  })
  // this adds a fourth column to the bar graph with the mean average score of either the comparison group or all of BASE users
  const generalPopulationAverages = chartData.toReversed().map((domain, domainIdx) => {
    let groupAvgKey = ''
    switch (domainIdx) {
      case 0:
        groupAvgKey = 'behavior'
        break
      case 1:
        groupAvgKey = 'engagement'
        break
      case 2:
        groupAvgKey = 'academic'
        break
      case 3:
        groupAvgKey = 'social'
        break
      default:
        break
    }
    return {
      [isGlobal ? `${floor(domain[0][0].popNormalizedAvgScore)}` : `${floor(organizationDomainAverages[groupAvgKey])}`]: isGlobal ? round(domain[0][0].popNormalizedAvgScore, 2) : round(organizationDomainAverages[groupAvgKey], 2),
      attempt: isGlobal ? 'Global Avg' : 'Group Avg',
      domain: domain[0][0].id,
      meanScore: isGlobal ? round(domain[0][0].popNormalizedAvgScore, 2) : round(organizationDomainAverages[groupAvgKey], 2),
    }
  })

  barChartData.forEach((domain, domainIdx) => {
    domain.push(generalPopulationAverages[domainIdx])
  })
  const colorBy = ({ id, data }) => PROGRESS_COLORS[Number(id) - 1] // coming from each barChartData loop

  const chartRef = useRef(null)
  const legendWidth = 72

  const [ refWidth, setRefWidth ] = useState(0)

  useEffect(() => {
    const handleResize = (printOption) => {
      const width = printOption === 880 ? printOption : chartRef.current.clientWidth
      setRefWidth(width)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    const handleBeforePrint = () => {
      handleResize(880)
    }

    const handleAfterPrint = () => {
      handleResize()
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  return (

    <div ref={chartRef} style={{
      display: 'flex',
      width,
      height,
      fontWeight: 'bold',
      alignContent: 'space-around',
      flexDirection: 'row',
    }}>
      {barChartData.map((data, idx) => {
        const barWidth = (refWidth - legendWidth) / 4
        return (
          <div
            style={{
              display: 'flex',
              width: idx === 3 ? `${barWidth + legendWidth}px` : `${barWidth}px`,
              height: '100%',
              fontWeight: 'bold',
              alignContent: 'center',
              flexDirection: 'column',
            }}
            key={idx}
          >
            <Header as='h5'
              style={{
                textAlign: 'center',
                width: '100%',
                paddingRight: idx === 3 ? '70px' : '0px',
                paddingLeft: '20px',
                marginBottom: '15px',
                fontSize: '0.9rem',
              }}>
              {data[0].domain}
            </Header>
            <ResponsiveBar

              margin={{
                top: 20, right: idx === 3 ? 70 : 0, bottom: 50, left: 20,
              }}
              style={{
                width: '100%',

              }}
              maxValue={5}
              labelSkipHeight={20}
              data={data}
              indexBy='attempt'
              defs={[
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#dddddd',
                  rotation: 0,
                  lineWidth: 0.1,
                  spacing: 0.12,
                  modifiers: [
                    [ 'darker', 0.6 ],
                    [ 'opacity', 0.01 ],
                  ],
                },
              ]}
              keys={[
                '1',
                '2',
                '3',
                '4',
                '5',
              ]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Attempt',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={null}
              axisRight={null}

              label={(d) =>
                `${data[d.index].meanScore}`
              }
              labelTextColor={(d) => 'black'}
              colors={colorBy}
              animate={false}

              legends={idx === 3 ? [
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                },
              ] : []}

              tooltip={CustomTooltip}
            />
          </div>
        )
      },
      )}
      <Popup
        content={
          <span>Scores range from 1 to 5 and reflect how students responded to the BASEline assessment. For more information on how the scores relate to each domain, please click{' '}
            <a href='https://downloads.base.education/Baseline-Score-Descriptors.pdf' target='_blank' rel='noopener noreferrer'>here</a>.
          </span>
        }
        trigger={<Icon className='no-print' name='info circle' />}
        hoverable
        on='hover'
        position='bottom center'
      />
    </div>
  )
}

BaselineMeanScoresByAttemptBarGraph.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  keys: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.any,
  width: PropTypes.any,
  indexBy: PropTypes.string,
  bottomLegendLabel: PropTypes.string,
  lineWidth: PropTypes.number,
  enableArea: PropTypes.bool,
  t: PropTypes.func.isRequired,
  groupedOrgScores: PropTypes.object,
  isGlobal: PropTypes.bool,
}
BaselineMeanScoresByAttemptBarGraph.defaultProps = {
  chartData: [],
  height: 300,
  width: '100%',
  lineWidth: 0,
  enableArea: true,
  groupedOrgScores: {},
  isGlobal: false,
}
export default translate([ 'components' ])(BaselineMeanScoresByAttemptBarGraph)
