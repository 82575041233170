import React from 'react'
import PropTypes from 'prop-types'

import Mutation from '../../common/mutation/mutation'
import AccessScheduleForm from '../../forms/access-schedule/access-schedule'
import UPDATE_ORG_ACCESS_SCHEDULE from '../../../helpers/graphql-queries/update-org-access-schedule'

const UpdateOrganizationAccessSchedule = ({
  org,
  disabled,
  hideTimezone,
}) => (
  <Mutation
    mutation={UPDATE_ORG_ACCESS_SCHEDULE}
  >
    {(updateOrganization, { loading }) => {
      return (
        <AccessScheduleForm
          accessSchedule={org.studentAccessSchedule}
          hideTimezone={hideTimezone}
          isLoading={loading}
          disabled={disabled}
          onSave={(studentAccessSchedule) => updateOrganization({
            variables: {
              organization: {
                studentAccessSchedule: {
                  ...studentAccessSchedule,
                  specificTimes: [],
                  yearlyExceptions: [],
                  timezone: (org.studentAccessSchedule) ? org.studentAccessSchedule.timezone : 'America/Denver',
                },
                id: org.id,
              },
            },
          })}
        />
      )
    }}
  </Mutation>
)

UpdateOrganizationAccessSchedule.propTypes = {
  org: PropTypes.object,
  disabled: PropTypes.bool,
  hideTimezone: PropTypes.bool,
}

UpdateOrganizationAccessSchedule.defaultProps = {
  disabled: false,
  hideTimezone: false,
}

export default UpdateOrganizationAccessSchedule
