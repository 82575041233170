import React from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Icon,
  Segment,
  Table,
  Grid,
  Form,
  Dropdown,
  Popup,
  Message,
} from 'semantic-ui-react'
import {
  find,
  get,
  take,
  uniqBy,
  round,
} from 'lodash'

import { getDisplayName } from '../../../helpers/user'
import ProgressDots from '../progress-dots'
import ScrollPager from '../pager/uncontrolled-scroll-pager'
import DebouncedInput from '../debounced-input'

import Query from '../query/query'
import GET_INDIVIDUAL_USER_ASSESSMENT_SCORES from '../../../helpers/graphql-queries/get-individual-user-assessment-scores-by-org'

const DEFAULT_PAGE_SIZE = 25

const attemptNumberOptions = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
]

export default class AssessmentMeanScoresUserSort extends React.Component {
  static propTypes = {
    orgId: PropTypes.string.isRequired,
    assessment: PropTypes.object.isRequired,
    schoolYear: PropTypes.number.isRequired,
    onUserClick: PropTypes.func.isRequired,
    isInternalRole: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      sortColumn: 'behavioral_infraction',
      direction: 'asc',
      attemptNumber: 1,
      textFilter: '',
      isRefetching: false,
      hasMore: true,
    }
  }

  handleOrderClick = (sortColumn) => {
    if (sortColumn === this.state.sortColumn) {
      this.setState({
        ...this.state,
        direction: this.state.direction === 'asc' ? 'desc' : 'asc',
        hasMore: true,
      })
    } else {
      this.setState({
        ...this.state,
        sortColumn: sortColumn,
        direction: 'asc',
        hasMore: true,
        textFilter: '', // don't allow search text if sorting by domain
      })
    }
    this.forceUpdate()
  }

  getMeanScoreInfo = (sectionKey, scores) => {
    if (!find(scores, { sectionKey })) {
      return <Table.Cell/>
    }
    const meanScore = find(scores, { sectionKey }).normalizedMeanScore
    const section = find(this.props.assessment.sections, { key: sectionKey })
    const scoreIndex = (!section || !section.scoringDefinitions) ? null : Math.floor(meanScore) - 1
    return (
      <Popup
        trigger={
          <Table.Cell>
            <div data-public className='text large bold' style={{ marginBottom: 10 }}>
              {round(meanScore, 1)}
            </div>

            <ProgressDots
              completed={20 * Math.floor(meanScore)}
              radius={8}
              totalDots={5}
              color={'scale'}
            />
          </Table.Cell>
        }
        hoverable
      >
        <Popup.Content style={{ width: 500 }}>
          <div data-public className='text large bold' style={{ marginBottom: 10 }}>
            {meanScore >= 5 ? `5` : `${Math.floor(meanScore)}-${Math.floor(meanScore)}.99`}
          </div>
          <div data-public className='text large'>
            {section.scoringDefinitions[scoreIndex].description}
          </div>
        </Popup.Content>
      </Popup>
    )
  }

  renderClientItem = (clientItemData) => {
    const displayName = getDisplayName(clientItemData)
    return (
      <Table.Row
        key={clientItemData.userName}
        onClick={() => this.props.onUserClick(clientItemData.clientId, clientItemData.orgId)}
      >
        <Table.Cell>{displayName}
          <div className='text grey italic' style={{ display: 'flex', marginTop: 5 }}>
            {clientItemData.orgName}
          </div>
        </Table.Cell>
        {this.getMeanScoreInfo('behavioral_infraction', clientItemData.scores)}
        {this.getMeanScoreInfo('engagement', clientItemData.scores)}
        {this.getMeanScoreInfo('academic_self_confidence', clientItemData.scores)}
        {this.getMeanScoreInfo('social_connectivity_in_school', clientItemData.scores)}

      </Table.Row>
    )
  }

  render () {
    const {
      textFilter,
      attemptNumber,
      isRefetching,
      hasMore,
      sortColumn,
      direction,
    } = this.state
    return (
      <Query
        variables={{
          orgId: this.props.orgId,
          assessmentId: this.props.assessment.id,
          schoolYear: this.props.schoolYear,
          schoolYearAttempt: attemptNumber,
          sortBy: sortColumn,
          sortOrder: (direction === 'desc') ? -1 : 1,
          skip: 0,
          limit: DEFAULT_PAGE_SIZE + 1, // query for 1 extra to see if there are more results
          searchText: textFilter,
        }}
        fetchPolicy='network-only'
        query={GET_INDIVIDUAL_USER_ASSESSMENT_SCORES}
        onCompleted={(result) => this.setState({ ...this.state, hasMore: result.individualUserAssessmentScoresByOrgId.length === DEFAULT_PAGE_SIZE + 1 })}
      >{({
          loading: individualAssessmentLoading,
          data: individualAssessmentData,
          fetchMore,
        }) => {
          const individualScores = get(individualAssessmentData, 'individualUserAssessmentScoresByOrgId') || []
          return (
            <Segment style={{ marginBottom: 10 }} loading={individualAssessmentLoading}>
              <div style={{
                display: 'flex', flex: 1,
              }}>

                <Grid.Column style={{ flex: 1 }}>
                  <Header as='h3' style={{ flexGrow: 2, marginBottom: 10 }}>
                    <Icon name='user' />
                    <Header.Content data-public>
                      Student Scores
                    </Header.Content>
                  </Header>

                  <Form style={{
                    display: 'flex',
                  }}>
                    <Form.Group style={{
                      display: 'flex', flex: 1,
                    }}>

                      <Form.Field
                        label='Attempt Number '
                        control={Dropdown}
                        data-public
                        fluid
                        selection
                        options={attemptNumberOptions}
                        value={attemptNumber}
                        onChange={(e, data) => {
                          this.setState({ ...this.state, attemptNumber: data.value })
                        }}
                      />
                      <Form.Field
                        data-public
                        label='Search'
                        control={DebouncedInput}
                        wait={500}
                        icon='search'
                        style={{
                          flex: 1,
                          display: 'flex',
                        }}
                        value={textFilter}
                        onChange={(value) => {
                          this.setState({
                            ...this.state,
                            textFilter: value.toLowerCase(),
                            sortColumn: 'user', // don't allow sorting by domain if there is search text
                            hasMore: true,
                          })
                        }}
                      />
                    </Form.Group>
                  </Form>
                  {(!individualScores.length) && (
                    <Message
                      style={{ marginTop: 0 }}
                      icon='warning circle'
                      header='There are no student scores that match these criteria'
                      content='Please select a different attempt number or provide different text to search by.'
                    />
                  )}

                  {(!!individualScores.length) && (
                    <ScrollPager
                      basic={true}
                      hasMore={hasMore}
                      showNoMoreResultsMessage={individualScores.length > DEFAULT_PAGE_SIZE}
                      isLoading={isRefetching}
                      onShowMore={(skip) => {
                        this.setState({
                          ...this.state,
                          isRefetching: true,
                        })
                        fetchMore({
                          query: GET_INDIVIDUAL_USER_ASSESSMENT_SCORES,
                          variables: {
                            orgId: this.props.orgId,
                            assessmentId: this.props.assessment.id,
                            schoolYear: this.props.schoolYear,
                            schoolYearAttempt: attemptNumber,
                            sortBy: sortColumn,
                            sortOrder: (direction === 'desc') ? -1 : 1,
                            skip: skip,
                            limit: DEFAULT_PAGE_SIZE + 1, // query for 1 extra to see if there are more results
                            searchText: textFilter,
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            this.setState({ ...this.state, hasMore: fetchMoreResult.individualUserAssessmentScoresByOrgId.length === DEFAULT_PAGE_SIZE + 1 })
                            return {
                              individualUserAssessmentScoresByOrgId: uniqBy([ ...previousResult.individualUserAssessmentScoresByOrgId, ...fetchMoreResult.individualUserAssessmentScoresByOrgId ], 'clientId'),
                            }
                          },
                        })
                          .then(() => this.setState({ ...this.state, isRefetching: false }))
                          .catch((ex) => {
                            this.setState({ ...this.state, isRefetching: false })
                            throw ex
                          })
                      }}
                      items={(hasMore) ? take(individualScores, individualScores.length - 1) : individualScores}
                      style={{ flex: 1 }}
                      render={(items) => {
                        return (
                          <Table celled padded sortable selectable style={{ flex: 1 }}>
                            <Table.Header style={{ flex: 1 }}>
                              <Table.Row textAlign='center' style={{ flex: 1 }}>
                                <Table.HeaderCell
                                  style={{ flex: 1 }}
                                  className='text white base-teal-bg'
                                  data-public
                                  sorted={sortColumn === 'user' ? direction + 'ending' : null}
                                  onClick={() => this.handleOrderClick('user')}
                                >
                                  User
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  collapsing
                                  data-public
                                  className='text white base-teal-bg'
                                  sorted={sortColumn === 'behavioral_infraction' ? direction + 'ending' : null}
                                  onClick={() => this.handleOrderClick('behavioral_infraction')}
                                >
                                  Behavior
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  collapsing
                                  data-public
                                  className='text white base-teal-bg'
                                  sorted={sortColumn === 'engagement' ? direction + 'ending' : null}
                                  onClick={() => this.handleOrderClick('engagement')}
                                >
                                  Engagement
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  collapsing
                                  data-public
                                  className='text white base-teal-bg'
                                  sorted={sortColumn === 'academic_self_confidence' ? direction + 'ending' : null}
                                  onClick={() => this.handleOrderClick('academic_self_confidence')}
                                >
                                  Academic Self Confidence
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  collapsing
                                  data-public
                                  className='text white base-teal-bg'
                                  sorted={sortColumn === 'social_connectivity_in_school' ? direction + 'ending' : null}
                                  onClick={() => this.handleOrderClick('social_connectivity_in_school')}
                                >
                                  Social Connectivity in School
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {items.map(this.renderClientItem)}
                            </Table.Body>
                          </Table>
                        )
                      }}
                    />
                  )}
                </Grid.Column>
              </div>
            </Segment>)
        }}
      </Query>
    )
  }
}
