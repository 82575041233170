import gql from 'graphql-tag'

const GET_INDIVIDUAL_USER_ASSESSMENT_SCORES_REPORT = gql`
query getIndividualUserAssessmentScoresByOrgIdReport($orgId: String!, $assessmentId: String!, $schoolYear: Int!) {
  getIndividualUserAssessmentScoresByOrgIdReport(
    orgId: $orgId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
  )
}
`

export default GET_INDIVIDUAL_USER_ASSESSMENT_SCORES_REPORT
