import React, {
  useState,
  useRef,
  memo,
} from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Message,
  Button,
  Dropdown,
  Header,
  Segment,
  Table,
  Icon,
  Popup,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { splitEasy } from 'csv-split-easy'
import {
  compact,
  countBy,
  difference,
  isEqual,
  isNaN,
  map,
  noop,
  set,
  uniqWith,
} from 'lodash'

import {
  required,
  composeValidators,
  minLength,
  maxLength,
  pattern,
  numRange,
  contains,
} from '../../../helpers/form-validators'
import {
  JOB_TITLE_OPTIONS,
} from '../../../helpers/user'

const MAX_RECORDS = 5000
const GENDER_OPTIONS = [ 'male', 'female', 'notmention' ]
const ETHNICITY_OPTIONS = [ 'native_american', 'asian', 'african_american', 'pacific_islander', 'white', 'hispanic' ]
const REQUIRED_HEADERS_BY_ROLE = {
  student: [ 'password', 'username' ],
  adult: [ 'password', 'username' ],
  advisor: [ 'password', 'email' ],
  supervisor: [ 'password', 'email' ],
  admin: [ 'password', 'email' ],
}
const COMMON_HEADERS = [ 'password', 'email', 'firstname', 'lastname' ]
const VALID_HEADERS_BY_ROLE_TYPE = {
  student: [ 'username', ...COMMON_HEADERS, 'gender', 'ethnicity', 'age', 'graduationyear' ],
  adult: [ 'username', ...COMMON_HEADERS ],
  advisor: [ ...COMMON_HEADERS, 'jobtitle' ],
  supervisor: [ ...COMMON_HEADERS, 'jobtitle' ],
  admin: [ ...COMMON_HEADERS, 'jobtitle' ],
}
const normalizeHeader = (header) => header.replace(/[_-]/g, '').toLowerCase()
const NORMALIZED_HEADERS_TO_ACTUAL = {
  username: 'userName',
  email: 'email',
  password: 'secret',
  firstname: 'profile.firstName',
  lastname: 'profile.lastName',
  gender: 'profile.gender',
  ethnicity: 'profile.ethnicity',
  age: 'profile.age',
  graduationyear: 'graduationYear',
  jobtitle: 'profile.jobTitle',
}
const UNIQUE_HEADERS = [
  'username',
  'email',
]
const EMAIL_PATTERN = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
const VALIDATORS_BY_FIELD = {
  username: (data) => composeValidators(
    required({ message: 'forms.error_required' }),
    minLength({ message: 'forms.error_min_length', count: 3 }),
    maxLength({ message: 'forms.error_max_length', count: 1000 }),
    pattern({ message: 'forms.login.user_name_error_pattern', pattern: /^[^\s]*$/ }),
  )(data),
  email: (data, roleType) => (roleType === 'student') ? pattern({ message: 'forms.client-account.error_email_pattern', pattern: EMAIL_PATTERN })(data) : composeValidators(
    required({ message: 'forms.error_required' }),
    pattern({ message: 'forms.client-account.error_email_pattern', pattern: EMAIL_PATTERN }),
  )(data),
  password: (data) => composeValidators(
    required({ message: 'forms.error_required' }),
    minLength({ message: 'forms.error_min_length', count: 7 }),
  )(data),
  graduationyear: (data) => numRange({
    message: 'forms.error_out_of_range',
    min: 2020,
    max: 3000,
    allowEmpty: true,
  })(data),
  firstname: (data) => composeValidators(
    minLength({
      message: 'forms.error_min_length',
      count: 1,
      allowEmpty: true,
    }),
    maxLength({ message: 'forms.error_max_length', count: 1000 }),
  )(data),
  lastname: (data) => composeValidators(
    minLength({
      message: 'forms.error_min_length',
      count: 1,
      allowEmpty: true,
    }),
    maxLength({ message: 'forms.error_max_length', count: 1000 }),
  )(data),
  jobtitle: (data) => contains({ message: 'forms.error_invalid', validValues: map(JOB_TITLE_OPTIONS, 'value') })(data),
  gender: (data) => contains({
    message: 'forms.error_invalid',
    validValues: GENDER_OPTIONS,
    allowEmpty: true,
  })(data),
  ethnicity: (data) => contains({
    message: 'forms.error_invalid_multiple',
    validValues: ETHNICITY_OPTIONS,
    allowEmpty: true,
  })(data),
  age: (data) => numRange({
    message: 'forms.error_out_of_range', min: 1, max: 99,
  })(data),
}
const identity = (val) => val || undefined
const FORMATTERS_BY_FIELD = {
  username: (data) => data.toLowerCase(),
  email: (data) => data.toLowerCase() || undefined,
  password: identity,
  firstname: identity,
  lastname: identity,
  jobtitle: (data) => data.replace(/ /g, '_').toLowerCase(),
  gender: (gender) => {
    if (!gender) {
      return
    }
    if (gender.toLowerCase().startsWith('m')) {
      return 'male'
    } else if (gender.toLowerCase().startsWith('f')) {
      return 'female'
    } else if (gender.toLowerCase().startsWith('n')) {
      return 'notmention'
    }
    return gender
  },
  ethnicity: (ethnicities, forApi = false) => {
    if (!ethnicities) {
      return
    }
    const mapped = uniqWith(compact(ethnicities.split(',').map((ethnicity) => {
      const eth = ethnicity.toLowerCase().trim()
      if (eth.startsWith('w')) {
        return (forApi) ? { key: 'white' } : 'white'
      } else if (eth.startsWith('h')) {
        return (forApi) ? { key: 'hispanic' } : 'hispanic'
      } else if (eth.startsWith('b') || eth.startsWith('aa') || eth.startsWith('af')) {
        return (forApi) ? { key: 'african_american' } : 'african_american'
      } else if (eth.startsWith('a')) {
        return (forApi) ? { key: 'asian' } : 'asian'
      } else if (eth.startsWith('na')) {
        return (forApi) ? { key: 'native_american' } : 'native_american'
      } else if (eth.startsWith('pa') || eth.startsWith('pi')) {
        return (forApi) ? { key: 'pacific_islander' } : 'pacific_islander'
      }
      return undefined
    })), isEqual)
    return (mapped.length) ? mapped : undefined
  },
  age: (age) => age.replace(/^0?(\d\d?).*/, '$1') * 1 || undefined,
  graduationyear: (year) => {
    if (!year) {
      return year
    }
    const yearNum = year * 1
    if (isNaN(yearNum)) {
      return year
    }
    return (yearNum < 100) ? yearNum + 2000 : yearNum
  },
}

const getRoleOption = (role) => ({
  key: `create-clients-role-${role.type}`,
  text: (role.isInternal) ? `${role.name} (Internal Only)` : role.name,
  value: role.type,
  content: <Header size='small' content={(role.isInternal) ? `${role.name} (Internal Only)` : role.name} subheader={role.description} />,
})

const getFileSize = (number) => {
  if (number < 1024) {
    return `${number} bytes`
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`
  }
}

const CsvImportTable = memo(function CsvImportTable ({
  data,
  ignoredHeaderColumns,
  csvColumnData,
  roleType,
  t,
}) {
  return (
    <Table celled compact definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell/>
          {data[0].map((cell, colIndex) => (
            <Table.HeaderCell
              key={`csv-line-header-column-${colIndex}`}
              className={ignoredHeaderColumns.includes(colIndex) ? 'text grey' : ''}
            >
              {cell}
              {(ignoredHeaderColumns.includes(colIndex)) && (
                <Popup
                  content={t('forms.create-clients.ignored_header')}
                  trigger={<Icon className='base-teal' style={{ paddingLeft: 5 }} name='attention' />}
                />
              )}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.slice(1).map((line, rowIndex) => (
          <Table.Row key={`csv-line-${rowIndex}`}>
            <Table.Cell>{rowIndex + 1}</Table.Cell>
            {line.map((cell, colIndex) => {
              const header = csvColumnData[colIndex]
              const isIgnored = ignoredHeaderColumns.includes(colIndex)
              const validator = (isIgnored) ? noop : VALIDATORS_BY_FIELD[header] || noop
              const formatter = (isIgnored) ? identity : FORMATTERS_BY_FIELD[header] || identity
              const formattedCell = formatter(cell)
              const validationErr = validator(formattedCell, roleType)
              return (
                <Table.Cell
                  key={`csv-line-header-column-${colIndex}`}
                  error={!!validationErr}
                  disabled={isIgnored}
                >
                  {(formattedCell) ? formattedCell.toString() : formattedCell}
                  {(!!validationErr) && (
                    <Popup content={t(validationErr.message, validationErr)} trigger={<Icon style={{ paddingLeft: 5 }} name='attention' />} />
                  )}
                </Table.Cell>
              )
            })}
          </Table.Row>
        ))}
      </Table.Body>

    </Table>
  )
})

CsvImportTable.propTypes = {
  data: PropTypes.array,
  ignoredHeaderColumns: PropTypes.array,
  csvColumnData: PropTypes.object,
  roleType: PropTypes.string,
  t: PropTypes.func,
}

const CreateClientsForm = ({
  t,
  roles,
  email,
  onSave,
  organizationId,
}) => {
  const [ roleType, setRoleType ] = useState('student')
  const [ previousRoleType, setPreviousRoleType ] = useState('student')
  const [ userEmail, setUserEmail ] = useState(email)
  const [ rawFileData, setRawFileData ] = useState()
  const [ csvData, setCsvData ] = useState()
  const [ csvColumnData, setCsvColumnData ] = useState()
  const [ isLoadingFile, setIsLoadingFile ] = useState(false)
  const [ fileFormatError, setFileFormatError ] = useState()
  const [ duplicateErrorMessages, setDuplicateErrorMessages ] = useState([])
  const [ ignoredHeaderColumns, setIgnoredHeaderColumns ] = useState([])
  const reader = useRef(new FileReader())
  const loadCsv = useRef((event) => {
    setIsLoadingFile(false)
    setRawFileData(event.target.result)
  })
  reader.current.addEventListener('load', loadCsv.current)

  // only reprocess the data if the rawFile data or roleType has changed.
  if (!fileFormatError && ((rawFileData && !csvData) || (!!csvData && previousRoleType !== roleType))) {
    let fileError
    setPreviousRoleType(roleType)
    const data = (rawFileData) ? splitEasy(rawFileData) : null
    if (data.length > MAX_RECORDS + 1) {
      fileError = t('forms.create-clients.too_many_rows_error', { count: data.length - 1, maxCount: MAX_RECORDS })
    }
    const normalizedHeaders = data[0].map(normalizeHeader)
    data[0] = normalizedHeaders
    const validHeaders = normalizedHeaders.filter((header) => VALID_HEADERS_BY_ROLE_TYPE[roleType].includes(header))
    if (!fileError) {
      const missingRequiredHeaders = difference(REQUIRED_HEADERS_BY_ROLE[roleType], validHeaders)
      if (missingRequiredHeaders.length) {
        fileError = t('forms.create-clients.missing_headers', { missingRequiredHeaders: missingRequiredHeaders.join(', ') })
      }
      const headersCount = countBy(validHeaders)
      const duplicateHeaders = Object.keys(headersCount).filter((header) => headersCount[header] > 1)
      if (duplicateHeaders.length) {
        fileError = t('forms.create-clients.duplicate_headers', { duplicateHeaders: duplicateHeaders.join(', ') })
      }
    }
    setFileFormatError(fileError)
    if (!fileError) {
      const ignoredColumns = normalizedHeaders.reduce((accum, header, index) => {
        if (validHeaders.includes(header)) {
          return accum
        }
        return [ ...accum, index ]
      }, [])
      setIgnoredHeaderColumns(ignoredColumns)
      const indexedColumnHeaders = normalizedHeaders.reduce((accum, header, index) => ({
        ...accum,
        [index]: header,
      }), {})
      const indexedUniqueColumnHeaders = normalizedHeaders.reduce((accum, header, index) => {
        if (UNIQUE_HEADERS.includes(header) && !ignoredColumns.includes(index)) {
          return {
            ...accum,
            [index]: header,
          }
        }
        return accum
      }, {})
      const duplicatesByHeader = Object.keys(indexedUniqueColumnHeaders).reduce((accum, headerColIndex) => {
        const header = indexedUniqueColumnHeaders[headerColIndex]
        const allValuesForColumn = data.slice(1).map((line) => {
          const formatter = FORMATTERS_BY_FIELD[header] || identity
          return formatter(line[headerColIndex])
        })
        const countsByValue = countBy(allValuesForColumn)
        const duplicateValues = Object.keys(countsByValue).reduce((valAccum, value) => {
          return (countsByValue[value] > 1) ? [ ...valAccum, value ] : valAccum
        }, [])
        if (!duplicateValues.length) {
          return accum
        }
        return {
          ...accum,
          [header]: duplicateValues,
        }
      }, {})
      const duplicateErrMessages = Object.keys(duplicatesByHeader).map((header) => t('forms.create-clients.duplicate_error', { header, values: duplicatesByHeader[header].join(', ') }))
      setDuplicateErrorMessages(duplicateErrMessages)
      const hasDataErrors = !!duplicateErrMessages.length || data.slice(1).some((line) => {
        return line.some((value, colIndex) => {
          if (ignoredColumns.includes(colIndex)) {
            return false
          }
          const header = indexedColumnHeaders[colIndex]
          const formatter = FORMATTERS_BY_FIELD[header] || identity
          const formattedValue = formatter(value)
          const validator = VALIDATORS_BY_FIELD[header] || noop
          return !!validator(formattedValue, roleType)
        })
      })
      if (hasDataErrors) {
        setFileFormatError(t('forms.create-clients.invalid_data'))
      }
      setCsvColumnData(indexedColumnHeaders)
      setCsvData(data)
    }
  }
  const emailValidationMessage = VALIDATORS_BY_FIELD.email(userEmail)
  return (
    <Segment loading={isLoadingFile} style={{ overflowX: 'auto' }}>
      <Message info>
        <Message.Content>
          <Message.Header>{t('forms.create-clients.header')}</Message.Header>
          <p>{t('forms.create-clients.new_description_part_1')}</p>
          {(roleType === 'student') && (
            <>
              <strong><code>{t('forms.create-clients.new_description_student_headers')}</code></strong>
              <p>{t('forms.create-clients.new_description_student_headers_description')}</p>
            </>
          )}
          {(roleType === 'adult') && (
            <>
              <strong><code>{t('forms.create-clients.new_description_adult_headers')}</code></strong>
              <p>{t('forms.create-clients.new_description_adult_headers_description')}</p>
            </>
          )}
          {([ 'admin', 'supervisor', 'advisor' ].includes(roleType)) && (
            <>
              <strong><code>{t('forms.create-clients.new_description_staff_headers')}</code></strong>
              <p>{t('forms.create-clients.new_description_staff_headers_description')}</p>
            </>
          )}
          <p>{t('forms.create-clients.new_description_part_2')}</p>
          <p className='text bold'>{t('forms.create-clients.description_part_3')}</p>
          <p>{t('forms.create-clients.description_part_4')}</p>
          <ul>
            <li><a className='text bold' href='https://media.base.education/examples/example_base_upload_student.csv' target='new'>example_base_upload_student.csv</a></li>
            <li><a className='text bold' href='https://media.base.education/examples/example_base_upload_admin.csv' target='new'>example_base_upload_admin.csv</a></li>
          </ul>
        </Message.Content>
      </Message>
      <Form>
        <Form.Group widths='equal'>
          <Form.Field disabled={isLoadingFile}>
            <label>{t('resource_types.role', { postProcess: 'titleCase' })} *</label>
            <Dropdown
              className='create-clients-input'
              placeholder={t('forms.create-clients.roles_placeholder')}
              fluid
              search
              selection
              upward
              disabled={isLoadingFile}
              value={roleType}
              options={roles.map(getRoleOption)}
              onChange={(e, data) => {
                setFileFormatError()
                setPreviousRoleType(roleType)
                setRoleType(data.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              className='create-clients-input'
              placeholder={t('forms.create-clients.email_label', { postProcess: 'titleCase' })}
              label={t('forms.create-clients.email_label', { postProcess: 'titleCase' }) + ' *'}
              type='text'
              fluid
              onChange={((e, data) => setUserEmail(data.value))}
              disabled={isLoadingFile}
              autoCorrect='off'
              autoCapitalize='none'
              spellCheck='false'
              error={(!emailValidationMessage || !userEmail) ? false : {
                content: t(emailValidationMessage.message),
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field
          style={{
            marginTop: 20,
          }}
        >
          <label
            htmlFor='file-upload'
            style={{
              borderRadius: 4,
              color: 'white',
              backgroundColor: '#003050',
              cursor: 'pointer',
              fontWeight: 'bold',
              padding: '0.78571429em 1.5em 0.78571429em',
              width: '100%',
              display: 'block',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            Select File
          </label>
          <Form.Input
            style={{ display: 'none' }}
            id='file-upload'
            className='create-clients-input'
            type='file'
            accept='.csv'
            disabled={isLoadingFile}
            onClick={(e) => {
              e.target.value = null // clears value so that user can re-select the same file (if they needed to make changes)
            }}
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                setCsvData()
                setRawFileData()
                setIgnoredHeaderColumns([])
                setCsvColumnData()
                if (e.target.files[0].size > (1048576)) {
                  setFileFormatError(t('forms.create-clients.file_too_large_error', { size: getFileSize(e.target.files[0].size), desiredSize: '5 MB' }))
                } else {
                  setIsLoadingFile(true)
                  setFileFormatError()
                  reader.current.readAsText(e.target.files[0])
                }
              }
            }}
          />
        </Form.Field>
      </Form>
      {(!!fileFormatError) && (
        <Message negative>
          <Message.Header>There was an error processing this file</Message.Header>
          <p>{fileFormatError}</p>
        </Message>
      )}
      {duplicateErrorMessages.map((message, i) => (
        <Message key={`duplicate-error-${i}`} negative>
          <Message.Header>Duplicate Data</Message.Header>
          <p>{message}</p>
        </Message>
      ))}
      {(!fileFormatError && !isLoadingFile && !!csvData) && (
        <Message positive>
          <Message.Header>Looks Good!</Message.Header>
          <p>The data is valid and can be submitted for import</p>
        </Message>
      )}
      {(!!csvData && csvData.length > 1) && (
        <CsvImportTable
          data={csvData}
          ignoredHeaderColumns={ignoredHeaderColumns}
          csvColumnData={csvColumnData}
          roleType={roleType}
          t={t}
        />
      )}
      <Button
        className='base-green-bg text white'
        style={{
          float: 'right',
        }}
        disabled={!csvData || !!fileFormatError || isLoadingFile || !userEmail}
        onClick={() => {
          const clients = csvData.slice(1).map((line) => {
            return line.reduce((accum, value, colIndex) => {
              if (ignoredHeaderColumns.includes(colIndex)) {
                return accum
              }
              const header = csvColumnData[colIndex]
              const formatter = FORMATTERS_BY_FIELD[header] || identity
              const formattedValue = formatter(value, true)
              return set({ ...accum }, NORMALIZED_HEADERS_TO_ACTUAL[header], formattedValue)
            }, {
              type: 'user',
              roleType,
              orgId: organizationId,
            })
          })
          const data = {
            clients,
            enrollmentCourseIds: [],
            emails: [ userEmail ],
          }
          setCsvData()
          setIgnoredHeaderColumns([])
          setCsvColumnData()
          onSave(data)
        }}
      >
        Create
      </Button>
      <br style={{ clear: 'both' }}/>
    </Segment>
  )
}

CreateClientsForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
  email: PropTypes.string,
  t: PropTypes.func,
  hideCourseSelection: PropTypes.bool,
}

CreateClientsForm.defaultProps = {
  onSave: console.log.bind(console, 'onSave'),
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(CreateClientsForm)
