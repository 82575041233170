import to from 'await-to-js'
import { map, isArray } from 'lodash'

import {
  FETCH_ACTIONS, HTTP_METHODS, FETCH_TYPES,
} from '../helpers/fetch-constants'
import invalidateCollection from '../reducers/fetch/invalidate-collection'
import acknowledge from '../reducers/fetch/acknowledge'
import callApi from './call-api'
import fetch from '../helpers/fetch'
import isUnauthorizedError from '../helpers/is-unauthorized-error'
import getFetchReducer from '../reducers/fetch/fetch-reducer-strategy-factory'
import { logout } from './authentication'
import setValueById from '../reducers/fetch/set-value-by-id'
import removeValueById from '../reducers/fetch/remove-value-by-id'

const STORE_PATH_PREFIX = 'enrollments'

export function getClientEnrollments ({
  accessToken,
  clientId,
  courseId,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const url = (courseId) ? `/clients/${clientId}/registrations?courseId=${courseId}` : `/clients/${clientId}/registrations`
  return callApi(HTTP_METHODS.GET, null, url, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getClientEnrollments')
}

export function getCourseEnrollments ({
  accessToken,
  orgId,
  courseId,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const url = `/courses/${courseId}/registrations?orgId=${orgId}`
  return callApi(HTTP_METHODS.GET, null, url, null, { headers }, FETCH_ACTIONS.GET_ALL, STORE_PATH_PREFIX, 'getCourseEnrollments')
}

export function invalidateClientEnrollments () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}`
  return [ invalidateCollection(), storePath, 'invalidateClientEnrollments' ]
}

export function createClientEnrollments ({
  clientId,
  data,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.POST
  const fetchAction = FETCH_ACTIONS.POST
  const url = `/clients/${clientId}/registrations`
  const actionName = 'createEnrollment'
  data = (isArray(data)) ? data : [ data ]

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const promises = data.map((enrollment) => fetch(url, {
        data: enrollment,
        method,
        headers,
      }))
      const [ err, result ] = await to(Promise.all(promises))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const now = new Date()
      const enrollments = result.map((enrollmentId, index) => ({
        id: enrollmentId,
        created: now,
        modified: now,
        ...data[index],
        contentItemProgresses: [],
        clientId,
        _meta: { parent: `/clients/${clientId}` },
      }))

      dispatch([ setValueById(enrollments), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function createCourseEnrollments ({
  courseId,
  clientAttempts,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.POST
  const fetchAction = FETCH_ACTIONS.POST
  const actionName = 'createEnrollment'
  clientAttempts = (isArray(clientAttempts)) ? clientAttempts : [ clientAttempts ]

  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(), storePath, actionName ])
      const promises = clientAttempts.map((clientAttempt) => fetch(`/clients/${clientAttempt.clientId}/registrations`, {
        data: { courseId, attemptNumber: clientAttempt.attemptNumber },
        method,
        headers,
      }))
      const [ err, result ] = await to(Promise.all(promises))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const now = new Date()
      const enrollments = result.map((enrollmentId, index) => ({
        id: enrollmentId,
        created: now,
        modified: now,
        courseId,
        attemptNumber: clientAttempts[index].attemptNumber,
        contentItemProgresses: [],
        clientId: clientAttempts[index].clientId,
        _meta: { parent: `/clients/${clientAttempts[index].clientId}` },
      }))

      dispatch([ setValueById(enrollments), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeCreateEnrollment () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.POST}`
  return [ acknowledge(), storePath, 'acknowledgeCreateEnrollment' ]
}

export function updateEnrollment ({
  clientId,
  data,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  return callApi(HTTP_METHODS.PUT, data.id, `/clients/${clientId}/registrations/${data.id}`, data, { headers }, FETCH_ACTIONS.PUT, STORE_PATH_PREFIX, 'updateEnrollment')
}

export function acknowledgeUpdateEnrollment () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.PUT}`
  return [ acknowledge(), storePath, 'acknowledgeUpdateEnrollment' ]
}

export function deleteClientEnrollments ({
  ids,
  clientId,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.DELETE
  const fetchAction = FETCH_ACTIONS.DELETE
  const actionName = 'deleteEnrollment'
  ids = (isArray(ids)) ? ids : [ ids ]
  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(ids[0]), storePath, actionName ])
      const promises = ids.map((id) => fetch(`/clients/${clientId}/registrations/${id}`, {
        method,
        headers,
      }))
      const [ err, result ] = await to(Promise.all(promises))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      dispatch([ removeValueById(ids), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function deleteCourseEnrollments ({
  enrollmentData,
  accessToken,
}) {
  const headers = { authorization: `Bearer ${accessToken}` }
  const method = HTTP_METHODS.DELETE
  const fetchAction = FETCH_ACTIONS.DELETE
  const actionName = 'deleteEnrollment'
  enrollmentData = (isArray(enrollmentData)) ? enrollmentData : [ enrollmentData ]
  return [
    () => async (dispatch) => {
      const storePath = `${STORE_PATH_PREFIX}.${fetchAction}`
      const request = getFetchReducer(fetchAction, FETCH_TYPES.REQUEST)
      const requestFailure = getFetchReducer(fetchAction, FETCH_TYPES.FAILURE)
      const requestSuccess = getFetchReducer(fetchAction, FETCH_TYPES.SUCCESS)

      dispatch([ request(enrollmentData[0].enrollmentId), storePath, actionName ])
      const promises = enrollmentData.map((enrollment) => fetch(`/clients/${enrollment.clientId}/registrations/${enrollment.enrollmentId}`, {
        method,
        headers,
      }))
      const [ err, result ] = await to(Promise.all(promises))
      isUnauthorizedError(err) && dispatch(logout({ userInitiated: false }))
      if (err) {
        return dispatch([ requestFailure(err), storePath, `${actionName}Failure` ])
      }

      const ids = map(enrollmentData, 'enrollmentId')
      dispatch([ removeValueById(ids), `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.GET_ALL}.value`, `${actionName}UpdateData` ])
      dispatch([ requestSuccess(result), storePath, `${actionName}Success` ])
    },
  ]
}

export function acknowledgeDeleteEnrollment () {
  const storePath = `${STORE_PATH_PREFIX}.${FETCH_ACTIONS.DELETE}`
  return [ acknowledge(), storePath, 'acknowledgeDeleteEnrollment' ]
}
