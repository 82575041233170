import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Header,
  Form,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'

import {
  JOB_TITLE_OPTIONS,
} from '../../../helpers/user'

import '../../common.css'

const StaffProfileModal = ({
  onSave,
  open,
  isSaving,
  firstName,
  lastName,
  jobTitle,
  worksWithStudents,
  t,
}) => {
  const [ first, setFirstName ] = useState(firstName)
  const [ last, setLastName ] = useState(lastName)
  const [ title, setJobTitle ] = useState(jobTitle)
  const [ worksWithStuds, setWorksWithStudents ] = useState(worksWithStudents)
  return (
    <Modal
      className='staff-profile-modal'
      closeOnDimmerClick={false}
      open={open}
    >
      <Modal.Content>
        <Header><Icon name='handshake' /> Hi, Welcome to BASE!</Header>
        <p>Let{`'`}s add some important information to your account. This will help us tailor the experience to your needs and make it easier for your students and fellow staff members find you in the system.</p>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input
              name='firstName'
              placeholder='First Name'
              defaultValue={first}
              onChange={(e, data) => setFirstName(data.value)}
            />
            <Form.Input
              name='lastName'
              placeholder='Last Name'
              defaultValue={last}
              onChange={(e, data) => setLastName(data.value)}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Dropdown
              name='jobTitle'
              placeholder='Job Title'
              selection
              options={JOB_TITLE_OPTIONS}
              defaultValue={title}
              onChange={(e, data) => setJobTitle(data.value)}
            />
            <Form.Checkbox
              style={{ marginTop: '.75em' }}
              name='worksWithStudents'
              label='I work directly with students'
              defaultChecked={!!worksWithStuds}
              onChange={(e, data) => setWorksWithStudents(data.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('forms.save_button')}
          labelPosition='right'
          loading={isSaving}
          disabled={!first || !last || !title}
          icon='checkmark'
          color='green'
          onClick={() => onSave({
            firstName: first,
            lastName: last,
            jobTitle: title,
            worksWithStudents: !!worksWithStuds,
          })}
        />
      </Modal.Actions>
    </Modal>
  )
}

StaffProfileModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  worksWithStudents: PropTypes.bool,
  t: PropTypes.func,
}

StaffProfileModal.defaultProps = {
  onSave: console.log.bind(console, 'onSave'),
  isSaving: false,
  t: (key, opts = {}) => opts.defaultValue || key,
}

export default translate([ 'components' ])(StaffProfileModal)
