import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'

import './full-screen-loading-overlay.css'

const FullScreenLoadingOverlay = ({
  isActive,
}) => {
  return (
    <Dimmer className="full-screen-loading-overlay" active={isActive}>
      <Loader data-public>Loading</Loader>
    </Dimmer>
  )
}

FullScreenLoadingOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export default FullScreenLoadingOverlay
