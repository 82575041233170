import gql from 'graphql-tag'

export const NAME = 'createClasslinkOrganization'
const CREATE_CLASSLINK_ORG = gql`
mutation ${NAME}($organization: CreateClasslinkOrganizationInput!) {
  ${NAME}(org: $organization) {
    id
    parentId
    parentIds
    name
    isActive
    contacts {
      firstName
      lastName
      email
      phoneNumber
    }
    courseIds
    studentLimit
    selfManagedNotificationRoleTypes
    assessmentsEnabled
    baselineAnalysisEnabled
    baselineRecommendationsEnabled
    alternativeAssessmentEnabled
    sessionsEnabled
    roomsEnabled
    privateRoomsEnabled
    helpNotificationsEmails
    requireSessionPasswords
    numberOfSites
    type
    educationLevels
    contractStartDate
    contractEndDate
    country
    region
    customerId
    studentAccessSchedule {
      timezone
      weeklyTimes {
        day
        startMinute
        endMinute
      }
      oneTimeExceptions {
        start {
          date
          month
          year
        }
        end {
          date
          month
          year
        }
      }
    }
    children {
      id
    }
  }
}
`

export default CREATE_CLASSLINK_ORG
