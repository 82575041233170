import React from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Message,
  Segment,
  List,
  Grid,
  Icon,
  Button,
  Tab,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get, isEqual, sortBy,
} from 'lodash'
// import numeral from 'numeral'

import { FETCH_ACTIONS } from '../../../helpers/fetch-constants'
import { getClientCounts, invalidateClientCounts } from '../../../actions/client-counts'

import Query from '../../common/query/query'
import Mutation from '../../common/mutation/mutation'
// import ProgressBar from '../../common/progress-bar'
import UpdateOrganizationActiveStatus from '../../mutations/update-organization-active-status/update-organization-active-status'
// import UpdateOrganizationLockedStatus from '../../mutations/update-organization-locked-status/update-organization-locked-status'
import UpdateOrganization from '../../mutations/update-organization/update-organization'
import UpdateOrganizationAccessSchedule from '../../mutations/update-organization-access-schedule/update-organization-access-schedule'
// import UpdateOrganizationStudentLimit from '../../mutations/update-organization-student-limit/update-organization-student-limit'
import ManageSuborganizations from '../../mutations/manage-suborganizations/manage-suborganizations'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ORG from '../../../helpers/graphql-queries/get-org'
import UPDATE_ORG from '../../../helpers/graphql-queries/update-org'
import FetchResultMessage from '../../common/fetch-result-message/fetch-result-message'
import { ROLE_TYPES } from '../../../helpers/get-roles'
import ClockLabel from '../../common/clock-label/clock-label'
import MainContentContainer from '../../common/main-content-container'
import {
  getOrgTypesOfParents,
  getOrgTypesOfChildren,
} from '../../../helpers/organization'
import GET_COURSES from '../../../helpers/graphql-queries/get-courses'
import CourseSelector from '../../common/course-selector'
import BaselineForm from '../../forms/put-organization/baseline-form'

import './manage-organization.css'

const path = `/manage`

// const getTotalChildStudentLimit = (org) => {
//   return (!org.children) ? 0 : org.children.reduce((accum, child) => {
//     return accum + Math.max(child.studentLimit, 0) + getTotalChildStudentLimit(child)
//   }, 0)
// }

// const ACTIVE_STATUS_STYLE = {
//   float: 'right',
//   fontSize: '1rem',
//   fontStyle: 'italic',
// }

const ManageOrganizationViewWrapper = ({
  organizationId,
  ...props
}) => {
  return (
    <Query
      variables={{ organizationId, childrenDepth: 10 }}
      query={GET_ORG}
      fetchPolicy='network-only'
    >
      {({
        loading,
        data,
        refetch,
      }) => {
        const org = get(data, 'organization')
        if (!org || loading) {
          return (<FullScreenLoadingOverlay isActive={true}/>)
        }
        return (
          <ManageOrganizationView
            refetch={refetch}
            organizationId={organizationId}
            org={org}
            {...props}
          />
        )
      }}
    </Query>
  )
}

ManageOrganizationViewWrapper.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

ManageOrganizationViewWrapper.path = path

export class ManageOrganizationView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getClientCountsState: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    org: PropTypes.object.isRequired,
    getClientCounts: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    invalidateClientCounts: PropTypes.func.isRequired,
    refectchChildOrgList: PropTypes.func,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    homeOrganization: PropTypes.object,
  }

  static path = path

  constructor (props) {
    super(props)
    const orgCourseIds = get(props, 'org.courseIds') || []
    const openCourseIds = get(props, 'org.openCourseIds') || orgCourseIds
    const parentCourseIds = get(props, 'org.parentCourseIds') || orgCourseIds

    this.state = {
      openCourseIds,
      parentCourseIds,
      selectedTabIndex: 0,
    }
  }

  componentWillUnmount () {
    this.props.invalidateClientCounts()
  }

  render () {
    const {
      getCoursesState,
      getClientCountsState,
      organizationId,
      authState: {
        roleType, orgId, accessToken,
      },
      showFriendlyApiErrorMessages,
      homeOrganization,
      refectchChildOrgList,
      refetch,
      org,
    } = this.props

    const courses = (getCoursesState.value) ? Object.values(getCoursesState.value) : []
    // const selfCount = (getClientCountsState.value) ? getClientCountsState.value.self : 0
    // const childrenCount = (getClientCountsState.value) ? getClientCountsState.value.children : 0
    const showManagerControls = (roleType === ROLE_TYPES.SUPER_ADMIN || roleType === ROLE_TYPES.INTERNAL_ADMIN || roleType === ROLE_TYPES.ACCOUNT_MANAGER)
    const disableOrgCreationAndDeletion = (roleType === ROLE_TYPES.ACCOUNT_MANAGER)
    const isSSOOrg = organizationId.startsWith('clo_') || organizationId.startsWith('co_')
    if (getCoursesState.error || getClientCountsState.error) {
      return (
        <MainContentContainer>
          <FetchResultMessage
            itemType='organization'
            success={false}
            error={getCoursesState.error || getClientCountsState.error}
            showFriendlyError={showFriendlyApiErrorMessages}
          />
        </MainContentContainer>
      )
    }
    // let licenseText
    // let showProgress = false
    // const showChildrenCount = (!!org.children && !!org.children.length)
    // const childrenCountText = (showChildrenCount) ? `There a total of ${numeral(childrenCount).format('0,0')} student licenses used within all your suborganizations` : ''
    // if (org.studentLimit === -1) {
    //   licenseText = '(None)'
    // } else if (org.studentLimit === 0) {
    //   licenseText = `(Using Parent Organization's Licenses)`
    // } else {
    //   licenseText = `(${numeral(org.studentLimit).format('0,0')} Total)`
    //   showProgress = true
    // }
    // const completed = (showProgress) ? Math.floor(100 * Math.max(selfCount, 0) / org.studentLimit) : 0
    const isCustomer = !!org.contractStartDate
    const isSite = !!org.type && !isCustomer
    const parentOrgTypes = (homeOrganization.id === org.id) ? [] : getOrgTypesOfParents(homeOrganization, org)
    const childOrgTypes = (!org.children || !org.children.length) ? [] : getOrgTypesOfChildren(org.children)
    const canCreateCustomer = !parentOrgTypes.includes('customer') && !parentOrgTypes.includes('site') && !isCustomer && !isSite
    const canCreateSite = isCustomer || (parentOrgTypes.includes('customer') && !parentOrgTypes.includes('site') && !isSite)
    const canCreateIntegrationOrgs = (roleType === ROLE_TYPES.SUPER_ADMIN)
    const canUpdateToCustomer = !parentOrgTypes.includes('customer') && !childOrgTypes.includes('customer')
    const canUpdateToSite = parentOrgTypes.includes('customer') && !parentOrgTypes.includes('site') && !childOrgTypes.includes('site')

    const header = (isCustomer) ? `Account Details (${org.name})` : (isSite) ? `Site Details (${org.name})` : `Group Details (${org.name})`
    const icon = (isCustomer) ? 'address card' : (isSite) ? 'building' : 'users'
    const educationLevelsMap = {
      elementary: 'Grades 1-5',
      secondary: 'Grades 6-12',
      parent: 'Parent/Guardian',
      prefessional: 'Professional/Staff',
      higher_ed: 'Higher Education',
    }

    return (
      <MainContentContainer>
        <Query
          variables={{
            type: 'wellness',
            isActive: true,
            first: 100,
          }}
          query={GET_COURSES}
        >
          {({
            loading: wellnessCoursesLoading,
            data: wellnessCourseData,
          }) => {
            if (wellnessCoursesLoading) {
              return (<FullScreenLoadingOverlay isActive={true}/>)
            }
            const wellnessCourses = get(wellnessCourseData, 'coursesByType.courses', []) || []
            return (
              <Query
                variables={{
                  type: 'elementary',
                  isActive: true,
                  first: 100,
                }}
                query={GET_COURSES}
              >
                {({
                  loading: coursesLoading,
                  data: courseData,
                }) => {
                  if (coursesLoading) {
                    return (<FullScreenLoadingOverlay isActive={true}/>)
                  }
                  const newCourses = get(courseData, 'coursesByType.courses', []) || []
                  const allCourses = courses.concat(newCourses).concat(wellnessCourses)
                  return (
                    <>
                      <FullScreenLoadingOverlay isActive={getClientCountsState.isLoading || getCoursesState.isLoading}/>

                      {/* {(showManagerControls) && (
                  <UpdateOrganizationStudentLimit
                    organizationId={organizationId}
                    studentLimit={org.studentLimit}
                    disabled={false}
                  />
                )} */}
                      <Mutation
                        mutation={UPDATE_ORG}
                      >
                        {(updateOrganization, { loading }) => {
                          const panes = [
                            {
                              menuItem: 'Details',
                              render: () => {
                                return (
                                  <Tab.Pane as='div'>
                                    {(!showManagerControls) && (
                                      <Segment>
                                        <Grid>
                                          <Grid.Row>
                                            <Grid.Column width={12}>
                                              <Header data-public><Icon name={icon}/> {header}</Header>
                                            </Grid.Column>
                                            <Grid.Column textAlign='right' width={4}>
                                              <UpdateOrganizationActiveStatus
                                                organizationId={organizationId}
                                                isActive={org.isActive}
                                                disabled={roleType === 'supervisor' || roleType === 'advisor' || organizationId === orgId}
                                              />
                                              {/* {showManagerControls && (
                          <UpdateOrganizationLockedStatus
                            organizationId={organizationId}
                            isLocked={org.isLocked}
                            disabled={!showManagerControls}
                          />
                        )} */}
                                            </Grid.Column>
                                          </Grid.Row>
                                        </Grid>

                                        <List relaxed>
                                          <List.Item>
                                            <List.Icon className='base-teal' style={{ minWidth: 32 }} name='graduation' size='large' verticalAlign='middle' />
                                            <List.Content>
                                              <List.Header data-public>Education Levels</List.Header>
                                              <List.Description>{(org.educationLevels && org.educationLevels.length) ? org.educationLevels.map((level) => educationLevelsMap[level]).join(', ') : 'None Selected'}</List.Description>
                                            </List.Content>
                                          </List.Item>
                                          <List.Item>
                                            <List.Icon className='base-teal' style={{ minWidth: 32 }} name='world' size='large' verticalAlign='middle' />
                                            <List.Content>
                                              <List.Header data-public>Location</List.Header>
                                              <List.Description>{(org.region) ? `${org.region}, ` : ''}{org.country || 'United States'}</List.Description>
                                            </List.Content>
                                          </List.Item>
                                          <List.Item>
                                            <List.Content floated='right'>
                                              {(org.studentAccessSchedule && org.studentAccessSchedule.timezone) && (
                                                <ClockLabel hideIcon={true} timeZone={org.studentAccessSchedule.timezone} size='medium'/>
                                              )}
                                            </List.Content>
                                            <List.Icon className='base-teal' style={{ minWidth: 32 }} name='clock' size='large' verticalAlign='middle' />
                                            <List.Content>
                                              <List.Header data-public>Time Zone</List.Header>
                                              <List.Description>{(org.studentAccessSchedule && org.studentAccessSchedule.timezone) ? org.studentAccessSchedule.timezone : 'No Time Zone Set'}</List.Description>
                                            </List.Content>
                                          </List.Item>
                                        </List>

                                        {/* <Header as='h4'>Organization Student Licenses {licenseText}</Header>
                  {(org.studentLimit === -1) && (
                    <Message
                      info
                      icon='users'
                      header='This organization does not allow student users'
                      content='Please contact your account manager to enable student users for this organization, or assign your students to a suborganization.'
                    />
                  )}
                  {(org.studentLimit === 0) && (
                    <Message
                      info
                      icon='users'
                      header={`This organization's students count against its parent organization's licenses`}
                      content={`There are ${numeral(selfCount).format('0,0')} students in this organization.`}
                    />
                  )}
                  {showProgress && (
                    <>
                      {(completed > 0 && selfCount >= org.studentLimit) && (
                        <Message
                          negative
                          icon='users'
                          header='Your organization has run out of student licenses'
                          content='We will reach out to your main contact to discuss increasing your number of licenses.'
                        />
                      )}
                      {(completed > 80 && completed < 100) && (
                        <Message
                          warning
                          icon='users'
                          header={`Your organization is approaching it's limit of student licenses`}
                          content={`If you'd like to request an increase, please contact your account manager.`}
                        />
                      )}
                      <ProgressBar completed={completed} />
                      <Header style={{ marginTop: 4 }} as='h4' textAlign='center'>{`There are a total of ${numeral(selfCount).format('0,0')} student licenses used in this organization`}</Header>
                    </>
                  )}
                  {showChildrenCount && (
                    <>
                      <Header as='h4'>Suborganization Student Licenses ({numeral(getTotalChildStudentLimit(org)).format('0,0')} Total)</Header>
                      <ProgressBar completed={Math.floor(100 * Math.max(childrenCount, 0) / Math.max(getTotalChildStudentLimit(org), 1))} />
                      <Header style={{ marginTop: 4 }} as='h4' textAlign='center'>{childrenCountText}</Header>
                    </>
                  )} */}
                                      </Segment>
                                    )}

                                    {(showManagerControls) && (
                                      <UpdateOrganization
                                        org={org}
                                        orgType={(isCustomer) ? 'customer' : (isSite) ? 'site' : 'group'}
                                        courses={allCourses}
                                        isSSO={isSSOOrg}
                                        showAdvancedOptions={roleType === ROLE_TYPES.SUPER_ADMIN}
                                        showGlobalOptions={showManagerControls}
                                        canUpdateToCustomer={canUpdateToCustomer}
                                        canUpdateToSite={canUpdateToSite}
                                        canUpdateCourses={org.id !== homeOrganization.id}
                                        canSyncSso={canCreateIntegrationOrgs}
                                        accessToken={accessToken}
                                      />
                                    )}

                                    {(showManagerControls) && (
                                      <ManageSuborganizations
                                        org={org}
                                        courses={allCourses}
                                        disableModifications={roleType === ROLE_TYPES.ADMIN || isSSOOrg || disableOrgCreationAndDeletion}
                                        canCreateCustomer={canCreateCustomer && !disableOrgCreationAndDeletion}
                                        canCreateSite={canCreateSite && !disableOrgCreationAndDeletion}
                                        canCreateIntegrationOrgs={canCreateIntegrationOrgs && !disableOrgCreationAndDeletion}
                                        canCreateGroups={!disableOrgCreationAndDeletion}
                                        refectchChildOrgList={refectchChildOrgList}
                                      />
                                    )}

                                    <br/>
                                  </Tab.Pane>
                                )
                              },
                            },
                            {
                              menuItem: 'Student Access',
                              render: () => {
                                return (
                                  <Tab.Pane as='div'>
                                    <Segment>
                                      <Header>Student Module Access</Header>
                                      <Message
                                        info
                                        icon='info circle'
                                        content='Students will have open access to any of the modules that are enabled below. If there are certain modules that you prefer students not have access to, unless recommended by a staff member, disable them here.'
                                      />
                                      <CourseSelector
                                        availableCourses={allCourses.filter((course) => course.type !== 'wellness' && (course.type !== 'elementary' || !course.videoUrl))}
                                        selectedCourseIds={this.state.openCourseIds}
                                        isDisabled={[ ROLE_TYPES.SUPERVISOR, ROLE_TYPES.ADVISOR ].includes(roleType)}
                                        onChange={(ids) => this.setState({ ...this.state, openCourseIds: ids })}
                                        isSaving={false}
                                        hideHeader={true}
                                        hideParentCourses={true}
                                      />
                                      <Button
                                        floated='right'
                                        disabled={isEqual(sortBy(this.state.openCourseIds), sortBy(get(org, 'openCourseIds')))}
                                        color='green'
                                        loading={loading}
                                        onClick={() => {
                                          updateOrganization({
                                            variables: {
                                              organization: {
                                                id: org.id,
                                                openCourseIds: this.state.openCourseIds,
                                              },
                                            },
                                          }).then(refetch)
                                        }}
                                      >
                                          Save
                                      </Button>
                                      <br style={{ clear: 'both' }} />
                                    </Segment>
                                    <UpdateOrganizationAccessSchedule
                                      hideTimezone={true}
                                      disabled={[ ROLE_TYPES.SUPERVISOR, ROLE_TYPES.ADVISOR ].includes(roleType)}
                                      org={org}
                                    />
                                  </Tab.Pane>
                                )
                              },
                            },
                            {
                              menuItem: 'Parent Access',
                              render: () => {
                                return (
                                  <Tab.Pane as='div'>
                                    <Segment>
                                      <Header>Parent Course Access</Header>
                                      <CourseSelector
                                        availableCourses={allCourses}
                                        isDisabled={[ ROLE_TYPES.SUPERVISOR, ROLE_TYPES.ADVISOR ].includes(roleType)}
                                        selectedCourseIds={this.state.parentCourseIds}
                                        onChange={(ids) => this.setState({ ...this.state, parentCourseIds: ids })}
                                        isSaving={false}
                                        hideHeader={true}
                                        hideParentCourses={false}
                                        showOnlyParentCourses={true}
                                      />
                                      <Button
                                        floated='right'
                                        disabled={isEqual(sortBy(this.state.parentCourseIds), sortBy(get(org, 'parentCourseIds')))}
                                        color='green'
                                        loading={loading}
                                        onClick={() => {
                                          updateOrganization({
                                            variables: {
                                              organization: {
                                                id: org.id,
                                                parentCourseIds: this.state.parentCourseIds,
                                              },
                                            },
                                          }).then(refetch)
                                        }}
                                      >
                                          Save
                                      </Button>
                                      <br style={{ clear: 'both' }} />
                                    </Segment>
                                  </Tab.Pane>
                                )
                              },
                            },
                            {
                              menuItem: 'Baseline',
                              render: () => (
                                <Tab.Pane as='div'>
                                  <BaselineForm
                                    assessmentsEnabled={org.assessmentsEnabled}
                                    baselineAnalysisEnabled={org.baselineAnalysisEnabled}
                                    baselineRecommendationsEnabled={org.baselineRecommendationsEnabled}
                                    alternativeAssessmentEnabled={org.alternativeAssessmentEnabled}
                                    isSaving={loading}
                                    onSave={(data) => {
                                      updateOrganization({
                                        variables: {
                                          organization: {
                                            id: org.id,
                                            ...data,
                                          },
                                        },
                                      }).then(refetch)
                                    }}
                                  />
                                </Tab.Pane>
                              ),
                            },
                          ]

                          return (
                            <div className='manage-org'>
                              <Tab
                                panes={(showManagerControls) ? panes : panes.slice(0, 3)}
                                menu={{ secondary: true, size: 'huge' }}
                                activeIndex={this.state.selectedTabIndex}
                                onTabChange={(e, { activeIndex }) => this.setState({ ...this.state, selectedTabIndex: activeIndex })}
                              />
                            </div>
                          )
                        }}
                      </Mutation>

                      {/* {(!showManagerControls && org.roomsEnabled) && (
                        <Segment>
                          <Header>Student Notifications</Header>
                          <Message
                            info
                            icon='info circle'
                            content='In order to ensure your students are getting the help they need, we require at least one email address for this organization to receive all student help requests and any fireword alerts that are triggered by students who are not being supervised by a staff member.'
                          />
                          <Form>
                            <Form.Field>
                              <Form.TextArea
                                label='Help Notifications Email Addresses'
                                type='text'
                                fluid
                                autoCorrect='off'
                                autoCapitalize='words'
                                spellCheck='false'
                              />
                            </Form.Field>
                            <Form.Field>
                              <Form.TextArea
                                label='Backup Fireword Notifications Email Addresses'
                                type='text'
                                fluid
                                autoCorrect='off'
                                autoCapitalize='words'
                                spellCheck='false'
                              />
                            </Form.Field>
                          </Form>
                        </Segment>

                      )} */}

                    </>
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </MainContentContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    clientCounts: {
      [FETCH_ACTIONS.GET_ALL]: getClientCountsState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getCoursesState,
    getClientCountsState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  getClientCounts,
  invalidateClientCounts,
}
const ManageOrganizationViewMainContentContainer = connect(mapStateToProps, mapDispatchToProps)(ManageOrganizationViewWrapper)

export default translate([ 'components' ])(ManageOrganizationViewMainContentContainer)
