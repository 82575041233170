import React, { useState } from 'react'
import to from 'await-to-js'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  Input,
  Modal,
  Icon,
} from 'semantic-ui-react'

import Mutation from '../../common/mutation/mutation'
import PutOrganizationForm from '../../forms/put-organization/put-organization'
import UPDATE_ORG from '../../../helpers/graphql-queries/update-org'
import REPARENT_ORG from '../../../helpers/graphql-queries/reparent-org'
import DELETE_NOTIFICATION_SETTINGS_BY_ORG from '../../../helpers/graphql-queries/delete-notification-settings-by-org'
import { HTTP_METHODS } from '../../../helpers/fetch-constants'
import fetch from '../../../helpers/fetch'

const ENTER_KEY_CODE = 13

const MoveOrgButton = ({ orgId }) => {
  const [ showMoveModal, setShowMoveModal ] = useState(false)
  const [ newParentOrgId, setNewParentOrgId ] = useState('')
  return (
    <Mutation
      mutation={REPARENT_ORG}
      onCompleted={() => window.location.reload()}
    >
      {(reparentOrganization, result) => {
        return (
          <>
            <Button
              color='blue'
              size='tiny'
              loading={result.loading}
              disabled={result.loading}
              onClick={() => setShowMoveModal(true)}
              type='button'
            >
              <Icon name='move'/> Move Organization
            </Button>
            <Modal
              open={showMoveModal}
            >
              <Modal.Header>Move This Organization</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <p>Enter the ID of the organization you wish to move this organization under:</p>
                  <Input
                    fluid
                    autoCorrect='off'
                    autoCapitalize='none'
                    spellCheck='false'
                    placeholder='New Parent Org ID'
                    value={newParentOrgId}
                    onChange={(e, data) => setNewParentOrgId(data.value)}
                    onKeyUp={(event) => {
                      if (event.keyCode === ENTER_KEY_CODE) {
                        reparentOrganization({
                          variables: {
                            id: orgId,
                            newParentId: newParentOrgId,
                          },
                        })
                        setShowMoveModal(false)
                      }
                    }}
                  />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button type='button' onClick={() => setShowMoveModal(false)}>
                  Cancel
                </Button>
                <Button
                  content='Move Org'
                  labelPosition='right'
                  icon='checkmark'
                  onClick={() => {
                    reparentOrganization({
                      variables: {
                        id: orgId,
                        newParentId: newParentOrgId,
                      },
                    })
                    setShowMoveModal(false)
                  }}
                  color='green'
                  type='button'
                />
              </Modal.Actions>
            </Modal>
          </>
        )
      }}
    </Mutation>
  )
}

MoveOrgButton.propTypes = {
  orgId: PropTypes.string.isRequired,
}

const SyncSsoButton = ({
  orgId, accessToken, ssoType,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ syncError, setSyncError ] = useState(null)
  return (
    <>
      <Button
        color='blue'
        size='tiny'
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true)
          const headers = { authorization: `Bearer ${accessToken}` }
          const url = `/${ssoType}/syncUsers`
          const method = HTTP_METHODS.POST
          const [ err ] = await to(fetch(url, {
            method,
            headers,
            data: {
              orgId,
            },
          }))
          setIsLoading(false)
          if (err) {
            setSyncError(err)
          }
        }}
        type='button'
      >
        <Icon loading={isLoading} name='sync alternate'/> Sync SSO Users
      </Button>
      <Modal
        open={!!syncError}
        closeIcon
      >
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>There was an error syncing the SSO user data. Please try again in a few minutes.</p>
            {(!!syncError) && (<p>{syncError.message}</p>)}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  )
}

SyncSsoButton.propTypes = {
  orgId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  ssoType: PropTypes.string.isRequired,
}

const UpdateOrganization = ({
  org,
  courses,
  isSSO,
  orgType,
  canUpdateToCustomer,
  canUpdateToSite,
  showAdvancedOptions,
  showGlobalOptions,
  canUpdateCourses,
  canSyncSso,
  accessToken,
}) => (
  <Mutation
    mutation={UPDATE_ORG}
  >
    {(updateOrganization, { loading }) => {
      return (
        <Mutation
          mutation={DELETE_NOTIFICATION_SETTINGS_BY_ORG}
        >
          {(deleteSettings, { loading: isDeleting }) => {
            const ssoType = (isSSO && org.id.startsWith('co_')) ? 'clever' : (isSSO && org.id.startsWith('clo_')) ? 'classlink' : null
            return (
              <>
                {(showAdvancedOptions) && (
                  <MoveOrgButton orgId={org.id}/>
                )}
                {(canSyncSso && !!ssoType && orgType === 'customer') && (
                  <SyncSsoButton orgId={org.id} accessToken={accessToken} ssoType={ssoType}/>
                )}
                <PutOrganizationForm
                  {...org}
                  isLoading={loading || isDeleting}
                  orgType={orgType}
                  isNewOrg={false}
                  isSSO={isSSO}
                  courses={courses}
                  canUpdateToCustomer={canUpdateToCustomer}
                  canUpdateToSite={canUpdateToSite}
                  canUpdateCourses={canUpdateCourses}
                  showAdvancedOptions={showAdvancedOptions}
                  showGlobalOptions={showGlobalOptions}
                  onRemoveNotifications={() => {
                    deleteSettings({
                      variables: {
                        orgId: org.id,
                      },
                    })
                  }}
                  onSave={(updatedOrg) => {
                    updateOrganization({
                      variables: {
                        organization: {
                          ...updatedOrg,
                          orgSource: undefined,
                          id: org.id,
                          studentAccessSchedule: updatedOrg.studentAccessSchedule,
                        },
                      },
                    })
                  }}
                />
              </>
            )
          }}
        </Mutation>
      )
    }}
  </Mutation>
)

UpdateOrganization.propTypes = {
  org: PropTypes.object,
  courses: PropTypes.arrayOf(PropTypes.object),
  isSSO: PropTypes.bool,
  orgType: PropTypes.string,
  canUpdateToCustomer: PropTypes.bool,
  canUpdateToSite: PropTypes.bool,
  showAdvancedOptions: PropTypes.bool,
  showGlobalOptions: PropTypes.bool,
  canUpdateCourses: PropTypes.bool,
  canSyncSso: PropTypes.bool,
  accessToken: PropTypes.string,
}

UpdateOrganization.defaultProps = {
  courses: [],
  isSSO: false,
  orgType: 'customer',
  canUpdateCourses: false,
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = { }
const UpdateOrganizationContainer = connect(mapStateToProps, mapDispatchToProps)(UpdateOrganization)

export default UpdateOrganizationContainer
