import axios from 'axios'
import to from 'await-to-js'
import httperr from 'httperr'
import {
  get,
  isObject,
} from 'lodash'
import Cache from 'eidetic'

import config from '../config.js'

const SIMILAR_REQUEST_THRESHOLD = 3

const options = {
  maxSize: 100,
  canPutWhenFull: true,
}
const cache = new Cache(options)

const instance = axios.create({
  baseURL: config.api.baseUrl,
  timeout: config.api.timeoutSeconds * 1000, // timeoutSeconds is in config.js
})
const gqlInstance = axios.create({
  baseURL: config.api.baseGraphQlUrl,
  timeout: config.api.timeoutSeconds * 1000, // timeoutSeconds is in config.js
})
const UnknownNetworkError = httperr.createHttpError(0, 'Unknown Network Error')
const TooManyRequestsError = httperr.createHttpError(429, 'Too Many Requests Error')

const getFetchCacheKey = (url, config) => `${config.method}|${url}|${(isObject(config.data)) ? JSON.stringify(config.data) : config.data}|${config.headers && config.headers.authorization}`
const getFetchGraphQlCacheKey = (headers, operationName, variables, query) => `${operationName}|${JSON.stringify(variables || {})}|${query}|${headers.authorization}`

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export const wasCancelled = (error) => axios.isCancel(error)

export default async function fetch (url, config) {
  const requestCacheKey = getFetchCacheKey(url, config)
  const requestCount = cache.get(requestCacheKey) || 0
  if (requestCount >= SIMILAR_REQUEST_THRESHOLD) {
    throw TooManyRequestsError('There were too many requests in a short period of time')
  }
  cache.put(requestCacheKey, requestCount + 1, 5, true) // keeps track of the request for 5 seconds, but each time the same key is accessed, it will reset the timer for that key
  let [ err, result ] = await to(instance(url, config))
  if (result && result.status >= 500) {
    [ err, result ] = await to(instance(url, config))
  }
  return handleFetchResults(err, result, url)
}

export const fetchGraphQl = async (headers, operationName, variables, query) => {
  const requestCacheKey = getFetchGraphQlCacheKey(headers, operationName, variables, query)
  const requestCount = cache.get(requestCacheKey) || 0
  if (requestCount >= SIMILAR_REQUEST_THRESHOLD) {
    throw TooManyRequestsError('There were too many requests in a short period of time')
  }
  cache.put(requestCacheKey, requestCount + 1, 5, true) // keeps track of the request for 5 seconds, but each time the same key is accessed, it will reset the timer for that key
  const [ err, result ] = await to(gqlInstance('/graphql', {
    method: 'post',
    headers,
    data: {
      operationName,
      variables,
      query,
    },
  }))
  return handleFetchResults(err, result, '/graphql')
}

// export for unit testing
export const handleFetchResults = (err, result, url) => {
  if (err) {
    if (wasCancelled(err)) {
      throw err
    }
    if (err.status === 0 || !err.response) {
      throw new UnknownNetworkError(err.message)
    }
    const errorMessage = get(err, 'response.data.message', err.message || 'Unknown Error')
    const errorStatus = get(err, 'response.status')
    // if (window.ga) {
    //   try {
    //     window.ga('send', 'exception', {
    //       exDescription: errorMessage + ' - ' + errorStatus + ' (' + url + ')',
    //       exFatal: false,
    //     })
    //   } catch (e) {
    //   }
    // }
    throw httperr[errorStatus](errorMessage)
  }
  if (result.status === 201 && result.headers.location) {
    return result.headers.location.split('/').pop()
  }
  return result.data
}
