import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Segment,
  Button,
  Header,
  Icon,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  find,
  map,
  sortBy,
} from 'lodash'

import isStale from '../../../../helpers/is-stale'
import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import {
  getAllContentItems,
  invalidateContentItems,
  updateContentItem,
  createContentItem,
  deleteContentItem,
  acknowledgeCreateContentItem,
  acknowledgeUpdateContentItem,
  moveContentItems,
} from '../../../../actions/content-items'
import {
  getCourseImages,
  invalidateCourseImages,
} from '../../../../actions/course-images'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import ContentItemForm from '../../../forms/content-item/content-item'
import CheckboxGroup from '../../../common/checkbox-group'

import './course-edit-details.css'

const courseIdPathParam = ':courseId'
const contentItemIdPathParam = ':contentItemId'
const path = `/courses/${courseIdPathParam}/edit/${contentItemIdPathParam}`
// const TRANSLATION_PREFIX = 'views.organization.course-edit-details'
const SCROLL_POSITION_MEMORY_KEY = 'ci-id-scroll-postion.base.education'

export const helpers = {
  getPath: (courseId, contentItemId) => `${path.replace(courseIdPathParam, courseId).replace(contentItemIdPathParam, contentItemId)}`,
}

export class CourseEditDetailsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getContentItemsState: PropTypes.object.isRequired,
    postContentItemsState: PropTypes.object.isRequired,
    putContentItemsState: PropTypes.object.isRequired,
    deleteContentItemsState: PropTypes.object.isRequired,
    getCourseImagesState: PropTypes.object.isRequired,
    getAllContentItems: PropTypes.func.isRequired,
    invalidateContentItems: PropTypes.func.isRequired,
    updateContentItem: PropTypes.func.isRequired,
    createContentItem: PropTypes.func.isRequired,
    deleteContentItem: PropTypes.func.isRequired,
    acknowledgeCreateContentItem: PropTypes.func.isRequired,
    acknowledgeUpdateContentItem: PropTypes.func.isRequired,
    moveContentItems: PropTypes.func.isRequired,
    getCourseImages: PropTypes.func.isRequired,
    invalidateCourseImages: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    onEditItemClick: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  static path = path

  dismissMessage = () => {
    this.props.acknowledgeUpdateContentItem()
  }

  saveContentItem = (data) => {
    const {
      authState: { accessToken },
      updateContentItem,
      getContentItemsState,
      match: { params: { courseId, contentItemId } },
    } = this.props

    const oldContentItem = get(getContentItemsState, `value.${contentItemId}`)

    updateContentItem({
      data: Object.assign({ id: contentItemId }, data),
      courseId,
      accessToken,
      oldContentItem,
    })
  }

  removeContentItem = (contentItem) => {
    const {
      authState: { accessToken },
      deleteContentItem,
      getContentItemsState,
      onBack,
      match: { params: { courseId } },
    } = this.props
    if (!window.confirm('Are you sure?')) {
      return
    }
    const contentItems = (getContentItemsState.value) ? sortBy(Object.values(getContentItemsState.value), 'ordinal') : []
    const allContentItemIds = map(contentItems, 'id')
    deleteContentItem({
      id: contentItem.id,
      accessToken,
      courseId,
      allContentItemIds,
    })
    onBack(courseId)
  }

  componentDidMount () {
    const {
      authState: { accessToken },
      getContentItemsState,
      getCourseImagesState,
      getAllContentItems,
      getCourseImages,
      match: { params: { courseId } },
    } = this.props

    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getCourseImagesState)) {
      getCourseImages({ accessToken })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      getContentItemsState,
      getCourseImagesState,
      getAllContentItems,
      getCourseImages,
      match: { params: { courseId } },
    } = this.props

    if (isStale(getContentItemsState)) {
      getAllContentItems({ accessToken, courseId })
    }
    if (isStale(getCourseImagesState)) {
      getCourseImages({ accessToken })
    }
  }

  componentWillUnmount () {
    setTimeout(this.props.acknowledgeUpdateContentItem.bind(this), 100)
    setTimeout(this.props.invalidateContentItems.bind(this), 100)
  }

  render () {
    const {
      t,
      getContentItemsState,
      putContentItemsState,
      getCoursesState,
      match: { params: { courseId, contentItemId } },
      getCourseImagesState,
      showFriendlyApiErrorMessages,
      onEditItemClick,
      onBack,
    } = this.props
    // since we know we have to reload the data if it's stale, don't bother rendering anything under this condition
    if (isStale(getContentItemsState)) {
      return null
    }

    if (getCoursesState.error || getContentItemsState.error) {
      return (
        <FetchResultMessage
          success={false}
          error={getCoursesState.error || getContentItemsState.error}
          showFriendlyError={showFriendlyApiErrorMessages}/>
      )
    }

    const isSaving = putContentItemsState.isLoading
    const isLoading = getCoursesState.isLoading || getContentItemsState.isLoading || getCourseImagesState.isLoading
    const course = get(getCoursesState, `value.${courseId}`, {})
    const ci = get(getContentItemsState, `value.${contentItemId}`)
    const error = putContentItemsState.error
    const wasSuccesful = (putContentItemsState.succeeded && !error)
    const images = (getCourseImagesState.value) ? get(getCourseImagesState, 'value', []) : []
    if (!ci) {
      return null
    }
    const nextCi = find(Object.values(getContentItemsState.value), { ordinal: ci.ordinal + 1 })
    const prevCi = find(Object.values(getContentItemsState.value), { ordinal: ci.ordinal - 1 })

    const matchingImg = images.find((img) => img === ci.view.container)
    const bgImagesBaseUrl = 'https://course.base.education/img'
    const bgImage = (matchingImg) ? `${bgImagesBaseUrl}/${matchingImg}` : undefined

    return (
      <Container className='course-edit'>
        <FullScreenLoadingOverlay isActive={isLoading}/>
        <FetchResultMessage
          itemType='content item'
          success={wasSuccesful}
          error={error}
          showFriendlyError={showFriendlyApiErrorMessages}
          onDismiss={this.dismissMessage}
        />
        <Header as='h2' className='inline text'>{course.titles} {(course.isActive) ? '' : ` (${t('discontinued')})`}</Header>
        <Button style={{ marginBottom: '10px' }} disabled={!nextCi} color='blue' size='tiny' floated='right' loading={isSaving} onClick={() => {
          window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, nextCi.id)
          onEditItemClick(courseId, nextCi.id)
        }}>
          Next Panel
          <Icon name='arrow right' />
        </Button>
        <Button style={{ marginBottom: '10px' }} disabled={!prevCi} color='blue' size='tiny' floated='right' loading={isSaving} onClick={() => {
          window.sessionStorage.setItem(SCROLL_POSITION_MEMORY_KEY, prevCi.id)
          onEditItemClick(courseId, prevCi.id)
        }}>
          <Icon name='arrow left' />
          Previous Panel
        </Button>
        <Button style={{ marginBottom: '10px' }} color='pink' size='tiny' floated='right' loading={isSaving} onClick={() => onBack(courseId)}>
          <Icon name='th list' />
          Back to all panels
        </Button>
        <Button style={{ marginBottom: '10px' }} color='red' size='tiny' floated='right' loading={isSaving} onClick={() => this.removeContentItem(ci)}>
          <Icon name='remove' />
          Delete
        </Button>
        <div style={{ clear: 'both' }}/>
        <ContentItemForm
          titles={ci.titles}
          bodies={ci.bodies}
          type={ci.type}
          view={ci.view}
          ordinal={ci.ordinal}
          availableImages={images}
          notes={ci.notes}
          choices={ci.choices}
          onSave={this.saveContentItem}
          isLoading={isSaving}
        />
        <Segment loading={isLoading || isSaving} style={{ overflow: 'hidden' }}>
          <div
            className='preview-panel'
            style={{
              position: 'relative',
              backgroundImage: `url(${bgImage})`,
              backgroundSize: 'cover',
              backgroundPositionX: 'center',
              backgroundPositionY: 'center',
              height: 500,
              width: '100%',
            }}>
            <div className={`flow ${ci.view.classes}`}>
              <div className='headers'>
                {ci.titles.map((t, index) => (
                  <h2 key={`${ci.id}-title-${index}`} className={ci.view.titleClasses}>{t}</h2>
                ))}
              </div>
              <div className='bodies'>
                {ci.bodies.map((t, index) => (
                  <div key={`${ci.id}-body-${index}`} className={ci.view.textClasses}>{t}</div>
                ))}
              </div>
              {(ci.type.startsWith('freeform')) && (
                <div style={{ width: '100%', marginBottom: 15 }}>
                  <textarea rows={8} style={{ width: '100%' }}></textarea>
                </div>
              )}
              {(ci.type === 'scale-1-10') && (
                <div style={{ width: '100%', marginBottom: 15 }}>
                  <Button.Group color='teal'>
                    <Button>1</Button>
                    <Button>2</Button>
                    <Button>3</Button>
                    <Button>4</Button>
                    <Button>5</Button>
                    <Button>6</Button>
                    <Button>7</Button>
                    <Button>8</Button>
                    <Button>9</Button>
                    <Button>10</Button>
                  </Button.Group>
                </div>
              )}
              {(ci.type === 'multiple-choice' && ci.choices && ci.choices.length) && (
                <CheckboxGroup
                  vertical={true}
                  toggle={true}
                  options={ci.choices.map((choice) => ({
                    value: choice,
                    label: choice,
                  }))}
                />
              )}
              {(ci.type === 'true-false') && (
                <React.Fragment>
                  <Button color='green'>True</Button>
                  <Button color='black'>False</Button>
                </React.Fragment>
              )}
              {(ci.type === 'yes-no') && (
                <React.Fragment>
                  <Button color='green'>Yes</Button>
                  <Button color='black'>No</Button>
                </React.Fragment>
              )}
              {(ci.type === 'freeform-na') && (
                <Button color='black'>Does Not Apply</Button>
              )}
              {(ci.type !== 'true-false' && ci.type !== 'yes-no') && (
                <Button color='blue'>Next</Button>
              )}
            </div>

          </div>
        </Segment>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    contentItems: {
      [FETCH_ACTIONS.GET_ALL]: getContentItemsState,
      [FETCH_ACTIONS.POST]: postContentItemsState,
      [FETCH_ACTIONS.PUT]: putContentItemsState,
      [FETCH_ACTIONS.DELETE]: deleteContentItemsState,
    },
    courses: {
      [FETCH_ACTIONS.GET_ALL]: getCoursesState,
    },
    courseImages: {
      [FETCH_ACTIONS.GET_ALL]: getCourseImagesState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getCoursesState,
    getContentItemsState,
    postContentItemsState,
    putContentItemsState,
    deleteContentItemsState,
    getCourseImagesState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  getAllContentItems,
  invalidateContentItems,
  updateContentItem,
  createContentItem,
  deleteContentItem,
  acknowledgeCreateContentItem,
  acknowledgeUpdateContentItem,
  moveContentItems,
  getCourseImages,
  invalidateCourseImages,
}
const CourseEditDetailsViewContainer = connect(mapStateToProps, mapDispatchToProps)(CourseEditDetailsView)

export default translate([ 'components' ])(CourseEditDetailsViewContainer)
