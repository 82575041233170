import React from 'react'
import ReactDOM from 'react-dom'
import qs from 'querystring'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router'
import { HashRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import localforage from 'localforage'
import to from 'await-to-js'
import {
  defer,
  omit,
  debounce,
} from 'lodash'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloProvider as ApolloProviderOld } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import LogRocket from 'logrocket'

import config from './config.js'
import Store, { thunkMiddleware } from './store'
import i18n from './i18n'
import { getInitialState, getInitialBaselineState } from './initial-state'
import HomeView from './components/views/home/home'
import NewHomeView from './components/views/home-new/home-new'
import BaselineHomeView from './components/views/baseline-home/baseline-home'
import LoginView from './components/views/login/login'
import NotFoundView from './components/views/not-found'
import ResetPasswordView from './components/views/reset-password/reset-password'
import SetPasswordView from './components/views/set-password/set-password'
import PrivateRoute from './components/common/private-route/private-route'
import { unregister } from './registerServiceWorker'
import isAuthenticated from './helpers/is-authenticated'
import UserActivityAuthRefresher from './components/common/user-activity-auth-refresher/user-activity-auth-refresher'
import TopLevelErrorBoundary from './components/common/top-level-error-boundary/top-level-error-boundary'

import './index.css'
import 'semantic-ui-css/semantic.min.css'

const initLogRocket = () => {
  LogRocket.init('nkvkn5/admin-portal-prd', {
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().includes('hotjar')) {
        // ignore the request response pair
          return null
        }
        request.body = null

        // scrub header value from request
        if (request.headers['authorization']) {
          request.headers['authorization'] = ''
        }
        return request
      },
      responseSanitizer: (response) => {
        if (response.status < 400) {
          if (response.url.endsWith('/graphql')) {
            try {
              const parsedResponse = JSON.parse(response.body)
              if (parsedResponse.errors) {
                response.body = JSON.stringify({ errors: parsedResponse.errors })
              } else {
                response.body = null
              }
            } catch (ex) {
              console.error('Unable to parse graphql response', ex)
              response.body = null
            }
          } else {
          // scrubs response body
            response.body = null
          }
        }
        return response
      },
    },
  })
}

const fetcher = (...args) => window.fetch(...args)

// NOTE: Only initialize LogRocket if we are on the prod site and the user has clicked on the page somewhere
if (window.location.hostname.startsWith('admin.base.education') || window.location.hostname.startsWith('baseline.base.education')) {
  // This line may be interfering with LogRocket's ability to instrument network request properly
  // Per their docs: "LogRocket should be imported and initialized before libraries that wrap fetch or XMLHttpRequest"
  // document.addEventListener('click', initLogRocket, { once: true })
  initLogRocket()
}

const renderHome = (routeProps) => {
  // login view handles saving the auth state from the query string
  if (window.location.search.includes('auth=')) {
    window.location.href = window.location.origin + window.location.search
    return null
  }
  return (
    <TopLevelErrorBoundary redirectOnError={config.redirectOnError}>
      <UserActivityAuthRefresher>
        <NewHomeView {...routeProps}/>
      </UserActivityAuthRefresher>
    </TopLevelErrorBoundary>
  )
}

const renderBaselineHomeView = (routeProps) => {
  // login view handles saving the auth state from the query string
  if (window.location.search.includes('auth=')) {
    window.location.href = window.location.origin + window.location.search
    return null
  }
  return (
    <TopLevelErrorBoundary redirectOnError={config.redirectOnError}>
      <UserActivityAuthRefresher>
        <BaselineHomeView {...routeProps}/>
      </UserActivityAuthRefresher>
    </TopLevelErrorBoundary>
  )
}

async function loadAdmin () {
  // TODO: refine the local storage logic
  // TODO: reset to initial state in memory as well on logout
  // TODO: if app version changes, clear stored state (avoids incompatibilities if structure changes)
  // NOTE: Do not change this value, otherwise old DB data could get left on the system. Maybe look into not storing data here.
  const DB_NAME = 'local.base.education'
  const localStore = localforage.createInstance({ name: DB_NAME })

  // NOTE: this is for tracking if tabs were open recently, and if not, clear the stored data
  // in case someone was authed and just closed the browser or tab instead of logging out
  const TABS_KEY = 'tabs.base.education'
  const lastTabUpdate = new Date(window.localStorage.getItem(TABS_KEY) || 0)
  const now = new Date()
  if (now - lastTabUpdate < 0 || now - lastTabUpdate > 25000) {
    // can't detect recent open tabs, clear stored data
    await localStore.clear()
  }
  let openTabInterval

  const persistState = debounce(localStore.setItem.bind(localStore, 'state'), 1000, { maxWait: 5000 })
  const [ err, savedState ] = await to(localStore.getItem('state'))
  if (err || !savedState) {
    console.log('No saved state')
  }
  const initialState = getInitialState()
  const state = (savedState) ? Object.assign(initialState, omit(savedState, 'config')) : initialState
  const queryParams = qs.parse(window.location.search.replace(/^\?/, ''))
  if (!isAuthenticated(state.authentication) || queryParams.token || queryParams.auth) {
    // if we are coming in via auth on the query param, lets save the tab date,
    // so when we reload the page without the query param, it doesn't clear the storage above
    window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    await localStore.clear()
  } else {
    window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    openTabInterval = setInterval(() => {
      window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    }, 20000)
    LogRocket.identify(state.authentication.userId, {
      name: state.authentication.userName,
      email: state.authentication.email,
      roleType: state.authentication.roleType,
      orgId: state.authentication.orgId,
    })
  }
  const store = new Store(state, config.logStoreEvents)
  store.addMiddleware(thunkMiddleware)

  store.subscribe(async (path, selectedOldState) => {
    const newAuthState = store.getState('authentication')
    // if auth changed from previously authed to no longer authed, reset the state
    // check accessToken because it gets nulled out when user logs out
    if (path === 'authentication' && !!selectedOldState.accessToken && !newAuthState.accessToken) {
      if (apolloClient) {
        await apolloClient.resetStore()
      }
      clearInterval(openTabInterval)
      window.sessionStorage.removeItem(`hide-staff-help.base.education.${selectedOldState.userId}`)
      await localStore.clear()
      defer(() => {
        store.dispatch([ () => ({ ...getInitialState(), authentication: newAuthState }), null, 'resetState' ])
      })
    }
    // if auth changed from not authed to authed, then identify the user in logrocket
    if (path === 'authentication' && !selectedOldState.accessToken && !!newAuthState.accessToken) {
      window.localStorage.setItem(TABS_KEY, new Date().toISOString())
      openTabInterval = setInterval(() => {
        window.localStorage.setItem(TABS_KEY, new Date().toISOString())
      }, 20000)
      LogRocket.identify(newAuthState.userId, {
        name: newAuthState.userName,
        email: newAuthState.email,
        roleType: newAuthState.roleType,
        orgId: newAuthState.orgId,
      })
    }
    // if we are authed, persist the state
    if (newAuthState.accessToken) {
      persistState(omit(store.getState(), 'clients')) // do not persist client overviews state because we don't want it cached on page refresh
    }
  })

  const cache = new InMemoryCache()
  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = store.getState('authentication.accessToken')
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return forward(operation)
  })
  const httpLink = new HttpLink({ uri: `${config.api.baseGraphQlUrl}/graphql`, fetch: fetcher })

  // TODO: somehow get the x-amzn-requestid header to the error modal
  const afterwareLink = onError(({
    operation, graphQLErrors, networkError,
  }) => {
    const context = operation.getContext()
    const date = (context && context.response && context.response.headers) ? new Date(context.response.headers.get('date')).toISOString() : new Date().toISOString()
    const requestId = (context && context.response && context.response.headers) ? context.response.headers.get('x-amzn-requestid') : 'unknown-id'
    if (graphQLErrors) {
      const messages = graphQLErrors.map(({
        message,
        locations,
        path,
      }) => `[GraphQL error] (${date}) <${requestId}>: Message: ${message}, Location: ${locations}, Path: ${path}`)
      messages.forEach((message) => console.log(message))
      if (window.ga) {
        try {
          window.ga('send', 'exception', {
            exDescription: messages.join('; '),
            exFatal: false,
          })
        } catch (e) {
        }
      }
    }
    if (networkError) {
      console.log(`[Network error] (${date}) <${requestId}>: ${networkError}`)
    }
  })

  function stripTypenames (obj, propToDelete) {
    for (const property in obj) {
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        delete obj.property
        const newData = stripTypenames(obj[property], propToDelete)
        obj[property] = newData
      } else {
        if (property === propToDelete) {
          delete obj[property]
        }
      }
    }
    return obj
  }

  const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = stripTypenames(operation.variables, '__typename')
      return forward ? forward(operation) : null
    }
  })

  const apolloClient = new ApolloClient({
    link: from([
      authMiddleware,
      afterwareLink,
      removeTypenameMiddleware,
      httpLink,
    ]),
    cache,
  })

  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <ApolloProviderOld client={apolloClient}>
            <Switch>
              <Route path={ResetPasswordView.path} component={ResetPasswordView}/>
              <Route path={SetPasswordView.path} component={SetPasswordView}/>
              <Route path={LoginView.path} component={LoginView} />
              <Route path={NotFoundView.path} component={NotFoundView} />
              <PrivateRoute path={HomeView.path} render={renderHome}/>
              <Redirect to={NotFoundView.path} />
            </Switch>
          </ApolloProviderOld>
        </I18nextProvider>
      </HashRouter>
    </Provider>,
    document.getElementById('root'),
  )
  unregister()
}

async function loadBaseline () {
  // TODO: refine the local storage logic
  // TODO: reset to initial state in memory as well on logout
  // TODO: if app version changes, clear stored state (avoids incompatibilities if structure changes)
  // NOTE: Do not change this value, otherwise old DB data could get left on the system. Maybe look into not storing data here.
  const DB_NAME = 'local.baseline.education'
  const localStore = localforage.createInstance({ name: DB_NAME })

  // NOTE: this is for tracking if tabs were open recently, and if not, clear the stored data
  // in case someone was authed and just closed the browser or tab instead of logging out
  const TABS_KEY = 'tabs.baseline.education'
  const lastTabUpdate = new Date(window.localStorage.getItem(TABS_KEY) || 0)
  const now = new Date()
  if (now - lastTabUpdate < 0 || now - lastTabUpdate > 25000) {
    // can't detect recent open tabs, clear stored data
    await localStore.clear()
  }
  let openTabInterval

  const persistState = debounce(localStore.setItem.bind(localStore, 'state'), 1000, { maxWait: 5000 })
  const [ err, savedState ] = await to(localStore.getItem('state'))
  if (err || !savedState) {
    console.log('No saved state')
  }
  const initialState = getInitialBaselineState()
  const state = (savedState) ? Object.assign(initialState, omit(savedState, 'config')) : initialState
  const queryParams = qs.parse(window.location.search.replace(/^\?/, ''))
  if (!isAuthenticated(state.authentication) || queryParams.token || queryParams.auth) {
    // if we are coming in via auth on the query param, lets save the tab date,
    // so when we reload the page without the query param, it doesn't clear the storage above
    window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    await localStore.clear()
  } else {
    window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    openTabInterval = setInterval(() => {
      window.localStorage.setItem(TABS_KEY, new Date().toISOString())
    }, 20000)
    LogRocket.identify(state.authentication.userId, {
      name: state.authentication.userName,
      email: state.authentication.email,
      roleType: state.authentication.roleType,
      orgId: state.authentication.orgId,
    })
  }
  const store = new Store(state, true)
  store.addMiddleware(thunkMiddleware)

  store.subscribe(async (path, selectedOldState) => {
    const newAuthState = store.getState('authentication')
    // if auth changed from previously authed to no longer authed, reset the state
    // check accessToken because it gets nulled out when user logs out
    if (path === 'authentication' && !!selectedOldState.accessToken && !newAuthState.accessToken) {
      if (apolloClient) {
        await apolloClient.resetStore()
      }
      clearInterval(openTabInterval)
      await localStore.clear()
      defer(() => {
        store.dispatch([ () => ({ ...getInitialState(), authentication: newAuthState }), null, 'resetState' ])
      })
    }
    // if auth changed from not authed to authed, then start tracking tab date
    if (path === 'authentication' && !selectedOldState.accessToken && !!newAuthState.accessToken) {
      window.localStorage.setItem(TABS_KEY, new Date().toISOString())
      openTabInterval = setInterval(() => {
        window.localStorage.setItem(TABS_KEY, new Date().toISOString())
      }, 20000)
      LogRocket.identify(newAuthState.userId, {
        name: newAuthState.userName,
        email: newAuthState.email,
        roleType: newAuthState.roleType,
        orgId: newAuthState.orgId,
      })
    }
    // if we are authed, persist the state
    if (newAuthState.accessToken) {
      persistState(store.getState())
    }
  })

  const cache = new InMemoryCache()
  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = store.getState('authentication.accessToken')
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return forward(operation)
  })
  const httpLink = new HttpLink({ uri: `${config.api.baseGraphQlUrl}/graphql`, fetch: fetcher })

  // TODO: somehow get the x-amzn-requestid header to the error modal
  const afterwareLink = onError(({
    operation, graphQLErrors, networkError,
  }) => {
    const context = operation.getContext()
    const date = (context && context.response && context.response.headers) ? new Date(context.response.headers.get('date')).toISOString() : new Date().toISOString()
    const requestId = (context && context.response && context.response.headers) ? context.response.headers.get('x-amzn-requestid') : 'unknown-id'
    if (graphQLErrors) {
      graphQLErrors.map(({
        message,
        locations,
        path,
      }) => console.log(`[GraphQL error] (${date}) <${requestId}>: Message: ${message}, Location: ${locations}, Path: ${path}`))
    }
    if (networkError) {
      console.log(`[Network error] (${date}) <${requestId}>: ${networkError}`)
    }
  })

  function stripTypenames (obj, propToDelete) {
    for (const property in obj) {
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        delete obj.property
        const newData = stripTypenames(obj[property], propToDelete)
        obj[property] = newData
      } else {
        if (property === propToDelete) {
          delete obj[property]
        }
      }
    }
    return obj
  }

  const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = stripTypenames(operation.variables, '__typename')
      return forward ? forward(operation) : null
    }
  })

  const apolloClient = new ApolloClient({
    link: from([
      authMiddleware,
      afterwareLink,
      removeTypenameMiddleware,
      httpLink,
    ]),
    cache,
  })

  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={apolloClient}>
            <Switch>
              <Route path={ResetPasswordView.path} component={ResetPasswordView}/>
              <Route path={SetPasswordView.path} component={SetPasswordView}/>
              <Route path={LoginView.path} component={LoginView} />
              <Route path={NotFoundView.path} component={NotFoundView} />
              <PrivateRoute path={BaselineHomeView.path} render={renderBaselineHomeView}/>
              <Redirect to={NotFoundView.path} />
            </Switch>
          </ApolloProvider>
        </I18nextProvider>
      </HashRouter>
    </Provider>,
    document.getElementById('root'),
  )
  unregister()
}

window.ResizeObserver = undefined

if (process.env.REACT_APP_NAME === 'BASE Administration') {
  loadAdmin()
} else if (process.env.REACT_APP_NAME === 'BASE Education') {
  console.log('loading course')
} else if (process.env.REACT_APP_NAME === 'BASEline') {
  loadBaseline()
} else {
  console.error('Unrecognized App')
}
