import gql from 'graphql-tag'

export const NAME = 'updateOrganizationAccessSchedule'
const UPDATE_ORG = gql`
mutation ${NAME}($organization: UpdateOrganizationAccessScheduleInput!) {
  ${NAME}(org: $organization) {
    id
    parentId
    name
    isActive
    courseIds
    studentLimit
    numberOfSites
    type
    educationLevels
    contractStartDate
    contractEndDate
    country
    region
    selfManagedNotificationRoleTypes
    assessmentsEnabled
    baselineAnalysisEnabled
    baselineRecommendationsEnabled
    alternativeAssessmentEnabled
    sessionsEnabled
    roomsEnabled
    privateRoomsEnabled
    firewordsEnabled
    sevenMindsetsEnabled
    helpNotificationsEmails
    requireSessionPasswords
    openCourseIds
    parentCourseIds
    studentAccessSchedule {
      timezone
      weeklyTimes {
        day
        startMinute
        endMinute
      }
      oneTimeExceptions {
        start {
          date
          month
          year
        }
        end {
          date
          month
          year
        }
      }
    }
  }
}
`

export default UPDATE_ORG
