const BUFFER_MS = 5000

const isAuthenticated = ({
  accessToken,
  expires,
  userId,
  orgId,
  roleType,
}) => {
  const now = new Date().getTime()
  return (!!accessToken && !!userId && !!orgId && !!roleType && (expires - now) > BUFFER_MS)
}

export default isAuthenticated
