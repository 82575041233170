import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid, Button, Icon, Input,
} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './specific-day-range-access-exception.css'

class SpecificDayRangeAccessException extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    }
  }

  static propTypes = {
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }

  static defaultProps = {
    onChange: console.log.bind(console, 'onChange'),
    onRemove: console.log.bind(console, 'onRemove'),
  }

  updateStartDate = (newValue) => {
    const { onChange } = this.props
    const endDate = (newValue > this.state.endDate) ? newValue : this.state.endDate
    this.setState({ startDate: newValue, endDate })
    onChange({ startDate: newValue, endDate })
  }

  updateEndDate = (newValue) => {
    const { onChange } = this.props
    this.setState({ ...this.state, endDate: newValue })
    onChange({ ...this.state, endDate: newValue })
  }

  render () {
    const {
      onRemove,
      disabled,
    } = this.props
    const { startDate, endDate } = this.state
    const startMonth = (startDate) ? startDate.getMonth() + 1 : ''
    const startDay = (startDate) ? startDate.getDate() : ''
    const disabledStartDateString = (startDate) ? `${(startMonth < 10) ? `0${startMonth}` : startMonth}/${(startDay < 10) ? `0${startDay}` : startDay}/${startDate.getFullYear()}` : ''
    const endMonth = (endDate) ? endDate.getMonth() + 1 : ''
    const endDay = (endDate) ? endDate.getDate() : ''
    const disabledEndDateString = (endDate) ? `${(endMonth < 10) ? `0${endMonth}` : endMonth}/${(endDay < 10) ? `0${endDay}` : endDay}/${endDate.getFullYear()}` : ''
    const maxDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * 365 * 5)) // 5 years out
    return (
      <Grid textAlign='center' stackable className='specific-day-range-access-exception'>
        <Grid.Column className={(!startDate) ? 'warn' : ''} width={7}>
          {(!disabled) && (<DatePicker
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            showYearDropdown={true}
            showMonthDropdown={true}
            minDate={new Date()}
            maxDate={maxDate}
            customInput={(
              <Input
                fluid
                label={{
                  basic: true,
                  content: 'Start Date',
                  icon: 'calendar',
                }}
              />
            )}
            selected={this.state.startDate}
            onChange={this.updateStartDate}
          />)}
          {(disabled) && (<Input
            fluid
            disabled
            label={{
              basic: true,
              content: 'Start Date',
              icon: 'calendar',
            }}
            value={disabledStartDateString}
          />
          )}
        </Grid.Column>
        <Grid.Column className={(!endDate) ? 'warn' : ''} width={7}>
          {(!disabled) && (<DatePicker
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            showYearDropdown={true}
            showMonthDropdown={true}
            minDate={this.state.startDate}
            maxDate={maxDate}
            customInput={(
              <Input
                fluid
                label={{
                  basic: true,
                  content: 'End Date',
                  icon: 'calendar',
                }}
              />
            )}
            selected={this.state.endDate}
            onChange={this.updateEndDate}
          />)}
          {(disabled) && (<Input
            fluid
            disabled
            label={{
              basic: true,
              content: 'End Date',
              icon: 'calendar',
            }}
            value={disabledEndDateString}
          />
          )}
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            disabled={disabled}
            icon
            color='red'
            onClick={onRemove}
          >
            <Icon name='remove'/>
          </Button>
        </Grid.Column>
      </Grid>
    )
  }
}

export default SpecificDayRangeAccessException
